import { Dispatch, SetStateAction, useState, useRef } from "react";

const usePreview = (videoPausedInitial: boolean = false): [
  any,
  boolean, Dispatch<SetStateAction<boolean>>,
  boolean, Dispatch<SetStateAction<boolean>>,
  boolean, Dispatch<SetStateAction<boolean>>,
  () => void,
  boolean, () => void
] => {
  let previewElement = useRef<HTMLVideoElement>();
  const [showPreview, setShowPreview] = useState(false);
  const [muted, setMuted] = useState(true);
  const [videoPaused, setVideoPaused] = useState(videoPausedInitial);

  const [previewFallback, setPreviewFallback] = useState(false);

  const changeShowPreview = (value) => {
    if (!previewFallback) {
      setShowPreview(value);
    }
  }

  const toggleVideo = () => {
    if (previewFallback) {
      return;
    }

    if (previewElement?.current) {
      if (previewElement.current.paused) {
        const playPromise = previewElement.current.play();
        if (playPromise) {
          playPromise.then(function () {
            console.log('play')
          }).catch(function (error) {
            console.log('error play', error)
          });
        }
      } else {
        previewElement.current.pause();
      }

    }
  }

  const onVideoPreviewError = () => {
    //stop preview and show fallback
    setShowPreview(false);
    setMuted(true);
    setVideoPaused(true);
    setPreviewFallback(true);
  }

  return [
    previewElement,
    showPreview, changeShowPreview,
    muted, setMuted,
    videoPaused, setVideoPaused,
    toggleVideo,
    previewFallback, onVideoPreviewError
  ];
};

export default usePreview;
