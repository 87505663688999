import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from '@hooks/redux';
import CartFilledIcon from "@svg-icons/bag-filled.svg";
import CartEmptyIcon from "@svg-icons/empty-bag.svg";
import { UserProfile } from '@model/User';
import * as actions from "@redux-actions/";
import clsx from "@utility/clsx";
import { Course } from '@model/CoursesClass';
import { isPurchasable, isPurchased } from '@utility/ecommerceUtility';
import ButtonV2 from "@components/UI/ButtonV2";

type CartIconProps = {
    className?: string;
    classNameButton?: string;
    variant: 'course' | 'plan';
    course?: Course;
    width?: string;
    height?: string;
    forcePurchased?: boolean;
    v2?: boolean;
    isFocusable?: boolean;
    small?: boolean;
}

export const CartIcon = ({
    className,
    classNameButton,
    variant,
    course,
    width = "30",
    height = "26",
    isFocusable = true,
    forcePurchased,
    v2,
    small = true,
}: CartIconProps): React.ReactElement => {
    const dispatch = useDispatch();
    const lang = useSelector(state => state.utils.lang);

    const userProfile: UserProfile = useSelector((state) => state.user.userProfile);
    const noShowModalCart: boolean = useSelector((state) => state.user.noShowModalCart);

    const [filled, setFilled] = useState<boolean>(null);

    useEffect(() => {
        let filledTemp = null;
        if (variant === 'course') {
            if (course) {
                if (isPurchasable(course) && !isPurchased(course)) {
                    filledTemp = false;
                } else if (isPurchased(course)) {
                    filledTemp = true;
                }
            }
        } else {
            filledTemp = false;
            if (userProfile?.subscription) {
                filledTemp = true;
            }
        }
        setFilled(!forcePurchased ? filledTemp : true);
    }, [variant, course, noShowModalCart, userProfile?.subscription])

    const handleClickCart = (e) => {
        if (e) {
            e.stopPropagation();
        }

        if (!noShowModalCart) {
            dispatch(actions.setShowModalCart(true));
        }
    }

    const CartIconSvgV1 = filled ? CartFilledIcon : CartEmptyIcon;
    const CartIconSvgV2 = filled ? CartFilledIcon : CartEmptyIcon;
    const CartIconSvg = v2 ? CartIconSvgV2 : CartIconSvgV1;

    return (
        filled === null
            ?
            null
            :
            <ButtonV2
                className={clsx("icon-btn-bag", {
                    "clickable": !noShowModalCart,
                    [classNameButton]: classNameButton,
                })}
                variant='icon-btn'
                small={small}
                tabIndex={isFocusable && !noShowModalCart ? 0 : -1}
                ariaLabel={filled ? lang.WCAG_PURCHASED : lang.WCAG_PUCHASABLE}
                handleClick={handleClickCart}
            >
                <CartIconSvg
                    className={clsx("svg-icon bag", {
                        [className]: className,
                        "bag-filled": filled,
                        "bag-empty": !filled,
                    })}
                    fill="white"
                    width={width}
                    height={height}
                />
            </ButtonV2>
    );
}

export default CartIcon;
