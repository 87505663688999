import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../store/actions';
import Modal from './UI/Modal';
import { Teacher } from '../model/Teacher';
import CardTeacherOverview from './CardV3/CardTeacherOverview';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { printHTML } from '@utility/Api';
import { RootState } from 'src/store/store';

type Props = PropsFromRedux & {
  teacherModal: Teacher;
  showTeacherModal: boolean;
  toggleTeacherModal: (showTeacherModal: boolean, teacher?: Teacher) => void;
};

const TeacherOverview = (props: Props): ReactElement => {
  const checkClose = (): void => {
    props.toggleTeacherModal(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        key={props.showTeacherModal && props.teacherModal ? props.teacherModal.teacherId : null}
      >
        {props.showTeacherModal && props.teacherModal && (
          <Modal show={true} close={checkClose} animateSlide={true} hideX={true}>
            <div className="teacher-overview">
              <Header isModal={true} closeModal={checkClose} isBackAbsolute />
              <CardTeacherOverview teacher={props.teacherModal} />
              <div
                className="teacher-overview__summary"
                role="region"
                aria-labelledby="teacher-description"
              >
                <p
                  id="teacher-description"
                  className="avenir-light summary"
                  dangerouslySetInnerHTML={printHTML(props.teacherModal.bio)}
                ></p>
              </div>
            </div>
          </Modal>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    teacherModal: state.course.teacherModal,
    showTeacherModal: state.course.teacherModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleTeacherModal: (showTeacherModal, teacher) =>
      dispatch(actions.toggleTeacherModal(showTeacherModal, teacher)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TeacherOverview);
