import { SKELETON_COLORS } from "@utility/const";
import React from "react";
import ContentLoader from "react-content-loader";

const GenericSkeleton = () => (
  <div className="skeleton-container">
    <ContentLoader
      viewBox="0 0 100 100"
      width="100%"
      height="100%"
      primaryColor={SKELETON_COLORS.primaryColor}
      secondaryColor={SKELETON_COLORS.secondaryColor}
      preserveAspectRatio="none"
    >
<rect x="15" y="8" rx="10" ry="10" width="70" height="70" />
    </ContentLoader>
  </div>
);

export default GenericSkeleton;
