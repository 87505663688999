import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { getHeaders, printDateLong, isCompletedCourse, isVirtualClassroom, isCompletedChildCourse, getValueFromJWT } from '../../utility/Api';
import { showError } from './utilsActions';
import { ReportManager, UserCompletionDetail } from '@model/MyTeamModel';
import { promiseMock } from '@utility/ecommerceUtility';
import { downloadBase64 } from '@utility/myTeamUtility';
const axios = require('axios');

// import myteam from '../../data/manager.json';

export const getMyTeamList = (courses = false) => {

    return (dispatch, getState) => {
        dispatch(request());
        let url = urlList.GET_REPORT_MANAGER;

        //MOCK
        // let lastUpdateReport = printDateLong(Date.now(), getState().utils.lang)

        // myteam.usersCompletions.map(user => {
        //     const completedCourses = parseInt(user.onlineCompletions.split("/")[0]) + parseInt(user.classroomsCompletions.split("/")[0]);
        //     const totalCourses = parseInt(user.onlineCompletions.split("/")[1]) + parseInt(user.classroomsCompletions.split("/")[1]);
        //      if(totalCourses === 0) return user.saturation = 0; 
        //     user.saturation = Math.round(completedCourses * 100 / totalCourses);
        // })


        // dispatch(success(myteam, lastUpdateReport));

        axios.get(url + "?courses=" + courses, {
            headers: getHeaders()
        })
            .then(response => {
                let lastUpdateReport = printDateLong(Date.now(), getState().utils.lang)
                let myteam: ReportManager = response.data;

                myteam.usersCompletions.map(user => {
                    const completedCourses = parseInt(user.onlineCompletions.split("/")[0]) + parseInt(user.classroomsCompletions.split("/")[0]);
                    const totalCourses = parseInt(user.onlineCompletions.split("/")[1]) + parseInt(user.classroomsCompletions.split("/")[1]);
                    if (totalCourses === 0) return user.saturation = 0;
                    user.saturation = Math.ceil(completedCourses * 100 / totalCourses);
                })

                dispatch(success(myteam, lastUpdateReport));

                if (courses) dispatch(endedCall());

            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_MY_TEAM_REQUEST
        };
    }

    function success(myTeam, lastUpdateReport) {
        return {
            type: actionTypes.GET_MY_TEAM_SUCCESS,
            myTeam,
            lastUpdateReport
        };
    }

    function endedCall() {
        return {
            type: actionTypes.GET_MY_TEAM_WITH_COURSE_SUCCESS,
        };
    }


    function failure() {
        return {
            type: actionTypes.GET_MY_TEAM_FAILURE
        };
    }
};

export const getRefresh = () => {
    return (dispatch, getState) => {
        dispatch(request());
        let url = urlList.GET_REPORT_REFRESH;

        axios.get(url, {
            headers: getHeaders()
        })
            .then(response => {
                if (response.data !== true) {
                    dispatch(failure());
                }

            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_REFRESH_REQUEST
        };
    }

    // function success() {
    //     return {
    //         type: actionTypes.GET_REFRESH_SUCCESS
    //     };
    // }

    function failure() {
        return {
            type: actionTypes.GET_REFRESH_FAILURE
        };
    }
};

export const setRefreshRequested = (refreshRequested) => {
    return {
        type: actionTypes.SET_REFRESH_REQUESTED,
        refreshRequested
    }
}

export const refreshReportMyTeam = () => {
    return (dispatch) => {
        dispatch(setRefreshRequested(false));
        dispatch(getMyTeamList());
    }
}

export const getReportExcel = (usernameSingleMember: string = '') => {

    return (dispatch, getState) => {
        dispatch(request());
        let url = usernameSingleMember
            ? urlList.GET_REPORT_ONBOARDING_EXCEL + '?username=' + usernameSingleMember
            : urlList.GET_REPORT_EXCEL;

        axios.get(url, {
            headers: getHeaders()
        })
            .then(response => {
                let lang = getState().utils.lang;

                //create filename
                const now = new Date();
                const day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                let month = now.getMonth() + 1;
                const monthString = month < 10 ? '0' + month : month.toString();
                const year = now.getFullYear().toString().slice(2);
                let filename = usernameSingleMember ? lang.DOC_TEAM_SINGLE_MEMBER_FILENAME : lang.DOC_TEAM_FILENAME;
                if (!filename) {
                    filename = 'Leonardo_Team-report_{dd}.{mm}.{yy}';
                }
                filename = filename
                    .replace("{dd}", day)
                    .replace("{mm}", monthString)
                    .replace("{yy}", year);
                if (usernameSingleMember) {
                    filename = filename.replace("{username}", usernameSingleMember);
                }
                filename = filename.endsWith('.xlsx') ? filename : filename + '.xlsx';

                //download base 64 response with specified filename
                downloadBase64(response.data, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

                dispatch(success());
            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_REPORT_EXCEL_REQUEST
        };
    }

    function success() {
        return {
            type: actionTypes.GET_REPORT_EXCEL_SUCCESS
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_REPORT_EXCEL_FAILURE
        };
    }
};

export const getMyStats = () => {
    return (dispatch, getState) => {
        let myStats = {
            myCredits: 0,
            myCompletedOnline: 0,
            myCompletedClassrooms: 0,
            myTimeTraining: 0
        }

        //add courses to stats
        const coursesMap = getState().course.coursesMap;
        if (coursesMap) {
            for (const courseIdMaster in coursesMap) {
                const course = coursesMap[courseIdMaster];

                //if a course has children --> consider children
                if (course.childCourses && course.childCourses.length > 0) {
                    // console.log('parent', course.courseIdMaster, course.childCourses.length)
                    course.childCourses.forEach(child => {
                        //only courses, not Learning paths
                        if (isCompletedChildCourse(child)) {
                            updateMyStats(myStats, child);
                        }
                    });
                } else {
                    //only courses, not Learning paths
                    if (isCompletedCourse(course)) {
                        updateMyStats(myStats, course);
                    }
                }

            }
        }

        //Add co0 to stats
        const courses0 = getState().course.courses0;
        if (courses0 && courses0.length > 0) {
            courses0.forEach(course0 => {
                if (isCompletedCourse(course0)) {
                    updateMyStats(myStats, course0);
                }
            });
        }

        dispatch({
            type: actionTypes.GET_MY_STATS,
            myStats: myStats
        });
    }
}

const updateMyStats = (myStats, course) => {
    myStats.myCredits += course.userCredits;
    if (course.duration) {
        myStats.myTimeTraining += +course.duration;
    }

    if (isVirtualClassroom(course)) {
        myStats.myCompletedClassrooms++;
    } else {
        myStats.myCompletedOnline++;
    }

}

const mockGetMyTeamDetail = async (user?) => {
    let filename = '/data/my_team/report_manager_detail.json';
    if (user) {
        filename = '/data/my_team/report_manager_detail.' + user + '.json';
    }
    const mock = await fetch(filename);
    const mockJson: UserCompletionDetail[] = await mock.json();
    return promiseMock({ data: mockJson }, false, 3000);
}


export const getMyTeamDetail = (
    users: string[] = [],
    successCallback?: (userDetails: UserCompletionDetail[]) => void,
    errorCallback?: () => void,
    finallyCallback?: () => void,
) => {
    return (dispatch, getState) => {
        dispatch(request());

        let url = urlList.GET_REPORT_MANAGER_DETAIL;

        if (users?.length > 0) {
            if (typeof users === 'string') {
                users = [users];
            }

            url += '?users=' + users.join(',');
        }


        const mock = false;
        return (
            (mock
                ?
                mockGetMyTeamDetail(users?.length > 0 ? users[0] : undefined)
                :
                axios.get(url, {
                    headers: getHeaders()
                })
            ).then(response => {
                let userDetails: UserCompletionDetail[] = response.data;

                if (successCallback) {
                    successCallback(userDetails);
                }
            }).catch(err => {
                dispatch(showError(err));

                if (errorCallback) {
                    errorCallback();
                }
            }).finally(() => {
                dispatch(end());

                if (finallyCallback) {
                    finallyCallback();
                }
            })
        )
    }

    function request() {
        return {
            type: actionTypes.GET_MY_TEAM_DETAIL_REQUEST
        };
    }

    function end() {
        return {
            type: actionTypes.GET_MY_TEAM_DETAIL_END,
        };
    }

};
