import React, {useEffect, useMemo, useRef} from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
export const initialPositionValues = ['left', 'right', 'top', 'bottom'];

export const animationSlideToTop = {
  initialPosition: 'bottom',
  initialY: '20px',
  initialX: '0',
  intersectionObserver: true,
  delay: 0,
  useSectionTagHtml: false,
};

export const animationSlideToTopStagger = {
  ...animationSlideToTop,
  intersectionObserver: false,
  isStagger: true,
};

const AnimationSlide = ({
  initialPosition = 'left',
  initialX = '40vw',
  initialY = '40vh',
  initialOpacity = 0,
  duration = 1,
  animationSpeed = 100,
  extraProps = {},
  className = '',
  intersectionObserver = false,
  isStagger = false,
  children,
  delay = 0,
  useSectionTagHtml = false,
  initialProps = {},
  enterProps = {},
  exitProps = {},
}) => {

  const { ref, inView } = useInView({});
  const intersectRef = useRef(false);

  useEffect(() => {
    if (inView) {
      intersectRef.current = true;
    }
    // Cleanup function on unmount
    return () => {
      intersectRef.current = false;
    };
  }, [inView]);

  const getInitialX = () => {
    switch (initialPosition) {
      case 'left':
        return '-' + initialX;
      case 'right':
        return initialX;
      default:
        return 0;
    }
  };

  const getInitialY = () => {
    switch (initialPosition) {
      case 'bottom':
        return initialY;
      case 'top':
        return '-' + initialY;
      default:
        return 0;
    }
  };

  const variants = useMemo(
    () => ({
      enter: {
        x: getInitialX(),
        y: getInitialY(),
        opacity: initialOpacity,
        ...initialProps,
      },
      center: {
        x: 0,
        y: 0,
        opacity: 1,
        ...enterProps,
      },
      exit: {
        x: getInitialX(),
        y: getInitialY(),
        opacity: initialOpacity,
        ...exitProps,
      },
    }),
    [initialPosition, initialX, initialY, initialOpacity, initialProps, enterProps, exitProps]
  );

  const transition = useMemo(
    () => ({
      x: { type: 'spring', stiffness: animationSpeed, damping: 30 },
      opacity: { duration: 0.2 },
      duration,
      delay,
    }),
    [animationSpeed, duration, delay]
  );

  const getAnimate = () => {
    if (isStagger) {
      return undefined;
    }
    if (inView || intersectRef.current || !intersectionObserver) {
      return 'center';
    }

    return 'enter';
  };

  const Wrapper = useSectionTagHtml ? motion.section : motion.div;

  return (
    <Wrapper
      variants={variants}
      animate={getAnimate()}
      transition={transition}
      initial="enter"
      exit="exit"
      className={'animation-slide ' + className}
      ref={ref}
      {...extraProps}
    >
      {children}
    </Wrapper>
  );
};

AnimationSlide.displayName = 'AnimationSlide';

export default AnimationSlide;
