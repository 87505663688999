 import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "@hooks/redux";
import ButtonV2 from "@components/UI/ButtonV2";
import CardController from "@components/CardV3";
import {USER_URLS} from "./link-utils";
import useGetEducationalPathsMenu from "@hooks/educationalPaths/useGetEducationalPathsMenu";
import {Course, CourseState, LangMap} from "@model/CoursesClass";
import useModalTypes from "@hooks/useModalByType";
import HeaderMenu from "./UI/HeaderMenu";
import {useDispatch} from "react-redux";
import {setAutoManageHistoryIframeOnClose, setShowModalByType} from "@redux-actions/";
import {purifyItemDataElementIdCamelCase} from "@utility/analytics-utils";
import {checkCourseState} from "@utility/CourseUtility";
import {getNextLessonWithinLP, isLearningPath, isVideoCourse} from "@utility/Api";
import { buildClickDataTrackingObject } from "@model/TrackingClass";
import camelCase from 'lodash/camelCase';

const EducationalPathsMenu = ({ show }: { show: boolean }) => {
  const lang = useSelector((state) => state.utils.lang);
  
  const modalTypes = useModalTypes();
  const history = useHistory();

  const handleClickViewAll = () => {
    closeModal();
    history.push(USER_URLS.EDUCATIONAL_PATHS.URL);
  };

  const closeModal = () => {
    modalTypes.close();
  };

  const [toStartCourses, toContinueCourses] = useGetEducationalPathsMenu();

  return (
    <HeaderMenu
      show={show}
      closeModal={closeModal}
      // MOVED ON MenuModalCollection.tsx
      // className={
      //   "educational-paths-menu" +
      //   (toContinueCourses.length > 0 ? " continue" : " start")
      // }
      firstColumn={
        <FirstColumn handleClickViewAll={handleClickViewAll} lang={lang} />
      }
      secondColumn={
        <SecondColumn courses={[toStartCourses, toContinueCourses]} />
      }
      thirdColumn={null}
    />
  );
};

const FirstColumn = ({
  lang,
  handleClickViewAll,
}: {
  lang: LangMap;
  handleClickViewAll: () => void;
}) => (
  <>
    {/* <div className="educational-paths-menu__section-1 educational-paths-menu__section"> */}
    <div className="educational-paths-menu__info">
      <h1 className="educational-paths-menu__title">
        {lang.PROGRAMS_MENU_TITLE}
      </h1>
      <p className="educational-paths-menu__descr">
        {lang.PROGRAMS_MENU_DESCR}
      </p>
    </div>
    <ButtonV2
      className="area-of-expertise__l0-button l0-button--selected educational-paths-menu__section-1__cta"
      variant="text-btn"
      handleClick={() =>{ buildClickDataTrackingObject("mainNav_"+camelCase(lang.EDUCATIONAL_PATHS_TITLE)+"_"+ camelCase(lang.PROGRAMS_MENU_CTA));handleClickViewAll()}}
      variantLongArrow
    >
      {lang.PROGRAMS_MENU_CTA}
    </ButtonV2>
    {/* </div> */}
  </>
);

const SecondColumn = ({
  courses,
}: {
  courses: ReturnType<typeof useGetEducationalPathsMenu>;
}) => {
  const [toStartCourses, toContinueCourses] = courses;
  const lang = useSelector((state) => state.utils.lang);

  return (
    // <div className="educational-paths-menu__section-2 educational-paths-menu__section">
    <div className="educational-paths-menu__section-2__content">
      <h1 className="educational-paths-menu__title">
        {toContinueCourses.length > 0
          ? lang.CONTINUE_LEARNING
          : lang.START_LEARNING}
      </h1>
      {toContinueCourses.length > 0 ? (
        <ToContinue courses={toContinueCourses} />
      ) : (
        <ToStart courses={toStartCourses} />
      )}
    </div>
    // </div>
  );
};

const ToStart = ({ courses }: { courses: Course[] }) => {
  return (
    <div className="flex">
      {courses.slice(0, 2).map((course) => (
        <CardController
          course={course}
          variant="card-evolution"
          noHoverEffect
          key={course.courseId}
        />
      ))}
    </div>
  );
};

const ToContinue = ({ courses }: { courses: Course[] }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const isModalOpen = useSelector(state=> state.utils.showIframe) ?? undefined
  const [courseOpened, setCourseOpened] = useState<Course>(null)
  const coursesMap = useSelector(state=>state.course.coursesMap)
  const userProfile = useSelector(state=>state.user.userProfile)
  const autoManageHistoryIframeOnClose: boolean = useSelector(state => state.utils.autoManageHistoryIframeOnClose);
  const lang = useSelector(state => state.utils.lang);



  const handleClick = (course: Course) => {
    buildClickDataTrackingObject("mainNav_"+camelCase(lang.EDUCATIONAL_PATHS_TITLE)+"_video","text")
    setCourseOpened(course)
    dispatch(setAutoManageHistoryIframeOnClose(false))
    dispatch(setShowModalByType(null))
  }

  /**
   * Preconditions
   * - L'utente ha in stato 'in progress' un program accessibile nella sezione Certificate Programs.
   * - Il prossimo contenuto del program (o il contenuto che l'utente sta fruendo) è una video lesson.
   * @see https://luxotticaretail.atlassian.net/browse/LEON-4411
   * */
  const setHistoryToProgramPage = ()=>{
    const urlLPPage = USER_URLS.ONBOARDING_COURSE_ID.URL.replace(":id",courseOpened?.courseIdMaster)

    const nextLesson = getNextLessonWithinLP(courseOpened, coursesMap, userProfile) ?? null

    if(history.location.pathname.includes(urlLPPage)) return

    const precondition1 = checkCourseState(courseOpened, CourseState.IN_PROGRESS)
    let precondition2 = isVideoCourse(courseOpened) || isLearningPath(courseOpened)
    if(nextLesson){
      precondition2 = precondition2  || isVideoCourse(nextLesson)
    }

    //<editor-fold desc="EducationalPathsMenu.tsx > setHistoryToProgramPage - line 156 at 10/01/2024 11:56:14">
    console.groupCollapsed('EducationalPathsMenu.tsx > setHistoryToProgramPage - line 156 at 10/01/2024 11:56:14');
    console.log("precondition1",precondition1);
    console.log("precondition2",precondition2);
    console.groupEnd();
    //</editor-fold>

    if( precondition1 && precondition2) {
      history.push(urlLPPage)
    }
  }

  useEffect(function onOpenModal() {
    if(isModalOpen && courseOpened) setHistoryToProgramPage()
  }, [isModalOpen, courseOpened]);

  return (
    <div className="flex">
      {courses.slice(0, 1).map((course) => (
        <CardController
          course={course}
          variant="card-large-evolution"
          key={course.courseId}
          postAction={()=>{handleClick(course)}}
          onClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang.EDUCATIONAL_PATHS_TITLE)+"_video","video")}}
        />
      ))}
    </div>
  );
};

export default EducationalPathsMenu;
