import {UserGroup, USERGROUP_ACTIVE_VALUES} from "@model/User";
import { UserProfile } from "@model/User";
const axios = require('axios');
import { getHeaders } from '@utility/Api';
import { useDispatch } from 'react-redux';
import {
	setShowLabelPersonificationBanner,
	setUserSimulated
} from '@redux-actions/';
import { PERSONIFICATION_COOKIE_KEYS } from '@utility/const';

import { getCookie } from '@utility/cookie';
import {PERSONIFICATION_URLS} from "../../config";
import {EUserGroup} from "./UserGroupSimulationLogic";
import {PUBLIC_URLS, USER_URLS} from "@components/link-utils";


const isUserEnabledToPersonificate = (userProfile: UserProfile): boolean => {
	return userProfile?.simulationStatus === USERGROUP_ACTIVE_VALUES.ENABLED;
}

const isPersonificationOn = (): boolean => {
	return getCookie(PERSONIFICATION_COOKIE_KEYS.inSimulation, true) as boolean
}

const isUserPersonificated = (user: UserGroup): boolean => {
	// debugger
	return user?.[EUserGroup.inUse] || false
}

const useStartPersonificate = (user: UserGroup) => {
	const dispatch = useDispatch();
	const data = {"username": String(user.username)}
	const params = new URLSearchParams(data)

	const personificate = (
		requestCallback?: (b: boolean) => void,
		successCallback?: (res: boolean) => void,
		onRequestDone?: (b: boolean) => void,
		errorCallback?: () => void
	) => {

		if (requestCallback) {
			requestCallback(true);
		}

		axios.post(`${PERSONIFICATION_URLS.URL_SET_PERSONIFICATE_USER_ON}?${params}`,{ headers: getHeaders() })
			.then(response => {
				// const users = response.data.simulableUsers
				location.reload()
				// if (successCallback) {
				// 	location.reload()
				// 	// successCallback(response.data);
				// }
				

			}).catch((error) => {
			console.error('Error on start personificate user', error);
			if (errorCallback) {
				errorCallback();
			}
		}).finally(_ => {
				onRequestDone(false)
		});
	}

	return {
		startPersonification: personificate
	}
}

const useStopPersonificate = () => {

	const personificate = (
		requestCallback?: (b: boolean) => void,
		successCallback?: (res: boolean) => void,
		onRequestDone?: (b: boolean) => void,
		errorCallback?: () => void
	) => {

		if (requestCallback) {
			requestCallback(true);
		}

		axios.post(PERSONIFICATION_URLS.URL_SET_PERSONIFICATE_USER_OFF, { headers: getHeaders() })
			.then(response => {
				localStorage.setItem('exitBannerWasClicked',"true")

				//LEON-4997 redirect to UserGroupSimulation page before reload page
				history.replaceState(null, "", USER_URLS.USER_GROUP_SIMULATION.URL);
				location.reload()
			}).catch((error) => {
			console.error('Error on stop personificate user', error);
			//debugger
			if (errorCallback) {
				errorCallback();
			}
		}).finally(_ => {
			//debugger
				onRequestDone(false)
		});
	}

	return {
		stopPersonification: personificate
	}
}


export const stopPersonification = () => {

	axios.post(PERSONIFICATION_URLS.URL_SET_PERSONIFICATE_USER_OFF, { headers: getHeaders() })
			.then(response => {
				location.reload()
			}).catch((error) => {
			console.error('Error on stop personificate user', error);			
		})
}



export {
	isPersonificationOn, isUserEnabledToPersonificate, isUserPersonificated,
	useStartPersonificate, useStopPersonificate}