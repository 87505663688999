import { SKELETON_COLORS } from "@utility/const";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";

const heightDesktop = 112;
const maxWidthBig = 1130;
const maxWidthSmall = 784;

const CardActivitySkeleton = (props) => {

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

  const marginDesktop = vw - (vw >= 1440 ? maxWidthBig : maxWidthSmall);
  const marginMobile = vw - maxWidthSmall;
  const widthDesktop = vw - (marginDesktop > 322 ? marginDesktop : 322);
  const widthMobile = vw - (marginMobile > 40 ? marginMobile : 40);
  const height = marginMobile > 0 ? heightDesktop : heightDesktop + (Math.floor((marginMobile / 6)) * -1);
  const breakpoint = useBreakpointBoolean();

  const width = breakpoint.isDesktop ? widthDesktop : widthMobile;


  return (
    <div
      style={{ width: `${width}px`, height: `${height}px`, overflow: "hidden" }}
      className="card-activity__skeleton"
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect x="0" y="0" rx="11" ry="11" width={width} height={height} />
      </ContentLoader>
    </div>
  );
};

export default CardActivitySkeleton;
