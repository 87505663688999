import { defaultBreakpoints, useBreakpoint } from "@hooks/createBreakpoint";
import { SKELETON_COLORS } from "@utility/const"
import React, { useEffect, useState } from "react"
import ContentLoader from "react-content-loader";

const widthMobile = 295;
const heightMobile = 87;
const widthDesktop = 501;
const heightDesktop = 181;

const CardUpcomingEventsSkeleton = (props) => {
  const breakpoint = useBreakpoint();

  const [width, setWidth] = useState<number>(widthDesktop);
  const [height, setHeight] = useState<number>(heightDesktop);

  useEffect(() => {
    if (breakpoint === defaultBreakpoints.desktop) {
      setWidth(widthDesktop);
      setHeight(heightDesktop);
    } else {
      setWidth(widthMobile);
      setHeight(heightMobile);
    }
  }, [breakpoint]);

  return (
    <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden', margin: '0', maxWidth: '100%', flexShrink: 0 }}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect className="mobile" x="0" y="0" rx="6" ry="6" width="119" height="67" />
        <rect className="mobile" x="0" y="75" rx="6" ry="6" width="119" height="12" />
        <rect className="mobile" x="135" y="4" rx="6" ry="6" width="100" height="12" />
        <rect className="mobile" x="135" y="25" rx="6" ry="6" width="120" height="12" />
        <rect className="mobile" x="135" y="46" rx="8" ry="8" width="160" height="14" />
        <rect className="mobile" x="135" y="66" rx="8" ry="8" width="160" height="14" />

        <rect className="desktop" x="0" y="0" rx="6" ry="6" width="225" height="143" />
        <rect className="desktop" x="0" y="159" rx="6" ry="6" width="225" height="18" />
        <rect className="desktop" x="231" y="4" rx="6" ry="6" width="150" height="18" />
        <rect className="desktop" x="231" y="32" rx="6" ry="6" width="180" height="18" />
        <rect className="desktop" x="231" y="66" rx="8" ry="8" width="240" height="20" />
        <rect className="desktop" x="231" y="96" rx="8" ry="8" width="240" height="20" />
      </ContentLoader>
    </div>
  )
}

export default CardUpcomingEventsSkeleton;