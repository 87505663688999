import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@hooks/redux";
import { TealiumEvents } from "@model/TrackingClass";
import { toFixedNumString } from "@utility/ecommerceUtility";
import { getSubscriptionList, setActiveRenewalPlan, showError } from "@redux-actions/";
import ModalEvolution from "./ModalEvolution";

type ModalStopRenewalConfirmationProps = {
  show: boolean;
  close: () => void;
  data: { expirationDate: string, orderId: string, subscriptionId: string };
};

const ModalStopRenewalConfirmation = ({
  show,
  close,
  data,
}: ModalStopRenewalConfirmationProps) => {
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
  // since only one subscription use the first one
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const confirm = () => {
    // @todo check
    // window?.utag?.link({
    //   id: TealiumEvents.renewal_off,
    //   Products_Category_Array: ["plan"],
    //   product_subcategory: [""],
    //   Products_Id_Array: [plan.id+""],
    //   Products_ModelName_Array: [lang?.UNLIMITED_ACCESS_PLAN || plan.name],
    //   product_format: ["plan"],
    //   Products_Mandatory_Array: ['N'],
    //   Products_Units_Array: ["1"],
    //   Products_PriceFull_Array: [toFixedNumString(plan?.price || 0)],
    //   Products_RevenuePerUnit_Array: [toFixedNumString(plan?.price || 0)],
    //   licence_destination: ["me"],
    //   Order_CartId: cart.orderId,
    //   Page_DeviceType: "web",
    //   Order_Currency: "USD",
    // });

    //ENABLE AUTORENEWAL
    setIsLoading(true);
    setActiveRenewalPlan(data?.orderId, data?.subscriptionId, false).then(res => {
      dispatch(getSubscriptionList());
      setIsLoading(false);
      close();
    }).catch(err => {
      setIsLoading(false);
      dispatch(showError());
    });
    // dispatch();
  };

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="stop-renewal-confirmation"
      title={lang?.MODAL_RENEWAL_CONFIRMATION_TITLE}
      description={lang?.MODAL_RENEWAL_CONFIRMATION_DESCR?.replace('{DATE}', data?.expirationDate)}
      mainButtonLabel={lang?.CONFIRM}
      mainButtonAction={confirm}
      mainButtonIsLoading={isLoading}
      secondaryButtonLabel={lang?.CANCEL}
      secondaryButtonAction={close}
    />
  );
};

export default ModalStopRenewalConfirmation;
