import { CoursesMap, LangMap, LangMapEcomm } from "@model/CoursesClass";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { ProductCart } from "@model/CartModel";
import { ADD_TO_CART_STEPS } from "@utility/const";
import { useSelector } from "@hooks/redux";
import { getParentCourse, isLearningPath } from "@utility/Api";
import ModalEvolution from "@components/UI/ModalEvolution";

type ModalCourseAlreadyPlanProps = {
  show: boolean;
  close: () => void;
};

const ModalCourseAlreadyPlan = ({
  show,
  close,
}: ModalCourseAlreadyPlanProps) => {
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);
  const product: ProductCart = useSelector((state) => state.ecommerce.productModalCourseAlreadyPlan);

  const [isCourse, setIsCourse] = useState<boolean>(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const entireCourse = getParentCourse(product.partNumber, coursesMap);

    setIsCourse(isLearningPath(entireCourse))
  }, [product, coursesMap]);

  const handleBuyCourse = () => {
    close();

    //proceed to add to cart
    dispatch(actions.insertProductInCart(product, ADD_TO_CART_STEPS.LESSON_IN_PLAN));
  };

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="only-1-month"
      icon={'/images/svg-icons/warning.svg'}
      title={isCourse ? lang?.HAVE_ADD_COURSE_ALREADY_IN_SUBSCRIPTION : lang?.HAVE_ADD_LESSON_ALREADY_IN_SUBSCRIPTION}
      description={isCourse ? lang?.HAVE_ADD_COURSE_ALREADY_IN_SUBSCRIPTION_DESCR : lang?.HAVE_ADD_LESSON_ALREADY_IN_SUBSCRIPTION_DESCR}
      mainButtonLabel={lang?.CANCEL}
      mainButtonAction={close}
      secondaryButtonLabel={isCourse ? lang?.BUY_COURSE_LABEL : lang?.BUY_LESSON_LABEL}
      secondaryButtonAction={handleBuyCourse}
    />
  );
};

export default ModalCourseAlreadyPlan;
