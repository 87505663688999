import { CourseToFilterMap, FilterEnableMap, FilterMap, FilterToCourseMap } from "../model/FilterMap";
import * as cloneDeep from 'lodash/cloneDeep';
import { initialOtherFilters } from '@components/FiltersV3';

// const FILTER_FIELDS = Object.keys(initialOtherFilters);// ['brand', 'ctype', 'language', 'status', 'assignment', 'section', 'duration'];

export const getFilterEnableMap = (filterToCourseMap: FilterToCourseMap, defaultValue: boolean): FilterEnableMap => {
    if (!filterToCourseMap) {
        return null;
    }

    let filterEnableMap: FilterEnableMap = cloneDeep(filterToCourseMap);

    Object.keys(initialOtherFilters).forEach(field => {
        if (filterEnableMap[field]) {
            for (const fieldValue in filterEnableMap[field]) {
                filterEnableMap[field][fieldValue] = defaultValue;
            }
        }
    });

    return filterEnableMap;
}

//put in AND each value of the 2 FilterEnableMaps
export const mergeANDFilterEnableMap = (map1: FilterEnableMap, map2: FilterEnableMap): FilterEnableMap => {
    if (!map1 || !map2) {
        return null;
    }

    let filterEnableMap: FilterEnableMap = cloneDeep(map1);

    Object.keys(initialOtherFilters).forEach(field => {
        if (filterEnableMap[field]) {
            for (const fieldValue in filterEnableMap[field]) {
                filterEnableMap[field][fieldValue] = filterEnableMap[field][fieldValue] && map2[field][fieldValue];
            }
        }
    });

    return filterEnableMap;
}

export const enableAppliedFilterInMap = (appliedFilters, globalFilterEnableMap: FilterEnableMap): FilterMap => {
    for (const appliedFilterField in appliedFilters) {
        //enable all selected filters
        appliedFilters[appliedFilterField].forEach(value => {
            globalFilterEnableMap[appliedFilterField][value] = true;
        });
    }

    return globalFilterEnableMap;
}

export const createFilterEnableMap = (appliedFilters, filterToCourseMap: FilterToCourseMap, courseToFilterMap: CourseToFilterMap) => {
    //at beginning all filters are enabled in the globalFilterEnableMap
    //the globalFilterEnableMap contains the filters to enable/disable for all applied filters
    let globalFilterEnableMap = getFilterEnableMap(filterToCourseMap, true);

    //get filtered courses but not by a single field
    let considerCoursesArray = {};
    Object.keys(initialOtherFilters).forEach(field => {
        for (const appliedFilterField2 in appliedFilters) {
            if (appliedFilterField2 !== field) {
                //for every selected filter type
                //get courses filtered by a type
                let considerCoursesArrayType = [];
                appliedFilters[appliedFilterField2].forEach(appliedFilterValue2 => {
                    Object.keys(filterToCourseMap[appliedFilterField2][appliedFilterValue2] || []).forEach(a => {
                        if (!considerCoursesArrayType.includes(a)) {
                            considerCoursesArrayType.push(a);
                        }
                    })
                });

                //intersect courses filtered by a type with courses filtered by other types
                if (!considerCoursesArray[field] || considerCoursesArray[field].length === 0) {
                    considerCoursesArray[field] = considerCoursesArrayType;
                } else {
                    considerCoursesArray[field] = intersection(considerCoursesArray[field], considerCoursesArrayType);
                }
            }
        }
    });

    for (const appliedFilterField in appliedFilters) {
        //for every selected filter
        //the localFilterEnableMap has all filters disabled
        //the localFilterEnableMap contains the filters to enable/disable for the courses matching an applied filter
        let localFilterEnableMap: FilterMap = getFilterEnableMap(filterToCourseMap, false);

        //for every selected filter
        appliedFilters[appliedFilterField].forEach(appliedFilterValue => {
            //for every course that match the filter
            for (const courseId in filterToCourseMap[appliedFilterField][appliedFilterValue]) {
                //for every group of filters
                Object.keys(initialOtherFilters).forEach(field => {
                    if (appliedFilterField !== field) {
                        // consider only filtered courses
                        const isCourseAbsent = considerCoursesArray[field].findIndex(a => a === courseId) < 0;
                        if (isCourseAbsent) {
                            return;
                        }

                        //if there is a course that match a filter --> enable it
                        for (const fieldValue in courseToFilterMap[courseId][field]) {
                            localFilterEnableMap[field][fieldValue] = localFilterEnableMap[field][fieldValue] || (courseToFilterMap[courseId][field][fieldValue] ? true : false);
                        }
                    } else {
                        //a filter enables every filter within its same group
                        for (const fieldValue in localFilterEnableMap[field]) {
                            localFilterEnableMap[field][fieldValue] = true;
                        }
                    }
                });
            }
        });

        //put all values in AND between the global and the local filter enable maps
        globalFilterEnableMap = mergeANDFilterEnableMap(globalFilterEnableMap, localFilterEnableMap);
    }

    //the selected filters cannot be disabled
    globalFilterEnableMap = enableAppliedFilterInMap(appliedFilters, globalFilterEnableMap);
    // console.log('globalFilterEnableMap', globalFilterEnableMap)
    return globalFilterEnableMap;
}

export const updateFilterMaps = (courseToFilterMap: CourseToFilterMap, filterToCourseMap: FilterToCourseMap, courseId: string, filterType: string, filterValue: any) => {
    updateCourseToFilterMap(courseToFilterMap, courseId, filterType, filterValue);
    updateFilterToCourseMap(filterToCourseMap, filterType, filterValue, courseId);
}

const updateCourseToFilterMap = (filterToCourseMap: CourseToFilterMap, courseId: string, filterType: string, filterValue: string) => {
    if (!filterToCourseMap[courseId]) {
        filterToCourseMap[courseId] = new FilterEnableMap;
    }

    if (!filterToCourseMap[courseId][filterType]) {
        filterToCourseMap[courseId][filterType] = {};
    }

    filterToCourseMap[courseId][filterType][filterValue] = true;
}

const updateFilterToCourseMap = (filterToCourseMap: FilterToCourseMap, filterType: string, filterValue: string, courseId: string) => {
    if (!filterToCourseMap[filterType]) {
        filterToCourseMap[filterType] = {};
    }

    if (!filterToCourseMap[filterType][filterValue]) {
        filterToCourseMap[filterType][filterValue] = {};
    }

    filterToCourseMap[filterType][filterValue][courseId] = true;
}