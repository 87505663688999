import { SurveyResponse } from "./SurveyResponse";

export enum SURVEY_QUESTION_TYPE {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  TIMEZONE_LANG = 'TIMEZONE_LANG',
  FREE_TEXT = 'FREE_TEXT'
}

export enum SURVEY_TYPE {
  PERSONALIZATION = 'PROFILE_TYPE',
  RATING = 'COURSE_RATING',
  RATING_LP = 'LP_RATING',
  RATING_LIVESTREAM = 'STREAM_RATING',
  REVIEW = 'COURSE_TYPE',
  REVIEW_LP = 'LP_TYPE',
  REVIEW_LIVESTREAM = 'STREAM_TYPE',
}

export type SurveyQuestionResponse = {
  description: string;
  id: number;
  questionId: number;
  responseInLanguage: string;
}

export class SurveyQuestion {
  description: string;
  id: number;
  questionInLanguage: string;
  questionOrder: number;
  questionType: SURVEY_QUESTION_TYPE;
  responses: Array<SurveyQuestionResponse>;
  surveyId: string;
}

export type Survey = {
  creationDate: string;
  descInLanguage: string;
  description: string;
  id: number;
  questions: Array<SurveyQuestion>;
  surveyType: string;
}

export type SurveyQA = {
  survey: Survey;
  surveyResponses: Array<SurveyResponse>
}