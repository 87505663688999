import { defaultBreakpoints, useBreakpoint } from "@hooks/createBreakpoint";
import { SKELETON_COLORS } from "@utility/const";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

const widthDesktop = 451;
const heightDesktop = 168.5;
const widthMobile = '100%';
const heightMobile = 144;

const ShipmentStatsSkeleton = (props) => {
  const breakpoint = useBreakpoint();

  const [width, setWidth] = useState<string | number>(widthDesktop);
  const [height, setHeight] = useState<string | number>(widthDesktop);

  useEffect(() => {
    if (breakpoint === defaultBreakpoints.desktop) {
      setWidth(widthDesktop);
      setHeight(heightDesktop);
    } else {
      setWidth(widthMobile);
      setHeight(heightMobile);
    }
  }, [breakpoint]);

  return (
    <div
      className="shipment-stats__skeleton"
      style={{ width: `${width}px`, height: `${height}px`, overflow: "hidden" }}
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMin"
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect x="0" y="0" rx="13" ry="13" width={width} height={height} />

        {/* DESKTOP
        <rect className="desktop" x="16" y="16" rx="0" ry="0" width="184" height="20" />
        <rect className="desktop" x="16" y="52" rx="0" ry="0" width="184" height="20" />
        <rect className="desktop" x="16" y="84" rx="0" ry="0" width="184" height="20" />

        {/* MOBILE
        <rect className="mobile" x="16" y="16" rx="0" ry="0" width="184" height="16" />
        <rect className="mobile" x="16" y="48" rx="0" ry="0" width="184" height="16" />
        <rect className="mobile" x="16" y="80" rx="0" ry="0" width="184" height="16" /> */}

      </ContentLoader>
    </div>
  );
};

export default ShipmentStatsSkeleton;
