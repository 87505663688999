import '@css/partials_v3/_story-modal.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import ModalEvolution from '../ModalEvolution';
import { openTrainingPillDetailModal, setStoryModalInfo } from '@redux-actions/';
import LazySVG from '@components/LazySvg';
import CardCover from '@components/CardV3/CardCover';
import { handleOnEnterKeyPress } from '@utility/Api';
import { useHistory } from 'react-router-dom';
import HelmetMetaTags from '@components/HelmetMetaTags';
import CloseIconModalRight from '../CloseIconModalRight';
import {
  FALLBACK_IMAGE_TP_TRANSPARENT_URL,
  FALLBACK_IMAGE_TP_URL,
} from '@utility/TrainingPillsUtility';

type StoryModalProps = {};

const StoryModal = ({}: StoryModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(state => state.utils.lang);

  const info = useSelector(state => state.trainingPills.storyModalInfo);
  const story = useSelector(state => state.trainingPills.storyModalInfo?.story);

  const close = () => {
    dispatch(setStoryModalInfo(false));
    history.goBack();
  };

  const handlePillClick = pill => {
    dispatch(openTrainingPillDetailModal(true, pill.code));
  };

  return (
    <ModalEvolution
      close={close}
      show={info.show}
      modalClassName="story-modal"
      clickToClose={true}
      hideX={true}
      descriptionElement={
        <section
          className="story-modal__content"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <CloseIconModalRight
            className="story-modal__close-icon"
            onClick={close}
            ariaLabel={lang?.CLOSE_MODAL}
            squareButtonMobile={true}
          />
          {story && (
            <>
              <HelmetMetaTags title={story.name} useTitleFormatLeonardo={true} />

              <div className="story-modal__image">
                <CardCover image={story.urlImage} courseFullName={story.name} hideShadow={true} />
              </div>
              <div className="story-modal__info">
                <h3 className="story-modal__title">{story.name}</h3>
                {story.description && (
                  <span className="story-modal__description">{story.description}</span>
                )}
                <div className="story-modal__pills-scroller-container">
                  <div className="story-modal__pills-grid">
                    {story.novelties.map(pill => (
                      <section
                        key={pill.code}
                        className="story-modal__pill-card focus-outline"
                        onClick={() => handlePillClick(pill)}
                        onKeyDown={event =>
                          handleOnEnterKeyPress(event, () => handlePillClick(pill), true)
                        }
                        tabIndex={0}
                      >
                        <div className="story-modal__pill-image-container">
                          <CardCover
                            className="story-modal__pill-image"
                            image={pill.urlImage}
                            fallbackImage={
                              FALLBACK_IMAGE_TP_TRANSPARENT_URL +
                              '&impolicy=Card&cropnorth=350&cropsouth=350&cropeast=300&cropwest=300'
                            }
                            courseFullName={pill.code}
                            hideSpinner
                            hideShadow
                          />
                        </div>
                        <span className="story-modal__pill-model">{pill.code}</span>
                        <span className="story-modal__pill-name">{pill.modelName}</span>
                      </section>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      }
    />
  );
};

export default StoryModal;
