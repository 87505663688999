import { useEffect, useState } from 'react';
import { useSelector } from './redux';
import { isCourseMaster, isMasterPurchasable } from '@utility/onBoardingCourseUtility';
import { PromoWithCode } from '@model/CartModel';
import { Course } from '@model/CoursesClass';
import useCartItems from './useCartItems';

class useCartClass {
  orderId: string = '';
  items: any[] = [];
  isAnyCourseExpired: boolean = false;
  showErrorBuyPlan: boolean = false;
  hasPlans: boolean = false;
  numItems: number = 0;
  grandTotal: string | number = 0;
  grandTotalCurrency: string = 'USD';
  totalProductPrice: string | number = 0;
  totalProductCurrency: string = 'USD';
  promoCodes: PromoWithCode[] = [];
  totalPromoCodes: string | number = 0;
  totalPromoCodesCurrency: string = 'USD';
  totalTaxes: string | number = 0;
  totalTaxesCurrency: string = 'USD';
  grandTotalNoTaxes: string | number = 0;
  masterOverSellability: Course[] = [];
  masterNotEnoughSeats: Course[] = [];
  errorType:
    | 'isAnyCourseExpired'
    | 'showErrorBuyPlan'
    | 'masterOverSellability'
    | 'masterNotEnoughSeats' = null;
  errorLabel: string = '';
}

const useCart = (): useCartClass => {
  const cart = useSelector(state => state.ecommerce.cart);
  const orderItems = cart?.orderItem || [];
  const cartItems = useCartItems(orderItems);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const lang = useSelector(state => state.utils.lang);
  const userProfile = useSelector((state) => state.user.userProfile);

  const [result, setResult] = useState<useCartClass>(new useCartClass());

  useEffect(() => {
    const grandTotal = cart?.grandTotal || 0;
    const totalProductPrice = cart?.totalProductPrice || 0;

    const isAnyCourseExpired: boolean = cartItems?.some(cartItem => cartItem.isExpired);

    const plans = cartItems?.filter(cartItem => cartItem?.isPlan);
    const hasPlans = plans?.length > 0;
    const showErrorBuyPlan: boolean =
      plans.length > 1 || (plans.length > 0 && cartItems.length > plans.length);

    const numItems: number = cartItems.length;

    //show only promo set manually by the user
    let promoCodes: PromoWithCode[] = cart?.adjustment || [];
    cart?.promotionCode?.forEach(promotionCode => {
      promotionCode.associatedPromotion?.forEach(associatedPromotion => {
        promoCodes.forEach(promo => {
          if (promo.code.endsWith(associatedPromotion.promotionId)) {
            promo.promotionCode = promotionCode.code;
          }
        });
      });
    });

    promoCodes = promoCodes.filter(a => !!a.promotionCode);

    //check master purchasability
    const masterOverSellability = [];
    const masterNotEnoughSeats: Course[] = [];
    cartItems.forEach(cartItem => {
      const course = coursesMap && coursesMap[cartItem.id];
      if (isCourseMaster(course) && !isMasterPurchasable(course, false)) {
        //master sellability period is ended
        masterOverSellability.push(course);
      }

      if (
        isCourseMaster(course) &&
        +cartItem.quantity > course.masterAttributes?.availableSubscription
      ) {
        //not enough available seats
        masterNotEnoughSeats.push(course);
      }
    });

    let errorType = null;
    let errorLabel = '';
    //decide error to display
    if (isAnyCourseExpired) {
      errorType = 'isAnyCourseExpired';
      errorLabel = lang?.ERROR_CART_ITEM_EXPIRED;
    } else if (showErrorBuyPlan) {
      errorType = 'showErrorBuyPlan';
      errorLabel = lang?.ERROR_CART_ITEMS_WITH_PLAN;
    } else if (masterOverSellability?.length > 0) {
      errorType = 'masterOverSellability';
      errorLabel = lang?.CART_ERROR_MASTER_OUT_SELLABILITY?.replace(
        '{COURSENAME}',
        masterOverSellability[0].courseFullName?.toUpperCase()
      );
    } else if (masterNotEnoughSeats?.length > 0) {
      errorType = 'masterNotEnoughSeats';
      errorLabel = lang?.CART_ERROR_MASTER_NOT_ENOUGH_SEATS?.replace(
        '{COURSENAME}',
        masterNotEnoughSeats[0].courseFullName?.toUpperCase()
      )?.replace(
        '{NUM}',
        masterNotEnoughSeats[0].masterAttributes?.availableSubscription?.toString()
      );
    }

    setResult({
      orderId: cart?.orderId,
      items: cartItems,
      isAnyCourseExpired,
      showErrorBuyPlan,
      hasPlans,
      numItems,
      grandTotal,
      grandTotalCurrency: cart?.grandTotalCurrency || userProfile?.ecommerceInfo?.currency,
      totalProductPrice,
      totalProductCurrency: cart?.totalProductPriceCurrency || userProfile?.ecommerceInfo?.currency,
      promoCodes: promoCodes,
      totalPromoCodes: cart?.totalAdjustment || 0,
      totalPromoCodesCurrency: cart?.totalAdjustmentCurrency || userProfile?.ecommerceInfo?.currency,
      totalTaxes: cart?.totalSalesTax || 0,
      totalTaxesCurrency: cart?.totalSalesTaxCurrency || userProfile?.ecommerceInfo?.currency,
      grandTotalNoTaxes: +grandTotal - +cart?.totalSalesTax,
      masterOverSellability,
      masterNotEnoughSeats,
      errorType,
      errorLabel,
    });
  }, [cart, JSON.stringify(cartItems), coursesMap, lang]);

  return result;
};
export default useCart;
