import { useEffect, useState } from 'react';
import { useBreakpointBoolean } from './createBreakpoint';

export const filterTopicsMaxHeightBreakpoint = {
  desktop: 46 * 2,
  tablet: {
    portrait: 46 * 3,
    landscape: 46 * 2,
  },
  phone: 46 * 4,
};

const useFilterTopicsMaxHeight = (): number => {
  const breakpoint = useBreakpointBoolean();
  const [topicsMaxHeight, setTopicsMaxHeight] = useState(0);

  useEffect(() => {
    let topicsMaxHeightTemp = filterTopicsMaxHeightBreakpoint.desktop;
    if (breakpoint.isTablet && breakpoint.isPortrait) {
      topicsMaxHeightTemp = filterTopicsMaxHeightBreakpoint.tablet.portrait;
    } else if (breakpoint.isTablet && !breakpoint.isPortrait) {
      topicsMaxHeightTemp = filterTopicsMaxHeightBreakpoint.tablet.landscape;
    } else if (breakpoint.isPhone) {
      topicsMaxHeightTemp = filterTopicsMaxHeightBreakpoint.phone;
    }

    setTopicsMaxHeight(topicsMaxHeightTemp);
  }, [breakpoint]);

  return topicsMaxHeight;
};

export default useFilterTopicsMaxHeight;
