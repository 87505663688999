export default {
  PREVIOUS_CHIP_LIST: "Previous page chip list",
  NEXT_CHIP_LIST: "Next page chip list",
  GO_BACK: "Go back",
  OPEN_FILTERS_MODAL: "Open filters modal",
  SKIP_MAIN_CONTENT: "Skip to main content",
  TOGGLE_NOTIFICATION_VISIBILITY: "Toggle notification visibility",
  OPEN_SEARCH: "Open search",
  SELECT_LANGUAGE_SECTION: "Select language section",
  SELECT_LANGUAGE_SELECT: "Select your language",
  PREVIOUS: "Previous",
  NEXT: "Next",
  CLOSE_POPUP: "Close popup",
  SHOW_MODAL_ALL_CHANNELS: "Show modal all channels",
  APP_PREVIEW_VIDEO: "App preview video",
  OPEN_CHANNEL: "Open {name} channel",
  CLOSE_NAVIGATION_MENU: "Close navigation menu",
  OPEN_NAVIGATION_MENU: "Open navigation menu",
  ADVERTISING_CAMPAIGN_VIDEO: "Advertising Campaign Video",
  SHOW_INFO_USER: "Show more about {user}",
  COLLAPSE_ACCORDION: "Collapse accordion",
  EXPAND_ACCORDION: "Expand accordion",
  ACCORDION_EXPANDED: "Accordion expanded",
  ACCORDION_COLLAPSED: "Accordion collapsed",
  GO_TO_CHANNEL: "Go to {name} channel",
  GO_TO_PAGE_CAROUSEL: `Go to page {num} carousel`,
  PAGING_CAROUSEL: "Carousel pagination",
  RESULTS_NUMBER: "Results number",
  CLOSE_MODAL: "Close modal",
  MENU: "menu", // not necessary
  PLAY_AUDIO: "Play video", // only public?
  PAUSE_AUDIO: "Pause video", // only public?
  // only public
  WHO_YOU_CONTACT: "Who you should contact",
  GO_TO_LOGIN: "Go to login page",
  LEONARDO_MANIFEST_VIDEO: "Leonardo manifest video",
  PLAY_LIVESTREAM: "Play livestream",
  SHOW_OVERVIEW_ITEM: "Show overview {name}",
  GO_TO_SERVICE_PAGE: "Go to service page",
  DESCRIPTION_IMAGES: "Description images",
  LOADING: "Loading",
  ADD_1_LICENSE: "Add one license",
  REMOVE_1_LICENSE: "Remove one license",
  REMOVE_ITEM_FROM_CART: "Remove item from cart",
  OPEN_CART: "Open cart",
  WCAG_SHOW_MODAL_ALL_CHANNELS: "Show all channels",
  WCAG_NUMBER_RESULTS: "Number of results",
  CHECKOUT_STEPS: "Checkout steps",
} as const
