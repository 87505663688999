import { CartModel, OrderHistory, PaymentInstruction, ProductCart } from '@model/CartModel';
import { Course, CoursesMap, LangMap, LangMapEcomm } from '@model/CoursesClass';
import { BillingAddress } from '@model/EcommerceClass';
import { PlanModel } from '@model/PlanModel';
import { ShippingAddress, ShippingInfo } from '@model/ShippingInfo';
import { UserProfile } from '@model/User';
import { RootState } from 'src/store/store';
import { formatCurrency, getParentCourse, getPriceWithAdjustement, isCourseVisible } from './Api';
import {
  CARD_VALIDATION,
  CONTACT_DEFAULT_PREFIX,
  ECOMMERCE_ACTIVE_VALUES,
  EXPIRATION,
  FILTER_PLAN,
  FILTER_PRICE,
  PLAN_IMAGE,
  PRICE_FILTER_LABELS,
  PRICE_FILTER_PLAN,
} from './const';
import { filterByPriceCondition } from './filterUtility';
import { FormValues as BillingFormValues } from '@components/UI/FormContentBilling';
import { DataLayerTracking, ErrorTracking, ErrorTypes, TealiumEvents } from '@model/TrackingClass';
import { isExcludedFromPlan } from './ecommercePlanUtility';
import { WalletItem, WalletRequestBody, WalletRequestResponse } from '@model/WalletModel';
import { triggerErrorEvent } from './analytics-utils';
import { styledLogUtagView } from '@components/link-utils';

export const canCourseBeAccessed = (course: Course): boolean => {
  if (!course) {
    return false;
  }

  return !isPurchasable(course) || isPurchased(course);
};

export const isCourseFree = (course: Course): boolean => {
  if (!course) {
    return false;
  }

  return !isPurchasable(course);
};

export const isPriceable = (course: Course): boolean => {
  return !!course?.priceable;
};

export const isPurchasable = (course: Course, considerCourseVisible: boolean = false): boolean => {
  if (!course) {
    return false;
  }

  if (considerCourseVisible && !isCourseVisible(course, false)) {
    return false;
  }

  return !!course?.priceable && +course.price !== 0;
};

export const isPurchased = (course: Course, considerCourseVisible: boolean = false): boolean => {
  if (!course) {
    return false;
  }

  if (considerCourseVisible && !isCourseVisible(course, false)) {
    return false;
  }

  return isPriceable(course) && !!course?.buyed;
};

export const isPurchasedIndividually = (course: Course, userProfile: UserProfile): boolean => {
  if (!course) {
    return false;
  }

  return isPurchased(course) && !isPurchasedInPlan(course, userProfile);
};

export const isPurchasedInPlan = (course: Course, userProfile: UserProfile): boolean => {
  if (!course || !userProfile) {
    return false;
  }

  return isPurchased(course) && userProfile.subscription && isInPlan(course);
};

export const isInPlan = (course: Course): boolean => {
  if (!course) {
    return false;
  }

  //all priceable courses with planExcluded=false
  return !!course.priceable && !isExcludedFromPlan(course);
};

export const promiseMock = <Data>(data: Data, shouldReject = false, delay = 3000) => {
  return new Promise<Data>((resolve, reject) =>
    setTimeout(() => {
      if (shouldReject) {
        return reject(data);
      } else {
        return resolve(data);
      }
    }, delay)
  );
};
export const promiseMockForTokendSend = (email, token , errorEmails) => {
  return new Promise((resolve, reject) => {
    if (errorEmails.includes(email)) {
      setTimeout(() => {
        reject(new Error("Internal Server Error (500)"));
      }, 1000);
    } else {
      setTimeout(() => {
        resolve(`Token sent to ${email} successfully: ${token}`);
      }, 1000);
    }
  });
};

export const promiseMockResetTokendSend = async (token: string[]) => {
  const data = await fetch('/data/ecommerce/getModdleTokens.json');
  const dataJson = await data.json();
  return promiseMock({ data: dataJson }, false, 1000);
};


export const orderHistoryMockRequest = async () => {
  const orderHistory = await fetch(`/data/ecommerce/orderHistory.json`);
  const orderHistoryJson: OrderHistory = await orderHistory.json();
  return { data: orderHistoryJson };
};

export const mockCoursePurchasable = (courses: Course[], courseId: string) => {
  for (let course of courses) {
    if (course.courseIdMaster === courseId) {
      course.priceable = true;
      course.buyed = 0;
      course.price = '20';
    }
  }

  return courses;
};

export const mockCoursePurchased = (courses: Course[], courseId: string) => {
  for (let course of courses) {
    if (course.courseIdMaster === courseId) {
      course.priceable = true;
      course.buyed = 1;
      course.price = '123';
    }
  }

  return courses;
};

export const mockCourseFree = (courses: Course[], courseId: string) => {
  for (let course of courses) {
    if (course.courseIdMaster === courseId) {
      course.priceable = true;
      course.buyed = 0;
      course.price = '0';
    }
  }

  return courses;
};

export const mockGetCart = async () => {
  const cart = await fetch(`/data/ecommerce/cart.json`);
  const cartJson: CartModel = await cart.json();
  return promiseMock({ data: cartJson }, false, 5000);
};

export const mockAddItemToCart = async (cart: CartModel, product: ProductCart) => {
  let newCart = cloneDeep(cart);
  const index = newCart.orderItem.findIndex(a => a.partNumber === product.partNumber);
  if (index >= 0) {
    newCart.orderItem[index] = product;
  } else {
    newCart.orderItem.push(product);
  }

  let grandTotal = newCart.orderItem.reduce((acc, item) => {
    return acc + +item?.orderItemPrice * +item.quantity;
  }, 0);
  const totalProductPrice = grandTotal || 0;
  grandTotal = grandTotal - (+cart?.totalAdjustment || 0);
  newCart.grandTotal = grandTotal.toString();
  newCart.totalProductPrice = totalProductPrice.toString();

  return promiseMock({ data: newCart }, false, 1000);
};

export const mockRemoveItemToCart = (cart: CartModel, id: string) => {
  let newCart = cloneDeep(cart);
  const index = newCart.orderItem.findIndex(a => a.partNumber === id);

  const itemAmount =
    index > -1
      ? +newCart.orderItem[index]?.orderItemPrice * +newCart.orderItem[index]?.quantity
      : 0;

  newCart.totalProductPrice = (+newCart.totalProductPrice - itemAmount).toString();
  newCart.grandTotal = (+newCart.grandTotal - itemAmount).toString();

  if (index >= 0) {
    newCart.orderItem.splice(index, 1);
  }
  return promiseMock({ data: newCart }, false, 1000);
};

export const mockAssignPromoCode = async (
  cart: CartModel,
  token: string
): Promise<{ data: CartModel }> => {
  let newCart = cloneDeep(cart);
  const amount = Math.floor(Math.random() * 10) + 1; // random coupon amount
  newCart.adjustment = newCart.adjustment?.length > 0 ? newCart.adjustment : [];
  newCart.adjustment.push({
    code: token,
    amount: amount.toString(),
    currency: 'USD',
  });

  const adjustmentAmount = newCart.adjustment.reduce((acc, item) => {
    return acc + +item.amount;
  }, 0);

  newCart.totalAdjustment = adjustmentAmount.toString();
  newCart.totalAdjustmentCurrency = 'USD';
  newCart.grandTotal = (+newCart.grandTotal - amount).toString();
  return promiseMock({ data: newCart }, false, 1000);
};

export const mockRemovePromoCode = async (cart: CartModel): Promise<{ data: CartModel }> => {
  let newCart = cloneDeep(cart);
  newCart.adjustment = [];
  newCart.grandTotal = (+newCart.grandTotal + +newCart.totalAdjustment).toString();
  newCart.totalAdjustment = '0';

  return promiseMock({ data: newCart }, false, 1000);
};

export const mockGetShippingInfo = async (empty = true, address: ShippingAddress = null) => {
  const data = await fetch(
    empty ? '/data/ecommerce/shippingInfoEmpty.json' : '/data/ecommerce/shippingInfo.json'
  );
  const dataJson: ShippingInfo = await data.json();
  if (address) {
    const { addressId } = dataJson.contact?.[0];
    dataJson.contact = [{ ...address, addressId }];
  }
  return promiseMock({ data: dataJson }, false, 4000);
};

export const mockGetPaymentDetails = async () => {
  const data = await fetch('/data/ecommerce/paymentInstructions.json');
  const dataJson: PaymentInstruction[] = await data.json();

  return promiseMock({ data: dataJson }, false, 4000);
};

export const mockAddPaymentDetail = (
  paymentDetail: WalletRequestBody,
  paymentDetails: WalletRequestResponse['wallet'],
  isDefault: boolean
) => {
  let paymentDetailsToUpdate = cloneDeep(paymentDetails);
  const paymentDetailsToAdd = new WalletItem();

  paymentDetailsToAdd.payMethodId = 'CyberSourceVisa';
  paymentDetailsToAdd.creation = new Date().toISOString();
  paymentDetailsToAdd.expireDate = new Date(
    +paymentDetail.expire_year,
    +paymentDetail.expire_month - 1
  ).toISOString();
  paymentDetailsToAdd.creditCardBin = paymentDetail.cc_account.substring(0, 6);
  paymentDetailsToAdd.creditCardPan = paymentDetail.cc_account.substring(
    paymentDetail.cc_account?.length - 4
  );
  paymentDetailsToAdd.identifier = paymentDetailsToUpdate.length + 1;
  paymentDetailsToAdd.issuer = paymentDetail.cc_nameoncard;
  paymentDetailsToAdd.paymentToken = paymentDetailsToUpdate.length + 1 + '';
  paymentDetailsToAdd.isDefault = isDefault;

  paymentDetailsToUpdate.push(paymentDetailsToAdd);

  paymentDetailsToUpdate = paymentDetailsToUpdate.map(p => ({
    ...p,
    isDefault: isDefault || (p.isDefault && !isDefault),
  }));

  const response = new WalletRequestResponse();
  response.wallet = paymentDetailsToUpdate;
  response.recordTotalCount = 1;
  return promiseMock({ data: response }, false, 1000);
};

export const mockUpdatePaymentDetail = (
  paymentDetail: WalletRequestBody,
  paymentDetails: WalletRequestResponse['wallet'],
  isDefault
) => {
  let paymentDetailsToUpdate = cloneDeep(paymentDetails);

  const index = paymentDetails.findIndex(p => p.identifier === paymentDetail.identifier);

  const isToUpdateDefault = paymentDetailsToUpdate[index]?.isDefault;

  const paymentDetailToUpdate = new WalletItem();

  paymentDetailToUpdate.payMethodId = 'CyberSourceVisa';
  paymentDetailToUpdate.creation = new Date().toISOString();
  paymentDetailToUpdate.expireDate = new Date(
    +paymentDetail.expire_year,
    +paymentDetail.expire_month - 1
  ).toISOString();
  paymentDetailsToUpdate[index];
  paymentDetailToUpdate.creditCardBin = paymentDetail.cc_account
    ? paymentDetail.cc_account.substring(0, 6)
    : paymentDetailsToUpdate[index].creditCardBin;
  paymentDetailToUpdate.creditCardPan = paymentDetail.cc_account
    ? paymentDetail.cc_account.substring(paymentDetail.cc_account?.length - 4)
    : paymentDetailsToUpdate[index].creditCardPan;
  paymentDetailToUpdate.identifier = paymentDetail.identifier;
  paymentDetailToUpdate.issuer = paymentDetail.cc_nameoncard;
  paymentDetailToUpdate.paymentToken = paymentDetailsToUpdate.length + 1 + '';
  if (isDefault) {
    paymentDetailsToUpdate = paymentDetailsToUpdate.map(p => ({ ...p, isDefault: false }));
  }
  paymentDetailToUpdate.isDefault = isDefault;

  if (index >= 0) {
    paymentDetailsToUpdate[index] = paymentDetailToUpdate;
    if (isToUpdateDefault && !isDefault) {
      const mostRecentPMethod = [...paymentDetailsToUpdate]
        .filter(
          p => paymentDetail?.identifier !== p?.identifier && !isCreditCardExpired(p?.expireDate)
        )
        ?.sort((a, b) => (a.creation < b.creation ? -1 : a.creation > b.creation ? 1 : 0))?.[0];

      const index = paymentDetails?.findIndex(p => p?.identifier === mostRecentPMethod?.identifier);
      if (index >= 0) {
        paymentDetailsToUpdate[index].isDefault = true;
      }
    }
    console.log('paymentDetailToUpdate', paymentDetailToUpdate);
  }

  const response = new WalletRequestResponse();

  response.wallet = paymentDetailsToUpdate;
  response.recordTotalCount = 1;
  return promiseMock({ data: response }, false, 1000);
};
export const mockUpdatePaymentDetailDefault = (
  id: number | string,
  paymentDetails: WalletRequestResponse['wallet']
) => {
  let paymentDetailsToUpdate = paymentDetails.map(p => ({ ...p, isDefault: p.identifier === id }));
  console.log('paymentDetailToUpdate', paymentDetailsToUpdate);
  const response = new WalletRequestResponse();
  response.wallet = paymentDetailsToUpdate;
  response.recordTotalCount = 1;
  return promiseMock({ data: response }, false, 1000);
};

export const mockRemovePaymentDetail = (
  id: number | string,
  paymentDetails: WalletRequestResponse['wallet']
) => {
  const paymentDetailsFiltered = paymentDetails?.filter(a => a.identifier !== id);
  const isAnyDefault = paymentDetailsFiltered?.some(a => a.isDefault);
  if (!isAnyDefault && paymentDetailsFiltered?.length > 0) {
    paymentDetailsFiltered[0].isDefault = true;
  }
  const response = new WalletRequestResponse();
  response.wallet = paymentDetailsFiltered;
  response.recordTotalCount = 1;
  return promiseMock({ data: response }, false, 1000);
};

export const mockGetOrderById = async (id: string) => {
  console.log(id);

  const data = await fetch('/data/ecommerce/orderHistoryDetails.json');
  const dataJson = await data.json();
  return promiseMock({ data: { orderId: id, ...dataJson } }, false, 1000);
};

export const mockGetOrderByIdWithToken = async (id: string) => {
  console.log(id);

  const data = await fetch('/data/ecommerce/getOrderWithTokens.json');
  const dataJson = await data.json();
  return promiseMock({ data: { orderId: id, ...dataJson } }, false, 1000);
};

export const mockGetTokensByOrderId = async (id: string) => {
  console.log(id);
  const data = await fetch('/data/ecommerce/getModdleTokens.json');
  const dataJson = await data.json();
  return promiseMock({ data: dataJson }, false, 1000);
};

export const mockActivateLicense = async () => {
  const data = await fetch('/data/ecommerce/activateLicense.json');
  const dataJson = await data.json();
  return promiseMock({ data: dataJson }, false, 1000);
};

export const getPriceLabel = (price: string, lang: LangMapEcomm, currency: string, userLang?: string) => {
  if (!price || !lang) {
    return '';
  }

  //console.log('price label', PRICE_FILTER_LABELS, PRICE_FILTER_LABELS[price]);
  const labelKey = PRICE_FILTER_LABELS[price]?.labelKey;
  if (!labelKey) {
    return price;
  }

  return lang[labelKey]
    ? lang[labelKey]
        .replace(/\{currency\}/g, "")
        .replace(
          '{numFrom}',
          PRICE_FILTER_LABELS[price].from ? formatCurrency(PRICE_FILTER_LABELS[price].from, currency, userLang, false, false) : ''
        )
        .replace('{numTo}', PRICE_FILTER_LABELS[price].to ? formatCurrency(PRICE_FILTER_LABELS[price].to, currency, userLang, false, false) : '')
    : price;
};

export const getOfferPlanLabel = (offerPlan: string, lang: LangMapEcomm) => {
  if (!offerPlan || !lang) {
    return '';
  }

  const labelKey = PRICE_FILTER_PLAN[offerPlan]?.labelKey;
  if (!labelKey) {
    return offerPlan;
  }

  return lang[labelKey] ? lang[labelKey] : offerPlan;
};

export const getExpirationLabel = (expirationDate: string, lang) => {
  if (!expirationDate || !lang) {
    return '';
  }

  const label = EXPIRATION?.[expirationDate];
  const labelKey = label?.labelKey;
  const labelNum = label?.num;
  if (!labelKey) {
    return expirationDate;
  }

  return lang[labelKey] ? lang[labelKey]?.replace?.('{num}', labelNum) : expirationDate;
};

export const getDateFixedRangeLabel = (dateFixedRange: string, lang) => {
  if (!dateFixedRange || !lang) {
    return '';
  }

  let isFuture = false;
  if (dateFixedRange.includes('_future')) {
    isFuture = true;
  }

  dateFixedRange = dateFixedRange.replace('_future', '').replace('_past', '');

  return (isFuture ? lang.NEXT_X_DAYS : lang.LAST_X_DAYS)?.replace('{NUM}', dateFixedRange);
};

export const getPriceCourseFilter = course => {
  if (!isPurchasable(course)) {
    return '';
  }

  for (let price of Object.keys(FILTER_PRICE)) {
    if (filterByPriceCondition(course, price)) {
      return price;
    }
  }

  return '';
};
export const getOfferPlanFilter = course => {
  if (!course) {
    return '';
  }

  if (isCourseFree(course)) {
    return FILTER_PLAN.FREE;
  } else if (isExcludedFromPlan(course)) {
    return FILTER_PLAN.PURCHASABLE_OUTSIDE_PLAN;
  } else if (isInPlan(course)) {
    return FILTER_PLAN.INPLAN;
  }

  // FILTER_PLAN.PROMO
  // FILTER_PLAN.MASTER
  // FILTER_PLAN.PROFESSIONAL
  // FILTER_PLAN.CERTIFICATE

  return '';
};

export const convertStringToBulletedString = (s, numDigits = 2, numBullets = 0, isCheckoutPage = false) => {
  const regex = new RegExp(`.(?=.{${numDigits},})`, 'g');
  const string = s.length > numDigits ? s : 'xxxx'.repeat(numDigits) + s;
  let bulletedString = string.replace(regex, '•');
  
  bulletedString = numBullets > 0 ? bulletedString.slice(-(numDigits + numBullets)) : bulletedString;

  let bulletedStringToShow = bulletedString.slice(0, -numDigits);
  let numDigitsToShow = bulletedString.slice(-numDigits);

  if(isCheckoutPage) {
    let arrOfBulletedString = bulletedStringToShow.match(/.{1,4}/g)
    bulletedStringToShow = arrOfBulletedString.join(" ");
  }

  return bulletedStringToShow + ' ' + numDigitsToShow;
};

export const selectCoursesById = (state: RootState, ids) => {
  const coursesMap: CoursesMap = state.course.coursesMap;
  const isGetCourseCompleted = state.course.isGetCourseCompleted;

  const plan: PlanModel = state.ecommerce.plan;
  const isLoadingPlan: boolean =
    state.ecommerce.isLoadingPlan || state.ecommerce.isSubscriptionListLoading;

  const lang: LangMap = state.utils.lang;

  const coursesById: CoursesMap = {};
  if (ids?.length > 0 && isGetCourseCompleted && !isLoadingPlan) {
    // const orderSubscription = selectSubscription(state);
    // const userPlan = selectUserPlan(state);
    // const isPlanRenewalActive = isPlanAutorenewalActive(orderSubscription, userPlan);

    ids.forEach(id => {
      if (plan && id == plan.id) {
        const planCourse = new Course();
        planCourse.courseId = id;
        planCourse.courseFullName = lang?.UNLIMITED_ACCESS_PLAN;
        planCourse.courseOverviewFile = PLAN_IMAGE;
        planCourse.ctype = ['plan'];
        planCourse.catalogTypes = ['plan'];
        planCourse.priceable = true;
        planCourse.buyed = state.user.userProfile?.subscription ? 1 : 0;
        planCourse.isPlan = true;
        //TODO GET CORRECT EXPIRATION DATE
        planCourse.endDate = '';
        planCourse.autoRenewalActive = false;
        coursesById[id] = planCourse;
        return;
      }

      const course: Course = getParentCourse(id, coursesMap);
      if (course && id) {
        coursesById[id] = course;
      }
    });
  }
  return coursesById;
};

export const selectOrderItemsCourses = (state, orderItems: ProductCart[] = []) => {
  const ids = orderItems.map(item => item.partNumber);
  const orderItemsCourses = selectCoursesById(state, ids);
  return orderItemsCourses;
};

export const isCartItemExpired = (item: Course): boolean => {
  // return true;
  if (item && !item.endDate) {
    //if endDate is non present --> content will never expire
    return false;
  }

  const today = new Date();
  const dateToCompare = new Date(item?.endDate);
  return dateToCompare < today;
};

export const getCountryLabel = (countryId: string, lang: LangMap) => {
  return lang[`COUNTRY_${countryId}`];
};

export const selectShippingInfoOrderItemConsiderTemporary = (
  state: RootState
): { [key in keyof BillingFormValues]: string } => {
  return selectShippingInfoOrderItem(state, true);
};

export const selectShippingInfoOrderItem = (
  state: RootState,
  considerTemporary: boolean = false
): { [key in keyof BillingFormValues]: string } => {
  const shippingInfoOrderItem = getDefaultContact(
    state.ecommerce.shippingInfo?.contact,
    considerTemporary
  );
  const lang = state.utils.lang;
  return {
    name: shippingInfoOrderItem?.firstName || '',
    surname: shippingInfoOrderItem?.lastName || '',
    address: shippingInfoOrderItem?.addressLine?.[0] || '',
    zipCode: shippingInfoOrderItem?.zipCode || '',
    city: shippingInfoOrderItem?.city || '',
    state: shippingInfoOrderItem?.state || '',
    country: getCountryLabel(shippingInfoOrderItem?.country, lang) || lang?.USA_LABEL,
  };
};

export const selectOrderHistory = (state: RootState) => {
  const orderHistory = state.ecommerce.orderHistory;
  const orderHistoryDetails = state.ecommerce.orderHistoryDetails;

  const orderHistoryItems = orderHistory?.Order.filter(
    item => orderHistoryDetails?.[item?.orderId]
  );
  const next = orderHistory?.Order.find(
    item => item?.orderId && !orderHistoryDetails?.[item?.orderId]
  )?.orderId;

  const orderItemsHistory = orderHistoryItems
    ?.map(item => selectOrderDetailsById(state, item.orderId, item.parentOrderId))
    .filter(item => !!item);

  // @todo fix sorting
  const orderItemsHistorySorted = orderItemsHistory; /*?.sort((a, b) => {
    return new Date(b.purchasedDate)?.getTime() - new Date(a.purchasedDate)?.getTime();
  })*/

  const completed =
    orderItemsHistorySorted?.some(item => Array.isArray(item?.products)) ||
    orderHistory?.recordSetCount === '0';

  return {
    items: orderItemsHistorySorted || [],
    next,
    completed,
  };
};

export const getyyyymmddDate = (d: string | Date) => {
  if (!d) {
    return '';
  }

  const date = new Date(d);
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return `${yyyy}/${mm}/${dd}`;
};
export const selectOrderDetailsById = (
  state: RootState,
  orderId: string,
  parentOrderId: string = null
) => {
  const isGetCourseCompleted = state.course.isGetCourseCompleted;
  if (orderId && isGetCourseCompleted) {
    const customerOrderNumber = orderId;
    const orderHistory = state.ecommerce.orderHistory;
    const orderDetails = state.ecommerce?.orderHistoryDetails?.[orderId];
    const productIds = orderDetails?.orderItem
      ?.map(product => product.partNumber)
      .filter(item => !!item);
    const courses = selectCoursesById(state, productIds);

    if (courses && !Object.keys(courses)?.length && productIds?.length > 0) {
      return null;
    }
    const paymentInstruction = orderDetails?.paymentInstruction?.[0];
    let billingAddress: BillingAddress = new BillingAddress();
    //if paymentInstruction are not present --> get billing address from first orderItem
    if (paymentInstruction) {
      billingAddress = {
        name: paymentInstruction.firstName || '',
        surname: paymentInstruction.lastName || '',
        address: paymentInstruction.addressLine?.[0] || '',
        zipCode: paymentInstruction.zipCode || '',
        city: paymentInstruction.city || '',
        state: paymentInstruction.state || '',
      };
    } else {
      const firstItem = orderDetails?.orderItem?.[0];
      if (firstItem) {
        billingAddress = {
          name: firstItem.firstName || '',
          surname: firstItem.lastName || '',
          address: firstItem.addressLine?.[0] || '',
          zipCode: firstItem.zipCode || '',
          city: firstItem.city || '',
          state: firstItem.state || '',
        };
      }
    }

    let creditCardNum = orderDetails?.paymentInstruction?.[0]?.protocolData?.find(
      item => item.name === 'account'
    )?.value;
    //if cc-account is not present in protocolData --> get last 4 digits from paymentToken
    if (!creditCardNum && orderDetails) {
      // const call_0_response = orderDetails?.paymentInstruction?.[0]?.protocolData?.find(
      //   item => item.name === 'call_0_response'
      // )?.value;
      // if (call_0_response) {
      //   creditCardNum = call_0_response
      //     .split('paySubscriptionCreateReply_instrumentIdentifierID=')?.[1]
      //     ?.split(',')?.[0]
      //     ?.slice(-4);
      //   if (creditCardNum) {
      //     creditCardNum = '000000000000' + creditCardNum;
      //   }
      // }
      creditCardNum = orderDetails?.paymentInstruction?.[0]?.protocolData.find(item => item.name === "paymentToken")?.value; //FIX LEON-4276
    }
    const orderTokens = state.ecommerce?.ordersTokens?.[parentOrderId || orderId];
    const order = orderHistory?.Order?.find(o => o?.orderId === orderId);
    const products = orderDetails?.orderItem?.map(product => {
      const partNumber = product?.partNumber;
      const oldPrice = product.adjustment?.length > 0 ? product.orderItemPrice : 0;
      const finalPrice = getPriceWithAdjustement(product);
      const tokensNum =
        orderTokens?.filter(token => token.object === product.partNumber)?.length || 0;

      const course = courses?.[partNumber];
      let autoRenewalActive = false;
      let planExpirationDate = '';
      if (course?.isPlan) {
        planExpirationDate = order?.subscriptionInfo?.orderTimeEnd;
        autoRenewalActive = order?.subscriptionInfo?.autoRenewalActive === 'Active';
        course.endDate = planExpirationDate;
      }
      return {
        id: partNumber,
        oldPrice,
        finalPrice,
        orderItemPrice: product?.orderItemPrice || 0,
        currency: product?.currency || 'USD',
        quantity: product?.quantity || 0,
        name: course?.courseFullName || partNumber || '',
        image: course?.courseOverviewFile || '',
        fallbackImage: course?.fallbackImage || '',
        expirationDate: course?.endDate || '',
        isExpired: !autoRenewalActive ? isCartItemExpired(course) : false,
        tokensNum,
        ...course,
        autoRenewalActive,
      };
    });

    const isPlan = products?.every(item => item?.isPlan);
    const autoRenewalActive = products?.some(p => !!p?.autoRenewalActive);
    const planPurchasedDate = order?.subscriptionInfo?.orderBoughtTimeStart || '';
    //console.log(`products`, products);

    return {
      id: orderId,
      name: customerOrderNumber || '',
      parentOrderId: order?.parentOrderId || '',
      totalProductPrice: orderDetails?.totalProductPrice || 0,
      totalProductCurrency: orderDetails?.grandTotalCurrency || 'USD',
      grandTotal: orderDetails?.grandTotal || 0,
      grandTotalCurrency: orderDetails?.totalProductPriceCurrency || 'USD',
      totalTaxes: orderDetails?.totalSalesTax || 0,
      totalTaxesCurrency: orderDetails?.totalSalesTaxCurrency || 'USD',
      totalPromoCodes: orderDetails?.totalAdjustment || 0,
      totalPromoCodesCurrency: orderDetails?.totalAdjustmentCurrency || 'USD',
      promoCodes: orderDetails?.adjustment?.filter(a => a.displayLevel !== 'OrderItem') || [],
      purchasedDate: !isPlan
        ? orderDetails?.placedDate || ''
        : planPurchasedDate || orderDetails?.placedDate || '',
      billingAddress: billingAddress,
      paymentInstruction: {
        creditCardNum: creditCardNum || '',
        payMethodId: paymentInstruction?.payMethodId || '',
      },
      products,
      hasTokens: products?.reduce((prev, curr) => prev || curr.tokensNum > 0, false),
      autoRenewalActive,
      hideTokens: order?.isParent,
    };
  }
  return null;
};

export const selectProductsTokensByOrderId = (state: RootState, orderId: string) => {
  const orderTokens = state.ecommerce?.ordersTokens?.[orderId];
  if (orderId && orderTokens?.length > 0) {
    const detailsOrderProducts = selectOrderDetailsById(state, orderId)?.products;
    const orderProducts = (detailsOrderProducts || [])?.map(product => {
      const tokens = orderTokens
        .filter(token => token.object === product.id)
        .map(token => ({
          ...token,
          id: token.token,
          activated: token.statustoken === 'active',
          isExpired: product?.isExpired,
        }));

      return {
        ...product,
        tokens: tokens,
      };
    });
    return orderProducts?.filter(item => item.tokens?.length > 0);
  }

  return [];
};

export const selectTokenData = (state: RootState, orderId, token: string) => {
  //set token to lowercase
  token = token?.toLowerCase();

  const orderDetails = state.ecommerce?.orderHistoryDetails?.[orderId];

  const productsTokens = selectProductsTokensByOrderId(state, orderId);

  const customerOrderNumber = orderId;

  const product = productsTokens?.find(productToken =>
    productToken?.tokens?.find(tokenItem => tokenItem?.id?.toLowerCase() === token)
  );
  console.log({ productsTokens, orderDetails, product });

  if (product && orderDetails) {
    const tokenFound = product?.tokens.find(tokenItem => tokenItem.id?.toLowerCase() === token);
    if (tokenFound) {
      return {
        date: orderDetails.placedDate || '',
        ...tokenFound,
        product,
        customerOrderNumber: customerOrderNumber || '',
      };
    }
  }

  return null;
};

export const selectOrderConfirmation = (state: RootState, orderId: string) => {
  if (orderId) {
    const detailsOrderProducts = selectOrderDetailsById(state, orderId);
    const customerOrderNumber = orderId;

    if (detailsOrderProducts) {
      return {
        customerOrderNumber,
        ...detailsOrderProducts,
      };
    }
  }
  return null;
};

export const checkoutErrorUtag = (error_message: string, error_type: ErrorTypes) => {
  let utagErrorData: ErrorTracking = {
    Error_Source: error_type,
    Error_Code: 'checkout',
    Error_Message: error_message

  };
  triggerErrorEvent(utagErrorData)
  styledLogUtagView(error_message, utagErrorData)
};

export const toFixedNumString = (value: string | number, digits = 2) => {
  return (+value)?.toFixed(digits)?.toString();
};
export const luhnCheck = cardNumber => {
  var trimmed = String(cardNumber).replace(/[\s]/g, ''),
    length = trimmed.length,
    odd = false,
    total = 0,
    calc,
    calc2;

  if (!/^[0-9]+$/.test(trimmed)) {
    return false;
  }

  for (var i = length; i > 0; i--) {
    calc = parseInt(trimmed.charAt(i - 1));
    if (!odd) {
      total += calc;
    } else {
      calc2 = calc * 2;

      switch (calc2) {
        case 10:
          calc2 = 1;
          break;
        case 12:
          calc2 = 3;
          break;
        case 14:
          calc2 = 5;
          break;
        case 16:
          calc2 = 7;
          break;
        case 18:
          calc2 = 9;
          break;
        default:
          calc2 = calc2;
      }
      total += calc2;
    }
    odd = !odd;
  }

  return total !== 0 && total % 10 === 0;
};

export const isEcommerceActive = (value: string) => {
  return value === ECOMMERCE_ACTIVE_VALUES.ENABLED;
};

export const getNickNameDefault = () => {
  return CONTACT_DEFAULT_PREFIX + new Date().getTime();
};
export const getDefaultContact = (
  contacts: ShippingAddress[],
  considerTemporary: boolean = false
): ShippingAddress => {
  if (!contacts || contacts.length < 1) {
    return null;
  }

  let myContacts = cloneDeep(contacts);
  // LEON-4471
  // if (!considerTemporary) {
  //   myContacts = myContacts.filter(a => a.nickName?.startsWith(CONTACT_DEFAULT_PREFIX));
  // }

  //myContacts are the saved contacts
  myContacts = myContacts.filter(a => a.nickName?.startsWith(CONTACT_DEFAULT_PREFIX));
  myContacts = myContacts.sort((a, b) => a.nickName?.localeCompare(b.nickName));
  //console.log('myContacts', { contacts: contacts, myContacts: myContacts });

  //if there's a saved contact and there's not a contact of type ShippingAddress in contacts then use latest contact in myContacts
  //second part is to avoid user that wants to use another contact to be forced to the saved one
  if(myContacts.length > 0 && !contacts.some((address => isShippingAddress(address)))) {
    return myContacts[myContacts.length - 1];
  }

  //if there has been an entered contact use that in spite of the saved one else user should fill the form
  let shippingAddress = contacts.map((address) => isShippingAddress(address) ? address : null);
  //console.log(shippingAddress)

  return shippingAddress[shippingAddress.length -1];

  // console.log(myContacts[myContacts.length - 1]);
  // return myContacts[myContacts.length - 1];
};

export const isShippingAddress = (address: ShippingAddress): address is ShippingAddress => {
  return (address as ShippingAddress).nickName === undefined
}

export const isDefaultContactPresent = contacts => {
  return !!getDefaultContact(contacts);
};

export const isIdPlan = (id: string): boolean => {
  return id?.startsWith('pl');
};

export const recognizeCardCircuit = (value: string): string => {
  let circuitTemp = 'generic';

  if (!value) {
    return circuitTemp;
  }

  for (let cardValidation of Object.values(CARD_VALIDATION)) {
    if (cardValidation.type !== 'generic' && cardValidation.pattern.test(value)) {
      circuitTemp = cardValidation.type;
    }
  }

  return circuitTemp;
};

export const isCreditCardExpired = date => {
  if (!date) {
    return false;
  }
  const dateConverted = date?.replaceAll('-', '/');
  const d = new Date(dateConverted),
    month = d.getMonth(),
    year = d.getFullYear();

  const currentDate = new Date();

  const isExpired =
    currentDate.getFullYear() === year
      ? currentDate.getMonth() > month
      : currentDate.getFullYear() > year;
  return isExpired;
};
