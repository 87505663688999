import { useState, useEffect } from 'react';

const useKeyboardStatus = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleKeyboardChange = () => {
      const isOpen = window.innerHeight < window.screen.height;
      setIsKeyboardOpen(isOpen);
    };

    window.addEventListener('resize', handleKeyboardChange);

    return () => {
      window.removeEventListener('resize', handleKeyboardChange);
    };
  }, []);

  return isKeyboardOpen;
};

export default useKeyboardStatus;
