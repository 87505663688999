import { useState, useEffect } from "react";
import { getChannelsByL0ToShow, isCourseVisible, isLearningPath } from "@utility/Api";
import {LevelEnrichedFE, LevelEnrichedMap} from "@model/CoursesClass";
import { useSelector } from "./redux";
import {BUILD_YOUR_CAREER_NAME_L0} from  "@utility/const.js"

type useL0Class = [
  channels: LevelEnrichedFE[],
  isLoading?: boolean
]

const useL0 = (): useL0Class => {
  const [channels, setChannels] = useState<LevelEnrichedFE[]>(
    []
  );

  const lang = useSelector((state) => state.utils.lang);
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const isGetCourseCompleted = useSelector(
    (state) => state.course.isGetCourseCompleted
  );
  const labelL0Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL0Map);
  const labelL1Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL1Map);

  useEffect(() => {
    if (isGetCourseCompleted && coursesMap && lang && Object.values(labelL0Map).length > 0 && Object.values(labelL1Map).length > 0) {
      const channelsBrandsToShow = getChannelsByL0ToShow(coursesMap, lang, labelL0Map, labelL1Map);
      setChannels(channelsBrandsToShow);
    }
  }, [coursesMap, lang, labelL0Map, labelL1Map]);

  return [channels];
};


/**
 * Custom hook for fetching levels of Build Your Career page (UpSkill Program, Role Specific Program too) with L0 and L1
 *  taxonomy well-formatted
 *
 *  @return {Array<LevelEnrichedFe>} Array of level L0 which its key starts with "career_"
 *  @return {boolean}                State of loading
 *  @example Returns
 *  {
 *    0:{
 *      key: "career_career_programs,
 *      ...
 *      l1:{
 *          key: "career_front_desk_professional",
 *          ....
 *      }
 *    }
*   }
 */
export const useL0Career = (): [Array<LevelEnrichedFE>, boolean] =>{
  const [listL0, setListL0] = useState<Array<LevelEnrichedFE>>();
  const [isLoading, setIsLoading] = useState(true)
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const isGetCourseCompleted = useSelector(
    (state) => state.course.isGetCourseCompleted
  );
  const labelL0Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL0Map);
  const labelL1Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL1Map);

  // OLD
  //<editor-fold desc="useL0.ts > useL0Career - line 42 at 30/08/2023 11:49:25">
  // console.group('useL0.ts > useL0Career - line 42 at 30/08/2023 11:49:25');
  // console.log(labelL0Map);
  // console.log(labelL1Map);
  // console.groupEnd();
  //</editor-fold>

  // useEffect(() => {

  //   if(Object.values(labelL0Map).length > 0 &&
  //      Object.values(labelL1Map).length > 0){
  //     const l0Career = Object.values(labelL0Map).filter(l0=>
  //         l0.key.toLowerCase().startsWith(BUILD_YOUR_CAREER_NAME_L0))

  //     const l1Career: LevelEnrichedFE[] = Object.values(labelL1Map).filter(l1=>
  //         l1.key.toLowerCase().startsWith(BUILD_YOUR_CAREER_NAME_L0))

  //     l0Career.sort((a,b)=>b.order - a.order)

  //     for(const l0 in l0Career){
  //       const l1s = l1Career.filter(l1=>
  //           l1.order.toString().startsWith(l0Career[l0].order.toString()))

  //       l1s.sort((a, b) => a.order - b.order)
  //       l1s.forEach(l1=> {
  //         l1["l0"] = l0Career[l0]
  //       })


  //       // @ts-ignore
  //       l0Career[l0]["l1"] = cloneDeep(l1s)

  //       // tempListL0Career.set(l0Career[l0].key, l0Career[l0])
  //     }

  //     //<editor-fold desc="useL0.ts > useL0CareerAndL1 - line 71 at 30/08/2023 12:06:43">
  //     console.clear()
  //     console.group('useL0.ts > useL0CareerAndL1 - line 71 at 30/08/2023 12:06:43');
  //     console.log(l0Career);
  //     console.groupEnd();
  //     //</editor-fold>

  //     setIsLoading(false)
  //     setListL0(l0Career)

  //   }

  // }, [labelL0Map, labelL1Map]);

  useEffect(() => {

    if(isGetCourseCompleted 
      && coursesMap 
      && Object.values(labelL0Map)?.length > 0 
      && Object.values(labelL1Map)?.length > 0) {

      // L0s career from Taxonomy (getAllLabels) 
      const l0Career = Object.values(labelL0Map).filter(l0 =>
        l0.key.toLowerCase().startsWith(BUILD_YOUR_CAREER_NAME_L0)
      )
      // L1s career from Taxonomy (getAllLabels)
      const l1Career: LevelEnrichedFE[] = Object.values(labelL1Map).filter(l1 =>
        l1.key.toLowerCase().startsWith(BUILD_YOUR_CAREER_NAME_L0)
      )
      // ADD ONLY L0s THAT ARE ALSO PRESENT IN coursesMap
      let userL0s: LevelEnrichedFE[] = []
      Object.values(l0Career).map(l0 => {
        if(Object.values(coursesMap).some(c => isCourseVisible(c) && c.level0 === l0.key))
          userL0s.push(l0)
      })
      // SORT L0s by order
      userL0s.sort((a,b) => a.order - b.order)
      // ADD ONLY L1s THAT ARE ALSO PRESENT IN coursesMap AND THAT HAVE AT LEAST 1 LP
      let userL1s: LevelEnrichedFE[] = []
      Object.values(l1Career).map(l1 => {
        if(Object.values(coursesMap).some(c => isCourseVisible(c) && c.catalogTypes.includes(l1.key) && isLearningPath(c)))
          userL1s.push(l1)
      })

      for(const l0 in userL0s) {

        const l1s = userL1s.filter(l1 => l1.order.toString().startsWith(userL0s[l0].order.toString()))
        l1s.sort((a, b) => a.order - b.order)
        l1s.forEach(l1 => {
          l1["l0"] = userL0s[l0]
        })
        // @ts-ignore
        userL0s[l0]["l1"] = cloneDeep(l1s)

      }

      setIsLoading(false)
      setListL0(userL0s)

    }

  }, [coursesMap, labelL0Map, labelL1Map]);


  return [listL0, isLoading]
}

export default useL0;
