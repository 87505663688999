import React, { useEffect, useState } from 'react';
import { History } from 'history';
import Layout from '@components/Layout';
import { useSelector } from '@hooks/redux';
import FiltersV3 from '@components/FiltersV3';
import { SORTBY, queryParams } from '@utility/const';
import useUpcomingEvents from '@hooks/useUpcomingEvents';
import HotAirBaloon from './HotAirBaloon';
import CarouselSection from '@components/CarouselSection';
import { LangMap } from '@model/CoursesClass';
import { selectCoursesAndL1, selectRecentlyAddedIds } from '../store/selectors/coursesSelectors';
import { useDispatch } from 'react-redux';
import { getHeroBannerInfo, getUserRelevantSessions } from '@redux-actions/';
import TabHandler from '@components/UI/TabHandler';
import PageTopTitle from '@components/UI/PageTopTitle';
import { selectUserProfile } from '@utility/Api';
import { UserProfile } from '@model/User';
import { DataLayerTracking, selectTrackingDataLayerBase } from '@model/TrackingClass';
import { USER_URLS, UtagViews, styledLogUtagView } from '@components/link-utils';
import { triggerPageView } from '@utility/analytics-utils';

type ProfileUpcomingProps = {
  lang: LangMap;
  history: History;
};

export enum UpcomingEventsTabEnum {
  upcoming = 'upcoming',
  past = 'past',
}

const ProfileUpcoming = (props: ProfileUpcomingProps) => {
  const dispatch = useDispatch();

  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);

  const { lang } = props;
  const [upcomingCourses, pastCourses] = useUpcomingEvents();
  const userProfile: UserProfile = useSelector(selectUserProfile);
  const heroBannerInfo = useSelector(state => state.cms.heroBannerInfo);
  const isLoadingHeroBannerInfo: boolean = useSelector(state => state.cms.isLoadingHeroBannerInfo);
  const recentlyAddedIds = useSelector(selectRecentlyAddedIds);
  const recentlyAdded = useSelector(state => selectCoursesAndL1(state, recentlyAddedIds.ids));
  const isLoadingGetUserRelevantSession = useSelector(state => state.utils.isLoadingGetUserRelevantSessions);
  const [tab, setTab] = useState<UpcomingEventsTabEnum>(UpcomingEventsTabEnum.upcoming);


  useEffect(() => {
    if (!heroBannerInfo && !isLoadingHeroBannerInfo) {
      dispatch(getHeroBannerInfo());
    }
  }, []);

  useEffect(() => {
   // console.log("**** iS LOADING?", isLoadingGetUserRelevantSession)
    if(!isLoadingGetUserRelevantSession && isGetCourseCompleted){
    //  console.log("**** LIVE SESSIONS IS CALLING USERRELEVANT SESSION")
      dispatch(getUserRelevantSessions());
    //console.log("**** iS LOADING?", isLoadingGetUserRelevantSession)
    }
  }, [userProfile?.preferredLang,isGetCourseCompleted]);

  //ANALYTICS
  const baseTealiumTracking = useSelector(selectTrackingDataLayerBase)
  useEffect(() => {

    if(baseTealiumTracking && !window.location.search.includes(queryParams.COURSE_DETAIL)) {
      const utagViewData: DataLayerTracking = {
        ...baseTealiumTracking,
        ...UtagViews[USER_URLS.PROFILE_UPCOMING_EVENTS.URL],
      };
      styledLogUtagView(UtagViews[USER_URLS.PROFILE_UPCOMING_EVENTS.URL]?.Page_Section2, utagViewData);
      triggerPageView(utagViewData);
    }
  }, [!!baseTealiumTracking, window.location.search])

  return (
    <Layout>
      <div className="upcoming">
        <PageTopTitle title={lang.UPCOMING_LABEL_HP_RED} />
        <div className="wrapper">
          <FiltersV3
            key={tab}
            category={'channel'}
            // categoryName={PageTitles.UPCOMING_EVENTS_TITLE}
            filteredCourses={tab === UpcomingEventsTabEnum.upcoming ? upcomingCourses : pastCourses}
            defaultSortBy={SORTBY.RELEVANCE_FAKE} //lessons are already ordered by useUpcomingEvents()
            isWishlist={false}
            upcomingEventsTab={tab}
            hideResultsNumber
            isLoading={isLoadingGetUserRelevantSession}
            hideSortingOutsideModal
            hideSortingInModal
            showDateSessionFilter
            considerDateSessionInTheFuture={tab === UpcomingEventsTabEnum.upcoming}
            filterOverlayComponent={
              ((upcomingCourses?.length > 0 && pastCourses?.length > 0) ||
                (!(upcomingCourses?.length > 0) && pastCourses?.length > 0)) && (
                <TabHandler
                  tab={tab}
                  setTab={setTab}
                  tabsList={[
                    {
                      id: UpcomingEventsTabEnum.upcoming,
                      labelKey: 'UPCOMING_LABEL',
                      dataElementId: 'LiveSessions_Upcoming',
                      dataDescription: 'LiveSessions_Upcoming',
                    },
                    {
                      id: UpcomingEventsTabEnum.past,
                      labelKey: 'PAST_EVENTS',
                      dataElementId: 'LiveSessions_PastSession',
                      dataDescription: 'LiveSessions_PastSession',
                    },
                  ]}
                  className="upcoming__tabs"
                />
              )
            }
            forceRendering={
              (upcomingCourses?.length > 0 && pastCourses?.length > 0) ||
              (!(upcomingCourses?.length > 0) && pastCourses?.length > 0) 
            }
          />
        
          {isGetCourseCompleted &&
            ((upcomingCourses?.length === 0 && pastCourses?.length === 0) ||
              (upcomingCourses?.length === 0 &&
                pastCourses?.length > 0 &&
                tab === UpcomingEventsTabEnum.upcoming)) && (
              <section className="flex column justify-center" aria-label={lang.ALL_DONE_FOR_THE}>
                <HotAirBaloon
                  calendar
                  lang={lang}
                  history={props.history}
                  showCta={!upcomingCourses?.length}
                />
                <h1 className="todo__empty-title text-center">
                  {lang.NO_UPCOMING_EVENTS?.toUpperCase()}
                </h1>
                <p className="upcoming__empty-description text-center">
                  {lang.BROWSE_CATALOG_MORE_CONTENT}
                </p>
                {recentlyAdded.length > 0 && (
                  <CarouselSection
                    courses={recentlyAdded}
                    lang={lang}
                    title="RECENTLY_ADDED"
                    description="RECENTLY_ADDED_DESCR"
                    classNames="carousel-max-width"
                    isLoading={!isGetCourseCompleted && isLoadingGetUserRelevantSession}
                    variableWidth={true}
                  />
                )}
              </section>
            )}
        </div>
      </div>
    </Layout>
  );
};

export default ProfileUpcoming;
