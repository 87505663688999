import { SKELETON_COLORS } from "@utility/const";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";

const heightDesktop = 289;
const widthDesktop = 513.78;
const heightMobile = 249;
const widthMobile = 369;

const CardLargeEvolutionSkeleton = (props) => {
  const breakpoint = useBreakpointBoolean();

  const [width, setWidth] = useState<string | number>(widthDesktop);
  const [height, setHeight] = useState<string | number>(widthDesktop);

  useEffect(() => {
    if (breakpoint.isDesktop) {
      setWidth(widthDesktop);
      setHeight(heightDesktop);
    } else {
      setWidth(widthMobile);
      setHeight(heightMobile);
    }
  }, [breakpoint]);

  return (
    <div
      className="skeleton__card-large-evolution"
      style={{ width: `${width}px`, height: `${height}px`, overflow: "hidden" }}
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect x="0" y="0" rx="8" ry="8" width={width} height={height} />
      </ContentLoader>
    </div>
  );
};

export default CardLargeEvolutionSkeleton;
