import { USER_URLS, styledLogUtagView } from "@components/link-utils";
import { DateTime } from "luxon";
import { formatDateInAMPM, isFaceToFace, isLivestreamCourse, isMandatory, printDate } from "./Api";
import { queryParams } from "./const";
import { triggerTrackEvent } from "./analytics-utils";
import { LevelEnrichedMap } from "@model/CoursesClass";

export const printDateTimezone = (time, lang, timezone, considerYear = true) => {
  //get the correct date format for printDate() function
  //CONVERT NOTIFICATION DATE IN USER TIMEZONE
  const notifDateTimezone = new Date((new Date(time)).toLocaleString('en-US', { timeZone: timezone }));
  // const notifDate = new Date((new Date('2020-09-10T03:57:21.758+0000')).toLocaleString('en-US', {timeZone: timezone}));

  const month = notifDateTimezone.getMonth() + 1;
  const timestampString =
    notifDateTimezone.getFullYear() +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    notifDateTimezone.getDate();
  const date = printDate(timestampString, lang);

  if (considerYear) {
    return date;
  } else {
    //remove year
    const dateSplit = date.split(' ');
    return dateSplit[0] + ' ' + dateSplit[1];
  }
}

export const printTimeTimezone = (time, timezone) => {
  //get the correct date format for printDate() function
  //CONVERT NOTIFICATION DATE IN USER TIMEZONE
  const notifDateTimezone = new Date((new Date(time)).toLocaleString('en-US', { timeZone: timezone }));
  // const notifDate = new Date((new Date('2020-09-10T03:57:21.758+0000')).toLocaleString('en-US', {timeZone: timezone}));

  let hours = notifDateTimezone.getHours().toString();
  if (hours.length < 2) {
    hours = "0" + hours;
  }

  let minutes = notifDateTimezone.getMinutes().toString();
  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }

  return hours + ":" + minutes;
}

export const convertTimestampToDate = (time, timezone) => {
  const notifDateTimezone = new Date((new Date(time)).toLocaleString('en-US', { timeZone: timezone }));

  const month = notifDateTimezone.getMonth() + 1;
  const dateString =
    notifDateTimezone.getFullYear() +
    "/" +
    (month < 10 ? "0" + month : month) +
    "/" +
    notifDateTimezone.getDate();

  let hours = notifDateTimezone.getHours().toString();
  hours = hours.length < 2 ? "0" + hours : hours;

  let minutes = notifDateTimezone.getMinutes().toString();
  minutes = minutes.length < 2 ? "0" + minutes : minutes;

  let seconds = notifDateTimezone.getSeconds().toString();
  seconds = seconds.length < 2 ? "0" + seconds : seconds;

  return dateString + ' ' + hours + ':' + minutes + ':' + seconds;
}

class IcsInfo {
  title: string;
  description: string;
  descriptionRow1: string;
  descriptionRow2: string;
  descriptionRow3: string;
  id: string;
  location: string;
  start: string;
  end: string;
  object: string;
  leonardoIcon: any;
}
export const createIcs = (courseIdMaster, sessionId, coursesMap, timezone, lang) => {
  let info: IcsInfo = new IcsInfo();

  //get info to create the ics file
  if (coursesMap && courseIdMaster && coursesMap[courseIdMaster]) {
    const course = coursesMap[courseIdMaster];

    /* 
    https://luxotticaretail.atlassian.net/browse/LEON-1020
    Object: Leonardo Save The Date: [name of VC]
    
    Location: Leonardo Platform (linked to the VC)
    
    Description:
    
    You’ve booked a space for the virtual class: [name of VC].
    
    The class will take place at [hh] on [dd/mm].
    
    Join it by clicking here: [direct link to VC]
    
    [Logo Leonardo] */
    // const logoLeonardo =  window.location.origin + "/images/logo-app/leonardo-logo.png"


    info.object = lang?.ICS_SUBJECT + " " + course.courseFullName;
    info.title = info.object
    info.location = lang?.ICS_LOCATION;
    // info.descriptionRow1 = "<span style=font-family:'Avenir LT Std 35 Light'>You've booked a space for the virtual class:</span> <br/> " + "<strong>" + course.courseFullName + "</strong>";  //CON HTML
    // info.descriptionRow3 = "<span style=font-family:'Avenir LT Std 35 Light'>Join it by clicking here:</span>" + "  " + ("Link").link(window.location.origin + USER_URLS.PROFILE_UPCOMING_EVENTS.URL + "?courseDetail=" + course.courseIdMaster + "&courseTitle=" + course.courseFullName); //CON HTML

    info.descriptionRow3 = lang?.ICS_JOIN_CLICKING_HERE + " " + window.location.origin + USER_URLS.PROFILE_UPCOMING_EVENTS.URL + "?" + queryParams.COURSE_DETAIL + "=" + course.courseIdMaster + "&" + queryParams.COURSE_DETAIL_TITLE + "=" + encodeURI(course.courseFullName);
    // info.leonardoIcon = `<img src="${logoLeonardo}" width="150" height="50"/>`; //CON HTML
    info.id = "leonardoessilorluxottica-" + course.courseIdMaster + '-' + sessionId + '-' + (new Date()).getTime();

    if (isLivestreamCourse(course)) {
      info.descriptionRow1 = course.courseFullName;

      if (course.liveInfo?.[0]) {
        info.start = getUTCISOFromString(course.liveInfo[0].timeStart, timezone);
        info.end = getUTCISOFromString(course.liveInfo[0].timeEnd, timezone);
        const dateAndHoursSplitted = course.liveInfo[0].timeStart.split(' ');
        info.descriptionRow2 = lang?.ICS_TAKE_PLACE_LIVESTREAM
          ?.replace("{TIME}", formatDateInAMPM(dateAndHoursSplitted[1].replaceAll(":", ".")) + " " + timezone)
          .replace("{DATE}", printDate(dateAndHoursSplitted[0], lang));
      }
    } else {
      info.descriptionRow1 = (isFaceToFace(course) ? lang?.ICS_BOOKED_F2F : lang?.ICS_BOOKED_VC)
        + "    \\n"
        + course.courseFullName;

      if (sessionId && course.courseSessions && course.courseSessions.length > 0) {
        const sessions = course.courseSessions.filter(a => a.id === sessionId);
        if (sessions && sessions.length > 0) {
          const session = sessions[0];

          //get location if present
          if (session.location) {
            info.location = session.location;
          }

          // info.start = getDateArrayFromString(session.sessionDates[0].timeStart, timezone);
          // info.end = getDateArrayFromString(session.sessionDates[0].timeFinish, timezone);

          info.start = getUTCISOFromString(session.sessionDates[0].timeStart, timezone);
          info.end = getUTCISOFromString(session.sessionDates[0].timeFinish, timezone);

          const dateAndHoursSplitted = session.sessionDates[0].timeStart.split(' ');
          info.descriptionRow2 = lang?.ICS_TAKE_PLACE_VC
            ?.replace("{TIME}", formatDateInAMPM(dateAndHoursSplitted[1].replaceAll(":", ".")) + " " + timezone)
            .replace("{DATE}", printDate(dateAndHoursSplitted[0], lang));
        }
      }
    }
  }

  if (!info.title || !info.start || !info.end) {
    console.error('Error generating ICS info');
    return;
  }

  let status = "CONFIRMED";
  let method = "PUBLISH";

  // to cancel an event, create an event with:
  // status = CANCELLED
  // method = CANCEL
  // uid = uid of the event to cancel
  // if(isCancel) {
  //     status = 'CANCELLED';
  //     method = 'CANCEL';
  // }


  //create ics event
  const eventIcs = {
    start: info.start,
    end: info.end,
    title: info.title,
    description: info.description,
    location: info.location,
    status: status,
    method: method,
    descriptionRow1: info.descriptionRow1,
    descriptionRow2: info.descriptionRow2,
    descriptionRow3: info.descriptionRow3,
    leonardoIcon: info.leonardoIcon,
  };

  const icsValue = "BEGIN:VCALENDAR\r\n" +
    "VERSION:2.0\r\n" +
    "CALSCALE:GREGORIAN\r\n" +
    "PRODID:EssilorLuxotticaUniversity\r\n" +
    "METHOD:" + eventIcs.method + "\r\n" +
    "BEGIN:VEVENT\r\n" +
    "UID:" + info.id + "\r\n" +
    "DTSTAMP:" + new Date().toISOString() + "\r\n" +
    "DTSTART:" + eventIcs.start + '\r\n' + // eventIcs.start[0] + eventIcs.start[1] + eventIcs.start[2] + "T" + eventIcs.start[3] + eventIcs.start[4] + "00\r\n" +
    "DTEND:" + eventIcs.end + '\r\n' + //eventIcs.end[0] + eventIcs.end[1] + eventIcs.end[2] + "T" + eventIcs.end[3] + eventIcs.end[4] + "00\r\n" +
    "SUMMARY:" + eventIcs.title + "\r\n" +
    "LOCATION:" + eventIcs.location + "\r\n" +
    //line break (\\n) doesn't work well. So I have add some spaces "    " to correctly wrap the text
    "DESCRIPTION:" + eventIcs.descriptionRow1 + "\\n\\n" + eventIcs.descriptionRow2 + "    \\n" + eventIcs.descriptionRow3 + "\r\n" +
    "END:VEVENT" + "\r\n" +
    "END:VCALENDAR";
  // "DESCRIPTION:" + "<br>" + eventIcs.descriptionRow1 + "<br>" + "<br>" + eventIcs.descriptionRow2 + "<br>" + eventIcs.descriptionRow3 + "<br>" + "<br>" + eventIcs.leonardoIcon + "<br>"; //CON HTML

  //download ics file
  console.log("ICSVALUE", icsValue);
  let link = document.createElement("a");
  link.href = "data:text/calendar;charset=utf8," + encodeURIComponent(icsValue);
  //define the file name
  link.download = info.title + ".ics";
  link.click();

  // var blob = new Blob([icsValue], { type: 'text/calendar; charset=utf-8' });
  // var link = document.createElement('a');
  // link.setAttribute('href', window.URL.createObjectURL(blob));
  // link.setAttribute('download', info.title + ".ics");
  // link.click();
};

export const getUTCISOFromString = (date: string, timezone: string = 'UTC') => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);

  const hour = date.substring(11, 13);
  const minute = date.substring(14, 16);

  //format example 2020-09-10T12:57:00.000
  let dateLuxonUTC = DateTime.fromISO(year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00.000', { zone: timezone });
  //format example 20200910T125700Z
  return dateLuxonUTC.toUTC().toISO()
    .replace(/-/g, '')
    .replace(/:/g, '')
    .replace('.000', '');
}

export const getDateArrayFromString = (date: string, timezone: string) => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);

  const hour = date.substring(11, 13);
  const minute = date.substring(14, 16);

  //format example 2020-09-10T12:57:00.000
  const dateTimezone = new Date(year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00.000');

  //remove timezone
  const dateLocal = fromTimezoneToLocal(dateTimezone, timezone);

  let dateArray = [dateLocal.getFullYear(), dateLocal.getMonth() + 1, dateLocal.getDate(), dateLocal.getHours(), dateLocal.getMinutes()];
  return [
    convertDateNumToString(dateArray[0], 4),
    convertDateNumToString(dateArray[1], 2),
    convertDateNumToString(dateArray[2], 2),
    convertDateNumToString(dateArray[3], 2),
    convertDateNumToString(dateArray[4], 2)
  ];
}

const convertDateNumToString = (dateNum, maxLength) => {
  var mystring = '' + dateNum;
  while (mystring.length < maxLength) {
    mystring = '0' + mystring;
  }

  return mystring;
}

export const fromTimezoneToLocal = (date: Date, timezone: string): Date => {
  // suppose the date is 12:00 UTC
  try {
    var invdate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  } catch (e) {
    console.log("ERROR", e);
    var invdate = new Date(date.toLocaleString('en-US', { timeZone: "America/New_York" }));
  }

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // console.log('DIFF', diff / 1000 / 60 / 60)

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() + diff);
}

export const getLocalDateFromString = (date: string, timezone: string): Date => {
  if (!date || !timezone) {
    return null;
  }

  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);

  const hour = date.substring(11, 13);
  const minute = date.substring(14, 16);
  const second = date.substring(17, 19);

  //format example 2020-09-10T12:57:00.000
  const dateTimezone = new Date(year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.000');

  //remove timezone
  return fromTimezoneToLocal(dateTimezone, timezone);
}