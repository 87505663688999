export class BrowseByTopicInfo {
  [key: string]: {
    channelId: string;
    numberOfEvents: number;
    numberOfCourses: number;
    numberOfLessons: number;
  };
}

export class BrowseByTopicInfoWrapper {
  isLoading: boolean = false;
  info: BrowseByTopicInfo = {};
}
