import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ErrorMessage from "@components/UI/ErrorMessage";
import TextField from "@components/UI/TextField";
import { useSelector } from "@hooks/redux";
import { UseFormMethods } from "react-hook-form";
import { LangMap } from "@model/CoursesClass";
import { useIMask } from "react-imask";
import { handleOnEnterKeyPress, mergeRefs } from "@utility/Api";
import { UserProfile } from "@model/User";
import CardLoader from "./CardLoader";
import Button from "@components/UI/Button";
import ButtonV2 from "@components/UI/ButtonV2";
import {
  convertStringToBulletedString,
  isCreditCardExpired,
  luhnCheck,
} from "@utility/ecommerceUtility";
import { CARD_VALIDATION } from "@utility/const";
import { printDateMMYYYY } from "@utility/Api";
import CheckboxExternalChecked from "@components/UI/CheckboxExternalChecked";
import AmexIcon from "@svg-icons/amex-card_smaller.svg";
import DiscoverIcon from "@svg-icons/discover-card_smaller.svg";
import MastercardIcon from "@svg-icons/mastercard_smaller.svg";
import VisaIcon from "@svg-icons/visa-card_smaller.svg";
import CreditCardIcon from "@svg-icons/credit-card.svg";
import useFormContentPayment from "@hooks/useFormContentPayment";
import Checkbox from "./Checkbox";
import { getPaymentDetails } from "@redux-actions/";
import PaymentInstructionsSkeleton from "@components/skeletons/PaymentInstructionsSkeleton";
import TagBadge from "./TagBadge";
import LazySvg from "@components/LazySvg";
import { ErrorTracking, ErrorTypes } from "@model/TrackingClass";
import { triggerErrorEvent } from "@utility/analytics-utils";
import { styledLogUtagView } from "@components/link-utils";

export type FormValues = {
  cardnumber: string;
  ccname: string;
  cvc: string;
  "cc-exp": string;
  saveMethod?: boolean;
  makeDefault?: boolean;
};

type FormContentPaymentProps = UseFormMethods<FormValues> &
  ReturnType<typeof useFormContentPayment> & {
    handleEditCard?: (identifier: string | number) => void;
    handleDeleteCard?: (identifier: string | number) => void;
    canEdit?: boolean;
    formBottom?: boolean;
    isCheckoutPage?: boolean;
    hideCvc?: boolean;
  };

const iconStyles = {
  fill: "currentColor",
  width: 35,
  height: 22,
  viewBox: "0 0 35 22",
};

const FormItems = ({
  lang,
  register,
  isValidCNum,
  handleOnChange,
  onChangeCNum,
  errors,
  circuit,
  onFocus = () => { },
  disableName,
}) => {
  const imaskCardnumber = useIMask({ mask: "0000-0000-0000-0000" });
  const imaskCcExp = useIMask({ mask: "00/00" });

  const validateCcExp = (value: string): string => {
    console.log("validateCcExp", value);

    if (!value) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }

    value = value.replace("/", "");
    const month: number = +value.slice(0, 2);
    const year: number = +value.slice(2, 4);

    //month cannot be greater than 12
    if (month > 12) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }

    const today = new Date();
    const todayMonth: number = +today.getMonth() + 1;
    const todayYear: number = +today.getFullYear().toString().slice(2, 4);

    if (year < todayYear || (year === todayYear && month < todayMonth)) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }
  };

  const ccExpRef = mergeRefs(
    imaskCcExp.ref,
    register({
      validate: validateCcExp,
    })
  );

  console.log("disableName", disableName);

  return (
    <div className="form-payment-details__add-card-form">
      <div className="form-payment-details__item">
        <TextField
          type="text"
          name="cardnumber"
          id="frmCCNum"
          placeholder={lang?.CARD_NUMBER_PLACEHOLDER}
          mandatory
          ref={mergeRefs(
            imaskCardnumber.ref,
            register({
              validate: isValidCNum,
            })
          )}
          autoComplete="cc-number"
          aria-invalid={errors.cardnumber ? "true" : "false"}
          isError={!!errors.cardnumber ? true : false}
          onChange={(value) => {
            handleOnChange();
            onChangeCNum(value);
          }}
          onFocus={onFocus}
          inputMode="numeric"
          disableName={disableName}
          htmlFor="frmCCNum"
        />
        <ErrorMessage error={errors.cardnumber} />
      </div>
      <div className="form-payment-details__item">
        <TextField
          type="text"
          name="ccname"
          id="frmNameCC"
          placeholder={lang?.CCNAME_PLACEHOLDER}
          mandatory
          ref={register({
            required: lang?.CREDIT_CARD_NAME_REQUIRED,
          })}
          autoComplete="cc-name"
          aria-invalid={errors.ccname ? "true" : "false"}
          isError={!!errors.ccname ? true : false}
          onChange={handleOnChange}
          onFocus={onFocus}
          disableName={disableName}
          htmlFor="frmNameCC"
        />
        <ErrorMessage error={errors.ccname} />
      </div>
      <div className="form-payment-details__item flex justify-between">
        <div className="cc-exp">
          <TextField
            type="text"
            name="cc-exp"
            id="frmCCExp"
            placeholder={lang?.CC_EXPIRY_PLACEHOLDER}
            mandatory
            ref={ccExpRef}
            autoComplete="cc-exp"
            aria-invalid={errors["cc-exp"] ? "true" : "false"}
            isError={!!errors["cc-exp"] ? true : false}
            onChange={handleOnChange}
            inputMode="numeric"
            onFocus={onFocus}
            disableName={disableName}
            htmlFor="frmCCExp"
          />
          <ErrorMessage error={errors["cc-exp"]} />
        </div>
        <div className="cvc">
          <TextField
            type="number"
            name="cvc"
            id="cvc-2"
            htmlFor="cvc-2"
            placeholder={lang?.CVC_PLACEHOLDER}
            mandatory
            ref={register({
              required: lang?.CREDIT_CARD_CVV_REQUIRED,
              pattern: {
                value: CARD_VALIDATION[circuit]?.cvcPattern,
                message: lang?.CREDIT_CARD_CVV_REQUIRED,
              },
            })}
            autoComplete="cc-csc"
            aria-invalid={!disableName && errors.cvc ? "true" : "false"}
            isError={!!!disableName && errors.cvc ? true : false}
            onChange={handleOnChange}
            inputMode="numeric"
            onFocus={onFocus}
            disableName={disableName}
          />
          <ErrorMessage error={!disableName && errors.cvc} />
        </div>
      </div>
    </div>
  );
};

const FormContentPayment = ({
  register,
  unregister,
  reset,
  control,
  errors,
  trigger,
  watch,
  handleOnChange,
  isAdding,
  setIsEditing,
  isEditing,
  setIsAdding,
  setSelectedAccount,
  selectedAccount,
  clearErrors,
  loading,
  handleEditCard,
  handleDeleteCard,
  canEdit,
  showForm,
  setShowGenericError,
  formBottom,
  isCheckoutPage,
  hideCvc,
}: FormContentPaymentProps) => {
  console.log("selectedAccount", selectedAccount);
  const dispatch = useDispatch();

  const lang: LangMap = useSelector((state) => state.utils.lang);
  const userProfile: UserProfile = useSelector(
    (state) => state.user.userProfile
  );
  const formValues = watch(["cvc", "cc-exp"]);

  //ANALYTICS
  useEffect(() => {
    if(Object.keys(errors).length >= 1) {
      let labelShownToTheUser = Object.values(errors)?.[0]?.message;
      let utagErrorData:  ErrorTracking = {
        Error_Source: ErrorTypes.user,
        Error_Code: 'checkout',
        Error_Message: labelShownToTheUser
      }
      triggerErrorEvent(utagErrorData);
      styledLogUtagView(utagErrorData?.Error_Message, utagErrorData)
    }
  }, [errors])

  const imaskCardnumber = useIMask({ mask: "0000-0000-0000-0000" });
  const imaskCcExp = useIMask({ mask: "00/00" });

  const [circuit, setCircuit] = useState<string>("generic");
  const [isCCNumFilled, setIsCCNumFilled] = useState<boolean>(false);

  const recognizeCardCircuit = (value: string): string => {
    let circuitTemp = "generic";

    if (!value) {
      return circuitTemp;
    }

    for (let cardValidation of Object.values(CARD_VALIDATION)) {
      if (
        cardValidation.type !== "generic" &&
        cardValidation.pattern.test(value)
      ) {
        circuitTemp = cardValidation.type;
      }
    }

    return circuitTemp;
  };

  const validateCcExp = (value: string): string => {
    console.log("validateCcExp", value);

    if (!value) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }

    value = value.replace("/", "");
    const month: number = +value.slice(0, 2);
    const year: number = +value.slice(2, 4);

    //month cannot be greater than 12
    if (month > 12) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }

    const today = new Date();
    const todayMonth: number = +today.getMonth() + 1;
    const todayYear: number = +today.getFullYear().toString().slice(2, 4);

    if (year < todayYear || (year === todayYear && month < todayMonth)) {
      return lang?.CREDIT_CARD_EXPIRE_DATE_REQUIRED;
    }
  };

  const onChangeCNum = (v: string) => {
    //remove "-" for validation
    setIsCCNumFilled(!!v && isEditing);
    const value = v.replace(/[- ]/g, "");
    const recognizedCircuit = recognizeCardCircuit(value);
    setCircuit(recognizedCircuit);
    return recognizedCircuit;
  };

  const isValidCNum = (v: string): string | boolean => {
    //remove "-" for validation
    const value = v ? v?.replace(/[- ]/g, "")?.slice(0, 16) : "";
    // console.log(`value`, value)

    if (!value) {
      return lang?.CREDIT_CARD_NUMBER_INVALID;
    }

    const recognizedCircuit = onChangeCNum(value);

    if (!luhnCheck(value)) {
      return lang?.CREDIT_CARD_NUMBER_INVALID;
    }

    if (formValues["cc-exp"]) {
      trigger("cc-exp");
    }
    if (formValues["cvc"]) {
      trigger("cvc");
    }

    //check format and length
    const format = cloneDeep(CARD_VALIDATION[recognizedCircuit]?.format);

    if (
      !format?.test(value) ||
      !CARD_VALIDATION[recognizedCircuit].length.includes(
        value.toString().length
      )
    ) {
      console.log("error - invalid card number");
      return lang?.CREDIT_CARD_NUMBER_INVALID;
    }
  };

  const paymentInstructions = useSelector((state) =>
    canEdit
      ? state.ecommerce.paymentInfo
      : state.ecommerce.paymentInfo?.filter(
        (paymentInstruction) =>
          !isCreditCardExpired(paymentInstruction.expireDate)
      )
  );
  const isLoadingPaymentInfo = useSelector(
    (state) => state.ecommerce.isLoadingPaymentInfo
  );

  useEffect(() => {
    if (!canEdit && !isLoadingPaymentInfo) {
      setIsAdding(!paymentInstructions?.length);
    }
  }, [!!paymentInstructions?.length]);

  useEffect(() => {
    clearErrors();
  }, [isAdding]);

  useEffect(() => {
    if (
      paymentInstructions?.length > 0 &&
      ((!isAdding && formBottom) || !formBottom) &&
      !isLoadingPaymentInfo
    ) {
      const firstIdentifier = paymentInstructions?.find((pi) => pi.isDefault)
        ?.identifier;
      firstIdentifier && setSelectedAccount(firstIdentifier);
    }
  }, [isLoadingPaymentInfo]);

  const handleAddCard = (e, bool: boolean) => {
    e.preventDefault();
    setShowGenericError(false);
    setIsAdding(bool);
  };

  const onSelectAccount = (id: number) => {
    setSelectedAccount(id);
    selectedAccount !== id && clearErrors();
  };

  return (
    <>
      {isLoadingPaymentInfo && <CardLoader />}
      <>
        {isLoadingPaymentInfo && <PaymentInstructionsSkeleton />}
        {/* <div className="checkbox">
            <Checkbox
              className="filter-modal__checkbox-active-now ecommerce"
              text={lang?.SAVE_CARD_ACCOUNT}
              name="saveAddress"
              ref={register}
            />
          </div> */}

        {showForm && !isLoadingPaymentInfo && !formBottom && (
          <>
            <div className="form-payment-details__item">
              <TextField
                type="text"
                name="cardnumber"
                id="frmCCNum"
                placeholder={/*
                  isEditing && !isCCNumFilled
                    ? "•".repeat(16)
                    :*/ lang?.CARD_NUMBER_PLACEHOLDER
                }
                mandatory
                ref={mergeRefs(
                  imaskCardnumber.ref,
                  register({
                    validate: (v) => (/*!v && isEditing ? true : */isValidCNum(v)),
                  })
                )}
                autoComplete="cc-number"
                aria-invalid={errors.cardnumber ? "true" : "false"}
                isError={!!errors.cardnumber ? true : false}
                onChange={(value) => {
                  handleOnChange();
                  onChangeCNum(value);
                }}
                inputMode="numeric"
              />
              <ErrorMessage error={errors.cardnumber} />
            </div>
            <div className="form-payment-details__item">
              <TextField
                type="text"
                name="ccname"
                id="frmNameCC"
                placeholder={lang?.CCNAME_PLACEHOLDER}
                mandatory
                ref={register({
                  required: lang?.CREDIT_CARD_NAME_REQUIRED,
                })}
                autoComplete="cc-name"
                aria-invalid={errors.ccname ? "true" : "false"}
                isError={!!errors.ccname ? true : false}
                onChange={handleOnChange}
              />
              <ErrorMessage error={errors.ccname} />
            </div>
            <div className="form-payment-details__item flex justify-between">
              <div className="cc-exp">
                <TextField
                  type="text"
                  name="cc-exp"
                  id="frmCCExp"
                  placeholder={lang?.CC_EXPIRY_PLACEHOLDER}
                  mandatory
                  ref={mergeRefs(
                    imaskCcExp.ref,
                    register({
                      validate: validateCcExp,
                    })
                  )}
                  autoComplete="cc-exp"
                  aria-invalid={errors["cc-exp"] ? "true" : "false"}
                  isError={!!errors["cc-exp"] ? true : false}
                  onChange={handleOnChange}
                  inputMode="numeric"
                />
                <ErrorMessage error={errors["cc-exp"]} />
              </div>
              <div className="cvc">
                <TextField
                  type="number"
                  name="cvc"
                  id="cvc"
                  placeholder={lang?.CVC_PLACEHOLDER}
                  mandatory
                  ref={register({
                    required: lang?.CREDIT_CARD_CVV_REQUIRED,
                    pattern: {
                      value: CARD_VALIDATION[circuit]?.cvcPattern,
                      message: lang?.CREDIT_CARD_CVV_REQUIRED,
                    },
                  })}
                  autoComplete="cc-csc"
                  aria-invalid={errors.cvc ? "true" : "false"}
                  isError={!!errors.cvc ? true : false}
                  onChange={handleOnChange}
                  inputMode="numeric"
                />
                <ErrorMessage error={errors.cvc} />
              </div>
            </div>
          </>
        )}
        {(!showForm || formBottom) && !isLoadingPaymentInfo && (
          <>
            <div className="form-payment-details__choices">
              {paymentInstructions.map((paymentInstruction, index) => (
                <div
                  className="form-payment-details__choice focus-outline"
                  key={paymentInstruction.identifier}
                  onClick={(e) => {
                    if (formBottom) {
                      reset();
                      unregister("cardnumber");
                      unregister("cc-exp");
                      unregister("ccname");
                      unregister("saveMethod");
                      if (isAdding) {
                        handleAddCard(e, false);
                      }
                    }
                    onSelectAccount(paymentInstruction.identifier);
                  }}
                  // onKeyDown={e =>  {  //FIX LEON-4111
                  //   if (formBottom) {
                  //     reset();
                  //     unregister("cardnumber");
                  //     unregister("cc-exp");
                  //     unregister("ccname");
                  //     unregister("saveMethod");
                  //     if (isAdding) {
                  //       handleAddCard(e, false);
                  //     }
                  //   }
                  //   onSelectAccount(paymentInstruction.identifier);
                  // }}
                >
                  <div className="form-payment-details__choice-item">
                    <div className="checkbox">
                      {/* <Checkbox
                        defaultChecked={
                          paymentInstruction.identifier === selectedAccount
                        }
                        className="filter-modal__checkbox-active-now ecommerce"
                        text=""
                        name="card-select"
                        ref={register}
                      /> */}

                      {(selectedAccount || formBottom) && !canEdit && (
                        <CheckboxExternalChecked
                          checked={
                            paymentInstruction.identifier === selectedAccount
                          }
                          text=""
                          onChange={() => {
                            onSelectAccount(paymentInstruction.identifier);
                          }}
                        />
                      )}
                    </div>

                    <div className="card-info">
                      <div className="card-image">
                        {paymentInstruction.payMethodId ===
                          CARD_VALIDATION.amex.payMethodId && (
                            <AmexIcon {...iconStyles} />
                          )}

                        {paymentInstruction.payMethodId ===
                          CARD_VALIDATION.discover.payMethodId && (
                            <DiscoverIcon {...iconStyles} />
                          )}

                        {paymentInstruction.payMethodId ===
                          CARD_VALIDATION.mastercard.payMethodId && (
                            <MastercardIcon {...iconStyles} />
                          )}

                        {paymentInstruction.payMethodId ===
                          CARD_VALIDATION.visa.payMethodId && (
                            <VisaIcon {...iconStyles} />
                          )}
                      </div>
                      <div className="card-info__user">
                        <div className="card-info__user-info">
                          <span className="name">
                            {paymentInstruction.owner}
                          </span>
                          <span className="number">
                            {isCheckoutPage ? 
                              convertStringToBulletedString(
                                paymentInstruction.creditCardPan,
                                4,
                                12,
                                true
                              ) : 
                              convertStringToBulletedString(
                                paymentInstruction.creditCardPan,
                                4,
                                4
                              )
                            }
                          </span>
                        </div>
                        <div className="expiration">
                          {canEdit &&
                            isCreditCardExpired(
                              paymentInstruction.expireDate
                            ) &&
                            lang.EXPIRED && (
                              <TagBadge
                                className={
                                  "form-payment-details__tagbadge background-red"
                                }
                                label={lang.EXPIRED}
                              />
                            )}

                          {canEdit &&
                            paymentInstruction.isDefault &&
                            lang.DEFAULT && (
                              <TagBadge
                                className={
                                  "form-payment-details__tagbadge background-blue"
                                }
                                label={lang.DEFAULT}
                              />
                            )}
                          <span className="expiration-date">
                            {printDateMMYYYY(
                              new Date(paymentInstruction.expireDate?.replaceAll('-', '/'))
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {paymentInstruction.identifier === selectedAccount &&
                    !canEdit && !hideCvc && (
                      <div className={"cvc" + (isCheckoutPage ? " checkout-page__cvc" : "")}>
                        {isCheckoutPage && paymentInstruction.isDefault &&
                          paymentInstruction.identifier === selectedAccount &&
                          lang.DEFAULT && (
                            <TagBadge
                              className={
                                "form-payment-details__tagbadge"
                              }
                              label={lang.DEFAULT}
                            />
                          )
                        }
                        <TextField
                          type="number"
                          name="cvc"
                          id="cvc"
                          labelFixedTop={isCheckoutPage ? false : true}
                          placeholder={lang?.CVC_PLACEHOLDER}
                          mandatory
                          ref={register({
                            required: lang?.CREDIT_CARD_CVV_REQUIRED,
                            pattern: {
                              value: CARD_VALIDATION[circuit]?.cvcPattern,
                              message: lang?.CREDIT_CARD_CVV_REQUIRED,
                            },
                          })}
                          autoComplete="cc-csc"
                          aria-invalid={errors.cvc ? "true" : "false"}
                          isError={!!errors.cvc ? true : false}
                          onChange={handleOnChange}
                          inputMode="numeric"
                          disabled={loading}
                          isDirty={!!formValues.cvc && isCheckoutPage}
                        />
                        <ErrorMessage error={errors.cvc} />
                      </div>
                    )}
                  {canEdit && (
                    <div className="form-payment-details__choice-ctas flex justify-end">
                      <ButtonV2
                        variant="text-btn-no-arrow"
                        small
                        type="button"
                        className="form-payment-details__delete-card"
                        loading={false}
                        handleClick={(e) => {
                          e?.preventDefault();
                          handleDeleteCard(paymentInstruction.identifier);
                        }}
                        disabled={false}
                      >
                        {lang.DELETE}
                      </ButtonV2>
                      {/* LEON-6548 */}
                      {/* <Button
                        type="button"
                        className="form-payment-details__edit-card"
                        loading={false}
                        handleClick={(e) => {
                          e.preventDefault();
                          setIsEditing(true);
                          handleEditCard(paymentInstruction.identifier);
                        }}
                        disabled={false}
                      >
                        {lang.EDIT}
                      </Button> */}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {!canEdit && paymentInstructions?.length > 0 && (
              <ButtonV2
                variant="text-btn-no-arrow"
                small
                type="button"
                className={
                  "form-payment-details__add-card blue-checkout-text-desktop " +
                  (formBottom ? "form-bottom" : "")
                }
                loading={false}
                handleClick={(e) => { onSelectAccount(null); handleAddCard(e, true) }}
                disabled={false}
              >
                 <LazySvg src="/images/svg-icons/plus2.svg" alt={lang.ADD_NEW_CARD} className="plus-icon"></LazySvg>
                  {lang.ADD_NEW_CARD}
              </ButtonV2>
            )}
          </>
        )}

        {showForm && !isLoadingPaymentInfo && formBottom && (
          <FormItems
            {...{
              lang,
              register,
              isValidCNum,
              handleOnChange,
              onChangeCNum,
              errors,
              circuit,
              disableName: !!selectedAccount && formBottom,
            }}
            onFocus={() => {
              if (formBottom) {
                onSelectAccount(null);
              }
            }}
          />
        )}

        {/* <div className="checkbox">
            <Checkbox
              className="filter-modal__checkbox-active-now ecommerce"
              text={lang?.SAVE_CARD_ACCOUNT}
              name="saveAddress"
              ref={register}
            />
          </div> */}
      </>
    </>
  );
};

export default FormContentPayment;

export const FormContentPaymentWrapper = ({
  children,
  title,
  titleComponent,
  hideBackground,
  handleClick = () => {},
}: {
  children: React.ReactNode;
  title?: string;
  titleComponent?: React.ReactNode;
  hideBackground?: boolean;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div
      className={`checkout-page__payments-details ${!hideBackground ? "background" : ""
        }`}
    >
      {!hideBackground && (
        !titleComponent && title ? 
        <div className="title flex justify-between align-center">
          <div className="flex">
            <h3>{title}</h3>
          </div>
          <CreditCardIcon fill="currentColor" />
        </div>
        :
        <div className="title flex justify-between align-center"
          onClick={handleClick}
        >
          {titleComponent}
        </div>
      )}
      {children}
    </div>
  );
};
