import React, { CSSProperties, useEffect } from "react";
import RadioButton, {
  Props as RadioButtonProps,
  ItemRadioButton,
} from "./RadioButton";
import { motion, useAnimation } from "framer-motion";
import { scaleFadeInVariants } from "../animations/variants";

const parentVariants = {
  visible: { transition: { staggerChildren: 0.5 } },
  hidden: { transition: { staggerChildren: 0.5 } },
};

export const defaultRatingItems: ItemRadioButton[] = [
  { value: 1, label: '1', checked: false },
  { value: 2, label: '2', checked: false },
  { value: 3, label: '3', checked: false },
  { value: 4, label: '4', checked: false },
  { value: 5, label: '5', checked: false },
];

const positionStyles: { [key: string]: CSSProperties } = {
  bottom: {
    gridTemplateColumns: "auto",
    justifyItems: "center",
  },
  right: {
    gridTemplateColumns: "min-content auto",
  },
};

export type Props = {
  handleOnClick?: (item: string | number) => void;
  items: ItemRadioButton[];
  fontSize?: string;
  positionLabel: "right" | "bottom";
  checked?: string | number;
  isRating?: boolean;
  hideLabel?: boolean;
  readOnly?: boolean;
  iconBig?: boolean;
  htmlName?: string;
  isVertical?: boolean;
  showCircle?: boolean;
  disableAnimation?: boolean;
} & Pick<RadioButtonProps, "fontSize" | "handleOnClick" | "positionLabel"> & {allowSeeChecked?: boolean, isUsergroup?: boolean};

const RadioButtonGroup = ({
  handleOnClick,
  items,
  fontSize,
  positionLabel,
  checked,
  isRating,
  hideLabel,
  readOnly = false,
  iconBig = false,
  htmlName,
  isVertical = false,
  showCircle = false,
  disableAnimation = false,
  allowSeeChecked = false,
  isUsergroup = false
}: Props) => {
  const controlsChildren = useAnimation();

  const onClick = (val) => {
    if (!readOnly) {
      handleOnClick(val)

      let scale = 1.2;
      controlsChildren.start(i => {
        if (i <= (val - 1)) {
          scale += 0.2;
          return {
            scale: [1, scale, 1],
            opacity: [0, 1],
            transition: {
              // duration: 1,
              delay: i * 0.3,
            },
          }
        }
      })
    }
  }

  return (
    <div className={"radio-group" + (isVertical ? ' vertical' : ' horizontal')}>
      <motion.div
        // style={{ display: "flex" }}
        variants={disableAnimation ? null : parentVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {items.map((item, i) => (
          <label
            className={"radio " + positionLabel}
            key={item.value}
            style={{
              fontSize,
              ...positionStyles[positionLabel],
            }}
          >
            <motion.div variants={disableAnimation ? null : scaleFadeInVariants} animate={controlsChildren} custom={i} key={item.value} className="radio-container">
              <RadioButton
                value={item.value}
                labelText={item.label}
                hideLabel={hideLabel}
                fontSize={fontSize}
                readOnly={readOnly}
                checked={isUsergroup ? (checked === item.value && allowSeeChecked) : (checked === item.value || (isRating && checked > item.value))}
                handleOnClick={() => onClick(item.value)}
                positionLabel={positionLabel}
                iconBig={iconBig}
                htmlName={htmlName}
                showCircle={showCircle}
              />
            </motion.div>
            {!hideLabel && item.label && (
              <span className="radio__label">
                {item.label}
              </span>
            )}
          </label>
        ))}
      </motion.div>
    </div>
  );
};

RadioButtonGroup.defaultProps = {
  positionLabel: 'bottom'
}

export default RadioButtonGroup;
