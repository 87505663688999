import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from '@hooks/redux';
import ReactSelect, { OptionTypeBase, Props as SelectProps, components } from 'react-select';
import clsx from '@utility/clsx';
const { ValueContainer, Placeholder } = components;
// import ChevronDown from '@svg-icons/chevron-down.svg';
import AlertIcon from '@svg-icons/alert.svg';
import ArrowDown from '@svg-icons/arrowhead-down.svg';
import ArrowDown2 from '@svg-icons/ArrowDown2.svg';
import ArrowDown3 from '@svg-icons/ArrowDown3.svg';
import SearchIcon from '@svg-icons/header/search.svg';
import lang from '@utility/lang';
import { isOptionDisabled } from 'react-select/src/builtins';
import { isSearchPage } from '@components/link-utils';
import LazySVG from '@components/LazySvg';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';

export type Props = {
  isLoading?: boolean;
  isSelectUpsideDown?: boolean;
  isShortModal?: boolean;
  className?: string;
  classNameWrapper?: string;
  removeLeftPadding?: boolean;
  noMaxHeightList?: boolean;
  fillSpace?: boolean;
  noFillMobile?: boolean;
  keyboardActive?: boolean;
  id?: string;
  autocompleteNewPassword?: boolean;
  variant?: 'border';
  mandatory?: boolean;
  isError?: boolean;
  labelFixedTop?: boolean;
  iconSvg?: {
    src: string;
    alt?: string;
  };
} & SelectProps<OptionTypeBase>;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props} className={`${props.selectProps.iconSvg ? 'icon-svg' : ''}`}>
      {props.selectProps.iconSvg && (
        <div className="icon-svg_wrapper">
          <img src={props.selectProps.iconSvg.src} alt={props.selectProps.iconSvg.alt} />
        </div>
      )}

      {props.selectProps.id !== "role-select" && (
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
          <div className="iconSearch">
            {props.selectProps.inputValue !== "" ?
              <LazySVG src="/images/svg-icons/x-icon2.svg" alt="" className='dropdown_close_icon' />
              : <SearchIcon />
            }
          </div>
        </Placeholder>
      )}
      {React.Children.map(children, child => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
  /* return (
    <>
      <ValueContainer {...props} className={`${props.selectProps.iconSvg ? 'icon-svg' : ''}`}>
        {props.selectProps.iconSvg && (
          <div className='icon-svg_wrapper'>
            <img src={props.selectProps.iconSvg.src} alt={props.selectProps.iconSvg.alt} />
          </div>
        )}
        {React.Children.map(children, child => (child && child.type !== Placeholder ? child : null))}
      </ValueContainer>
      {!props.selectProps.iconSvg && (
        <Placeholder {...props} isFocused={props.isFocused}>
          {lang?.SEARCH_PLACEHOLDER}
        </Placeholder>
      )}
      </>
  ); */
};

const CustomSelect = React.forwardRef<ReactSelect, Props>(
  (
    {
      labelFixedTop = true,
      isSelectUpsideDown,
      isShortModal,
      options,
      className,
      removeLeftPadding,
      noMaxHeightList,
      fillSpace,
      //keyboardActive = true,
      id,
      autocompleteNewPassword = false,
      noFillMobile,
      variant,
      mandatory = false,
      isError = false,
      classNameWrapper,
      ...rest
    },
    inputRef
  ) => {
    const [inFocus, setInFocus] = useState<boolean>(false);
    const [keyboardActive, setKeyboardActive] = useState(false);
    const lang = useSelector(state => state.utils.lang);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [hidePlaceholder, setHidePlaceholder] = useState<boolean>(false);
    const [placeHolder, setPlaceHolder] = useState<string>(lang?.SEARCH_LABEL || 'Search');
    const { isPhoneOrTabletPortrait, isDesktop, isPhone, isTablet } = useBreakpointBoolean();
    const visibleValueRef = useRef(null)

    const DropdownIndicator = props => {

      return (
        <div className="wrapper-icon with-icon-suffix">
          {isError && <AlertIcon id="alert-icon" />}
          <components.DropdownIndicator {...props}>
            {className?.includes("recommended-in-todo") || className?.includes("todo") || className?.includes("custom-select-sortings") ?
              isPhoneOrTabletPortrait ? <ArrowDown3/> : <ArrowDown2 /> 
              : <ArrowDown/>}
          </components.DropdownIndicator>
        </div>
      );
    };
    const upsideDownShortModal = isSelectUpsideDown && isShortModal;
    const classNameDefault = clsx('custom-select-v3', {
      'select-upsidedown': upsideDownShortModal,
      'no-padding-left': removeLeftPadding,
      'one-opt': upsideDownShortModal && options.length === 1,
      'two-opt': upsideDownShortModal && options.length === 2,
      'no-max-height-list': noMaxHeightList && options.length < 15,
      'fill-space': fillSpace,
      'no-fill-mobile': noFillMobile,
      'keyboard-active': keyboardActive,
      mandatory: mandatory,
      isError: isError,
      'input--error': isError,
      inFocus: inFocus,
      [className]: className,
      // [variant]: variant,
      'no-placeholder': !!rest.isSearchable ? false : true,
    });

    const label = rest.placeholder;
    delete rest?.placeholder;

    const checkHasValue = () => {
      const parent = document.querySelector(
        `.form__wrapper_select[data-name='${label}'] .custom-select__value-container`
      );
      if (parent && parent.classList.contains('custom-select__value-container--has-value'))
        setHasValue(true);
      else setHasValue(false);
    };

    useEffect(() => {
      checkHasValue();
    }, [rest]);

    useEffect(() => {
      const isTodoOrRecommended = className?.includes("recommended-in-todo") || className?.includes("todo");
      const isSorting = className?.includes("custom-select-sortings");

      const languageSingleValue = document.querySelector(".languages .custom-select__single-value");
      languageSingleValue?.setAttribute('tabindex', '0');

      if ((isTodoOrRecommended || isSorting) && //affect todo, recommended, or sorting
          (visibleValueRef?.current?.label !== rest.value?.label || //compute size when label has changed
            (!isPhoneOrTabletPortrait && !isSorting) || //avoid computing if phone or tablet-portrait (except for sorting)
            (!isPhone && isSorting) //avoid computing if phone (only for sorting)
          )
        ) {

          const selectControlContainer = document.querySelector(".custom-select__control") as HTMLElement;
          const selectControlContainerWidth = selectControlContainer?.offsetWidth;

          const selectSingleValue = document.querySelector(".custom-select__single-value") as HTMLElement;
          const selectSingleValueWidth = selectSingleValue?.offsetWidth;


          const icon = document.querySelector(".custom-select__indicators") as HTMLElement;

          if(icon) {
            //to even the value + arrow we get the difference between value + arrow and divide by two (8 is text's distance from arrow, 32 is icon's width)
            //remove 10 if desktop
            icon.style.paddingRight = `${(selectControlContainerWidth - selectSingleValueWidth - 8 - 32 - (isDesktop ? 10 : 0) + (isTablet && !isTodoOrRecommended ? 45 : 0)) / 2}px`; 
          }
      }

      visibleValueRef.current = rest.value;
             
    }, [rest.value?.label, visibleValueRef, isPhoneOrTabletPortrait, isPhone, isDesktop, isTablet])

    useEffect(() => {
      const handleKeyDown = () => {
        setKeyboardActive(true);
      };

      const handleMouseDown = () => {
        setKeyboardActive(false);
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('mousedown', handleMouseDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('mousedown', handleMouseDown);
      };
    }, []);

    return (
      <>
        <div
          className={clsx(
            'form__wrapper_select',
            mandatory && 'mandatory',
            !!classNameWrapper && classNameWrapper
          )}
          data-name={label}
        >
          {label && (
            <label
              className={clsx(
                'form__label',
                (labelFixedTop || hasValue) && 'form__label--fixedtop'
              )}
            >
              {label}
            </label>
          )}
          <ReactSelect
            id={id ? id : 'myid'}
            tabIndex='0'
            className={classNameDefault}
            classNamePrefix="custom-select"
            options={options}
            styles={{
              singleValue: provided => ({
                ...provided,
                position: 'static',
                textOverflow: 'none',
                WebkitTransform: 'none',
                transform: 'none',
                whiteSpace: 'normal',
              }),
              input: provided => ({
                ...provided,
                margin: 0,
                padding: 0,
              }),
            }}
            onInputChange={(value: any, actionMeta: any) => {
              // debugger
              checkHasValue();
              setHidePlaceholder(true);
            }}
            isOptionDisabled={option => option.disabled === true}
            inputRef={inputRef}
            aria-label={(rest.placeholder || rest.name) as string}
            // theme={theme => ({
            //   ...theme,
            //   colors: themeColorOverride,
            // })}
            {...rest}
            placeholder={hidePlaceholder ? '' : placeHolder}
            // menuIsOpen
            onFocus={e => {
              if (
                autocompleteNewPassword &&
                e?.target &&
                e.target.autocomplete !== 'new-password'
              ) {
                e.target.autocomplete = 'new-password';
              }
              // debugger
              setInFocus(true);
              setHidePlaceholder(false);
            }}
            isSearchable={rest.isSearchable}
            onBlur={() => {
              // debugger
              setInFocus(false);
              setHidePlaceholder(true);
            }}
            components={
              { ValueContainer: CustomValueContainer, DropdownIndicator }
              /* variant
              ? { ValueContainer: CustomValueContainer, DropdownIndicator }
               : { DropdownIndicator }*/
            }
          />
        </div>
      </>
    );
  }
);

CustomSelect.defaultProps = {
  isSearchable: false,
};

export default CustomSelect;
