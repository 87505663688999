export enum titles {
  LOGIN_TITLE = 'Login',
  LANDING = 'Landing',
  HOMEPAGE_TITLE = 'Homepage',
  REGISTER_TITLE = 'Sign up',
  REGISTER = 'Register',
  PASSWORD_RESET_TITLE = 'Password Reset',
  PASSWORD_EXPIRED_TITLE = 'Expired Password',
  FORGOT_PASSWORD_TITLE = 'Forgot Password',
  CATALOGUE_TITLE = 'Catalogue',
  PROFILE_TITLE = 'My Leonardo',
  PROFILE_ORDER_HISTORY = 'Order history',
  YOUR_TOKENS = 'Your tokens',
  PROFILE_ACTIVATE_LICENSE = 'Activate License',
  PROFILE_DASHBOARD = 'Dashboard',
  TO_DO_TITLE = 'To do',
  UPCOMING_EVENTS_TITLE = "What's coming up",
  UPCOMING_EVENTS = 'Live Sessions',
  MY_PROGRESS_TITLE = 'My progress',
  MY_CERTIFICATES_TITLE = 'My certificates',
  MY_TEAM_TITLE = 'My team',
  MY_TEAM_MEMBER_TITLE = 'Team member',
  MY_CHANNELS_TITLE = 'My channels',
  SETTINGS_TITLE = 'Settings',
  PERSONAL_SETTINGS_TITLE = 'Personal informations',
  WISHLIST_TITLE = 'Wishlist',
  NOTIFICATIONS_TITLE = 'Notifications',
  CONTACT_US_TITLE = 'Contact Us',
  MANAGE_COOKIES = 'Manage Cookies',
  LEGAL_TITLE = 'Legal, Privacy and Security',
  SEARCH_TITLE = 'Search',
  TPS_TITLE = 'Training pills',
  TP_DETAILS_TITLE = 'Details Training Pill',
  SEARCH_RESULTS = 'Search results',
  BRAND_PAGE = 'Brand page',
  NOT_FOUND_TITLE = '404',
  COMPATIBILITY_TITLE = 'Compatibility',
  MAINTENANCE_TITLE = 'Maintenance',
  HOWTO_TITLE = 'How-to section',
  DELETE_PROFILE_TITLE = 'Delete profile',
  SERVICE_PAGE_TITLE = 'App available soon',
  SEND_TOKEN = 'Send token',
  CART = 'Cart',
  CHECKOUT = 'Checkout',
  ORDER_CONFIRMATION = 'Order confirmation',
  PLAN = 'Plan',
  COMMERCIAL_OFFER = 'Commercial offer',
  ONBOARDING_COURSE = 'Onboarding',
  ONBOARDING_COURSE_OVERVIEW = 'Onboarding - Overview',
  EDUCATIONAL_PATHS = 'Programs',
  WELCOME = 'Welcome',
  LATEST_COLLECTIONS = 'Latest collections',
  COLLECTION = 'Collection',
  EMPTY_PAGE = 'Coming Soon',
  BUILD_YOUR_CAREER = 'BuildYourCareer',
  SUBJECT_MATTER_PROGRAM = 'SpecialtyPrograms',
  ROLE_SPECIFIC_PROGRAM = 'RoleSpecificProgram',
  CERTIFICATE_PROGRAM = 'CertificateProgram',
  OVERLAY = 'overlay',
  PAGE = 'page',
  STANDARD = 'Standard',
  FULL_CATALOG = 'Full',
  CHANNEL = 'Channel',
  BRAND = 'Brand',
  CMS = 'Cms',
  NOVELTIES = 'Novelties',
  RESULTS = 'Results',
  NEW_TO_YOUR_STORE ='NewToYourStore',
  HIGHLIGHTS_COLLECTIONS = 'highlights-collections',
  PILL = 'Pill',
  PERSONIFICATION = "Personification",
  DELETE_PROFILE = "Delete profile",
  //ANALYTICS W1
  PASSWORD_RESET_TITLE_ANALYTICS = 'Reset Password',
  PASSWORD_EXPIRED_TITLE_ANALYTICS = 'Expire Password',
  LATEST_COLLECTIONS_ANALYTICS = 'latest-collections',
  UPCOMING_EVENTS_ANALYTICS = 'LiveSessions',
  PROFILE_TITLE_ANALYTICS = 'MyLeonardo',
  TO_DO_TITLE_ANALYTICS = 'ToDo',
  RECOMMENDED_IN_TODO = 'Recommended',
  MY_PROGRESS_TITLE_ANALYTICS = 'MyProgress',
  MY_CERTIFICATES_TITLE_ANALYTICS = 'MyCertificates',
  MY_CHANNELS_TITLE_ANALYTICS = 'MyChannels',
  MY_TEAM_TITLE_ANALYTICS = 'MyTeam',
  MY_TEAM_MEMBER_TITLE_ANALYTICS = 'TeamMember',
  SETTINGS_TITLE_ANALYTICS = 'Settings',
  PROFILE_SETTINGS_PLATFORM_ANALYTICS = 'PlatformSettings',
  PROFILE_SETTINGS_CONTENT_PERSONALIZATION_ANALYTICS = 'ContentPersonalization',
  PROFILE_SETTINGS_BILLING_ANALYTICS = 'BillingAddress',
  PROFILE_SETTINGS_PAYMENT_ANALYTICS = 'PaymentMethods',
  PROFILE_ORDER_HISTORY_ANALYTICS = 'OrderHistory',
  YOUR_LICENSES_ANALYTICS = "YourLicenses",
  PROFILE_ACTIVATE_LICENSE_ANALYTICS = 'ActivateLicense',
  PERSONAL_SETTINGS_TITLE_ANALYTICS = 'PersonalInformation',
  WISHLIST_TITLE_ANALYTICS = 'Wishlist',
  NOTIFICATIONS_TITLE_ANALYTICS = 'Notifications',
  DELETE_PROFILE_ANALYTICS = "DeleteProfile",
  LEGAL_ANALYTICS = 'Legal',
  COOKIES = 'Cookies',
  CONTACT = 'Contact',
  MANAGE_COOKIES_ANALYTICS = 'ManageCookies',
  CART_ANALYTICS = 'Cart',
  ORDER_CONFIRMATION_ANALYTICS = 'OrderConfirmation',
  COMPETENCY_HUB = 'CompetencyHub'
}
