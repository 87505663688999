import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from '@hooks/redux';
import ButtonV2 from '@components/UI/ButtonV2';
import CardController from '@components/CardV3';
import { styledLogUtagView, USER_URLS, UtagViewPages } from './link-utils';
import useModalTypes from '@hooks/useModalByType';
import { getHeroBannerInfo, getHpBeans, setCatalogueCourses } from '@redux-actions/';
import SearchSection from './Search/SearchSection';
import { HpBean } from '@model/HPBean';
import { selectBrowseByTopicsIds, selectCoursesAndL1 } from '../store/selectors/coursesSelectors';
import useFetchL1 from '@hooks/useFetchL1';
import { toTitleCase } from '@utility/Api';
import TextSkeleton from './skeletons/TextSkeleton';
import SearchColumns from '@components/UI/SearchColumns';
import useGetBrowseByTopicInfo from '@hooks/useGetBrowseByTopicInfo';
import CardBrowseByTopicSkeleton from './skeletons/CardBrowseByTopicSkeleton';
import CardBigEvolutionSkeleton from './skeletons/CardBigEvolutionSkeleton';
import { purifyItemDataElementId } from '@utility/analytics-utils';
import useRefState from '@hooks/useRefState';
import FocusTrap from 'focus-trap-react';
import { queryParams } from '@utility/const';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { buildClickDataTrackingObject, DataLayerTracking, selectTrackingDataLayerBase } from '@model/TrackingClass';
import { cmMetaData } from '@utility/previewUtils';
import { camelCase } from 'lodash';


export const searchTransitionTimeFromMenuToPage = 510;

const SearchMenu = ({
  show,
  notModal,
  handleSearch,
  breakpoint,
}: {
  show: boolean;
  notModal?: boolean;
  handleSearch?: () => void;
  breakpoint: ReturnType<typeof useBreakpointBoolean>;
}) => {
  const history = useHistory();
  const location = useLocation();
  const modalTypes = useModalTypes();
  const dispatch = useDispatch();
  const lang = useSelector(state => state.utils.lang);

  const isLoadingSearchCourses = useSelector(state => state.search.isLoadingSearchCourses);
  const isLoadingCourses: boolean = useSelector(state => state.course.isLoadingCourses);

  const handleClickViewAll = () => {
    closeModal();
    if (!notModal && window.location.pathname !== USER_URLS.SUBCATALOGUE_SEARCH.URL) {
      //if arriving here from search menu, go to search and then to subcatalogue
      history.push(USER_URLS.SUBCATALOGUE_SEARCH.URL);
    }
    //FIX:
    //when passing from subcatalog to full catalog in case of FE cta
    //catalogue courses were not reset hence full catalog page showed previous title, description and courses
    //condition set to avoid reset of filters when already in full catalog and user clicks "view full catalog" from the search bar
    if(window.location.pathname !== USER_URLS.SUBCATALOGUE.URL) {
      dispatch(setCatalogueCourses([], '', ''));
    }
    history.push(USER_URLS.SUBCATALOGUE.URL);
  };

  const [valueSearch, setValueSearch] = useRefState('');

  const handleFocusSearch = () => {
    handleSearch?.();
    setTimeout(() => {
      const searchQuery = valueSearch.current;
      closeModal();
      handleChangeSearch(searchQuery);
    }, searchTransitionTimeFromMenuToPage);
  };

  const handleOnChangeSearch = (value: string) => {
    setValueSearch(value);
  };

  const handleChangeSearch = (val, notFocus = false) => {
    handleSearch?.();

    let useSearchDoubleBack = false;
    const insideSearchPage = window.location.pathname === USER_URLS.SUBCATALOGUE_SEARCH.URL;
    if (val && !notModal && notFocus && !insideSearchPage) {
      //if arriving here from search menu
      history.push(USER_URLS.SUBCATALOGUE_SEARCH.URL);
      useSearchDoubleBack = true;
    }

    //build search location
    let search = '';
    if (val) {
      search = '?' + queryParams.SEARCH_QUERY + '=' + val;
    }

    const sameSearchQuery = window.location.search === search;
    //do not push twice the same url
    if (!insideSearchPage || !sameSearchQuery) {
      const useReplace = window.location.search?.includes(queryParams.SEARCH_QUERY);
      const insideSearchPageNoInput = insideSearchPage && !useReplace;
      history[
        useReplace
          ? 'replace' //do not push twice a query search
          : 'push'
      ](USER_URLS.SUBCATALOGUE_SEARCH.URL + search, {
        notFocus: notFocus,
        useSearchDoubleBack: useReplace //useSearchDoubleBack is used to execute history.go(-2) or history.goBack() clicking the X button or the search icon in the header
          ? location.state?.useSearchDoubleBack
          : insideSearchPageNoInput
          ? true
          : useSearchDoubleBack || location?.state?.useSearchDoubleBack,
      });
    }
  };

  const closeModal = () => {
    modalTypes.close();
  };

  return (
    <FocusTrap
      active={!notModal}
      focusTrapOptions={{
        initialFocus: false,
        fallbackFocus: 'body',
        allowOutsideClick: true,
      }}
    >
      <div>
        <SearchColumns
          show={show}
          closeModal={closeModal}
          header={
            !notModal && (
              <Header
                handleFocusSearch={handleFocusSearch}
                handleOnChangeSearch={handleOnChangeSearch}
              />
            )
          }
          firstColumn={
            <FirstColumn
              notModal={notModal}
              handleChangeSearch={handleChangeSearch}
              handleClickViewAll={handleClickViewAll}
              breakpoint={breakpoint}
            />
          }
          secondColumn={
            <SecondColumn handleChangeSearch={handleChangeSearch} breakpoint={breakpoint} />
          }
          closeDataId={`mainnav_search_close`}
          className={!notModal ? 'is-modal' : ''}
          notModal={notModal}
        />

        {!isLoadingSearchCourses && !isLoadingCourses && show && (
          <ButtonV2
            variant="text-btn"
            className="search-evolution__view-all phone-and-tablet-portrait"
            handleClick={()=>{buildClickDataTrackingObject("viewFullCatalog");handleClickViewAll()}}
            variantLongArrow
          >
            {lang.VIEW_ALL_CATALOGUE}
          </ButtonV2>
        )}
      </div>
    </FocusTrap>
  );
};

type HeaderProps = {
  handleFocusSearch?: () => void;
  handleOnChangeSearch?: (value: string) => void;
};

const Header = ({ handleFocusSearch, handleOnChangeSearch }: HeaderProps) => {
  return (
    <div className="search-evolution__header">
      <SearchSection
        handleFocus={handleFocusSearch}
        handleChange={handleOnChangeSearch}
        isMenu={true}
      />
    </div>
  );
};

const FirstColumn = ({
  handleClickViewAll,
  notModal,
  handleChangeSearch,
  breakpoint,
}: {
  handleClickViewAll: () => void;
  notModal: boolean;
  handleChangeSearch: (val: string, notFocus: boolean) => void;
  breakpoint: ReturnType<typeof useBreakpointBoolean>;
}) => {
  const lang = useSelector(state => state.utils.lang);
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user.userProfile);
  const isLoadingHpBeans: boolean = useSelector(state => state.cms.isLoadingHpBeans);
  const isErrorHpBeans: boolean = useSelector(state => state.cms.isErrorHpBeans);
  const languageLoadedHpBeans: string = useSelector(state => state.cms.languageLoadedHpBeans);
  const hpBeans: HpBean[] = useSelector(state => state.cms.hpBeans);

  useEffect(() => {
    //get HP beans ("you may be interested in" section)
    if (userProfile && languageLoadedHpBeans !== userProfile.preferredLang && !isLoadingHpBeans) {
      dispatch(getHpBeans(userProfile.preferredLang));
    }
  }, [userProfile]);

  return (
    <>
      <h1 className="search-evolution__title">{lang.TRENDING_SEARCHES}</h1>
      <div className="search-evolution__suggestions">
        {(hpBeans?.length > 0 || isLoadingHpBeans) && (
          <section
            className="search-evolution__suggestions__box"
            aria-label={lang.TRENDING_SEARCHES}
          >
            <div className="search-evolution__suggestions__box__content flex column">
              {hpBeans.map(hpBean => (
                <ButtonV2
                  variant="text-btn"
                  key={hpBean.key || uuidv4()}
                  handleClick={() => { buildClickDataTrackingObject(camelCase(lang.TRENDING_SEARCHES)+"_"+camelCase(hpBean.label));handleChangeSearch(hpBean.label, true)}}
                  variantLongArrow
                  data-element-id={
                    !notModal ? `mainNav_search_${purifyItemDataElementId(hpBean.key)}` : ''
                  }>
                 <span {...hpBean?.contentId ? {...cmMetaData(hpBean?.contentId)} : null}>{hpBean.label}</span> 
                </ButtonV2>
              ))}
              {(isLoadingHpBeans || (!hpBeans?.length && !isErrorHpBeans)) &&
                [...Array(6)].map((_, i) => <TextSkeleton height={20} borderRadius={10} key={i} />)}
            </div>
          </section>
        )}
      </div>
      {breakpoint.isDesktopOrTabletLandscape && (
        <ButtonV2
          variant="text-btn"
          className="search-evolution__view-all"
          handleClick={()=>{buildClickDataTrackingObject("viewFullCatalog");handleClickViewAll()}}
          variantLongArrow
        >
          {lang.VIEW_ALL_CATALOGUE}
        </ButtonV2>
      )}
    </>
  );
};

const SecondColumn = ({
  notModal,
  handleChangeSearch,
  breakpoint,
}: {
  notModal?: boolean;
  handleChangeSearch: (val: string, notFocus: boolean) => void;
  breakpoint: ReturnType<typeof useBreakpointBoolean>;
}) => {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.utils.lang);
  const browseByTopicsIds = useSelector(selectBrowseByTopicsIds);
  const browseByTopics = useSelector(state => selectCoursesAndL1(state, browseByTopicsIds.ids));
  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);
  const heroBannerLanguage = useSelector(state => state.cms.heroBannerLanguage);
  const userProfile = useSelector(state => state.user.userProfile);
  const isLoadingHeroBannerInfo: boolean = useSelector(state => state.cms.isLoadingHeroBannerInfo);

  useEffect(() => {
    //download the hero banner if not already done or the user language has changed
    if (heroBannerLanguage !== userProfile?.preferredLang && !isLoadingHeroBannerInfo) {
      dispatch(getHeroBannerInfo());
    }
  }, [userProfile?.preferredLang]);

  //useFetchL1([...browseByTopicsIds.l1Ids]);
  //useGetBrowseByTopicInfo(browseByTopicsIds.l1Ids);
  useFetchL1([...Object.values(browseByTopicsIds.l1Ids).map(obj => obj.l1Id)]);
  useGetBrowseByTopicInfo([...Object.values(browseByTopicsIds.l1Ids).map(obj => obj.l1Id)]);

  return (
    <div>
      {(!isGetCourseCompleted || browseByTopics.length > 0 || isLoadingHeroBannerInfo) && (
        <>
          <h3 className="search-evolution__title">
            {toTitleCase(lang?.BROWSE_BY_TOPICS_CAROUSEL_TITLE)}
          </h3>
          {breakpoint.isTablet && breakpoint.isPortrait && (
            <div className="search-evolution__topics tablet-portrait">
              {browseByTopics.map(topic => (
                <CardController
                  course={topic}
                  variant="card-evolution"
                  key={topic?.l1?.value}
                  onClick={()=>{buildClickDataTrackingObject(camelCase(lang.BROWSE_BY_TOPICS_CAROUSEL_TITLE)+"_"+camelCase(topic?.l1?.value))}}
                  breakpoint={{
                    isDesktop: false,
                    isDesktopOrTabletLandscape: false,
                    isTablet: true,
                    isPhoneOrTabletPortrait: false,
                    isPhone: false,
                    isPortrait: false,
                  }}
                />
              ))}
              {(isLoadingHeroBannerInfo || !browseByTopics?.length) &&
                [...Array(4)].map((_, i) => (
                  <div className="item" key={i}>
                    <CardBigEvolutionSkeleton widthMobile={152} />
                  </div>
                ))}
            </div>
          )}

          {/* <CustomScrollbarV3 class='search-evolution__topics-scrollbar'> */}
          <div className="search-evolution__topics">
            {browseByTopics.map(topic => (
              <CardController
                course={topic}
                variant="search"
                key={topic?.l1?.value}
              />
            ))}
            {(isLoadingHeroBannerInfo || !browseByTopics?.length) &&
              [...Array(6)].map((_, i) => (
                <div className="item" key={i}>
                  <CardBrowseByTopicSkeleton />
                </div>
              ))}
          </div>
          {/* </CustomScrollbarV3> */}
        </>
      )}
    </div>
  );
};

export default SearchMenu;
