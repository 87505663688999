import React from 'react';

type Props = {
	label: string;
	className?: string;
};

const EducationalPathsComplexity = (props: Props) => {
	const { label, className } = props;

	return <p className={`educational-paths__complexity ${className ? className : ''}`}>{label}</p>;
};

export default EducationalPathsComplexity;
