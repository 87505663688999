import { Course, CourseAssociatedSession, CourseSession, LiveStreamInfo } from "@model/CoursesClass";
import { UserProfile } from "@model/User";
import { canJoinSession, isAttendedSession, isLivestreamCourse, isScheduledSession, isVirtualClassroom, milliseconds } from "./Api";
import { getLocalDateFromString } from "./CalendarUtility";

export const getAssociatedSessionFromVCSession = (session: CourseSession, course: Course, userProfile: UserProfile): CourseAssociatedSession => {
  if (!session) {
    return new CourseAssociatedSession();
  }

  const relevantSession = course?.userRelevantSessions?.find(a => a.id === session.id);
  const endingSession = new Date(getLocalDateFromString(session.sessionDates?.[0]?.timeFinish, userProfile?.timezone));
  const isBooked = !!relevantSession && isScheduledSession(relevantSession);
  let isSessionPassed = endingSession.getTime() < new Date().getTime(); //it is passed if endDate < now
  const canJoinThisSession = canJoinSession(relevantSession);

  return {
    id: session.id,
    trainerFirstname: session.trainers?.[0]?.firstName,
    trainerLastname: session.trainers?.[0]?.lastName,
    location: session.city,
    timeStart: session.sessionDates?.[0]?.timeStart,
    timeFinish: session.sessionDates?.[0]?.timeFinish,
    isBooked: isBooked && !isSessionPassed,
    showJoin: course?.showJoin,
    showLiveBadge: course?.showLiveBadge && canJoinThisSession,
    showLiveCountdown: course?.showLiveCountdown && canJoinThisSession,
    isSessionPassed: isSessionPassed,
    showPlayButton: course?.showJoin && canJoinThisSession,
    relevantSession: relevantSession,
    isAttendedSession: isAttendedSession(relevantSession),
  }
}

export const getAssociatedSessionFromLivestreamInfo = (liveInfo: LiveStreamInfo, course: Course, userProfile: UserProfile): CourseAssociatedSession => {
  if (!liveInfo) {
    return new CourseAssociatedSession();
  }

  const endingSession = new Date(getLocalDateFromString(liveInfo.timeEnd, userProfile?.timezone));

  return {
    id: liveInfo.moduleId,
    trainerFirstname: liveInfo.trainers?.[0]?.firstName,
    trainerLastname: liveInfo.trainers?.[0]?.lastName,
    location: '',
    timeStart: liveInfo.timeStart,
    timeFinish: liveInfo.timeEnd,
    isBooked: false,
    showJoin: course?.showJoin,
    showLiveBadge: course?.showLiveBadge,
    showLiveCountdown: course?.showLiveCountdown,
    isSessionPassed: (endingSession.getTime() - new Date().getTime()) < 0,
    showPlayButton: course?.showJoin,
    relevantSession: null,
    isAttendedSession: false,
  }
}

export const getAssociatedSessionFromCourse = (course: Course, userProfile: UserProfile): CourseAssociatedSession => {
  if (!course) {
    return new CourseAssociatedSession();
  }

  if (isVirtualClassroom(course)) {
    return getAssociatedSessionFromVCSession(course.courseSessions?.[0], course, userProfile);
  } else if (isLivestreamCourse(course)) {
    return getAssociatedSessionFromLivestreamInfo(course.liveInfo?.[0], course, userProfile);
  } else {
    return new CourseAssociatedSession();
  }
}