import { useEffect } from 'react';

const useHandleBodyScrollAfterModalOpen = (
  showModal: boolean,
  elseCondition: boolean = true
): void => {
  useEffect(() => {
    useHandleBodyScrollAfterModalOpenMethod(showModal, elseCondition);
  }, [showModal]);
};
export default useHandleBodyScrollAfterModalOpen;

export const useHandleBodyScrollAfterModalOpenMethod = (
  showModal: boolean,
  elseCondition: boolean = true
): void => {
  if (showModal) {
    if (!document.body.classList.contains('overflow-under-modal')) {
      document.body.classList.add('overflow-under-modal');
    }
  } else if (elseCondition) {
    if (document.body.classList.contains('overflow-under-modal')) {
      document.body.classList.remove('overflow-under-modal');
    }
  }

  // const wrapper = document.querySelector('#themeManager .custom-scrollbar-v3__scroller');
  // if (wrapper) {
  //   if (showModal) {
  //     if (!wrapper.classList.contains('overflow-under-modal')) {
  //       wrapper.classList.add('overflow-under-modal');
  //     }
  //   } else if (elseCondition) {
  //     if (wrapper.classList.contains('overflow-under-modal')) {
  //       wrapper.classList.remove('overflow-under-modal');
  //     }
  //   }
  // }
};
