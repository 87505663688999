import React, { useEffect, useState } from 'react';
import { Course, CourseSession, CoursesMap } from "@model/CoursesClass";
import { formatDateInAMPM, isMandatory, printDayAndTime, printDayAndTimeAMPM } from "@utility/Api";
import { buildClickDataTrackingObject, TealiumEvents } from '@model/TrackingClass';
import { useSelector } from '@hooks/redux';
import ModalEvolution from '@components/UI/ModalEvolution';
import { getLevelLabel } from '@utility/levelUtility';
import { purifyItemDataElementIdandApplyCamelCasingBrandCase } from '@utility/analytics-utils';
import { ConfirmBookingModalParams } from '@model/ConfirmBookingModalParams';

type ConfirmBookingModalProps = {
    closeModal: () => void,
    course: Course,
    bookSession: (sessionId: string, booked?: boolean) => void,
    coursesMap: CoursesMap;
    session: CourseSession,
    show: boolean,
    isSignUp: boolean,
    isLoading: boolean
}

export const ConfirmBookingModal = ({ closeModal, course, bookSession, session, show, isSignUp, isLoading, coursesMap }: ConfirmBookingModalProps): React.ReactElement => {
    const [labels, setLabels] = useState({ title: '', descr: '', action: '' });
    const [indexes, setIndexes] = useState({ courseName: -1, date: -1 });

    const lang = useSelector(state => state.utils.lang)

    useEffect(() => {
        if (isSignUp) {
            setLabels({ title: lang.DO_YOU_CONFIRM_BOOKING_TITLE, descr: lang.DO_YOU_CONFIRM_BOOKING, action: lang.YES_CONFIRM_BOOKING });
            setIndexes({ courseName: lang.DO_YOU_CONFIRM_BOOKING.indexOf("{COURSE_NAME}"), date: lang.DO_YOU_CONFIRM_BOOKING.indexOf("{DATE_AND_TIME}") });
        } else {
            setLabels({ title: lang.DO_YOU_CONFIRM_CANCEL_BOOKING_TITLE, descr: lang.DO_YOU_CONFIRM_CANCEL_BOOKING, action: lang.YES_CONFIRM_CANCEL_BOOKING });
            setIndexes({ courseName: lang.DO_YOU_CONFIRM_CANCEL_BOOKING.indexOf("{COURSE_NAME}"), date: lang.DO_YOU_CONFIRM_CANCEL_BOOKING.indexOf("{DATE_AND_TIME}") });
        }

    }, [lang, course, session, isSignUp]);

    const handleBookSession = (id, isSignUp) => {
        bookSession(id, isSignUp);
    }
    const text = labels?.descr?.replace("{COURSE_NAME}", "divider" + " " + course?.courseFullName + " " + "divider").replace("{DATE_AND_TIME}", " " + "divider" + printDayAndTimeAMPM(session?.sessionDates[0]?.timeStart,lang) + " " + "divider").replace("{ADDRESS}", session?.location ? " " + session?.location + " " + "divider" : "").split("divider")
    
    //ANALYTICS
    const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
    const contentType = purifyItemDataElementIdandApplyCamelCasingBrandCase(getLevelLabel(course?.complexityId, labelComplexityMap));
    const bookingModalParams = useSelector<ConfirmBookingModalParams>(state => state.course.bookingModalParams);
    
    const cancelDataElementId = `liveSessions_${contentType}_CancelBooking_${course?.courseId}`;
    const signDataElementId = `liveSessions_${contentType}_SignUp_${course?.courseId}`;
    const dataElementId = !bookingModalParams?.isSessionSignUp ? cancelDataElementId : signDataElementId;
    const mainButtonDataDescription = `Confirm`;
    const secondaryButtonDataDescription = `NotNow`;
    
    return (
        <ModalEvolution
            close={closeModal}
            show={show}
            hideX={true}
            icon={''}
            title={labels.title}
            descriptionElement={
                <p>
                    <span>{text[0]}</span>
                    <span className="bold">{course.courseFullName ? text[1] : ''}</span>
                    <span>{text[2]}</span>
                    <span className="bold">{text[3]}</span>           
                    <span>{text[4]}</span>
                </p>
            }
            mainButtonLabel={labels.action}
            mainButtonAction={() =>{isSignUp? buildClickDataTrackingObject("signUp_confirm") : buildClickDataTrackingObject("cancelBooking_confirm");handleBookSession(session.id, isSignUp)}}
            mainButtonIsLoading={isLoading}
            secondaryButtonLabel={lang.CANCEL}
            secondaryButtonAction={closeModal}
            mainButtonDataElementId={dataElementId}
            mainButtonDataDescription={mainButtonDataDescription}
            secondaryButtonDataElementId={dataElementId}
            secondaryButtonDataDescription={secondaryButtonDataDescription}
        />
    );
}

export default ConfirmBookingModal;