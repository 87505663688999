import React, { ReactElement, useEffect } from 'react';
import { Course, LangMap } from '@model/CoursesClass';
import CourseReviewModal from '@components/CourseReviewModal';
import * as actions from '@redux-actions/';
import { connect, ConnectedProps } from 'react-redux';
import RatingModal from '@components/RatingModal';
import { RootState } from 'src/store/store';
import { isVirtualClassroom } from '@utility/Api';
import { getUserRelevantSessionsById } from '@redux-actions/';
import { useDispatch } from 'react-redux';

type RatingReviewlProps = PropsFromRedux & {
  lang: LangMap;
  goBack: () => void;
  courseName: string;
  course: Course;
  show: boolean;
  updateCourse: (courseId: string, notif: any, maintainCourseDetail: boolean) => void;
};

export const ReviewModal = ({
  lang,
  goBack,
  course,
  courseName,
  show,
  updateCourse,
  getUserRelevantSessionsById,
}: RatingReviewlProps): ReactElement => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      // Update course closing the rating modal
      updateCourse(course.courseIdMaster, {}, true, false, false, false, true);
  
      //Moved this part into updateCourse function for LEON-7127
      /*if (isVirtualClassroom(course)) {
        getUserRelevantSessionsById(course.courseId);
      } */
    },
    []
  );

  return (
    <>
      {!course?.isStepRatingPassed ? (
        <RatingModal courseName={courseName} course={course} show={show} goBack={goBack} />
      ) : (
        <div className="rating-modal">
          <CourseReviewModal courseName={courseName} course={course} show={show} goBack={goBack} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCourse: (courseId, notif, maintainCourseDetail, updateAllReturnedCourses, maintainCourseSummary, prevUrlIsMyCertificates, isReviewModal) =>
      dispatch(actions.updateCourse(courseId, notif, maintainCourseDetail, updateAllReturnedCourses, maintainCourseSummary, prevUrlIsMyCertificates, isReviewModal)),
      getUserRelevantSessionsById : (courseId)  => dispatch(actions.getUserRelevantSessionsById(courseId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReviewModal);
