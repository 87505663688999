import React, { memo, useEffect, useState } from "react";
import { LangMap } from "@model/CoursesClass";
import useTime from "@hooks/useTime";
import { printDateLivestream, printDateLong, printHTML } from "@utility/Api";
import { useSelector } from "@hooks/redux";
import { getLocalDateFromString } from "@utility/CalendarUtility";
import { UserProfile } from "@model/User";

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

const formatTime = (time: number, lang: LangMap, showTimeLabel: boolean = false) => {
  const hours = Math.floor(time / ONE_HOUR);
  const minutes = Math.floor((time % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((time % ONE_MINUTE) / ONE_SECOND);
  const hoursString = hours.toString().padStart(2, "0");
  const minutesString = minutes.toString().padStart(2, "0");
  const secondsString = seconds.toString().padStart(2, "0");

  const less1hour = hoursString === '00';
  // const less1min = hoursString === '00' && minutesString === '00';

  if (showTimeLabel) {

    const labelHour = lang.DURATION_HOUR_LABEL ? lang.DURATION_HOUR_LABEL : "h";
    const labelMinute = lang.DURATION_MIN_LABEL ? lang.DURATION_MIN_LABEL : "min";
    const timeLabel = less1hour ? labelMinute : labelHour;

    return `${less1hour ? '' : hoursString + ':'}${minutesString}:${secondsString} ${timeLabel}`;
  }

  return `${less1hour ? '' : hoursString + ':'}${minutesString}:${secondsString}`;
};

const threshold15Min = ONE_MINUTE * 15;

type BottomLabelLiveProps = {
  timeStart: string,
  timeEnd: string,
  variant: 'big' | 'medium' | 'small' | 'overview' | 'calendar';
  lang: LangMap;
  showDate?: boolean;
  hideWhenNow?: boolean;
  showLiveBadge?: boolean;
  showLiveCountdown?: boolean;
};
const BottomLabelLive = ({
  timeStart,
  timeEnd,
  showDate,
  variant,
  lang,
  hideWhenNow,
  showLiveBadge,
  showLiveCountdown
}: BottomLabelLiveProps) => {
  const [nowInterval, setNowInterval] = useState(1000);
  const now = useTime(nowInterval); //not refresh every second

  const [bottomLabelText, setBottomLabelText] = useState("");
  const [timeStartValue, setTimeStartValue] = useState(0);
  const [timeEndValue, setTimeEndValue] = useState(0);

  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);

  useEffect(() => {
    setTimeStartValue(getLocalDateFromString(timeStart, userProfile?.timezone)?.getTime());
    setTimeEndValue(getLocalDateFromString(timeEnd, userProfile?.timezone)?.getTime());
  }, [timeStart, userProfile?.timezone]);

  useEffect(() => {
    const timeNow = new Date().valueOf();
    const countdownTimeEnd =
      (showLiveCountdown || timeEndValue - timeNow < threshold15Min || variant === "calendar") ? timeEndValue - timeNow : null;

    //if live is not started --> refresh at live ending - 15 minutes 
    //if live is already started (finished) --> not refresh
    // const interval = timeEndValue - new Date().getTime();
    // let newNowInterval = interval > 0 ? interval - threshold15Min : null;
    // newNowInterval = newNowInterval && newNowInterval < 1000 ? 1000 : newNowInterval;
    let newNowInterval = null;

    if (timeStartValue <= new Date().getTime() && countdownTimeEnd && countdownTimeEnd > 0) {

      if (variant != 'calendar') {
        setBottomLabelText(
          lang.LIVESTREAM_ENDING_IN.replace("TIME", formatTime(countdownTimeEnd, lang))
            .replace("{", (variant === 'medium' ? '</br>' : '') + "<span class='red'>")
            .replace("}", "</span>")
        );
      } else {
        setBottomLabelText(
          lang.LIVESTREAM_ENDING_IN.replace("TIME", formatTime(countdownTimeEnd, lang, true))
            .replace("{", "<span>")
            .replace("}", "</span>")
        );
      }
      //refresh every second
      newNowInterval = 1000;
    } else if (
      hideWhenNow &&
      timeEndValue - timeNow > 0 &&
      timeStartValue - timeNow < 0
    ) {
      setBottomLabelText("");
    } else if (showDate && timeEndValue) {
      if (variant !== 'overview' || timeEndValue < new Date().getTime()) {
        //if live is passed --> print only date and time without label
        setBottomLabelText(
          printDateLong(timeStart, lang).replace(" - ", variant === 'medium' || variant === 'big' ? "</br>" : " ") +
          " - " +
          printDateLong(timeEnd, lang).split("-")[1]
        );
      } else {
        const label = lang.LIVESTREAM_ON_ABBR;
        let date = printDateLivestream(timeStart, timeEnd, lang);

        setBottomLabelText(
          label.replace(
            "DATE",
            date
          )
            .replace("{", "")
            .replace("}", "")
        );
      }
    } else {
      setBottomLabelText("");
    }

    setNowInterval(newNowInterval);
  }, [now, hideWhenNow, timeStartValue, timeEndValue, showLiveBadge, showLiveCountdown, lang]);

  return bottomLabelText || hideWhenNow ? (
    <span
      className="bottom-label-text"
      dangerouslySetInnerHTML={printHTML(bottomLabelText)}
    />
  ) : (
    <></>
  );
};

export default memo(BottomLabelLive);
