import clsx from '@utility/clsx';
import React from 'react';
import { useSelector } from '@hooks/redux';

class MultiactivityCompletionPercentageProps {
  className?: string = "";
  completedActivities: number = 0;
  totalActivities: number = 0;
  isCompleted: boolean;
}

export const MultiactivityCompletionPercentage = (props: MultiactivityCompletionPercentageProps): React.ReactElement => {

  const lang = useSelector(state => state.utils.lang);

  return (
    <span
      className={clsx('multiactivity-completion-percentage', {
        "theme-color-text": props.isCompleted,
        [props.className]: props.className,
      })}
      role="region"
      aria-label={
        lang.RATIO_ACTIVITIES_COMPLETED.replace("{ratio}", `${props.completedActivities || 0}`)
      }
    >
      <span className={'num-completed ' + (props.completedActivities > 0 ? 'theme-color-text' : '')}>{props.completedActivities || 0}</span><span>/{props.totalActivities || 0}</span>
    </span>
  );
}

export default MultiactivityCompletionPercentage;
