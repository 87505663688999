import * as actionTypes from '../actions/actionTypes';
import {
  updateObject,
  orderVirtualClassrooms,
  orderOnlineCourses,
  isLearningPath,
  compareDates,
  orderToDos,
  orderUpcomingEvents,
  handleCourseDetailQueryParam,
} from '../../utility/Api';
import { sortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { Course, CoursesMap } from '@model/CoursesClass';
import { LearningPath } from '@components/LearningPath/LPCardInterface';
import { Teacher } from '@model/Teacher';
import { queryParams } from '@utility/const';
import history from '../../history';
import {
  fixL1LetterCase,
  getBrandIdFromL1Id,
  getL1IdFromBrandId,
  isL1IdBrand,
} from '@utility/levelUtility';
//import { queryParams } from '@utility/const';

class InitialStateProps {
  courses: Course[] = [];
  coursesMap: CoursesMap = null;
  courseCounterVirtual: number[] = [];
  courseCounterOnline: number[] = [];
  showCourseModal = false;
  showTeacherModal = false;

  //MAP BETWEEN COURSE ID AND ARRAY OF IDS
  courseIdSubarrayMap: {
    [key: string]: string[];
  } = {};

  //ARRAY OF COURSES
  filteredCourses: Course[] = []; //courses printed in Subcatalogue,
  currentlyFiltered: string = null;
  catalogueCourses: Course[] = []; //courses to show in catalogue
  catalogueTitle: string = '';
  catalogueDescription: string = '';
  catalogueLP: LearningPath[] = []; //LPs to show in catalogue
  completedCourses: Course[] = [];
  courses0: Course[] = [];
  upcomingEvents: Course[] = [];
  livestreams: Course[] = [];

  //new homepage carousel
  newCourses: Course[] = [];

  //SEARCH
  searchInput = '';
  searchType: string = null;
  searchValueToView = '';
  isBySearchCompleted = false;
  searchResults = [];

  //FILTERS
  pagination = 20;
  isSearchOpen = false;
  isGetCourses0Fetching = false;
  categoriesProducts = [];
  categoriesSkills = [];
  categoriesCompliance = [];
  otherFilters = {
    ctype: ['online_courses', 'classes'],
    // onlineStatus: ["inProgress", "expiring", "overdue", "done"],
    // classStatus: ["upcoming", "scheduled", "attended", "missed"],
    status: ['toBeStarted', 'scheduled', 'toBeContinued', 'completed', 'overdue', 'missed'],
    assignment: ['mandatory', 'wishlist'],
  };

  //LOADERS
  isLoadingCourses = false;
  isLoadingCoursesFast = true;
  isLoadingCourse = {};
  isBookingSession = false;
  isLoadingDashboard = true;
  isLoadingLike = false;
  isLoadingWishlist = false;
  isLoadingDetails: {
    [key: string]: boolean;
  } = {};
  isGetCourse0Completed = false;
  isGettingCourseCompletion = false;
  isGetCourseCompletionCompleted = false;
  isGetCourseCompleted = false;
  isVirtualCompleted = false;
  isVirtualEmpty = false;
  isLoadingCourseSummary = {};

  //COURSE MODAL
  currentCourse: Course = null;
  childCourse = null;
  feedbackLinks: string[] = [];
  certificateLinks: string[] = [];
  isCourseDetailOpenedByUrl = false;
  scrollToCurrentCourseSessions = false;

  //TO GET NOTICES
  subscribedCourses = [];
  joiningCourses = [];
  joiningLivestreams = [];
  isClosingEventSource = false;
  isNoticeArrived = false;

  //TEACHER MODAL
  teacherModal: Teacher = null;

  //RATING MODAL
  showRatingModal = false;
  ratingModalCourse: Course = null;

  //NEXT UP MODAL
  showNextUpModal = false;
  nextUpModalCourses: Course[] = [];

  //BOOKING MODAL
  bookingModalParams = { open: false };

  //DOWNLOAD LINK MODAL
  showDownloadLinkModal = false;
  linkToDownload = null;
  courseToDownload = null;

  //YOU MAY ALSO LIKE
  youMayAlsoLikeCourses: {
    [key: string]: Course[];
  } = {};
  isLoadingYMAL: boolean = null;

  //TIME SPENT ON TRAINING - used for analytics
  timeOnTraining = '0';
  isTimeOnTrainingLoaded = false;

  //MODAL PREVIEW VIDEO
  previewVideoUrl = null;

  //LP ENROLL
  isLpEnrolling = false;

  triggerAppJsUpdate = 0;

  completedCoursesById: { [key: string]: Course } = {};
  isGettingCourseCompletionById: boolean = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const setCounterOnline = (state, action) => {
  return updateObject(state, {
    courseCounterOnline: action.courseCounterOnline,
    isLoadingDashboard: false,
  });
};

const setCounterVirtual = (state, action) => {
  return updateObject(state, {
    courseCounterVirtual: action.courseCounterVirtual,
    isLoadingDashboard: false,
  });
};

const saveSearchResults = (state, action) => {
  return updateObject(state, {
    searchResults: action.searchResults,
    isBySearchCompleted: true,
  });
};

const resetSearch = state => {
  return updateObject(state, {
    isBySearchCompleted: false,
  });
};

const setSuggestionInput = (state, action) => {
  return updateObject(state, {
    searchInput: action.searchInput,
    searchType: action.searchType,
    searchValueToView: action.searchValueToView,
  });
};

const setVisibilitySearch = (state, action) => {
  document.body.style.overflow = action.isOpen ? 'hidden' : 'auto';

  return updateObject(state, {
    isSearchOpen: action.isOpen,
  });
};

const getCourseRequest = state => {
  return updateObject(state, {
    isLoadingCourses: true,
    isGetCourseCompleted: false,
  });
};

const getCourseSuccess = (state, action) => {
  return updateObject(state, {
    courses: action.courses,
    isLoadingCourses: false,
    isGetCourseCompleted: true,
    isGetCourseCompletionCompleted: false, //completions are overwritten by catalog success --> re-download them
  });
};

const getCourseFailure = state => {
  return updateObject(state, {
    isLoadingCourses: false,
  });
};

const getSingleCourseRequest = (state, action) => {
  return updateObject(state, {
    isLoadingCourse: { ...state.isLoadingCourse, [action.courseId]: true },
  });
};

const getSingleCourseEnd = (state, action) => {
  return updateObject(state, {
    isLoadingCourse: { ...state.isLoadingCourse, [action.courseId]: false },
  });
};

const changeCourseMap = (state, action) => {
  // console.log(JSON.stringify(action.coursesMap), "-- coursesMap")

  for (const course of Object.values(action.coursesMap)) {
    //qualche volta ritrovo catalogTypes in minuscolo ma i brand devono restare in maiuscolo
    //sembra succeda solo su questa chiamata
    //https://luxotticaretail.atlassian.net/browse/UU-2654
    course.catalogTypes = course.catalogTypes?.map(l1Id => fixL1LetterCase(l1Id)) || [];
  }

  return updateObject(state, {
    coursesMap: action.coursesMap,
  });
};

const getLivestreams = (state, action) => {
  let livestreams = action.livestreams ? action.livestreams : [];
  livestreams = orderUpcomingEvents(livestreams);

  return updateObject(state, {
    livestreams,
    courseIdSubarrayMap: action.courseIdSubarrayMap,
  });
};

const getCompletedCourses = (state, action) => {
  let completedCourses = orderBy(action.completedCourses, ['courseCompletionDate'], 'desc');

  return updateObject(state, {
    completedCourses,
  });
};

const setTimeOnTraining = (state, action) => {
  return updateObject(state, {
    timeOnTraining: action.timeOnTraining || '0',
    isTimeOnTrainingLoaded: true,
  });
};

const getWishlistRequest = (state, action) => {
  return updateObject(state, {
    isLoadingGetWishlist: true,
    isGetWishlistCompleted: false,
  });
};

const getWishlistSuccess = (state, action) => {
  let wishlist = orderBy(action.wishlist, ['wishDate'], 'desc');

  return updateObject(state, {
    wishlist,
    isLoadingGetWishlist: false,
    isGetWishlistCompleted: true,
  });
};

const getWishlistFailure = (state, action) => {
  return updateObject(state, {
    isLoadingGetWishlist: false,
    isGetWishlistCompleted: true,
  });
};

const getUpcomingEvents = (state, action) => {
  //order
  // const upcomingEvents = orderUpcomingEvents(action.upcomingEvents);
  return updateObject(state, {
    upcomingEvents: action.upcomingEvents,
    courseIdSubarrayMap: action.courseIdSubarrayMap,
  });
};

const getFilters = (state, action) => {
  return updateObject(state, {
    categoriesProducts: action.categoriesProducts,
    categoriesSkills: action.categoriesSkills,
    categoriesCompliance: action.categoriesCompliance,
    otherFilters: {
      ...state.otherFilters,
      language: action.languages,
    },
  });
};

const setCatalogueCourses = (state, action) => {
  return updateObject(state, {
    catalogueCourses: action.catalogueCourses,
    catalogueTitle: action.catalogueTitle,
    catalogueDescription: action.catalogueDescription,
  });
};

const resetCatalogueCourses = state => {
  return updateObject(state, {
    catalogueCourses: [],
    catalogueTitle: '',
    catalogueDescription: '',
  });
};

const setCatalogueLP = (state, action) => {
  return updateObject(state, {
    catalogueLP: action.catalogueLP,
  });
};
const resetCatalogueLP = state => {
  return updateObject(state, {
    catalogueLP: [],
  });
};

const bookSessionRequest = state => {
  return updateObject(state, {
    isBookingSession: true,
  });
};

const bookSessionSuccess = state => {
  return updateObject(state, {
    isBookingSession: false,
  });
};

const bookSessionFailure = state => {
  return updateObject(state, {
    isBookingSession: false,
  });
};

const toggleLikeRequest = state => {
  return updateObject(state, {
    isLoadingLike: true,
  });
};

const toggleLikeSuccess = state => {
  return updateObject(state, {
    isLoadingLike: false,
  });
};

const toggleLikeFailure = state => {
  return updateObject(state, {
    isLoadingLike: false,
  });
};

const updateWishlistRequest = state => {
  return updateObject(state, {
    isLoadingWishlist: true,
  });
};

const updateWishlistSuccess = state => {
  return updateObject(state, {
    isLoadingWishlist: false,
  });
};

const updateWishlistFailure = state => {
  return updateObject(state, {
    isLoadingWishlist: false,
  });
};

const updateCourseIdSubarray = (state, action) => {
  return updateObject(state, {
    courseIdSubarrayMap: action.courseIdSubarrayMap,
  });
};

const logout = state => {
  return {
    ...initialState,
    courseIdSubarrayMap: {},
    completedCourses: [],
    isClosingEventSource: true,
    isCourseDetailOpenedByUrl: state.isCourseDetailOpenedByUrl,
  };
};

const setCurrentCourse = (state, action) => {
  return updateObject(state, {
    currentCourse: action.currentCourse,
    feedbackLinks: action.feedbackLinks,
    certificateLinks: action.certificateLinks,
  });
};

const triggerAppJsUpdate = state => {
  const random = Math.random();
  console.log('triggerAppJsUpdate reducer', random);
  return updateObject(state, {
    triggerAppJsUpdate: random,
  });
};

const openSubCourseInLP = (state, action) => {
  return updateObject(state, {
    currentCourse: action.currentCourse,
    lpParentCourse: state.currentCourse,
  });
};

const toggleCourseModal = (state, action) => {
  let showCourseModal = action.value;
  const newState: {
    showCourseModal?: boolean;
    currentCourse?: any;
    lpParentCourse?: any;
    scrollToCurrentCourseSessions?: boolean;
  } = {};

  // console.log('toggleCourseModal', action);

  if (action.closeAll) {
    newState.showCourseModal = false;
    newState.currentCourse = null;
    newState.scrollToCurrentCourseSessions = false;
  } else {
    if (showCourseModal && action.course) {
      newState.showCourseModal = showCourseModal;
      newState.currentCourse = action.course;
    } else {
      newState.currentCourse = null;
      newState.showCourseModal = showCourseModal;

      if (state.currentCourse && !state.currentCourse.isStatic) {
        console.log('history', window.history.state.prevUrl);
        history.goBack();
      }
    }
    newState.scrollToCurrentCourseSessions = action.scrollToCurrentCourseSessions;
  }

  return updateObject(state, newState);
};
//add URL
/* const handleCourseDetailQueryParam = (currentCourse = null) => {
    let newUrl = window.location.href;

    //remove old parameter
    if (newUrl) {
        let changeUrl = false;

        if (newUrl.includes(queryParams.COURSE_DETAIL)) {
            changeUrl = true;
            const replaceRegex1 = queryParams.COURSE_DETAIL + '=[^&#]*(#.*)?$';
            const replaceRegex2 = queryParams.COURSE_DETAIL + '=[^&]*&';

            newUrl = newUrl.replace(new RegExp(replaceRegex1), '');
            newUrl = newUrl.replace(new RegExp(replaceRegex2), '');
            if (newUrl.endsWith('?')) {
                newUrl = newUrl.replace('?', '');
            }
            if (newUrl.endsWith('&')) {
                newUrl = newUrl.replace('&', '');
            }
        }

        if (currentCourse) {
            //include parameter
            changeUrl = true;
            newUrl += newUrl.includes('?') ? '&' : '?';
            newUrl += queryParams.COURSE_DETAIL + '=' + currentCourse.courseId;
        }

        if (changeUrl) {
            console.log('pushState', newUrl);
            // window.history.pushState({ path: newUrl }, '', newUrl);
            history.push(newUrl.replace(window.location.origin, ''));
            // window.history.replaceState({ path: newUrl }, '', newUrl);
        }



    }
} */
const resetScrollToCurrentCourseSessions = state => {
  return updateObject(state, {
    scrollToCurrentCourseSessions: false,
  });
};

const getCourseDetailsRequest = (state, action) => {
  return updateObject(state, {
    isLoadingDetails: { ...state.isLoadingDetails, [action.courseId]: true },
  });
};

const getCourseDetailsSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingDetails: { ...state.isLoadingDetails, [action.courseId]: false },
  });
};

const getCourseDetailsFailure = (state, action) => {
  return updateObject(state, {
    isLoadingDetails: { ...state.isLoadingDetails, [action.courseId]: false },
  });
};

const getCourse0Completed = (state, action) => {
  return updateObject(state, {
    isGetCourse0Completed: true,
    courses0: action.courses0,
    isGetCourses0Fetching: false,
  });
};

const getCourses0Request = state => {
  return updateObject(state, {
    isGetCourses0Fetching: true,
  });
};
const getCourses0Failure = state => {
  return updateObject(state, {
    isGetCourses0Fetching: false,
  });
};

const getCourseCompletionRequest = state => {
  return updateObject(state, {
    isGettingCourseCompletion: true,
  });
};

const setIsGetCourseCompletionCompleted = (state, action) => {
  return updateObject(state, {
    isGetCourseCompletionCompleted: action.isGetCourseCompletionCompleted,
  });
};

const getCourseCompletionCompleted = state => {
  return updateObject(state, {
    isGettingCourseCompletion: false,
    isGetCourseCompletionCompleted: true,
  });
};

const getCourseCompletionByIdRequest = state => {
  return updateObject(state, {
    isGettingCourseCompletionById: true,
  });
};

const getCourseCompletionByIdSuccess = (state, action) => {
  const newCompletedCoursesById = {};
  action.courses.forEach(course => {
    newCompletedCoursesById[course.courseIdMaster] = cloneDeep(course);
  });
  console.log('completedCoursesById', {
    ...state.completedCoursesById,
    ...newCompletedCoursesById,
  });

  return updateObject(state, {
    completedCoursesById: {
      ...state.completedCoursesById,
      ...newCompletedCoursesById,
    },
    isGettingCourseCompletionById: false,
  });
};

const getCourseCompletionByIdFailure = state => {
  return updateObject(state, {
    isGettingCourseCompletionById: false,
  });
};

const getCourseSummaryRequest = (state, action) => {
  const updatedLoadingSummaries = action.courseIds.reduce((acc, id) => {
    return { ...acc, [id]: true };
  }, {});
  return updateObject(state, {
    isLoadingCourseSummary: { ...state.isLoadingCourseSummary, ...updatedLoadingSummaries },
    });
}
const getCourseSummarySuccess = (state, action) => {
  const updatedLoadingSummaries = action.courseIds.reduce((acc, id) => {
    return { ...acc, [id]: false };
  }, {});  
  return updateObject(state, {
    isLoadingCourseSummary: { ...state.isLoadingCourseSummary, ...updatedLoadingSummaries },
    });
}
const getCourseSummaryFailure = (state, action) => {
  const updatedLoadingSummaries = action.courseIds.reduce((acc, id) => {
    return { ...acc, [id]: false };
  }, {});  
  return updateObject(state, {
    isLoadingCourseSummary: { ...state.isLoadingCourseSummary, ...updatedLoadingSummaries },
    });
}

const resetVirtual = state => {
  return updateObject(state, {
    isVirtualCompleted: false,
    isVirtualEmpty: false,
  });
};

const cleanCompletedCourses = state => {
  return updateObject(state, {
    completedCourses: [],
    isGetCourse0Completed: false,
  });
};

const getSubscribedCourses = (state, action) => {
  return updateObject(state, {
    subscribedCourses: action.subscribedCourses,
  });
};

const setJoiningCourses = (state, action) => {
  return updateObject(state, {
    joiningCourses: action.joiningCourses,
  });
};

const setJoiningLivestreams = (state, action) => {
  return updateObject(state, {
    joiningLivestreams: action.joiningLivestreams,
  });
};

const noticeArrive = state => {
  return updateObject(state, {
    isNoticeArrived: true,
  });
};

const clearNoticeArrive = state => {
  return updateObject(state, {
    isNoticeArrived: false,
  });
};

const setIsCourseDetailOpenedByUrl = (state, action) => {
  return updateObject(state, {
    isCourseDetailOpenedByUrl: action.isCourseDetailOpenedByUrl,
  });
};

const toggleTeacherModal = (state, action) => {
  const newState = {
    showTeacherModal: action.showTeacherModal,
    teacherModal: action.teacherModal,
  };

  if (!action.showTeacherModal) {
    newState.teacherModal = null;
  }

  return updateObject(state, newState);
};

const toggleRatingModal = (state, action) => {
  return updateObject(state, {
    showRatingModal: action.value,
    ratingModalCourse: action.course,
  });
};

const toggleNextUpModal = (state, action) => {
  return updateObject(state, {
    showNextUpModal: action.value,
    nextUpModalCourses: action.course,
  });
};

const openConfirmBookingModal = (state, action) => {
  return updateObject(state, {
    showRatingModal: action.value,
    bookingModalParams: action.bookingModalParams,
  });
};

const closeConfirmBookingModal = (state, action) => {
  return updateObject(state, {
    showRatingModal: action.value,
    bookingModalParams: { open: false },
  });
};

const toggleDownloadLinkModal = (state, action) => {
  return updateObject(state, {
    showDownloadLinkModal: action.showModal,
    linkToDownload: action.link,
    courseToDownload: action.courseId
  })
}

const getYMALRequest = state => {
  return updateObject(state, {
    isLoadingYMAL: true,
  });
};

const getYMALSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingYMAL: false,
    youMayAlsoLikeCourses: { ...state.youMayAlsoLikeCourses, [action.courseId]: action.ymal },
  });
};

const getYMALFailure = state => {
  return updateObject(state, {
    isLoadingYMAL: false,
  });
};

const setPreviewVideoUrl = (state, action) => {
  return updateObject(state, {
    previewVideoUrl: action.previewVideoUrl,
  });
};

const enrollLpRequest = state => {
  return updateObject(state, {
    isLpEnrolling: true,
  });
};

const enrollLpSuccess = state => {
  return updateObject(state, {
    isLpEnrolling: false,
  });
};

const enrollLpFailure = state => {
  return updateObject(state, {
    isLpEnrolling: false,
  });
};
const reducer = (state = initialState, action): InitialStateProps => {
  switch (action.type) {
    case actionTypes.GET_COURSE_COUNTER_ONLINE:
      return setCounterOnline(state, action);
    case actionTypes.GET_COURSE_COUNTER_VIRTUAL:
      return setCounterVirtual(state, action);
    case actionTypes.GET_COURSES_REQUEST:
      return getCourseRequest(state);
    case actionTypes.GET_COURSES_SUCCESS:
      return getCourseSuccess(state, action);
    case actionTypes.GET_COURSES_FAILURE:
      return getCourseFailure(state);
    case actionTypes.GET_COURSE_REQUEST:
      return getSingleCourseRequest(state, action);
    case actionTypes.GET_COURSE_END:
      return getSingleCourseEnd(state, action);
    case actionTypes.GET_SEARCH_RESULTS:
      return saveSearchResults(state, action);
    case actionTypes.SET_VISIBILITY_SEARCH:
      return setVisibilitySearch(state, action);
    case actionTypes.GET_LIVESTREAMS:
      return getLivestreams(state, action);
    case actionTypes.GET_COMPLETED_COURSES:
      return getCompletedCourses(state, action);
    case actionTypes.SET_TIME_ON_TRAINING:
      return setTimeOnTraining(state, action);
    case actionTypes.GET_WISHLIST_REQUEST:
      return getWishlistRequest(state, action);
    case actionTypes.GET_WISHLIST_SUCCESS:
      return getWishlistSuccess(state, action);
    case actionTypes.GET_WISHLIST_FAILED:
      return getWishlistFailure(state, action);
    case actionTypes.GET_UPCOMING_EVENTS:
      return getUpcomingEvents(state, action);
    case actionTypes.CHANGE_COURSE_MAP:
      return changeCourseMap(state, action);
    case actionTypes.GET_FILTERS:
      return getFilters(state, action);
    case actionTypes.SET_CATALOGUE_COURSES:
      return setCatalogueCourses(state, action);
    case actionTypes.RESET_CATALOGUE_COURSES:
      return resetCatalogueCourses(state);
    case actionTypes.SET_CATALOGUE_LP:
      return setCatalogueLP(state, action);
    case actionTypes.RESET_CATALOGUE_LP:
      return resetCatalogueLP(state);
    case actionTypes.SET_SUGGESTION_INPUT:
      return setSuggestionInput(state, action);
    case actionTypes.BOOK_SESSION_REQUEST:
      return bookSessionRequest(state);
    case actionTypes.BOOK_SESSION_SUCCESS:
      return bookSessionSuccess(state);
    case actionTypes.BOOK_SESSION_FAILURE:
      return bookSessionFailure(state);
    case actionTypes.TOGGLE_LIKE_REQUEST:
      return toggleLikeRequest(state);
    case actionTypes.TOGGLE_LIKE_SUCCESS:
      return toggleLikeSuccess(state);
    case actionTypes.TOGGLE_LIKE_FAILURE:
      return toggleLikeFailure(state);
    case actionTypes.UPDATE_WISHLIST_REQUEST:
      return updateWishlistRequest(state);
    case actionTypes.UPDATE_WISHLIST_SUCCESS:
      return updateWishlistSuccess(state);
    case actionTypes.UPDATE_WISHLIST_FAILURE:
      return updateWishlistFailure(state);
    case actionTypes.RESET_SEARCH:
      return resetSearch(state);
    case actionTypes.UPDATE_COURSE_ID_SUBARRAY_MAP:
      return updateCourseIdSubarray(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.SET_CURRENT_COURSE:
      return setCurrentCourse(state, action);
    case actionTypes.OPEN_SUB_COURSE_IN_LP:
      return openSubCourseInLP(state, action);
    case actionTypes.TOGGLE_COURSE_MODAL:
      return toggleCourseModal(state, action);
    case actionTypes.RESET_SCROLL_SESSIONS:
      return resetScrollToCurrentCourseSessions(state);
    case actionTypes.GET_COURSE_DETAILS_REQUEST:
      return getCourseDetailsRequest(state, action);
    case actionTypes.GET_COURSE_DETAILS_SUCCESS:
      return getCourseDetailsSuccess(state, action);
    case actionTypes.GET_COURSE_DETAILS_FAILURE:
      return getCourseDetailsFailure(state, action);
    case actionTypes.GET_COURSE_0_COMPLETED:
      return getCourse0Completed(state, action);
    case actionTypes.GET_COURSES_0_REQUEST:
      return getCourses0Request(state);
    case actionTypes.GET_COURSES_0_FAILURE:
      return getCourses0Failure(state);
    case actionTypes.GET_COURSE_COMPLETION_REQUEST:
      return getCourseCompletionRequest(state);
    case actionTypes.SET_IS_COURSE_COMPLETION_COMPLETED:
      return setIsGetCourseCompletionCompleted(state, action);
    case actionTypes.GET_COURSE_COMPLETION_COMPLETED:
      return getCourseCompletionCompleted(state);
    case actionTypes.GET_COURSE_COMPLETION_BY_ID_REQUEST:
      return getCourseCompletionByIdRequest(state);
    case actionTypes.GET_COURSE_COMPLETION_BY_ID_SUCCESS:
      return getCourseCompletionByIdSuccess(state, action);
    case actionTypes.GET_COURSE_COMPLETION_BY_ID_FAILURE:
      return getCourseCompletionByIdFailure(state);
    case actionTypes.GET_COURSE_SUMMARY_REQUEST:
      return getCourseSummaryRequest(state, action);
    case actionTypes.GET_COURSE_SUMMARY_SUCCESS:
      return getCourseSummarySuccess(state, action);
    case actionTypes.GET_COURSE_SUMMARY_FAILURE:
      return getCourseSummaryFailure(state, action);
    case actionTypes.RESET_VIRTUAL:
      return resetVirtual(state);
    case actionTypes.CLEAN_COMPLETED_COURSES:
      return cleanCompletedCourses(state);
    case actionTypes.GET_SUBSCRIBED_COURSES:
      return getSubscribedCourses(state, action);
    case actionTypes.GET_JOINING_COURSES:
      return setJoiningCourses(state, action);
    case actionTypes.GET_JOINING_LIVESTREAM:
      return setJoiningLivestreams(state, action);
    case actionTypes.NOTICE_ARRIVED:
      return noticeArrive(state);
    case actionTypes.CLEAR_NOTICE_ARRIVED:
      return clearNoticeArrive(state);
    case actionTypes.SET_IS_COURSE_DETAIL_OPENED_BY_URL:
      return setIsCourseDetailOpenedByUrl(state, action);
    case actionTypes.TOGGLE_TEACHER_MODAL:
      return toggleTeacherModal(state, action);
    case actionTypes.TOGGLE_RATING_MODAL:
      return toggleRatingModal(state, action);
    case actionTypes.TOGGLE_NEXT_UP_MODAL:
      return toggleNextUpModal(state, action);
    case actionTypes.OPEN_CONFIRM_BOOKING_MODAL:
      return openConfirmBookingModal(state, action);
    case actionTypes.CLOSE_CONFIRM_BOOKING_MODAL:
      return closeConfirmBookingModal(state, action);
    case actionTypes.TOGGLE_DOWNLOAD_LINK_MODAL:
      return toggleDownloadLinkModal(state, action);
    case actionTypes.GET_YMAL_REQUEST:
      return getYMALRequest(state);
    case actionTypes.GET_YMAL_SUCCESS:
      return getYMALSuccess(state, action);
    case actionTypes.GET_YMAL_FAILURE:
      return getYMALFailure(state);
    case actionTypes.SET_PREVIEW_VIDEO_URL:
      return setPreviewVideoUrl(state, action);
    case actionTypes.LP_ENROLL_REQUEST:
      return enrollLpRequest(state);
    case actionTypes.LP_ENROLL_SUCCESS:
      return enrollLpSuccess(state);
    case actionTypes.LP_ENROLL_FAILURE:
      return enrollLpFailure(state);
    case actionTypes.TRIGGER_APPJS_UPDATE:
      return triggerAppJsUpdate(state);
    default:
      return state;
  }
};

export default reducer;
