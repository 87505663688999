import React, { useEffect, useRef, useState } from 'react';
// import InputRange from "./Slider";
import Slider from 'react-slick';
import ChevronLeft from '@svg-icons/carousel-arrow-left.svg';
import ChevronLeft2 from '@svg-icons/chevron-left-round.svg';

import {
  carouselSettingsPill,
  carouselSettingsCardProgressDefault,
  carouselSettingsImageDefault,
  carouselSettingsCardMediumPillDefault,
  carouselSettingsCardMediumPillVariableWidthDefault,
  carouselSettingsCardVcSessionDefault,
  carouselSettingsVcSessionsDesktop,
  carouselSettingsVcSessionsTablet
} from './carouselSettings';
import { v4 as uuidv4 } from 'uuid';
import { LangMapWcag } from '@model/CoursesClass';
import useLangAuth from '@hooks/useLangAuth';
import clsx from '@utility/clsx';
import LazySVG from '@components/LazySvg';
import { AnimateSharedLayout, motion } from 'framer-motion';
import CardCover from '@components/CardV3/CardCover';
import { scrollToElement } from '@utility/scrollUtility';

const clickStop = (e, onClick) => {
  e.stopPropagation();
  if (onClick) {
    onClick(e);
  }
};

const Chevron = ({ round = false, arrowsStyle = '' }) => {
  switch (arrowsStyle) {
    case 'tp-image':
      return <LazySVG src="/images/svg-icons/arrow-right5.svg" alt="" />;
    default:
      return round ? <ChevronLeft2 /> : <ChevronLeft />;
  }
};

export const PrevArrow = ({
  onClick,
  className,
  ariaLabel = '',
  nextDisabled,
  round,
  tabIndex = -1,
  arrowsStyle = '',
  dataElementId,
}) => (
  <button
    onClick={e => clickStop(e, onClick)}
    type="button"
    aria-label={ariaLabel}
    disabled={className?.includes('slick-disabled')}
    className={className + (nextDisabled ? ' slick-next-disabled' : '')}
    tabIndex={tabIndex}
    data-element-id={`${dataElementId}_Left`}
  >
    <Chevron round={round} arrowsStyle={arrowsStyle} />
  </button>
);

export const NextArrow = ({
  onClick,
  className,
  ariaLabel = '',
  setDisable,
  round,
  tabIndex = 0,
  arrowsStyle = '',
  dataElementId,
}) => {
  const disabled = className?.includes('slick-disabled');

  useEffect(() => {
    setDisable(disabled);
  }, [disabled]);

  return (
    <button
      onClick={e => clickStop(e, onClick)}
      type="button"
      aria-label={ariaLabel}
      disabled={disabled}
      className={className}
      tabIndex={tabIndex}
      data-element-id={`${dataElementId}_Right`}
    >
      <Chevron round={round} arrowsStyle={arrowsStyle} />
    </button>
  );
};

export type CarouselPicture = {
  alt: string;
  image: string;
  fallbackImage: string;
};

export type Props = {
  numSlides: number;
  children: React.ReactNode;
  showSlider?: boolean;
  className?: string;
  variant: 'pills' | 'progress' | 'training-pills' | 'image' | 'vc-sessions-mobile' | 'vc-sessions-desktop' | 'vc-sessions-tablet';
  variableWidth?: boolean;
  swipeToSlide?: boolean;
  roundChevron?: boolean;
  vertical?: boolean;
  arrowsStyle?: 'latest-collections' | 'tp-image';
  showPreviewBottom?: boolean;
  pictures?: CarouselPicture[];
  speed?: number;
  containerQuery?: string;
};

const Carousel = ({
  children,
  numSlides,
  showSlider = true,
  variant,
  className,
  variableWidth,
  swipeToSlide,
  roundChevron,
  vertical,
  arrowsStyle,
  showPreviewBottom,
  pictures,
  speed = 500,
  containerQuery = '',
}: Props) => {
  const sliderRef = useRef<Slider>();
  const [nextArrowDisabled, setNextArrowDisabled] = useState(false);
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [customSettings, setCustomSettings] = useState<any>({});
  const [uniqueIdSlider, setUniqueIdSlider] = useState<string>('Slider_' + uuidv4());
  const [selectedItem, setSelectedItem] = useState(0);

  const langKeys: Readonly<(keyof LangMapWcag)[]> = [
    'GO_TO_PAGE_CAROUSEL',
    'NEXT',
    'PREVIOUS',
    'PAGING_CAROUSEL',
  ] as const;
  const labels = useLangAuth(langKeys);

  const handleAppendDots = (dots: React.ReactElement): React.ReactElement => (
    <div>
      {!!dots && (
        <PrevArrow
          onClick={() => {
            if (selectedItem > 0) {
              sliderRef?.current?.slickGoTo(selectedItem - 1);
            }
          }}
          className={
            'image-section__dots-arrow image-section__dots-arrow-left ' +
            (selectedItem <= 0 ? 'slick-disabled' : '')
          }
          ariaLabel={labels?.PREVIOUS}
          nextDisabled={nextArrowDisabled}
          round={false}
          arrowsStyle="tp-image"
        />
      )}
      <AnimateSharedLayout>
        <section className="slick-dots-content" aria-label={labels.PAGING_CAROUSEL}>
          {dots}
        </section>
      </AnimateSharedLayout>
      {!!dots && (
        <NextArrow
          onClick={() => {
            if (selectedItem < numSlides - 1) {
              sliderRef?.current?.slickGoTo(selectedItem + 1);
            }
          }}
          className={
            'image-section__dots-arrow image-section__dots-arrow-right ' +
            (selectedItem >= numSlides - 1 ? 'slick-disabled' : '')
          }
          ariaLabel={labels?.NEXT}
          setDisable={setNextArrowDisabled}
          round={false}
          arrowsStyle="tp-image"
        />
      )}
    </div>
  );

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    setSelectedItem(newIndex);
    scrollToElement(
      containerQuery + ' .slick-dots-content li:nth-child(' + (newIndex + 1).toString() + ')',
      containerQuery + ' .slick-dots-content',
      'smooth',
      'nearest',
      'center',
      true
    );
  };

  const handlePreviewBottom = (index: number) => {
    const picture = pictures?.[index];

    if (!picture) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div className="image-section__preview-image-container">
        <CardCover
          className="image-section__preview-image"
          courseFullName={picture.alt}
          image={picture?.image?.split?.('?')?.[0]}
          fallbackImage={picture.fallbackImage}
          variant="image-carousel-preview"
          hideShadow
          centerSpinner={false}
        />
        {selectedItem === index && (
          <motion.div
            className="image-section__preview-image-slider"
            initial={false}
            layoutId="images-carousel-dots"
            transition={{
              type: 'linear',
              duration: speed / 1000,
            }}
          />
        )}
      </div>
    );
  };

  const defaultSettings = {
    draggable: false, //to set it to true --> prevent click on drag https://github.com/akiran/react-slick/issues/848
    speed: speed,
    infinite: false,
    onSwipe: () => {},
    prevArrow: (
      <PrevArrow
        onClick={() => {}}
        className=""
        ariaLabel={labels?.PREVIOUS}
        nextDisabled={nextArrowDisabled}
        round={variableWidth || roundChevron}
        arrowsStyle={arrowsStyle}
      />
    ),
    nextArrow: (
      <NextArrow
        onClick={() => {}}
        className=""
        ariaLabel={labels?.NEXT}
        setDisable={setNextArrowDisabled}
        round={roundChevron || variableWidth}
        arrowsStyle={arrowsStyle}
      />
    ),
    appendDots: showPreviewBottom
      ? handleAppendDots
      : dots => (
          <section className="slick-dots" aria-label={labels.PAGING_CAROUSEL}>
            {dots}
          </section>
        ),
    customPaging: showPreviewBottom
      ? handlePreviewBottom
      : function (i) {
          return <button>{labels.GO_TO_PAGE_CAROUSEL.replace('{num}', i + 1)}</button>;
        },
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: showPreviewBottom ? handleBeforeChange : undefined,
  };

  useEffect(() => {
    //Set custom settings for slider
    switch (variant) {
      case 'training-pills':
        setCustomSettings(
          !variableWidth
            ? carouselSettingsCardMediumPillDefault
            : carouselSettingsCardMediumPillVariableWidthDefault
        );
        break;
      case 'pills':
        setCustomSettings(carouselSettingsPill);
        break;
        case 'vc-sessions-desktop':
          setCustomSettings(carouselSettingsVcSessionsDesktop);
          break;
          case 'vc-sessions-tablet':
            setCustomSettings(carouselSettingsVcSessionsTablet);
            break;
      case 'progress':
        setCustomSettings(carouselSettingsCardProgressDefault);
        break;
      case 'vc-sessions-mobile':
        setCustomSettings(carouselSettingsCardVcSessionDefault);
        break;
      case 'image':
        setCustomSettings(carouselSettingsImageDefault);
    }

    //Add listener on resize to initialize slider again
    window.addEventListener('resize', resizeFunction);
    return () => window.removeEventListener('resize', resizeFunction);
  }, []);

  const getSettings = () => {
    let { slidesToShow } = getBreakpointSettings();
    // let infinite = false;

    // if(slidesToShow !== undefined && numSlides > slidesToShow){
    //   infinite = true
    // }

    return {
      dots: showSlider && showSliderFunc(),
      ...defaultSettings,
      ...customSettings,
      infinite: false,
      swipeToSlide,
      ...(variableWidth && { variableWidth: true }),
      ...(vertical && { vertical: true }),
    };
  };

  const resizeFunction = () => {
    setUniqueIdSlider('Slider_' + uuidv4());
  };

  const getBreakpointSettings = () => {
    if (customSettings && customSettings.responsive && customSettings.responsive.length > 0) {
      let bp = null;
      customSettings.responsive.map(setting => {
        if (window.innerWidth < setting.breakpoint) {
          bp = setting;
        }
      });

      if (bp && bp.settings) {
        return bp.settings;
      } else {
        return customSettings;
      }
    }

    return {};
  };

  const showSliderFunc = () => {
    let { slidesToShow } = getBreakpointSettings();
    return slidesToShow !== undefined && numSlides > slidesToShow ? true : false;
  };

  const getLength = () => {
    let { slidesToScroll } = getBreakpointSettings();

    return numSlides / slidesToScroll;
  };

  const onHandleReInit = () => {
    const listItemsActive = sliderRef?.current?.innerSlider?.list?.querySelectorAll?.(
      '.slick-slide.slick-active'
    );
    const listItemsNotActive = sliderRef?.current?.innerSlider?.list?.querySelectorAll?.(
      '.slick-slide:not(.slick-active)'
    );

    listItemsNotActive?.forEach((item, index) => {
      item?.querySelector('.card-v3')?.setAttribute?.('tabindex', -Math.abs(index + 1));
      item?.querySelector('.card-v3')?.setAttribute?.('aria-hidden', true);
    });

    listItemsActive?.forEach(item => {
      item?.querySelector('.card-v3')?.setAttribute?.('tabindex', 0);
      item?.querySelector('.card-v3')?.setAttribute?.('aria-hidden', false);
    });
  };

  return (
    Object.keys(customSettings).length > 0 && (
      <div
        className={clsx('carousel', {
          [variant]: variant,
          [className]: className,
          'top-arrow': variableWidth,
          vertical,
          'preview-bottom': showPreviewBottom,
        })}
        id={uniqueIdSlider}
      >
        <Slider {...getSettings()} ref={sliderRef} onReInit={onHandleReInit}>
          {children}
        </Slider>
        {/* {showSlider && showSliderFunc() && (
        <div className="carousel-slider-wrapper">
          <InputRange
            max={getLength()}
            current={currentSlide}
            handleOnChange={(val) => sliderRef?.current?.slickGoTo(val)}
            horizontal
          />
        </div>
      )} */}
      </div>
    )
  );
};

export default Carousel;
