import { useTranslation } from "react-i18next";
import {useSelector} from './redux'
const useGetLang = () => {
  const { i18n } = useTranslation();
  const userProfile = useSelector((state) => state.user.userProfile);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)

  if(userProfile?.preferredLang && isAuthenticated) {
    return userProfile.preferredLang.toUpperCase();
  }

return !isAuthenticated? i18n.language.toUpperCase(): "EN";
}

export default useGetLang;