import { SKELETON_COLORS } from "@utility/const"
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";

const widthDesktop = 321;
const heightDesktop = 244;
const widthMobile = 321;
const heightMobile = 67;

const CardMediumCompletedSkeleton = (props) => {
  const { isDesktopOrTabletLandscape } = useBreakpointBoolean();

  const [width, setWidth] = useState<number>(widthDesktop);
  const [height, setHeight] = useState<number>(widthDesktop);

  useEffect(() => {
    if (isDesktopOrTabletLandscape) {
      setWidth(widthDesktop);
      setHeight(heightDesktop);
    } else {
      setWidth(widthMobile);
      setHeight(heightMobile);
    }
  }, [isDesktopOrTabletLandscape]);

  return (
    <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden', margin: '0 auto', maxWidth: 'calc(100vw - 32px)' }}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        {/* DESKTOP */}
        <rect className="desktop" x="0" y="0" rx="12" ry="12" width="321" height="180" />
        <rect className="desktop" x="12" y="193" rx="12" ry="12" width="184" height="20" />
        <rect className="desktop" x="204" y="193" rx="12" ry="12" width="60" height="20" />
        <rect className="desktop" x="12" y="220" rx="12" ry="12" width="220" height="24" />

        {/* TABLET_LANDSCAPE */}
        {isDesktopOrTabletLandscape &&
          <>
          <rect className="tablet-landscape" x="0" y="0" rx="12" ry="12" width="300" height="180" />
          <rect className="tablet-landscape" x="12" y="193" rx="12" ry="12" width="184" height="20" />
          <rect className="tablet-landscape" x="204" y="193" rx="12" ry="12" width="60" height="20" />
          <rect className="tablet-landscape" x="12" y="220" rx="12" ry="12" width="220" height="24" />
          </>
        }

        {/* MOBILE */}
        {!isDesktopOrTabletLandscape &&
          <>
            <rect className="mobile" x="0" y="0" rx="12" ry="12" width="120" height="67" />
            <rect className="mobile" x="135" y="0" rx="12" ry="12" width="186" height="24" />
            <rect className="mobile" x="135" y="30" rx="12" ry="12" width="186" height="20" />
          </>
        }  
</ContentLoader>

    </div>
  )
}

export default CardMediumCompletedSkeleton