import React, { ReactElement, useEffect, useState } from 'react';
import * as actions from '@redux-actions/';
import CardMediumPill from './CardMediumPill';
import { TrainingPill } from '@model/ProductClass';
import { FALLBACK_IMAGE_TP_URL, getBagdesFromPill } from '@utility/TrainingPillsUtility';
import useWishlistTP from '@hooks/useWishlistTP';
import { useSelector } from '@hooks/redux';
import { useDispatch } from 'react-redux';
import { openTrainingPillDetailModal } from '@redux-actions/';
import { useLocation } from 'react-router-dom';

type Props = {
  product: TrainingPill;
  showAsMobile?: boolean;
  tabIndex?: number;
  beforeOnClickCard?: (product: TrainingPill, elementPosition: number) => void;
  elementPosition?: number;
};

const CardControllerProduct = (props: Props): ReactElement => {
  const { product, elementPosition } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = useSelector(state => state.utils.lang);
  const brandsList = useSelector(state => state.utils.brandsList);

  const [badgeLabels, setBadgeLabels] = useState< (string[] |string)[]>([]);
  const [brandString, setBrandString] = useState<string>('');

  const [isWishlist, isLoadingWishlistLocal, handleToggleWish] = useWishlistTP(product);

  useEffect(() => {
    if (!product) {
      return;
    }

    //get badge labels
    setBadgeLabels(getBagdesFromPill(product, lang, false, location.pathname));

    setBrandString(
      product?.brandString
        ? actions.getBrandNameById(brandsList, product?.brandString)
        : product?.brandName
    );
  }, [product, lang, brandsList]);

  const openProductModal = () => {
    if (props.beforeOnClickCard) {
      props.beforeOnClickCard(product, elementPosition);
    }

    dispatch(openTrainingPillDetailModal(true, product.model));
  };

  return (
    <CardMediumPill
      lang={lang}
      product={product}
      brandName={brandString}
      modelId={product?.model}
      modelName={product?.modelName}
      badgeLabels={badgeLabels}
      newPicksForYou={product?.newPicksForYou}
      isComingSoon={(!product?.brandName || !product.image) && !product.isNovelty}
      image={product?.image}
      shipmentDate={product?.orderDate}
      fallbackImage={FALLBACK_IMAGE_TP_URL || product?.fallbackImage}
      isWishlist={isWishlist}
      isLoadingWishlist={isLoadingWishlistLocal}
      isLoadingWishlistLocal={isLoadingWishlistLocal}
      toggleWishlist={handleToggleWish}
      openProductModal={openProductModal}
      showAsMobile={props.showAsMobile}
      tabIndex={props.tabIndex}
      isNovelty={product.isNovelty}
    />
  );
};

export default React.memo(CardControllerProduct);
