import React from 'react';
import { useSelector } from '@hooks/redux';
import AnimationSlide from '@components/animations/AnimationSlide';
import CloseIconModalRight from '@components/UI/CloseIconModalRight';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import AnimationOpacity from '@components/animations/AnimationOpacity';

const animationSlideToTopDefault = {
  initialPosition: 'top',
  initialX: '0',
};

const animationSlideToLeftDefault = {
  initialPosition: 'right',
  initialX: '0',
};

const animationXSection = '20px';
const animationYSection = '30px';

type SearchColumnsProps = {
  className?: string;
  show: boolean;
  closeModal: () => void;
  closeDataId?: string;
  header?: React.ReactNode;
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
  thirdColumn?: React.ReactNode;
  firstColumnProps?: any;
  secondColumnProps?: any;
  secondColumnClassName?: string;
  notModal?: boolean;
};

const SearchColumns = (props: SearchColumnsProps) => {
  const lang = useSelector(state => state.utils.lang);

  const animationDurationSection = props?.notModal ? 0 : 0.2;
  const animationDurationModal = props?.notModal ? 0 : 0.5;

  return (
    <AnimatePresence>
      {props.show && (
        <FocusTrap
          active={props.show}
          focusTrapOptions={{
            fallbackFocus: 'body',
            allowOutsideClick: true,
          }}
        >
          <AnimationSlide
            {...animationSlideToTopDefault}
            initialX={animationYSection}
            duration={animationDurationSection}
            className={'search-columns ' + (props.className ? props.className : '')}
            extraProps={{ onClick: e => e.stopPropagation() }}
            exitProps={{ position: 'absolute', ...(props?.notModal ? { y: 0 } : null) }}
          >
            {props.header && (
              <AnimationOpacity
                duration={animationDurationSection}
                delay={animationDurationModal / 1.5}
                useSectionTagHtml={true}
                {...props.firstColumnProps}
              >
                {props.header}
              </AnimationOpacity>
            )}
            <div className="search-columns__container">
              {!props.notModal && (
                <CloseIconModalRight
                  onClick={props.closeModal}
                  ariaLabel={lang?.CLOSE_MODAL}
                  dataElementId={props?.closeDataId}
                  squareButtonMobile={true}
                />
              )}
              <AnimationSlide
                className="search-columns__section scrollable-section search-columns__first-column"
                {...animationSlideToLeftDefault}
                initialX={props.notModal ? 0 : animationXSection}
                duration={animationDurationSection}
                delay={animationDurationModal}
                useSectionTagHtml={true}
                {...props.firstColumnProps}
              >
                {props.firstColumn}
              </AnimationSlide>
              <AnimationSlide
                className={
                  'search-columns__section scrollable-section search-columns__second-column search-columns__column-larger' +
                  (!props.secondColumnClassName ? '' : ' ' + props.secondColumnClassName)
                }
                {...animationSlideToLeftDefault}
                initialX={props.notModal ? 0 : animationXSection}
                duration={animationDurationSection}
                delay={animationDurationModal}
                useSectionTagHtml={true}
                {...props.secondColumnProps}
              >
                {props.secondColumn}
              </AnimationSlide>
            </div>
          </AnimationSlide>
        </FocusTrap>
      )}
    </AnimatePresence>
  );
};

export default SearchColumns;
