import * as actionTypes from '../actions/actionTypes';
import { normalizeUserProfile, updateObject } from '../../utility/Api';
import { UserProfile } from '@model/User';
import { LeaderboardModel } from '@model/LeaderboardModel';
import { updateLearnerNameAndDownloadCertificate } from '../actions/userActions';


class InitialStateProps {
    userProfile: UserProfile = null;
    isUserCompleted: boolean = false;
    isFullUserCompleted: boolean = false;
    isUserCompletedAnalytics = null;
    isUserUpdatedCompleted: boolean = false;
    isUserUpdateLoading: boolean = false;
    isOnlyUser: boolean = false;
    leaderboard: LeaderboardModel["leaderboardInfo"] = null;
    leaderboardCy: LeaderboardModel["leaderboardInfo"] = null;
    isLeaderboardLoading: boolean = false;
    isLeaderboardCyLoading: boolean = false;
    isDeletingUser: boolean = false;
    //handle if show cart icon modal
    noShowModalCart: boolean = false;
    //handle if show expiration label modal
    noShowModalExpiration: boolean = false;
    //is user enabled to ecommerce?
    ecommerceEnable: boolean = false;
    isLoadingLearnerName: boolean = false;
    //SMART CERT
    learnerName: string = '';
    // USER BYC
    isBycUser: boolean = null;
    isBycOffice: boolean = null;
}

const initialState: InitialStateProps = new InitialStateProps();


const getUserProfileRequest = state => {
    return updateObject(state, {
        isUserCompleted: false
    });
}

const getUserProfileSuccess = (state, action) => {
    return updateObject(state, {
        userProfile: normalizeUserProfile(action.userProfile),
        isUserCompleted: true,
        isFullUserCompleted: state.isFullUserCompleted || !!action.isFullUserCompleted,
        isBycUser: action.isBycUser,
        isBycOffice: action.isBycOffice,
        isUserCompletedAnalytics: action.isUserCompletedAnalytics
    });
}

const getUserProfileUpdateRequest = state => {
    return updateObject(state, {
        isUserUpdatedCompleted: false,
        isUserUpdateLoading: true
    });
}

const getUserProfileUpdateSuccess = (state, action) => {
    return updateObject(state, {
        userProfile: normalizeUserProfile(action.userProfile),
        isUserUpdatedCompleted: true,
        isUserUpdateLoading: false,
        isOnlyUser: action.isOnlyUser
    });
}

const getUserProfileUpdateFailure = state => {
    return updateObject(state, {
        isUserUpdatedCompleted: false,
        isUserUpdateLoading: false
    });
}

const resetUserProfileUpdate = state => {
    return updateObject(state, {
        isUserUpdatedCompleted: false,
        isUserUpdateLoading: false
    });
}

const getLeaderboardRequest = state => {
    return updateObject(state, {
        isLeaderboardLoading: true
    });
}

const getLeaderboardSuccess = (state, action) => {
    return updateObject(state, {
        leaderboard: action.leaderboard,
        isLeaderboardLoading: false
    });
}

const getLeaderboardFailure = (state) => {
    return updateObject(state, {
        isLeaderboardLoading: false
    });
}

const getLeaderboardCyRequest = state => {
    return updateObject(state, {
        isLeaderboardCyLoading: true
    });
}

const getLeaderboardCySuccess = (state, action) => {
    return updateObject(state, {
        leaderboardCy: action.leaderboardCy,
        isLeaderboardCyLoading: false
    });
}

const getLeaderboardCyFailure = (state) => {
    return updateObject(state, {
        isLeaderboardCyLoading: false
    });
}

const deleteUserRequest = state => {
    return updateObject(state, {
        isDeletingUser: true
    });
}

const deleteUserSuccess = (state) => {
    return updateObject(state, {
        isDeletingUser: false
    });
}

const deleteUserFailure = (state) => {
    return updateObject(state, {
        isDeletingUser: false
    });
}

const setNoShowModalCart = (state, action) => {
    return updateObject(state, {
        noShowModalCart: action.value,
    });
}

//NO MORE USED
const setNoShowModalExpiration = (state, action) => {
    return updateObject(state, {
        noShowModalExpiration: action.value,
    });
}

const setEcommerceEnable = (state, action) => {
    return updateObject(state, {
        ecommerceEnable: action.value,
    });
}

const setLearnerName = (state, action) => {
    return updateObject(state, {
        learnerName: action.learnerName
    })
}

const logout = () => {
    return initialState;
}

const reducer = (state = initialState, action): InitialStateProps => {
    switch (action.type) {
        case actionTypes.GET_USER_PROFILE_REQUEST: return getUserProfileRequest(state);
        case actionTypes.GET_USER_PROFILE_SUCCESS: return getUserProfileSuccess(state, action);
        case actionTypes.GET_USER_PROFILE_UPDATE_REQUEST: return getUserProfileUpdateRequest(state);
        case actionTypes.GET_USER_PROFILE_UPDATE_SUCCESS: return getUserProfileUpdateSuccess(state, action);
        case actionTypes.GET_USER_PROFILE_UPDATE_FAILURE: return getUserProfileUpdateFailure(state);
        case actionTypes.GET_LEADERBOARD_REQUEST: return getLeaderboardRequest(state);
        case actionTypes.GET_LEADERBOARD_SUCCESS: return getLeaderboardSuccess(state, action);
        case actionTypes.GET_LEADERBOARD_FAILURE: return getLeaderboardFailure(state);

        case actionTypes.GET_LEADERBOARDCY_REQUEST: return getLeaderboardCyRequest(state);
        case actionTypes.GET_LEADERBOARDCY_SUCCESS: return getLeaderboardCySuccess(state, action);
        case actionTypes.GET_LEADERBOARDCY_FAILURE: return getLeaderboardCyFailure(state);

        case actionTypes.RESET_USER_PROFILE_UPDATE: return resetUserProfileUpdate(state);
        case actionTypes.DELETE_USER_REQUEST: return deleteUserRequest(state);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state);
        case actionTypes.DELETE_USER_FAILURE: return deleteUserFailure(state);
        case actionTypes.SET_NO_SHOW_MODAL_CART: return setNoShowModalCart(state, action);
        case actionTypes.SET_NO_SHOW_MODAL_EXPIRATION: return setNoShowModalExpiration(state, action);
        case actionTypes.SET_ECOMMERCE_ENABLE: return setEcommerceEnable(state, action);

        case actionTypes.SET_LEARNER_NAME: return setLearnerName(state, action)

        case actionTypes.LOGOUT: return logout();
        default:
            return state;
    }
};

export default reducer;