import React from 'react';
import { motion, MotionProps } from "framer-motion";
import { Easing, Transition } from 'framer-motion/types/types';

interface AnimationExpandProps {
  children?: React.ReactNode;
  initialWidth: string;
  finalWidth: string;
  backgroundColor?: string;
  animationDuration?: number;
  animationDelay?: number;
  animationEase?: Easing;
  extraProps?: MotionProps;
}

const AnimationExpand: React.FC<AnimationExpandProps> = ({
  initialWidth,
  finalWidth,
  backgroundColor,
  animationDuration,
  animationDelay,
  animationEase,
  children,
  extraProps,
}): React.ReactElement => {
  const variants = {
    initial: {
      width: initialWidth
    },
    animate: {
      width: finalWidth
    }
  };

  const transition: Transition = {
    width: { ease: animationEase, duration: animationDuration, delay: animationDelay },
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      transition={transition}
      className="animation-expand"
      style={{ backgroundColor: backgroundColor }}
      {...extraProps}
    >
      {children}
    </motion.div>
  );
};

AnimationExpand.defaultProps = {
  initialWidth: '10%',
  finalWidth: '100%',
  animationDuration: 1.5,
  animationDelay: 0.5,
  animationEase: 'backInOut'
};

export default AnimationExpand;
