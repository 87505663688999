import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Course } from '@model/CoursesClass';
import CarouselSection from '@components/CarouselSection';
import { useSelector } from '@hooks/redux';
import { getYouMayAlsoLike } from '@redux-actions/';
import { isCourseHarvard } from '@components/Harvard/Harvard';

interface RecommendedSectionProps {
  course: Course;
}

const RecommendedSection = ({ course }: RecommendedSectionProps) => {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.utils.lang);

  const isLoadingYMAL = useSelector(state => state.course.isLoadingYMAL);
  const youMayAlsoLikeCourses = useSelector(state => state.course.youMayAlsoLikeCourses);

  useEffect(() => {
    dispatch(getYouMayAlsoLike(course.courseIdMaster));
  }, []);

  return (
    <>
      {youMayAlsoLikeCourses?.[course.courseIdMaster]?.length > 0 && (
        <section className={'recommended-section ' + (isCourseHarvard(course) ? "recommended-section-harvard" : '')} aria-label={lang.YOU_MAY_ALSO_LIKE}>
          <CarouselSection
            courses={youMayAlsoLikeCourses?.[course.courseIdMaster]}
            lang={lang}
            title="YOU_MAY_ALSO_LIKE"
            classNames="carousel-max-width"
            variableWidth
            isLoading={isLoadingYMAL}
            key={
              isLoadingYMAL
                ? `ymal-loading-${course.courseIdMaster}`
                : `ymal-${course.courseIdMaster}`
            }
          />
        </section>
      )}
    </>
  );
};

export default RecommendedSection;
