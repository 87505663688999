import { USER_URLS } from "@components/link-utils";
import { CollectionNovelty, NoveltyByBrand } from "@model/collections/CollectionModel";
import { UserProfile } from "@model/User";
import history from "../history";
import { promiseMock } from "./ecommerceUtility";
import { FALLBACK_IMAGE_TP_URL } from "./TrainingPillsUtility";


export const COLLECTION_ACTIVE_VALUES = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
};
export const isUserEnabledForCollections = (userProfile: UserProfile): boolean => {
    return userProfile?.noveltiesStatus === COLLECTION_ACTIVE_VALUES.ENABLED;
}

export const goToCollectionPage = (collectionId: string, brandId: string) => {
    const url = getCollectionPageUrl(collectionId, brandId);

    if (url) {
        history.push(url);
    }
}

export const getCollectionPageUrl = (collectionId: string, brandId: string) => {
    if (!collectionId || !brandId) {
        return '';
    }

    return USER_URLS.COLLECTION.URL
        .replace(':collectionId', collectionId)
        .replace(':brandId', brandId);
}

export const getNoveltiesStoryCataloguePageUrl = (releaseLongName: string, brandId: string, storyId: string): string => {
    if (!releaseLongName || !brandId || !storyId) {
        return '';
    }

    return USER_URLS.SUBCATALOGUE_NOVELTIES_STORY.URL
        .replace(':release', encodeSlash(encodeURIComponent(releaseLongName)))
        .replace(':brandId', encodeSlash(encodeURIComponent(brandId)))
        .replace(':storyId', encodeSlash(encodeURIComponent(storyId)));
}

export const encodeSlash = (query: string) => {
    //replace %2F with _XX_ to prevent 404 error refreshing the page
    if (!query) {
        return "";
    }

    return query.replace(/%2F/g, '_XX_');
}

export const decodeSlash = (query: string) => {
    //replace _XX_ with %2F to prevent 404 error refreshing the page
    if (!query) {
        return "";
    }

    return query.replace(/_XX_/g, '%2F');
}

export const getCollectionInfoMock = async () => {
    const data = await fetch('/data/collections/collectionInfo.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const getCollectionStoriesMock = async () => {
    const data = await fetch('/data/collections/collectionStories.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const getBrandCampaignsMock = async () => {
    const data = await fetch('/data/collections/brandCampaignsMock.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}
export const getNoveltiesMock = async () => {
    const data = await fetch('/data/collections/latest-collections-carousel.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const getPillsFromSameCollectionMock = async () => {
    const data = await fetch('/data/collections/pills-from-same-collection.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const transformNoveltiesIntoTrainingPills = (novelties: CollectionNovelty[], brandCollection?: NoveltyByBrand) => {
    if (!novelties) {
        return [];
    }

    return novelties.map(novelty => ({
        image: novelty.urlImage,
        fallbackImage: FALLBACK_IMAGE_TP_URL,
        brandName: novelty.brandName || brandCollection?.brandName || "",
        brandString: novelty.brand || brandCollection?.brand || "",
        model: novelty.code,
        modelName: novelty.modelName,
        isNovelty: true,
        advertising: novelty.advertising,
        ...(novelty?.brandCampaign?.length > 0 && {brandCampaign: novelty?.brandCampaign})
    }));
}

export const getLatestCollectionsCatalogueMock = async (variant = 1) => {
    let file = '/data/collections/latest-collections-catalogue.json';
    if (variant === 2) {
        file = '/data/collections/latest-collections-catalogue2.json';
    }
    const data = await fetch(file);
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const getBrandCollectionCatalogueMock = async (variant = 1) => {
    let file = '/data/collections/brand-collection-catalogue.json';
    if (variant === 2) {
        file = '/data/collections/brand-collection-catalogue2.json';
    }
    const data = await fetch(file);
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

export const getBrandCampaignMocoData = async () => {
    let file = '/data/collections/brand-campaign-by-moco.json';
   
    const data = await fetch(file);
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 3000);
}

// export const houseBrandsList = [
//     "A0", //Alain Mikly
//     "AN", //Arnette
//     "6S", //Costa
//     "OO", "OX", "OY", "OZ", "OJ", "OM", //Oakley
//     "OV", //Oliver Peoples
//     "PO", //Persol
//     "RB", "RX", "RY", "RJ", //Ray-Ban
//     "VO", "VJ", "VY", //Vogue
// ]