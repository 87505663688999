import { defaultBreakpoints, useBreakpoint } from "@hooks/createBreakpoint";
import { SKELETON_COLORS } from "@utility/const";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

const widthDesktop = 255;
const heightDesktop = 407;
const widthMobile = 220;
const heightMobile = 270;

const CardMediumPillSkeleton = (props) => {
  const breakpoint = useBreakpoint();

  const [width, setWidth] = useState<number>(widthDesktop);
  const [height, setHeight] = useState<number>(widthDesktop);

  useEffect(() => {
    if (breakpoint === defaultBreakpoints.desktop) {
      setWidth(widthDesktop);
      setHeight(heightDesktop);
    } else {
      setWidth(widthMobile);
      setHeight(heightMobile);
    }
  }, [breakpoint]);

  return (
    <div
      className={"card-v3-medium-pill__skeleton " + (props.showAsMobile ? 'showAsMobile' : '') + (props?.isInHP ? "hp-carousel": "")}
      style={{ width: `${width}px`, height: `${height}px`, overflow: "hidden"}}
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        //{...props}
      >

        {props?.isInHP ?
          <>
            {/* DESKTOP */}
            <rect className="desktop" x="0" y="0" rx="12" ry="12" width="255" height="230" />

            <rect className="desktop" x="10" y="247" rx="12" ry="12" width="184" height="24" />
            <rect className="desktop" x="10" y="287" rx="9.25" ry="9.25" width="154" height="18.5" />
            <rect className="desktop" x="10" y="327" rx="9.25" ry="9.25" width="154" height="18.5" />

            <rect className="desktop" x="10" y="367" rx="8" ry="8" width="60" height="16" />
            <rect className="desktop" x="85" y="367" rx="8" ry="8" width="60" height="16" />

            {/* MOBILE */}
            <rect className="mobile" x="0" y="0" rx="12" ry="12" width="220" height="198" />

            <rect className="mobile" x="10" y="190" rx="10" ry="10" width="110" height="20" />
            <rect className="mobile" x="10" y="215" rx="7" ry="7" width="80" height="14" />
            <rect className="mobile" x="10" y="235" rx="7" ry="7" width="80" height="14" />

            <rect className="mobile" x="10" y="255" rx="5.25" ry="5.25" width="30" height="10.5" />
            <rect className="mobile" x="40" y="255" rx="5.25" ry="5.25" width="30" height="10.5" />
          </>
          :
          <>
            {/* DESKTOP */}
            <rect className="desktop" x="0" y="0" rx="12" ry="12" width="207" height="207" />

            <rect className="desktop" x="10" y="227" rx="12" ry="12" width="184" height="24" />
            <rect className="desktop" x="10" y="267" rx="9.25" ry="9.25" width="154" height="18.5" />
            <rect className="desktop" x="10" y="307" rx="9.25" ry="9.25" width="154" height="18.5" />

            <rect className="desktop" x="10" y="347" rx="8" ry="8" width="60" height="16" />
            <rect className="desktop" x="85" y="347" rx="8" ry="8" width="60" height="16" />

            {/* MOBILE */}
            <rect className="mobile" x="0" y="0" rx="12" ry="12" width="164" height="164" />

            <rect className="mobile" x="10" y="180" rx="10" ry="10" width="100" height="20" />
            <rect className="mobile" x="10" y="205" rx="7" ry="7" width="80" height="14" />
            <rect className="mobile" x="10" y="225" rx="7" ry="7" width="80" height="14" />

            <rect className="mobile" x="10" y="245" rx="5.25" ry="5.25" width="30" height="10.5" />
            <rect className="mobile" x="40" y="245" rx="5.25" ry="5.25" width="30" height="10.5" />

          </>
        }

      </ContentLoader>
    </div>
  );
};

export default CardMediumPillSkeleton;
