import React from "react";
import { NOTIFICATION_ACTION, NOTIFICATION_ATTRIBUTE, updateType } from "./const";
import { authFirebase, myFirestore } from "../firebase/firebase";

import NotifAlertIcon from "@images/svg-icons/notif-alert.svg";
import NotifInfoIcon from "@images/svg-icons/notif-info.svg";
import NotifTimesIcon from "@images/svg-icons/notif-times.svg";
import NotifLeaderboardIcon from "@images/svg-icons/notif-leaderboard.svg";
import NotifLivestreamIcon from "@images/ctype-icons/livestream.svg";
import { getDateCard, getStartTimeCard, getParentCourse, getSessionByCourse, isUpcomingCourse, canSignUp, isCourseToBeRated, isLearningPath, isCourseCertificateCtype, isStartedCourse, isCompletedCourse } from "./Api";
import { printDateTimezone, printTimeTimezone, getLocalDateFromString } from '@utility/CalendarUtility';
import { getLivestreamDate, isLivestreamNotYetFinished } from "./LivestreamUtility";
import { isIdPlan } from "./ecommerceUtility";
import { isCourseOnBoarding } from "./onBoardingCourseUtility";
import { getCourseFullName, getLessonCompletionPercentage } from "./CourseUtility";

export const sortNotifications = (notifications) => {
  if (!notifications || notifications.length < 1) {
    return [];
  }

  return notifications.sort((a, b) => {
    if (!a.timestart && !b.timestart) {
      return 0;
    }

    if (!a.timestart) {
      return 1;
    }

    if (!b.timestart) {
      return -1;
    }

    return b.timestart - a.timestart;
  });
};

export const getNotifInfoByType = (notif, coursesMap, lang, langNotif, timezone) => {
  let courseLabel = {
    coursetitle: '',
    coursedate: '',
    coursetime: '',
    expectedCompletion: '',
    numcourses: '',
    numhours: '',
    mandatoryexpirydate: '',
    usergroup: '',
    isOnboarding: false,
    valueBar: 0,
  };

  //mandatoryexpirydate used just for 2 New course assigned
  if (notif.expectedcompletion && notif.expectedcompletion > 0) {
    courseLabel.mandatoryexpirydate = printDateTimezone(notif.expectedcompletion, lang, timezone);
  }

  //usergroup used just for notification 15 PROFILE_UPDATED
  if (notif.usergroup) {
    courseLabel.usergroup = notif.usergroup;
  }

  //expectedCompletion used just for notifications course_expiring and lux 17, 18 COURSE_IS_EXPIRING, LP_IS_EXPIRING
  if (notif.completiondate && notif.completiondate > 0) {
    courseLabel.expectedCompletion = printDateTimezone(notif.completiondate, lang, timezone);
  }

  //numcourses and numhours used just for notification 30 MONTHLY_ACHIEVEMENT
  if (notif.count_courses_completed) {
    courseLabel.numcourses = notif.count_courses_completed;
  }
  if (notif.total_duration) {
    courseLabel.numhours = Math.round(notif.total_duration / 60 / 60 / 1000);
  }

  //get labels for notification title or description
  let course = null;
  const courseId = notif.notificationtype === updateType.TOKEN_ACTIVATED ? notif.object : notif.courseid;
  const isNotifPlan = isIdPlan(courseId);
  if (isNotifPlan) {
    courseLabel.coursetitle = lang?.UNLIMITED_ACCESS_PLAN;
  } else {
    if (coursesMap && courseId) {
      if (coursesMap[courseId]) {
        course = coursesMap[courseId];
      } else {
        const parent = coursesMap[getParentIdByNotif(notif, coursesMap)];

        if (parent?.childCourses?.length > 0) {
          for (const child of parent.childCourses) {
            if (child.childId === courseId) {
              course = child;
              break;
            }
          }
        }
      }

      //if the course was found
      if (course !== null) {
        courseLabel.coursetitle = getCourseFullName(course, lang)?.toUpperCase();

        //check if the course is onboarding
        courseLabel.isOnboarding = isCourseOnBoarding(course);
        try {
          courseLabel.valueBar = getLessonCompletionPercentage(course);
        } catch (error) {
          // errors might not be shown
        }

        if (notif.sessionid && !notif.sessiondate && course.courseSessions && course.courseSessions.length > 0) {
          const sessions = course.courseSessions.filter(a => a.id === notif.sessionid);
          if (sessions && sessions.length > 0) {
            courseLabel.coursedate = getDateCard(sessions[0].sessionDates, lang);
            courseLabel.coursetime = getStartTimeCard(sessions[0].sessionDates);
          }
        }
      }
    }
  }

  //get session date and time
  if (notif.sessiondate && notif.sessiondate > 0) {
    courseLabel.coursedate = printDateTimezone(notif.sessiondate, lang, timezone);
    courseLabel.coursetime = printTimeTimezone(notif.sessiondate, timezone);
  }

  const additionalValues = { valueBar: courseLabel.valueBar };

  switch (notif.notificationtype) {
    case updateType.NEW_COURSE:
      return {
        title: langNotif.NOTIF_NEW_COURSE_TITLE,
        description: (langNotif.NOTIF_NEW_COURSE_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };
      case updateType.NEW_LP:
      return {
        title: langNotif.NEW_COURSE_AVAILABLE,
        description: (langNotif.NOTIF_NEW_COURSE_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.GO_TO_LP,
        ...additionalValues,
      };
    case updateType.NEW_COURSE_ASSIGNED:
      return {
        title: langNotif.NOTIF_NEW_COURSE_ASSIGNED_TITLE,
        description: ((langNotif.NOTIF_NEW_COURSE_ASSIGNED_DESCR || "") + (courseLabel.mandatoryexpirydate ? ' ' + (langNotif.NOTIF_NEW_COURSE_ASSIGNED_DESCR2 || '') : ''))
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{mandatory expiry date}", courseLabel.mandatoryexpirydate),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.NOTIF_ACTION_GO_ASSIGNMENT,
        ...additionalValues,
      };
    case updateType.NEW_LP_ASSIGNED:
      return {
        title: langNotif.NOTIF_NEW_LP_ASSIGNED_TITLE,
        description: ((langNotif.NOTIF_NEW_LP_ASSIGNED_DESCR || "") + (courseLabel.mandatoryexpirydate ? ' ' + (langNotif.NOTIF_NEW_COURSE_ASSIGNED_DESCR2 || '') : ''))
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{mandatory expiry date}", courseLabel.mandatoryexpirydate),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.GO_TO_LP,
        ...additionalValues,
      };
    case updateType.COURSE_OVERDUE:
      return {
        title: langNotif.NOTIF_COURSE_OVERDUE_TITLE,
        description: (langNotif.NOTIF_COURSE_OVERDUE_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifTimesIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };
    case updateType.LP_OVERDUE:
      return {
        title: langNotif.NOTIF_LP_OVERDUE_TITLE,
        description: (langNotif.NOTIF_LP_OVERDUE_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifTimesIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.GO_TO_LP,
        ...additionalValues,
      };
    case updateType.RECORDING_AVAILABLE:
      return {
        title: langNotif.NOTIF_RECORDING_AVAILABLE_TITLE,
        description: (langNotif.NOTIF_RECORDING_AVAILABLE_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.BOOK_COMPLETE:
      return {
        title: langNotif.NOTIF_BOOK_COMPLETE_TITLE,
        description: (langNotif.NOTIF_BOOK_COMPLETE_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.DOWNLOAD_CALENDAR,
        actionText: langNotif.NOTIF_ACTION_DOWNLOAD_CALENDAR,
        ...additionalValues,
      };
    case updateType.SESSION_DATE_MODIFIED:
      return {
        title: langNotif.NOTIF_SESSION_DATE_MODIFIED_TITLE,
        description: (langNotif.NOTIF_SESSION_DATE_MODIFIED_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifAlertIcon />,
        action: null,
        actionText: "",
        ...additionalValues,
      };
    case updateType.NEW_SESSION_AVAILABLE:
    case updateType.NEW_SESSION:
      return {
        title: langNotif.NOTIF_NEW_SESSION_TITLE,
        description: (langNotif.NOTIF_NEW_SESSION_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_VC,
      };
    case updateType.SESSION_CANCEL:
      return {
        title: langNotif.NOTIF_SESSION_CANCEL_TITLE,
        description: (langNotif.NOTIF_SESSION_CANCEL_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifTimesIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.BOOK_CANCEL:
      return {
        title: langNotif.NOTIF_BOOK_CANCEL_TITLE,
        description: (langNotif.NOTIF_BOOK_CANCEL_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText: langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.WAIT_LIST_OPEN:
      return {
        title: langNotif.NOTIF_WAIT_LIST_OPEN_TITLE,
        description: (langNotif.NOTIF_WAIT_LIST_OPEN_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifInfoIcon />,
        action: null,
        actionText: "",
        ...additionalValues,
      };
    case updateType.WAIT_LIST_CLOSE:
      return {
        title: langNotif.NOTIF_WAIT_LIST_CLOSE_TITLE,
        description: (langNotif.NOTIF_WAIT_LIST_CLOSE_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifTimesIcon />,
        action: null,
        actionText: "",
        ...additionalValues,
      };
    case updateType.FROM_WAIT_TO_BOOK:
    case updateType.BOOK_CONFIRM:
      return {
        title: langNotif.NOTIF_BOOK_CONFIRM_TITLE,
        description: (langNotif.NOTIF_BOOK_CONFIRM_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", courseLabel.coursedate)
          .replace("{coursetime}", courseLabel.coursetime),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.DOWNLOAD_CALENDAR,
        actionText: langNotif.NOTIF_ACTION_DOWNLOAD_CALENDAR,
        ...additionalValues,
      };
    case updateType.COURSE_COMPLETED_OLD:
    case updateType.COURSE_COMPLETED:
      return {
        title: langNotif.NOTIF_COURSE_COMPLETE_TITLE,
        description: (!notif.certificate || !isCourseCertificateCtype(course)) ? (langNotif.NOTIF_COURSE_COMPLETE_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle) : (langNotif.NOTIF_COURSE_COMPLETE_CERT_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: notif.certificate ? NOTIFICATION_ACTION.GO_TO_CERTIFICATES : null,
        actionText: notif.certificate ? langNotif.NOTIF_ACTION_GO_COMPLETIONS : "",
        ...additionalValues,
      };
    case updateType.LP_COMPLETED:
      return {
        title: langNotif.NOTIF_LP_COMPLETED_TITLE,
        description: !notif.certificate ? (langNotif.NOTIF_LP_COMPLETED_DESCR || "").replace("{learningpathtitle}", courseLabel.coursetitle) : (langNotif.NOTIF_LP_COMPLETED_CERT_DESCR || "").replace("{learningpathtitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: notif.certificate ? NOTIFICATION_ACTION.GO_TO_CERTIFICATES : null,
        actionText: notif.certificate ? langNotif.NOTIF_ACTION_GO_COMPLETIONS : "",
        ...additionalValues,
      };
    case updateType.PROFILE_UPDATED:
      return {
        title: langNotif.NOTIF_PROFILE_UPDATED_TITLE,
        description: (langNotif.NOTIF_PROFILE_UPDATED_DESCR || "")
          .replace("{usergroup}", courseLabel.usergroup),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_CATALOGUE,
        actionText:
          langNotif.NOTIF_ACTION_GO_CATALOG,
        ...additionalValues,
      };
    case updateType.NEW_COURSE_MATERIAL:
      return {
        title: langNotif.NOTIF_NEW_COURSE_MATERIAL_TITLE,
        description: (langNotif.NOTIF_NEW_COURSE_MATERIAL_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.COURSE_IS_EXPIRING_OLD:
    case updateType.COURSE_IS_EXPIRING:
      return {
        title:
          langNotif.NOTIF_COURSE_EXPIRING_TITLE,
        description: (langNotif.NOTIF_COURSE_EXPIRING_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{date}", courseLabel.expectedCompletion),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };
    case updateType.LP_IS_EXPIRING:
      return {
        title:
          langNotif.NOTIF_LP_EXPIRING_TITLE,
        description: (langNotif.NOTIF_LP_EXPIRING_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{date}", courseLabel.expectedCompletion),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.GO_TO_LP,
        ...additionalValues,
      };
    case updateType.TEAM_EXPIRING_ASSIGNMENTS:
      return {
        title: langNotif.NOTIF_TEAM_EXPIRING_ASSIGNMENTS_TITLE,
        description: (langNotif.NOTIF_TEAM_EXPIRING_ASSIGNMENTS_DESCR || ""),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_MY_TEAM,
        actionText:
          langNotif.NOTIF_ACTION_GO_MY_TEAM,
        ...additionalValues,
      };
    case updateType.TEAM_EXPIRING_ASSIGNMENTS_LP:
      return {
        title: langNotif.NOTIF_TEAM_EXPIRING_ASSIGNMENTS_LP_TITLE,
        description: (langNotif.NOTIF_TEAM_EXPIRING_ASSIGNMENTS_LP_DESCR || ""),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_MY_TEAM,
        actionText:
          langNotif.NOTIF_ACTION_GO_MY_TEAM,
        ...additionalValues,
      };
    case updateType.SESSION_STARTING:
    case updateType.SESSION_START:
      return {
        title: langNotif.NOTIF_SESSION_START_TITLE,
        description: (langNotif.NOTIF_SESSION_START_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.SESSION_MISSED:
      return {
        title: langNotif.NOTIF_SESSION_MISSED_TITLE,
        description: (langNotif.NOTIF_SESSION_MISSED_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifTimesIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_VC,
        ...additionalValues,
      };
    case updateType.CERTIFICATE_AVAILABLE:
      return {
        title: langNotif.NOTIF_CERTIFICATE_AVAILABLE_TITLE,
        description: (langNotif.NOTIF_CERTIFICATE_AVAILABLE_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_CERTIFICATES,
        actionText:
          langNotif.NOTIF_ACTION_GO_COMPLETIONS,
        ...additionalValues,
      };
    case updateType.COURSE_COMPLIANCE_EXPIRED:
      return {
        title: langNotif.NOTIF_COURSE_COMPLIANCE_EXPIRING_TITLE,
        description: (langNotif.NOTIF_COURSE_COMPLIANCE_EXPIRING_DESCR || ""),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };
    case updateType.REPORT_MANAGER_UPDATED_OLD:
    case updateType.REPORT_MANAGER_UPDATED:
      return {
        title: langNotif.NOTIF_REPORT_MANAGER_UPDATED_TITLE,
        description: (langNotif.NOTIF_REPORT_MANAGER_UPDATED_DESCR || ""),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_MY_TEAM,
        actionText:
          langNotif.NOTIF_ACTION_GO_REPORT,
        ...additionalValues,
      };
    case updateType.COURSE_PENDING:
      return {
        title: isLearningPath(course) ? langNotif.NOTIF_COURSE_PENDING_TITLE : langNotif.NOTIF_LESSON_PENDING_TITLE,  //course.ctype.some(item => item.includes("lesson"))
        description: (langNotif.NOTIF_COURSE_PENDING_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };

    case updateType.MONTHLY_ACHIEVEMENT:
      return {
        title: langNotif.NOTIF_MONTHLY_ACHIEVEMENT_TITLE,
        description: (langNotif.NOTIF_MONTHLY_ACHIEVEMENT_DESCR || "")
          .replace("{numcourses}", courseLabel.numcourses)
          .replace("{numhours}", courseLabel.numhours),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COMPLETIONS,
        actionText:
          langNotif.NOTIF_ACTION_GO_COMPLETIONS,
        ...additionalValues,
      };
    case updateType.RATING:
      return {
        title: langNotif.NOTIF_RATING_TITLE,
        description: (langNotif.NOTIF_RATING_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.RATE_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_RATING,
        ...additionalValues,
      };
    case updateType.RATING_LP:
      return {
        title: langNotif.NOTIF_RATING_LP_TITLE,
        description: (langNotif.NOTIF_RATING_LP_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.RATE_COURSE,
        actionText:
          langNotif.RATE_LP,
        ...additionalValues,
      };
    case updateType.WISH_EXPIRING:
      return {
        title: langNotif.NOTIF_WISH_EXPIRING_TITLE,
        description: (langNotif.NOTIF_WISH_EXPIRING_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_COURSE,
        actionText:
          langNotif.NOTIF_ACTION_GO_COURSE,
        ...additionalValues,
      };
    case updateType.WISH_AVAILABILITY:
      return {
        title: langNotif.NOTIF_WISH_AVAILABILITY_TITLE,
        description: (langNotif.NOTIF_WISH_AVAILABILITY_DESCR || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.BOOKING,
        actionText:
          langNotif.NOTIF_ACTION_BOOKING,
        ...additionalValues,
      };
    case updateType.LEADERBOARD_IN:
      return {
        title: langNotif.NOTIF_LEADERBOARD_IN_TITLE,
        description: (langNotif.NOTIF_LEADERBOARD_IN_DESC || ""),
        icon: <NotifLeaderboardIcon />,
        action: NOTIFICATION_ACTION.GO_TO_LEADERBOARD,
        actionText:
          langNotif.NOTIF_ACTION_GO_LEADERBOARD,
        ...additionalValues,
      };
    case updateType.LEADERBOARD_OUT:
      return {
        title: langNotif.NOTIF_LEADERBOARD_OUT_TITLE,
        description: (langNotif.NOTIF_LEADERBOARD_OUT_DESC || ""),
        icon: <NotifLeaderboardIcon />,
        action: NOTIFICATION_ACTION.GO_TO_LEADERBOARD,
        actionText:
          langNotif.NOTIF_ACTION_GO_LEADERBOARD,
        ...additionalValues,
      };
    case updateType.LIVESTREAM_STARTING:
      const livestreamDate = getLivestreamDate(course);
      const timeStart = livestreamDate?.timeStart ? livestreamDate.timeStart.slice(10, 16) : '';
      const timeFinish = livestreamDate?.timeEnd ? livestreamDate.timeEnd.slice(10, 16) : '';

      return {
        title: langNotif.NOTIF_LIVESTREAM_STARTING_TITLE,
        description: (langNotif.NOTIF_LIVESTREAM_STARTING_DESC || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{starttime}", timeStart)
          .replace("{endtime}", timeFinish),
        icon: <NotifLivestreamIcon />,
        action: null,
        actionText: "",
        livestreamStart: livestreamDate?.timeStart,
        ...additionalValues,
      };
    case updateType.LIVESTREAM_JOIN:
      return {
        title: langNotif.NOTIF_LIVESTREAM_JOIN_TITLE,
        titleClassName: "livestream-red",
        description: (langNotif.NOTIF_LIVESTREAM_JOIN_DESC || "").replace("{coursetitle}", courseLabel.coursetitle),
        icon: <NotifLivestreamIcon />,
        iconClassName: "livestream-red",
        action: NOTIFICATION_ACTION.LIVESTREAM_JOIN,
        actionText:
          langNotif.NOTIF_ACTION_LIVESTREAM_JOIN,
        ...additionalValues,
      };
    case updateType.LIVESTREAM_RECORDING:
      const livestreamRecordingDate = getLivestreamDate(course);
      const livestreamDateString = livestreamDate?.timeStart ? getDateCard([livestreamRecordingDate]) : '';

      return {
        title: langNotif.NOTIF_LIVESTREAM_RECORDING_TITLE,
        description: (langNotif.NOTIF_LIVESTREAM_RECORDING_DESC || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{coursedate}", livestreamDateString),
        icon: <NotifLivestreamIcon />,
        action: NOTIFICATION_ACTION.LIVESTREAM_RECORDING_LAUNCH,
        actionText: langNotif.NOTIF_ACTION_RECORDING_LAUNCH,
        ...additionalValues,
      };
    case updateType.NEW_CONTENT_TP:
      return {
        title: langNotif.NOTIF_NEW_CONTENT_TP_TITLE,
        description: (langNotif.NOTIF_NEW_CONTENT_TP_DESC || ""),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.GO_TO_TP_PLP,
        actionText: langNotif.NOTIF_ACTION_NEW_CONTENT_TP,
        ...additionalValues,
      };
    case updateType.TOKEN_ACTIVATED:
      return {
        title: langNotif.NOTIF_TOKEN_ACTIVATED_TITLE,
        description: (langNotif.NOTIF_TOKEN_ACTIVATED_DESCR || "")
          .replace("{coursetitle}", courseLabel.coursetitle)
          .replace("{token}", notif.token),
        icon: <NotifInfoIcon />,
        action: NOTIFICATION_ACTION.ACTIVATE_TOKEN_GO_CONTENT,
        actionText:
          langNotif.NOTIF_TOKEN_ACTIVATED_ACTION,
        ...additionalValues,
      };
    case updateType.LP_OVERDUE_MANAGER:
      return {
        title: courseLabel.isOnboarding
          ? langNotif.ONBOARDING_OVERDUE_MANAGER_TITLE
          : langNotif.COURSE_OVERDUE_MANAGER_TITLE,
        description: courseLabel.isOnboarding
          ? langNotif.ONBOARDING_OVERDUE_MANAGER_DESC
          : langNotif.COURSE_OVERDUE_MANAGER_DESC
            ?.replace('{coursetitle}', courseLabel.coursetitle),
        icon: <NotifAlertIcon />,
        action: NOTIFICATION_ACTION.GO_TO_MY_TEAM,
        actionText:
          langNotif.ONBOARDING_ACTION_OVERDUE_MANAGER,
        ...additionalValues,
      };
    default:
      //notifications to not show
      return {
        title: "",
        description: "",
        icon: null,
        action: null,
        actionText: "",
      };
  }
};

export const getElapsedTime = (notif, lang, timezone) => {
  if (!notif || !notif.timestart) {
    return "";
  }

  //get time elapsed from now to the notification creation
  const notifDate = new Date(notif.timestart);
  const elapsedTime = new Date().getTime() - notifDate.getTime();

  //if elapsed time is less than 1 min --> print "now"
  if (elapsedTime < 1000 * 60) {
    return lang.NOW_LABEL;
  }

  //if elapsed time is less than 1 hour --> print minutes
  if (elapsedTime < 1000 * 60 * 60) {
    return (
      (lang.AGO_LABEL || "").replace("{time}", Math.floor(elapsedTime / 1000 / 60) + " " + lang.DURATION_MIN_LABEL)
    );
  }

  //if elapsed time is less than 1 day --> print hours
  if (elapsedTime < 1000 * 60 * 60 * 24) {
    return (
      (lang.AGO_LABEL || "").replace("{time}", Math.floor(elapsedTime / 1000 / 60 / 60) + " " + lang.DURATION_HOUR_LABEL)
    );
  }

  //if elapsed time is more or equal than 1 day --> print day and month

  return printDateTimezone(notif.timestart, lang, timezone);
};

export const isNotificationToShow = (notif, coursesMap, userProfile) => {
  if (!notif) {
    return false;
  }

  if (notif.timeend < (new Date()).getTime()) {
    return false;
  }

  let isToShow = false;

  //check if the notification is to show and it doesn't need the courseId
  switch (notif.notificationtype) {
    case updateType.PROFILE_UPDATED:
    case updateType.TEAM_EXPIRING_ASSIGNMENTS:
    case updateType.TEAM_EXPIRING_ASSIGNMENTS_LP:
    case updateType.REPORT_MANAGER_UPDATED_OLD:
    case updateType.REPORT_MANAGER_UPDATED:
    case updateType.MONTHLY_ACHIEVEMENT:
    case updateType.LEADERBOARD_IN:
    case updateType.LEADERBOARD_OUT:
    case updateType.NEW_CONTENT_TP:
    case updateType.LP_OVERDUE_MANAGER:
      return true;
    default:
      break;
  }

  //check if the notification is to show
  switch (notif.notificationtype) {
    case updateType.NEW_COURSE:
    case updateType.NEW_LP:
    case updateType.NEW_COURSE_ASSIGNED:
    case updateType.NEW_LP_ASSIGNED:
    case updateType.COURSE_OVERDUE:
    case updateType.LP_OVERDUE:
    case updateType.RECORDING_AVAILABLE:
    case updateType.BOOK_COMPLETE:
    case updateType.SESSION_DATE_MODIFIED:
    case updateType.NEW_SESSION_AVAILABLE:
    case updateType.NEW_SESSION:
    case updateType.SESSION_CANCEL:
    case updateType.BOOK_CANCEL:
    case updateType.WAIT_LIST_OPEN:
    case updateType.WAIT_LIST_CLOSE:
    case updateType.FROM_WAIT_TO_BOOK:
    case updateType.BOOK_CONFIRM:
    case updateType.COURSE_COMPLETED_OLD:
    case updateType.COURSE_COMPLETED:
    case updateType.LP_COMPLETED:
    case updateType.NEW_COURSE_MATERIAL:
    case updateType.COURSE_IS_EXPIRING_OLD:
    case updateType.COURSE_IS_EXPIRING:
    case updateType.LP_IS_EXPIRING:
    case updateType.SESSION_STARTING:
    case updateType.SESSION_START:
    case updateType.SESSION_MISSED:
    case updateType.CERTIFICATE_AVAILABLE:
    case updateType.COURSE_COMPLIANCE_EXPIRED:
    case updateType.COURSE_PENDING:
    case updateType.RATING:
    case updateType.RATING_LP:
    case updateType.WISH_EXPIRING:
    case updateType.WISH_AVAILABILITY:
    case updateType.LIVESTREAM_STARTING:
    case updateType.LIVESTREAM_JOIN:
    case updateType.LIVESTREAM_RECORDING:
    case updateType.TOKEN_ACTIVATED:
      isToShow = true;
      break;
    default:
      break;
  }

  //check if the course associated to the notification is present
  //if not, the title and description would not be correct --> hide
  const courseId = notif.notificationtype === updateType.TOKEN_ACTIVATED ? notif.object : notif.courseid;
  const isNotifPlan = isIdPlan(courseId);

  if (isNotifPlan) {
    return isToShow;
  }

  if (courseId && coursesMap && (coursesMap[courseId] || coursesMap[getParentIdByNotif(notif, coursesMap)])) {
    const course = coursesMap[courseId];
    const parentCourse = coursesMap[getParentIdByNotif(notif, coursesMap)];

    //non mostrare notifiche per le lesson certificate
    if (isCourseCertificateCtype(course)) {
      return false;
    }

    switch (notif.notificationtype) {
      case updateType.WISH_AVAILABILITY:
        return isUpcomingCourse(course) && canSignUp(getSessionByCourse(course, notif.sessionid), course);
      case updateType.RATING:
      case updateType.RATING_LP:
        //mostrare la notifica se l'utente non ha già effettuato il rating
        return isToShow && isCourseToBeRated(parentCourse ? parentCourse : course);
      case updateType.LIVESTREAM_JOIN:
        //mostrare la notifica solo se la live non è ancora terminata
        return isToShow && isLivestreamNotYetFinished(course, userProfile);
      case updateType.LP_COMPLETED:
        if (notif.certificate) {
          //se il corso ha un certificato associato, non mostrare la notifica finché il certificato non è completato
          const certificateLesson = coursesMap[notif.certificate];
          if (!isCompletedCourse(certificateLesson)) {
            return false;
          }
        }
        return isToShow;
      default:
        return isToShow;
    }
  } else {
    return false;
  }
};

export const isPreviewToShow = (notif, includePreviewAfterCourseUpdate = false, justPreviewAfterCourseUpdate = false) => {
  if (!notif) {
    return false;
  }

  switch (notif.notificationtype) {
    //show preview after course download because they're not already in catalogue
    case updateType.NEW_COURSE:
    case updateType.NEW_LP:
    case updateType.NEW_COURSE_ASSIGNED:
    case updateType.NEW_LP_ASSIGNED:
      return includePreviewAfterCourseUpdate || justPreviewAfterCourseUpdate;
    //show at once the preview
    case updateType.BOOK_COMPLETE:
    case updateType.SESSION_DATE_MODIFIED:
    case updateType.SESSION_CANCEL:
    case updateType.FROM_WAIT_TO_BOOK:
    case updateType.BOOK_CONFIRM:
    case updateType.SESSION_STARTING:
    case updateType.SESSION_START:
    case updateType.LIVESTREAM_RECORDING:
    case updateType.NEW_CONTENT_TP:
      return !justPreviewAfterCourseUpdate;
    default:
      return false;
  }
}

export const getParentIdByNotif = (notif, coursesMap) => {
  if (!notif) {
    return '';
  }

  if (notif.parentCourseId) {
    return notif.parentCourseId;
  } else if (coursesMap) {
    const parent = getParentCourse(notif.courseid, coursesMap);
    if (parent) {
      notif.parentCourseId = parent.courseIdMaster;
      return parent.courseId;
    }
  }

  return '';
}

export const getNotificationsToShow = (notifications, coursesMap, userProfile) => {
  if (!notifications || notifications.length < 1) {
    return [];
  }

  return notifications.filter(a => isNotificationToShow(a, coursesMap, userProfile));
};

export const getMyTeamNotifications = (notifications, fromMyTeam = true) => {
  if (!notifications || notifications.length < 1) {
    return [];
  }

  return notifications.filter(a => fromMyTeam ? isNotifMyTeam(a) : !isNotifMyTeam(a));
};

export const isNotifMyTeam = (notif) => {
  if (!notif) {
    return false;
  }

  switch (notif.notificationtype) {
    case updateType.TEAM_EXPIRING_ASSIGNMENTS:
    case updateType.TEAM_EXPIRING_ASSIGNMENTS_LP:
    case updateType.REPORT_MANAGER_UPDATED_OLD:
    case updateType.REPORT_MANAGER_UPDATED:
      return true;
    default:
      return false;
  }
}

export const getDataFromSnapshot = (snapshot) => {
  if (!snapshot) {
    return null;
  }

  let items = [];

  snapshot.forEach(item => {
    items.push(item);
  });

  return items;
}

export const getQueryFirebase = (attribute, whereCondition = null) => {
  let query = null;
  switch (attribute) {
    case NOTIFICATION_ATTRIBUTE.USER:
      query = myFirestore.collection('userNotifications').doc(authFirebase().currentUser.uid).collection('notifications');
      break;
    case NOTIFICATION_ATTRIBUTE.GENERIC:
      query = myFirestore.collection('genericNotifications');
      break;
    default:
      break;
  }

  if (whereCondition && query) {
    query = query.where(whereCondition.fieldPath, whereCondition.opStr, whereCondition.value);
  }

  return query;
}

export const getNotifRetention = (notifId) => {
  if (!notifId) {
    return;
  }

  // if (notifId === updateType.COURSE_IS_EXPIRING_OLD) {
  //   return 1000 * 60 * 60 * 24 * 31; //1 month
  // } else {
  //   return 0;
  // }

  return 1000 * 60 * 60 * 24;
}

export const getNotReadNumber = (notifs, coursesMap, userProfile) => {
  let notReadNum = 0;
  if (notifs && notifs.length > 0) {
    let notifsToShow = getNotificationsToShow(notifs, coursesMap, userProfile);
    notifsToShow = notifsToShow.filter((notif) => !notif.read);

    notReadNum = notifsToShow.length;
  }

  return notReadNum;
};

export const getLivestreamCountdown = (livestreamStart, timezone) => {
  if (!livestreamStart) {
    return;
  }

  let seconds = (getLocalDateFromString(livestreamStart, timezone)?.getTime() - new Date().getTime()) / 1000;
  let minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  if (minutes < 0) {
    minutes = 0;
  }
  if (seconds < 0) {
    seconds = 0;
  }

  return (
    (minutes < 10 ? '0' + minutes : minutes)
    + ':'
    + (seconds < 10 ? '0' + seconds : seconds)
  );
}


