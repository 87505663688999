import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utility/Api";
import { NOTIFICATION_ATTRIBUTE } from "../../utility/const";

export const initialState = {
  isAuthenticating: false,
  isLoggedIn: false,
  error: null,
  notifications: null,
  isNotifReady: false,
  isOnSnapshot: false,
  isGettingNotif: false,
  isWritingDb: false,
  writingDbRequests: 0,
  showNotifAnimation: {},
  genericNotifications: null,
  snapshotSubscribers: [],
  lastLogins: [],
  notifPreviews: [],
  showNotifPanel: false,
  localNotifications: [],
  isLoadingNotifyRecordingLivestream: {},
  isLoadingNotifyLivestreamStarting: {},
};

const loginRequest = (state) => {
  return updateObject(state, {
    isAuthenticating: true,
    isLoggedIn: false,
    error: null,
  });
};

const loginSuccess = (state) => {
  const lastLogins = [...state.lastLogins];
  lastLogins.push(new Date());

  return updateObject(state, {
    isAuthenticating: false,
    isLoggedIn: true,
    error: null,
    lastLogins: lastLogins
  });
};

const loginFailure = (state, action) => {
  return updateObject(state, {
    isAuthenticating: false,
    isLoggedIn: false,
    error: action.error,
  });
};

const logoutRequest = (state) => {
  return updateObject(state, {
    isAuthenticating: true,
  });
};

const logoutSuccess = (state) => {
  return updateObject(state, {
    isAuthenticating: false,
    isLoggedIn: false,
    error: null,
  });
};

const logoutFailure = (state, action) => {
  return updateObject(state, {
    isAuthenticating: false,
    error: action.error,
  });
};

const snapshotRequest = (state) => {
  return updateObject(state, {
    isOnSnapshot: true,
  });
};

const snapshotFailure = (state, action) => {
  return updateObject(state, {
    isOnSnapshot: false,
    error: action.error,
  });
};

const getNotifRequest = (state) => {
  return updateObject(state, {
    isGettingNotif: true,
  });
};

const getNotifSuccess = (state) => {
  return updateObject(state, {
    isGettingNotif: false,
  });
};

const getNotifFailure = (state, action) => {
  return updateObject(state, {
    isGettingNotif: false,
    error: action.error,
  });
};

const saveNotif = (state, action) => {
  let isNotifReady = state.isNotifReady;
  if (action.attribute === NOTIFICATION_ATTRIBUTE.USER) {
    isNotifReady = true;
  }

  return updateObject(state, {
    isNotifReady: isNotifReady,
    [action.attribute]: action.notifications,
    showNotifAnimation: action.showNotifAnimation
  });
};

const writeDbRequest = (state) => {
  return updateObject(state, {
    isWritingDb: true,
    writingDbRequests: state.writingDbRequests + 1,
  });
};

const writeDbSuccess = (state) => {
  return updateObject(state, {
    isWritingDb: state.writingDbRequests > 1,
    writingDbRequests:
      state.writingDbRequests > 0 ? state.writingDbRequests - 1 : 0,
  });
};

const writeDbFailure = (state, action) => {
  return updateObject(state, {
    isWritingDb: state.writingDbRequests > 1,
    writingDbRequests:
      state.writingDbRequests > 0 ? state.writingDbRequests - 1 : 0,
    error: action.error,
  });
};

const setShowNotifAnimation = (state, action) => {
  return updateObject(state, {
    showNotifAnimation: action.showNotifAnimation
  });
};

const setSnapshotSubscribers = (state, action) => {
  return updateObject(state, {
    snapshotSubscribers: action.snapshotSubscribers
  });
};

const pushNotificationPreview = (state, action) => {
  const notifPreviews = [...state.notifPreviews];

  //if the notif is already in the array --> return
  if (notifPreviews.findIndex(a => a.id === action.notif.id) >= 0) {
    return state;
  }

  notifPreviews.push(action.notif);

  return updateObject(state, {
    notifPreviews
  });
};

const popNotificationPreview = (state) => {
  if (state.notifPreviews.length < 1) {
    return state;
  }

  return updateObject(state, {
    notifPreviews: state.notifPreviews.slice(1)
  });
};


const setShowNotifPanel = (state, action) => {
  return updateObject(state, {
    showNotifPanel: action.showNotifPanel
  });
}

const getNotifyRecordingLivestreamRequest = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyRecordingLivestream: { ...state.isLoadingNotifyRecordingLivestream, [action.courseId]: true }
  });
};

const getNotifyRecordingLivestreamSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyRecordingLivestream: { ...state.isLoadingNotifyRecordingLivestream, [action.courseId]: false }
  });
};

const getNotifyRecordingLivestreamFailure = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyRecordingLivestream: { ...state.isLoadingNotifyRecordingLivestream, [action.courseId]: false }
  });
};

const getNotifyLivestreamStartingRequest = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyLivestreamStarting: { ...state.isLoadingNotifyLivestreamStarting, [action.courseId]: true }
  });
};

const getNotifyLivestreamStartingSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyLivestreamStarting: { ...state.isLoadingNotifyLivestreamStarting, [action.courseId]: false }
  });
};

const getNotifyLivestreamStartingFailure = (state, action) => {
  return updateObject(state, {
    isLoadingNotifyLivestreamStarting: { ...state.isLoadingNotifyLivestreamStarting, [action.courseId]: false }
  });
};

const getLocalNotifications = (state, action) => {
  const localNotifications = state.localNotifications;

  // if (action.notif) {
  //   localNotifications.push(action.notif);
  // }

  return updateObject(state, {
    localNotifications: action.localNotifications
  });
}



const logout = () => {
  return initialState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIREBASE_LOGIN_REQUEST:
      return loginRequest(state);
    case actionTypes.FIREBASE_LOGIN_SUCCESS:
      return loginSuccess(state);
    case actionTypes.FIREBASE_LOGIN_FAILURE:
      return loginFailure(state, action);
    case actionTypes.FIREBASE_LOGOUT_REQUEST:
      return logoutRequest(state);
    case actionTypes.FIREBASE_LOGOUT_SUCCESS:
      return logoutSuccess(state);
    case actionTypes.FIREBASE_LOGOUT_FAILURE:
      return logoutFailure(state, action);
    case actionTypes.FIREBASE_SNAPSHOT_REQUEST:
      return snapshotRequest(state);
    case actionTypes.FIREBASE_SNAPSHOT_FAILURE:
      return snapshotFailure(state, action);
    case actionTypes.FIREBASE_GET_NOTIF_REQUEST:
      return getNotifRequest(state);
    case actionTypes.FIREBASE_GET_NOTIF_SUCCESS:
      return getNotifSuccess(state);
    case actionTypes.FIREBASE_GET_NOTIF_FAILURE:
      return getNotifFailure(state, action);
    case actionTypes.FIREBASE_SAVE_NOTIF:
      return saveNotif(state, action);
    case actionTypes.FIREBASE_WRITE_DB_REQUEST:
      return writeDbRequest(state);
    case actionTypes.FIREBASE_WRITE_DB_SUCCESS:
      return writeDbSuccess(state);
    case actionTypes.FIREBASE_WRITE_DB_FAILURE:
      return writeDbFailure(state, action);
    case actionTypes.FIREBASE_SET_SHOW_NOTIF_ANIMATION:
      return setShowNotifAnimation(state, action);
    case actionTypes.FIREBASE_SET_SNAPSHOT_SUBSCRIBERS:
      return setSnapshotSubscribers(state, action);
    case actionTypes.FIREBASE_PUSH_NOTIFICATION_PREVIEW:
      return pushNotificationPreview(state, action);
    case actionTypes.FIREBASE_POP_NOTIFICATION_PREVIEW:
      return popNotificationPreview(state);
    case actionTypes.FIREBASE_SET_SHOW_NOTIF_PANEL:
      return setShowNotifPanel(state, action);
    case actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_REQUEST: return getNotifyRecordingLivestreamRequest(state, action);
    case actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_SUCCESS: return getNotifyRecordingLivestreamSuccess(state, action);
    case actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_FAILURE: return getNotifyRecordingLivestreamFailure(state, action);
    // case actionTypes.GET_NOTIFY_LIVESTREAM_START_REQUEST: return getNotifyLivestreamStartingRequest(state, action);
    // case actionTypes.GET_NOTIFY_LIVESTREAM_START_SUCCESS: return getNotifyLivestreamStartingSuccess(state, action);
    // case actionTypes.GET_NOTIFY_LIVESTREAM_START_FAILURE: return getNotifyLivestreamStartingFailure(state, action);
    case actionTypes.GET_LOCAL_NOTIFICATIONS: return getLocalNotifications(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
