import React from "react";
import HorizontalShadowScrollbar from "@components/UI/HorizontalShadowScrollbar";
import AnimationExpand from './animations/AnimationExpand';
import ShipmentStatsSkeleton from "./skeletons/ShipmentStatsSkeleton";
import { LangMap } from "@model/CoursesClass";

type TPShipmentStatsProps = {
  lang: LangMap;
  shipmentModels: any;
  isLoading: boolean;
}
const TPShipmentStats = ({ lang, shipmentModels, isLoading }: TPShipmentStatsProps) => {
  // console.log(`isLoading`, isLoading)
  return (
    <>
      {!isLoading ? (
        shipmentModels.length > 0 && (
          <div className="shipment-stats flex column max-width-1440">
            {/* <a href="https://my.luxottica.com" target="_blank" className=" shipment-stats__link">{lang.SEE_MORE_ON_LUXOTTICA}</a> */}
            <div className="flex">
              <HorizontalShadowScrollbar useSpaceIphone>
                <>
                  {shipmentModels.map((model) => (
                    <div className="shipment-stats__item-wrapper" key={model.labelKey}>
                      <p className="shipment-stats__item-title">
                        {lang?.[model.labelKey]}
                      </p>
                      <div>
                        <div className="shipment-stats__item">
                          {model.items.length > 0 &&
                            model.items.map((item) => (
                              <div className="shipment-stats__model flex justify-between" key={item.labelKey}>
                                <span className="shipment-stats__model__label">
                                  {lang[item.labelKey]}
                                </span>
                                <span
                                  className="shipment-stats__model__value"
                                  style={{ color: item.color }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            ))}

                          <div className="shipment-stats__model flex justify-between">
                            <span className="shipment-stats__model__label">
                              {lang.TP_TOTAL_MODELS}
                            </span>
                            <span
                              className="shipment-stats__model__value"
                              style={{ color: '#009bff' }}
                            >
                              {model.total}
                            </span>
                          </div>
                        </div>

                        <div className="shipment-stats__graph">
                          {model.items.length > 0 &&
                            model.items.map((item) => (
                              <AnimationExpand
                                backgroundColor="rgba(0,0,0,0)"
                                initialWidth={"3px"}
                                finalWidth="100%"
                                animationEase="easeOut"
                                key={item.labelKey}
                              >
                                <div
                                  style={{
                                    background: item.color,
                                    width: model.total ? item.width + "%" : "1%",
                                  }}
                                  className="shipment-stats__graph-item"
                                />
                              </AnimationExpand>
                            ))}
                          <AnimationExpand
                            backgroundColor="rgba(0,0,0,0)"
                            initialWidth={"3px"}
                            finalWidth={"100%"}
                            animationEase="easeOut"
                          >
                            <div
                              style={{ background: '#009bff', width: model.total ? "100%" : "1%" }}
                              className="shipment-stats__graph-item"
                            />
                          </AnimationExpand>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </HorizontalShadowScrollbar>
            </div>
          </div>
        )
      ) : (
        <div className="shipment-stats skeleton flex column max-width-1440">
          {/* <TextSkeleton width={159} height={25} /> */}
          <div className="flex">
            <HorizontalShadowScrollbar useSpaceIphone>
              {[1, 2].map((_, index) => <ShipmentStatsSkeleton key={index} />)}
            </HorizontalShadowScrollbar>
          </div>
        </div>
      )}
    </>
  );
};

export default TPShipmentStats;
