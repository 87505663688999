import React, { useEffect, useState } from "react";
import ModalEvolution from "./ModalEvolution";
import { useSelector } from "@hooks/redux";
import { useDispatch } from "react-redux";
import * as actions from '@redux-actions/';
import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { OrderToken } from "@model/EcommerceClass";

type ModalActivatePlanConfirmationProps = {
    show: boolean;
    availableLicenses: OrderToken[];
    close: () => void;
};

const ModalActivatePlanConfirmation = ({ show, close, availableLicenses }: ModalActivatePlanConfirmationProps) => {
    const breakpoint = useBreakpointBoolean();
    const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    
    const confirm = ()  => {
        setIsLoading(true);
        dispatch(actions.activateLicense(availableLicenses[0].token));
        // dispatch(actions.getSubscriptionList());
        setIsLoading(false);
        close();
    }

    let descriptionLabel = lang.MODAL_ACTIVATE_PLAN_CONFIRMATION_DESCR.replace("{QUANTITY}", (availableLicenses?.length || 1).toString());

    return (
        <ModalEvolution
            close={close}
            show={show}
            hideX={breakpoint.isDesktopOrTabletLandscape}
            modalClassName="activate-plan-confirmation"
            title={lang?.MODAL_ACTIVATE_PLAN_CONFIRMATION_TITLE}
            description={
                breakpoint.isDesktopOrTabletLandscape ?
                descriptionLabel.replace("? ", "?\n") :
                descriptionLabel
            }
            mainButtonLabel={lang?.MODAL_ACTIVATE_PLAN_CONFIRM}
            mainButtonAction={confirm}
            mainButtonIsLoading={isLoading}
            secondaryButtonLabel={lang?.MODAL_ACTIVATE_PLAN_CANCEL}
            secondaryButtonAction={close}
        />
    );
}

export default ModalActivatePlanConfirmation;