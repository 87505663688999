import { useEffect, useLayoutEffect, useState } from 'react';

const useBlockScroll = (block, ignoreBlock = false) => {
  const [localBlock, setLocalBlock] = useState(undefined);

  useEffect(() => {
    if (!ignoreBlock) {
      if (!!block !== !!localBlock) {
        // console.log('localBlock', { block, localBlock });
        setLocalBlock(!!block);
      }
    }
  }, [block]);

  useLayoutEffect(() => {
    if (localBlock === undefined) {
      //if block always remains false --> do nothing
      return;
    }

    //avoid scroll if block=true
    if (localBlock) {
      document.querySelector('html').classList.add('block-scroll');
      // console.trace("ADD block-scroll")
    } else {
      document.querySelector('html').classList.remove('block-scroll');
      // console.trace("REMOVE block-scroll")
    }
  }, [localBlock]);

  useEffect(() => {
    return () => {
      //if block always remains false --> do nothing
      if (!ignoreBlock) {
        document.querySelector('html').classList.remove('block-scroll');
        // console.trace("REMOVE 2 block-scroll")
      }
    };
  }, []);
};

export default useBlockScroll;
