export const setCookie = (cname, cvalue, exdays, domain = "", secure = false, sameSite="") => {
  var d = new Date(exdays);
  var expires = "expires=" + d.toUTCString();
  let cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  let newCookie = cookie;

  if (domain !== "") newCookie = cookie + ";domain=" + domain;

  if (secure) {
    newCookie += ";Secure";
    //console.log(newCookie)
  }

  if(sameSite !== "") {
    newCookie += ";SameSite=" + sameSite
  }

  //console.log('newCookie', newCookie)
  document.cookie = newCookie;
}

export const getCookie = (cname, asBoolean) => {
  var name = cname + "=";
  try {
    var decodedCookie = decodeURIComponent(document.cookie);

    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        let value =  c.substring(name.length, c.length);

        if(asBoolean){
          if(value === "true") return true
          if(value === "false") return false
        }

        return value
      }
    }
  } catch (e) {
    console.error('getCookie ', e);
  }

  if(asBoolean) return false
  return "";
}

export const removeCookie = (cname, domain) => {
  let cookieString = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
  if (domain) {
    cookieString += ';domain=' + domain;
  }
  document.cookie = cookieString;
}