import { defaultBreakpoints, useBreakpoint, useBreakpointBoolean } from '@hooks/createBreakpoint';
import { SKELETON_COLORS } from '@utility/const';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';

// const widthMobile = 295;
// const heightMobile = 114;
// const widthDesktop = 295;
// const heightDesktop = 114;

const dimensions = {
  general: {
    mobile: {
      width: 295,
      height: 114
    },
    desktop: {
      width: 295,
      height: 114
    }
  },
  hp: {
    phoneAndTabletPortrait: {
      width: 152,
      height: 196
    },
    desktopAndTabletLandscape: {
      width: 255,
      height: 278
    }
  }
}

const CardBrowseByTopicSkeleton = props => {
  const breakpoint = useBreakpointBoolean();

  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if(props?.isInHP) {
      if(breakpoint.isDesktopOrTabletLandscape) {
        setWidth(dimensions.hp.desktopAndTabletLandscape.width)
        setHeight(dimensions.hp.desktopAndTabletLandscape.height)
      } else {
        setWidth(dimensions.hp.phoneAndTabletPortrait.width)
        setHeight(dimensions.hp.phoneAndTabletPortrait.height)
      }
    } else {
      if (breakpoint.isDesktop) {
        setWidth(dimensions.general.desktop.width);
        setHeight(dimensions.general.desktop.height);
      } else {
        setWidth(dimensions.general.mobile.width);
        setHeight(dimensions.general.mobile.height);
      }
    }
    
  }, [breakpoint]);

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        overflow: 'hidden',
        margin: '0',
        maxWidth: '100%',
        flexShrink: 0,
        paddingRight: props?.isInHP ? "16px" : "0px"
      }}
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        //{...props}
      >

        {props?.isInHP && 
          <>
            <rect className="phone-and-tablet-portrait" x="0" y="0" rx="6" ry="6" width="152" height="196" />
            <rect className="desktop-and-tablet-landscape" x="0" y="0" rx="6" ry="6" width="255" height="278" />
          </>
        }

        {!props?.isInHP &&
          <>
            <rect className="mobile" x="0" y="0" rx="6" ry="6" width="128" height="114" />
            <rect className="mobile" x="135" y="4" rx="6" ry="6" width="100" height="12" />
            <rect className="mobile" x="135" y="25" rx="6" ry="6" width="120" height="14" />
            <rect className="mobile" x="135" y="46" rx="6" ry="6" width="100" height="10" />
            <rect className="mobile" x="135" y="60" rx="6" ry="6" width="100" height="10" />
            <rect className="mobile" x="135" y="74" rx="6" ry="6" width="100" height="10" />
  
            <rect className="desktop" x="0" y="0" rx="6" ry="6" width="128" height="114" />
            <rect className="desktop" x="135" y="4" rx="6" ry="6" width="100" height="12" />
            <rect className="desktop" x="135" y="25" rx="6" ry="6" width="120" height="14" />
            <rect className="desktop" x="135" y="46" rx="6" ry="6" width="100" height="10" />
            <rect className="desktop" x="135" y="60" rx="6" ry="6" width="100" height="10" />
            <rect className="desktop" x="135" y="74" rx="6" ry="6" width="100" height="10" />
          </>
        }
        
      </ContentLoader>
    </div>
  );
};

export default CardBrowseByTopicSkeleton;
