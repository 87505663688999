import React from 'react';
import { useSelector } from '@hooks/redux';
import ButtonV2 from '@components/UI/ButtonV2';
import FavEmptyIcon from '@svg-icons/heart-outline.svg';
import FavFilledIcon from '@svg-icons/heart-fill.svg';
import NotYetAvailableIcon from '@svg-icons/not-yet-available.svg';
import clsx from '@utility/clsx';

type WishlistIconProps = {
  className?: string;
  small?: boolean;
  isWishlist: boolean;
  isLoadingWishlistLocal?: boolean;
  isNotYetAvailable?: boolean;
  enableWishlist: boolean;
  toggleWishlist: (e?) => void;
  handleClickCondition?: boolean;
  isStatic?: boolean;
  tabIndex?: number;
};

export const WishlistIcon = ({
  className,
  small = true,
  isWishlist,
  isLoadingWishlistLocal = false,
  isNotYetAvailable = false,
  enableWishlist,
  toggleWishlist,
  handleClickCondition = true,
  isStatic = false,
  tabIndex = 0,
}: WishlistIconProps): React.ReactElement => {
  const lang = useSelector(state => state.utils.lang);

  const handleClick = e => {
    if (!handleClickCondition) {
      toggleWishlist(e);
    } else {
      if (!isLoadingWishlistLocal && enableWishlist && !isNotYetAvailable) {
        toggleWishlist(e);
      } else {
        e?.stopPropagation();
      }
    }
  };

  if (isStatic) {
    return null;
  }

  return (
    <ButtonV2
      variant="icon-btn"
      small={small}
      className={clsx('card-v3__wishlist', {
        [className]: className,
        'card-v3__wishlist--active':
          ((isLoadingWishlistLocal && !isWishlist) || (!isLoadingWishlistLocal && isWishlist)) &&
          enableWishlist,
        'card-v3__wishlist--not-available': isNotYetAvailable,
      })}
      tabIndex={!isNotYetAvailable ? tabIndex : undefined}
      ariaLabel={
        !isLoadingWishlistLocal && enableWishlist && !isNotYetAvailable
          ? isWishlist
            ? lang.REMOVE_TO_FAVOURITES
            : lang.ADD_TO_FAVOURITES
          : null
      }
      handleClick={handleClick}
    >
      {isNotYetAvailable && <NotYetAvailableIcon />}
      {enableWishlist &&
        ((isLoadingWishlistLocal && !isWishlist) || (!isLoadingWishlistLocal && isWishlist) ? (
          <FavFilledIcon className="theme-color-text" />
        ) : (
          <FavEmptyIcon />
        ))}
    </ButtonV2>
  );
};

export default WishlistIcon;
