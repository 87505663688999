import { BASE_ADMIN_URL, BASE_NOTIF_URL, BASE_URL } from './config_local';

export const OAUTH_AUTHENTICATION = BASE_URL + '/auth/login';
export const OAUTH_AUTHENTICATION_REFRESH = BASE_URL + '/auth/login/refresh';
export const CIAM_AUTHENTICATION = BASE_URL + '/auth/ciam';
export const LOGOUT_URL = BASE_URL + '/auth/logout';
export const GET_FIREBASE_TOKEN = BASE_URL + '/auth/refreshFirebaseToken'; //refresh firebase token

export const REGISTER_USER = BASE_ADMIN_URL + '/public/user/create';
export const ACTIVATE_USER_URL = BASE_ADMIN_URL + '/public/user/activate';
//export const SEND_CHANGE_PSW_EMAIL_URL = BASE_ADMIN_URL + "/public/user/resetPassword";
export const SEND_CHANGE_PSW_EMAIL_URL = BASE_ADMIN_URL + '/public/user/resetPassword/unified';
export const RESET_PSW_PUBLIC_URL = BASE_ADMIN_URL + '/public/user/changePassword';
export const RESET_PSW_LOGGED_URL = BASE_ADMIN_URL + '/user/changePassword';
export const GET_PASSWORD_RESET_AZURE_URL = BASE_URL + '/user/getTokenMyel';
export const DELETE_USER_URL = BASE_ADMIN_URL + '/user/delete';
export const GET_COUNTRY_URL = BASE_URL + '/public/country';
export const GET_STATE_OF_RESIDENCE_URL = BASE_URL + '/public/country/residence';

export const GET_COURSES_URL = BASE_URL + '/course/catalog';
export const GET_COURSES_FAST_URL = BASE_URL + '/course/catalogFast';
export const GET_COURSES_V2_URL = BASE_URL + '/course/v2/catalog';

//HP OPTIMIZATION W1
export const GET_COURSE_SUMMARY_URL = BASE_URL + '/course/v3/details'; //list of user courses details -> atm used for summary only
export const GET_SINGLE_COURSE_V3 = BASE_URL + '/course/v3/catalog'; //punctual catalog
export const GET_COURSES_V3_URL = BASE_URL + '/course/homepage/catalog'; //list of courses


export const GET_COURSES_EDUCATIONAL_PATHS =
  BASE_URL + '/course/educationalPathOverview{courseIds}'; //?courseId=
export const GET_COURSES_CAREER_PROGRAM = BASE_URL + '/course/educationalPathOverviewByc{courseIds}';
// export const GET_SINGLE_COURSE_V2_URL = BASE_URL + "/course/v2/catalog/{courseId}";
// export const GET_COURSE_DETAIL_V2_URL = BASE_URL + "/course/v2/details/{courseId}";
export const GET_COURSES_COMPLETION_V2_URL = BASE_URL + '/course/v2/completions';
export const GET_COURSES_COMPLETION_V3_URL = BASE_URL + '/course/v3/completions';
export const UPDATE_WISHLIST_URL = BASE_URL + '/course/courseWl';
export const UPDATE_WISHLIST_V2_URL = BASE_URL + '/course/courseWishlist';
export const GET_WISHLIST_COURSES_URL = BASE_URL + '/user/wishlist';
export const UPDATE_LIKE_URL = BASE_URL + '/course/courseLike';
export const BOOK_SESSION_URL = BASE_URL + '/course/sessionSubscribe';
export const COURSE_OVERVIEW_URL = BASE_URL + '/course/v2/overview';
export const LP_ENROLL_URL = BASE_URL + '/course/lpSubscribe';
export const COURSE_STARTED_URL = BASE_URL + '/course/start';
export const GET_YMAL_URL = BASE_URL + '/sr/mlt';
export const COMPLETE_CERTIFICATE_URL = BASE_URL + '/course/completeCertificate';

export const GET_USER_UPDATES = BASE_URL + '/notify/getCourseUpdates';
export const GET_USER_UPDATES_PROVA = BASE_URL + '/notify/receiveCourseUpdates';
export const GET_NOTICE_RECEIVED = BASE_URL + '/notify/received';

export const GET_USER_PROFILE_URL = BASE_URL + '/user/profile';
export const GET_USER_PROFILE_FAST_URL = BASE_URL + '/user/profileFast';
export const UPDATE_USER_URL = BASE_URL + '/user/update';
export const UPDATE_USER_TIMEZONE_URL = BASE_URL + '/user/update/timezone';
export const UPDATE_USER_LANGUAGE_URL = BASE_URL + '/user/update/language';
export const ACCEPT_DISCLAIMER = BASE_URL + '/user/disclaimerAccept';

export const FOLLOW_BRAND_URL = BASE_URL + '/user/followBrand';
export const FOLLOW_CHANNEL_URL = BASE_URL + '/user/followChannel';
export const FOLLOW_L1_LIST_URL = BASE_URL + '/user/followUnfollowBrandAndChannels';

export const GET_LEADERBOARD_URL = BASE_URL + '/user/leaderboard';
export const GET_LEADERBOARD_CY_URL = BASE_URL + '/user/leaderboard/currentYear';

export const GET_LABEL_URL = BASE_URL + '/label';
export const GET_TIMEZONE_URL = BASE_URL + '/label/timezone';
export const GET_ALL_LANGUAGES = BASE_URL + '/label/allLanguages';
export const GET_LABEL_NOTIF_URL = BASE_URL + '/label/notification';
export const GET_LABEL_TP_URL = BASE_URL + '/label/trainingPills';
export const GET_LABEL_ECOMMERCE_URL = BASE_URL + '/label/ecommerce/labels';
export const GET_LABEL_L0_URL = BASE_URL + '/label/levels/labels/{language}';
export const GET_LABEL_L1_URL = BASE_URL + '/label/channels/labels/{language}';
export const GET_LABEL_LEVEL_URL = BASE_URL + '/label/getAllLevels/{language}';
export const GET_LABEL_ONBOARDING_TOPICS_URL = BASE_URL + '/label/topic/labels/{language}';

export const GET_BRANDS_URL = BASE_URL + '/brand/getAll';
export const GET_REPORT_MANAGER = BASE_URL + '/report/manager';
export const GET_REPORT_REFRESH = BASE_URL + '/report/refresh';
export const GET_REPORT_EXCEL = BASE_URL + '/report/exportXlsx';
export const GET_REPORT_ONBOARDING_EXCEL = BASE_URL + '/report/onBoarding/exportXlsx';
export const GET_REPORT_MANAGER_DETAIL = BASE_URL + '/report/detail';
export const GET_HOT_CHANNELS = BASE_URL + '/report/usersFollow';

/** CMS **/
export const GET_FAQ_URL = BASE_URL + '/cms/faq';
export const GET_FAQ_ECOMMERCE_URL = BASE_URL + '/cms/faq/ecommerce';
export const GET_FAQ_PLAN_URL = BASE_URL + '/cms/faq/subscription';
export const GET_FAQ_CHECKOUT_URL = BASE_URL + '/cms/faq/checkout';
export const GET_FAQ_EDUCATIONAL_PATHS_URL = BASE_URL + '/cms/faq/educationalPath';

export const GET_PUBLIC_FAQ_URL = BASE_URL + '/public/cms/faq';

export const GET_CONTACT_US_URL = BASE_URL + '/cms/contactUs';
export const GET_CONTACT_US_CHECKOUT_URL = BASE_URL + '/cms/contactUs/checkout';

export const GET_PUBLIC_CONTACT_US_URL = BASE_URL + '/public/cms/contactUs';
export const GET_BRAND_PAGE_URL = BASE_URL + '/cms/brandPage';
export const GET_CHANNEL_PAGE_URL = BASE_URL + '/cms/channelPage';
export const GET_L1_IMAGE_URL = BASE_URL + '/cms/carousel';
export const HOWTO_PAGE_URL = BASE_URL + '/cms/help';
export const GET_BEANS_URL = BASE_URL + '/cms/beans';

// TRAINING PILLS
export const GET_TRAINING_PILL_DETAIL = BASE_URL + '/pills/getTrainingPills?model={modelId}';
//novelty request replaces the training pill request. Also training pills will run the novelty request
export const GET_NOVELTY_DETAIL = BASE_URL + '/ntys/v2/getNtysPillsInfo?model={modelId}';
export const GET_FILTERS_TRAINING_PILLS_URL = BASE_URL + '/pills/getFilters';
export const GET_TRAINING_PILLS_SEARCH = BASE_URL + '/pills/getSearchProductPills';
export const GET_TRAINING_PILLS_SEARCH_SPELL = BASE_URL + '/pills/getSpellSearchProductPills';
export const SAVE_WISHLIST_TP_URL = BASE_URL + '/pills/saveWishList';
export const DELETE_WISHLIST_TP_URL = BASE_URL + '/pills/deleteWishList';
export const GET_TRAINING_PILLS_WEEK_SUMMARY_URL = BASE_URL + '/pills/getWeekSummary';
// NOVELTIES
export const GET_LATEST_COLLECTIONS_URL = BASE_URL + '/ntys/getNovelties';
export const GET_LATEST_COLLECTIONS_DISTINCT_BY_BRAND_URL =
  BASE_URL + '/ntys/getNoveltiesDistinctByBrand';
export const GET_FILTERS_NOVELTIES_URL = BASE_URL + '/ntys/getFilters';
export const GET_NOVELTIES_SEARCH = BASE_URL + '/ntys/search';
export const GET_COLLECTION_INFO = BASE_URL + '/ntys/getBrandInfo';
export const GET_COLLECTION_STORIES = BASE_URL + '/ntys/v2/getStories';
//LATEST COLLECTIONS FILTERS
export const GET_LATEST_COLLECTIONS_FILTERS_AND_CONTENT_URL = BASE_URL + '/ntys/getCollection';
export const GET_LATEST_COLLECTIONS_FILTERS_URL = BASE_URL + '/ntys/getFilterCollections';
//BRAND COLLECTION FILTERS
export const GET_BRAND_COLLECTION_FILTERS_AND_CONTENT_URL = BASE_URL + '/ntys/getStoriesModel';
export const GET_BRAND_COLLECTION_FILTERS_URL = BASE_URL + '/ntys/getStoriesFilters';

//useless
export const GET_TRAINING_PILL_BASE_URL =
  'https://uat-redcarpet-cms.luxottica.com/servlet/rest/v1/6/contents/redcarpet-en';

//ORDER BY RELEVANCE
export const GET_ORDER_BY_RELEVANCE_URL = BASE_URL + '/operator/all';

//NOTIFICATION CENTER
export const READ_NOTIFICATIONS = BASE_NOTIF_URL + '/notify/read';
export const CREATE_NOTIFICATION = BASE_NOTIF_URL + '/update/courses';

//PERSONALIZATION TEST
export const GET_SURVEY_BY_TYPE = BASE_ADMIN_URL + '/survey/read-by-type/{surveyType}';
export const SEND_SURVEY_RESPONSE = BASE_ADMIN_URL + '/survey/survey-response/save';
export const GET_SURVEY_RESPONSES = BASE_ADMIN_URL + '/survey/survey-response/readLast';

//SEARCH
export const GET_SUGGESTED_TERMS_URL = BASE_URL + '/sr/suggestedTerms';
export const GET_SEARCH_COURSES_URL = BASE_URL + '/sr/search';
export const GET_SEARCH_COURSES_SPELL_URL = BASE_URL + '/sr/search/spell';
export const GET_SEARCH_COURSES_TP_URL = BASE_URL + '/sr/searchWithTp';
// export const GET_SEARCH_COURSES_TP_SPELL_URL = BASE_URL + "/sr/searchSpellWithTp"; OLD REQUEST
export const GET_SEARCH_COURSES_TP_SPELL_URL = BASE_URL + '/sr/searchSpellWithTpWithNtys';
export const GET_BROWSE_BY_TOPIC_INFO_URL = BASE_URL + '/sr/searchChannels';

//LIVESTREAM APIS
export const LIVESTREAM_COMPLETION = BASE_URL + '/course/liveStreamCompleted';
export const LIVESTREAM_NOTIFY_ME_RECORDING = BASE_URL + '/course/liveInfoSubscribe';

//LIVESTREAM DEFAULT CHINA IFRAME URL
export const LIVESTREAM_DEFAULT_IFRAME_CHINA =
  'https://leonardo.ccsupport.cn/live/{eventId}_720p/index.m3u8';

//LIVESTREAM DEFAULT IFRAME URL
export const LIVESTREAM_DEFAULT_IFRAME = 'https://vimeo.com/event/{eventId}/embed/';

//LIVESTREAM DEFAULT IFRAME CHAT URL
export const LIVESTREAM_DEFAULT_CHAT_IFRAME = 'https://vimeo.com/event/{eventId}/chat/';

// ECOMMERCE URL
export const GET_ORDER_HISTORY = BASE_URL + '/wcs/getOrderHistory';
export const ACTIVATE_LICENSE = BASE_URL + '/wcs/activateToken';
export const ACTIVATE_LICENSE_MAIL = BASE_URL + '/wcs/activateTokenMail';
export const GET_TOKENS_BY_ORDER_ID = BASE_URL + '/wcs/v2/getMoodleTokens';
export const SEND_TOKEN_FRIEND = BASE_URL + '/wcs/v2/activateTokenMail';
export const RESET_SENDED_TOKENS = BASE_URL + '/wcs/resetSendedTokens';
//promo
// export const APPLY_PROMOCODE = BASE_URL + "/wcs/assignCoupon/{couponId}";
// export const REMOVE_PROMOCODE = BASE_URL + "/wcs/deleteCoupon/{couponId}";
export const GET_PROMOCODE = BASE_URL + '/wcs/getPromoCodeList';
export const APPLY_PROMOCODE = BASE_URL + '/wcs/assignPromoCode/{promoCode}';
export const REMOVE_PROMOCODE = BASE_URL + '/wcs/deletePromoCode/{promoCode}';
//cart
export const GET_CART = BASE_URL + '/wcs/getCart';
export const ADD_ITEM_CART = BASE_URL + '/wcs/addToCart';
export const REMOVE_CART = BASE_URL + '/wcs/deleteCart';
export const UPDATE_ITEM_CART = BASE_URL + '/wcs/updateOrderItem';
export const REMOVE_ITEM_CART = BASE_URL + '/wcs/deleteOrderItem';
//plan
export const GET_PLAN = BASE_URL + '/user/subscription/list';
//shipping info
export const GET_CONTACT = BASE_URL + '/wcs/getContact';
export const ADD_CONTACT = BASE_URL + '/wcs/addContact';
export const ADD_CONTACT_CHECKOUT = BASE_URL + '/wcs/addContactCheckout';
export const SET_SHIPPING_INFO = BASE_URL + '/wcs/setShippingInfo';

export const SET_PAYMENT_AND_CONFIRM = BASE_URL + '/wcs/setPaymentAndConfirm';
export const SET_PAYMENT_AND_CONFIRM2 = BASE_URL + '/wcs/setPayment/v2';
export const GET_USABLE_PAYMENT_INFO_URL = BASE_URL + '/wcs/getUsablePaymentInfo';
export const GET_PAYMENT_INFO_LIST_URL = BASE_URL + '/wcs/wallet';

export const ADD_PAYMENT_INFO = BASE_URL + '/wcs/wallet/create';
export const ADD_PAYMENT_INFO_DEFAULT = BASE_URL + '/wcs/wallet/create/default';
export const UPDATE_PAYMENT_INFO = BASE_URL + '/wcs/wallet/update/{walletId}';
export const UPDATE_PAYMENT_INFO_DEFAULT = BASE_URL + '/wcs/wallet/update/default/{walletId}';
export const REMOVE_PAYMENT_INFO = BASE_URL + '/wcs/wallet/delete/{walletId}';

export const GET_ORDER_BY_ID = BASE_URL + '/wcs/getOrder/{orderId}';
export const GET_ORDER_ENRICHMENT_BY_ID = BASE_URL + '/wcs/getOrderEnrichment/{orderId}';
//below for thank you page only
export const GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID = BASE_URL + '/wcs/getOrderEnrichmentConfirmation/{orderId}'
export const GET_ORDER_WITH_TOKEN = BASE_URL + '/wcs/getOrderWithToken/{orderId}';

export const ENABLE_PLAN_RENEWAL = BASE_URL + '/wcs/subscription/activate';
export const DISABLE_PLAN_RENEWAL = BASE_URL + '/wcs/subscription/delete';
export const GET_SUBSCRIPTION_LIST = BASE_URL + '/wcs/subscription';
export const GET_SUBSCRIPTION_LIST_V2 = BASE_URL + '/wcs/subscriptionV2';

//plan carousel CMS
export const GET_PLAN_CAROUSEL_INFO_URL = BASE_URL + '/cms/subscriptionCarousel';
export const GET_PLAN_PAGE_INFO_URL = BASE_URL + '/cms/planPage';

//build your career CMS
export const GET_BUILD_YOUR_CAREER_URL = BASE_URL + '/cms/getPathDetail';

//hero banner carousel CMS
export const GET_HERO_BANNER_INFO_URL = BASE_URL + '/cms/homepage';

//tortona
export const GET_TORTONA_INFO_URL = BASE_URL + '/cms/getCollectionBanner';

//onboarding CMS
export const GET_ONBOARDING_OVERVIEW_URL =
  BASE_URL + '/Onboarding/getOnboardingDetails/{language}/{pointer}';
export const GET_ONBOARDING_WHS_OVERVIEW_URL =
  BASE_URL + '/Onboarding/getOnboardingDetailsWHS/{language}/{pointer}';
export const GET_ONBOARDING_TEACHERS_URL = BASE_URL + '/master/getTeachers';

//onboarding CMS master
export const GET_ONBOARDING_OVERVIEW_MASTER_URL =
  BASE_URL + '/Onboarding/getMasterDetails/{language}/{pointer}';

//onboarding celebration
export const DOWNLOAD_ONBOARDING_CERTIFICATE = BASE_URL + '/Onboarding/downloadPdf/{courseId}';

//onboarding form
export const SEND_ONBOARDING_CONTACT_CUSTOMER_SERVICE = BASE_URL + '/master/customerService';
export const SEND_ONBOARDING_REQUEST_JOIN = BASE_URL + '/master/waitingListRequest';

//get collections
export const GET_COLLECTIONS = BASE_URL + '/pills/collections';

export const PERSONIFICATION_URLS = {
  URL_GET_LIST_USERS: BASE_URL + "/simulation/getUsersForSimulation",
  URL_GET_LIST_USERS_W2: BASE_URL + "/simulation/v2/getUsersForSimulation",
  URL_GET_USERS_SIMULATION_FILTERS: BASE_URL + "/simulation/getSimulationFilters",
    URL_SET_FAVORITE_USER :  BASE_URL +  "/simulation/addFavorite",
    URL_SET_UNFAVORITE_USER : BASE_URL +   "/simulation/removeFavorite",
    URL_SET_PERSONIFICATE_USER_ON :  BASE_URL +  '/simulation/loginSimulation',
    URL_SET_PERSONIFICATE_USER_OFF :  BASE_URL +  '/simulation/logoutSimulation',
}

//catalog light: get UserRelevantSessions
export const GET_USER_RELEVANT_SESSIONS_URL = BASE_URL + '/course/catalog/userSessions';
//Certificate Improvements
export const UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT = BASE_URL + '/course/certificate/download';

//Collection v3

export const GET_BRAND_CAMPAIGN_BY_MOCO = BASE_URL + '/ntys/getBrandCampaignByMoco?moco={moco}'

// CMS new pages
export const CMS_NEW_PAGES_URLS =  { // TODO sostuire /api con ${BASE_URL}
    GET_STRUCTURE_PRIVATE   : `${BASE_URL}/cms/cmsNewPage/page`,         // slug FE == segment CMS
    GET_STRUCTURE_PUBLIC    : `${BASE_URL}/public/cms/cmsNewPage/page`,  // slug FE == segment CMS
    GET_CONTENT             : `${BASE_URL}/public/cms/cmsNewPage/content/{contentId}`,
    GET_CONTENTS            : `${BASE_URL}/public/cms/cmsNewPage/contentList`,

    POST_CONTENTS_PUBLIC    : `${BASE_URL}/public/cms/cmsNewPage/contentList`,
    POST_CONTENTS           : `${BASE_URL}/cms/cmsNewPage/contentList`,
}

/*
export const GET_STRUCTURE_CMS_NEW_PAGES_PRIVATE= `/api/cms/cmsNewPage/page/`   // TO FIX api = BASE_URL
export const GET_STRUCTURE_CMS_NEW_PAGES_PUBLIC = `/api/public/cms/cmsNewPage/page/`    // TO FIX api = BASE_URL
export const GET_CONTENT_CMS_NEW_PAGES          = `/api/public/cms/cmsNewPage/content/{contentId}`  // TO FIX api = BASE_URL
export const GET_CONTENTS_CMS_NEW_PAGES         = `/api/public/cms/cmsNewPage/contentList`  // TO FIX api = BASE_URL
*/
//COLLECTION WAVE 3
export const GET_BRAND_CAMPAIGNS = BASE_URL + '/ntys/getBrandCampaignInfo';
