import { useSelector } from "@hooks/redux";
import { Course, LevelEnrichedFE, LevelEnrichedMap } from "@model/CoursesClass";
import { isCourseVisible } from "@utility/Api";
import { LEVELS_AREA, ctype } from "@utility/const";
import { useEffect, useState } from "react";
import cloneDeep from 'lodash/cloneDeep';

export const allowedEducationalCatalogBycL1s = [
  'self_leadership',
  'team_leadership',
  'organizational_leadership'
]

export const useL0BycOffice = (): [Array<LevelEnrichedFE>, boolean] => {
  const [listL0, setListL0] = useState<Array<LevelEnrichedFE>>();
  const [isLoading, setIsLoading] = useState(true)
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const isGetCourseCompleted = useSelector(
    (state) => state.course.isGetCourseCompleted
  );
  const labelL0Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL0Map);
  const labelL1Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL1Map);

  useEffect(() => {

    if (isGetCourseCompleted
      && coursesMap
      && Object.values(labelL0Map)?.length > 0
      && Object.values(labelL1Map)?.length > 0) {

      // L0s career from Taxonomy (getAllLabels) 
      let l0sBycOffice = Object.values(labelL0Map)?.filter(l0 =>
        (l0.area === LEVELS_AREA.EDUCATIONAL_CATALOG_BYC ||
          l0.area === LEVELS_AREA.BUILD_YOUR_CAREER_UP_OFFICE) &&
        (Object.values(coursesMap)?.some(c => isCourseVisible(c) && c.level0 === l0.key))
      )
      // Array to add L0s to use
      let listL0s: LevelEnrichedFE[] = []

      l0sBycOffice.map(l0 => {
        const l1Career: LevelEnrichedFE[] = [];
        // MAP courses with l0sBycOffice
        Object?.values(coursesMap)?.filter(course => course?.level0 === l0?.key)?.map(c => {
          if (isCourseVisible(c)) {
            // IF Specialty Programs BYC Office && Course not ctype learning_path return
            if(l0.area === LEVELS_AREA.BUILD_YOUR_CAREER_UP_OFFICE && !c?.ctype?.includes(ctype.LEARNING_PATH)) return
            // IF Educational Catalog BYC && l1Key not allowed return
            if(l0.area === LEVELS_AREA.EDUCATIONAL_CATALOG_BYC && !c?.catalogTypes.some(l1 => allowedEducationalCatalogBycL1s?.includes(l1))) return
            // GET l1Key
            let l1key = c?.catalogTypes[0]
            // FIND the specific l1 from the l1s taxonomy list
            let l1: LevelEnrichedFE = Object?.values(labelL1Map)?.find(l1c => l1c?.key === l1key)
            // PUSH it to the array if it's not already present
            if (l1 && !l1Career.includes(l1))
              l1Career.push(l1)
          }
        })

        listL0s.push({
          ...l0,
          l1: cloneDeep(l1Career.sort((a, b) => a.order - b.order))
        })
      })

      setListL0(listL0s)
      setIsLoading(false)
    }

  }, [coursesMap, labelL0Map, labelL1Map]);


  return [listL0, isLoading]
}