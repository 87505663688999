import React from 'react';
import ReactDOM from 'react-dom';
import './css/custom.scss';
import App from './App';
import { Provider } from 'react-redux';
import { saveState } from './utility/localStorage';
import { Router } from 'react-router-dom';
import history from './history';
import { isProdEnv as isProd, isQualityEnv as isQa } from './utility/Api';
import './i18n.js';
import { store } from './store/store';

store.subscribe(
  throttle(() => {
    saveState({
      authentication: {
        cookies: store.getState().authentication.cookies,
        isAuthenticated: store.getState().authentication.isAuthenticated,
      },
    });
  }, 1000)
);

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'auto';
}

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <React.Suspense fallback="">
          <App />
        </React.Suspense>
      </Router>
    </Provider>,
    document.getElementById('root')
  );

//POLYFILL E CSS FOR IE
if (
  'fetch' in window &&
  'Map' in window &&
  'forEach' in NodeList.prototype &&
  'startsWith' in String.prototype &&
  'endsWith' in String.prototype &&
  'includes' in String.prototype &&
  'includes' in Array.prototype &&
  'assign' in Object &&
  'entries' in Object &&
  'keys' in Object
) {
  if (!('IntersectionObserver' in window)) {
    //polyfill to react-intersection-observer
    require('intersection-observer');
  }
  render();
} else {
  require('core-js/features/map');
  require('core-js/features/string');
  require('core-js/features/array');
  require('core-js/features/object');
  require('./css/ie-specific.scss');
  render();
}
