import React from "react";
import CardCover from "@components/CardV3/CardCover";
import { handleOnEnterKeyPress, isCompletedCourse } from "../../utility/Api";
import { CoursesMap, LangMap } from "../../model/CoursesClass";
import { useSelector } from "@hooks/redux";
import { CardDisplayProps } from "./CardDisplayUtils";
import { getLevelLabel } from "@utility/levelUtility";
import { isUserEl360 } from "@utility/userUtility";
import { isCourseEl360 } from "@utility/CourseUtility";
import { isProgramBlocked } from "@utility/EducationalPathsUtility";
import LockIconSmall from '@images/svg-icons/lock-icon-small.svg';


const CardSearch = ({ course, openCourseModal, duration }: CardDisplayProps) => {
    const lang: LangMap = useSelector((state) => state.utils.lang);
    const labelComplexityMap = useSelector((state) => state.utils.labelComplexityMap);
    const user = useSelector((state) => state.user.userProfile);
    const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);

    const handleClickCard = () => {
        openCourseModal();
    }
    const handleKeyDown = (event) => {
        handleOnEnterKeyPress(event, handleClickCard, true);
    }

    return (
        <div className={"card-search"} data-id={course.courseId}>
            <div className={"card-search__content"}>
                <div className="card-search__cover" onClick={handleClickCard} tabIndex={0} onKeyDown={handleKeyDown} aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace("{name}", course.courseFullName)}>
                    <CardCover image={course.courseOverviewFile} courseFullName={course.courseFullName} variant="card-medium" hideShadow />
                </div>

                <section className={`card-search__info `} aria-label={course.courseFullName}>
                    <div>
                        <div className="card-search__ctype">
                            <span className="card-search__ctype-text">
                                {getLevelLabel(course.complexityId, labelComplexityMap)}
                            </span>
                            {duration &&
                                <>
                                    <div className="card-search__divider" />
                                    <div className="card-search__duration">
                                        <span>{duration}</span>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="card-search__title" onClick={handleClickCard}>{course.courseFullName}</div>
                        {isUserEl360(user) && isCourseEl360(course) &&
                        <div className="card-search__el360-label">
                            {lang?.EL360_LABEL}
                        </div>
                        }
                        {isProgramBlocked(course, coursesMap) && !isCompletedCourse(course) && (                
                            <div className='card-search__blocked-program'>
                                <span className='card-search__blocked-program-info'>
                                    <LockIconSmall />
                                    <p>{lang?.BLOCKED_PROGRAM_INFO_CARD}</p>
                                </span>
                            </div>
                        )}
                    </div>

                </section>
            </div>
        </div>
    )
};

export default CardSearch;
