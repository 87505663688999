import { isCompletedCourse, isStartedCourse } from "@utility/Api";
import useGetEducationalPaths from "./useGetEducationalPaths";

const  useGetEducationalPathsMenu = () => {
  const [paths] = useGetEducationalPaths();

  const toContinuePaths = paths?.available?.filter(path => isStartedCourse(path) && !isCompletedCourse(path));
  const toStartPaths = paths?.available?.filter(path => !isStartedCourse(path) && !isCompletedCourse(path));

  return [toStartPaths, toContinuePaths];
}

export default useGetEducationalPathsMenu;