import React from 'react';
import { useSelector } from '@hooks/redux';
import useGetDetailsMultiactivity, {
  DetailMultiactivityClass,
} from '@hooks/useGetDetailsMultiactivity';
import useToggle from '@hooks/useToggle';
import { Course, LangMap } from '@model/CoursesClass';
import ArrowRight from '@svg-icons/arrow-right.svg';
import Button from './Button';
import { isCompletedCourse } from '@utility/Api';
import { getCoursesWithinLP, isAnEvent } from '@utility/Api';

class Props {
  course: Course;
  className?: string = '';
  coursesMap?: Array<Course>;
}

const handleClickScroll = (e, key) => {
  const idToScroll = 'ID' + key;
  const sessions = document.getElementById(idToScroll);
  sessions.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
};

export const ActivitiesListLP = ({ course, className, coursesMap }: Props): React.ReactElement => {
  const lang: LangMap = useSelector(state => state.utils.lang);

  const lpCourses = getCoursesWithinLP(course, coursesMap);

  const [viewAll, toggleViewAll] = useToggle(false);

  return lpCourses?.length > 0 && !isAnEvent(course) ? (
    <div className={'activities-list ' + className}>
      {!isAnEvent(course) && <span className="activities-list__title">{lang.MODULES_TITLE}</span>}
      <div
        className={
          'activities-list__content' +
          (lpCourses.length > 3 ? (viewAll ? ' view-all' : ' view-less') : '')
        }
      >
        {lpCourses.map((detail, i) => (
          <div
            key={i}
            className={
              'activities-list__activity ' + (detail.isBlocked ? 'card-activity--locked' : '')
            }
            onClick={e => handleClickScroll(e, i + 1)}
          >
            <div
              className={
                'card-activity__order-number' +
                (isCompletedCourse(detail) ? ' card-activity--completed' : '')
              }
            >
              <span>{i + 1}</span>
            </div>

            {detail.courseFullName}
          </div>
        ))}
      </div>
      {lpCourses.length > 3 && (
        <Button
          handleClick={toggleViewAll}
          ariaLabel={lang.VIEW_ALL_LABEL}
          variant="link"
          className="activities-list__view-all"
        >
          {viewAll ? lang.VIEW_LESS : lang.VIEW_ALL}
          <ArrowRight className={`icon-svg arrow ${viewAll ? 'arrow-up' : 'arrow-down'}`} />
        </Button>
      )}
    </div>
  ) : null;
};

export default ActivitiesListLP;
