import React, { useState } from 'react';
import * as actions from '@redux-actions/';
import { useDispatch } from 'react-redux';
import { Course, CourseActivity, CourseSession, LangMap } from '@model/CoursesClass';
import Carousel from '@components/UI/Carousel';
import AnimationSlide, { animationSlideToTop } from '@components/animations/AnimationSlide';
import { USER_URLS } from '@components/link-utils';
import { TrainingPill } from '@model/ProductClass';
import CardMediumPillSkeleton from '@components/skeletons/CardMediumPillSkeleton';
import { useSelector } from '@hooks/redux';
import CarouselVariableWidth from '@components/CarouselVariableWidth';
import ButtonV2 from '@components/UI/ButtonV2';
import { CarouselTitle, CarouselTitleSkeleton } from '@components/CarouselSection';
import useInViewCarousel from '@hooks/useInViewCarousel';
import CardShowMore from '@components/CardV3/CardShowMore';
import {
  checkCourseState,
  formatTimeInAMPM,
  getHoursCard,
  goToUrl,
  isMobile,
  isScheduledSession,
  printDate,
} from '@utility/Api';
import { analyticsPageType, purifyItemDataElementId } from '@utility/analytics-utils';
import { HPCarousel } from '@hooks/useHomepageCarousels';
import '@css/partials_v3/_vcMultiSession.scss';
import { openConfirmBookingModal } from '@redux-actions/';
import { courseStatesConst } from '@utility/const';
import LazySVG from '@components/LazySvg';
import DetailsSingleActivityButton from '@components/DetailsSingleActivityButton';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';

type VCMultiSessionCarouselSectionProps = {
  vcMultiSessions: CourseSession[];
  isLoading: boolean;
  hideTitle?: boolean;
  numVCToShow?: number;
  title?: string;
  description?: string;
  stringDescription?: string;
  classNames?: string;
  linkLabel?: string;
  viewAllTo?: string;
  variableWidth?: boolean;
  id?: string;
  beforeOnClickCard?: (pill: TrainingPill, elementPosition: number) => void;
  elementPosition?: number;
  visibleCarousels?: HPCarousel[];
  course?: Course;
  vcLesson?:string;
};

const VCMultiSessionCarouselSection = ({
  vcMultiSessions = [],
  isLoading,
  numVCToShow = 12,
  hideTitle,
  title,
  description,
  stringDescription = '',
  classNames = '',
  linkLabel = 'SEE_ALL_LABEL',
  viewAllTo = USER_URLS.SUBCATALOGUE_TP.URL,
  variableWidth,
  id = 'carousel_' + (title || 'training-pills')?.toLowerCase()?.replace(/[^\d\w]/g, '_'),
  beforeOnClickCard,
  elementPosition,
  visibleCarousels,
  course,
  vcLesson,
}: VCMultiSessionCarouselSectionProps) => {
  const dispatch = useDispatch();
  const lang: LangMap = useSelector(state => state.utils.lang);
  const breakpoint = useBreakpointBoolean();
  const { UPCOMING, SCHEDULED, MISSED, ATTENDED } = courseStatesConst;
  const { isPortrait, isPhone, isTablet, isDesktop } = useBreakpointBoolean();

  const handleShowMore = () => {
    if (title !== 'TP_CAROUSEL_TITLE') {
      dispatch(actions.setTPCatalogueTitle(title));
    }
    if (variableWidth) {
      dispatch(actions.setTPCatalogueTitle(title, description));
    }
  };

  const isDisabled = session => {
    let today = new Date(),
      sessionDate = new Date(session.minStartDate);

    return (
      sessionDate < today ||
      (checkCourseState(session.status, SCHEDULED) && !isScheduledSession(session))
    );
  };

  const hasMoreCards = !!(vcMultiSessions.length > numVCToShow);
  const carousel = visibleCarousels && visibleCarousels.find(carousel => title === carousel.title);
  const CardElement = ({
    vcsession,
    carouselId,
    useOpacityOnCutCard,
    beforeOnClickCard,
    elementPosition,
  }) => {
    const [ref, isVisible] = useInViewCarousel(carouselId);
    const pageType = analyticsPageType();
    const dataElementId = `${pageType}_${purifyItemDataElementId(title)}N${
      carousel?.position
    }_N${elementPosition}`;
    let dateTimeSession = formatTimeInAMPM(
      vcsession.sessionDates[0].timeStart.substring(11, 16).toString +
        '-' +
        formatTimeInAMPM(vcsession.sessionDates[0].timeFinish.substring(11, 16))
    );

    return (
      <a
        ref={ref}
        className={
          'carousel-section__element-wrapper ' +
          (!useOpacityOnCutCard || isVisible ? '' : 'carousel-section__element-not-visible')
        }
        data-element-id={dataElementId}
      >
        <div className="vcMultiSession__box-card">
          {/*vcsession.id &&( //vcSessionId
          <div className="howto__tip-title">{vcsession.id}</div>)*/}
          {vcsession.minStartDate && ( //date
            <div className="vcMultiSession__box-card__date">
              {printDate(new Date(vcsession.minStartDate), lang)}
            </div>
          )}
          {vcsession.sessionDates && ( //time
            <>
              <div className="vcMultiSession__box-card__time">
                {getHoursCard(vcsession.sessionDates, true)}
              </div>
            </>
          )}
          <div className="vcMultiSession__box-card__innerBoxLanguageSeats">
            {vcsession.language && ( //language
              <div className="vcMultiSession__box-card__language">{vcsession.language}</div>
            )}
            {vcsession.availablePlaces &&
              vcsession.availablePlaces < 10 && ( //available places
                <div className="vcMultiSession__box-card__seats">
                  {vcsession.availablePlaces + ' ' + lang.SEATS_VC_AVAILABLE}
                </div>
              )}
          </div>
          <div className="vcMultiSession__box-card__innerBoxTeacherCity">
            {vcsession.trainers && ( //trainers
              <div className="vcMultiSession__box-card__teacherBox">
                <div className="vcMultiSession__box-card__teacher">
                  {vcsession?.trainers[0]?.firstName.toLowerCase()}
                </div>
                <div className="vcMultiSession__box-card__teacher">
                  {vcsession?.trainers[0]?.lastName.toLowerCase()}
                </div>
              </div>
            )}
            {vcsession.city && ( //city
              <div className="vcMultiSession__box-card__teacherBox">
                <div className="vcMultiSession__box-card__city__tag">{'-'}</div>
                <div className="vcMultiSession__box-card__city">{vcsession.city}</div>
              </div>
            )}
          </div>
          {!isDisabled(vcsession) &&
            breakpoint.isDesktopOrTabletLandscape && ( //desktop and tablet
              <div className="vcMultiSession__box-card__button">
                <DetailsSingleActivityButton
                  lang={lang}
                  course={course}
                  subtitle={null}
                  buttonVariant={'primary'}
                  isLoading={false}
                  multiActivityVCSession={true}
                  buttonSmall={true}
                  vcSession={vcsession}
                />
              </div>
            )}
          {!isDisabled(vcsession) &&
            isMobile.any() && ( //mobile
              <div className="vcMultiSession__plus-sign-up">
                <LazySVG
                  className="vcMultiSession__plus-icon"
                  src="/images/svg-icons/plus2.svg"
                  alt={''}
                />
                <DetailsSingleActivityButton
                  lang={lang}
                  course={course}
                  subtitle={null}
                  buttonVariant="text-btn-no-arrow"
                  isLoading={false}
                  multiActivityVCSession={true}
                  buttonSmall={true}
                />
              </div>
            )}
        </div>
      </a>
    );
  };
  const ContentCarousel = vcMultiSessions.map((session, index) => (
    <CardElement
      key={session.id}
      vcsession={session}
      carouselId={id || 'training-pills'}
      useOpacityOnCutCard={true}
      beforeOnClickCard={beforeOnClickCard}
      elementPosition={index}
    />
  ));

  const SkeletonCarousel =
    (vcMultiSessions.length === 0 || isLoading) &&
    [...Array(!variableWidth ? 6 : 13)].map((e, i) => (
      <div key={`training-pills-skeleton-${i}`}>
        <CardMediumPillSkeleton />
      </div>
    ));

  const handleViewAll = () => {
    handleShowMore();
    goToUrl(viewAllTo);
  };

  const ShowMoreButton = variableWidth && hasMoreCards && (
    <CardShowMore variant="medium-pill" onClick={handleViewAll} />
  );

  const carouselTitleHandler =
    !isLoading || vcMultiSessions?.length > 0 || !variableWidth ? (
      <CarouselTitle
        hideTitle={hideTitle}
        stringTitle={lang?.[title] || title}
        showPlusButton={hasMoreCards && variableWidth}
        linkLabel={linkLabel}
        handleViewAll={handleViewAll}
        description={description}
        stringDescription={stringDescription}
      />
    ) : (
      <CarouselTitleSkeleton />
    );

  return (
    <div
      className={`training-pills-section carousel-wrapper ${
        variableWidth ? 'variable-width' : ''
      } ${classNames}`}
      id={id}
    >
      {(vcMultiSessions?.length > 0 || isLoading) && (
        <div className="vcMultiSession_carousel">
          <AnimationSlide {...animationSlideToTop}>
            {variableWidth ? (
              <CarouselVariableWidth
                ContentCarousel={ContentCarousel}
                SkeletonCarousel={SkeletonCarousel}
                ShowMoreButton={ShowMoreButton}
                isLoading={isLoading || !(vcMultiSessions?.length > 0)}
                variant={'training-pills'}
                titleComponent={carouselTitleHandler}
                elementPosition={elementPosition}
              />
            ) : (
              <>
                <>
                  {carouselTitleHandler}
                  {isMobile.any() && !isTablet &&(
                    <Carousel
                      numSlides={vcMultiSessions.length > 0 ? vcMultiSessions.length : 1}
                      showSlider={true}
                      variableWidth={true}
                      variant="progress"
                      roundChevron={false}
                      // arrowsStyle='latest-collections'
                    >
                      {ContentCarousel}
                      {SkeletonCarousel}
                      {ShowMoreButton}
                    </Carousel>
                  )}
                  {isMobile.any() && isTablet && (
                    <Carousel
                      numSlides={vcMultiSessions.length > 0 ? vcMultiSessions.length : 6}
                      showSlider={true}
                      variableWidth={true}
                      variant="vc-sessions-tablet"
                      roundChevron={false}
                      // arrowsStyle='latest-collections'
                    >
                      {ContentCarousel}
                      {SkeletonCarousel}
                      {ShowMoreButton}
                    </Carousel>
                  )}
                  {!isMobile.any() && (
                    <Carousel
                      numSlides={vcMultiSessions.length > 0 ? vcMultiSessions.length : 6}
                      showSlider={true}
                      variableWidth={true}
                      variant="vc-sessions-desktop"
                      roundChevron={false}
                      // arrowsStyle='latest-collections'
                    >
                      {ContentCarousel}
                      {SkeletonCarousel}
                      {ShowMoreButton}
                    </Carousel>
                  )}
                </>
              </>
            )}
          </AnimationSlide>
        </div>
      )}
    </div>
  );
};

export default VCMultiSessionCarouselSection;
