import React, { useState } from "react";
import clsx from "@utility/clsx";
import { useDispatch } from "react-redux";
import LazyImage from 'react-lazy-progressive-image';
import { Teacher } from "@model/Teacher";
import { getAvatar, getInitialsFromFullName } from '@utility/Api';
import { Trainer } from "@model/CoursesClass";
import GenericSkeleton from "@components/skeletons/GenericSkeleton";
import { toggleTeacherModal } from "../../store/actions";

type Props = {
  image: string;
  fullName: string;
  variant: 'small' | 'community' | 'session' | 'teacher-avatar-educational-paths';
  noBorder?: boolean; // only working for small
  className?: string;
  user?: Teacher | Trainer;
  canOpenModal?: boolean;
  isStatic?: boolean;
  // customWidthPx?: string;
  // customHeightPx?: string;
}

type CircleAvatarProps = {
  children: React.ReactNode;
} & Props;

const CircleAvatar = (props: CircleAvatarProps) => (
  <div
    className={clsx("avatar", {
      [props.className]: props.className,
      [props.variant]: props.variant,
      "no-border": props.noBorder,
      "cursor-pointer": props.canOpenModal,
    })}
  >
    {props.children}
  </div>
);

const Avatar = (props: Props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.noBorder && props.variant !== "small") {
      console.error('noBorder prop only works for small variant')
    }
  }, [])

  const [hideImage, setHideImage] = useState(false);

  const openUserModal = () => {
    if (props.user && props.canOpenModal) {
      dispatch(toggleTeacherModal(true, props.user));
    }
  }

  // const { customWidthPx, customHeightPx } = props;

  return (
    <div 
      // style={(customWidthPx && { width: `${customWidthPx}px` }) && (customHeightPx && { height: `${customHeightPx}px` })}
      className="avatar__wrapper" onClick={openUserModal}
    >
      <CircleAvatar {...props}>
        {props.image && !hideImage ? (
          <LazyImage
            src={props.image}
            onError={() => {
              setHideImage(true);
            }}
            placeholder="/images/spinner/img_placeholder.gif"
          >
            {(src, loading) => {
              // loading = true;
              
              if(loading) {
                return (
                  <div className={
                    "card-v3__background" + (loading ? " loading" : "") + " " + props.variant
                  }>
                    <GenericSkeleton />
                  </div>
                )
              }

              return (
                <>
                {!loading && (
                  <div
                    className="card-v3__gradient"
                  />
                )}
                <img
                  // style={(customWidthPx && { width: `${customWidthPx}px` }) && (customHeightPx && { height: `${customHeightPx}px` })}
                  className={
                    "card-v3__background" + (loading ? " loading" : "") + " " + props.variant
                  }
                  src={`${src}${!props.isStatic ? "?impolicy=Card" : ""}`}
                  alt={props.fullName}
                  draggable={false}
                />
              </>
              )
            }}
              </LazyImage>
        ) : (

          <section className="flex justify-center align-center width-100 height-100 avatar__initials" aria-label={props.user?.firstName || props.fullName}>
            <p className="no-margin">{props.user && getAvatar(props.user) || getInitialsFromFullName(props.fullName)}</p>
          </section>
        )}
      </CircleAvatar>
      {props.variant === "community" && <div className="outerBorder"></div>}
    </div>
  );

};


export default Avatar;
