import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/Api';
import { CMSOnboarding, CMSTeacherRedux } from '@model/CMSOnboarding';
import { HeroBannerInfo } from '@model/CMSHeroBanner';
import { HpBean } from '@model/HPBean';
import { HowToInfo } from '@model/CMSHowTo';
import { CMSBrandInfo } from '@model/CMSBrand';
import { InfoTortona } from '@model/CMSTortona';
import { CMSPlanPage, CMSPlanPageResponse} from '@model/CMSPlanPage';
import { BuildYourCareerInfos } from '@model/CMSBuildYourCareer';

class InitialStateProps {
  isFaqLoading: boolean = false;
  isFaqEcommerceLoading: boolean = false;
  isFaqPlanLoading: boolean = false;
  isFaqCheckoutLoading: boolean = false;
  isFaqEducationalPathsLoading: boolean = false;
  isContactUsCheckoutLoading: boolean = false;
  faqList = [];
  faqListPlan = [];
  faqListEcommerce = [];
  faqListCheckout = [];
  faqListEducationalPaths = [];
  contactUs = { content: null, contentId: 0 };
  contactUsCheckout = { content: null, contentId: 0 };
  imgCategoriesUrl = {};
  brandPageInfo: { [l1Id: string]: CMSBrandInfo } = {};
  isLoadingL1Image: boolean = false;
  L1Images = {};
  isLoadingHowToInfo: boolean = false;
  howtoInfo: HowToInfo = null;
  isLoadingHpBeans: boolean = false;
  isErrorHpBeans: boolean = false;
  languageLoadedHpBeans: string = '';
  hpBeans: HpBean[] = [];
  //plan carousel info from CMS
  planCarouselInfo: CMSBrandInfo = null;
  isLoadingPlanCarouselInfo: boolean = false;
  planPageInfos: CMSPlanPage = null;
  isLoadingPlanPageInfos: false;
  planPageInfosLanguage: string = '';

  heroBannerInfo: HeroBannerInfo[] = null;
  isLoadingHeroBannerInfo: boolean = false;
  isHeroBannerInfoError: boolean = false;
  heroBannerLanguage: string = '';
  infoTortona: InfoTortona[] = null;
  isLoadingInfoTortona: boolean = false;
  isInfoTortonaError: boolean = false;
  infoTortonaLanguage: string = '';
  //onboarding info
  onboardingOverview: { [courseIdMaster: string]: { data: CMSOnboarding; language: string } } =
    null;
  isLoadingOnboardingOverview: boolean = false;
  //onboarding teachers
  onboardingTeachersInfo: CMSTeacherRedux = new CMSTeacherRedux();
  //build your career infos
  buildYourCareerInfos: BuildYourCareerInfos[] = null;
  isLoadingBuildYourCareerInfos: boolean = false;
  isBuildYourCareerInfosError: boolean = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const getFaqRequest = state => {
  return updateObject(state, {
    isFaqLoading: true,
  });
};

const getFaqSuccess = (state, action) => {
  return updateObject(state, {
    faqList: action.faqList,
    isFaqLoading: false,
  });
};

const getFaqFailure = state => {
  return updateObject(state, {
    isFaqLoading: false,
  });
};

const getFaqPlanRequest = state => {
  return updateObject(state, {
    isFaqPlanLoading: true,
  });
};

const getFaqPlanSuccess = (state, action) => {
  return updateObject(state, {
    faqListPlan: action.faqList,
    isFaqPlanLoading: false,
  });
};

const getFaqPlanFailure = state => {
  return updateObject(state, {
    isFaqPlanLoading: false,
  });
};

const getFaqEcommerceRequest = state => {
  return updateObject(state, {
    isFaqEcommerceLoading: true,
  });
};

const getFaqEcommerceSuccess = (state, action) => {
  return updateObject(state, {
    faqListEcommerce: action.faqList,
    isFaqEcommerceLoading: false,
  });
};

const getFaqEcommerceFailure = state => {
  return updateObject(state, {
    isFaqEcommerceLoading: false,
  });
};

const getFaqCheckoutRequest = state => {
  return updateObject(state, {
    isFaqCheckoutLoading: true,
  });
};

const getFaqCheckoutSuccess = (state, action) => {
  return updateObject(state, {
    faqListCheckout: action.faqList,
    isFaqCheckoutLoading: false,
  });
};

const getFaqCheckoutFailure = state => {
  return updateObject(state, {
    isFaqCheckoutLoading: false,
  });
};

const getFaqEducationalPathsRequest = state => {
  return updateObject(state, {
    isFaqEducationalPathsLoading: true,
  });
};

const getFaqEducationalPathsSuccess = (state, action) => {
  return updateObject(state, {
    faqListEducationalPaths: action.faqList,
    isFaqEducationalPathsLoading: false,
  });
};

const getFaqEducationalPathsFailure = state => {
  return updateObject(state, {
    isFaqEducationalPathsLoading: false,
  });
};

const getContactUsSuccess = (state, action) => {
  return updateObject(state, {
    contactUs: {
      content: action.payload.content,
      contentId: action.payload.contentId
    }
  });
};

const getContactUsCheckoutRequest = (state) => {
  return updateObject(state, {
    isContactUsCheckoutLoading: true,
  });
};

const getContactUsCheckoutSuccess = (state, action) => {
  return updateObject(state, {
    contactUsCheckout: {
      content: action.payload.content,
      contentId: action.payload.contentId
    },
    isContactUsCheckoutLoading: false,
  });
};

const getContactUsCheckoutFailure = (state) => {
  return updateObject(state, {
    isContactUsCheckoutLoading: false,
  });
};

const getBrandPageInfoSuccess = (state, action) => {
  return updateObject(state, {
    brandPageInfo: action.payload?.l1
      ? {
        ...state.brandPageInfo,
        [action.payload.l1]: action.payload,
      }
      : state.brandPageInfo,
  });
};

const getL1ImageRequest = state => {
  return updateObject(state, {
    isLoadingL1Image: true,
  });
};

const getL1ImageSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingL1Image: false,
    L1Images: { ...state.L1Images, ...action.L1Images },
  });
};

const getL1ImageFailure = state => {
  return updateObject(state, {
    isLoadingL1Image: false,
  });
};

const getHowToInfoRequest = state => {
  return updateObject(state, {
    isLoadingHowToInfo: true,
  });
};

const getHowToInfoSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingHowToInfo: false,
    howtoInfo: action.payload,
  });
};

const getHowToInfoFailure = state => {
  return updateObject(state, {
    isLoadingHowToInfo: false,
    howtoInfo: null,
  });
};

const getHpBeansRequest = state => {
  return updateObject(state, {
    isLoadingHpBeans: true,
    isErrorHpBeans: false,
  });
};

const getHpBeansSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingHpBeans: false,
    languageLoadedHpBeans: action.language,
    hpBeans: action.hpBeans,
    isErrorHpBeans: !action?.hpBeans?.length,
  });
};

const getHpBeansFailure = state => {
  return updateObject(state, {
    isLoadingHpBeans: false,
    isErrorHpBeans: true,
  });
};

const getPlanCarouselInfoRequest = state => {
  return updateObject(state, {
    isLoadingPlanCarouselInfo: true,
  });
};

const getPlanCarouselInfoSuccess = (state, action) => {
  return updateObject(state, {
    planCarouselInfo: action.payload,
    isLoadingPlanCarouselInfo: false,
  });
};

const getPlanCarouselInfoFailure = state => {
  return updateObject(state, {
    isLoadingPlanCarouselInfo: false,
  });
};

const getPlanPageInfosRequest = state => {
  return updateObject(state, {
    isLoadingPlanPageInfos: true,
  })
}
const getPlanPageInfosSuccess = (state, action) => {
  return updateObject(state, {
    isLoadingPlanPageInfos: false,
    planPageInfos: action.payload,
    planPageInfosLanguage: action.preferredLang
  })
}
const getPlanPageInfosFailure = state => {
  return updateObject(state, {
    isLoadingPlanPageInfos: false,
  })
}

const getHeroBannerInfoRequest = state => {
  return updateObject(state, {
    isLoadingHeroBannerInfo: true,
    isHeroBannerInfoError: false,
  });
};

const getHeroBannerInfoSuccess = (state, action) => {
  return updateObject(state, {
    heroBannerInfo: action.payload,
    isLoadingHeroBannerInfo: false,
    heroBannerLanguage: action.preferredLang,
  });
};

const getHeroBannerInfoFailure = state => {
  return updateObject(state, {
    isLoadingHeroBannerInfo: false,
    isHeroBannerInfoError: true,
  });
};

const getInfoTortonaRequest = state => {
  return updateObject(state, {
    isLoadingInfoTortona: true,
    isInfoTortonaError: false,
  });
};

const getInfoTortonaSuccess = (state, action) => {
  return updateObject(state, {
    infoTortona: action.payload,
    isLoadingInfoTortona: false,
    infoTortonaLanguage: action.preferredLang,
  });
};

const getInfoTortonaFailure = state => {
  return updateObject(state, {
    isLoadingInfoTortona: false,
    isInfoTortonaError: true,
  });
};

const getOnboardingOverviewRequest = state => {
  return updateObject(state, {
    isLoadingOnboardingOverview: true,
  });
};

const getOnboardingOverviewSuccess = (state, action) => {
  return updateObject(state, {
    onboardingOverview: {
      ...state.onboardingOverview,
      [action.courseIdMaster]: {
        data: action.payload,
        language: action.preferredLang,
      },
    },
    isLoadingOnboardingOverview: false,
  });
};

const getOnboardingOverviewFailure = state => {
  return updateObject(state, {
    isLoadingOnboardingOverview: false,
  });
};

const getOnboardingTeachersRequest = state => {
  return updateObject(state, {
    onboardingTeachersInfo: {
      ...new CMSTeacherRedux(),
      isLoading: true,
    },
  });
};

const getOnboardingTeachersSuccess = (state, action) => {
  return updateObject(state, {
    onboardingTeachersInfo: {
      teachers: action.teachers,
      preferredLang: action.preferredLang,
      courseIdMaster: action.courseIdMaster,
      isLoading: false,
    },
  });
};

const getOnboardingTeachersFailure = state => {
  return updateObject(state, {
    onboardingTeachersInfo: {
      ...state.onboardingTeachersInfo,
      isLoading: false,
    },
  });
};

const getBuidYourCareerRequest = (state) => {
  return updateObject(state, {
    isLoadingBuildYourCareerInfos: true,
    isBuildYourCareerInfosError: false
  });
}

const getBuidYourCareerSuccess = (state, action) => {
  return updateObject(state, {
    buildYourCareerInfos: action.buildYourCareerInfos,
    isLoadingBuildYourCareerInfos: false,
    isBuildYourCareerInfosError: false
  });
}

const getBuidYourCareerFailure = (state) => {
  return updateObject(state, {
    isLoadingBuildYourCareerInfos: false,
    isBuildYourCareerInfosError: true
  });
}

const logout = () => {
  return initialState;
};

const reducer = (state = initialState, action): InitialStateProps => {
  switch (action.type) {
    case actionTypes.GET_FAQ_REQUEST:
      return getFaqRequest(state);
    case actionTypes.GET_FAQ_SUCCESS:
      return getFaqSuccess(state, action);
    case actionTypes.GET_FAQ_FAILURE:
      return getFaqFailure(state);
    case actionTypes.GET_FAQ_PLAN_REQUEST:
      return getFaqPlanRequest(state);
    case actionTypes.GET_FAQ_PLAN_SUCCESS:
      return getFaqPlanSuccess(state, action);
    case actionTypes.GET_FAQ_PLAN_FAILURE:
      return getFaqPlanFailure(state);
    case actionTypes.GET_FAQ_ECOMMERCE_REQUEST:
      return getFaqEcommerceRequest(state);
    case actionTypes.GET_FAQ_ECOMMERCE_SUCCESS:
      return getFaqEcommerceSuccess(state, action);
    case actionTypes.GET_FAQ_ECOMMERCE_FAILURE:
      return getFaqEcommerceFailure(state);
    case actionTypes.GET_FAQ_CHECKOUT_REQUEST:
      return getFaqCheckoutRequest(state);
    case actionTypes.GET_FAQ_CHECKOUT_SUCCESS:
      return getFaqCheckoutSuccess(state, action);
    case actionTypes.GET_FAQ_CHECKOUT_FAILURE:
      return getFaqCheckoutFailure(state);
    case actionTypes.GET_FAQ_EDUCATIONAL_PATHS_REQUEST:
      return getFaqEducationalPathsRequest(state);
    case actionTypes.GET_FAQ_EDUCATIONAL_PATHS_SUCCESS:
      return getFaqEducationalPathsSuccess(state, action);
    case actionTypes.GET_FAQ_EDUCATIONAL_PATHS_FAILURE:
      return getFaqEducationalPathsFailure(state);
    case actionTypes.GET_CONTACT_US_SUCCESS:
      return getContactUsSuccess(state, action);
    case actionTypes.GET_CONTACT_US_CHECKOUT_REQUEST:
      return getContactUsCheckoutRequest(state);
    case actionTypes.GET_CONTACT_US_CHECKOUT_SUCCESS:
      return getContactUsCheckoutSuccess(state, action);
    case actionTypes.GET_CONTACT_US_CHECKOUT_FAILURE:
      return getContactUsCheckoutFailure(state);
    case actionTypes.GET_BRAND_PAGE_INFO_SUCCESS:
      return getBrandPageInfoSuccess(state, action);
    case actionTypes.GET_L1_IMAGE_REQUEST:
      return getL1ImageRequest(state);
    case actionTypes.GET_L1_IMAGE_SUCCESS:
      return getL1ImageSuccess(state, action);
    case actionTypes.GET_L1_IMAGE_FAILURE:
      return getL1ImageFailure(state);
    case actionTypes.GET_HOWTO_REQUEST:
      return getHowToInfoRequest(state);
    case actionTypes.GET_HOWTO_SUCCESS:
      return getHowToInfoSuccess(state, action);
    case actionTypes.GET_HOWTO_FAILURE:
      return getHowToInfoFailure(state);
    case actionTypes.GET_BEANS_REQUEST:
      return getHpBeansRequest(state);
    case actionTypes.GET_BEANS_SUCCESS:
      return getHpBeansSuccess(state, action);
    case actionTypes.GET_BEANS_FAILURE:
      return getHpBeansFailure(state);
    case actionTypes.GET_PLAN_CAROUSEL_INFO_REQUEST:
      return getPlanCarouselInfoRequest(state);
    case actionTypes.GET_PLAN_CAROUSEL_INFO_SUCCESS:
      return getPlanCarouselInfoSuccess(state, action);
    case actionTypes.GET_PLAN_CAROUSEL_INFO_FAILURE:
      return getPlanCarouselInfoFailure(state);
    case actionTypes.GET_PLAN_PAGE_INFOS_REQUEST:
      return getPlanPageInfosRequest(state);
    case actionTypes.GET_PLAN_PAGE_INFOS_SUCCESS:
      return getPlanPageInfosSuccess(state, action);
    case actionTypes.GET_PLAN_PAGE_INFOS_FAILURE:
      return getPlanPageInfosFailure(state);
    case actionTypes.GET_HERO_BANNER_INFO_REQUEST:
      return getHeroBannerInfoRequest(state);
    case actionTypes.GET_HERO_BANNER_INFO_SUCCESS:
      return getHeroBannerInfoSuccess(state, action);
    case actionTypes.GET_HERO_BANNER_INFO_FAILURE:
      return getHeroBannerInfoFailure(state);
    case actionTypes.GET_INFO_TORTONA_REQUEST:
      return getInfoTortonaRequest(state);
    case actionTypes.GET_INFO_TORTONA_SUCCESS:
      return getInfoTortonaSuccess(state, action);
    case actionTypes.GET_INFO_TORTONA_FAILURE:
      return getInfoTortonaFailure(state);
    case actionTypes.GET_ONBOARDING_OVERVIEW_REQUEST:
      return getOnboardingOverviewRequest(state);
    case actionTypes.GET_ONBOARDING_OVERVIEW_SUCCESS:
      return getOnboardingOverviewSuccess(state, action);
    case actionTypes.GET_ONBOARDING_OVERVIEW_FAILURE:
      return getOnboardingOverviewFailure(state);
    case actionTypes.GET_ONBOARDING_TEACHERS_REQUEST:
      return getOnboardingTeachersRequest(state);
    case actionTypes.GET_ONBOARDING_TEACHERS_SUCCESS:
      return getOnboardingTeachersSuccess(state, action);
    case actionTypes.GET_ONBOARDING_TEACHERS_FAILURE:
      return getOnboardingTeachersFailure(state);
    case actionTypes.GET_BUILD_YOUR_CAREER_REQUEST:
      return getBuidYourCareerRequest(state);
    case actionTypes.GET_BUILD_YOUR_CAREER_SUCCESS:
      return getBuidYourCareerSuccess(state, action);
    case actionTypes.GET_BUILD_YOUR_CAREER_FAILURE:
      return getBuidYourCareerFailure(state);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
