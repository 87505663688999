import React, { CSSProperties } from "react";
import Spinner from "@components/UI/spinner_50px.svg";
import clsx from "@utility/clsx";
export type Props = {
  loading?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  isLike?: boolean;
  style?: CSSProperties;
  variant?: "colored" | "link" | "linear-gradient" | "big-with-icon" | "large" | "long-btn" | "new-style" | "link--small" | "colored-black-text";
  title?: string;
  ariaLabel?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const Button = ({
  loading,
  handleClick = () => { },
  disabled,
  children,
  className,
  isLike,
  style,
  variant,
  title,
  ariaLabel,
  type,
}: Props) => {
  const classes = clsx("btn-v3 btn-border", {
    "loading": loading && !isLike,
    [className]: !!className,
    [variant]: !!variant,
    disabled: !!disabled
  });

  return (
    <button
      className={classes}
      onClick={handleClick}
      disabled={disabled || (loading && !isLike)}
      title={title}
      aria-label={ariaLabel}
      style={style}
      type={type}
    >
      {loading && !isLike ? <Spinner className="loading-spinner" /> : children}
    </button>
  );
};

Button.displayName = "Button";

export default Button;
