export class WalletItem {
  identifier?: number;
  /**
   * Credit card first 6 digits
   */
  creditCardBin: string;
  /**
   *  Credit card last 4 digits
   */
  creditCardPan: string;
  paymentToken: string;
  payMethodId?: string;
  issuer: string;
  owner: string;
  expireDate: string;
  creation: string;
  isDefault: boolean;
}

export class WalletRequestBody {
  payMethodId?: string;
  /**
   * number of the month: MM
   */
  expire_month?: string;
  /**
   * number of the year: YYYY
   */
  expire_year?: string;
  /**
   * number of card
   */
  cc_account?: string;
  /**
   * control number card
   */
  cc_cvc?: string;
  /**
   * name of card issuer
   */
  cc_nameoncard?: string;
  /**
   * name of credit card circuit. If not provided, it will be calculated by card number
   */
   cc_issuer?: string;
  /**
   * Billing address Id
   */
  billingAddressId?: string;
  /**
   * Shipping address Id
   */
  shippingAddressId?: string;
}

export class WalletRequestResponse {
  recordTotalCount: number;
  wallet: Array<WalletItem>;
}
