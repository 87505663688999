import { useState, useEffect } from "react";
import { Course, LangMap, LevelEnrichedMap } from "@model/CoursesClass";
import { getLevelLabel } from "@utility/levelUtility";
import { isMandatory, isOverdueCourse, printDate } from "@utility/Api";
import { useSelector } from "@hooks/redux";
import { getIconsOnBoarding } from "@utility/onBoardingCourseUtility";
import { getMasterStartEndDate } from "@utility/CourseUtility";


class OnboardingLabelsClass {
  complexity: string;
  title: string;
  description: string;
  mcActiveMasterStartEndDate?: string;
  dueByDate?: string;
  dueByDateColor?: string;
  icons: OnboardingLabelsIconClass[] = [];
};

export class OnboardingLabelsIconClass {
  icon: string;
  label: string;
  descr: string;
  isHTML?: boolean = false;
};


const useGetOnboardingLabels = (course: Course, iconGradient: boolean = false, isOnboardingOverview: boolean = false, showElearningIconOnlyMoreIcons: boolean = false): OnboardingLabelsClass => {
  const lang: LangMap = useSelector((state) => state.utils.lang);
  const labelComplexityMap: LevelEnrichedMap = useSelector((state) => state.utils.labelComplexityMap);

  const [onboardingLabels, setOnboardingLabels] = useState<OnboardingLabelsClass>(new OnboardingLabelsClass());

  useEffect(() => {
    let onboardingLabelsTemp = new OnboardingLabelsClass();

    if (course) {
      onboardingLabelsTemp.complexity = getLevelLabel(course.complexityId, labelComplexityMap);
      onboardingLabelsTemp.title = course.courseFullName;
      onboardingLabelsTemp.description = course.courseSummary;
      onboardingLabelsTemp.mcActiveMasterStartEndDate =
        getMasterStartEndDate(course.masterAttributes?.mcStartDate, course.masterAttributes?.mcEndDate, lang);

      onboardingLabelsTemp.dueByDateColor = isOverdueCourse(course) ? 'red' : 'white';

      if (lang) {
        const dueDate = isMandatory(course) ? course.expectedCompletion : course.endDate;
        onboardingLabelsTemp.dueByDate = dueDate ? (isMandatory(course) ? lang.ONBOARDINGCOURSE_DUE_BY : lang.ONBOARDINGCOURSE_EXPIRATION_DATE)?.replace('{DATE}', printDate(dueDate, lang)) : '';
        onboardingLabelsTemp.icons = getIconsOnBoarding(lang, course, iconGradient, isOnboardingOverview, showElearningIconOnlyMoreIcons);
      }

    }

    setOnboardingLabels(onboardingLabelsTemp);
  }, [course, labelComplexityMap, lang])

  return onboardingLabels;
};

export default useGetOnboardingLabels;
