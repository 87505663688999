import { RootState } from './../store';
import { Course, CourseL1 } from '@model/CoursesClass';
import {
  compareDates,
  getL1Element,
  isCourseVisible,
  isOnlineCourse,
  isHighlighted,
} from '@utility/Api';
import { CourseIdL1, HeroBannerInfo, RowTeasers } from '@model/CMSHeroBanner';
import {
  getL1BrandByCourse,
  getL1IdFromBrandId,
  getLevelLabel,
  isL1IdBrand,
} from '@utility/levelUtility';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { canMasterBeAccessed, isCourseMaster } from '@utility/onBoardingCourseUtility';
import { isPurchased } from '@utility/ecommerceUtility';
import { CMSCarouselLayout, HPCarousel, HPCarouselCourseIdL1 } from '@hooks/useHomepageCarousels';
import { createSelector } from '@reduxjs/toolkit';
import { isCourseCareerMaster } from '@utility/buildYourCareerUtility';

export const selectCoursesAndL1 = createSelector(
  (state: RootState) => state.course.coursesMap,
  state => state.utils.l1Map,
  state => state.cms.L1Images,
  state => state.utils.lang,
  (_, coursesAndL1) => coursesAndL1,
  (_, __, switchOff) => switchOff,
  (
    coursesMap,
    l1Map,
    L1Images,
    lang,
    coursesAndL1: HPCarouselCourseIdL1["ids"],
    switchOff: boolean = false,
  ): Course[] => {
    // console.log("selectCoursesAndL1")
    let newCoursesList: Course[] = [];

    if (switchOff) {
      //prevent useless calculations
      return newCoursesList;
    }

    const insertedCourses = {};
    let numFallbackImage = 0;
    if (coursesAndL1?.length > 0 && coursesMap) {
      coursesAndL1.forEach(courseOrL1 => {
        if (!courseOrL1.l1) {
          //courseIds should contain just parent courses id
          const courseId = courseOrL1.id;
          const newCourse = coursesMap[courseId];
          if (
            newCourse &&
            isCourseVisible(newCourse) &&
            !insertedCourses[newCourse.courseIdMaster]
          ) {
            newCourse.positionInCarousel = courseOrL1.position;
            //newCoursesList.push(newCourse);
            newCoursesList.push({...newCourse, contentId: courseOrL1?.contentId});
            insertedCourses[newCourse.courseIdMaster] = true;
          }
        } else {
          const l1Id = courseOrL1.id;

          let l1 = getL1Element(l1Id, l1Map, L1Images, lang);
          l1.positionInCarousel = courseOrL1.position;

          let newCourseL1 = new CourseL1();
          newCourseL1 = {
            ...newCourseL1,
            courseId: 'l1_' + l1Id,
            l1: l1,
            positionInCarousel: courseOrL1.position,
          };

          if (newCourseL1 && !insertedCourses[newCourseL1.courseId] && l1Map?.[l1Id]) {
            //newCoursesList.push(newCourseL1);
            newCoursesList.push({...newCourseL1, contentId: courseOrL1?.contentId});
            insertedCourses[newCourseL1.courseId] = true;
            numFallbackImage++;
          }
        }
      });
    }

    return newCoursesList.filter(course => !!course);
  }
);

// dispatch(getL1Images(channels, brands));

export const getIdsFromTeasersHeroBanner = (coursesL1: CourseIdL1[]): HPCarouselCourseIdL1 => {
  const ids: HPCarouselCourseIdL1["ids"] = [];
  const l1Ids: {l1Id: string, contentId: number}[] = [];

  coursesL1?.forEach((course, index) => {
    const courseId = course?.teaserTitle;
    let l1Id = course?.subjectTaxonomy?.[0]?.externalReference;
    const courseContentId = course?.contentId;
    const l1ContentId = course?.subjectTaxonomy?.[0]?.contentId;

    if (l1Id?.includes('university-')) {
      //from CMS may arrive brands in the format university-<brand id> but we use brand_<brand id>
      l1Id = l1Id.replace('university-', 'brand_');
    }
    // console.log("l1Id", l1Id);
    if (courseId || l1Id) {
      ids.push({
        id: courseId || l1Id,
        l1: !course?.teaserTitle,
        position: index + 1,
        contentId: courseId ? courseContentId : l1ContentId
      });
      if (!courseId && l1Id) {
        l1Ids.push({
          l1Id: l1Id,
          contentId: l1ContentId,
        });
      }
    }
  });

  return {
    ids,
    l1Ids,
  };
};

export const selectRecentlyAddedIds = createSelector(
  (state: RootState) => state.cms.heroBannerInfo,
  heroBannerInfo => {
    let CourseIdL1 = [];

    if (heroBannerInfo?.[0]?.placements) {
      for (let placement of Object.values(heroBannerInfo?.[0]?.placements)) {
        if (placement?.[0]?.link?.includes('recently_added')) {
          CourseIdL1 = placement?.[0]?.extendedFields?.CourseIdL1 || [];
          break;
        }
      }
    }

    const idsFromTeaser = getIdsFromTeasersHeroBanner(CourseIdL1);
    return idsFromTeaser;
  }
);

export const selectHighlightedCourses = createSelector(
  (state: RootState) => state.course.coursesMap,
  state => state.course.isGetCourseCompleted,
  (coursesMap, isGetCourseCompleted) => {
    if (!coursesMap || !isGetCourseCompleted) {
      return [];
    }
    const coursesToShowTemp = Object.values(coursesMap).filter(
      a => isCourseVisible(a) && isHighlighted(a)
    );

    const courses = coursesToShowTemp.filter(
      a => isL1IdBrand(a.catalogTypes[0]) && a.level2?.includes('1017')
    );

    const coursesSorted = multipleSortCourses(courses, [SORTBY.TITLE_AZ, SORTBY.START_DATE]);

    return coursesSorted;
  }
);

export const selectBrowseByTopicsIds = createSelector(
  (state: RootState) => state.cms.heroBannerInfo,
  state => state.course.coursesMap,
  (heroBannerInfo, coursesMap) => {
    let CourseIdL1 = [];

    if (heroBannerInfo?.[0]?.placements) {
      for (let placement of Object.values(heroBannerInfo?.[0]?.placements)) {
        if (
          placement?.[0]?.extendedFields?.layout?.[0]?.layout === CMSCarouselLayout.BROWSE_BY_TOPIC
        ) {
          CourseIdL1 = placement?.[0]?.extendedFields?.CourseIdL1 || [];
          break;
        }
      }
    }

    const idsFromTeaser = getIdsFromTeasersHeroBanner(CourseIdL1);

    const l1CatalogList = [];
    if (coursesMap) {
      for (let course of Object.values(coursesMap)) {
        if (isCourseVisible(course)) {
          course.catalogTypes.forEach(l1Id => {
            if (!l1CatalogList.includes(l1Id)) {
              l1CatalogList.push(l1Id);
            }
          });
        }
      }
    }

    //show L1 if user has at least one course associated to it
    idsFromTeaser.ids = idsFromTeaser.ids.filter(a => l1CatalogList.includes(a.id));
    idsFromTeaser.l1Ids = idsFromTeaser.l1Ids.filter(a => l1CatalogList.includes(a.l1Id));

    return idsFromTeaser;
  }
);

export class selectBannerHpLpDataResponse {
  courseIdMaster: string = '';
  image: string = '';
  imageContentId?: number;
  itemContentId?: number;
  courseIdMasterContentId?: number;
}
export const selectBannerHpLpData = createSelector(
  (state: RootState) => state.course.coursesMap,
  (state, placements) => placements,
  (coursesMap, placements: RowTeasers[]): selectBannerHpLpDataResponse => {
    // @todo remove mock
    const mockData = {
      courseIdMaster: 'lp1725',
      image: 'https://im-uat-multiplatform-cms.luxottica.com/coremedia10delivery/image/28906',
      isMaster: true,
    };
    const showFallbackMock = false;

    const data: selectBannerHpLpDataResponse[] = [];

    if (!placements?.length) {
      return showFallbackMock ? mockData : new selectBannerHpLpDataResponse();
    }

    for (const item of placements) {
      const rowItem = getIdsFromTeasersHeroBanner(item.extendedFields.CourseIdL1 || []);

      if (!rowItem) {
        continue;
      }

      //get !masters || (masters && (not started || started+confirmed+purchased+activated))
      const now = new Date();
      const filteredIDs = rowItem.ids.filter(item => {
        const course = coursesMap?.[item.id];
        return (
          item.id &&
          isCourseVisible(course) &&
          (!isCourseMaster(course) ||
            now < new Date(course.masterAttributes?.mcStartDate) ||
            (isPurchased(course) && canMasterBeAccessed(course)))
        );
      });

      const image = item.extendedFields.image?.[0]?.data;
      const imageContentId = item.extendedFields.image?.[0]?.contentId;
      const itemContentId = item?.contentId;

      if (filteredIDs?.length > 0) {
        const course = coursesMap?.[filteredIDs[0].id];
        data.push({
          itemContentId: itemContentId,
          courseIdMaster: filteredIDs[0].id,
          courseIdMasterContentId: filteredIDs[0].contentId,
          image: image || course?.courseOverviewFile || '', //use CMS image if present, otherwise use course image
          imageContentId: image ? imageContentId : null,
        });
      }
    }

    //console.log("selectBannerHpLpData", data)
    return data?.length > 0 ? data[0] : new selectBannerHpLpDataResponse();
  }
);

export const selectPrograms = createSelector(
  (state: RootState) => state.course.coursesMap,
  coursesMap => {
    let programs: Course[] = [];

    if (!coursesMap) {
      return [];
    }
    Object.values(coursesMap).forEach(course => {
      //user must have at least one educational path excluding the onboarding retail
      if (isCourseVisible(course) && isCourseMaster(course)) {
        programs.push(course);
      }
    });

    const sortings = [
      SORTBY.START_DATE,
      SORTBY.COMPLEXITY_DESC,
      SORTBY.PRICE_DES,
      SORTBY.NOT_COMPLETED,
      SORTBY.IN_PROGRESS,
    ];

    programs = multipleSortCourses(programs, sortings);

    return programs;
  }
);

export const selectCareerMasters = createSelector(
  (state: RootState) => state.course.coursesMap,
  coursesMap => {
    let careerMasters: Course[] = [];

    if (!coursesMap) {
      return [];
    }
    Object.values(coursesMap).forEach(course => {
      // Check if course is master and is a career master course
      if (isCourseVisible(course) && isCourseMaster(course) && isCourseCareerMaster(course)) {
        careerMasters.push(course);
      }
    });

    const sortings = [
      SORTBY.START_DATE,
      SORTBY.COMPLEXITY_DESC,
      SORTBY.PRICE_DES,
      SORTBY.NOT_COMPLETED,
      SORTBY.IN_PROGRESS,
    ];

    careerMasters = multipleSortCourses(careerMasters, sortings);

    return careerMasters;
  }
);
