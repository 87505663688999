import { useState, useEffect } from "react";
import { useSelector } from "@hooks/redux";
import { isCourseCareer } from "@utility/buildYourCareerUtility";
import { LevelEnrichedMap } from "@model/CoursesClass";
import { LEVELS_AREA } from "@utility/const";
import { allowedEducationalCatalogBycL1s } from "./useL0BycOffice";

const useShowBuildYourCareerMenuHeader = () => {
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const labelL0Map: LevelEnrichedMap = useSelector((state) => state.utils.labelL0Map);
  const isBycUser = useSelector(state => state.user.isBycUser);
  const isBycOffice = useSelector(state => state.user.isBycOffice);

  const [showMenu, setShowMenu] = useState<boolean>(null);

  useEffect(() => {

    if(isBycUser !== undefined 
      && isBycUser !== null 
      && isBycOffice !== undefined 
      && isBycOffice !== null 
    ) {
      
      if (coursesMap && labelL0Map && Object?.values(labelL0Map)?.length > 0) {

        // check for byc office
        if (isBycOffice) {
          const l0sBycOffice = Object?.values(labelL0Map).filter(
            l0 =>
              l0.area === LEVELS_AREA.BUILD_YOUR_CAREER_UP_OFFICE ||
              l0.area === LEVELS_AREA.EDUCATIONAL_CATALOG_BYC
          );
          setShowMenu(
            Object.values(coursesMap).some(course =>
              l0sBycOffice.some(l0 => {
                if (l0.area === LEVELS_AREA.EDUCATIONAL_CATALOG_BYC)
                  return (
                    l0.key === course.level0 &&
                    course?.catalogTypes.some(l1 => allowedEducationalCatalogBycL1s?.includes(l1))
                  );
                return course?.catalogTypes.some(l1 =>
                  l0.key === course.level0
                );
              })
            )
          );
        }

        // check for byc standard
        if(isBycUser) {
          setShowMenu(Object.values(coursesMap).some(course => isCourseCareer(course)))
        }

      }
    }
    
  }, [coursesMap,labelL0Map,isBycUser,isBycOffice])

  return [showMenu];
};

export default useShowBuildYourCareerMenuHeader;
