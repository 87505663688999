import React from "react";
import CircleAlert from "@svg-icons/circle-alert.svg";
import Close from "@svg-icons/close.svg";
import { LangMapWcag } from "@model/CoursesClass";
import useLangAuth from "@hooks/useLangAuth";

type Props = {
  message?: string;
  variant: "error" | "success";
  className?: string;
  children: React.ReactNode;
  focusOnMount?: boolean;
  close: () => void;
};


const Popup = (props: Props) => {
  
  const langKeys: Readonly<(keyof LangMapWcag)[]> = [
    "CLOSE_POPUP",
  ] as const;

  const labels = useLangAuth(langKeys);

  const popupRef = React.useRef(null);

  React.useEffect(() => {
    if(props.focusOnMount) {
      popupRef.current.focus();
    }
  }, [props.focusOnMount])
  
  return (
    <div className={"popup-v3 " + (props.className ? props.className : '')} tabIndex={0} role="dialog" ref={popupRef}>
      <div className="flex width-100 justify-center">
        {/*props.message*/}
        <div className="margin-auto-vertical flex">
          {
            <span className={`alert-${props.variant}`}>
              <CircleAlert fill="currentColor" />
            </span>
          }
          <div className="margin-auto-vertical">
            {props.children}
          </div>
        </div>
      </div>
      <button className="button-no-back popup-v3__close" aria-label={labels?.CLOSE_POPUP} onClick={props.close}>
        <Close />
      </button>
    </div>
  );
};

export default Popup;
