import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@hooks/redux";
import { PlanModel } from "@model/PlanModel";
import RenewalBox from "@components/RenewalBox";
import ModalChoosePayment from "./ModalChoosePayment";
import ModalEvolution from "./ModalEvolution";

type ModalStartAutorenewalProps = {
  show: boolean;
  close: () => void;
  plan: PlanModel;
  data: { orderId: string, subscriptionId: string };
};

const ModalStartAutorenewal = ({
  show,
  close,
  plan,
  data,
}: ModalStartAutorenewalProps) => {
  const dispatch = useDispatch();
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleConfirmAutorenewal = () => {
    //close modals
    setShowPaymentModal(true);
  };

  const handleCloseModalChoosePayment = () => {
    setShowPaymentModal(false);
    close();
  }


  return (
    <>
      <ModalEvolution
        close={close}
        show={show && !showPaymentModal}
        hideX={true}
        modalClassName="modal-start-autorenewal overflow-auto"
        title={lang?.MODAL_START_AUTORENEWAL_TITLE}
        descriptionElement={
          <RenewalBox toggleActiveParent={true} isModal />
        }
        mainButtonLabel={lang?.CONFIRM}
        mainButtonAction={handleConfirmAutorenewal}
        mainButtonIsLoading={showPaymentModal}
        secondaryButtonLabel={lang?.CANCEL}
        secondaryButtonAction={close}
      />
      <ModalChoosePayment data={data} plan={plan} show={showPaymentModal} close={handleCloseModalChoosePayment} />
    </>
  );
};

export default ModalStartAutorenewal;
