import React, {useState, useRef, useEffect} from 'react'
import {  printHTML, removeHTMLTags } from '@utility/Api';
import clsx from '@utility/clsx';

type Props ={
    label: string,
    show: boolean
}


const TooltipV2 = (props: Props)=>{
    const ref = useRef(null);

    
    const calculateOnResize = ()=>{
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect()
            if (rect.left < 0) {
                ref.current.style.setProperty('--left', '6%');
            }
            else if (document.body.clientWidth - rect.right < 0) {
                ref.current.style.setProperty('--left', '94%');
            }
            else {
                ref.current.style.setProperty('--left', '50%');
            }
        }
    }

    useEffect(()=>{
        calculateOnResize()
    },[props.show])

    return (
        <div className={clsx("tooltipV2", props.show && 'tooltipV2--shown')} ref={ref}>
            <div dangerouslySetInnerHTML={printHTML(props.label)} aria-label={removeHTMLTags(props.label)} />
            <div className="tooltipV2-arrow" />
        </div>
    )
}

export default TooltipV2;