import React from "react";


export type Props = {
  className?: string;
  label: string;
  color?: string;
  variant?: string;
};

const BadgeCourse = ({ className = '', label, color = '', variant = '' }: Props) => {

  return (
    <section className={"card-v3__badge " + className + ' ' + color + ' ' + variant} aria-label={label}>
      <span>{label}</span>
    </section>
  );
};

export default BadgeCourse;
