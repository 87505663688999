import { useEffect, useRef } from 'react';

const useOnClickOutside = (handleClickOutside) => {
  const ref = useRef(null);

  const onClick = (event) => {
    if (ref.current && !ref.current.contains(event.target) && handleClickOutside) {
      handleClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, []);

  return { clickOutsideRef: ref };
};

export default useOnClickOutside;