import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/Api';
import { TrainingPill, ProductCloseUp, ProductCMS, SingleWeekSummary } from "@model/ProductClass";
import { CollectionsBrandModalInfo } from '@model/collections/CollectionsBrandModalInfo';
import { BrandCampaignsMap, CollectionInfoMapModel, CollectionStoriesMapModel, LatestCollectionCarouselsModel, PillsFromSameStoryMapModel } from '@model/collections/CollectionModel';
import { StoryModalInfo } from '@model/collections/StoryModalInfo';
import { LatestCollectionsCarouselInfo } from '@model/collections/LatestCollectionsCarouselInfo';
import { LatestCollectionsCMSCarouselInfo } from '@model/collections/LatestCollectionsCMSCarouselInfo';
import { LatestCollectionsCatalogueClass } from '@model/collections/LatestCollectionsCatalogueClass';
import { BrandCollectionCatalogueMapClass } from '@model/collections/BrandCollectionCatalogueClass';
import { getBrandCampaigns } from '../actions/trainingPillsActions';

class InitialStateProps {
  // homepage training pills 
  trainingPillsHP: TrainingPill[] = [];
  isLoadingTrainingPillsHP: boolean = false;
  isTrainingPillsHPCompleted: boolean = false;
  //training pills details
  trainingPillsDetail: { [key: string]: ProductCMS } = {};
  isTrainingPillsDetailLoading: boolean = false;
  isTrainingPillsDetailCompleted: boolean = false;
  // showTrainingPillDetailModal: boolean = false;
  // currentTrainingPillsStack: string[] = [];
  // currentTrainingPill: string = null;
  // close up training pills 
  trainingPillsCloseUp: { [key: string]: ProductCloseUp[] } = {};
  isTrainingPillCloseUpLoading: boolean = false;
  isTrainingPillCloseUpCompleted: boolean = false;
  trainingPillsAdvCampaign: { [key: string]: ProductCloseUp[] } = {};
  trainingPillsAdvCampaignLoading: boolean = false;
  trainingPillsAdvCampaignCompleted: boolean = false;
  // brand page training pills  carousel
  trainingPillsBrandCarousel: TrainingPill[] = [];
  isLoadingTrainingPillsBrandCarousel: boolean = false;
  isTrainingPillsBrandCarouselCompleted: boolean = false;
  //catalogue training pills
  TPCatalogueTitle: string = '';
  TPCatalogueDescription: string = '';
  trainingPillsCatalogue: TrainingPill[] = [];
  trainingPillsCatalogueToNumber: number = 0;
  trainingPillsCatalogueTotalNumber: number = 0;
  isLoadingTrainingPillsCatalogue: boolean = false;
  isTrainingPillsCatalogueCompleted: boolean = false;

  trainingPillsWeeklySummary: SingleWeekSummary[] = [];
  isLoadingTrainingPillsWeeklySummary: boolean = false;
  isTrainingPillsWeeklySummaryCompleted: boolean = false;

  //catalogue novelties
  noveltiesCatalogue: TrainingPill[] = [];
  noveltiesCatalogueFromNumber: number = 0;
  noveltiesCatalogueToNumber: number = 0;
  noveltiesCatalogueTotalNumber: number = 0;
  isLoadingNoveltiesCatalogue: boolean = false;
  isNoveltiesCatalogueCompleted: boolean = false;

  //force filter updating --> used when the user removes a TP from wishlist when he is in the wishlist page
  forceFilterUpdate: boolean = false;

  // wishlist training pills carousel
  trainingPillsWishlistCarousel: TrainingPill[] = [];
  isLoadingTrainingPillsWishlistCarousel: boolean = false;
  isTrainingPillsWishlistCarouselCompleted: boolean = false;

  //latest collection - brand modal
  collectionsBrandModalInfo: CollectionsBrandModalInfo = new CollectionsBrandModalInfo();

  //collection page - story modal
  storyModalInfo: StoryModalInfo = new StoryModalInfo();

  //latest collections page
  latestCollections: LatestCollectionCarouselsModel[] = [];
  latestCollectionsLanguage: string = undefined;
  isLoadingLatestCollections: boolean = false;
  isLatestCollectionsCompleted: boolean = false;
  latestCollectionsCatalogue: LatestCollectionsCatalogueClass = new LatestCollectionsCatalogueClass();
  //single collection
  collectionInfoMap: CollectionInfoMapModel = {};
  collectionStoriesMap: CollectionStoriesMapModel = {};
  brandCollectionCatalogueMap: BrandCollectionCatalogueMapClass = {};
  //latest collections carousel
  latestCollectionsCarouselInfo: LatestCollectionsCarouselInfo = new LatestCollectionsCarouselInfo();
  //latest collections CMS carousel
  latestCollectionsCMSCarouselInfo: LatestCollectionsCMSCarouselInfo = new LatestCollectionsCMSCarouselInfo();
  //pills from the same collections
  pillsFromSameStoryMap: PillsFromSameStoryMapModel = {};

  mocoInfo = null;
  isLoadingMoco: boolean = false;
  //COLLECTION WAVE 3 banner 
  brandCampaignsMapState: BrandCampaignsMap = {};
  isBrandCampaignsBannerLoading: boolean = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const getTrainingPillsHPRequest = state => {
  return updateObject(state, {
    isLoadingTrainingPillsHP: true,
    isTrainingPillsHPCompleted: false,
  })
}

const getTrainingPillsHPSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsHP: action.trainingPillsHP,
    isLoadingTrainingPillsHP: false,
    isTrainingPillsHPCompleted: true,
  })
}

const getTrainingPillsHPFailure = state => {
  return updateObject(state, {
    isLoadingTrainingPillsHP: false,
    isTrainingPillsHPCompleted: true
  })
}


const getTrainingPillDetailRequest = state => {
  return updateObject(state, {
    isTrainingPillsDetailLoading: true,
    isTrainingPillsDetailCompleted: false,
  })
}

const getTrainingPillDetailSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsDetail: {
      ...state.trainingPillsDetail,
      [action.model]: action.trainingPillDetail
    },
    isTrainingPillsDetailLoading: false,
    isTrainingPillsDetailCompleted: true,
  })
}

const getTrainingPillDetailFailure = state => {
  return updateObject(state, {
    isTrainingPillsDetailLoading: false,
    isTrainingPillsDetailCompleted: true,
  })
}


const getTrainingPillCloseUpRequest = state => {
  return updateObject(state, {
    isTrainingPillCloseUpLoading: true,
    isTrainingPillCloseUpCompleted: false,
  })
}

const getTrainingPillCloseUpSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsCloseUp: {
      ...state.trainingPillsCloseUp,
      [action.model]: action.trainingPillCloseUp
    },
    isTrainingPillCloseUpLoading: false,
    isTrainingPillCloseUpCompleted: true,
  })
}

const getTrainingPillCloseUpFailure = state => {
  return updateObject(state, {
    isTrainingPillCloseUpLoading: false,
    isTrainingPillCloseUpCompleted: true,
  })
}
const getTrainingPillAdvCampaignRequest = state => {
  return updateObject(state, {
    isTrainingPillAdvCampaignLoading: true,
    isTrainingPillAdvCampaignCompleted: false,
  })
}

const getTrainingPillAdvCampaignSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsAdvCampaign: {
      ...state.trainingPillsAdvCampaign,
      [action.model]: action.trainingPillAdvCampaign
    },
    isTrainingPillAdvCampaignLoading: false,
    isTrainingPillAdvCampaignCompleted: true,
  })
}

const getTrainingPillAdvCampaignFailure = state => {
  return updateObject(state, {
    isTrainingPillAdvCampaignLoading: false,
    isTrainingPillAdvCampaignCompleted: true,
  })
}

const getTrainingPillsBrandCarouselRequest = state => {
  return updateObject(state, {
    isLoadingTrainingPillsBrandCarousel: true,
    isTrainingPillsBrandCarouselCompleted: false,
  })
}

const getTrainingPillsBrandCarouselSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsBrandCarousel: action.trainingPillsBrandCarousel,
    isLoadingTrainingPillsBrandCarousel: false,
    isTrainingPillsBrandCarouselCompleted: true,
  })
}

const getTrainingPillsBrandCarouselFailure = state => {
  return updateObject(state, {
    isLoadingTrainingPillsBrandCarousel: false,
    isTrainingPillsBrandCarouselCompleted: true,
  })
}

const resetTrainingPillsBrandCarousel = state => {
  return updateObject(state, {
    trainingPillsBrandCarousel: [],
    isLoadingTrainingPillsBrandCarousel: false,
    isTrainingPillsBrandCarouselCompleted: false,
  })
}

const setTPCatalogueTitle = (state, action) => {
  return updateObject(state, {
    TPCatalogueTitle: action.TPCatalogueTitle,
    TPCatalogueDescription: action.TPCatalogueDescription,
  })
}

const getTrainingPillsCatalogueRequest = (state, action) => {
  let isTrainingPillsCatalogueCompleted: boolean = true;
  //if no one training pills is downloaded yet --> isCompleted = false
  if (action.trainingPillsCatalogueFromNumber === 0) {
    isTrainingPillsCatalogueCompleted = false;
  }

  return updateObject(state, {
    isLoadingTrainingPillsCatalogue: true,
    isTrainingPillsCatalogueCompleted: isTrainingPillsCatalogueCompleted,
    forceFilterUpdate: false,
  })
}

const getTrainingPillsCatalogueSuccess = (state, action) => {
  let trainingPillsCatalogue: TrainingPill[] = state.trainingPillsCatalogue;
  const trainingPillsCatalogueFromNumber: number = action.trainingPillsCatalogueFromNumber;
  if (trainingPillsCatalogueFromNumber === 0) {
    trainingPillsCatalogue = action.trainingPillsCatalogue;
  } else {
    trainingPillsCatalogue = trainingPillsCatalogue.concat(action.trainingPillsCatalogue);
  }


  return updateObject(state, {
    trainingPillsCatalogue: trainingPillsCatalogue,
    trainingPillsCatalogueToNumber: action.trainingPillsCatalogueToNumber,
    trainingPillsCatalogueTotalNumber: action.trainingPillsCatalogueTotalNumber,
    isLoadingTrainingPillsCatalogue: false,
    isTrainingPillsCatalogueCompleted: true,
  })
}

const getTrainingPillsCatalogueFailure = state => {
  return updateObject(state, {
    isLoadingTrainingPillsCatalogue: false,
    isTrainingPillsCatalogueCompleted: true,
  })
}

const resetTrainingPillsCatalogue = state => {
  return updateObject(state, {
    trainingPillsCatalogue: [],
    trainingPillsCatalogueToNumber: 0,
    trainingPillsCatalogueTotalNumber: 0,
    isLoadingTrainingPillsCatalogue: false,
    isTrainingPillsCatalogueCompleted: false,
  })
}

const getNoveltiesCatalogueRequest = (state, action) => {
  let isTrainingPillsCatalogueCompleted: boolean = true;
  //if no one training pills is downloaded yet --> isCompleted = false
  if (action.trainingPillsCatalogueFromNumber === 0) {
    isTrainingPillsCatalogueCompleted = false;
  }

  return updateObject(state, {
    isLoadingTrainingPillsCatalogue: true,
    isTrainingPillsCatalogueCompleted: isTrainingPillsCatalogueCompleted,
    forceFilterUpdate: false,
  })
}

const getNoveltiesCatalogueSuccess = (state, action) => {
  let noveltiesCatalogue: TrainingPill[] = state.noveltiesCatalogue || [];
  const noveltiesCatalogueFromNumber: number = action.noveltiesCatalogueFromNumber;
  if (noveltiesCatalogueFromNumber === 0) {
    noveltiesCatalogue = action.noveltiesCatalogue;
  } else {
    noveltiesCatalogue = noveltiesCatalogue.concat(action.noveltiesCatalogue);
  }


  return updateObject(state, {
    noveltiesCatalogue: noveltiesCatalogue,
    noveltiesCatalogueToNumber: action.noveltiesCatalogueToNumber,
    noveltiesCatalogueTotalNumber: action.noveltiesCatalogueTotalNumber,
    isLoadingNoveltiesCatalogue: false,
    isNoveltiesCatalogueCompleted: true,
  })
}

const getNoveltiesCatalogueFailure = state => {
  return updateObject(state, {
    isLoadingNoveltiesCatalogue: false,
    isNoveltiesCatalogueCompleted: true,
  })
}

const resetNoveltiesCatalogue = state => {
  return updateObject(state, {
    noveltiesCatalogue: [],
    noveltiesCatalogueToNumber: 0,
    noveltiesCatalogueTotalNumber: 0,
    isLoadingNoveltiesCatalogue: false,
    isNoveltiesCatalogueCompleted: false,
  })
}

// const openTrainingPillDetailModal = (state, action) => {
//   let showTrainingPillDetailModal = action.showTrainingPillDetailModal;
//   const newState = {
//     showTrainingPillDetailModal: false,
//     currentTrainingPillsStack: cloneDeep(state.currentTrainingPillsStack),
//     currentTrainingPill: null,
//   };

//   if (action.closeAll) {
//     newState.showTrainingPillDetailModal = false;
//     newState.currentTrainingPill = null;
//     newState.currentTrainingPillsStack = [];
//   } else {
//     if (showTrainingPillDetailModal && action.trainingPill) {
//       if (state.currentTrainingPill) {
//         newState.currentTrainingPillsStack.push(state.currentTrainingPill)
//       }
//       newState.showTrainingPillDetailModal = showTrainingPillDetailModal;
//       newState.currentTrainingPill = action.trainingPill;
//     } else {
//       if (state.currentTrainingPillsStack.length > 0) {
//         newState.currentTrainingPill = newState.currentTrainingPillsStack.pop();
//         newState.showTrainingPillDetailModal = true;
//       } else {
//         newState.currentTrainingPill = null;
//         newState.showTrainingPillDetailModal = showTrainingPillDetailModal;
//       }
//     }
//   }

//   return updateObject(state, newState);
// }


const getTrainingPillsWeeklySummarySuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsWeeklySummary: action.weeklySummary,
    isLoadingTrainingPillsWeeklySummary: false,
    isTrainingPillsWeeklySummaryCompleted: true,
  } as Partial<InitialStateProps>)
}

const getTrainingPillsWeeklySummaryFailure = state => {
  return updateObject(state, {
    isLoadingTrainingPillsWeeklySummary: false,
  } as Partial<InitialStateProps>)
}


const getTrainingPillsWeeklySummaryRequest = state => {
  return updateObject(state, {
    isLoadingTrainingPillsWeeklySummary: true,
    isTrainingPillsWeeklySummaryCompleted: false,
  } as Partial<InitialStateProps>)
}

const updateTrainingPill = (state, action) => {
  let newTp = action.newTp;
  const trainingPillsHP = updateTrainingPillInArray(newTp, state.trainingPillsHP);
  const trainingPillsBrandCarousel = updateTrainingPillInArray(newTp, state.trainingPillsBrandCarousel);
  const trainingPillsCatalogue = updateTrainingPillInArray(newTp, state.trainingPillsCatalogue);
  const trainingPillsWishlistCarousel = updateTrainingPillInArray(newTp, state.trainingPillsWishlistCarousel);

  //update current detail TP if the wishlist is not started by the detail TP
  let trainingPillsDetail = cloneDeep(state.trainingPillsDetail);
  let trainingPillsDetailToUpdate = trainingPillsDetail?.[newTp.model];

  if (trainingPillsDetailToUpdate) {

    trainingPillsDetail[newTp.model] = {
      ...trainingPillsDetailToUpdate,
      ...newTp
    };
  }


  return updateObject(state, {
    trainingPillsHP,
    trainingPillsBrandCarousel,
    trainingPillsCatalogue,
    trainingPillsWishlistCarousel,
    trainingPillsDetail,
  })
}

const updateTrainingPillInArray = (newTp: TrainingPill, trainingPills: TrainingPill[]) => {
  if (!trainingPills) {
    return [];
  }
  if (!newTp) {
    return trainingPills;
  }

  //check if the new TP is present
  const index = trainingPills.findIndex(a => a.model === newTp.model);
  if (index >= 0) {
    //update pill in array
    let newTrainingPills = cloneDeep(trainingPills);
    newTrainingPills[index] = {
      ...newTrainingPills[index],
      ...cloneDeep(newTp),
    }
    return newTrainingPills;
  } else {
    return trainingPills;
  }
}

const setForceFilterUpdate = (state, action) => {
  return updateObject(state, {
    forceFilterUpdate: action.forceFilterUpdate
  });
}

const getTrainingPillsWishlistCarouselRequest = state => {
  return updateObject(state, {
    isLoadingTrainingPillsWishlistCarousel: true,
    isTrainingPillsWishlistCarouselCompleted: false,
  })
}

const getTrainingPillsWishlistCarouselSuccess = (state, action) => {
  return updateObject(state, {
    trainingPillsWishlistCarousel: action.trainingPillsWishlistCarousel,
    isLoadingTrainingPillsWishlistCarousel: false,
    isTrainingPillsWishlistCarouselCompleted: true,
  })
}

const getTrainingPillsWishlistCarouselFailure = state => {
  return updateObject(state, {
    isLoadingTrainingPillsWishlistCarousel: false
  })
}

const setCollectionsBrandModalInfo = (state, action) => {
  return updateObject(state, {
    collectionsBrandModalInfo: action.collectionsBrandModalInfo,
  })
}

const setStoryModalInfo = (state, action) => {
  return updateObject(state, {
    storyModalInfo: action.storyModalInfo,
  })
}

const getLatestCollectionsRequest = state => {
  return updateObject(state, {
    isLoadingLatestCollections: true,
  })
}

const getLatestCollectionsSuccess = (state, action) => {
  return updateObject(state, {
    latestCollections: action.latestCollections,
    latestCollectionsLanguage: action.language,
    isLoadingLatestCollections: false,
    isLatestCollectionsCompleted: true,
  })
}

const getLatestCollectionsFailure = state => {
  return updateObject(state, {
    isLoadingLatestCollections: false,
    isLatestCollectionsCompleted: true,
  })
}

const getCollectionInfoRequest = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionInfoMap: {
      ...state.collectionInfoMap,
      [collectionBrand]: {
        ...state.collectionInfoMap[collectionBrand],
        isLoading: true,
      },
    },
  })
}

const getCollectionInfoSuccess = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionInfoMap: {
      ...state.collectionInfoMap,
      [collectionBrand]: {
        ...state.collectionInfoMap[collectionBrand],
        collection: action.collection,
        language: action.language,
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getCollectionInfoFailure = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionInfoMap: {
      ...state.collectionInfoMap,
      [collectionBrand]: {
        ...state.collectionInfoMap[collectionBrand],
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getCollectionStoriesRequest = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionStoriesMap: {
      ...state.collectionStoriesMap,
      [collectionBrand]: {
        ...state.collectionStoriesMap[collectionBrand],
        isLoading: true,
      },
    },
  })
}

const getCollectionStoriesSuccess = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionStoriesMap: {
      ...state.collectionStoriesMap,
      [collectionBrand]: {
        ...state.collectionStoriesMap[collectionBrand],
        collection: action.collection,
        language: action.language,
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getCollectionStoriesFailure = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    collectionStoriesMap: {
      ...state.collectionStoriesMap,
      [collectionBrand]: {
        ...state.collectionStoriesMap[collectionBrand],
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getNoveltiesRequest = (state) => {
  return updateObject(state, {
    latestCollectionsCarouselInfo: {
      ...state.latestCollectionsCarouselInfo,
      isLoading: true,
    },
  })
}

const getNoveltiesSuccess = (state, action) => {
  return updateObject(state, {
    latestCollectionsCarouselInfo: {
      ...state.latestCollectionsCarouselInfo,
      collection: action.collection,
      language: action.language,
      isLoading: false,
      isCompleted: true,
    },
  })
}

const getNoveltiesFailure = (state) => {
  return updateObject(state, {
    latestCollectionsCarouselInfo: {
      ...state.latestCollectionsCarouselInfo,
      isLoading: false,
      isCompleted: true,
    },
  })
}

const getNoveltiesCMSCarouselRequest = (state) => {
  return updateObject(state, {
    latestCollectionsCMSCarouselInfo: {
      ...state.latestCollectionsCMSCarouselInfo,
      isLoading: true,
    },
  })
}

const getNoveltiesCMSCarouselSuccess = (state, action) => {
  return updateObject(state, {
    latestCollectionsCMSCarouselInfo: {
      ...state.latestCollectionsCMSCarouselInfo,
      collections: action.collections,
      language: action.language,
      isLoading: false,
      isCompleted: true,
    },
  })
}

const getNoveltiesCMSCarouselFailure = (state) => {
  return updateObject(state, {
    latestCollectionsCMSCarouselInfo: {
      ...state.latestCollectionsCMSCarouselInfo,
      isLoading: false,
      isCompleted: true,
    },
  })
}

const getPillsFromSameStoryRequest = (state, action) => {
  return updateObject(state, {
    pillsFromSameStoryMap: {
      ...state.pillsFromSameStoryMap,
      [action.pillModel]: {
        ...state.pillsFromSameStoryMap[action.pillModel],
        isLoading: true,
      },
    },
  })
}

const getPillsFromSameStorySuccess = (state, action) => {
  return updateObject(state, {
    pillsFromSameStoryMap: {
      ...state.pillsFromSameStoryMap,
      [action.pillModel]: {
        ...state.pillsFromSameStoryMap[action.pillModel],
        pills: action.pills,
        language: action.language,
        isLoading: false,
        isCompleted: true,
        storyId: action.storyId,
      }
    },
  })
}

const getPillsFromSameStoryFailure = (state, action) => {
  return updateObject(state, {
    pillsFromSameStoryMap: {
      ...state.pillsFromSameStoryMap,
      [action.pillModel]: {
        ...state.pillsFromSameStoryMap[action.pillModel],
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}


const getLatestCollectionsCatalogueRequest = (state) => {
  return updateObject(state, {
    latestCollectionsCatalogue: {
      ...state.latestCollectionsCatalogue,
      isLoading: true,
    },
  })
}

const getLatestCollectionsCatalogueSuccess = (state, action) => {
  return updateObject(state, {
    latestCollectionsCatalogue: {
      ...state.latestCollectionsCatalogue,
      filters: action.latestCollectionsCatalogue.responseFilter,
      collections: action.latestCollectionsCatalogue.collectionNoveltiesList,
      isLoading: false,
      isCompleted: true,
      language: action.language,
    },
  })
}

const getLatestCollectionsCatalogueFailure = (state) => {
  return updateObject(state, {
    latestCollectionsCatalogue: {
      ...state.latestCollectionsCatalogue,
      isLoading: false,
      isCompleted: true,
    },
  })
}

const getBrandCollectionCatalogueRequest = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    brandCollectionCatalogueMap: {
      ...state.brandCollectionCatalogueMap,
      [collectionBrand]: {
        ...state.brandCollectionCatalogueMap[collectionBrand],
        isLoading: true,
      },
    },
  })
}

const getBrandCollectionCatalogueSuccess = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    brandCollectionCatalogueMap: {
      ...state.brandCollectionCatalogueMap,
      [collectionBrand]: {
        ...state.brandCollectionCatalogueMap[collectionBrand],
        filters: action.brandCollectionCatalogue.responseFilter,
        stories: action.brandCollectionCatalogue.collectionInfoStories?.[0],
        language: action.language,
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getBrandCollectionCatalogueFailure = (state, action) => {
  const collectionBrand = action.collectionId + "_" + action.brandId;
  return updateObject(state, {
    brandCollectionCatalogueMap: {
      ...state.brandCollectionCatalogueMap,
      [collectionBrand]: {
        ...state.brandCollectionCatalogueMap[collectionBrand],
        isLoading: false,
        isCompleted: true,
      }
    },
  })
}

const getBrandCampaignByMocoRequest = state => {
  return updateObject(state, {
    isLoadingMoco: true,
  })
}

const getBrandCampaignByMocoSuccess = (state, action) => {
  return updateObject(state, {
    mocoInfo: action.mocoInfo,
    isLoadingMoco: false,
  })
}

const getBrandCampaignByMocoFailure = state => {
  return updateObject(state, {
    isLoadingMoco: false,
  })
};

const getBrandCampaignsSuccess = (state, action) => {
  return updateObject(state, {
    brandCampaignsMapState: action.mapFromResponse,
    isBrandCampaignsBannerLoading: false
  })
}

const getBrandCampaignsFailure = (state, action) => {
  return updateObject(state, {
    brandCampaignsMapState: {},
    isBrandCampaignsBannerLoading: false
  })
}

const getBrandCampaignsRequest = (state, action) => {
  return updateObject(state, {
    isBrandCampaignsBannerLoading: true
  })
}


const logout = () => {
  return initialState;
}

const reducer = (state = initialState, action): InitialStateProps => {
  switch (action.type) {
    case actionTypes.GET_TP_HP_REQUEST: return getTrainingPillsHPRequest(state);
    case actionTypes.GET_TP_HP_SUCCESS: return getTrainingPillsHPSuccess(state, action);
    case actionTypes.GET_TP_HP_FAILURE: return getTrainingPillsHPFailure(state);
    case actionTypes.GET_TP_DETAIL_REQUEST: return getTrainingPillDetailRequest(state);
    case actionTypes.GET_TP_DETAIL_SUCCESS: return getTrainingPillDetailSuccess(state, action);
    case actionTypes.GET_TP_DETAIL_FAILURE: return getTrainingPillDetailFailure(state);
    case actionTypes.GET_TP_CLOSEUP_REQUEST: return getTrainingPillCloseUpRequest(state);
    case actionTypes.GET_TP_CLOSEUP_REQUEST_SUCCESS: return getTrainingPillCloseUpSuccess(state, action);
    case actionTypes.GET_TP_CLOSEUP_REQUEST_FAILURE: return getTrainingPillCloseUpFailure(state);
    case actionTypes.GET_TP_ADVCAMPAIGN_REQUEST: return getTrainingPillAdvCampaignRequest(state);
    case actionTypes.GET_TP_ADVCAMPAIGN_REQUEST_SUCCESS: return getTrainingPillAdvCampaignSuccess(state, action);
    case actionTypes.GET_TP_ADVCAMPAIGN_REQUEST_FAILURE: return getTrainingPillAdvCampaignFailure(state);
    case actionTypes.GET_TP_BRAND_REQUEST: return getTrainingPillsBrandCarouselRequest(state);
    case actionTypes.GET_TP_BRAND_SUCCESS: return getTrainingPillsBrandCarouselSuccess(state, action);
    case actionTypes.GET_TP_BRAND_FAILURE: return getTrainingPillsBrandCarouselFailure(state);
    case actionTypes.RESET_TP_BRAND: return resetTrainingPillsBrandCarousel(state);
    case actionTypes.SET_TP_CATALOGUE_TITLE: return setTPCatalogueTitle(state, action);
    case actionTypes.GET_TP_CATALOGUE_REQUEST: return getTrainingPillsCatalogueRequest(state, action);
    case actionTypes.GET_TP_CATALOGUE_SUCCESS: return getTrainingPillsCatalogueSuccess(state, action);
    case actionTypes.GET_TP_CATALOGUE_FAILURE: return getTrainingPillsCatalogueFailure(state);
    case actionTypes.RESET_TP_CATALOGUE: return resetTrainingPillsCatalogue(state);
    case actionTypes.GET_NOVELTIES_CATALOGUE_REQUEST: return getNoveltiesCatalogueRequest(state, action);
    case actionTypes.GET_NOVELTIES_CATALOGUE_SUCCESS: return getNoveltiesCatalogueSuccess(state, action);
    case actionTypes.GET_NOVELTIES_CATALOGUE_FAILURE: return getNoveltiesCatalogueFailure(state);
    case actionTypes.RESET_NOVELTIES_CATALOGUE: return resetNoveltiesCatalogue(state);
    // case actionTypes.SET_OPEN_TRAINING_PILL_DETAIL_MODAL: return openTrainingPillDetailModal(state, action);
    case actionTypes.GET_TP_SHIPMENT_DATES_REQUEST: return getTrainingPillsWeeklySummaryRequest(state);
    case actionTypes.GET_TP_SHIPMENT_WEEKLY_SUMMARY_SUCCESS: return getTrainingPillsWeeklySummarySuccess(state, action);
    case actionTypes.GET_TP_SHIPMENT_WEEKLY_SUMMARY_FAILURE: return getTrainingPillsWeeklySummaryFailure(state);
    case actionTypes.UPDATE_TP: return updateTrainingPill(state, action);
    case actionTypes.FORCE_FILTER_UPDATE: return setForceFilterUpdate(state, action);
    case actionTypes.GET_TP_WISH_CAROUSEL_REQUEST: return getTrainingPillsWishlistCarouselRequest(state);
    case actionTypes.GET_TP_WISH_CAROUSEL_SUCCESS: return getTrainingPillsWishlistCarouselSuccess(state, action);
    case actionTypes.GET_TP_WISH_CAROUSEL_FAILURE: return getTrainingPillsWishlistCarouselFailure(state);
    case actionTypes.SET_COLLECTIONS_BRAND_MODAL_INFO: return setCollectionsBrandModalInfo(state, action);
    case actionTypes.SET_STORY_MODAL_INFO: return setStoryModalInfo(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_REQUEST: return getLatestCollectionsRequest(state);
    case actionTypes.GET_LATEST_COLLECTIONS_SUCCESS: return getLatestCollectionsSuccess(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_FAILURE: return getLatestCollectionsFailure(state);
    case actionTypes.GET_COLLECTION_INFO_REQUEST: return getCollectionInfoRequest(state, action);
    case actionTypes.GET_COLLECTION_INFO_SUCCESS: return getCollectionInfoSuccess(state, action);
    case actionTypes.GET_COLLECTION_INFO_FAILURE: return getCollectionInfoFailure(state, action);
    case actionTypes.GET_COLLECTION_STORIES_REQUEST: return getCollectionStoriesRequest(state, action);
    case actionTypes.GET_COLLECTION_STORIES_SUCCESS: return getCollectionStoriesSuccess(state, action);
    case actionTypes.GET_COLLECTION_STORIES_FAILURE: return getCollectionStoriesFailure(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_REQUEST: return getNoveltiesRequest(state);
    case actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_SUCCESS: return getNoveltiesSuccess(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_CAROUSEL_FAILURE: return getNoveltiesFailure(state);
    case actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_REQUEST: return getNoveltiesCMSCarouselRequest(state);
    case actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_SUCCESS: return getNoveltiesCMSCarouselSuccess(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_CMS_CAROUSEL_FAILURE: return getNoveltiesCMSCarouselFailure(state);
    case actionTypes.GET_PILLS_FROM_SAME_STORY_REQUEST: return getPillsFromSameStoryRequest(state, action);
    case actionTypes.GET_PILLS_FROM_SAME_STORY_SUCCESS: return getPillsFromSameStorySuccess(state, action);
    case actionTypes.GET_PILLS_FROM_SAME_STORY_FAILURE: return getPillsFromSameStoryFailure(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_REQUEST: return getLatestCollectionsCatalogueRequest(state);
    case actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_SUCCESS: return getLatestCollectionsCatalogueSuccess(state, action);
    case actionTypes.GET_LATEST_COLLECTIONS_CATALOGUE_FAILURE: return getLatestCollectionsCatalogueFailure(state);
    case actionTypes.GET_BRAND_COLLECTION_CATALOGUE_REQUEST: return getBrandCollectionCatalogueRequest(state, action);
    case actionTypes.GET_BRAND_COLLECTION_CATALOGUE_SUCCESS: return getBrandCollectionCatalogueSuccess(state, action);
    case actionTypes.GET_BRAND_COLLECTION_CATALOGUE_FAILURE: return getBrandCollectionCatalogueFailure(state, action);
    case actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_REQUEST: return getBrandCampaignByMocoRequest(state);
    case actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_SUCCESS: return getBrandCampaignByMocoSuccess(state, action);
    case actionTypes.GET_BRAND_CAMPAIGN_BY_MOCO_FAILURE: return getBrandCampaignByMocoFailure(state);

    case actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_SUCCESS: return getBrandCampaignsSuccess(state,action);
    case actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_FAILURE: return getBrandCampaignsFailure(state,action);
    case actionTypes.GET_BRAND_CAMPAIGNS_BY_COLLECTION_REQUEST: return getBrandCampaignsRequest(state,action);
    case actionTypes.LOGOUT: return logout();
    default:
      return state;
  }
};

export default reducer;
