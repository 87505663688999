import React from 'react';
import { getLevelLabel } from '@utility/levelUtility';
import { useSelector } from '@hooks/redux';
import AlertIcon from '@svg-icons/alert-share.svg';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import { isMobile } from '@utility/Api';
import { camelCase } from 'lodash';
import { buildClickDataTrackingObject } from '@model/TrackingClass';

const ShareContent = ({ course }) => {
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const lang = useSelector(state => state.utils.lang);
  const issueDate = course.courseCompletionDate;
  const issueYear = issueDate.split('/')[0];
  const issueMonth = issueDate.split('/')[1];
  const shareData = [
    {
      name: 'LINKEDIN',
      acronym: 'In',
      link: `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${course.courseFullName}&organizationId=13000719&issueYear=${issueYear}&issueMonth=${issueMonth}`,
      iconSrc: '/images/svg-icons/linkedin-white.svg',
    },
  ];
  return (
    <div className="share-body">
      <h1>{isMobile.any() ? lang.CTA_SHARE : lang.CTA_SHARE_CERTIFICATE}</h1>
      <p>{getLevelLabel(course.complexityId, labelComplexityMap)}</p>
      <p>{course.courseFullName}</p>
      <div className="linkedin-alert">
        <AlertIcon id="alert-icon" />
        <p>{lang.ENABLE_LINKEDIN_ALERT}.</p>
      </div>
      {shareData.map(share => (
        <ButtonV2
          className="social"
          variant="outline-btn"
          small
          handleClick={() => {buildClickDataTrackingObject("shareLinkedin_confirm",camelCase(course?.courseId));window.open(share.link, '_blank')}}
          ariaLabel={share.name}
          key={share.acronym}
          data-element-id={'main_certificates_share2_linkedIn'}
          data-description={share.name}
        >
          <span>{share.name}</span>
          <LazySVG src={share.iconSrc} alt={share.acronym} />
        </ButtonV2>
      ))}
    </div>
  );
};
export default ShareContent;
