import {BASE_URL} from "../config_local";

export const sessionStatus = {
  BOOKING_OPEN: 'BOOKING_OPEN', //l'utente può bookarsi
  BOOKING_FULL: 'BOOKING_FULL', //sono terminati i posti per bookarsi alla sessione
  SESSION_OVER: 'SESSION_OVER', //la sessione è terminata
  SESSION_INPROGRESS: 'SESSION_INPROGRESS', //la sessione è in progress
  WAITLISTED: 'WAITLISTED', //l'utente è stato messo in waiting list
  BOOKED: 'BOOKED', //l'utente è bookato alla sessione
  SESSION_INPROGRESS_WAITLISTED: 'SESSION_INPROGRESS_WAITLISTED', //l'utente è stato messo in waiting list e la sessione è in progress
  SESSION_INPROGRESS_BOOKED: 'SESSION_INPROGRESS_BOOKED', //l'utente è bookato alla sessione e la sessione è in progress
  SESSION_OVER_WAITLISTED: 'SESSION_OVER_WAITLISTED', //l'utente è stato messo in waiting list e la sessione è terminata
  SESSION_OVER_BOOKED: 'SESSION_OVER_BOOKED', //l'utente è bookato alla sessione e la sessione è terminata
  SESSION_OVER_NO_SHOW: 'SESSION_OVER_NO_SHOW', //la sessione è terminata, non so cosa significa NO_SHOW
  SESSION_OVER_PARTIALLY_ATTEND: 'SESSION_OVER_PARTIALLY_ATTENDED', //l'utente ha partecipato parzialmente alla sessione e la sessione è terminata
  SESSION_OVER_FULLY_ATTENDED: 'SESSION_OVER_FULLY_ATTENDED', //l'utente ha partecipato alla sessione dall'inizio alla fine e la sessione è terminata
  NO_SHOW: 'NO_SHOW', //non so cosa indichi
  PARTIALLY_ATTENDED: 'PARTIALLY_ATTENDED', //l'utente ha partecipato parzialmente alla sessione (non so che differenza ci sia tra questo status e SESSION_OVER_PARTIALLY_ATTEND)
  FULLY_ATTENDED: 'FULLY_ATTENDED', //l'utente ha partecipato alla sessione dall'inizio alla fine (non so che differenza ci sia tra questo status e SESSION_OVER_FULLY_ATTENDED)
};

export const courseStatus = {
  ENROLLED: 'enrolled',
  COMPLETED: 'completed',
  UNENROLLED: 'unenrolled',
  AVAILABLE: 'available',
};

export const ctype = {
  VIRTUAL_CLASSROOM: 'virtual_classroom',
  FACE_TO_FACE: 'classroom',
  LEARNING_PATH: 'learning_path',
  ONLINE_COURSE: 'self_learning_lesson',
  INTERACTIVE: 'interactive',
  PDF: 'pdf',
  VIDEO: 'video',
  MULTIACTIVITY: 'multiactivity',
  PODCAST: 'podcast',
  LIVESTREAM: 'vimeo',
  EXTERNAL_CONTENT: 'external_content',
  L1: 'l1',
  CERTIFICATE: 'certificate',
};

// better use CourseState in CoursesClass
export const courseStatesConst = {
  UPCOMING: 'upcoming',
  SCHEDULED: 'scheduled',
  ATTENDED: 'attended',
  MISSED: 'missed',
  IN_PROGRESS: 'inProgress',
  EXPIRING: 'expiring',
  OVERDUE: 'overdue',
  TO_BEGIN: 'toBegin',
  TO_DO: 'toDo',
};

export const activityState = {
  INCOMPLETE: 'COMPLETION_INCOMPLETE',
  COMPLETE: 'COMPLETION_COMPLETE',
  PASS: 'COMPLETION_COMPLETE_PASS',
  FAIL: 'COMPLETION_COMPLETE_FAIL',
  STARTED: 'COMPLETION_STARTED',
};

export const updateType = {
  NEW_COURSE: '4', //1
  NEW_LP: '76', //1
  NEW_COURSE_ASSIGNED: '1', //2 V2 - usata anche per VC assegnate manualmente
  NEW_LP_ASSIGNED: '68', //come NEW_COURSE_ASSIGNED ma per LP
  COURSE_OVERDUE: '3', //3 V2
  LP_OVERDUE: '67', //come COURSE_OVERDUE ma per LP
  LP_OVERDUE_MANAGER: '67m', //come 67 ma per manager (usata anche quando un member ha l'onboarding in overdue)
  RECORDING_AVAILABLE: '18', //5 V2
  BOOK_COMPLETE: '6', //6
  SESSION_DATE_MODIFIED: '20', //7 V2
  NEW_SESSION_AVAILABLE: 'session_added', //8
  NEW_SESSION: '21', //8
  SESSION_CANCEL: '22', //9 V2
  BOOK_CANCEL: '10', //10
  WAIT_LIST_OPEN: '11', //11
  WAIT_LIST_CLOSE: '12', //12
  FROM_WAIT_TO_BOOK: 'session_booked', //13
  BOOK_CONFIRM: '13', //13
  COURSE_COMPLETED_OLD: 'course_completed', //14 V2
  COURSE_COMPLETED: '31', //14 V2
  PROFILE_UPDATED: '40', //15 V2
  NEW_COURSE_MATERIAL: '30', //16 V2
  COURSE_IS_EXPIRING_OLD: 'course_expiring', //17 V2 - before generated by FE, now by moodle
  COURSE_IS_EXPIRING: '2', //17 V2
  LP_IS_EXPIRING: '66', //come COURSE_IS_EXPIRING ma per LP
  TEAM_EXPIRING_ASSIGNMENTS: '2m', //18 V2
  TEAM_EXPIRING_ASSIGNMENTS_LP: '66m', //come TEAM_EXPIRING_ASSIGNMENTS ma per LP
  SESSION_STARTING: 'session_starting', //19
  SESSION_START: '15', //19
  SESSION_MISSED: '16', //20
  CERTIFICATE_AVAILABLE: '33', //24 V2
  COURSE_COMPLIANCE_EXPIRED: '8', //25
  LP_COMPLETED: '69', //27 come COURSE_COMPLETED ma per LP
  REPORT_MANAGER_UPDATED_OLD: 'report_manager_updated', //28 V2
  REPORT_MANAGER_UPDATED: '50', //28 V2
  COURSE_PENDING: '34', //29 V2
  MONTHLY_ACHIEVEMENT: '51', //30 V2
  RATING: '60', //32B V3
  RATING_LP: '70', //come RATING ma per LP
  WISH_EXPIRING: '61', //33 V3
  WISH_AVAILABILITY: '62', //35 V3
  LEADERBOARD_IN: '63', //43A V3
  LEADERBOARD_IN_MANAGER: '63m',
  LEADERBOARD_OUT: '64', //43B V3
  LEADERBOARD_OUT_MANAGER: '64m',
  LIVESTREAM_STARTING: 'LIVESTREAM_STARTING', //no used because out of scope
  LIVESTREAM_JOIN: 'LIVESTREAM_JOIN', //no used because out of scope
  LIVESTREAM_RECORDING: '71', //notifica recording disponibile per livestream
  NEW_CONTENT_TP: 'tp_new', //notifica nuovo contenuto TP (nuove new picks for you)
  TOKEN_ACTIVATED: '74', //74
  COURSE_DELETED: 'course_deleted',
  COURSE_CREATED: 'course_created',
  COURSE_UPDATED: 'course_updated',
  ACTIVITY_COMPLETED: 'activity_completed',
  COURSE_MODULE_CREATED: 'course_module_created',
  COURSE_MODULE_UPDATED: 'course_module_updated',
  COURSE_MODULE_DELETED: 'course_module_deleted',
  LP_CREATED: 'learningpath_created',
  LP_UPDATED: 'learningpath_updated',
  LP_DELETED: 'learningpath_deleted',
  SESSION_UPDATED: 'session_updated',
  SESSION_DELETED: 'session_deleted',
};

export const imgCategories = {
  PRODUCTS: 'Products and Brands',
  SKILLS: 'Skills and Behaviors',
  COMPLIANCE: 'Safety and Compliance',
  COMPANY: 'Company DNA',
};

export const SEARCH_TYPE = {
  TAG: 'tag',
  SECTION: 'section',
  TITLE: 'title',
  DESCRIPTION: 'description',
  GENERAL: 'general',
};

export const SENDER_SYSTEM = {
  LUXOTTICA: 'MYLB2B',
  ESSILOR: 'ESSILORU',
  BACKOFFICE: 'BACKOFFICE',
  EXTERNAL: 'EXTWW',
};

export const USER_TYPE = {
  MAINUSER: 'B2BMAINUSER',
  SUBUSER: 'B2BSUBUSER',
};

export const queryParams = {
  COURSE_DETAIL: 'courseDetail',
  COURSE_DETAIL_TITLE: 'courseTitle',
  REDIRECT_AFTER_LOGIN: 'redirectAfterLogin',
  SEARCH_QUERY: 'searchQuery',
  FILTER_SECTION: 'filterSection',
  FILTER_TEACHER: 'filterTeacher',
  SEARCH_TAB: 'searchTab',
  SHOW_BACK_BUTTON: 'showBackButton',
  TOKEN: 'token',
  PLANID: 'planId',
  WISHLIST_TAB: 'wishlistTab',
  MEMBER_USERNAME: 'member',
  CID: 'cid',
  ONBOARDING_SCROLL_TO_TOPIC: 'scrollToTopic',
  ONBOARDING_SCROLL_TO_LESSON: 'scrollToCourse',
  PILL_MODEL: 'pillModel',
  STORY: 'storyId',
  CMS_PREVIEW: 'cmspreview'
};

export const queryParamsLive = {
  USER_TOKEN: 'userToken',
  EVENT_ID: 'eventId',
};

export const TAGS = {
  isMobileNotOptimized: 'mobile: no',
  isSubtitledVideo: 'Title: sub',
  isActivityVideo: 'type: video',
  isActivityPodcast: 'type: podcast',
  isActivityFaceToFace: 'type: face_to_face',
  // isActivityVirtualClassroom: 'type: virtual_classroom',
  subtitle: 'subtitle: ',
  comingSoon: 'next_',
  publishDirect: 'publish: direct',
  hasVideoPreview: 'hasVideoPreview: ',
  hasVideoPreviewTrue: 'hasVideoPreview: true',
  ecpu: 'ecpu: true',
  noStandalone: 'no_standalone',
  onTheJobTraining: 'hasOnTheJobTraining: true',
  hasSubtitlePreview: 'hasSubtitlePreview: true',
  abo: 'abo: true',
  // assignment: 'program: assignment', //no used by FE
  // oneToOne: 'program: one2one', //no used by FE
  // supportMaterial: 'program: support', //no used by FE
  isComingSoon: "coming_soon",
};

export const COOKIE = {
  MOODLE_SESSION: 'MoodleSession',
  FIREBASE_TOKEN: 'fireBaseToken',
  USER_TOKEN: 'UserToken',
  COOKIE_POLICY: 'cookie-policy',
  MYL_TOKEN: 'mylToken',
  CART: 'cart',
  NO_SHOW_MODAL_CART: 'noShowModalCart',
  // NO_SHOW_MODAL_EXPIRATION: "noShowModalExpiration",
  ECOMMERCE: 'ecommerceStatus',
  FORTER_TOKEN: 'forterToken',
  LABEL_PREVIEW: 'labelPreview',
  CLOSE_ADD_TO_HOME: 'closeAddToHomescreen',
  USER_DETAILS: 'userDetails',
  AUTORENEWAL_ACTIVE: 'autorenewalActive',
  PLAN_PURCHASED: 'planPurchased',
  REFRESH_TOKEN: 'refreshToken',
  COLLECTION_PREVIEW: 'collectionList',
  CMS_PREVIEW: 'CmsPreview',
  COURSES_PROGRESS: 'coursesProgress'
};

export const HEADER = {
  MOODLE_SESSION: 'moodle-session',
  USER_TOKEN: 'University-Auth',
  ISWHITE: false,
};

export const NOTIFICATION_ACTION = {
  DOWNLOAD_CALENDAR: 'download-calendar',
  GO_TO_COURSE: 'go-course',
  GO_TO_MY_TEAM: 'go-my-team',
  GO_TO_CATALOGUE: 'go-catalogue',
  GO_TO_COMPLETIONS: 'go-completions',
  GO_TO_CERTIFICATES: 'go-certificates',
  GO_TO_LEADERBOARD: 'go-leaderboard',
  RATE_COURSE: 'rate-course',
  BOOKING: 'booking',
  LIVESTREAM_JOIN: 'livestream-join',
  LIVESTREAM_RECORDING_LAUNCH: 'livestream-recording-launch',
  GO_TO_TP_PLP: 'go-tp-plp',
  ACTIVATE_TOKEN_GO_CONTENT: 'activate-token-go-content',
};

export const NOTIFICATION_ATTRIBUTE = {
  USER: 'notifications',
  GENERIC: 'genericNotifications',
};

export const BADGE = {
  recording_available: 'recording_available',
  recording_available_soon: 'recording_available_soon',
  done: 'done',
  overdue: 'overdue',
  missed: 'missed',
  expiring: 'expiring',
  duesoon: 'duesoon',
  livenow: 'livenow',
  live_in_x: 'live_in_x',
  booked: 'booked',
  mandatory: 'mandatory',
  new: 'new',
  // hot: 'hot', --no more used
  livestream: 'livestream',
  coming_soon: "coming_soon"
};

export const BADGE_PROGRAM = {
  purchased: 'recording_available',
  request_sent: 'request_sent',
  sold_out: 'sold_out',
  coming_soon: "coming_soon"
};

export const BADGE_PRIORITY = {
  [BADGE.recording_available]: 1,
  [BADGE.recording_available_soon]: 2,
  [BADGE.done]: 3,
  [BADGE.overdue]: 4,
  [BADGE.missed]: 5,
  [BADGE.expiring]: 6,
  [BADGE.duesoon]: 7,
  [BADGE.livenow]: 8,
  [BADGE.live_in_x]: 9,
  [BADGE.booked]: 10,
  [BADGE.mandatory]: 11,
  [BADGE.new]: 12,
};

export const BADGE_PROGRAM_PRIORITY = {
  [BADGE.request_sent]: 1,
  [BADGE.purchased]: 2,
  [BADGE.sold_out]: 3,
};

export const SORTBY = {
  RATING: 'Like',
  MANDATORY: 'Mandatory',
  MANDATORY_DESC: 'MandatoryDesc',
  NEW: 'New',
  RELEVANCE: 'Relevance',
  RELEVANCE_FAKE: 'RelevanceFake',
  RELEVANCE_SEARCH: 'RelevanceSearch',
  SHORTEST: 'Shortest',
  TITLE_AZ: 'Title_AZ',
  TITLE_ZA: 'Title_ZA',
  /**CAN'T BE USED FOR SORT BY, USE RECOMMANDATION INSTEAD */
  COMPATIBILITY: 'Compatibility',
  UPCOMING: 'Upcoming',
  START_DATE: 'StartDate',
  START_DATE_LIVESTREAM: 'StartDateLiveStream',
  // RECOMMANDATION: "Recommandation",
  NOT_COMPLETED: 'NotCompleted',
  IN_PROGRESS: 'InProgress',
  OVERDUE: 'Overdue',
  EXPIRING: 'Expiring',
  TO_START: 'ToStart',
  COMPLETION: 'Completition',
  MASTER_PRIORITY: 'master',
  START_DATE_LIVESTREAM: 'StartDateLivestream',
  START_DATE_LIVESTREAM_ASC: 'StartDateLivestreamAsc',
  DONE_FOR_LAST: 'DoneForLast',
  PURCHASE_DATE: 'PurchaseDate',
  PRICE_DES: 'PriceDes',
  PRICE_ASC: 'PriceAsc',
  ONBOARDING: 'Onboarding',
  COMPLEXITY_DESC: 'ComplexityDesc',
  NAME: 'Name',
  COURSE_STARTED: 'CourseStarted',
  BLOCKED_PROGRAM: 'BlockedProgram',
};

export const FILTER_CTYPE = {
  CLASSES: 'classes',
  ONLINE_COURSES: 'online_courses',
  MULTIACTIVITY: ctype.MULTIACTIVITY,
  PDF: ctype.PDF,
  EXTERNAL_CONTENT: ctype.EXTERNAL_CONTENT,
  LEARNING_PATH: ctype.LEARNING_PATH,
  VIDEO: ctype.VIDEO,
  SUBTITLED_VIDEO: 'subtitled_video',
  PODCAST: ctype.PODCAST,
  LIVESTREAM: ctype.LIVESTREAM,
};

export const FILTER_OTHERS = {
  RECORDING_AVAILABLE: 'recording_available',
};

export const FILTER_COURSE = {
  EL_360: 'el_360',
}

export const FILTER_PLAN = {
  FREE: 'free',
  INPLAN: 'inplan',
  PURCHASABLE_OUTSIDE_PLAN: 'purchasableOutsidePlan',
  PROMO: 'promo',
  MASTER: 'master',
  PROFESSIONAL: 'professional',
  CERTIFICATE: 'certificate',
};

export const FILTER_PRICE = {
  _1_15: {
    id: '_1_15',
    from: 1,
    to: 15,
  },
  _15_50: {
    id: '_15_50',
    from: 15,
    to: 50,
  },
  _50_250: {
    id: '_50_250',
    from: 50,
    to: 250,
  },
  _250_500: {
    id: '_250_500',
    from: 250,
    to: 500,
  },
  _500_1000: {
    id: '_500_1000',
    from: 500,
    to: 1000,
  },
  _1000_2000: {
    id: '_1000_2000',
    from: 1000,
    to: 2000,
  },
  _2000_inf: {
    id: '_2000_inf',
    from: 2000,
    to: null,
  },
};

export const DURATION = {
  LESS3: { id: '<3m', index: 3, labelKey: '', lower: 3 * 60, greater: null },
  LESS5: { id: '<5m', index: 5, labelKey: 'DURATION_LESS_5', lower: 5 * 60, greater: null },
  BETWEEN5_10: {
    id: '5<<10',
    index: 6,
    labelKey: 'DURATION_5_10',
    lower: 10 * 60,
    greater: 5 * 60,
  },
  LESS10: { id: '<10m', index: 10, labelKey: '', lower: 10 * 60, greater: null },
  BETWEEN10_20: {
    id: '10<<20',
    index: 11,
    labelKey: 'DURATION_10_20',
    lower: 20 * 60,
    greater: 10 * 60,
  },
  LESS15: { id: '<15m', index: 15, labelKey: '', lower: 15 * 60, greater: null },
  MORE20: { id: '>20m', index: 20, labelKey: 'DURATION_MORE_20', lower: null, greater: 20 * 60 },
};

export const SESSION_SCHEDULE = {
  TODAY: { id: 'TODAY', index: 0, labelKey: 'SCHEDULE_TODAY' },
  TOMORROW: { id: 'TOMORROW', index: 1, labelKey: 'SCHEDULE_TOMORROW' },
  NEXT_WEEK: { id: 'NEXT_WEEK', index: 7, labelKey: 'SCHEDULE_NEXT_WEEK' },
  NEXT_MONTH: { id: 'NEXT_MONTH', index: 30, labelKey: 'SCHEDULE_NEXT_MONTH' },
};

export const PRICE_FILTER_PLAN = {
  [FILTER_PLAN.FREE]: { id: FILTER_PLAN.FREE, index: 0, labelKey: 'FREE' },
  [FILTER_PLAN.PURCHASABLE_OUTSIDE_PLAN]: {
    id: FILTER_PLAN.PURCHASABLE_OUTSIDE_PLAN,
    index: 1,
    labelKey: 'PURCHASABLE_OUTSIDE_PLAN_LABEL',
  },
  [FILTER_PLAN.INPLAN]: { id: FILTER_PLAN.INPLAN, index: 2, labelKey: 'UNDER_SUBSCRIPTION_PLAN' },
  [FILTER_PLAN.PROMO]: { id: FILTER_PLAN.PROMO, index: 3, labelKey: 'FILTER_PLAN_PROMO' },
  [FILTER_PLAN.MASTER]: {
    id: FILTER_PLAN.MASTER,
    index: 4,
    labelKey: 'FILTER_PLAN_MASTER_COURSES',
  },
  [FILTER_PLAN.PROFESSIONAL]: {
    id: FILTER_PLAN.PROFESSIONAL,
    index: 5,
    labelKey: 'FILTER_PLAN_PROFESSIONAL',
  },
  [FILTER_PLAN.CERTIFICATE]: {
    id: FILTER_PLAN.CERTIFICATE,
    index: 6,
    labelKey: 'FILTER_PLAN_CERTIFICATES',
  },
};

export const PRICE_FILTER_LABELS = {
  [FILTER_PRICE._1_15.id]: { ...FILTER_PRICE._1_15, index: 0, labelKey: 'FILTER_PRICE_FROM_TO' },
  [FILTER_PRICE._15_50.id]: { ...FILTER_PRICE._15_50, index: 1, labelKey: 'FILTER_PRICE_FROM_TO' },
  [FILTER_PRICE._50_250.id]: {
    ...FILTER_PRICE._50_250,
    index: 2,
    labelKey: 'FILTER_PRICE_FROM_TO',
  },
  [FILTER_PRICE._250_500.id]: {
    ...FILTER_PRICE._250_500,
    index: 3,
    labelKey: 'FILTER_PRICE_FROM_TO',
  },
  [FILTER_PRICE._500_1000.id]: {
    ...FILTER_PRICE._500_1000,
    index: 4,
    labelKey: 'FILTER_PRICE_FROM_TO',
  },
  [FILTER_PRICE._1000_2000.id]: {
    ...FILTER_PRICE._1000_2000,
    index: 5,
    labelKey: 'FILTER_PRICE_FROM_TO',
  },
  [FILTER_PRICE._2000_inf.id]: {
    ...FILTER_PRICE._2000_inf,
    index: 6,
    labelKey: 'FILTER_PRICE_OVER_FROM',
  },
};

export const EXPIRATION_KEYS = {
  IN7DAYS: 'IN7DAYS',
  IN1MONTH: 'IN1MONTH',
  IN3MONTHS: 'IN3MONTHS',
  IN12MONTHS: 'IN12MONTHS',
};

export const EXPIRATION = {
  [EXPIRATION_KEYS.IN7DAYS]: { id: '<7d', index: 7, num: 7, labelKey: 'IN_DAYS' },
  [EXPIRATION_KEYS.IN1MONTH]: { id: '7d<<1m', index: 30 * 1, num: 1, labelKey: 'IN_1_MONTH' },
  [EXPIRATION_KEYS.IN3MONTHS]: { id: '1m<<3m', index: 30 * 3, num: 3, labelKey: 'IN_MONTHS' },
  [EXPIRATION_KEYS.IN12MONTHS]: { id: '3m<<12m', index: 30 * 12, num: 1, labelKey: 'IN_1_YEAR' },
};

export const SKELETON_COLORS = {
  primaryColor: '#ffffff26',
  secondaryColor: '#ffffff1a',
};

export const BRAND_TYPE = {
  HOUSE_BRAND: 'houseBrands',
  LICENSED_BRAND: 'licensedBrands',
  THIRD_PARTY_BRAND: 'thirdPartyBrands',
  LENS_TECH: 'lensTechnologies',
  LENS: 'lens',
  EYEWEAR: 'eyewear',
};

export const L0_IDS = {
  ESSILOR_LUX: 'essilorlux',
  EYE_VISION: 'eye_vision',
  SELLING_DISPENSING: 'selling_dispensing',
  PRODUCT_TECH: 'product_technology',
  BUSINESS_PEOPLE_DEV: 'business_b_p_dev',
  PLANT_OPS: 'plant_operations',
  BRAND: 'brand',
  BRANDS: 'brands',
  BRANDS_EYEWEAR: 'brands_eyewear',
  BRAND_LENSES: 'brands_lenses',
  BRANDS_PRODUCTS: 'brands_products',
};

export const API_ADMIN_STATUS_CODE = {
  OK: 0,
  GENERIC_ERROR: -1,
  PENDING_REQUEST: 1,
  EMAIL_ALREADY_EXISTS: 4,
  PASS_WRONG_FORMAT: 11,
  EXPIRED_TOKEN: 12,
};

export const FIELD_PATTERN = {
  //min 12 chars, at least 1 lowercase and 1 uppercase chars, at least 1 number, at least 1 symbol
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d*])(?=.*[.!@$%^&(){}\[\]:;<>,.?\/~_+\-=|"'£€*°#\\])[A-Za-z\d.!@$%^&(){}\[\]:;<>,.?\/~_+\-=|"'£€*°#\\]{12,}$/,
  //RFC 5322 Official Standard
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // MYL_CODE: /^[a-zA-Z0-9]{4,6}\.([0-9]{10}|[sS][0-9]{4})\.\w{2,4}$/,
  MYL_CODE: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_./* -]{0,6}\.([0-9]{10}|[sS][0-9]{4})\.\w{2,4}$/, ///^[a-zA-Z0-9]{0,6}\ old regex
  MYL_CODE_NO_USER: /^([0-9]{10}|[sS][0-9]{4})\.\w{2,4}$/,
  US_ZIP_CODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
  US_AND_CA_ZIP_CODE: /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/
};

export const LOGIN_ERROR = {
  PASS_EXPIRED: 'PASSWORD EXPIRED',
};

export const CONTACT_US_COURSES_L2 = '1052';

export const COOKIE_POLICY_KEY = 'cookie-policy';
export const cookie_policy = {
  analytics: true,
  marketing: true,
};

export const ONE_HOUR = 1000 * 60 * 60;

export const TP_CATALOGUE_PAGINATION = 20;

export const ASSISTANCE_NUMBER = '0123-456-789';
export const EXCLUSIVE_COLLECTION = 'EXC';

export const ADD_TO_CART_STEPS = {
  PLAN: -1,
  NO_MODAL: 0,
  LESSON_IN_PLAN: 1,
  LESSON_PART_COURSE: 2,
  EXPIRATION_LESS_1_MONTH: 3,
};

export const PROMO_STATUS_CODE = {
  GENERIC_ERROR: -1,
};

export const PAYMENT_ERROR_RECAPTCHA = 'reCaptcha check failed!';

const CARD_VALIDATION_DEFAULT_NUMBER = /(\d{1,4})/g;

const CVC_PATTERN_3_NUMBER = /^(\d{3})$/;
const CVC_PATTERN_3_4_NUMBER = /^(\d{3,4})$/;

export const CARD_EXPIRING_DATE_PATTERN = /^(\d{2})\/(\d{2})$/;

export const CARD_ZERO = 'Zero';
export const CARD_VALIDATION = {
  generic: {
    type: 'generic',
    format: CARD_VALIDATION_DEFAULT_NUMBER,
    length: [13, 15, 16],
    cvcLength: [3, 4],
    cvcPattern: CVC_PATTERN_3_4_NUMBER,
    luhn: true,
    payMethodId: 'CyberSource',
  },
  amex: {
    type: 'amex',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [3, 4],
    cvcPattern: CVC_PATTERN_3_4_NUMBER,
    luhn: true,
    payMethodId: 'CyberSourceAmex',
  },
  visa: {
    type: 'visa',
    pattern: /^4/,
    format: CARD_VALIDATION_DEFAULT_NUMBER,
    length: [13, 16],
    cvcLength: [3],
    cvcPattern: CVC_PATTERN_3_NUMBER,
    luhn: true,
    payMethodId: 'CyberSourceVisa',
  },
  mastercard: {
    type: 'mastercard',
    pattern: /^5[0-5]/,
    format: CARD_VALIDATION_DEFAULT_NUMBER,
    length: [16],
    cvcLength: [3],
    cvcPattern: CVC_PATTERN_3_NUMBER,
    luhn: true,
    payMethodId: 'CyberSourceMC',
  },
  discover: {
    type: 'discover',
    pattern: /^6([045]|22)/,
    format: CARD_VALIDATION_DEFAULT_NUMBER,
    length: [16],
    minLength: 16,
    maxLength: 16,
    cvcLength: [3],
    cvcPattern: CVC_PATTERN_3_NUMBER,
    luhn: true,
    payMethodId: 'CyberSourceDiscover',
  },
  // visaelectron: {
  //     type: 'visaelectron',
  //     pattern: /^4(026|17500|405|508|844|91[37])/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [16],
  //     cvcLength: [3],
  //     luhn: true
  // },
  // maestro: {
  //     type: 'maestro',
  //     pattern: /^(5(018|0[23]|[68])|6(39|7))/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [12, 13, 14, 15, 16, 17, 18, 19],
  //     cvcLength: [3],
  //     luhn: true
  // },
  // forbrugsforeningen: {
  //     type: 'forbrugsforeningen',
  //     pattern: /^600/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [16],
  //     cvcLength: [3],
  //     luhn: true
  // },
  // dankort: {
  //     type: 'dankort',
  //     pattern: /^5019/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [16],
  //     cvcLength: [3],
  //     luhn: true
  // },
  // dinersclub: {
  //     type: 'dinersclub',
  //     pattern: /^3[0689]/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [14],
  //     cvcLength: [3],
  //     luhn: true
  // },
  // unionpay: {
  //     type: 'unionpay',
  //     pattern: /^(62|88)/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [16, 17, 18, 19],
  //     cvcLength: [3],
  //     luhn: false
  // },
  // jcb: {
  //     type: 'jcb',
  //     pattern: /^35/,
  //     format: CARD_VALIDATION_DEFAULT_NUMBER,
  //     length: [16],
  //     cvcLength: [3],
  //     luhn: true
  // }
};

export const ECOMMERCE_ACTIVE_VALUES = {
  ENABLED: 'enabled',
  EMPLOYEE: 'employee',
  DISABLED: 'disabled',
};

export const CONTACT_DEFAULT_PREFIX = 'default_';

export const PLAN_IMAGE =
  'https://media.leonardo.essilorluxottica.com/images/ecommerce/subscriptionplan_gold.jpg';
export const PLAN_IMAGE_COMMERCIAL_CARD =
  'https://media.leonardo.essilorluxottica.com/images/ecommerce/subscriptionplan_gold-nobadge.jpg';

export const TEACHING_METHOLOGY_IMAGE =
  'https://media.leonardo.essilorluxottica.com/images/catalog_generic/catalog_generic_futuresighters.png';

export const PREVIEW_LABEL_COOKIE_KEYS = [
  'versionLabels',
  'versionTrainingPills',
  'versionEcommerce',
  'versionLevel',
  'versionChannel',
  'versionSection',
  'versionNotification',
  'versionTags',
  'versionComplexity',
  'versionTopic',
  'versionCollections',
];

export const FALLBACK_IMAGE_COVER_URL =
  'https://media.leonardo.essilorluxottica.com/images/Course_Images/';

// export const SELECTOR_LAYOUT_SCROLLBAR_CUSTOM = "#themeManager > .custom-scrollbar-v3 > .custom-scrollbar-v3__wrapper > .custom-scrollbar-v3__scroller";
// export const SELECTOR_LAYOUT_SCROLLBAR_CUSTOM_MAC = "#themeManager > .custom-scrollbar-v3__scroller";

export const FILTER_COMPLEXITY_CATEGORY = {
  COURSES: 'COURSES',
  LESSONS: 'LESSONS',
  EVENTS: 'EVENTS',
};
export const FILTER_PREFIX_COMPLEXITY_CATEGORY = 'complexityCategory_';

export const AKAMAI_IMAGE_URL = 'https://media-university.luxottica.com/images';
export const AKAMAI_IMAGE_LEONARDO_URL = 'https://media.leonardo.essilorluxottica.com/images';

export const PREBOARDING_IMAGE_DESK =
  AKAMAI_IMAGE_LEONARDO_URL + '/preboarding/Leonardo_background_desk.jpg';
export const PREBOARDING_IMAGE_SMARTPHONE =
  AKAMAI_IMAGE_LEONARDO_URL + '/preboarding/Leonardo_background_mob.jpg';
export const PREBOARDING_IMAGE_TABLET =
  AKAMAI_IMAGE_LEONARDO_URL + '/preboarding/Leonardo_background_tab.jpg';

export const PREBOARDING_IMAGE2_DESK =
  AKAMAI_IMAGE_LEONARDO_URL + '/preboarding/Leonardo_background_2_desk.jpg?impolicy=crop&north=152';
export const PREBOARDING_IMAGE2_SMARTPHONE =
  AKAMAI_IMAGE_LEONARDO_URL +
  '/preboarding/Leonardo_background_2_mob.jpg?impolicy=crop&north=7&west=27';
export const PREBOARDING_IMAGE2_TABLET =
  AKAMAI_IMAGE_LEONARDO_URL + '/preboarding/Leonardo_background_2_tab.jpg';

export const PREBOARDING_IMAGE_LOGIN_DESK =
  AKAMAI_IMAGE_LEONARDO_URL + '/login/Leonardo_background_login_desk.jpg';
export const PREBOARDING_IMAGE_LOGIN_SMARTPHONE =
  AKAMAI_IMAGE_LEONARDO_URL + '/login/Leonardo_background_login_mob.jpg';
export const PREBOARDING_IMAGE_LOGIN_TABLET =
  AKAMAI_IMAGE_LEONARDO_URL + '/login/Leonardo_background_login_tablet.jpg';

// BUILD YOUR CAREER
export const BYC_IMAGE_HERO_DESK =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/D_byc_hero_banner.jpg';
export const BYC_IMAGE_HERO_TL =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/TL_byc_hero_banner.jpg';
export const BYC_IMAGE_HERO_TP =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/TP_byc_hero_banner.jpg';
export const BYC_IMAGE_HERO_MOBILE =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/M_byc__hero_banner.jpg';
export const BYC_IMAGE_BTN_HERO_ROLE =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/Role_Specific_Programs.jpg';
export const BYC_IMAGE_BTN_HERO_SMP =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/Subject_Matter_Programs.jpg';
export const BYC_IMAGE_BTN_HERO_MDP =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/Management_Development_Program.jpg';
export const SMP_IMAGE_HERO =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/upskill-programs/Hero_upskill_programs.jpg';
export const SMP_IMAGE_BTN_HERO_BRANDS =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/upskill-programs/Brand_programs.jpg';
export const BYC_IMAGE_BTN_HERO_VISION =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/upskill-programs/Vision_care.jpg';
export const BYC_IMAGE_BTN_HERO_SALES =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/upskill-programs/Sales_and_business.jpg';
export const BYC_IMAGE_BTN_HERO_PEOPLE =
  AKAMAI_IMAGE_LEONARDO_URL + '/build-your-career/upskill-programs/People_development.jpg';

export const classesTypography = {
  ['primary-headline-big']: 'typography-primary-headline-big',
  ['primary-headline-medium']: 'typography-primary-headline-medium',
  ['primary-headline-small']: 'typography-primary-headline-small',
  ['primary-subhead']: 'typography-primary-subhead',
  ['primary-h1-left-gradient']: 'typography-primary-h1-left-gradient',
  ['primary-h2-left-gradient']: 'typography-primary-h2-left-gradient',
  ['primary-h3-left-gradient']: 'typography-primary-h3-left-gradient',
  ['primary-h4-left-gradient']: 'typography-primary-h4-left-gradient',
  ['primary-h5-left-gradient']: 'typography-primary-h5-left-gradient',
  ['primary-h6-left-gradient']: 'typography-primary-h6-left-gradient',
  ['primary-value-heavy']: 'typography-primary-value-heavy',
  ['primary-value']: 'typography-primary-value',
  ['primary-caption']: 'typography-primary-caption',
  ['danger-regular']: 'typography-danger-regular',
  ['success-regular']: 'typography-success-regular',
  ['price-default']: 'typography-price-default',
  ['price-sale']: 'typography-price-sale',
};

export const EVOLUTION_PRIMARY_COLOR = '#26e5e9';

export const HTTP_REDIRECT_STATUS = 308;
export const AZURE_RESPONSE_QUERY_PARAM = 'result';
export const AZURE_RESPONSE_QUERY_PARAM_OK = '0';
export const AZURE_RESPONSE_QUERY_PARAM_KO = '1';

export const BRANDS_RESIZE_LIST = [
  'brand_RL',
  'brand_PS',
  'brand_EA',
  'brand_AR',
  'brand_DG',
  'brand_MK',
  'brand_OV',
  'brand_TY',
];

export const HEADER_LEVEL = {
  ONE: '1',
  TWO: '2',
  THREE: '3',
  TITLE: 'title',
};

export const MODAL_TYPES = {
  profileMenu: 'profileMenu',
  areaOfExpertiseMenu: 'areaOfExpertiseMenu',
  areaOfExpertiseL1Menu: 'areaOfExpertiseL1Menu',
  collectionsMenu: 'collectionsMenu',
  programsMenu: 'programsMenu',
  cartRightModal: 'cartRightModal',
  notificationsRightModal: 'notificationsRightModal',
  menuRightModal: 'menuRightModal',
  searchMenu: 'searchMenu',
  visionCareMenu: 'visionCareMenu'
};

export const BUILD_YOUR_CAREER_NAME_L0  = "career"

export const LEVELS_AREA = {
  EDUCATIONAL_CATALOG: "EDUCATIONAL_CATALOG",
  BRANDS: "BRANDS",
  BUILD_YOUR_CAREER_CP: "BUILD_YOUR_CAREER_CP",
  BUILD_YOUR_CAREER_UP: "BUILD_YOUR_CAREER_UP",
  BUILD_YOUR_CAREER_MASTER: "BUILD_YOUR_CAREER_MASTER",
  EDUCATIONAL_CATALOG_BYC: 'EDUCATIONAL_CATALOG_BYC',
  BUILD_YOUR_CAREER_UP_OFFICE: "BUILD_YOUR_CAREER_UP_OFFICE"
  
}

export const PERSONIFICATION_COOKIE_KEYS = {
  inSimulation:'InSimulation',
  userTokenSimulator:'UserTokenSimulator',
  showUserGroupBanner:'ShowUserGroupBanner'
}

export const EXPIRE_COOKIE_SAVING_STATE = 4 * 60 * 1000 // 4 min

export const CMS_NEW_PAGE = {
  PREFIX_LAYOUTVARIANT_TYPE : "EP",
  LAYOUT_ANCHORABLE_ROW     : "anchorable",
  MAX_ROW_ANCHORABLE        : 4,
}