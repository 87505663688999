import React from "react";

export type SliderProps = {
  handleOnChange?: (value: number | string) => void;
  horizontal?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  current?: number;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, "name" | "max">;

const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
  (
    {
      name,
      handleOnChange,
      leftLabel,
      rightLabel,
      horizontal,
      max,
      current,
    },
    forwardedRef
  ) => {

    return (
      <div className="slider__wrapper ">
        {leftLabel && <span>{leftLabel}</span>}
        <div className={"slider__container " + (horizontal ? "horizontal" : "")}>
          <input
            type="range"
            min="0"
            max={max || 3}
            name={name}
            value={current}
            className={`slider shadow-color ${horizontal ? "horizontal" : ""}`}
            ref={forwardedRef}
            onChange={({ target: { value } }) => {
              handleOnChange?.(value);
            }}
          />
          <div className="slider__line" />
        </div>
        {rightLabel && <span>{rightLabel}</span>}
      </div>
    );
  });

export default Slider;
