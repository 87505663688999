import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
const axios = require('axios');
import { getHeaders } from '../../utility/Api';
import { showError } from './utilsActions';
import { CMSBrandInfo } from '@model/CMSBrand';
import { CMSL1Image } from '@model/CMSL1Image';
import { UserProfile } from '@model/User';
import { HowToInfo } from '@model/CMSHowTo';
import { HpBean } from '@model/HPBean';
import { HeroBannerInfoResponse } from '@model/CMSHeroBanner';
import { InfoTortonaResponse } from '@model/CMSTortona';
import { SHOW_L1_LABEL } from '@model/L1Element';
import { getL1IdFromBrandId } from '@utility/levelUtility';
import { CMSOnboarding, CMSOnboardingResponse, CMSTeacherResponse } from '@model/CMSOnboarding';
import { promiseMock } from '@utility/ecommerceUtility';
import { Course, getTrainerFullName, TrainerExtended } from '@model/CoursesClass';
import { isCourseMaster, isCourseOnBoardingWholesale } from '@utility/onBoardingCourseUtility';
import { BuildYourCareerResponse } from '@model/CMSBuildYourCareer';

export const getFaq = (isLanding: boolean = false, language: string = 'en') => {
  return dispatch => {
    let url = isLanding
      ? urlList.GET_PUBLIC_FAQ_URL + '?language=' + language
      : urlList.GET_FAQ_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: isLanding ? null : getHeaders(),
    })
      .then(response => {
        let faqList = response.data;
        faqList = faqList.sort((a, b) => a.order - b.order);
        dispatch(success(faqList));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_FAQ_REQUEST,
    };
  }

  function success(faqList) {
    return {
      type: actionTypes.GET_FAQ_SUCCESS,
      faqList,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_FAQ_FAILURE,
    };
  }
};

export const getFaqPlan = () => {
  return dispatch => {
    let url = urlList.GET_FAQ_PLAN_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let faqList = response.data;
        faqList = faqList.sort((a, b) => a.order - b.order);
        dispatch(success(faqList));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_FAQ_PLAN_REQUEST,
    };
  }

  function success(faqList) {
    return {
      type: actionTypes.GET_FAQ_PLAN_SUCCESS,
      faqList,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_FAQ_PLAN_FAILURE,
    };
  }
};
export const getFaqEcommerce = () => {
  return dispatch => {
    let url = urlList.GET_FAQ_ECOMMERCE_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let faqList = response.data;
        faqList = faqList.sort((a, b) => a.order - b.order);
        dispatch(success(faqList));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_FAQ_ECOMMERCE_REQUEST,
    };
  }

  function success(faqList) {
    return {
      type: actionTypes.GET_FAQ_ECOMMERCE_SUCCESS,
      faqList,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_FAQ_ECOMMERCE_FAILURE,
    };
  }
};
export const getFaqCheckout = () => {
  return dispatch => {
    let url = urlList.GET_FAQ_CHECKOUT_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let faqList = response.data;
        faqList = faqList.sort((a, b) => a.order - b.order);
        dispatch(success(faqList));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_FAQ_CHECKOUT_REQUEST,
    };
  }

  function success(faqList) {
    return {
      type: actionTypes.GET_FAQ_CHECKOUT_SUCCESS,
      faqList,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_FAQ_CHECKOUT_FAILURE,
    };
  }
};

export const getFaqEducationalPaths = () => {
  return dispatch => {
    let url = urlList.GET_FAQ_EDUCATIONAL_PATHS_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let faqList = response.data;
        faqList = faqList.sort((a, b) => a.order - b.order);
        dispatch(success(faqList));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_FAQ_EDUCATIONAL_PATHS_REQUEST,
    };
  }

  function success(faqList) {
    return {
      type: actionTypes.GET_FAQ_EDUCATIONAL_PATHS_SUCCESS,
      faqList,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_FAQ_EDUCATIONAL_PATHS_FAILURE,
    };
  }
};

export const mockGetContactUs = async () => {
  const contactsUsInfo = await fetch(`/data/cms/getContactUsMock.json`);
  const contactsUsInfoJson = await contactsUsInfo.json();
  return { data: contactsUsInfoJson }
}

export const getContactUs = (isLanding: boolean = false) => {
  return dispatch => {
    let url = isLanding ? urlList.GET_PUBLIC_CONTACT_US_URL : urlList.GET_CONTACT_US_URL;
    const mock = false;

    (mock ?
      mockGetContactUs() :
      axios({
        url,
        method: 'GET',
        headers: isLanding ? null : getHeaders(),
      }))
        .then(response => {
          dispatch(success(response.data));
        })
        .catch(err => {
          dispatch(showError(err));
        });
  };

  function success(payload) {
    return {
      type: actionTypes.GET_CONTACT_US_SUCCESS,
      payload,
    };
  }
};

export const mockGetContactUsCheckout = async () => {
  const contactsUsInfoCheckout = await fetch(`/data/cms/getContactUsMockCheckout.json`);
  const contactsUsInfoJson = await contactsUsInfoCheckout.json();
  return { data: contactsUsInfoJson }
}

export const getContactUsCheckout = () => {
  return dispatch => {
    let url = urlList.GET_CONTACT_US_CHECKOUT_URL;
    const mock = false;

    dispatch(request());
    (mock ? mockGetContactUsCheckout() : axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    }))
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return { type: actionTypes.GET_CONTACT_US_CHECKOUT_REQUEST }
  }

  function success(payload) {
    return {
      type: actionTypes.GET_CONTACT_US_CHECKOUT_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_CONTACT_US_CHECKOUT_FAILURE
    }
  }
};

export const getBrandPageInfo = (l1: string) => {
  return dispatch => {
    if (!l1) {
      console.error('getBrandPageInfo: parameters not valid', { l1 });
      return;
    }

    //URL /cms/brandPage IS NO MORE USED
    let url = urlList.GET_CHANNEL_PAGE_URL;

    //add L1 id as query parameter
    url += '?channel=' + l1;

    // go to PLP if the CMS returns an error
    const CMSInfoError = new CMSBrandInfo();
    CMSInfoError.pageLayout = 'Default_PLP';

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response?.data || !response.data[0]) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(success(CMSInfoError));
          // dispatch(showError(''));
          return;
        }

        let CMSInfo: CMSBrandInfo = response.data[0];
        if (CMSInfo) {
          CMSInfo.l1 = l1;
        }

        // dispatch(setGradientStyle(null, null, CMSInfo));
        dispatch(success(CMSInfo));
      })
      .catch(err => {
        dispatch(error(CMSInfoError));
        dispatch(showError(err));
      });
  };

  function success(payload) {
    return {
      type: actionTypes.GET_BRAND_PAGE_INFO_SUCCESS,
      payload,
    };
  }

  function error(payload) {
    return {
      type: actionTypes.GET_BRAND_PAGE_INFO_SUCCESS,
      payload,
    };
  }
};

export const areL1ImagesChanged = (
  oldUserProfile: UserProfile,
  userProfile: UserProfile
): boolean => {
  let updateL1Images = false;

  if (!userProfile) {
    return false;
  }

  if (oldUserProfile) {
    if (userProfile.followedChannels) {
      userProfile.followedChannels.forEach(channel => {
        if (!oldUserProfile.followedChannels?.includes(channel)) {
          updateL1Images = true;
        }
      });
    }

    if (userProfile.followedBrands) {
      userProfile.followedBrands.forEach(brand => {
        if (!oldUserProfile.followedBrands?.includes(brand)) {
          updateL1Images = true;
        }
      });
    }
  } else {
    return userProfile.followedChannels?.length > 0 || userProfile.followedBrands?.length > 0;
  }

  return updateL1Images;
};

export const getL1Images = (
  channels: Array<string>,
  brands?: Array<string>,
  forceRefresh = false
) => {
  return (dispatch, getState) => {
    if (!channels && !brands) {
      console.warn('getL1Images: parameters not valid', { channels, brands });
      return;
    } else if (channels?.length === 0 && brands?.length === 0) {
      console.warn('getL1Images: empty parameters', { channels, brands });
      return;
    }

    let newChannels = [];
    let newBrands = [];
    const L1Images = getState().cms.L1Images;
    if (forceRefresh || !L1Images) {
      //force image refresh
      newChannels = channels ? channels : [];
      newBrands = brands ? brands : [];
    } else {
      //get only new images
      //not get again images that we already have
      if (channels?.length > 0) {
        channels.forEach(a => {
          if (!L1Images[a]) {
            newChannels.push(a);
          }
        });
      }
      if (brands?.length > 0) {
        brands.forEach(a => {
          if (!L1Images[a]) {
            newBrands.push(a);
          }
        });
      }
    }

    //if not new images --> return
    if (newChannels.length === 0 && newBrands.length === 0) {
      console.log('getL1Images - no new images to get');
      return;
    }

    dispatch(request());

    let url = urlList.GET_L1_IMAGE_URL + '?additionalParams=';

    //add parameters
    if (newChannels.length > 0) {
      const channelsParams = newChannels.join(',');
      url += channelsParams;
    }
    if (newChannels.length > 0 && newBrands.length > 0) {
      url += ',';
    }
    if (newBrands.length > 0) {
      const brandsParams = newBrands.join(',');
      url += brandsParams;
    }

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response?.data || !response.data[0]) {
          console.log(url + ' returned empty response', response?.data);
          // dispatch(success(CMSInfoError));
          // dispatch(showError(''));
          return;
        }

        const CMSL1ImageResponse: CMSL1Image = response.data[0];
        let L1Images = {};
        CMSL1ImageResponse.items.forEach(item => {
          let l1Id = item.subjectTaxonomy?.filter(
            a => a?.externalReference !== SHOW_L1_LABEL.SHOW
          )?.[0]?.externalReference;

          //should be useless because bff converts university-RB to brand_RB
          if (l1Id.includes('university-')) {
            l1Id = l1Id.replace('university-', '');
            l1Id = getL1IdFromBrandId(l1Id);
          }

          L1Images[l1Id] = {
            img: item.data,
            alt: item.alt,
            showL1Label: !!item.subjectTaxonomy?.find(
              a => a?.externalReference === SHOW_L1_LABEL.SHOW
            ),
            imageContentId: item.contentId
          };
        });

        //add empty data to not invoke infinitely the request
        channels?.forEach(channel => {
          if (!L1Images[channel]) {
            L1Images[channel] = {};
          }
        });
        brands?.forEach(brand => {
          if (!L1Images[brand]) {
            L1Images[brand] = {};
          }
        });

        console.log('L1Images', L1Images);

        dispatch(success(L1Images));
      })
      .catch(err => {
        dispatch(error());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_L1_IMAGE_REQUEST,
    };
  }

  function success(L1Images) {
    return {
      type: actionTypes.GET_L1_IMAGE_SUCCESS,
      L1Images,
    };
  }

  function error() {
    return {
      type: actionTypes.GET_L1_IMAGE_FAILURE,
    };
  }
};

export const getHowToInfo = () => {
  return dispatch => {
    let url = urlList.HOWTO_PAGE_URL;
    dispatch(request());

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response?.data || !response.data[0]) {
          console.error(url + ' returned empty response', response?.data);
          dispatch(error());
          dispatch(showError());
          return;
        }

        const howToInfo: HowToInfo = response.data[0];

        dispatch(success(howToInfo));
      })
      .catch(err => {
        dispatch(error());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_HOWTO_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: actionTypes.GET_HOWTO_SUCCESS,
      payload,
    };
  }

  function error() {
    return {
      type: actionTypes.GET_HOWTO_FAILURE,
    };
  }
};

export const getHpBeans = (labelLang: string) => {
  return (dispatch, getState) => {
    if (labelLang === getState().utils.labelLang && getState().cms.hpBeans?.length > 0) {
      return;
    }

    let url = urlList.GET_BEANS_URL;

    dispatch(request());
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let hpBeans: HpBean[] = response.data;
        hpBeans = hpBeans.sort((a, b) => a.order - b.order);
        dispatch(success(hpBeans, labelLang));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_BEANS_REQUEST,
    };
  }

  function success(hpBeans, language) {
    return {
      type: actionTypes.GET_BEANS_SUCCESS,
      hpBeans,
      language,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_BEANS_FAILURE,
    };
  }
};

export const getPlanCarouselInfo = () => {
  return dispatch => {
    let url = urlList.GET_PLAN_CAROUSEL_INFO_URL;

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response?.data || !response.data[0]) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(success({}));
          return;
        }

        const CMSInfo: CMSBrandInfo = response.data[0];
        dispatch(success(CMSInfo));
      })
      .catch(err => {
        console.error(url + ' error: ', err);

        //call success with {} as parameter --> the application will use the fallback settings for the plan carousel
        dispatch(success({}));

        // dispatch(failure());
        // dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_PLAN_CAROUSEL_INFO_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: actionTypes.GET_PLAN_CAROUSEL_INFO_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_PLAN_CAROUSEL_INFO_FAILURE,
    };
  }
};

const mockGetCMSPlanPageInfos = async () => {
  const planPageInfos = await fetch(`data/cms/PlanPageMock.json`);
  const planPageInfosJson = await planPageInfos.json();
  return { data: planPageInfosJson };
}

export const getCMSPlanPageInfos = () => {
  const mock = false;

  return (dispatch, getState) => {
    if (!getState().authentication.isAuthenticated) {
      return;
    }
    let url = urlList.GET_PLAN_PAGE_INFO_URL;

    dispatch(request());

    (mock ?
      mockGetCMSPlanPageInfos()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: any) => {
        const CMSPlanPageInfos = response?.data;

        if (!CMSPlanPageInfos) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(failure());
          dispatch(showError(''))
          return;
        }

        dispatch(success(CMSPlanPageInfos, getState().user.userProfile?.preferredLang))
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  }

  function request() {
    return {
      type: actionTypes.GET_PLAN_PAGE_INFOS_REQUEST,
    }
  }

  function success(payload, preferredLang) {
    return {
      type: actionTypes.GET_PLAN_PAGE_INFOS_SUCCESS,
      payload,
      preferredLang,
    }
  }

  function failure() {
    return {
      type: actionTypes.GET_PLAN_PAGE_INFOS_FAILURE
    };
  }
}

const mockGetHeroBannerInfo = async () => {
  const heroBannerInfo = await fetch(`/data/cms/heroBannerInfoMockTortona2.json`);
  const heroBannerInfoJson: HeroBannerInfoResponse['data'] = await heroBannerInfo.json();
  return { data: heroBannerInfoJson };
};

export const getHeroBannerInfo = () => {
  const mock = false;

  return (dispatch, getState) => {
    if (!getState().authentication.isAuthenticated) {
      return;
    }
    let url = urlList.GET_HERO_BANNER_INFO_URL;

    dispatch(request());

    (mock
      ? mockGetHeroBannerInfo()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: HeroBannerInfoResponse) => {
        const HeroBannerItems = response?.data;

        if (!HeroBannerItems) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(failure());
          dispatch(showError(''));
          return;
        }

        dispatch(success(HeroBannerItems, getState().user.userProfile?.preferredLang));
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_HERO_BANNER_INFO_REQUEST,
    };
  }

  function success(payload, preferredLang) {
    return {
      type: actionTypes.GET_HERO_BANNER_INFO_SUCCESS,
      payload,
      preferredLang,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_HERO_BANNER_INFO_FAILURE,
    };
  }
};

const mockGetTortonaInfo = async () => {
  const tortonaInfo = await fetch(`/data/cms/infoMockTortona.json`);
  const tortonaInfoJson: InfoTortonaResponse['data'] = await tortonaInfo.json();
  return { data: tortonaInfoJson };
};

export const getTortonaInfo = () => {
  const mock = false;

  return (dispatch, getState) => {
    if (!getState().authentication.isAuthenticated) {
      return;
    }
    let url = urlList.GET_TORTONA_INFO_URL;

    dispatch(request());

    (mock
      ? mockGetTortonaInfo()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: InfoTortonaResponse) => {
        const TortonaInfoItems = response?.data;

        if (!TortonaInfoItems) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(failure());
          dispatch(showError(''));
          return;
        }

        dispatch(success(TortonaInfoItems, getState().user.userProfile?.preferredLang));
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_INFO_TORTONA_REQUEST,
    };
  }

  function success(payload, preferredLang) {
    return {
      type: actionTypes.GET_INFO_TORTONA_SUCCESS,
      payload,
      preferredLang,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_INFO_TORTONA_FAILURE,
    };
  }
};

const mockGetOnboardingOverviewCMS = async () => {
  // const onboardingOverview = await fetch(`/data/cms/onboardingOverviewFallback.json`);
  const onboardingOverview = await fetch(`/data/cms/onboardingOverview.json`);
  const onboardingOverviewJson: CMSOnboarding = await onboardingOverview.json();
  return promiseMock({ data: onboardingOverviewJson }, false, 3000);
};

export const getOnboardingOverviewCMS = (course: Course) => {
  const mock = false;

  return (dispatch, getState) => {
    if (!getState().authentication.isAuthenticated) {
      return;
    }
    if (!course?.pointerOnboard) {
      console.log('getOnboardingOverviewCMS() - pointer not found in course', course);
      return;
    }

    const userProfile = getState().user.userProfile;

    let url = (
      isCourseOnBoardingWholesale(course)
        ? urlList.GET_ONBOARDING_WHS_OVERVIEW_URL
        : isCourseMaster(course)
          ? urlList.GET_ONBOARDING_OVERVIEW_MASTER_URL
          : urlList.GET_ONBOARDING_OVERVIEW_URL
    )
      ?.replace('{language}', userProfile?.preferredLang)
      ?.replace('{pointer}', course.pointerOnboard);

    dispatch(request());

    (mock
      ? mockGetOnboardingOverviewCMS()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: CMSOnboardingResponse) => {
        let onboardingOverview = response?.data?.[0];

        if (!onboardingOverview) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(failure());
          dispatch(showError(''));
          return;
        }

        //FALLBACK ARE REMOVED BY BFF --> DO NOTHING IN FE
        //if row1 has more than one item --> remove fallback item
        // let rows1Items = onboardingOverview?.row1?.[0]?.items || [];
        // if (rows1Items.length > 1) {
        //     onboardingOverview.row1[0].items = rows1Items.filter(a => a.subjectTaxonomy?.findIndex(a => a?.externalReference?.toLowerCase() === 'fallback') < 0);
        // }

        //FALLBACK ARE REMOVED BY BFF --> DO NOTHING IN FE
        //if row2 has more than one item --> remove fallback item
        // let rows2Items = onboardingOverview?.row2?.[0]?.items || [];
        // if (rows2Items.length > 1) {
        //     onboardingOverview.row2[0].items = rows2Items.filter(a => a.subjectTaxonomy?.findIndex(a => a?.externalReference?.toLowerCase() === 'fallback') < 0);
        // }

        dispatch(
          success(
            onboardingOverview,
            getState().user.userProfile?.preferredLang,
            course.courseIdMaster
          )
        );
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ONBOARDING_OVERVIEW_REQUEST,
    };
  }

  function success(payload, preferredLang, courseIdMaster) {
    return {
      type: actionTypes.GET_ONBOARDING_OVERVIEW_SUCCESS,
      payload,
      preferredLang,
      courseIdMaster,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_ONBOARDING_OVERVIEW_FAILURE,
    };
  }
};

const mockGetCMSTeachers = async () => {
  const content = await fetch(`/data/master/teachers.json`);
  const contentJson = await content.json();
  return promiseMock({ data: contentJson }, false, 3000);
};

export const getCMSTeachers = (teachers: TrainerExtended[], courseIdMaster: string) => {
  return (dispatch, getState) => {
    const mock = false;
    if (
      !getState().authentication.isAuthenticated ||
      !teachers ||
      teachers.length === 0 ||
      !courseIdMaster
    ) {
      return;
    }

    const userProfile = getState().user.userProfile;

    let url =
      urlList.GET_ONBOARDING_TEACHERS_URL +
      '?teacherUsernameList=' +
      teachers
        .filter(a => !!a.username)
        .map(a => a.username)
        .join(',');
    dispatch(request());

    (mock
      ? mockGetCMSTeachers()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: CMSTeacherResponse) => {
        handleTeachers(teachers, response, dispatch, userProfile, courseIdMaster);
      })
      .catch(err => {
        handleTeachers(teachers, {}, dispatch, userProfile, courseIdMaster);
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_ONBOARDING_TEACHERS_REQUEST,
    };
  }

  function success(teachers, preferredLang, courseIdMaster) {
    return {
      type: actionTypes.GET_ONBOARDING_TEACHERS_SUCCESS,
      teachers,
      preferredLang,
      courseIdMaster,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_ONBOARDING_TEACHERS_FAILURE,
    };
  }

  function handleTeachers(teachers, response, dispatch, userProfile, courseIdMaster) {
    let teachersExtendedCMS = teachers;
    let teachersCMS = response?.data;

    teachersExtendedCMS = teachersExtendedCMS.map(teacherExtendedCMS => {
      let teacherCMS = teachersCMS?.[teacherExtendedCMS.username];

      //merge moodle info with CMS info
      return {
        ...teacherExtendedCMS,
        fullName: getTrainerFullName(teacherExtendedCMS),
        location: teacherCMS?.location || '',
        bio: teacherCMS?.bio || '',
        richText2: teacherCMS?.teacherInfo || '',
        contentId: teacherCMS?.contentId
      };
    });
    //sort teacher by
    teachersExtendedCMS.sort((a, b) =>
      a.fullName?.toLowerCase().localeCompare(b.fullName?.toLowerCase())
    );

    dispatch(success(teachersExtendedCMS, userProfile?.preferredLang, courseIdMaster));
  }
};

const mockGetBuildYourCareerInfos = async () => {
  const content = await fetch(`/data/cms/buildYourCareerMock.json`);
  const contentJson = await content.json();
  return promiseMock({ data: contentJson }, false, 3000);
}


export const getBuildYourCareerInfos = () => {
  const mock = false;

  return (dispatch, getState) => {
    if (!getState().authentication.isAuthenticated) {
      return
    }

    let url = urlList.GET_BUILD_YOUR_CAREER_URL;

    dispatch(request());

    (mock
      ? mockGetBuildYourCareerInfos()
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response: BuildYourCareerResponse) => {
        const buildYourCareerInfos = response?.data;

        if (!buildYourCareerInfos) {
          console.log(url + ' returned empty response', response?.data);
          dispatch(failure());
          dispatch(showError(''));
          return
        }

        dispatch(success(buildYourCareerInfos, getState().user.userProfile?.preferredLang))
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });

  };

  function request() {
    return {
      type: actionTypes.GET_BUILD_YOUR_CAREER_REQUEST
    };
  }

  function success(buildYourCareerInfos, preferredLang) {
    return {
      type: actionTypes.GET_BUILD_YOUR_CAREER_SUCCESS,
      buildYourCareerInfos,
      preferredLang
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_BUILD_YOUR_CAREER_FAILURE
    };
  }
}