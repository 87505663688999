import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector } from './redux';
import { Course, CourseL1, CoursesMap, LangMap, LevelEnrichedMap } from '@model/CoursesClass';
import { getL1Element, isCourseVisible } from '@utility/Api';
import { sortCourses } from '@utility/sortUtility';
import { queryParams, SORTBY } from '@utility/const';
import { TrainingPill, TrainingPillResponse } from '@model/ProductClass';
import queryString from 'query-string';
import { ChannelInfoObject } from '@model/SearchResult';
import { searchTabValues } from '@components/Search/SearchTabs';

const useSearch = (
  considerL1: boolean = false,
  justFirstResults: boolean = true
): [
  number,
  Dispatch<SetStateAction<number>>,
  Course[],
  string[],
  number,
  TrainingPill[],
  number,
  TrainingPill[],
  number,
  string,
  string
] => {
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const searchCourseIds: string[] = useSelector(state => state.search.searchCourseIds);
  const searchL1s: string[] = useSelector(state => state.search.searchL1s);
  const channelsInfo: ChannelInfoObject[] = useSelector(state => state.search.channelsInfo);
  const sectionL1: string[] = useSelector(state => state.search.sectionL1);
  const searchTrainingPills: TrainingPillResponse = useSelector(
    state => state.search.searchTrainingPills
  );
  const searchNovelties: TrainingPillResponse = useSelector(state => state.search.searchNovelties);
  const suggestedPhrase: string = useSelector(state => state.search.suggestedPhrase);
  const suggestedPhraseTP: string = useSelector(state => state.search.suggestedPhraseTP);
  const suggestedPhraseNovelties: string = useSelector(
    state => state.search.suggestedPhraseNovelties
  );
  const searchValRequest: string = useSelector(state => state.search.searchValRequest);

  const lang: LangMap = useSelector(state => state.utils.lang);
  const l1Map: LevelEnrichedMap = useSelector(state => state.utils.l1Map);
  const L1Images: any[] = useSelector(state => state.cms.L1Images);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [defaultCurrentTab, setDefaultCurrentTab] = useState<number>(0);
  const [searchCourses, setSearchCourses] = useState<Course[]>([]);
  const [searchCoursesNum, setSearchCoursesNum] = useState<number>(0);
  const [searchCoursesNoL1Num, setSearchCoursesNoL1Num] = useState<number>(0);

  const [searchTrainingPillsArray, setSearchTrainingPillsArray] = useState<TrainingPill[]>([]);
  const [searchTrainingPillsNum, setSearchTrainingPillsNum] = useState<number>(0);

  const [searchNoveltiesArray, setSearchNoveltiesArray] = useState<TrainingPill[]>([]);
  const [searchNoveltiesNum, setSearchNoveltiesNum] = useState<number>(0);

  const [suggestedPhraseTab, setSuggestedPhraseTab] = useState<string>('');
  const [useSearchVal, setUseSearchVal] = useState<string>(undefined);

  let L1_BRAND_PREFIX = 'brand_';

  useEffect(() => {
    let urlParams = queryString.parse(window.location.search);
    if (urlParams && urlParams[queryParams.SEARCH_TAB]) {
      setDefaultCurrentTab(+urlParams[queryParams.SEARCH_TAB]);
    }
  }, []);

  useEffect(() => {
    let newSearchCourses = [];
    const insertedCourses = {};
    if (searchCourseIds?.length > 0 && coursesMap) {
      //searchCourseIds should contain just parent courses id
      searchCourseIds.forEach(searchCourseId => {
        const searchCourse = coursesMap[searchCourseId];

        if (
          searchCourse &&
          isCourseVisible(searchCourse) &&
          !insertedCourses[searchCourse.courseIdMaster]
        ) {
          newSearchCourses.push(searchCourse);
          insertedCourses[searchCourse.courseIdMaster] = true;
        }
      });
    }

    let searchL1sNum = 0;
    if (searchL1s?.length > 0) {
      let numFallbackImage = 0;
      searchL1s.forEach(searchL1 => {
        console.log('searchL1', searchL1);

        let l1 = getL1Element(searchL1, l1Map, L1Images, lang);

        channelsInfo?.forEach(info => {
          let infoChannelBrand = L1_BRAND_PREFIX + info.channelId;
          if (info.channelId === searchL1 || infoChannelBrand === searchL1) {
            l1.channelInfos = info;
          }
        });

        let searchCourse = new CourseL1();
        searchCourse = {
          ...searchCourse,
          courseId: 'l1_' + searchL1,
          courseIdMaster: 'l1_' + searchL1,
          l1: l1,
        };

        if (searchCourse && !insertedCourses[searchCourse.courseIdMaster]) {
          if (considerL1) {
            newSearchCourses.push(searchCourse);
            insertedCourses[searchCourse.courseIdMaster] = true;
          } else {
            searchL1sNum++;
          }
        }

        numFallbackImage++;
      });

      //CONSIDER CHILDREN
      // const mapSortSearchCourse = {};
      // Object.values(coursesMap).forEach(course => {
      //   let searchCourse = null;
      //   const index = searchCourseIds.findIndex(a => a === course.courseId);
      //   if (index >= 0) {
      //     searchCourse = course;
      //     mapSortSearchCourse[course.courseId] = index;
      //   } else {
      //     course.childCourses.forEach((child) => {
      //       const childIndex = searchCourseIds.findIndex(a => a === child.courseId);
      //       if (childIndex >= 0) {
      //         searchCourse = course;
      //         mapSortSearchCourse[course.courseId] = childIndex;
      //       }
      //     })
      //   }

      //   if (searchCourse && !insertedCourses[searchCourse.courseId]) {
      //     if (isLearningPath(searchCourse)) {
      //       newSearchLps.push(searchCourse);
      //     } else {
      //       newSearchCourses.push(searchCourse);
      //     }

      //     insertedCourses[searchCourse.courseId] = true;
      //   }
      // });

      // if (keepSearchSorting) {
      //   newSearchCourses = newSearchCourses.sort((a, b) => mapSortSearchCourse[a.courseId] - mapSortSearchCourse[b.courseId]);
      //   newSearchLps = newSearchLps.sort((a, b) => mapSortSearchCourse[a.courseId] - mapSortSearchCourse[b.courseId]);
      // }
    }

    //sort courses (first L1, then LP, then lessons)
    newSearchCourses = sortCourses(newSearchCourses, SORTBY.RELEVANCE_SEARCH, null);

    //get courses number before slicing
    setSearchCoursesNum(newSearchCourses.length + searchL1sNum);
    setSearchCoursesNoL1Num(newSearchCourses.length);
    if (justFirstResults) {
      newSearchCourses = newSearchCourses.slice(0, 4);
    }

    setSearchCourses(newSearchCourses);
    setUseSearchVal(!!coursesMap ? searchValRequest : undefined);
  }, [searchCourseIds, searchL1s, channelsInfo, sectionL1, coursesMap, lang, l1Map, L1Images]);

  useEffect(() => {
    console.log('useSearch searchTrainingPills', searchTrainingPills);
    setSearchTrainingPillsNum(searchTrainingPills?.elementsNum || 0);

    let newSearchTrainingPills =
      searchTrainingPills?.items?.length > 0 ? searchTrainingPills.items : [];
    if (justFirstResults) {
      newSearchTrainingPills = newSearchTrainingPills.slice(0, 4);
    }

    setSearchTrainingPillsArray(newSearchTrainingPills);
  }, [searchTrainingPills]);

  useEffect(() => {
    setSearchNoveltiesNum(searchNovelties?.elementsNum || 0);

    let newSearchNovelties = searchNovelties?.items?.length > 0 ? searchNovelties.items : [];
    if (justFirstResults) {
      newSearchNovelties = newSearchNovelties.slice(0, 4);
    }

    setSearchNoveltiesArray(newSearchNovelties);
  }, [searchNovelties]);

  useEffect(() => {
    const isSeachCoursesEmpty = searchCoursesNoL1Num === 0;
    const isSearchTrainingPillsEmpty = searchTrainingPillsNum === 0;
    const isSeachNoveltiesEmpty = searchNoveltiesNum === 0;

    const firstNotEmptyTab = [
      {
        tabOrder: searchTabValues.courses,
        empty: isSeachCoursesEmpty,
      },
      {
        tabOrder: searchTabValues.trainingPills,
        empty: isSearchTrainingPillsEmpty,
      },
      {
        tabOrder: searchTabValues.novelties,
        empty: isSeachNoveltiesEmpty,
      },
    ]
      .filter(a => !a.empty)
      .sort((a, b) => a.tabOrder - b.tabOrder)?.[0];

    //handle tabs
    if (firstNotEmptyTab) {
      if (currentTab === searchTabValues.courses && isSeachCoursesEmpty) {
        setCurrentTab(firstNotEmptyTab.tabOrder);
      } else if (currentTab === searchTabValues.trainingPills && isSearchTrainingPillsEmpty) {
        setCurrentTab(firstNotEmptyTab.tabOrder);
      } else if (currentTab === searchTabValues.novelties && isSeachNoveltiesEmpty) {
        setCurrentTab(firstNotEmptyTab.tabOrder);
      }
    } else {
      setCurrentTab(defaultCurrentTab);
    }
  }, [searchCoursesNoL1Num, searchTrainingPillsNum, searchNoveltiesNum]);

  useEffect(() => {
    switch (currentTab) {
      case searchTabValues.courses:
        setSuggestedPhraseTab(suggestedPhrase);
        break;
      case searchTabValues.trainingPills:
        setSuggestedPhraseTab(suggestedPhrase);
        break;
      case searchTabValues.novelties:
        setSuggestedPhraseTab(suggestedPhraseNovelties);
        break;
    }
  }, [currentTab, suggestedPhrase, suggestedPhraseTP, suggestedPhraseNovelties]);

  return [
    currentTab,
    setCurrentTab,
    searchCourses,
    searchCourseIds,
    searchCoursesNum,
    searchTrainingPillsArray,
    searchTrainingPillsNum,
    searchNoveltiesArray,
    searchNoveltiesNum,
    suggestedPhraseTab,
    useSearchVal,
  ];
};
export default useSearch;
