import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimationOpacity = ({
  initialOpacity = 0,
  duration = 1,
  extraProps = {},
  className = '',
  intersectionObserver = false, // if it should animate when inView
  isStagger = false,
  delay = 0,
  useSectionTagHtml,
  children,
}) => {
  const viewRef = React.useRef(false);

  const { ref, inView } = useInView({});

  React.useEffect(() => {
    if (inView) {
      viewRef.current = true;
    }
  }, [inView]);

  const variants = {
    enter: {
      opacity: initialOpacity,
    },
    center: {
      opacity: 1,
    },
  };

  const transition = {
    opacity: { duration: duration },
    duration,
    delay,
  };

  const getAnimate = () => {
    if (isStagger) {
      return undefined;
    }
    if (inView || viewRef.current || !intersectionObserver) {
      return 'center';
    }

    return 'enter';
  };

  const Wrapper = useSectionTagHtml ? motion.section : motion.div;

  return (
    <Wrapper
      variants={variants}
      initial="enter"
      animate={getAnimate()}
      transition={transition}
      className={'animation-opacity ' + className}
      ref={ref}
      {...extraProps}
    >
      {children}
    </Wrapper>
  );
};

export default AnimationOpacity;
