import React from "react";
import { USER_URLS } from "@components/link-utils";
import { printHTML } from "@utility/Api";
import { LangMapEcomm } from "@model/CoursesClass";
import { useSelector } from "@hooks/redux";

type RefundAndCancellationPolicyProps = {
  messageKey?: keyof LangMapEcomm | "ACCEPT_TERMS_CONDITIONS";
  className?: string;
  firstUrlTitleKey?: string;
  secondUrlTitleKey?: string;
}
const RefundAndCancellationPolicy = (
  {
    messageKey = "LICENSE_REFUND_POLICY",
    className = "",
    firstUrlTitleKey = "",
    secondUrlTitleKey = "",
  }: RefundAndCancellationPolicyProps
) => {
  const lang: LangMapEcomm = useSelector((state) => state.utils.lang);


  return (
    <p
      className={`refund-cancellation-policy ${className}`}
      aria-label={lang?.[messageKey]?.replace("{", "")?.replace("{", "")?.replace("}", "")?.replace("}", "")}
      role="region"
      dangerouslySetInnerHTML={printHTML(
        lang?.[messageKey]?.replace(
          "{",
          '<a title="' + lang[firstUrlTitleKey] + '" rel="noopener noreferrer" class="text-btn-no-arrow small" href="' +
          USER_URLS.LEGAL.URL +
          '">'
        )
          ?.replace("}", "</a>")
          ?.replace(
            "{",
            '<a title="' + lang[secondUrlTitleKey] + '" rel="noopener noreferrer" class="text-btn-no-arrow small" href="' +
            USER_URLS.LEGAL.URL +
            '">'
          )
          ?.replace("}", "</a>")
      , { ADD_ATTR: ['target'] })}
    />
  );
};

export default RefundAndCancellationPolicy;
