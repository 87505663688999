export type UserResponse = {
  freeText?: string;
  id?: number;
  questionId: number;
  responseId: number;
  surveyResponseId?: number;
}

export class SurveyResponse {
  compilationDate: number = (new Date()).getTime();
  courseId?: string;
  id?: number;
  surveyId: number;
  username: string;
  userResponses: Array<UserResponse> = [];
}