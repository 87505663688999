import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React from "react";
import { useDispatch } from "react-redux";
import CartFilledIcon from "@svg-icons/bag-filled.svg";
import CartIcon from "@svg-icons/empty-bag.svg";
import ButtonV2 from "@components/UI/ButtonV2";
import Checkbox from "@components/UI/Checkbox";
import { useForm } from "react-hook-form";
import * as actions from "@redux-actions/";
import { useSelector } from "@hooks/redux";
import ModalEvolution from "./ModalEvolution";

type ModalCartProps = {
  show: boolean;
  shoppable: boolean;
  close: () => void;
};

type FormValues = {
  "dont-show-message-again": boolean;
};
const ModalCart = ({ shoppable, show, close }: ModalCartProps) => {
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);

  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    if (data["dont-show-message-again"]) {
      console.log(`don't show message again`);

      dispatch(actions.setNoShowModalCart(true, true));
    }

    close();
  };

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="cart"
      headerElement={
        shoppable
          ?
          <CartFilledIcon fill="currentColor" className="bag-filled cart-animation" />
          :
          <CartIcon fill="currentColor" />
      }
      title={lang?.CART_MODAL_TITLE}
      descriptionElement={
        <>
          <p>{lang?.CART_MODAL_MESSAGE}</p>
          <p>{lang?.GOT_IT}</p>
        </>
      }
      footerElement={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Checkbox
            text={lang?.DONT_SHOW_MESSAGE_AGAIN}
            name="dont-show-message-again"
            ref={register}
          />
          <ButtonV2 variant="primary">{lang?.GOT_IT2}</ButtonV2>
        </form>
      }
    />
  );
};

export default ModalCart;
