import { getBackgroundGradientCss, getColorByColorPicker } from "@utility/gradient-utils";
import { CMSBrandInfo } from "./CMSBrand";

export class GradientClass {
    range: GradientRange;
    gradient: GradientVariant;
}

export class GradientRange {
    value: number;
}

export class GradientVariant {
    variant: GRADIENT_VARIANT_ENUM;
    type: 'radial-gradient' | 'linear-gradient';
    orientation: string;
    opacity: string; //opacity in hex format
    colors: Array<GradientColor>;
    otherStyle?: any = {};
    imageStyle?: any = {};
}

export class GradientColor {
    hex: string;
    position: number = 0;
}

export enum GRADIENT_VARIANT_ENUM {
    defaultRadial = 'defaultRadial',
    doubleLayer1 = 'doubleLayer1',
    doubleLayer2 = 'doubleLayer2',
    defaultLinear = 'defaultLinear',
}

export class GradientStyle {
    [GRADIENT_VARIANT_ENUM.defaultRadial]: any;
    [GRADIENT_VARIANT_ENUM.doubleLayer1]: any;
    [GRADIENT_VARIANT_ENUM.doubleLayer2]: any;
    [GRADIENT_VARIANT_ENUM.defaultLinear]: any;
    mainColor: any;

    constructor(colorPickerValue: number, gradientEnable: boolean, brandPageInfo?: CMSBrandInfo) {
        this.defaultRadial = getBackgroundGradientCss(colorPickerValue, GRADIENT_VARIANT_ENUM.defaultRadial, gradientEnable, brandPageInfo);
        this.doubleLayer1 = getBackgroundGradientCss(colorPickerValue, GRADIENT_VARIANT_ENUM.doubleLayer1, gradientEnable, brandPageInfo);
        this.doubleLayer2 = getBackgroundGradientCss(colorPickerValue, GRADIENT_VARIANT_ENUM.doubleLayer2, gradientEnable, brandPageInfo);
        this.defaultLinear = getBackgroundGradientCss(colorPickerValue, GRADIENT_VARIANT_ENUM.defaultLinear, gradientEnable, brandPageInfo);
        this.mainColor = getColorByColorPicker(colorPickerValue, brandPageInfo)
    }

}