import userReducer from './userReducer';
import cmsReducer from './cmsReducer';
import authenticationReducer, { initialState as authenticationReducerInitialState } from './authenticationReducer';
import utilsReducer, { initialState as utilsReducerInitialState } from './utilsReducer';
import courseReducer from './coursesReducer';
import reportManagerReducer from './reportManagerReducer';
import firebaseReducer, { initialState as firebaseReducerInitialState } from './firebaseReducer';
import searchReducer from './searchReducer';
import trainingPillsReducer from './trainingPillsReducer';
import ecommerceReducer from './ecommerceReducer';
import { LangMap } from '@model/CoursesClass';

type ReducerType<T> = (state: T, action: any) => T;


const rootReducer = {
    authentication: authenticationReducer as ReducerType<typeof authenticationReducerInitialState>,
    utils: utilsReducer as (() => typeof utilsReducerInitialState & { lang: LangMap }),
    user: userReducer,
    course: courseReducer,
    reportManager: reportManagerReducer,
    cms: cmsReducer,
    firebase: firebaseReducer as ReducerType<typeof firebaseReducerInitialState>,
    search: searchReducer,
    trainingPills: trainingPillsReducer,
    ecommerce: ecommerceReducer,
};

export default rootReducer;