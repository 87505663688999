import React from "react";
import LazySVG from "@components/LazySvg";
import { PREBOARDING_IMAGE2_DESK, PREBOARDING_IMAGE2_SMARTPHONE, PREBOARDING_IMAGE2_TABLET, PREBOARDING_IMAGE_DESK, PREBOARDING_IMAGE_SMARTPHONE, PREBOARDING_IMAGE_TABLET, PREBOARDING_IMAGE_LOGIN_SMARTPHONE, PREBOARDING_IMAGE_LOGIN_TABLET, PREBOARDING_IMAGE_LOGIN_DESK } from "@utility/const";
import clsx from "@utility/clsx";

export type Props = {
  className?: string;
  classNameImg?: string;
  classNameImgDesk?: string;
  classNameImgSmartphone?: string;
  classNameImgTablet?: string;
  variant?: BackgroundEvolutionVariants;
};

export type BackgroundEvolutionVariants = keyof typeof variants;

const variants = {
  background_1: {
    imgDesk: PREBOARDING_IMAGE_DESK,
    imgSmartphone: PREBOARDING_IMAGE_SMARTPHONE,
    imgTablet: PREBOARDING_IMAGE_TABLET,
  },
  background_2: {
    imgDesk: PREBOARDING_IMAGE2_DESK,
    imgSmartphone: PREBOARDING_IMAGE2_SMARTPHONE,
    imgTablet: PREBOARDING_IMAGE2_TABLET,
  },
  login: {
    imgDesk: PREBOARDING_IMAGE_LOGIN_DESK,
    imgSmartphone: PREBOARDING_IMAGE_LOGIN_SMARTPHONE,
    imgTablet: PREBOARDING_IMAGE_LOGIN_TABLET,
  }
} as const;

const BackgroundEvolution = ({
  className = '',
  classNameImg = '',
  classNameImgDesk = '',
  classNameImgSmartphone = '',
  classNameImgTablet = '',
  variant = "background_1",
}: Props) => {

  return (
    <div className={clsx({ [className]: className })}>
      <LazySVG
        className={clsx("background-evolution-img desktop", { [classNameImg]: classNameImg, [classNameImgDesk]: classNameImgDesk })}
        src={variants[variant].imgDesk}
        alt="gradient background desktop"
      />
      <LazySVG
        className={clsx("background-evolution-img phone", { [classNameImg]: classNameImg, [classNameImgSmartphone]: classNameImgSmartphone })}
        src={variants[variant].imgSmartphone}
        alt="gradient background smartphone"
      />
      <LazySVG
        className={clsx("background-evolution-img tablet", { [classNameImg]: classNameImg, [classNameImgTablet]: classNameImgTablet })}
        src={variants[variant].imgTablet}
        alt="gradient background tablet"
      />
    </div>
  );
};

export default BackgroundEvolution;
