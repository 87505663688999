export default {
  TP_CAROUSEL_TITLE: "",
  TP_CAROUSEL_DESCRIPTION: "",
  IN_YOUR_STORE: "",
  IN_MY_STORE: "",
  SORT_MOST_RECENT_SHIPMENT: "",
  SORT_AZ_MODEL: "",
  SORT_ZA_MODEL: "",
  SORT_LATEST_RELEASE: "",
  MONTH_SHIPMENT: "",
  COLLECTION: "",
  BROWSE_ALL_MODELS: "",
  BACK_PLATFORM: "",
  COURSES_LESSONS: "",
  BESTSELLER: "",
  RXABLE: "",
  POLARIZED: "",
  ADVERTISING: "",
  TP_PRODUCT_CATEGORY: "",
  TP_SUN: "",
  TP_OPTICAL: "",
  TP_GOGGLES: "",
  TP_CLIPON: "",
  TP_HALF_EYES: "",
  TP_WEARABLE: "",
  TP_PRESCRIPTION: "",
  TP_SUN_PRESCRIPTION_LENS: "",
  TP_HELMETS: "",
  TP_ELECTRONICS_OPTICAL: "",
  TP_AFA_FOOTWEAR: "",
  TP_AFA_ACCESSORIES: "",
  TP_AFA_APPAREL: "",
  TP_INSTRUMENT: "",
  TP_INSTRUMENT_SPARE_PARTS: "",
  TP_SNOW_GOGGLES: "",
  TP_SNOW_GOGGLES_ACCESSORIES: "",
  TP_ACCESSORIES: "",
  TP_MX_GOGGLES: "",
  TP_MX_GOGGLES_ACCESSORIES: "",
  TP_ELECTRONICS_SNOW_GOGGLE: "",
  TP_SPARE_PARTS: "",
  TP_WHATCHES: "",
  TP_FACE_MASK: "",
  TP_RX_OCP_MATERIAL: "",
  TP_MISCELLANEOUS: "",
  TP_OTHER: "",
  TP_NOT_DEFINED: "",
  TP_FEMALE: "",
  TP_MALE: "",
  TP_DETAILS: "",
  TP_GENDER: "",
  TP_THEME: "",
  TP_MATERIAL: "",
  TP_SIZE_AVAILABLE: "",
  TP_INSPIRATION: "",
  TP_OPTICIAN_TIPS: "",
  TP_ADVERTISING_CAMPAIGN: "",
  TP_FRONTAL: "",
  TP_TEMPLE: "",
  TP_OTHER_TIPS: "",
  TP_COLORS_MAY_VARY: "",
  TP_MODEL: "",
  TP_MODEL_CODE: "",
  TP_MODEL_NAME: "",
  TP_SHIPMENT_DATE: "",
  TP_NEW: "",
  TP_NEW_PICKS_FOR_YOU: "",
  TP_NEW_COLLECTION: "",
  TP_SHIPMENT_NUMBER: "",
  TP_PURCHASE_ORDER: "",
  CHARACTERISTICS: "",
  TP_CARRY_OVER: "",
  TP_AVAILABLE_FEW_DAYS: "",
  TP_NEW_MODELS: "",
  TP_TOTAL_MODELS: "",
  TP_SHIPPED_ON: "",
  LAST_WEEK: "",
  LAST_4_WEEKS: "",
  SEE_MORE_ON_LUXOTTICA: "",
  TP_TECH_DISCLAIMER: "",
  TP_SHAPE: "",
  EXC: "",
  GOLD: "",
  NYLON: "",
  WOOD: "",
  PROPIONATE: "",
  ALUMINIUM: "",
  "HORN/IVORY": "",
  ACETATE: "",
  METAL: "",
  STEEL: "",
  OTHERMATERIAL: "",
  INJECTED: "",
  MEMORYMETAL: "",
  TITANIUM: "",
  CARBONFIBER: "",
  PEEK: "",
  PILOT: "",
  NOTDEFINED: "",
  BUTTERFLY: "",
  PHANTOS: "",
  OVAL: "",
  RECTANGLE: "",
  IRREGULAR: "",
  ROUND: "",
  SQUARE: "",
  CATEYE: "",
  PILLOW: "",
  FEMALE: "",
  MALE: "",
  UNISEX: "",
  SHIPMENT: '',
  MY_FAVORITES_FRAMES_TITLE: '',
  TP_COLORS_MATERIALS: "",
  FRAME_COLOR: "",
  LENS_COLOR: "",
  LATEST_COLLECTIONS_MENU_TITLE: "",
  LATEST_COLLECTIONS_TITLE: "",
  LATEST_COLLECTIONS_DESCR: "",
  LATEST_COLLECTIONS_SELECT_BRAND: "",
  LATEST_COLLECTIONS_EMPTY: "",
  COLLECTION_BRAND_MODAL_DESCR: "",
  COLLECTION_BANNER_LATEST_COLLECTION: "",
  COLLECTION_BANNER_CTA: "",
  COLLECTION_PAGE_TITLE: "",
  COLLECTION_PAGE_STORIES_OVERVIEW: "",
  COLLECTION_PAGE_NOT_FOUND: "",
  LATEST_COLLECTIONS_CAROUSEL_TITLE: "",
  LATEST_COLLECTIONS_CAROUSEL_DISCOVER_MORE: "",
  LATEST_COLLECTIONS_CAROUSEL_SEE_COLLECTION: "",
  MOCO_LAUNCH_DATE_CODE: "",
  MOCO_SPECIAL_PROJECT_COLLECTION: "",
  MOCO_SPECIAL_PROJECT_SPONSOR: "",
  MOCO_SPECIAL_LENS_BASE: "",
  MOCO_LOAD_MORE: "",
  MOCO_LOAD_LESS: "",
  CAROUSEL_MODELS_SAME_COLLECTION_TITLE: "",
  CAROUSEL_MODELS_SAME_COLLECTION_DESCR: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  CAROUSEL_MODELS_SAME_COLLECTION_CTA: "",
  TP_DETAIL_BRAND_BANNER_TYPE: "",
  TP_DETAIL_BRAND_BANNER_CTA: "",
  SEARCH_TAB_LATEST_COLLECTIONS: "",
  SEASON_SS: "",
  SEASON_AW: "",
  LATEST_COLLECTIONS_MENU_CTA: "View all collections",
  HIGHLIGHTS_MENU_CTA: "View all highlights",
  COLLECTION_PAGE_DESCR: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent.",
  HIGHLIGHTS_LABEL: "",
  HIGHLIGHTS_LABEL_DESCR: "",
  COLLECTIONS_LABEL: "Brands",
  TP_FRONT_COLOR: "Front color",
  TP_TREATMENT: "Treatment",
  //COLLECTIONS WAVE 2
  CAROUSEL_LEARN_MORE_ABOUT_BRAND_TITLE: "Learn more about {brand}",
  CAROUSEL_LEARN_MORE_ABOUT_BRAND_DESCR: "",
  CAROUSEL_BROWSE_BY_STORIES_TITLE: "Browse by stories",
  CAROUSEL_BROWSE_BY_STORIES_DESCR: "",
  CAROUSEL_MODELS_NOT_AVAILABLE: "Some models may not be available in your country.If you have any doubts please refer to our selling platform.",
  TP_KEY_SELLING_POINTS: 'Key selling points',
  TP_SUSTAINABILITY_DESCR: 'Some variants of this glasses are sustainable, discover more about it in the "Colors and materials" sections.',
  SUSTAINABILITY: 'Sustainability',
  TP_AGE_RANGE: 'Age range',
  TP_MARKETING_THEME: 'Marketing theme',
  TP_MARKETING_STORY: 'Marketing story',
  TP_EXCLUSIVE_COLLECTION: 'Exclusive Collection',
  TP_FRAME_MATERIAL: 'Frame material',
  TP_TEMPLE_MATERIAL: 'Temple material',
  TP_LENS_MATERIAL: 'Lens material',
  TP_LENS_WIDTH: 'Lens width',
  TP_LENS_BASE: 'Lens base',
  TP_EXACT_SIZE: 'Exact size',
  TP_BRIDGE_SIZE: 'Bridge size',
  TP_TEMPLE_LENGHT: 'Temple lenght',
  TP_FACE_SHAPE: 'Face shape',
  TP_PROGRESSIVE_FRIENDLY: 'Progressive friendly',
  TP_GEO_FIT: 'Geo Fit',
  TP_COLOR_ADD: 'color add',
  TP_CHANNEL_SPECIFIC: 'Channel specific',
  TP_BRAND_CAMPAIGN: 'Brand campaign',
  TP_BRAND_CAMPAIGN_CTA: 'View details',
  ADV: 'Adv',
  LATEST_COLLECTIONS_FILTER_BRANDS: "Brands",
  LATEST_COLLECTIONS_FILTER_ALL_BRANDS: "All brands",
  LATEST_COLLECTIONS_FILTER_COLLECTION: "Collection",
  LATEST_COLLECTIONS_FILTER_COLLECTION_LATEST_COLLECTION: "Latest collection ({NAME})",
  BRAND_COLLECTION_FILTER_TYPE: "Type",
  BRAND_COLLECTION_FILTER_AGE_RANGE: "Age range",
  BRAND_COLLECTION_FILTER_FRAME_MATERIAL: "Frame material",
  BRAND_COLLECTION_FILTER_CHANNEL_SPECIFIC: "Channel specific",
  BRAND_COLLECTION_FILTER_COLOR_ADD: "New model",
  BRAND_COLLECTION_FILTER_LOW_BRIDGE_FIT: "Low bridge fit",
  BRAND_COLLECTION_FILTER_ADV_STYLES: "Advertising styles",
  BRAND_COLLECTION_FILTER_BRAND_CAMPAIGN: "Brand Campaign",
  LATEST_COLLECTION_ADDITION: "Addition",
  POLARIZED_ABBREVIATION: "Pol.",
  BLUE_LIGHT_PROTECTION_ABBREVIATION: "Blue l.",
  PHOTOCROMIC_ABBREVIATION: "Photocr.",
  COLOR_CODE: "Color code",
  COLLECTION_BRAND_CAMPAIGN:"Brand Campaign for this collection",
  TP_BRAND_CAMPAIGN: "Brand Campaign",
  TP_BRAND_CAMPAIGN_CTA:"View Details",
  MODAL_BRAND_CAMPAIGN_KEY_SELLING_POINTS: "Key Selling Points",
  TP_BRAND_CAMPAIGN_FILTER: "Brand Campaign",
  TP_OTHER_BRAND_CAMPAIGNS: "Other Campaigns"
} as const
