import React from 'react';

type HorizontalShadowScrollbar = {
  children: React.ReactNode;
  useSpaceIphone?: boolean;
}

const HorizontalShadowScrollbar = ({ children, useSpaceIphone = false }: HorizontalShadowScrollbar) => {
  const [showStart, setShowStart] = React.useState(false);
  const [showEnd, setShowEnd] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const onScroll = () => {
      const { scrollWidth = 0, scrollLeft = 0, offsetWidth = 0 } =
        ref.current || {};
      setShowStart(scrollLeft > 0);
      //the +7 is caused by .spaceIphone
      setShowEnd(scrollLeft + offsetWidth + 7 < scrollWidth);
    };
    onScroll();
    const node = ref.current;
    node.addEventListener('scroll', onScroll);
    return () => {
      node.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={`horizontal-shadow-scrollbar ${showEnd ? 'showEnd' : ''} ${showStart ? 'showStart' : ''}`}>
      <div className="shadow-left" />
      <div className="container" ref={ref}>
        {children}
        {useSpaceIphone &&
          <p className="spaceIphone">a</p>
        }
      </div>
      <div className="shadow-right" />
    </div>
  );
};

export default HorizontalShadowScrollbar;
