import { SKELETON_COLORS } from "@utility/const";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";

const widthDesktop = 432;
const widthPhone = 344;
const heightDesktop = 521;
const heightPhone = 472;

const heightText = 20;
const borderRadiusText = 10;

const CardEducationalPathsProgramSkeleton = (props) => {
  const breakpoint = useBreakpointBoolean();
  const [height, setHeight] = useState(heightDesktop);
  const [width, setWidth] = useState(widthDesktop);

  useEffect(() => {
    if (breakpoint.isDesktop) {
      setHeight(heightDesktop);
      setWidth(widthDesktop);
    } else {
      setHeight(heightPhone);
      setWidth(widthPhone);
    }
  }, [breakpoint]);

  return (
    <div
      className=""
      style={{
        width: `${width}px`,
        height: `${height - 123}px`,
        overflow: "hidden",
        paddingRight: props?.isInCarousel ? "16px": "0px"
      }}
    >
      <ContentLoader
        speed={2}
        width={width}
        height={height - 123}
        viewBox={`0 0 ${width} ${height - 123}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        //{...props}
      >
        <rect x="0" y="0" rx="5" ry="5" width={width} height={width / 1.75} />
        <rect
          x="10"
          y={width / 1.75 + (143 - 109 - 10)}
          rx={borderRadiusText}
          ry={borderRadiusText}
          width={(width - 10) / 3}
          height={heightText}
        />
        <rect
          x="10"
          y={width / 1.75 + (173 - 109)}
          rx={borderRadiusText}
          ry={borderRadiusText}
          width={width - 100}
          height={heightText}
        />
        <rect
          x="10"
          y={width / 1.75 + (187 - 109 + 20)}
          rx={borderRadiusText}
          ry={borderRadiusText}
          width={width - 10}
          height={heightText}
        />
        <rect
          x="10"
          y={width / 1.75 + (209 - 109 + 30)}
          rx={borderRadiusText}
          ry={borderRadiusText}
          width={(width - 10) / 3}
          height={heightText}
        />
      </ContentLoader>
    </div>
  );
};

export default CardEducationalPathsProgramSkeleton;
