import React, { useEffect, useState } from 'react';
import { LangMap } from '@model/CoursesClass';
import {
  ResponseGetProducts,
  ResponseGetProductsFilter,
  ResponseGetProductsItems,
  SingleWeekSummary,
  TrainingPill,
} from '@model/ProductClass';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import queryString from 'query-string';
import SettingsIcon from '@svg-icons/settings.svg';
import CoursesResults from '@components/CoursesResults';
import FilterModalProduct from './FilterModalProduct';
import Select from '@components/UI/Select';
import {
  firstLetterToUpperCase,
  fromCamelToCapitalize,
  getExtendedMonthToPrint,
  printDate,
  printDateLong,
} from '@utility/Api';
import * as actions from '@redux-actions/';
import Chip from '@components/UI/Chip';
import useToggle from '@hooks/useToggle';
import { TP_CATALOGUE_PAGINATION } from '@utility/const';
import {
  filtersLabel,
  isFiltersItems,
  selectTPWeeklySummaryData,
} from '@utility/TrainingPillsUtility';
import {
  isPlpTpPage,
  isWishlistPage,
  tpPillsParamKeysMapping,
  tpPillsParamValuesMapping,
  UtagViewPages,
  isSearchPage,
  styledLogUtagView,
} from './link-utils';
import { UserProfile } from '@model/User';
import TPShipmentStats from './TPShipmentStats';
import { useHistory, useLocation } from 'react-router-dom';
import useHandleBodyScrollAfterModalOpen from '@hooks/useHandleBodyScrollAfterModalOpen';
import ButtonV2 from '@components/UI/ButtonV2';
import AnimationSlide from './animations/AnimationSlide';
import { isPersonificationOn } from '../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { buildClickDataTrackingObject, UserTypes } from '@model/TrackingClass';
import { selectTrackingDataLayerBase } from '@model/TrackingClass';
import { triggerPageView } from '@utility/analytics-utils';
import { camelCase } from 'lodash';

type FiltersProductProps = {
  initialFilters: ResponseGetProducts;
  categoryName?: string;
  subCategoryName?: string;
  category?: string;
  isSearch?: boolean;
  searchQuery?: string;
  searchForceNoCourseSuggestion?: boolean;
  urlParamsSearch?: string;
  showTPAsMobile?: boolean;
  isNovelties?: boolean;
  rightAnimation?: boolean;
  hideCollectionFilter?: boolean;
  productId?: string;
  tab?: string;
};

export type ApplyFiltersArgs = {
  filters: ResponseGetProducts;
  refresh?: boolean;
  mock?: boolean;
};

const mockApplyFilters = false;

const animationSlideToLeftDefault = {
  initialPosition: 'left',
  initialX: '0',
};

const animationSlideToRightDefault = {
  initialPosition: 'right',
  initialX: '0',
};

const animationXSection = '40px';
const animationDurationSection = 0.1;

const FiltersProduct = (props: FiltersProductProps) => {
  const dispatch = useDispatch();
  const lang: LangMap = useSelector(state => state.utils.lang);
  const brandsList = useSelector(state => state.utils.brandsList);
  const pagination: number = TP_CATALOGUE_PAGINATION;
  const labelL1Map = useSelector(state => state.utils.labelL1Map);

  const trainingPillsCatalogue: TrainingPill[] = useSelector(state =>
    props.isNovelties
      ? state.trainingPills.noveltiesCatalogue
      : state.trainingPills.trainingPillsCatalogue
  );
  const isTrainingPillsCatalogueCompleted: boolean = useSelector(state =>
    props.isNovelties
      ? state.trainingPills.isNoveltiesCatalogueCompleted
      : state.trainingPills.isTrainingPillsCatalogueCompleted
  );
  const trainingPillsCatalogueToNumber: number = useSelector(state =>
    props.isNovelties
      ? state.trainingPills.noveltiesCatalogueToNumber
      : state.trainingPills.trainingPillsCatalogueToNumber
  );
  const trainingPillsCatalogueTotalNumber: number = useSelector(state =>
    props.isNovelties
      ? state.trainingPills.noveltiesCatalogueTotalNumber
      : state.trainingPills.trainingPillsCatalogueTotalNumber
  );

  const [limit, setLimit] = useState(0);
  const [showModal, setShowModal] = useToggle(false);
  const [appliedFiltersNum, setAppliedFiltersNum] = useState(0);
  const [sortings, setSortings] = useState({});
  const [filters, setFilters] = useState<ResponseGetProducts>();
  const [smartFilters, setSmartFilters] = useState<ResponseGetProductsFilter[]>([]);
  const [lastTypeFilter, setLastTypeFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState<Pick<ResponseGetProducts, 'items'> | {}>({});
  const [arentOtherFilters, setArentOtherFilters] = useState(false);
  const [showBrandsAccordion, setBrandsAccordion] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [filtersAreApplied, setFiltersAreApplied] = useState(false);
  const [refetchFilters, setRefetchFilters] = useState<ResponseGetProducts>(null);
  const [isInPlpTpPage, setIsInPlpTpPage] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const [analyticsAlreadySent, setAnalyticsAlreadySent] = useState<boolean>(false);

  const urlParams = props?.urlParamsSearch || queryString.parse(location.search);
  // props
  const { initialFilters, categoryName } = props;

  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  // const isTimeOnTrainingLoaded: boolean = useSelector(state => state.course.isTimeOnTrainingLoaded);
  // const timeOnTraining: string = useSelector(state => state.course.timeOnTraining);
  const baseTealiumTracking = useSelector(selectTrackingDataLayerBase)

  const trainingPillsWeeklySummary: Array<SingleWeekSummary> =
    useSelector(selectTPWeeklySummaryData);
  const isLoadingTrainingPillsWeeklySummary: boolean = useSelector(
    state => state.trainingPills.isLoadingTrainingPillsWeeklySummary
  );
  const showCourseModal = useSelector(state => state.course.showCourseModal);

  const forceFilterUpdate = useSelector(state => state.trainingPills.forceFilterUpdate);

  // console.log(`isLoadingTrainingPillsWeeklySummary`, isLoadingTrainingPillsWeeklySummary)
  const [jumpFirstFilterSelection, setJumpFirstFilterSelection] = useState(true);

  const [showShipmentStats, setShowShipmentStats] = useState<boolean>(false);
  const bSearchPage = isSearchPage(location.pathname);

  useHandleBodyScrollAfterModalOpen(showModal);

  useEffect(() => {
    return () => {
      //clean redux objects
      if (props.isSearch) {
        dispatch(actions.resetSearchTrainingPillsCatalogue(props.isNovelties));
      } else {
        dispatch(
          props.isNovelties
            ? actions.resetNoveltiesCatalogue()
            : actions.resetTrainingPillsCatalogue()
        );
      }
    };
  }, []);

  useEffect(() => {
    if (forceFilterUpdate) {
      console.log('forceFilterUpdate', forceFilterUpdate);
      const filtersTemp = cloneDeep(filters);
      // handleApplyFilters({ filters: filtersTemp, refresh: true });
      setRefetchFilters(filtersTemp);
      dispatch(actions.setForceFilterUpdate(false));
    }
  }, [forceFilterUpdate]);

  useEffect(() => {
    // console.log(`initialFilters`, initialFilters)

    const showBrands =
      !!initialFilters.items?.brands &&
      !initialFilters.items.brands?.some(filterItem => filterItem.checked);
    const showStatusTemp =
      !!initialFilters.items?.status &&
      !initialFilters.items.status?.some(filterItem => filterItem.checked);
    let initialFiltersTemp = cloneDeep(initialFilters);
    let tempSortById = null;
    Object.keys(initialFiltersTemp?.items)?.forEach(filterKey => {
      const paramKey = tpPillsParamKeysMapping[filterKey] || filterKey;

      const urlParamValues = Array.isArray(urlParams?.[paramKey])
        ? urlParams?.[paramKey]
        : [urlParams?.[paramKey] || ''];
      urlParamValues.forEach(urlParamValue => {
        const decodedValue = decodeURI(urlParamValue || '');
        const filtersParamQuery = tpPillsParamValuesMapping[decodedValue] || decodedValue;
        const foundChar: ResponseGetProductsFilter = initialFiltersTemp?.items?.[filterKey]?.find(
          char => char?.id === filtersParamQuery
        );
        if (foundChar?.id) {
          if (filterKey !== 'sortings') {
            foundChar.checked = true;
          } else {
            tempSortById = foundChar.id;
          }
        }
      });
    });

    if (tempSortById) {
      const sortings = initialFiltersTemp?.items?.sortings.map(char => ({
        ...char,
        checked: char?.id === tempSortById,
      }));
      initialFiltersTemp.items.sortings = sortings;
      setSortBy(tempSortById);
    }

    // console.log(`initialFiltersTemp`, initialFiltersTemp)

    setBrandsAccordion(showBrands);
    setShowStatus(showStatusTemp);
    handleApplyFilters({ filters: initialFiltersTemp, mock: mockApplyFilters });
    if (showShipmentStats) {
      dispatch(actions.getTrainigPillsWeeklySummary());
    }
    setFilters(initialFiltersTemp);
  }, [initialFilters, showShipmentStats]);

  useEffect(() => {
    const filtersItems = filters?.items;

    if (filtersItems && lang) {
      let appliedFiltersCount = 0;
      let numTotalFilters = 0;

      const appliedFilterTemp: Pick<ResponseGetProducts, 'items'> | {} = Object.fromEntries(
        Object.entries<ResponseGetProductsItems[keyof ResponseGetProductsItems]>(filtersItems).map(
          ([key, value]) => {
            if (key === 'orderDateType') {
              return [key, value];
            }

            // console.log(`value`, key, value);
            let checkedFilters = [];
            if (
              key != 'sortings' &&
              key != 'brands' &&
              key !== 'marketingTheme2' &&
              (showStatus || key !== 'status') &&
              (!props.hideCollectionFilter || key !== 'collections')
            ) {
              checkedFilters = value.filter(entry => entry.checked);
              numTotalFilters += value?.length || 0;
              const isOrderDateChecked = key === 'orderDate' && checkedFilters.length > 0;
              appliedFiltersCount += !isOrderDateChecked ? checkedFilters.length : 1;
            } else if (showBrandsAccordion && key === 'brands') {
              checkedFilters = value.filter(entry => {
                if (entry.checked && brandsList && brandsList.map) {
                  const brandName = brandsList.map[entry.id]?.brand;
                  return entry.id === brandName;
                }
                return !(brandsList && brandsList.map);
              });
              numTotalFilters += value?.length || 0;
              appliedFiltersCount += checkedFilters.length;
            }

            return [key, checkedFilters];
          }
        )
      );

      const sortingsSortBy = {};

      filtersItems.sortings?.forEach(sortItem => {
        sortingsSortBy[sortItem.id] = {
          value: sortItem.id,
          label: lang?.SORT_BY_LABEL.replace(
            '{sorting}',
            lang?.[filtersLabel?.[sortItem.id]] ||
            lang?.[filtersLabel?.[sortItem.id]] ||
            filtersLabel?.[sortItem.id] ||
            sortItem.id
          ),
        };
      });

      const currentSortBy = filtersItems.sortings?.find(sortItem => sortItem.checked);

      console.log(`currentSortBy`, currentSortBy);
      currentSortBy?.id && setSortBy(currentSortBy.id);
      setSortings(sortingsSortBy);
      setAppliedFiltersNum(appliedFiltersCount);
      setAppliedFilters(appliedFilterTemp);
      setArentOtherFilters(!numTotalFilters);
      // getTrainingPillsByFilters(filters);
    }
  }, [lang, filters, brandsList]);

  useEffect(() => {
    //show shipment stats just in PLP
    const inPlpTpPage: boolean = isPlpTpPage(location.pathname);
    setIsInPlpTpPage(inPlpTpPage);
    setShowShipmentStats(inPlpTpPage);
  }, [location?.pathname]);

  const openModalFilter = () => {
    setShowModal();
  };

  const printChipLabelOrderDate = (
    orderDateFilter: ResponseGetProductsItems['orderDate'],
    orderDateType: ResponseGetProductsItems['orderDateType'],
    lang: LangMap
  ): string => {
    //const startDate = orderDateFilter?.[0]?.id;
    const startDateCET = orderDateFilter?.[0]?.id;
    const startDateUTC = new Date(startDateCET);
    const startDate = startDateUTC;
    const endDate = orderDateFilter?.[1]?.id;
    const startDateLabel = printDate(startDate, lang);
    const endDateLabel = printDate(endDate, lang);
    if ([7, 14, 30].includes(orderDateType)) {
      return lang?.LAST_X_DAYS?.replace('{NUM}', orderDateType.toString());
    }

    return lang.FROM_TO_ORDER_DATE.replace('{0}', startDateLabel).replace('{1}', endDateLabel);
  };

  const printChipLabel = (
    key: keyof ResponseGetProductsItems,
    filter: ResponseGetProductsFilter,
    lang: LangMap
  ) => {
    if (!key || !filter) {
      return '';
    }
    switch (key) {
      case 'brands':
        return filter.id === 'ALL'
          ? lang.BRAND_FILTER_ALL
          : fromCamelToCapitalize(actions.getBrandNameById(brandsList, filter.id) || filter.id);
      case 'months':
        const [year, month] = filter.id?.split('-');
        return getExtendedMonthToPrint(month?.toString(), lang) + ' ' + year;
      case 'launchDate':
        return printDateLong(+filter.id, lang, false);
      default:
        return lang?.[filtersLabel?.[filter.id]] || filtersLabel?.[filter.id] || filter.id;
    }
  };

  useEffect(() => {
    if (
      (trainingPillsCatalogueToNumber > pagination || filtersAreApplied) &&
      isTrainingPillsCatalogueCompleted &&
      filters?.items
    ) {
      applyFilterAnalytics(filters.items);
    }
  }, [isTrainingPillsCatalogueCompleted, trainingPillsCatalogueToNumber, filtersAreApplied]);

  const setLimitWithPagination = () => {
    if (trainingPillsCatalogueToNumber < trainingPillsCatalogueTotalNumber) {
      if (props.isSearch) {
        dispatch(
          actions.getTrainingPillsCatalogue(
            filters,
            trainingPillsCatalogueToNumber,
            trainingPillsCatalogueTotalNumber,
            props.searchQuery,
            props.isNovelties
          )
        );
      } else {
        dispatch(
          actions.getTrainingPillsCatalogue(
            filters,
            trainingPillsCatalogueToNumber,
            trainingPillsCatalogueTotalNumber,
            null,
            props.isNovelties
          )
        );
      }

      setLimit(limit + pagination);
    }
  };

  const applyFilterAnalytics = (filtersAnalytics: ResponseGetProductsItems) => {
    const products = trainingPillsCatalogue || [];

    // console.log(`categoryName`, categoryName);
    // console.log(
    //   `isLoading, sortBy, userProfile, isTimeOnTrainingLoaded, selectedChannel, selectedFilters, subCategoryName, categoryName, searchQuery, searchType, numCourses`,
    //   {
    //     sortBy,
    //     userProfile,
    //     isTimeOnTrainingLoaded,
    //     showModal,
    //     showCourseModal,
    //     appliedFilters,
    //     subCategoryName: props.subCategoryName,
    //     categoryName,
    //     trainingPillsCatalogueTotalNumber,
    //     isTrainingPillsCatalogueCompleted,
    //     trainingPillsCatalogue,
    //   }
    // );
    // analytics
    if (!!userProfile && categoryName !== 'WISHLIST_PAGE_NAME_LABEL' && categoryName !== 'search' && categoryName && baseTealiumTracking && !analyticsAlreadySent) {
      const appliedFilterTemp: Pick<ResponseGetProducts, 'items'> | {} = Object.fromEntries(
        Object.entries(filtersAnalytics)
          .filter(
            ([_, value]) =>
              Array.isArray(value) && value?.filter(entry => entry?.checked).length > 0
          )
          .map(([key, value]) => {
            const checkedFilters =
              Array.isArray(value) &&
              value
                ?.filter(entry => entry?.checked)
                .map(entry => entry.id)
                .join(',');
            return [key, checkedFilters];
          })
      );

      const selectedFiltersTypes: string[] = Object.keys(appliedFilterTemp);
      const selectedFiltersValues: string[] = Object.values(appliedFilterTemp);

      const page_subcategory = props.subCategoryName
        ? { page_subcategory: props.subCategoryName }
        : {};

      const isInPlpTPBrandPage = location.pathname.includes('/subcatalogue/tp/');
      const brandId = props?.productId?.replace("brand_", '');
      const UtagViewPageFilter = isInPlpTpPage || isInPlpTPBrandPage ? UtagViewPages.SUBCATALOGUE_TP : UtagViewPages.CATALOGUE_TP;
      const UtagViewData = {
        ...baseTealiumTracking,
        ...UtagViewPageFilter,
        Page_Section3: isInPlpTpPage ? 'All' : firstLetterToUpperCase(labelL1Map?.[`brand_${brandId}`]?.label),
        //Page_Language: userProfile?.preferredLang,
        //Page_Country: userProfile?.country,
        //User_LoginStatus: userProfile?.senderSystem,
        //User_Id: userProfile?.username,
        //user_timeontraining: timeOnTraining,
        //browse_refine_type: selectedFiltersTypes,
        //browse_refine_value: selectedFiltersValues,
        //browse_refine_sorting: sortBy,
        //Products_Id_Array: products.map(product => product.model),
        //Products_ModelName_Array: products.map(product => product.modelName),
        // product_format: [],
        // Products_Category_Array: [],
        // Products_Mandatory_Array: [],
        // Page_Section3: categoryName,
        //...page_subcategory,
        // Order_Currency: userProfile?.ecommerceInfo?.currency,
        //User_MoodleID: userProfile?.moodleId,
        // user_timeontraining: timeOnTraining,
        // browse_refine_type: selectedFiltersTypes,
        // browse_refine_value: selectedFiltersValues,
        // browse_refine_sorting: sortBy,
        // product_format: [],
        //product_category: products.map(product => 'brand' + '_' + product.brandString.toLowerCase()),
        // product_mandatory: [],
        // ...page_subcategory,
        // User_MoodleID: userProfile?.moodleId,
      }

      styledLogUtagView(`${UtagViewData?.Page_Section2} - ${UtagViewData?.Page_Section3}`, UtagViewData)

      triggerPageView(UtagViewData);
      setAnalyticsAlreadySent(true)

      if (
        !props?.isSearch &&
        Object.keys(initialFilters?.items)?.some(
          key => urlParams?.[tpPillsParamKeysMapping[key] || key]
        )
      ) {
        history.replace({
          search: '',
        });
      }
    }
    setFiltersAreApplied(false);
  };

  const removeAppliedFilter = (filterName: string, filterGroup: keyof ResponseGetProductsItems) => {
    const filtersTemp = cloneDeep(filters);

    if (filterGroup !== 'brands' && filterGroup !== 'orderDate') {
      const foundFilterIndex = filtersTemp.items[filterGroup].findIndex(
        filterItem => filterItem.id === filterName
      );
      const foundFilterChecked = filtersTemp?.items?.[filterGroup]?.[foundFilterIndex]?.checked;
      if (typeof foundFilterChecked === 'boolean') {
        filtersTemp.items[filterGroup][foundFilterIndex].checked = false;
      }
    }
    if (filterGroup === 'brands') {
      const foundFilters = filtersTemp.items[filterGroup].filter(filterItem => {
        if (brandsList && brandsList.map && brandsList.map[filterItem.id]) {
          const brandName = brandsList.map[filterItem.id]?.brand;
          return brandName === filterName;
        }
      });

      if (foundFilters.length > 0) {
        foundFilters.forEach(f => {
          const foundFilterIndex = filtersTemp.items[filterGroup].findIndex(
            filterItem => filterItem.id === f.id
          );
          const foundFilterChecked = filtersTemp?.items?.[filterGroup]?.[foundFilterIndex]?.checked;
          if (typeof foundFilterChecked === 'boolean') {
            filtersTemp.items[filterGroup][foundFilterIndex].checked = false;
          }
        });
      }
    }

    if (filterGroup === 'orderDate') {
      filtersTemp.items[filterGroup] = [];
    }

    setLastTypeFilter(filterGroup);
    handleApplyFilters({ filters: filtersTemp, mock: mockApplyFilters, refresh: true });
  };

  const handleSortBy = option => {
    const filtersTemp = cloneDeep(filters);
    const selectedSortBy = option.value;
    filtersTemp.items.sortings = filtersTemp?.items?.sortings?.map(filterItem => ({
      ...filterItem,
      checked: filterItem?.id === selectedSortBy,
    }));
    handleApplyFilters({ filters: filtersTemp, mock: mockApplyFilters });
    setSortBy(option.value);
  };

  const handleFilterByCollection = filterId => {
    const filtersTemp = cloneDeep(filters);

    for (let collection of filtersTemp.items.collections) {
      if (collection?.id === filterId) {
        collection.checked = true;
      }
    }

    console.log('filtersTemp', filtersTemp);
    handleApplyFilters({ filters: filtersTemp, mock: mockApplyFilters, refresh: true });
  };

  const handleApplyFilters = ({
    filters: filtersObject,
    refresh = false,
    mock = false,
  }: ApplyFiltersArgs) => {
    setFiltersAreApplied(true);
    setFilters(filtersObject);
    if (refresh) {
      setRefetchFilters(filtersObject);
    }
    //get new training pills according to new filters
    if (props.isSearch) {
      if (!jumpFirstFilterSelection) {
        dispatch(
          actions.getTrainingPillsCatalogue(
            filtersObject,
            0,
            null,
            props.searchQuery,
            props.isNovelties
          )
        );
      } else {
        setJumpFirstFilterSelection(false);
      }
    } else {
      dispatch(actions.getTrainingPillsCatalogue(filtersObject, 0, null, null, props.isNovelties));
    }
  };

  const getTPNumber = (trainingPillsNum: number = 0) => {
    if (isWishlistPage(location.pathname)) {
      let pillsNoWish: number = 0;
      trainingPillsCatalogue.forEach(pill => {
        if (!pill.wishlist) {
          pillsNoWish++;
        }
      });

      return (trainingPillsNum - pillsNoWish).toString();
    } else {
      return trainingPillsNum.toString();
    }
  };

  const getTrainingPillsCatalogueToDisplay = (pills: TrainingPill[] = []) => {
    if (isWishlistPage(location.pathname)) {
      return pills.filter(pill => pill.wishlist);
    } else {
      return pills;
    }
  };

  const isNoveltiesWithCollections = props.isNovelties && smartFilters?.length > 0;

  const CoursesResultsWrapper =
    typeof props.rightAnimation === 'boolean' ? AnimationSlide : React.Fragment;
    
  // LEON-6129
  const trainingPillsCatalogueToDisplay = getTrainingPillsCatalogueToDisplay(trainingPillsCatalogue)

  return (
    <div className="filters-product">
      {showShipmentStats && (
        <TPShipmentStats
          lang={lang}
          isLoading={isLoadingTrainingPillsWeeklySummary}
          shipmentModels={trainingPillsWeeklySummary}
        />
      )}
      {trainingPillsCatalogueToDisplay && (
        <div className={'filtersV3__section ' + (showModal ? 'filtersV3__section--open' : '')}>
          <div className="wrapper">
            <div className="filter-overlay flex">
              <section
                className="filter-overlay-element results"
                aria-label={
                  !isTrainingPillsCatalogueCompleted
                    ? lang.LOADING
                    : lang.RESULTS_LABEL?.replace(
                      '{0}',
                      (trainingPillsCatalogueToNumber || 0).toString()
                    )?.replace('{1}', (trainingPillsCatalogueTotalNumber || 0).toString())
                }
              >
                {isTrainingPillsCatalogueCompleted && (
                  <span className="results__number-courses">
                    {lang.RESULTS_LABEL.replace(
                      '{0}',
                      getTPNumber(trainingPillsCatalogueToNumber)
                    ).replace('{1}', getTPNumber(trainingPillsCatalogueTotalNumber))}
                  </span>
                )}
              </section>
              <div className="results__sort-wrapper filter-overlay-element">
                {sortBy && (
                  <section aria-label={lang?.TITLE_SORT_BY_LABEL}>
                    <Select
                      className="custom-select-sortings"
                      options={Object.values(sortings)}
                      noMaxHeightList
                      onChange={handleSortBy}
                      value={sortings[sortBy]}
                      aria-label={sortings[sortBy]?.label}
                      keyboardActive
                      noFillMobile
                    />
                  </section>
                )}
              </div>

              <div
                className={`filtersV3__box${appliedFiltersNum > 0 ? ' filtersV3__box--active' : ''
                  } filter-others filter-overlay-element right`}
              >
                {!arentOtherFilters && (
                  <>
                    {appliedFiltersNum > 0 && (
                      <span className="text-14-medium filtersV3__filters-num">
                        {appliedFiltersNum}
                      </span>
                    )}
                    <ButtonV2
                      variant="icon-btn"
                      className="icon-button button-filter button-filter-toggle"
                      handleClick={openModalFilter}
                      ariaLabel={lang?.OPEN_FILTERS_MODAL}
                    >
                      {/* { bSearchPage?
                        ( */}
                      <SettingsIcon
                        aria-hidden="true"
                        className={
                          'filters-icon' + (appliedFiltersNum > 0 ? ' theme-evolution' : '')
                        }
                      />
                      {/* {)
                        :(<FiltersIcon className={'filters-icon' + (appliedFiltersNum > 0 ? ' theme-evolution' : '')} aria-hidden="true" />)}
                      } */}
                    </ButtonV2>
                    <FilterModalProduct
                      // elementsNum={getTPNumber(trainingPillsCatalogueTotalNumber)}
                      close={() => setShowModal(false)}
                      show={showModal}
                      initialFilters={initialFilters?.items}
                      filters={filters?.items}
                      setSmartFilters={setSmartFilters}
                      applyFilters={handleApplyFilters}
                      // analytics
                      isLoading={!isTrainingPillsCatalogueCompleted}
                      category={props.category}
                      brandsList={brandsList}
                      currentNumProducts={trainingPillsCatalogueTotalNumber}
                      lang={lang}
                      sortByApplied={sortBy}
                      showBrandsAccordion={showBrandsAccordion}
                      searchQuery={props.searchQuery}
                      setRefetchFilters={setRefetchFilters}
                      refetchFilters={refetchFilters}
                      isNovelties={props.isNovelties}
                      lastTypeFilter={lastTypeFilter}
                      setLastTypeFilter={setLastTypeFilter}
                      hideCollectionFilter={props.hideCollectionFilter}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(!!appliedFiltersNum || isNoveltiesWithCollections) && isTrainingPillsCatalogueCompleted && (
        <section className="filtersV3__section chips" aria-label={lang.SEARCH_IN}>
          {isNoveltiesWithCollections && <span className="titleSearch">{lang.SEARCH_IN}</span>}
          <div className="wrapper">
            <div className="results">
              <div className="results__header flex">
                <div className="results__filters">
                  {isNoveltiesWithCollections &&
                    smartFilters
                      ?.sort((a, b) => a.id?.toLowerCase()?.localeCompare(b.id?.toLowerCase()))
                      .map((filter: ResponseGetProductsFilter) => (
                        <Chip
                          key={filter.id}
                          checked={filter.checked}
                          showClose={filter.checked}
                          className="button-filter results__filter"
                          toggle={
                            filter.checked
                              ? () => {removeAppliedFilter(filter.id, 'collections');buildClickDataTrackingObject("chips",camelCase(printChipLabel('collections', filter, lang)));}
                              : () => {
                                setLastTypeFilter('collections');
                                handleFilterByCollection(filter.id);
                                buildClickDataTrackingObject("chips",camelCase(printChipLabel('collections', filter, lang)))
                              }
                          }
                          label={printChipLabel('collections', filter, lang)}
                          disabled={filter.disabled}
                        />
                      ))}
                  {isFiltersItems(appliedFilters) &&
                    Object.keys(appliedFilters).map((key: keyof ResponseGetProductsItems) => {
                      if (key === 'brands' && !showBrandsAccordion) {
                        return null;
                      }

                      if (key === 'status' && !showStatus) {
                        return null;
                      }

                      if (
                        key === 'orderDateType' ||
                        (isNoveltiesWithCollections && key === 'collections')
                      ) {
                        return null;
                      }

                      if (key === 'orderDate' && appliedFilters[key]?.length > 0) {
                        return (
                          <Chip
                            key={key}
                            checked={true}
                            showClose={true}
                            className="button-filter results__filter"
                            toggle={() => removeAppliedFilter('', key)}
                            label={printChipLabelOrderDate(
                              appliedFilters[key],
                              appliedFilters.orderDateType,
                              lang
                            )}
                          />
                        );
                      }

                      return appliedFilters[key].map((filter: ResponseGetProductsFilter) => (
                        <Chip
                          key={filter.id}
                          checked={true}
                          showClose={true}
                          className="button-filter results__filter"
                          toggle={() => removeAppliedFilter(filter.id, key)}
                          label={printChipLabel(key, filter, lang)}
                        />
                      ));
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section
        className="filter-overlay-element results-mobile"
        aria-label={
          !isTrainingPillsCatalogueCompleted
            ? lang.LOADING
            : lang.RESULTS_LABEL?.replace(
              '{0}',
              (trainingPillsCatalogueToNumber || 0).toString()
            )?.replace('{1}', (trainingPillsCatalogueTotalNumber || 0).toString())
        }
      >
        {isTrainingPillsCatalogueCompleted && (
          <span className="results__number-courses">
            {lang.RESULTS_LABEL.replace('{0}', getTPNumber(trainingPillsCatalogueToNumber)).replace(
              '{1}',
              getTPNumber(trainingPillsCatalogueTotalNumber)
            )}
          </span>
        )}
      </section>
      <div className="section">
        <CoursesResultsWrapper
          {...(props.rightAnimation ? animationSlideToRightDefault : animationSlideToLeftDefault)}
          initialX={animationXSection}
          duration={animationDurationSection}
        >
          <CoursesResults
            setLimit={setLimitWithPagination}
            pagination={pagination}
            lang={lang}
            filteredCourses={new Array(+getTPNumber(trainingPillsCatalogueTotalNumber))}
            displayedCourses={getTrainingPillsCatalogueToDisplay(trainingPillsCatalogue)}
            isLoading={!isTrainingPillsCatalogueCompleted}
            isTP
            noResults={!!appliedFiltersNum}
            showTPAsMobile={props.showTPAsMobile}
            isSearch={props.isSearch}
            tab={props.tab}
          />
        </CoursesResultsWrapper>
      </div>
    </div>
  );
};

export default FiltersProduct;
