import { SKELETON_COLORS } from '@utility/const';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { CardBigEvolutionSkeletonPulse, useEditorialCarouselSkeletonPulseDimensions } from './EditorialCarouselSkeletonPulse';

const CardBigEvolutionSkeleton = props => {
  const dimensions = useEditorialCarouselSkeletonPulseDimensions();

  const fullHeight = dimensions.card.height + dimensions.card.marginBottom
    + dimensions.cardTitle1.height + dimensions.cardTitle1.marginBottom
    + dimensions.cardTitle2.height + dimensions.cardTitle2.marginBottom
    + dimensions.cardTitle3.height + dimensions.cardTitle3.marginBottom
    + dimensions.cardTitle4.height + dimensions.cardTitle4.marginBottom
    + dimensions.cardTitle5.height + dimensions.cardTitle5.marginBottom;

  return (
    <div
      className="skeleton__card-evolution"
      style={{ width: `${dimensions.card.width}px`, height: `${fullHeight}px`, overflow: 'hidden' }}
    >
      <ContentLoader
        speed={2}
        width={dimensions.card.width}
        height={fullHeight}
        viewBox={`0 0 ${dimensions.card.width} ${fullHeight}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <CardBigEvolutionSkeletonPulse x={0} y={0} dimensions={dimensions} />
      </ContentLoader>
    </div>
  );
};

export default CardBigEvolutionSkeleton;
