import React from 'react';
import { useDispatch, useSelector } from "@hooks/redux";
import { setPromptMethod } from '@redux-actions/';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => void
] {
  const dispatch = useDispatch();
  const prompt: IBeforeInstallPromptEvent | null = useSelector((state) => state.utils.promptMethod);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  React.useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      dispatch(setPromptMethod(e));
    };

    const handleAppInstalled = (evt) => {
      //close pwa banner because app has been installed
      console.log('PWA installed - appinstalled event triggered')
      dispatch(setPromptMethod(null));
    }

    window.addEventListener("beforeinstallprompt", ready as any);
    window.addEventListener("appinstalled", handleAppInstalled as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
      window.removeEventListener("appinstalled", ready as any);
    };
  }, []);

  return [prompt, promptToInstall];
}