import React, { useEffect, useState } from 'react';
import ChevronUp from '@svg-icons/chevron-up.svg';
import ChevronLeft from '@images/svg-icons/chevron-left.svg';
import PlusIcon from '@images/svg-icons/plus2.svg';
import useToggle from '@hooks/useToggle';
import clsx from '@utility/clsx';
import Modal from './Modal';
import Portal from '@components/UI/Portal';
import { truncateLabel } from '@utility/Api';
import { LangMapWcag } from '@model/CoursesClass';
import useLangAuth from '@hooks/useLangAuth';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonV2 from './ButtonV2';
import { USER_URLS } from '@components/link-utils';
import { cmMetaData } from '@utility/previewUtils';

type AccordionProps = {
  title?: string;
  titleComponent?: React.ReactNode;
  badgeElement?: JSX.Element;
  className?: string;
  children: React.ReactNode;
  hideTopBorder?: boolean;
  variant?: 'default' | 'gradient' | 'plus';
  contentSize?: 'default' | 'big';
  isModal?: boolean;
  toggleDefault?: boolean;
  alwaysOpen?: boolean;
  accordionList?: boolean;
  topRightSection?: JSX.Element;
  getMaxHeightFromCSSVariables?: boolean;
  numElements?: number;
  maxHeightOffset?: string;
  maxHeightMultiplier?: string;
  transitionOpenTime?: string;
  transitionCloseTime?: string;
  loadChildrenAfterExpansion?: boolean;
  hideIcon?: boolean;
  isTitleTextButton?: boolean;
  actionUrl?: string;
  // titleInlineStyle?: React.CSSProperties;
  titleClasses?: string;
  noBorder?: boolean;
  notToggled?: boolean;
  code?: string;
  onToggle?: (toggle: boolean) => void;
  id?: string;
  useTransitionTimeProps?: boolean;
  trackTransition?: boolean;
  forceToggleDefault?: boolean;
  faqContentId?: number;
};

const langKeys: Readonly<(keyof LangMapWcag)[]> = [
  'COLLAPSE_ACCORDION',
  'EXPAND_ACCORDION',
  'GO_BACK',
] as const;
const encodeActionUrl = url => {
  url = url
    ?.toLowerCase()
    .replaceAll(' ', '_')
    .replace(/[\.\?]/g, '');
  if (url) {
    return encodeURI(url);
  } else {
    return url;
  }
};
export const Accordion = (props: AccordionProps): React.ReactElement => {
  const [toggled, toggle] = useToggle(props.toggleDefault);
  const [onTransition, setOnTransition] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const labels = useLangAuth(langKeys);

  useEffect(() => {
    if (props.forceToggleDefault && props.toggleDefault !== toggled) {
      toggle(props.toggleDefault);
    }
  }, [props.toggleDefault, props.forceToggleDefault]);

  const handleToggle = value => {
    if (props.className === 'latest-collection__accordion-element') {
      if (!history?.location?.state?.listOfAccordionOpen) {
        history.replace({
          state: {
            listOfAccordionOpen: [],
          },
        });
      }
      let listOfStatusSaved = history?.location?.state?.listOfAccordionOpen;
      if (value) {
        listOfStatusSaved.push(props.id);
        history.replace({
          state: {
            listOfAccordionOpen: listOfStatusSaved,
          },
        });
      } else if (!value) {
        let cleanedList = listOfStatusSaved.filter(function (id) {
          return id !== props.id;
        });
        history.replace({
          state: {
            listOfAccordionOpen: cleanedList,
          },
        });
      }
    }

    if (props.actionUrl) {
      let url = encodeActionUrl(props.actionUrl);

      if (!value) {
        //if closing accordion
        if (window.location.pathname.includes(url)) {
          //if the url param points to this specific accordion --> change url
          window.history.pushState(
            { path: USER_URLS.CONTACT_US.URL },
            '',
            USER_URLS.CONTACT_US.URL
          );
        }
      } else {
        //if opening accordion --> change url
        window.history.pushState(
          { path: USER_URLS.CONTACT_US.NORMAL_URL + '/' + url },
          '',
          USER_URLS.CONTACT_US.NORMAL_URL + '/' + url
        );
      }
    }
    if (!props.alwaysOpen) {
      toggle(value);
    }
  };

  useEffect(() => {
    let actionUrl = props.actionUrl?.slice(0, -1);
    if (actionUrl) {
      const url = encodeActionUrl(props.actionUrl);
      if (window.location.pathname.includes(url)) {
        toggle(true);
      }
    } else {
      if (!props.isModal) {
        // toggle(true)
        toggle(toggled);
      }
      if (!props.toggleDefault) {
        toggle(false);
      }
    }
  }, []);

  useEffect(() => {
    props?.onToggle?.(toggled);
    setOnTransition(true);
  }, [toggled]);

  useEffect(() => {
    if (props.onToggle && props.notToggled === toggled) {
      toggle(!props.notToggled);
    }
  }, [props.notToggled]);

  const accordionClass = props.accordionList ? 'accordion-list' : 'accordion-v3';

  return (
    <div
      className={clsx(accordionClass, {
        [props.className]: props.className,
        [props.variant]: props.variant,
        'hide-border-top': props.hideTopBorder,
        'accordion-v3--expand': toggled,
        getMaxHeightFromCSSVariables: props.getMaxHeightFromCSSVariables,
        useTransitionTimeProps: props.useTransitionTimeProps,
      })}
      style={{
        '--num-elements': props.numElements || 1,
        '--height-per-elements': props.maxHeightMultiplier,
        '--height-offset': props.maxHeightOffset || '0px ',
        '--transition-open-time': props.transitionOpenTime || '0.2s',
        '--transition-close-time': props.transitionCloseTime || '0.2s',
      }}
     {...props?.faqContentId ? {...cmMetaData(props?.faqContentId)} : null}
    >
      <div
        className={`${accordionClass}__title-container`}
        onClick={() => handleToggle(!toggled)}
        id={props.id}
      >
        <div
          className={
            `${props.titleClasses ? props.titleClasses : ''} ${accordionClass}__title ` +
            (props.alwaysOpen ? 'always-open' : '')
          }
        >
          {props.titleComponent ||
            (props.isTitleTextButton ? (
              <ButtonV2 variant="text-btn-no-arrow">{props.title}</ButtonV2>
            ) : props.badgeElement ? (
              <>
                <div className={`${accordionClass}__title-badge-container`}>
                  <span>{props.title}</span>
                  {props.badgeElement}
                </div>
              </>
            ) : (
              <span>{props.title}</span>
            ))}

          {!props.alwaysOpen && !props.hideIcon && (
            <button
              className={
                `button-no-back ${accordionClass}__chevron ` +
                (toggled && !props.isModal ? '' : `${accordionClass}__chevron--expand`)
              }
              aria-label={`${
                toggled ? labels?.COLLAPSE_ACCORDION : labels?.EXPAND_ACCORDION
              } ${truncateLabel(props.title, 20)}`}
            >
              {props.isModal ? (
                <ChevronLeft />
              ) : !toggled && props.variant === 'plus' ? (
                <PlusIcon />
              ) : (
                <ChevronUp className={'chevron-up ' + props.variant} />
              )}
            </button>
          )}
        </div>
        <div className={`${accordionClass}__top-right-section`}>
          {!!props.topRightSection && props.topRightSection}
        </div>
      </div>

      {!props.isModal ? (
        <section
          className={clsx(`${accordionClass}__list ${props.noBorder && 'noBorder'}`, {
            expand: toggled,
            [props.contentSize]: props.contentSize,
            trackTransition: props.trackTransition,
            onTransition: props.trackTransition && onTransition,
          })}
          aria-hidden={!toggled}
          onTransitionEnd={() => {
            setOnTransition(false);
          }}
        >
          {(!props.loadChildrenAfterExpansion || toggled) && props.children}
        </section>
      ) : (
        <>
          {toggled && (
            <Portal parent={document.querySelector('#modal')}>
              <Modal
                hideX
                className="accordion-v3__modal"
                contentClassName="modal__content--small"
                close={() => handleToggle(false)}
                show={toggled}
              >
                <header>
                  <button
                    className="icon-button back-button"
                    onClick={() => handleToggle(false)}
                    aria-label={labels?.GO_BACK}
                  >
                    <ChevronLeft stroke="currentColor" />
                  </button>
                  <span className="accordion-modal__title">{props.title}</span>
                </header>
                <div className="accordion-v3__modal__content">{props.children}</div>
              </Modal>
            </Portal>
          )}
        </>
      )}
    </div>
  );
};

Accordion.defaultProps = {
  variant: 'default',
  contentSize: 'default',
};

export default Accordion;
