import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IframePopup from '@components/UI/IframePopup';
import CourseOverview from '@components/CourseOverview';
import TeacherOverview from '@components/TeacherOverview';
import { Course, CoursesMap, LangMap } from '@model/CoursesClass';
import ReviewModal from '@components/ReviewModal';
import { ConfirmBookingModal } from './ConfirmBookingModal';
import { getSessionByCoursesMap, isAnEvent, isChildOfAnEvent } from '@utility/Api';
import { ConfirmBookingModalParams } from '@model/ConfirmBookingModalParams';
import ModalUpNext from './UI/ModalUpNext';
import ModalPlan from './UI/ModalPlan';
import ModalCourseAlreadyPlan from './UI/ModalCourseAlreadyPlan';
import ModalCourseOnly1Month from './UI/ModalCourseOnly1Month';
import ModalLessonPartCourse from './UI/ModalLessonPartCourse';
import ModalCart from './UI/ModalCart';
import { ActivateLicenseModal } from '../pages_v3/ProfileActivateLicense';
import ModalContentExpiration from './UI/ModalContentExpiration';
import { ActivateLicenseModalParams } from '@model/ActivateLicenseModalParams';
import ModalStopRenewalConfirmation from './UI/ModalStopRenewalConfirmation';
import ModalActivatePlanConfirmation from './UI/ModalActivatePlanConfirmation';
import ModalDownloadLink from './UI/ModalDownloadLink';

import { useSelector } from '@hooks/redux';
import {
  bookSession,
  handleCloseIframe,
  toggleNextUpModal,
  toggleRatingModal,
  handleWhatsNextModal,
  setShowModalCart,
  setShowModalContentExpiration,
  setShowModalCourseAlreadyPlan,
  setShowModalCourseOnly1Month,
  setShowModalLessonPartCourse,
  setShowModalPlan,
  setShowModalStopRenewalConfirmationInfo,
  closeConfirmBookingModal,
  toggleIframePopup,
  setModalStartAutorenewal,
  setShowModalAlertAddCartPlan,
  setShowModalExpiredInPlan,
  setModalActivatePlan,
  setModalRecoveringLicense,
  toggleDownloadLinkModal
} from '@redux-actions/';
import { UserProfile } from '@model/User';
import ModalStartAutorenewal from './UI/ModalStartAutorenewal';
import ModalMessage from './UI/ModalMessage';
import CollectionsBrandModal from './UI/collections/CollectionsBrandModal';
import StoryModal from './UI/collections/StoryModal';
import useModalTypes from '@hooks/useModalByType';
import MenuModalCollection from './MenuMobile/MenuModalCollection';
import ModalRecoveringLicense from './UI/ModalRecoveringLicense';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';

type Props = {
  location: Location;
  logout: () => void;
  isAuthenticated: boolean;
};

const ModalCollection = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector<LangMap>(state => state.utils.lang);
  const userProfile = useSelector<UserProfile>(state => state.user.userProfile);
  const coursesMap = useSelector<CoursesMap>(state => state.course.coursesMap);
  const {isPhone} = useBreakpointBoolean()
  //show course detail
  const showCourseModal = useSelector<boolean>(state => state.course.showCourseModal);
  //show teacher detail
  const showTeacherModal = useSelector<boolean>(state => state.course.showTeacherModal);

  //lesson iframe modal
  const showIframe = useSelector<boolean>(state => state.utils.showIframe);
  const urlIframe = useSelector<string>(state => state.utils.urlIframe);
  const urlToCheckIframe = useSelector<string>(state => state.utils.urlToCheckIframe);
  const isIframeVideo = useSelector<boolean>(state => state.utils.isIframeVideo);
  const courseIframe = useSelector<Course>(state => state.utils.courseIframe);
  const urlsLivestream = useSelector<{
    liveUrl: string;
    liveUrlCn: string;
    liveChat: string;
    liveEventId: string;
  }>(state => state.utils.urlsLivestream);

  //rating modal
  const showRatingModal = useSelector<boolean>(state => state.course.showRatingModal);
  const ratingModalCourse = useSelector<Course>(state => state.course.ratingModalCourse);

  //what's next up modal (when close a lesson within a LP)
  const showNextUpModal = useSelector<boolean>(state => state.course.showNextUpModal);
  const nextUpModalCourses = useSelector<Course[]>(state => state.course.nextUpModalCourses);

  //booking confirmation modal
  const bookingModalParams = useSelector<ConfirmBookingModalParams>(
    state => state.course.bookingModalParams
  );
  const isBookingSession = useSelector<boolean>(state => state.course.isBookingSession);

  //download link modal
  const showDownloadLinkModal = useSelector<boolean>(state => state.course.showDownloadLinkModal);
  const linkToDownload = useSelector<string>(state => state.course.linkToDownload)
  const courseToDownload = useSelector<string>(state => state.course.courseToDownload)

  //ecommerce modals
  const showModalPlan = useSelector<boolean>(state => state.ecommerce.showModalPlan);
  const showModalCourseAlreadyPlan = useSelector<boolean>(
    state => state.ecommerce.showModalCourseAlreadyPlan
  );
  const showModalCourseOnly1Month = useSelector<boolean>(
    state => state.ecommerce.showModalCourseOnly1Month
  );
  const showModalLessonPartCourse = useSelector<boolean>(
    state => state.ecommerce.showModalLessonPartCourse
  );
  const showModalCart = useSelector<boolean>(state => state.ecommerce.showModalCart);
  const showModalContentExpiration = useSelector<boolean>(
    state => state.ecommerce.showModalContentExpiration
  );
  const modalStopRenewalConfirmationInfo = useSelector(
    state => state.ecommerce.modalStopRenewalConfirmationInfo
  );
  const modalStartRenewalConfirmationInfo = useSelector(
    state => state.ecommerce.modalStartRenewalConfirmationInfo
  );
  const modalActivatePlanConfirmation = useSelector(
    state => state.ecommerce.modalActivatePlanConfirmation
  );
  const modalRecoveringLicense = useSelector(
    state => state.ecommerce.modalRecoveringLicense
  );
  //console.log("🚀 ~ file: ModalCollection.tsx:221 ~ ModalCollection ~ bookingModalParams?.open:", bookingModalParams?.open)
  //activate token modal
  const showModalLicenseActivate = useSelector<ActivateLicenseModalParams>(
    state => state.ecommerce.showModalLicenseActivate
  );

  const modalAlertAddCartPlan = useSelector(state => state.ecommerce.modalAlertAddCartPlan);
  const modalAlertExpiredInPlan = useSelector(state => state.ecommerce.modalAlertExpiredInPlan);

  const collectionsBrandModalInfo = useSelector(
    state => state.trainingPills.collectionsBrandModalInfo
  );

  const storyModalInfo = useSelector(state => state.trainingPills.storyModalInfo);
  const modalTypes = useModalTypes();

  useEffect(() => {
    //block scroll in background
    if (showCourseModal || showTeacherModal || showIframe || showDownloadLinkModal) {
      document.body.style.overflow = 'hidden';
      if(showDownloadLinkModal && isPhone) {
        document.body.style.position = 'fixed';
      }
    } else if (!showCourseModal && !showTeacherModal && !showIframe && !showDownloadLinkModal) {
      document.body.style.overflow = 'auto';
      if(!showDownloadLinkModal && isPhone) {
        document.body.style.position = 'unset';
      }
    }
  }, [showCourseModal, showTeacherModal, showIframe, showDownloadLinkModal, isPhone]);

  useEffect(() => {
    if (!isBookingSession && bookingModalParams?.open) {
      handleCloseConfirmBookingModal();
    }
  }, [isBookingSession]);

  const handleCloseConfirmBookingModal = (isClosedByUser: boolean = false) => {
    if (!isClosedByUser && coursesMap && coursesMap[bookingModalParams?.courseId]) {
      //what's next modal
      dispatch(handleWhatsNextModal(coursesMap[bookingModalParams?.courseId]));
    }

    dispatch(closeConfirmBookingModal());
  };

  const closeIframe = (isCompleted?: boolean) => {
    //handle rating or what's next modal
    console.log('isCompleted', isCompleted);
    dispatch(handleCloseIframe(courseIframe, false, isCompleted));
    dispatch(toggleIframePopup(false));
  };

  const closeReviewModal = () => {
    // close modal rating
    dispatch(toggleRatingModal(false, null));

    //what's next modal
    dispatch(handleWhatsNextModal(ratingModalCourse));
  };

  return (
    <React.Fragment>
      <CourseOverview history={history} />
      <TeacherOverview />
      {showIframe && (
        <IframePopup
          lang={lang}
          url={urlIframe}
          urlChina={urlsLivestream?.liveUrlCn}
          urlChat={urlsLivestream?.liveChat}
          eventId={urlsLivestream?.liveEventId}
          close={closeIframe}
          urlToCheck={urlToCheckIframe}
          isVideo={isIframeVideo}
          course={courseIframe}
        />
      )}

      {ratingModalCourse &&
        !isChildOfAnEvent(ratingModalCourse.courseIdMaster, coursesMap) &&
        !isAnEvent(ratingModalCourse) && (
          <ReviewModal
            courseName={ratingModalCourse.courseFullName}
            course={ratingModalCourse}
            show={showRatingModal}
            goBack={closeReviewModal}
          />
        )}
      {showNextUpModal && (
        //  !nextUpModalCourses.find(course => isChildOfAnEvent(course.courseId, coursesMap)) &&
        <ModalUpNext
          open={showNextUpModal}
          courses={nextUpModalCourses}
          close={() => dispatch(toggleNextUpModal(false, []))}
        />
      )}
      {bookingModalParams?.open && (
        <ConfirmBookingModal
          closeModal={() => handleCloseConfirmBookingModal(true)}
          course={coursesMap && coursesMap[bookingModalParams?.courseId]}
          bookSession={() =>
            dispatch(
              bookSession(
                bookingModalParams?.sessionId,
                bookingModalParams?.isSessionSignUp,
                bookingModalParams?.courseId
              )
            )
          }
          session={getSessionByCoursesMap(
            coursesMap,
            bookingModalParams?.courseId,
            bookingModalParams?.sessionId
          )}
          coursesMap={coursesMap}
          show={bookingModalParams?.open}
          isSignUp={bookingModalParams?.isSessionSignUp}
          isLoading={isBookingSession}
        />
      )}
      {showDownloadLinkModal &&
        <ModalDownloadLink 
          show={showDownloadLinkModal}
          linkToDownload = {linkToDownload}
          close={() => dispatch(toggleDownloadLinkModal(false))}
          course={courseToDownload}
        />
      }
      {showModalPlan && (
        <ModalPlan show={showModalPlan} close={() => dispatch(setShowModalPlan(false))} />
      )}
      {showModalCourseAlreadyPlan && (
        <ModalCourseAlreadyPlan
          show={showModalCourseAlreadyPlan}
          close={() => dispatch(setShowModalCourseAlreadyPlan(false))}
        />
      )}
      {showModalCourseOnly1Month && (
        <ModalCourseOnly1Month
          show={showModalCourseOnly1Month}
          close={() => dispatch(setShowModalCourseOnly1Month(false))}
        />
      )}
      {showModalLessonPartCourse && (
        <ModalLessonPartCourse
          show={showModalLessonPartCourse}
          close={() => dispatch(setShowModalLessonPartCourse(false))}
        />
      )}
      {showModalCart && (
        <ModalCart
          show={showModalCart}
          close={() => dispatch(setShowModalCart(false))}
          shoppable={true}
        />
      )}
      {showModalContentExpiration && (
        <ModalContentExpiration
          show={showModalContentExpiration}
          close={() => dispatch(setShowModalContentExpiration(false))}
        />
      )}
      {modalStopRenewalConfirmationInfo && (
        <ModalStopRenewalConfirmation
          show={modalStopRenewalConfirmationInfo.show}
          close={() => dispatch(setShowModalStopRenewalConfirmationInfo(false))}
          data={modalStopRenewalConfirmationInfo}
        />
      )}
      {modalStartRenewalConfirmationInfo && (
        <ModalStartAutorenewal
          show={modalStartRenewalConfirmationInfo.show}
          plan={modalStartRenewalConfirmationInfo.plan}
          data={modalStartRenewalConfirmationInfo}
          close={() => dispatch(setModalStartAutorenewal(false))}
        />
      )}
      {modalActivatePlanConfirmation && (
        <ModalActivatePlanConfirmation
          show={modalActivatePlanConfirmation.show}
          availableLicenses={modalActivatePlanConfirmation.availableLicenses}
          close={() => dispatch(setModalActivatePlan(false))}
        />
      )}
       {modalRecoveringLicense && (
        <ModalRecoveringLicense
          show={modalRecoveringLicense.show}
          email={modalRecoveringLicense.email}
          token={modalRecoveringLicense.token}
          close={() => dispatch(setModalRecoveringLicense(false))}
        />
      )}

      {modalAlertAddCartPlan && (
        <ModalMessage
          alert
          show={modalAlertAddCartPlan}
          title={lang.ADD_ITEMS_PLAN_ERROR_TITLE}
          description={lang?.ADD_ITEMS_PLAN_ERROR_DESCR}
          close={() => dispatch(setShowModalAlertAddCartPlan(false))}
        />
      )}
      {modalAlertExpiredInPlan && (
        <ModalMessage
          alert
          show={modalAlertExpiredInPlan}
          title={lang.ERROR_CANT_CONTINUE_LESSON_NO_PLAN_TITLE}
          description={lang?.ERROR_CANT_CONTINUE_LESSON_NO_PLAN_DESCR}
          close={() => dispatch(setShowModalExpiredInPlan(false))}
        />
      )}
      {showModalLicenseActivate.open && (
        <ActivateLicenseModal
          show={showModalLicenseActivate.open}
          success={showModalLicenseActivate.success}
          loading={showModalLicenseActivate.loading}
          errorCode={showModalLicenseActivate.errorCode}
        />
      )}
      {collectionsBrandModalInfo?.show && <CollectionsBrandModal />}
      {storyModalInfo?.show && <StoryModal />}
      <MenuModalCollection />
    </React.Fragment>
  );
};

export default ModalCollection;
