import { Course, CourseAssociatedSession, CourseSession, RecordingLinks } from "@model/CoursesClass";
import { canJoinSession, canSeePlayButton, checkCourseState, isCompletedCourse, isFaceToFaceAvailable, isMissedInCatalog, isVirtualClassroom } from "./Api";
import { courseStatesConst } from "./const";

export const getVCRecording = (crs) => {
    if (!crs) {
        return null;
    }

    const { courseSessions, userRelevantSessions, courseStates } = crs;
    const { MISSED, ATTENDED } = courseStatesConst;

    if (checkCourseState(courseStates, ATTENDED) || checkCourseState(courseStates, MISSED) || isMissedInCatalog(crs) || isCompletedCourse(crs)) {
        if (userRelevantSessions.length > 0 && (checkCourseState(courseStates, ATTENDED) || isCompletedCourse(crs))) {
            if (canSeePlayButton(userRelevantSessions) && isFaceToFaceAvailable(userRelevantSessions)) {
                return userRelevantSessions[0].webExRecordingLinks[0];
            } else {
                return '';
            }
        } else if (checkCourseState(courseStates, MISSED) || isMissedInCatalog(crs)) {
            let mostRecentSessionWithRec = null;

            if (isFaceToFaceAvailable(userRelevantSessions)) {
                //get most recent recordings
                courseSessions?.forEach(session => {
                    if (session?.webExRecordingLinks?.length > 0) {
                        if (mostRecentSessionWithRec) {
                            if (new Date(session.minStartDate).getTime() > new Date(mostRecentSessionWithRec.minStartDate).getTime()) {
                                mostRecentSessionWithRec = session.webExRecordingLinks[0];
                            }
                        } else {
                            mostRecentSessionWithRec = session.webExRecordingLinks[0];
                        }
                    }
                })
            }

            if (mostRecentSessionWithRec) {
                return mostRecentSessionWithRec;
            } else {
                return '';
            }
        }
    }

    return null;
}

/**
 * Function to get VC recording links
 * @param crs 
 * @returns Recording links (completionurl: url for completion; directurl: url of crosscast video)
 */
export const getVCRecordingLinks = (crs: Course): RecordingLinks => {
    
    if (!crs) {
        return null;
    }

    const { courseSessions, userRelevantSessions, courseStates } = crs;
    const { MISSED, ATTENDED } = courseStatesConst;

    if (checkCourseState(courseStates, ATTENDED) || checkCourseState(courseStates, MISSED) || isMissedInCatalog(crs) || isCompletedCourse(crs)) {
        if (userRelevantSessions.length > 0 && (checkCourseState(courseStates, ATTENDED) || isCompletedCourse(crs))) {
            if (canSeePlayButton(userRelevantSessions) && isFaceToFaceAvailable(userRelevantSessions)) {
                return {
                    completionurl: userRelevantSessions[0]?.webExRecordingLinks[0]?.completionurl,
                    directurl: userRelevantSessions[0]?.webExRecordingLinks[0]?.directurl
                };
            } else {
                return {
                    completionurl: '',
                    directurl: ''
                };
            }
        } else if (checkCourseState(courseStates, MISSED) || isMissedInCatalog(crs)) {
            let mostRecentSessionWithRec: CourseSession = null;

            if (isFaceToFaceAvailable(userRelevantSessions)) {
                //get most recent recordings
                courseSessions?.forEach((session: CourseSession) => {
                    if (session?.webExRecordingLinks?.length > 0) {
                        if (mostRecentSessionWithRec) {
                            if (new Date(session?.minStartDate)?.getTime() > new Date(mostRecentSessionWithRec?.minStartDate)?.getTime()) {
                                mostRecentSessionWithRec = session;
                            }
                        } else {
                            mostRecentSessionWithRec = session;
                        }
                    }
                })
            }

            if (mostRecentSessionWithRec) {
                return {
                    completionurl: mostRecentSessionWithRec?.webExRecordingLinks[0]?.completionurl,
                    directurl: mostRecentSessionWithRec?.webExRecordingLinks[0]?.directurl
                }
            } else {
                return {
                    completionurl: '',
                    directurl: ''
                };
            }
        }
    }

    return {
        completionurl: null,
        directurl: null
    };
}

export const getVCRecordings = (crs) => {
    if (!crs) {
        return null;
    }
    const { courseSessions, userRelevantSessions, courseStates } = crs;
    const { MISSED, ATTENDED } = courseStatesConst;

    if (checkCourseState(courseStates, ATTENDED) || checkCourseState(courseStates, MISSED) || isMissedInCatalog(crs) || isCompletedCourse(crs)) {
        if (userRelevantSessions.length > 0 && (checkCourseState(courseStates, ATTENDED) || isCompletedCourse(crs))) {
            if (canSeePlayButton(userRelevantSessions) && isFaceToFaceAvailable(userRelevantSessions)) {
                return userRelevantSessions[0].webExRecordingLinks;
            } else {
                return [];
            }
        }
    }
    return [];
}

export const getVCDateForBadge = (course: Course): { timeCourseStart: string, timeCourseEnd: string } => {
    let dateForBadge = { timeCourseStart: null, timeCourseEnd: null };

    if (!course || !isVirtualClassroom(course)) {
        return dateForBadge;
    }

    course?.courseSessions?.forEach(session => {
        const relevantSession = course?.userRelevantSessions?.find(a => a.id === session.id);
        const canJoinThisSession = canJoinSession(relevantSession);
        if (canJoinThisSession) {
            dateForBadge = {
                timeCourseStart: session.sessionDates?.[0]?.timeStart,
                timeCourseEnd: session.sessionDates?.[0]?.timeFinish,
            }
        }

    })

    return dateForBadge;
}

export const getVCDateBadgeFromAssociatedSession = (course: Course, associatedSession: CourseAssociatedSession): { timeStart: string, timeEnd: string } => {
    let dateForBadge = { timeStart: null, timeEnd: null };
    if (course && associatedSession) {
        const relevantSession = course?.userRelevantSessions?.find(a => a.id === associatedSession?.id);
        const canJoinThisSession = canJoinSession(relevantSession);
        if (canJoinThisSession) {
            dateForBadge = {
                timeStart: associatedSession?.timeStart,
                timeEnd: associatedSession?.timeFinish,
            }
        }
    }


    return dateForBadge;
}

export const orderSessions = (sessions: CourseSession[], orderType: string = ""): CourseSession[] => {
    let sessionsSorted = [...sessions];

    return sessionsSorted.sort((a, b) => {
        let dateA = new Date(a.minStartDate).getTime(),
            dateB = new Date(b.minStartDate).getTime();
        if (orderType === "desc") return dateB - dateA;
        return dateA - dateB;
    });
};