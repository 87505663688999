// import LazySVG from '@components/LazySvg';
import React from 'react';

type Props = {
  className?: string;
};

const CardLoader = (props: Props) => {
  return <div className={'card-loader ' + (props.className ? props.className : '')} />;
};

export default CardLoader;
