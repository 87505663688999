import React from 'react';
import { Course, LangMap } from '../model/CoursesClass';
import CarouselSection from './CarouselSection';
import { handleOnEnterKeyPress } from '@utility/Api';
import { useSelector } from '@hooks/redux';
import useGetMostLikedCourses from '@hooks/useGetMostLikedCourses';

type Props = {
  searchQuery: string;
  suggestedPhrase: string;
  search: (forceSearchQuery: string, forceNoCourseSuggestion: boolean) => void;
};

let placeholder = '{SEARCH}';

const NoSearchResults = ({ searchQuery, suggestedPhrase, search }: Props) => {
  const lang: LangMap = useSelector(state => state.utils.lang);
  const [mostLikedCourses] = useGetMostLikedCourses(12);

  const print = () => {
    let indexPlaceholder = lang.NO_SEARCH_RESULTS_DID_YOU_MEAN.indexOf(placeholder);

    return (
      <>
        {lang.NO_SEARCH_RESULTS_DID_YOU_MEAN.slice(0, indexPlaceholder)}
        <span
          className="search-catalogue__search-instead-link focus-outline"
          tabIndex={0}
          onKeyDown={event => handleOnEnterKeyPress(event, () => search(searchQuery, false))}
          onClick={() => search(searchQuery, false)}
        >
          {suggestedPhrase}
        </span>
        {lang.NO_SEARCH_RESULTS_DID_YOU_MEAN.slice(
          indexPlaceholder + placeholder.length,
          lang.NO_SEARCH_RESULTS_DID_YOU_MEAN.length
        )}
      </>
    );
  };

  return (
    <div className="no-search-results">
      <div className="no-search-results__text-container">
        <div className="no-search-results__text">
          <div className="no-search-results__text__main">
            {lang.NO_SEARCH_RESULTS_SORRY.replace(placeholder, searchQuery)}
          </div>
          {suggestedPhrase && <div className="no-search-results__text__second">{print()}</div>}
          <div className="no-search-results__text__desc">
            <p>{lang.NO_SEARCH_RESULTS_IF_YOU_DIDNT_FIND}</p>
            <ul>
              <li>{lang.NO_SEARCH_RESULTS_DOUBLE_CHECK}</li>
              <li>{lang.NO_SEARCH_RESULTS_CHANGE_QUERY}</li>
              <li>{lang.NO_SEARCH_RESULTS_BE_LESS_SPECIFIC}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="no-search-results__carousel carousel-max-width">
        <CarouselSection
          elements={mostLikedCourses}
          lang={lang}
          title="TRY_BROWSING_MOST_LIKED_CONTENT"
          variableWidth
        />
      </div>
    </div>
  );
};

export default NoSearchResults;
