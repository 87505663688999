
import { useSelector } from "./redux";
import { LangMap } from '@model/CoursesClass';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


/* if i18n key is different from lang key */
const customKeysi18n: {[key: string]: string} = {

};

/**
 * @example 
 * const keys = ["keyLabel", "keyLabel2"] as const
 * const labels = useLangAuth(keys);
 */
function useLangAuth<T extends Readonly<string[]>>(langKeys: T) {
  const lang: LangMap = useSelector((state) => state.utils.lang);
  const [labels, setLabels] = useState<Record<T[number], string>>();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const { t } = useTranslation();

  useEffect(() => {

    const labs = langKeys.reduce<Record<T[number], string>>((acc, key)=> {
      const i18nKey = customKeysi18n[key] || key;
      return ({
        ...acc, [key]: isAuthenticated? lang?.[key] || t(i18nKey): t(i18nKey)
      })
  }, {} as any);

  setLabels(labs);

  }, [isAuthenticated, lang, t]);

  return labels;
}

export default useLangAuth;