import React, { ReactElement, useEffect, useRef, useState } from 'react';
import useLangAuth from "@hooks/useLangAuth";

interface LangageObject  {
    code: string,
    courseId: string,
    description: string,
    isParent: boolean,
    label: string,
    parentId: string,
    value: string,
}

interface Props  {
    items?: Array<LangageObject>
    availableLanguages?: Array<LangageObject>
}


const ComingSoonList = ({ items, availableLanguages  }:Props): ReactElement => {
    const labels = useLangAuth([
        "COMING_SOON_LANG_TITLE"
    ]);

    const [comingSoonFiltered, setComingSoonFiltered] = useState<LangageObject[] | null>(null);


    useEffect(() => {
        if (availableLanguages && items ){
            let arrayCompareItems = [];
            let arrayCompareAvailable =[];
            let arrayEqualsItems = [];
            
            for(let i of items) { 
                arrayCompareItems.push(i.code);
            }
        
            for(let i of availableLanguages) {
                arrayCompareAvailable.push(i.label);
            }
        
            for(let i of arrayCompareAvailable){
                if(arrayCompareItems.includes(i)){
                    arrayEqualsItems.push(i);
                }
            }

            const csFiltered = items.filter(item =>{
                return !arrayEqualsItems.includes(item.code)
            })        
            setComingSoonFiltered(csFiltered);
        }
    }, [items])

    return comingSoonFiltered && comingSoonFiltered.length > 0 ? (
        <div className='coming-soon'>
            <p className='coming-soon__title'>{labels?.COMING_SOON_LANG_TITLE}</p>
            {comingSoonFiltered?.map((item, index) => (
                <span className='coming-soon__single-lang' key={"_" + index}>{(index + 1) < comingSoonFiltered.length ? item.value + ',' : item.value} </span>
            ))}
        </div>
    ) : null
} 
export default ComingSoonList;