export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum GRADIENT_COLOR {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum LEADERBOARD_PRESENCE {
  ENABLED = 'yes',
  DISABLED = 'no',
}

export enum NOTIFICATION_CHANNELS {
  ENABLED = 'email',
  DISABLED = '',
}

export enum USERGROUP_ACTIVE_VALUES{
  ENABLED =  'enabled',
  DISABLED = 'disabled',
}

export type UserProfile = {
  username: string;
  email: string;
  country: string;
  ecommerceInfo:{country:string,currency:string}
  firstName: string;
  lastName: string;
  department: string;
  timezone: string;
  phone: string;
  address: string;
  city: string;
  lang: string;
  description: string;
  location: string;
  jobTitle: string;
  subarea: string;
  office: string;
  userType: string;
  managerId: string;
  hireDate: number;
  state: string;
  zip: string;
  retailRegion: string;
  geographicalArea: string;
  brandRegion: string;
  departmentType: string;
  managerOf: string;
  managerLevel: string;
  employeeStatus: string;
  customManager: string;
  customerGroup: string;
  secondManager: string;
  company: string;
  managersEmail: string;
  retailer: string;
  channelCode: string;
  channel: string;
  rating: string;
  area: string;
  b2bSyncstatus: string;
  disclaimerDate: number;
  b2bbrand: string[];
  editableUserByImport: string;
  credits: number;
  role: string[];
  companyCode: string;
  preferredLang: string;
  senderSystem: string;
  preferencesToDo: boolean;
  preferencesSetDate: string;
  preferredTheme: THEME;
  preferredGradientColor: GRADIENT_COLOR;
  preferredColorTheme: string; //VALUES TO BE DEFINED
  followedBrands: Array<string>;
  followedChannels: Array<string>;
  preferredLeaderBoardPresence: LEADERBOARD_PRESENCE;
  preferredNotificationChannels: NOTIFICATION_CHANNELS;
  removedFromWish: boolean;
  pillsRegion: string;
  subscription: boolean;
  ecommerceStatus: string;
  boughtPlanOrderId: string;
  myelRedirect: boolean;
  linkIntranet?: string;
  noveltiesStatus?: string;
  extRole?: string;
  waitingList?: { [courseIdMaster: string]: boolean }; //map for master waiting lists, true if user is in waiting list
  schoolName?: string;
  graduationYear?: string;
  stateOfResidence?: string;
  moodleId?: string;
  el360: string;
  el360Ecomm?: boolean;
  bycStatus?: "standard" | "office"
  simulationStatus: "enabled" | "disabled"
  //NEW FIELDS CERTIFICATE IMPROVEMENTS
  learnerName?: string;
  licenseId?: string;
  field1?: string;
  field2?: string;
  logoUrlSvg?: string;
  logoUrlPng?: string;
  menuEntryPoint?: {
    podcast: boolean;
};
};

export type UserGroup = {
  username: string,
  simulableMacrouser: string,
  retailer: string,
  brandRegion: string,
  country: string,
  userType: string,
  favorite: boolean,
  simulableInUse: boolean,
}