import React, { Component } from 'react';
import {
  isLearningPath,
  enrichCourseInfo,
  isVirtualClassroom,
  isVideoOrPodcast,
  printDurationHourFormat,
  printHTML,
  getCourseCompatibility,
  scrollCourseDetailToTop,
  getSubtitlesCodes,
  isMultiActivity,
  isLivestreamCourse,
  getCourseWithinLP,
  isCourseInLPDisabled,
  getCoursesWithinLP,
  scrollToSessions,
  isPoweredByEcpu,
  isRecognizedByABO,
  getParentCourse,
} from '../../utility/Api';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import DisplayTopDesk from './DisplayTopDesk';
import DisplayTopMobile from './DisplayTopMobile';
import Modal from '../UI/Modal';
import { withTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '@components/Header';
import RecommendedSection from './RecommendedSection';
import DetailCourseActivities from './DetailCourseActivities';
import AnimationSlide, { animationSlideToTop } from '@components/animations/AnimationSlide';
import { USER_URLS, UtagViewPages } from '@components/link-utils';
import { HEADER_LEVEL, MODAL_TYPES, queryParams } from '@utility/const';
import CardController from '@components/CardV3';
import { getBlockingLps, isLpBlocked } from '@utility/LPUtility';
import {
  canCourseBeAccessed,
  isPriceable,
  isPurchasable,
  toFixedNumString,
} from '@utility/ecommerceUtility';
import HelmetMetaTags from '@components/HelmetMetaTags';
import { getLevelLabel } from '@utility/levelUtility';
import { sendCourseOverviewUtagData } from '@utility/analytics-utils';
import CardActivitySkeleton from '@components/skeletons/CardActivitySkeleton';
import { checkUpcomingOrPastEvent } from '@hooks/useUpcomingEvents';
import Footer from '@components/Footer';

class CourseOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowSummary: false,
      language: props.currentCourse?.languageToPrint,
      languagesOptions: [],
      subtitlesOptions: [],
      comingSoonLangOptions: [],
      selectedSubtitle: null,
      childCourse: null,
      lpCourses: [],
      liveLang: '',
      courseParentDetail: {},
    };
  }

  componentDidMount = () => {

    if (this.props.currentCourse && !this.props.currentCourse.isStatic) {
      if (
        canCourseBeAccessed(this.props.currentCourse) &&
        !isLearningPath(this.props.currentCourse) &&
        !this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster]
      ) {
        this.props.getCourseDetails(this.props.currentCourse);
      }

      if (this.props.currentCourse.totalLanguages && this.props.languages) {
        this.setLanguagesOptions();
      }
      if (this.props.currentCourse.courseIdMaster && this.props.coursesMap) {
        this.setCourseParentDetail();
      }
      if (this.props.currentCourse.comingSoonLanguages && this.props.languages) {
        this.setComingSoonLanguagesOptions();
      }
      if (this.props.currentCourse.tags && this.props.languages) {
        this.setSubtitlesOptions(true);
      }
      if (isLearningPath(this.props.currentCourse)) {
        this.getCoursesWithinLP();
      }
     // console.log("**** iS LOADING?", this.props.isLoadingGetUserRelevantSessionById)
      if((isVirtualClassroom(this.props.currentCourse) || isLearningPath(this.props.currentCourse) || isMultiActivity(this.props.currentCourse)) && !isLoadingGetUserRelevantSessionById){
        //console.log("**** COURSE OVERVIEW IS CALLING GETUSERRELEVANTSESSIONS", this.props.currentCourse.courseId)
        this.getUserRelevantSessionsCourse(this.props.currentCourse.courseId);
      }
      // console.log("**** iS LOADING?", this.props.isLoadingGetUserRelevantSessionById)
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.currentCourse && !this.props.currentCourse.isStatic) {
      if (prevProps.currentCourse !== this.props.currentCourse || prevProps.isLoadingGetUserRelevantSessionById !== this.props.isLoadingGetUserRelevantSessionById) {
        if (prevProps.currentCourse?.courseIdMaster !== this.props.currentCourse.courseIdMaster) {
          if (
            canCourseBeAccessed(this.props.currentCourse) &&
            !isLearningPath(this.props.currentCourse) &&
            !this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster]
          ) {
            this.props.getCourseDetails(this.props.currentCourse);
          }
        // console.log("**** iS LOADING?", this.props.isLoadingGetUserRelevantSessionById, this.props.currentCourse)
          if(((isVirtualClassroom(this.props.currentCourse) || isLearningPath(this.props.currentCourse) || isMultiActivity(this.props.currentCourse)) && !this.props.isLoadingGetUserRelevantSessionById) 
          || this.props.userProfile?.preferredLang !== prevProps.userProfile?.preferredLang
          ){
           // console.log("**** COURSE OVERVIEW IS CALLING GETUSERRELEVANTSESSIONS", this.props.currentCourse.courseId)
            this.props.getUserRelevantSessionsCourse(this.props.currentCourse.courseId)
          }
         // console.log("**** iS LOADING?", this.props.isLoadingGetUserRelevantSessionById)

          //scroll to top
          scrollCourseDetailToTop('auto');

          this.setCourseParentDetail();
        }
        this.setLanguagesOptions();
        this.setComingSoonLanguagesOptions();
        this.setSubtitlesOptions(
          prevProps.currentCourse?.courseId !== this.props.currentCourse.courseId
        );
        this.setLiveLang();

        // if (isLearningPath(this.props.currentCourse)) {
        //   this.getCoursesWithinLP();
        // }

        if (this.props.scrollToCurrentCourseSessions) {
          setTimeout(() => {
            const hasScrolled = scrollToSessions(true);
            if (hasScrolled) {
              this.props.resetScrollToCurrentCourseSessions();
            }
          }, 100);
        }
      }
      if (this.props.userProfile?.preferredLang !== prevProps.userProfile.preferredLang) {
        let foundMatch = false;
        this.props.currentCourse.childCourses.forEach((child) => {
    
            if (child.language === this.props.userProfile?.preferredLang) {
                this.props.setChildCourse(child.languageToPrint);
                foundMatch = true;
                return;
            }
            
        });
    
        // If no match is found: look for english child, if no english child use courseParentDetail as last fallback
        if(!foundMatch) {
          const enFallback = this.props.currentCourse.childCourses.find( child => child.language === "en")
          if(enFallback) {
            this.props.setChildCourse(enFallback.languageToPrint);
          } else {
            this.state.courseParentDetail.languageToPrint && this.props.setChildCourse(this.state.courseParentDetail.languageToPrint);
          }
        }
    }

    if(Object?.keys(this.props.labelL1Map)?.length > 1 && this?.props?.typeModal !== MODAL_TYPES.notificationsRightModal) {
      if (
        this.props.userProfile &&
        this.props.isUserCompletedAnalytics &&
        Object.keys(this.props.labelL1Map).length > 1
      ) {
        if(this.props.analyticsCourseId !== this.props.currentCourse?.courseId) {
          this.sendCourseOverviewUtagData();
          this.props.setAnalyticsCourseId(this.props.currentCourse?.courseId)
        }
      }
    }

      if (
        (prevProps.currentCourse !== this.props.currentCourse ||
          prevProps.coursesMap !== this.props.coursesMap) &&
        isLearningPath(this.props.currentCourse)
      ) {
        this.getCoursesWithinLP();
      }
    }
  };

  sendCourseOverviewUtagData = () => {
    sendCourseOverviewUtagData(
      this.props.currentCourse,
      this.props.userProfile,
      'overlay',
      this.props.labelL1Map
    );
  }

  getCoursesWithinLP = () => {
    let lpCourses = getCoursesWithinLP(this.props.currentCourse, this.props.coursesMap);

    this.setState({
      lpCourses,
    });
  };

  setComingSoonLanguagesOptions = () => {
    if (!isVirtualClassroom(this.props.currentCourse)) {
      let comingSoonLangOptions = [];
      if (this.props.currentCourse.comingSoonLanguages) {
        this.props.currentCourse.comingSoonLanguages.map(csLang => {
          this.props.languages.map(lang => {
            if (lang.code === csLang.value) {
              let comingSoonLang = { ...csLang, ...lang };
              comingSoonLangOptions.push(comingSoonLang);
            }
          });
        });
      }

      comingSoonLangOptions = orderBy(comingSoonLangOptions, ['label']);
      this.setState({
        comingSoonLangOptions,
      });
    } else {
      this.setState({
        comingSoonLangOptions: [],
      });
    }
  };


  setCourseParentDetail = () => {
    this.setState({
      courseParentDetail: getParentCourse(this.props.currentCourse.courseIdMaster, this.props.coursesMap)
    });
  }

  setLanguagesOptions = () => {
    if (!isVirtualClassroom(this.props.currentCourse)) {
      let languagesOptions = [],
        languageToPrint = this.props.currentCourse.languageToPrint,
        language = this.props.currentCourse.languageToPrint;
      if (this.props.currentCourse.totalLanguages) {
        this.props.currentCourse.totalLanguages.map(langCourse => {
          this.props.languages.map(lang => {
            if (lang.code === langCourse.value) {
              let langToPrint = { ...langCourse, ...lang };
              languagesOptions.push(langToPrint);
            }
            if (languageToPrint && lang.code === languageToPrint.value) {
              language = { ...languageToPrint, ...lang };
            }
          });
        });
      }

      //if the language is not set and there is just one language option => open the child course with that language
      if (!language && languagesOptions && languagesOptions.length === 1) {
        this.setLanguage(languagesOptions[0]);
      }

      languagesOptions = orderBy(languagesOptions, ['label']);

      this.setState({
        languagesOptions,
        language,
      });
    }
  };

  setLiveLang = () => {
    let liveLang = '';
    this.props.languages.map(lang => {
      if (lang.code === this.props.currentCourse.language) {
        liveLang = lang.description;
      }
    });
    this.setState({
      liveLang,
    });
  };

  isNotSameLanguage = () => {
    if (this.state.languagesOptions.length > 1 || this.state.language === null) return true;

    return this.state.languagesOptions[0].code === this.state.language.code ? false : true;
  };

  setSubtitlesOptions = (refreshSelectedSubtitle = false) => {
    if (isVideoOrPodcast(this.props.currentCourse) || isMultiActivity(this.props.currentCourse)) {
      let subtitlesOptions = [];
      let subtitlesCodes = getSubtitlesCodes(this.props.currentCourse);
      let selectedSubtitle = this.state.selectedSubtitle;

      subtitlesCodes.map(subCourse => {
        this.props.languages.map(lang => {
          if (lang.code === subCourse) {
            let langToPrint = { code: subCourse, ...lang };
            subtitlesOptions.push(langToPrint);

            if (subCourse === this.props.currentCourse.language) {
              selectedSubtitle = langToPrint;
            }
          }
        });
      });

      subtitlesOptions = orderBy(subtitlesOptions, ['label']);

      this.setState({
        subtitlesOptions,
        selectedSubtitle: refreshSelectedSubtitle ? null : selectedSubtitle,
      });
    } else {
      this.setState({
        subtitlesOptions: [],
        selectedSubtitle: null,
      });
    }
  };

  setSubtitle = subtitle => {
    let newSelectedSubtitle = subtitle;
    if (this.state.selectedSubtitle?.code === subtitle.code) {
      newSelectedSubtitle = null;
    }

    this.setState({
      selectedSubtitle: newSelectedSubtitle,
    });
  };

  stop = e => e.stopPropagation();

  setLanguage = language => {
    this.setState({
      language,
    });

    if(this.props.currentCourse.childCourses.length > 0) {
      this.props.currentCourse.childCourses.forEach( childCourse => {
        if(childCourse?.childId === language?.courseId && childCourse?.courseSummary === undefined) {
          this.props.getCourseSummary([this.props.currentCourse], this.props.coursesMap, childCourse)
        }
      })
    }

    this.props.getChildCourse(this.props.currentCourse, null, language.code, this.props.coursesMap);
    this.props.setChildCourse(language);
  };
  checkClose = e => {
    this.props.toggleModal(false);
  };

  showAllSummary = () =>
    this.setState(prevState => {
      return { isShowSummary: !prevState.isShowSummary };
    });

  toggleEvent = stateName =>
    this.setState(prevState => {
      return { [stateName]: !prevState[stateName] };
    });

  isSingleActivity = () => {
    let { courseDetails } = this.props.currentCourse;

    if (
      courseDetails.length === 1 &&
      courseDetails[0].content.length === 1 &&
      courseDetails[0].content[0].type !== 'facetoface'
    )
      return true;
    else if (courseDetails.length === 2) {
      let isCustomCert = false,
        isUniqueActivity = false;

      courseDetails.map(activity => {
        if (activity.sectionName === 'completed') isCustomCert = true;
        else if (
          activity.sectionName !== 'completed' &&
          activity.content.length === 1 &&
          activity.content[0].type !== 'facetoface'
        )
          isUniqueActivity = true;
      });

      if (isCustomCert && isUniqueActivity) return true;
    }

    return false;
  };

  getChips = course => {
    let chips = [];

    //L2 LIST
    if (course?.level2) {
      course.level2.forEach(sectionId => {
        const index = chips.findIndex(chip => chip.key === sectionId);
        if (index < 0 && this.props.labelL2Map?.[sectionId]) {
          chips.push(this.props.labelL2Map[sectionId]);
        }
      });
    }

    //ORDER TAGS
    chips = orderBy(chips, ['order']);

    return chips;
  };

  handleClickImage = e => {
    const courseDetails = this.props.currentCourse.courseDetails;
    if (isVideoOrPodcast(this.props.currentCourse) && courseDetails && courseDetails.length > 0) {
      e.stopPropagation();
      this.props.openVideo(courseDetails, this.props.currentCourse);
    }
  };

  

  toggleSectionChip = sectionId => {
    //go to PLP filtered by section
    const isSubcatalogue = this.props.history.location.pathname === USER_URLS.SUBCATALOGUE.URL;
    this.props.history.push(
      USER_URLS.SUBCATALOGUE.URL + '?' + queryParams.FILTER_SECTION + '=' + encodeURI(sectionId)
    );
    if (isSubcatalogue) {
      this.props.history.go();
    }
  };

  handleExpiringClick = course => {
    if (isPriceable(course)) {
      this.props.setShowModalContentExpiration(true);
    }
  };




  render() {
    const { lang, currentCourse, showCourseModal, coursesMap } = this.props;
    const {
      language,
      languagesOptions,
      selectedSubtitle,
      comingSoonLangOptions,
      subtitlesOptions,
      lpCourses,
      liveLang,
    } = this.state;
    let displayTopProps = {};

    if (this.props.currentCourse) {
      // return null;
      const {
        courseSummary,
        courseFullName,
        courseDetails,
        ctype,
        duration,
        credits,
        isStatic,
        totalLearningPathDuration,
      } = this.props.currentCourse;
      const totalLPDuration =
        totalLearningPathDuration !== 0 ? totalLearningPathDuration : duration;
      const showLaunchButton = isStatic || isLearningPath(currentCourse) || courseDetails; // && this.isSingleActivity());

      let languagesChips = null;
      if (languagesOptions.length > 0 && this.isNotSameLanguage()) {
        languagesChips = languagesOptions;
      }
      let comingSoonLanguagesChips = null;
      if (comingSoonLangOptions.length > 0) {
        console.log('CSLANG', comingSoonLangOptions);
        comingSoonLanguagesChips = comingSoonLangOptions;
        this.props.currentCourse.comingSoonLanguages = comingSoonLanguagesChips;
      }

      let subtitlesChips = null;
      if (subtitlesOptions.length > 0) {
        subtitlesChips = subtitlesOptions;
      }

      const translate = isStatic ? this.props.t : null;
      const isPastEvent = checkUpcomingOrPastEvent(currentCourse, this.props.userProfile)?.[0]
        ?.isPastEvent;

      displayTopProps = {
        course: { ...currentCourse, isPastEvent },
        compatibility: getCourseCompatibility(currentCourse),
        courseFullName: courseFullName,
        duration: printDurationHourFormat(totalLPDuration, lang, translate, false),
        credits: credits,
        chips: this.getChips(currentCourse),
        summary: printHTML(courseSummary),
        publicRating: currentCourse.rating,
        mentorRating: 0, //out of scope for now
        showLaunchButton: showLaunchButton,
        languages: languagesChips,
        comingSoonLanguages: comingSoonLanguagesChips,
        selectedLanguage: language,
        setLanguage: this.setLanguage,
        subtitles: subtitlesChips,
        selectedSubtitle: selectedSubtitle,
        setSubtitle: this.setSubtitle,
        toggleSectionChip: this.toggleSectionChip,
        complexityName: getLevelLabel(currentCourse.complexityId, this.props.labelComplexityMap),
        toggleModalCourseById: this.props.toggleModalCourseById,
        isCurrentLpBlocked: isLpBlocked(currentCourse, coursesMap),
        blockingLps: getBlockingLps(currentCourse, coursesMap),
        isLiveStream: isLivestreamCourse(this.props.currentCourse),
        handleExpiringClick: this.handleExpiringClick,
        plan: this.props.plan,
        ecpu: isPoweredByEcpu(this.props.currentCourse),
        abo: isRecognizedByABO(this.props.currentCourse),
        isLoadingCourse: this.props.isLoadingCourse,
        isMultiActivity: isMultiActivity(currentCourse),
        coursesMap: this.props.coursesMap,
        levels: {
          l0: this.props.currentCourse.level0,
          l1: this.props.currentCourse.catalogTypes?.[0],
          l2: this.props.currentCourse.level2?.[0],
        },
        liveLang: liveLang,
      };
    }

    return (
      <AnimatePresence>
        <motion.div
          key={showCourseModal && currentCourse ? 'open' : 'close'}
          id="course_overview_wrapper"
        >
          {showCourseModal && currentCourse && (
            <>
              <HelmetMetaTags
                description={
                  showCourseModal ? currentCourse?.courseSummary?.replace(/<[^>]*>/g, '') : ''
                }
              />
              <Modal
                show={true}
                close={this.checkClose}
                animateSlide={true}
                hideX={true}
                className="modal__course-overview fillAvailable"
                focusTrapOptions={{
                  initialFocus: false,
                }}
              >
                <div
                  className="course-overview"
                  key={showCourseModal && currentCourse ? currentCourse.courseIdMaster : null}
                >
                  <Header level={HEADER_LEVEL.THREE} forceNotTransparent={true} />
                  <div className="course-overview__content">
                    <AnimationSlide {...animationSlideToTop}>
                      <DisplayTopMobile {...displayTopProps} />
                      <DisplayTopDesk {...displayTopProps} />
                    </AnimationSlide>
                    {((this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster] && this.props.isLoadingGetUserRelevantSessionById &&
                      !this.props.currentCourse.courseDetails &&
                      !isLearningPath(currentCourse)) ||
                      (this.props.currentCourse.courseDetails &&
                        !this.isSingleActivity() &&
                        !isLearningPath(currentCourse)) ||
                      (!!displayTopProps.isLiveStream && !isLearningPath(currentCourse))) && (
                        <AnimationSlide {...animationSlideToTop}>
                          <DetailCourseActivities
                            lang={lang}
                            course={currentCourse}
                            isLoadingDetails={
                              this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster]  && this.props.isLoadingGetUserRelevantSessionById
                            }
                            subtitle={selectedSubtitle}
                            openActivity={this.props.openActivity}
                          />
                        </AnimationSlide>
                      )}
                    {isLearningPath(currentCourse) && lpCourses?.length > 0 && (
                      <section className="course-overview__lp" aria-label={lang.LECTURES_IN_COURSE}>
                        <p className="course-overview__lp__courses-num">
                          {/* LEON-1057 {lang.LECTURES_IN_COURSE?.replace('{num}', lpCourses.length)} */}
                        </p>
                        <AnimationSlide {...animationSlideToTop}>
                          <DetailCourseActivities
                            lang={lang}
                            course={currentCourse}
                            isLoadingDetails={
                              this.props.isLoadingDetails[this.props.currentCourse.courseIdMaster] && this.props.isLoadingGetUserRelevantSessionById
                            }
                            subtitle={selectedSubtitle}
                            openActivity={this.props.openActivity}
                            coursesMap={this.props.coursesMap}
                          />
                        </AnimationSlide>
                      </section>
                    )}

                    {isLearningPath(currentCourse) && lpCourses.length === 0 && (
                      <div className="top-desk__multiactivity-skeleton">
                        {[...Array(3)].map((e, i) => (
                          <CardActivitySkeleton key={i} />
                        ))}
                      </div>
                    )}
                  </div>
                  {this.props.isAuthenticated && (
                    <RecommendedSection course={currentCourse} coursesMap={this.props.coursesMap} />
                  )}
                  <Footer></Footer>
                </div>
              </Modal>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
}

const mapStateToProps = state => {
  return {
    coursesMap: state.course.coursesMap,
    isLoadingWishlist: state.course.isLoadingWishlist,
    currentCourse: state.course.currentCourse,
    userProfile: state.user.userProfile,
    isUserCompleted: state.user.isUserCompleted,
    isUserCompletedAnalytics: state?.user?.isUserCompletedAnalytics,
    lang: state.utils.lang,
    isLoadingDetails: state.course.isLoadingDetails,
    languages: state.utils.languages,
    showCourseModal: state.course.showCourseModal,
    isAuthenticated: state.authentication.isAuthenticated,
    scrollToCurrentCourseSessions: state.course.scrollToCurrentCourseSessions,
    plan: state.ecommerce.plan,
    isLoadingCourse: state.course.isLoadingCourse,
    labelL1Map: state.utils.labelL1Map,
    labelL2Map: state.utils.labelL2Map,
    labelComplexityMap: state.utils.labelComplexityMap,
    isLoadingGetUserRelevantSessionById : state.utils.isLoadingGetUserRelevantSessionsById,
    analyticsCourseId: state.utils.analyticsCourseId,
    typeModal: state.utils.typeModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCourseDetails: (courseId, parentId) =>
      dispatch(actions.getCourseDetails(courseId, parentId)),
      getUserRelevantSessionsCourse : (courseId) => dispatch(actions.getUserRelevantSessionsById(courseId)),
    getCourseCompletion: () => dispatch(actions.getCourseCompletion()),
    getCourseCompletionById: courseId => dispatch(actions.getCourseCompletionById(courseId)),
    setChildCourse: lang => dispatch(actions.setChildCourse(lang)),
    openSubCourseInLP: course => dispatch(actions.openSubCourseInLP(course)),
    toggleModal: (value, course = null) => dispatch(actions.toggleModal(value, course)),
    openVideo: (courseDetails, course) => dispatch(actions.openVideo(courseDetails, course)),
    openConfirmBookingModal: (courseId, sessionId, isSessionSignUp) =>
      dispatch(actions.openConfirmBookingModal(courseId, sessionId, isSessionSignUp)),
    openActivity: (activity, course, subtitle) =>
      dispatch(actions.openActivity(activity, course, subtitle)),
    resetScrollToCurrentCourseSessions: () =>
      dispatch(actions.resetScrollToCurrentCourseSessions()),
    toggleModalCourseById: (...args) => dispatch(actions.toggleModalCourseById(...args)),
    setShowModalContentExpiration: show => dispatch(actions.setShowModalContentExpiration(show)),
    getChildCourse: (course, childId, language, coursesMap) =>
      dispatch(actions.getChildCourse(course, childId, language, coursesMap)),
    getCourseSummary: (courses, coursesMap, childCourse) =>
      dispatch(actions.getCourseSummary(courses, coursesMap, childCourse)),
    setAnalyticsCourseId: (courseId) => dispatch(actions.setAnalyticsCourseId(courseId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseOverview));
