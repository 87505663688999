import React from 'react';
import LazySVG from '@components/LazySvg';
import clsx from '@utility/clsx';

class Props {
  className?: string = '';
  onClick: () => void;
  ariaLabel: string;
  squareButtonMobile?: boolean;
  dataElementId?: string;
}

export const CloseIconModalRight = ({
  className = '',
  onClick = () => {},
  ariaLabel = '',
  squareButtonMobile = false,
  dataElementId,
}: Props): React.ReactElement => {
  return (
    <button
      className={clsx('button-no-back modal-right__close close-icon-modal-right', {
        [className]: className,
        'square-button-mobile': squareButtonMobile,
      })}
      data-element-id={dataElementId}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <LazySVG src="/images/svg-icons/x-icon2.svg" alt={ariaLabel} />
    </button>
  );
};

export default CloseIconModalRight;
