export class FilterMap {
    brand: any = {};
    ctype: any = {};
    status: any = {};
    assignment: any = {};
    language: any = {};
    section: any = {};
    duration: any = {};
    teacher: any = {};
    location: any = {};
    schedule: any = {};
    others: any = {};
    price: any = {};
    offerPlan: any = {};
    expiration: any = {};
    el360: any = {};
}


class FilterValueBoolean {
    [filterValue: string]: boolean
}
export class FilterEnableMap {
    brand: FilterValueBoolean = {};
    complexity: FilterValueBoolean = {};
    status: FilterValueBoolean = {};
    assignment: FilterValueBoolean = {};
    language: FilterValueBoolean = {};
    section: FilterValueBoolean = {};
    duration: FilterValueBoolean = {};
    teacher: FilterValueBoolean = {};
    location: FilterValueBoolean = {};
    schedule: FilterValueBoolean = {};
    others: FilterValueBoolean = {};
    price: FilterValueBoolean = {};
    offerPlan: FilterValueBoolean = {};
    expiration: FilterValueBoolean = {};
    dateFixedRange: FilterValueBoolean = {};
    el360: FilterValueBoolean = {};
}

export class CourseToFilterMap {
    [courseId: string]: FilterEnableMap
}

class FilterValueCourseId {
    [filterValue: string]: { [courseId: string]: boolean }
}
export class FilterToCourseMap {
    brand: FilterValueCourseId = {};
    complexity: FilterValueCourseId = {};
    status: FilterValueCourseId = {};
    assignment: FilterValueCourseId = {};
    language: FilterValueCourseId = {};
    section: FilterValueCourseId = {};
    duration: FilterValueCourseId = {};
    teacher: FilterValueCourseId = {};
    location: FilterValueCourseId = {};
    schedule: FilterValueCourseId = {};
    others: FilterValueCourseId = {};
    price: FilterValueCourseId = {};
    offerPlan: FilterValueCourseId = {};
    expiration: FilterValueCourseId = {}; 
    el360: FilterValueCourseId = {};
}

