import { useState, useEffect } from "react";
import { useSelector } from "@hooks/redux";
import { isEducationalPath } from "@utility/EducationalPathsUtility";
import { isCompletedCourse, isCourseVisible } from "@utility/Api";


const useShowEducationalPathsMenuHeader = () => {
  const coursesMap = useSelector((state) => state.course.coursesMap);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  useEffect(() => {
    let showMenuTemp = false;
    let showDropdownTemp = false;

    if (coursesMap) {
      Object.values(coursesMap).forEach(course => {
        if (isCourseVisible(course) && isEducationalPath(course)) {
          showMenuTemp = true;

          if (!isCompletedCourse(course)) {
            showDropdownTemp = true;
          }
        }
      });
    }

    setShowMenu(showMenuTemp);
    setShowDropdown(showDropdownTemp);
  }, [coursesMap])

  return [showMenu, showDropdown];
};



export default useShowEducationalPathsMenuHeader;
