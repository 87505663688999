import React, { useState } from 'react';
import CardCover from '@components/CardV3/CardCover';
import {
  printDate,
  isCourseToBeRated,
  isCourse0,
  isLearningPath,
  handleOnEnterKeyPress,
  isLpEnrolled,
  isCompletedCourse,
  canShowRating,
} from '../utility/Api';
import ButtonV2 from '@components/UI/ButtonV2';
import { Course } from '../model/CoursesClass';
import { useDispatch } from 'react-redux';
import RadioButtonGroup from './UI/RadioButtonGroup';
import { defaultRatingItems } from '@components/UI/RadioButtonGroup';
import { GRADIENT_VARIANT_ENUM } from '@model/GradientClass';
import { getLevelLabel } from '@utility/levelUtility';
import { useSelector } from '@hooks/redux';
import { isCourseOnBoarding } from '@utility/onBoardingCourseUtility';
import LazySVG from './LazySvg';
import { enrollLp, toggleDownloadLinkModal, toggleModal, toggleModalCourseById, toggleRatingModal } from '@redux-actions/';
import ModalShare from './UI/ModalShare';
import ShareContent from './CourseOverview/ShareContent';
import { USER_URLS } from './link-utils';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';
import { courseStatesConst } from '@utility/const';

type Props = {
  course: Course;
  isGetCertificate: boolean;
  position: number;
};

const CompletedCourseCard = ({ course, isGetCertificate,position}: Props) => {
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const lang = useSelector(state => state.utils.lang);
  const isLpEnrolling = useSelector(state => state.course.isLpEnrolling);
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const cardInMyCertificatesPage = window.location?.pathname?.includes(USER_URLS.PROFILE_MY_CERTIFICATES.URL);

  const openLink = (e, link, courseId) => {
    e.stopPropagation();
    dispatch(toggleDownloadLinkModal(true, link, courseId))
  };

  const handleClickCard = () => {
    if(isGetCertificate){
      buildClickDataTrackingObject("plp_N"+position++)
    }else{
      buildClickDataTrackingObject("plp_N"+position++, camelCase(course.courseStates.includes(courseStatesConst.IN_PROGRESS)? "inProgress" : "done"))
    }
    if (!isCourse0(course)) {
      dispatch(toggleModal(true, course, false, false, false, cardInMyCertificatesPage));
    }
  };
  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const shouldShowUnrollCta =
    isLpEnrolled(course) &&
    !isCompletedCourse(course) &&
    !isCourseOnBoarding(course) &&
    !isLpEnrolling;

  const handleUnroll = () => {
    dispatch(enrollLp(false, course.courseIdMaster));
  };

  return (
    <div
      className={'completed-course ' + (isCourse0(course) ? 'completed-course__course0' : '')}
      data-id={course.courseIdMaster}
    >
      <div className="completed-course__content">
        <div
          className={course?.isInValidityPeriod? "completed-course__cover__not-hover" : "completed-course__cover"}
          onClick={course?.isInValidityPeriod ? () => {} : handleClickCard}
          tabIndex={isCourse0(course) ? null : 0}
          onKeyDown={handleKeyDown}
          aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', course.courseFullName)}
        >
          <CardCover
            image={course.courseOverviewFile}
            courseFullName={course.courseFullName}
            gradients={[GRADIENT_VARIANT_ENUM.defaultRadial]}
            variant="card-evolution-desk"
          />
        </div>
        <section
          className={`completed-course__info ${isGetCertificate ? 'justify-between' : ''}`}
          aria-label={course.courseFullName}
        >
          <div className="completed-course__ctype-row">
            <span className="completed-course__ctype">
              {getLevelLabel(course.complexityId, labelComplexityMap)}
            </span>

            <div className="completed-course__divider" />
            <span className="completed-course__credits">
              {course.userCredits || '0'}{' '}
              {course.userCredits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL}
            </span>
          </div>
          <div className={course?.isInValidityPeriod? "completed-course__title-not-hover" : "completed-course__title"}
          onClick={course?.isInValidityPeriod ? () => {} : handleClickCard}>
            {course.courseFullName}
          </div>
          <div>
            {!isGetCertificate && (
              <div className="completed-course__type">
                {getLevelLabel(course.complexityId, labelComplexityMap)},{' '}
                <span>
                  {course.userCredits}{' '}
                  {course.userCredits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL}
                </span>
              </div>
            )}
            {/* {!isGetCertificate && course?.parentLP?.length > 0 && (
              <div className="completed-course__part-of">
                <span>{lang.PART_OF_LABEL + ':'}</span>
                {course.parentLP
                  .sort((a, b) => a.courseFullName.localeCompare(b.courseFullName))
                  .map(childCourse => (
                    <ButtonV2
                      variant="text-btn-no-arrow"
                      small
                      handleClick={() => dispatch(toggleModalCourseById(childCourse.courseId))}
                    >
                      {childCourse.courseFullName}
                    </ButtonV2>
                  ))}
              </div>
            )} */}
            <div
              className={`${
                !isGetCertificate && !isCourseToBeRated(course)
                  ? 'completed-course__rating-section'
                  : ''
              }`}
            >
              {course.courseCompletionDate && (
                <div className="completed-course__date">
                  {lang.COMPLETED_ON.replace(
                    '{DATE}',
                    printDate(course.courseCompletionDate, lang)
                  )}
                </div>
              )}
              {!isGetCertificate && (
                <div
                  className={`completed-course__rating-section ${
                    shouldShowUnrollCta ? 'with-unenroll' : ''
                  }`}
                >
                  {shouldShowUnrollCta && (
                    <ButtonV2
                      variant="text-btn-no-arrow"
                      small
                      type="button"
                      className="completed-course__unenroll-btn-overPhone"
                      handleClick={handleUnroll}
                    >
                      {lang?.DISENROLL_LABEL}
                    </ButtonV2>
                  )}
                  {isCourseToBeRated(course) ? (
                    <span>
                      <ButtonV2
                        variant="secondary"
                        small
                        className="completed-course__rate-btn-overPhone"
                        handleClick={() => dispatch(toggleRatingModal(true, course))}
                      >
                        {isLearningPath(course) ? lang.RATE_COURSE : lang.RATE_LESSON}
                      </ButtonV2>
                    </span>
                  ) : canShowRating(course) ? (
                    <span>
                      <RadioButtonGroup
                        items={defaultRatingItems}
                        checked={course.userRating}
                        handleOnClick={() => {}}
                        isRating={true}
                        fontSize="14px"
                        hideLabel
                        readOnly
                      />
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {isGetCertificate && (
            <div className="completed-course__buttons">
              <ButtonV2
                className="completed-course__certificate"
                variant="text-btn-no-arrow"
                ariaLabel={lang.DOWNLOAD + ' ' + course.courseFullName}
                handleClick={e => {buildClickDataTrackingObject("download_pdf",camelCase(course?.courseId));openLink(e, course.certificateLinks[0], course?.courseId)}}
                data-element-id={'main_certificates_download'}
              >
                <LazySVG
                  className="area-of-expertise__l1-channel-arrow"
                  src={'/images/svg-icons/download-certificate.svg'}
                  alt={lang.DOWNLOAD}
                />
                {lang.DOWNLOAD}
              </ButtonV2>
              <ButtonV2
                className="completed-course__certificate "
                variant="text-btn-no-arrow"
                ariaLabel={lang.CTA_SHARE + ' ' + course.courseFullName}
                handleClick={() => {buildClickDataTrackingObject("shareLinkedin",camelCase(course.courseId));setShowShareModal(true)}}
                data-element-id={'main_certificates_share1_linkedIn'}
              >
                <LazySVG
                  className="area-of-expertise__l1-channel-arrow"
                  src={'/images/svg-icons/plus2.svg'}
                  alt={lang.CTA_SHARE}
                />
                {lang.CTA_SHARE}
              </ButtonV2>
            </div>
          )}
        </section>
      </div>

      {!isGetCertificate && (
        <div
          className={`completed-course__footer-phone ${shouldShowUnrollCta ? 'with-unenroll' : ''}`}
        >
          {shouldShowUnrollCta && (
            <ButtonV2
              variant="text-btn-no-arrow"
              small
              type="button"
              className="completed-course__unenroll-btn-phone"
              handleClick={handleUnroll}
            >
              {lang?.DISENROLL_LABEL}
            </ButtonV2>
          )}
          {isCourseToBeRated(course) && (
            <ButtonV2
              variant="secondary"
              small
              className="completed-course__rate-btn-phone"
              handleClick={() => dispatch(toggleRatingModal(true, course))}
              ariaLabel={
                isLearningPath(course)
                  ? lang.RATE_COURSE
                  : lang.RATE_LESSON + ' ' + course.courseFullName
              }
            >
              {isLearningPath(course) ? lang.RATE_COURSE : lang.RATE_LESSON}
            </ButtonV2>
          )}
        </div>
      )}
      {showShareModal && (
        <ModalShare show={true} close={() => setShowShareModal(false)}>
          <ShareContent course={course} />
        </ModalShare>
      )}
    </div>
  );
};

export default CompletedCourseCard;
