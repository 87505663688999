import React, { useEffect, useState } from 'react';
import Close from '../../images/svg-icons/x-icon.svg';
import ArrowDown from '@svg-icons/arrowhead-down.svg';

interface ChipProps {
  id?: string;
  label: string;
  checked: boolean;
  image?: string;
  toggle: () => void;
  scrollToItem?: () => void;
  scrollItem?: boolean;
  variant?: 'transparent' | 'normal';
  showClose?: boolean;
  className?: string;
  disabled?: boolean;
  title?: string;
  showArrow?: boolean;
  showDropdown?: boolean;
  invert?: boolean; // Add invert prop
  dataElementId?: string;
  dataDescription?: string;
  isOtherCampaignsChip?: boolean;
  // isBrandCampaignMobileChip?: boolean;
}

export const Chip = (props: ChipProps): React.ReactElement => {
  const [arrowRotation, setArrowRotation] = useState(0);

  useEffect(() => {
    if (props.showArrow) {
      setArrowRotation(props.showDropdown ? 180 : 0);
    }
  }, [props.showDropdown]);

  const getDataElementId = () => {
    if(props?.dataElementId && !props?.isOtherCampaignsChip) return props?.dataElementId
    if(!props?.dataElementId && !props?.isOtherCampaignsChip) {
      return `SearchPanel_SearchIn_${props.label}`
    } 
    return undefined
  }

  return (
    <>
      {!props.isOtherCampaignsChip &&
        <button
          id={props.id}
          className={
            'chip ' +
            props.className +
            ' ' +
            (props.checked ? 'checked ' : '') +
            (props.disabled ? 'disabled ' : '') +
            props.variant
          }
          data-element-id={getDataElementId()}
          data-description={props?.dataDescription ?? undefined}
          onClick={props.disabled ? () => { } : props.toggle}
          title={props.title}
        >
          {typeof props.image === 'string' && (
            <span className="chip__icon">
              <img src={props.image} alt={props.label} />
            </span>
          )}
          <span className="chip__label" aria-label={props.label}>
            {props.label}
          </span>
          {props.showArrow && (
            <span className={"chip__arrow"} style={{ transform: `rotate(${arrowRotation}deg)`, filter: props.invert ? 'invert(100%)' : 'none' }}>
              <ArrowDown />
            </span>
          )}
          {props.showClose && props.checked && (
            <span className="chip__close">
              <Close fill="currentColor" />
            </span>
          )}
        </button>
      }
      {/* USED DIV INSTEAD OF BUTTON IN ORDER TO PREVENT TRIGGER OF ANALYTICS CLICK EVENT */}
      {props.isOtherCampaignsChip &&
        <div
          id={props.id}
          className={
            'chip ' +
            props.className +
            ' ' +
            (props.checked ? 'checked ' : '') +
            (props.disabled ? 'disabled ' : '') +
            props.variant
          }
          // data-element-id={getDataElementId}
          // data-description={props?.dataDescription ?? undefined}
          onClick={props.disabled ? () => { } : props.toggle}
          title={props.title}
        >
          {typeof props.image === 'string' && (
            <span className="chip__icon">
              <img src={props.image} alt={props.label} />
            </span>
          )}
          <span className="chip__label" aria-label={props.label}>
            {props.label}
          </span>
          {props.showArrow && (
            <span className={"chip__arrow"} style={{ transform: `rotate(${arrowRotation}deg)`, filter: props.invert ? 'invert(100%)' : 'none' }}>
              <ArrowDown />
            </span>
          )}
          {props.showClose && props.checked && (
            <span className="chip__close">
              <Close fill="currentColor" />
            </span>
          )}
        </div>
      }
    </>
    
  );
};

Chip.defaultProps = {
  variant: '',
  id: null,
  checked: false,
  toggle: () => {},
  showClose: false,
  className: '',
  showArrow: false,
  arrowClassName: '',
  showDropdown: false,
  invert: false, // Default invert value
};

Chip.displayName = 'Chip';

export default Chip;
