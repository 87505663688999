import React from "react";
import LazyImage from "react-lazy-progressive-image";

interface Props {
  src: string;
  className?: string;
  alt: string;
  onError?: () => void;
}


const LazySVG = ({ src, className, alt, onError = () => { } }: Props) => {

  return (
    <LazyImage
      src={src}
      placeholder=""
      visibilitySensorProps={{
        partialVisibility: true,
        offset: { bottom: -100, right: -100 },
      }}
      onError={onError}
    >
      {(src, loading) => (
        loading
          ?
          <div className={'placeholder-lazyload ' + (className ? className : '')}></div>
          :
          <img
            className={className}
            src={src}
            alt={alt}
            draggable={false}
          />
      )}
    </LazyImage>
  );
};

export default LazySVG;

