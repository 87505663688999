import React from "react";
import { USER_URLS } from "@components/link-utils";
import { printHTML } from "@utility/Api";
import { LangMapEcomm } from "@model/CoursesClass";
import { useSelector } from "@hooks/redux";

const PlanExpiredMessage = () => {
  const lang: LangMapEcomm = useSelector((state) => state.utils.lang);

  return (
    <div className="course-in-plan">
      <p
        aria-label={lang?.PLAN_EXPIRED_COURSE_OVERVIEW_MESSAGE?.replace("{", "")?.replace("{", "")?.replace("}", "")?.replace("}", "")}
        role="region"
        className="course-in-plan-text"
        dangerouslySetInnerHTML={printHTML(
          lang?.PLAN_EXPIRED_COURSE_OVERVIEW_MESSAGE?.replace(
            "{",
            '<a title="' + lang?.UNLIMITED_ACCESS_PLAN + '" class="text-btn-no-arrow small" href="' +
            USER_URLS.PLAN.URL +
            '" >'
          )
            ?.replace("}", "</a>")
        )}
      />

    </div>
  );
};

export default PlanExpiredMessage;
