import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/Api';
import { TrainingPillResponse } from '@model/ProductClass';
import { ChannelInfoObject } from '@model/SearchResult';
import { BrowseByTopicInfoWrapper } from '@model/BrowseByTopicInfo';

class InitialStateProps {
  isLoadingSuggestedTerms: boolean = false;
  isLoadedSuggestedTerms: boolean = false;
  searchQuery: string = '';
  isSearchSpell: boolean = false;
  suggestedPhrase: string = '';
  suggestedPhraseTP: string = '';
  suggestedPhraseNovelties: string = '';
  suggestedTerms: string[] = null;
  isLoadingSearchCourses: boolean = false;
  isLoadedSearchCourses: boolean = false;
  isLoadingSearchTP: boolean = false;
  isLoadedSearchTP: boolean = false;
  isLoadingSearchNovelties: boolean = false;
  isLoadedSearchNovelties: boolean = false;
  searchValRequest: string = undefined;
  searchCourseIds: string[] = null;
  searchL1s: string[] = null;
  channelsInfo: ChannelInfoObject[] = null;
  sectionL1: string[] = null;
  searchTrainingPills: TrainingPillResponse = null;
  searchNovelties: TrainingPillResponse = null;
  previouslySearched: string[] = [];
  browseByTopicInfo: BrowseByTopicInfoWrapper = new BrowseByTopicInfoWrapper();
}

const initialState: InitialStateProps = new InitialStateProps();

const getSuggestedTermsRequest = state => {
  return updateObject(state, {
    isLoadingSuggestedTerms: true,
  });
};

const getSuggestedTermsSuccess = (state, action) => {
  return updateObject(state, {
    suggestedTerms: action.suggestedTerms,
    isLoadingSuggestedTerms: false,
    isLoadedSuggestedTerms: true,
  });
};

const getSuggestedTermsFailure = state => {
  return updateObject(state, {
    isLoadingSuggestedTerms: false,
    isLoadedSuggestedTerms: false,
  });
};

const getSearchCourseRequest = (state, action) => {
  return updateObject(state, {
    isLoadingSearchCourses: true,
    isLoadingSearchTP: true,
    isLoadingSearchNovelties: true,
    isLoadedSearchCourses: false,
    isLoadedSearchTP: false,
    searchQuery: action.searchQuery,
    isSearchSpell: action.isSearchSpell,
  });
};

const getSearchCourseSuccess = (state, action) => {
  //handle training pills
  let searchTrainingPills: TrainingPillResponse = state.searchTrainingPills;
  const trainingPillsCatalogueFromNumber: number = action.trainingPillsCatalogueFromNumber;
  if (trainingPillsCatalogueFromNumber > 0) {
    searchTrainingPills.items = searchTrainingPills.items.concat(action.searchTrainingPills.items);
  } else {
    searchTrainingPills = action.searchTrainingPills;
  }

  //handle novelties
  let searchNovelties: TrainingPillResponse = state.searchNovelties;
  const noveltiesCatalogueFromNumber: number = action.noveltiesCatalogueFromNumber;
  if (noveltiesCatalogueFromNumber > 0) {
    searchNovelties.items = searchNovelties.items.concat(action.searchNovelties.items);
  } else {
    searchNovelties = action.searchNovelties;
  }

  return updateObject(state, {
    searchCourseIds: action.searchCourseIds,
    searchL1s: action.searchL1s,
    channelsInfo: action.channelsInfo,
    sectionL1: action.sectionL1,
    searchTrainingPills: searchTrainingPills,
    searchNovelties: searchNovelties,
    suggestedPhrase: action.suggestedPhrase,
    suggestedPhraseTP: action.suggestedPhraseTP,
    suggestedPhraseNovelties: action.suggestedPhraseNovelties,
    isLoadingSearchCourses: false,
    isLoadingSearchTP: false,
    isLoadingSearchNovelties: false,
    isLoadedSearchCourses: true,
    isLoadedSearchTP: searchTrainingPills.elementsNum > 0,
    isLoadedSearchNovelties: searchNovelties.elementsNum > 0,
    searchValRequest: action.searchValRequest,
  });
};

const getSearchCourseFailure = state => {
  return updateObject(state, {
    isLoadingSearchCourses: false,
    isLoadedSearchCourses: true,
    searchCourseIds: null,
    searchL1s: null,
    channelsInfo: null,
    sectionL1: null,
    searchTrainingPills: null,
    searchNovelties: null,
    isLoadingSearchTP: false,
    isLoadingSearchNovelties: false,
  });
};

const resetSearchCourse = state => {
  return updateObject(state, {
    searchQuery: '',
    searchCourseIds: null,
    searchL1s: null,
    channelsInfo: null,
    sectionL1: null,
    searchTrainingPills: null,
    searchNovelties: null,
    isLoadedSearchCourses: false,
    isLoadingSearchTP: false,
    isLoadingSearchNovelties: false
  });
};

const addPreviouslySearched = (state, action) => {
  let newPreviouslySearched = [];
  let searchQuery = action.searchQuery;

  state.previouslySearched.forEach(prev => {
    if (prev.toLowerCase() !== searchQuery?.toLowerCase()) {
      newPreviouslySearched.push(prev);
    }
  });
  newPreviouslySearched.push(searchQuery);

  //get last 3 previously searched
  const firstIndex = newPreviouslySearched.length - 3 > 0 ? newPreviouslySearched.length - 3 : 0;
  newPreviouslySearched = newPreviouslySearched.slice(firstIndex, newPreviouslySearched.length);

  return updateObject(state, {
    previouslySearched: newPreviouslySearched,
  });
};

const getBrowseByTopicInfoRequest = state => {
  return updateObject(state, {
    browseByTopicInfo: {
      isLoading: true,
      info: state.browseByTopicInfo?.info || {},
    },
  });
};

const getBrowseByTopicInfoSuccess = (state, action) => {
  return updateObject(state, {
    browseByTopicInfo: {
      isLoading: false,
      info: {
        ...(state.browseByTopicInfo?.info || {}),
        ...action.info,
      },
    },
  });
};

const getBrowseByTopicInfoFailure = (state, action) => {
  return updateObject(state, {
    browseByTopicInfo: {
      isLoading: false,
      info: {
        ...(state.browseByTopicInfo?.info || {}),
        ...action.info,
      },
    },
  });
};

const logout = () => {
  return initialState;
};

const reducer = (state = initialState, action): InitialStateProps => {
  switch (action.type) {
    case actionTypes.GET_SUGGESTED_TERMS_REQ:
      return getSuggestedTermsRequest(state);
    case actionTypes.GET_SUGGESTED_TERMS_SUCCESS:
      return getSuggestedTermsSuccess(state, action);
    case actionTypes.GET_SUGGESTED_TERMS_FAILURE:
      return getSuggestedTermsFailure(state);
    case actionTypes.GET_SEARCH_COURSES_REQ:
      return getSearchCourseRequest(state, action);
    case actionTypes.GET_SEARCH_COURSES_SUCCESS:
      return getSearchCourseSuccess(state, action);
    case actionTypes.GET_SEARCH_COURSES_FAILURE:
      return getSearchCourseFailure(state);
    case actionTypes.RESET_SEARCH_COURSES:
      return resetSearchCourse(state);
    case actionTypes.ADD_PREVIOUSLY_SEARCHED:
      return addPreviouslySearched(state, action);
    case actionTypes.GET_BROWSE_BY_TOPIC_REQ:
      return getBrowseByTopicInfoRequest(state);
    case actionTypes.GET_BROWSE_BY_TOPIC_SUCCESS:
      return getBrowseByTopicInfoSuccess(state, action);
    case actionTypes.GET_BROWSE_BY_TOPIC_FAILURE:
      return getBrowseByTopicInfoFailure(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
