import { Course, LangMap, LangMapEcomm } from "@model/CoursesClass";
import { GetSubscriptionResponse, PlanModel, PlanStatesModel, PlanStatusInfoModel, SubscriptionInfo, UserPlan, WCSDetail, boxInfoPlan } from "@model/PlanModel";
import { UserProfile } from "@model/User";
import moment from "moment";
import { RootState } from "src/store/store";
import { formatCurrency, isInThePast, printDate, printDateDDMMYYYY } from "./Api";
import { COOKIE } from "./const";
import { setCookie } from "./cookie";
import { isPurchased, promiseMock } from "./ecommerceUtility";

const mockPlanStates = {
  useMock: false,
  isPlanAutorenewalActive: false,
  isPlanExpired: false,
  isPlanFreeExpired: false,
  isPlanFullPriceExpired: false,
  isPlanPurchased: true,
  isPlanActive: true,
  isSubscriptionFree: false,
  isPlanFree: false,
  isPlanCloseToExpiration: false,
}



export const isPlanAutorenewalActive = (wcsDetail : WCSDetail, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanAutorenewalActive;
  }
  return wcsDetail?.state === "Active" || userPlan?.invited?.state === "Active";
}

export const isPlanExpired = (wcsDetail : WCSDetail, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanExpired;
  }
  const isExpired = isPlanFreeExpired(wcsDetail, userPlan) || isPlanFullPriceExpired(wcsDetail, userPlan);
  return isExpired;
}

export const getPriceFromSubscription = (wcsDetail : WCSDetail): number => {
  return wcsDetail?.totalCost?.value;
}

const isPlanEndDateInThePast = (userPlan: UserPlan) => {
  const fulfillmentEndDate = userPlan.timeEnded
  const expirationDate = ((fulfillmentEndDate || userPlan?.invited?.expirationDate) && !userPlan?.isInactive) ? fulfillmentEndDate || userPlan?.invited?.expirationDate : userPlan?.timeEnded;
  const isExpired = isInThePast(expirationDate);
  return isExpired;
}
export const isPlanFreeExpired = (wcsDetail : WCSDetail, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanFreeExpired;
  }
  // @todo - check if purchased (even if not activated)
  //const price = getPriceFromSubscription(wcsDetail);
  const isFree = userPlan?.isFree || userPlan?.isInactiveFree // || price === 0;
  const isExpired = isPlanEndDateInThePast(userPlan);
  return isExpired && isFree;
}
export const isPlanFullPriceExpired = (wcsDetail : WCSDetail, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanFullPriceExpired;
  }
  // @todo - check if free plan
  //const price = getPriceFromSubscription(wcsDetail);
  const isNotFree = !userPlan?.isFree || userPlan?.isInactiveFullPrice //|| price > 0;
  const isExpired = isPlanEndDateInThePast(userPlan);
  return isExpired && isNotFree;
}

/** PURCHASED BUT NOT ACTIVE */
export const isPlanPurchased = (userProfile: UserProfile): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanPurchased;
  }
  return isPlanActive(userProfile);
}

export const isPlanActive = (userProfile: UserProfile): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanActive;
  }
  return userProfile?.subscription;
}

export const isSubscriptionFree = (wcsDetail : WCSDetail, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isSubscriptionFree;
  }
  //const price = wcsDetail?.totalCost?.value;
  const isFree = (userPlan?.duration === "90" && !userPlan?.isInactive); //|| price === 0;
  return isFree;
}

export const isPlanFree = (userProfile: UserProfile, userPlan: UserPlan): boolean => {
  if (mockPlanStates.useMock) {
    return mockPlanStates.isPlanFree;
  }
  const isFree = !userProfile?.subscription && !userPlan?.isInactive;
  return isFree;
}

export const isPlanBoughtByUser = (userProfile: UserProfile, userPlan: UserPlan) => {
  // @todo is the person who activated the plan through the token the person who bought the plan
  if (!userPlan) {
    return false;
  }

  return userProfile?.username?.toLowerCase() === userPlan?.userBuyer?.toLowerCase() || (!userPlan?.isFree && !userProfile?.subscription);
}

export const isCloseToExpiration = (date: string, days: number) => {
  const expirationDate = moment(date);
  const now = moment();
  const diff = expirationDate.diff(now, "days");
  return diff < days;
}

// export const isPlanCloseToExpiration = (wcsDetail : WCSDetail, userPlan: UserPlan, isPlanAutorenewalActive: boolean): boolean => {
//   if (mockPlanStates.useMock) {
//     return mockPlanStates.isPlanCloseToExpiration;
//   }

//   if (isPlanAutorenewalActive) {
//     return false;
//   }

//   const expirationDate = subscription?.subscriptionInfo?.fulfillmentSchedule?.endInfo?.endDate || userPlan?.timeEnded
//   let days = 0;
//   if (subscription) {
//     const isFree = isSubscriptionFree(subscription, userPlan);
//     days = isFree ? 21 : 40;
//   } else {
//     const isFree = userPlan?.isFree && !userPlan?.active;
//     days = isFree ? 21 : 40;
//   }

//   const isDateClose = expirationDate ? isCloseToExpiration(expirationDate, days) : false;
//   return isDateClose;
// }

export const isIdPlan = (id: string): boolean => {
  return id?.startsWith('pl');
}

//get label for CTA to buy a plan (BuyLicenseSection.tsx)
export const getPlanCTALabel = (planStates: ReturnType<typeof selectPlanStates>, lang: LangMap): string => {
  //TODO - aggiornare condizioni per mostrare le label
  if (planStates.isPlanExpired) {
    return lang.BUY_AGAIN;
  }

  const isFree = planStates.isPlanFree;
  return isFree ? lang.TRY_FOR_FREE2 : lang.BUY_NOW;
}

export const getPlanInfos = (plan: PlanModel, userProfile: UserProfile, lang: LangMap & LangMapEcomm, orderSubscription: ReturnType<typeof selectSubscription>, isPaymentMethodPresent: boolean, planStates: ReturnType<typeof selectPlanStates>, userPlan: UserPlan): PlanStatusInfoModel => {
  if (!plan || !userProfile || !lang) {
    return null;
  }

  const isFree = planStates.isSubscriptionFree;
  const isPlanFree = planStates.isPlanFree;
  const isPlanBoughtByUser = planStates.isPlanBoughtByUser;

  const expirationDateFallBack = userPlan?.timeEnded;
  const expirationDateInvited = userPlan?.invited?.expirationDate;

  const price = (orderSubscription && Object.keys(orderSubscription).length > 0) ? getPriceFromSubscription(orderSubscription)?.toString() : plan.price;

  let expirationDate = null;
  if (orderSubscription && planStates.isPlanBoughtByUser) {
    //expiration date subscription plan not invited
    expirationDate = expirationDateFallBack;
  } else {
    //expiration date plan
    expirationDate = expirationDateFallBack;
  }

  if (!planStates.isPlanBoughtByUser) {
    //expiration date subscription plan user invited
    expirationDate = expirationDateInvited;
    if(userPlan?.active === true) {
      if(userPlan?.invited?.orderId === userPlan?.orderId) {
        expirationDate = userPlan?.timeEnded
      }
    }
  }
  if (!userPlan?.isInactive) {
    expirationDate = expirationDateFallBack;
  }

  const expirationDateFormattedDDMMYYYY = printDateDDMMYYYY(expirationDate); 
  const expirationDateFormattedDDMonYY = printDate(expirationDate, lang);

  const labelExpiration = planStates.isPlanExpired ?
    lang.PLAN_BADGE_EXPIRED_ON?.replace('{DATE}', " " + expirationDateFormattedDDMMYYYY)
    : isFree ? 
      lang.PLAN_BADGE_END_FREE_TRIAL?.replace('{DATE}', " " + expirationDateFormattedDDMonYY) 
      :  
      lang.PLAN_BADGE_VALID_UNTIL?.replace('{DATE}', " " + expirationDateFormattedDDMonYY);

  if (planStates.isPlanActive) {
    //plan is active

    return {
      showStartLearning: true,
      showManageLicenses: isPlanBoughtByUser ? true : false,
      showEndDate: expirationDateFallBack ? labelExpiration : "",
      showAutorenewalActivated: planStates.isPlanAutorenewalActive,
      showStopRenewalCTA: isPlanBoughtByUser ? planStates.isPlanAutorenewalActive : false,
      showActivateRenewalCTA: isPlanBoughtByUser ? !planStates.isPlanAutorenewalActive : false,
      boxInfoPlan: isPlanBoughtByUser ? {
        title: lang.YOU_HAVE_ACTIVATED_PREMIUM_ACCESS_PLAN,
        description: lang.START_LEARNING_OR_SEND_LICENSE
      } : null

    }

  } else {
    //plan is not active

    if (planStates.isPlanExpired) {
      //plan is not active and it is expired
      return {
        textBuyCTA: lang.BUY_AGAIN,
        badge: {
          color: "red-border",
          label: labelExpiration
        }
      }

    }

    if (planStates.isPlanPurchased) {
      /*plan has been purchased by the user but he/she did not activate it
      or user has an active plan that has been bought by someone else, 
      in this case since the active plan is handled above, 
      this is the case of the user that bought the plan and did not activate it only*/
      return {
        showManageLicenses: isPlanBoughtByUser,
        showEndDate: planStates.isPlanAutorenewalActive ? null : expirationDateFallBack ? labelExpiration : "",
        showActivatePlan: isPlanBoughtByUser,
        showAutorenewalActivated: planStates.isPlanAutorenewalActive,
        showStopRenewalCTA: isPlanBoughtByUser && planStates.isPlanAutorenewalActive,
        showActivateRenewalCTA: isPlanBoughtByUser && !planStates.isPlanAutorenewalActive,
        boxInfoPlan: isPlanBoughtByUser ? {
          title: lang.YOU_HAVE_PURCHASED_PREMIUM_ACCESS_PLAN,
          description: lang.ACTIVATE_PLAN_FOR_YOURSELF_OR_SEND_LICENSE
        } : null
      }

    } else {
      //plan is not active nor it is purchased --> user has free trial available
      return {
        textBuyCTA: isPlanFree ? lang.TRY_FOR_FREE : lang.BUY_NOW,
        textBelowBuyMoreLicenses: null,
        textBelowCta: isPlanFree && price ? lang?.RENEWS_AT_UNTIL_CANCELLED?.replace("{PRICE}", " " + formatCurrency(price, userProfile?.ecommerceInfo?.currency, userProfile.preferredLang)) : null, 
      }

    }

  }

}

export const mockGetPlan = async () => {
  const plan = await fetch(`/data/ecommerce/plan.json`);
  const planJson: PlanModel = await plan.json();
  return promiseMock({ data: planJson }, false, 3000);
}

export const mockGetSubscriptionList = async () => {

  const data = await fetch('/data/ecommerce/getSubscriptionList.json');
  const dataJson: GetSubscriptionResponse = await data.json();
  // dataJson.resultList?.[0].purchaseDetails.parentOrderItemIdentifier.parentOrderItemId;

  return promiseMock({ data: { ...dataJson } }, false, 1000);
}

export const selectSubscription = (state: RootState) => {
  return state.ecommerce.wcsDetail
}

export const selectUserPlan = (state: RootState) => {
  const userPlans = state.ecommerce.userPlans;

  const activatedPlan = userPlans?.activated;
  const boughtPlan = userPlans?.bought;
  const invited = userPlans?.invited;

  const planPurchasedBeforeExpiration = (new Date(boughtPlan?.timeStarted)?.getTime() < new Date(activatedPlan?.timeEnded)?.getTime()) || (Object.keys(activatedPlan).length === 0 || Object.keys(boughtPlan).length === 0 ) || activatedPlan?.active;

  const isInactive = isInThePast(boughtPlan?.timeEnded) || ( planPurchasedBeforeExpiration && activatedPlan?.hasOwnProperty("active") && activatedPlan?.active === false);
  const isFreeActivatedPlan = activatedPlan?.duration === "90"
  //const isFree = !boughtPlan && !activatedPlan && invited?.state !== "Active";
  const isFree =(boughtPlan && Object.keys(boughtPlan)?.length === 0 ) && (activatedPlan && Object.keys(activatedPlan)?.length === 0) && 
    ((invited && Object.keys(invited)?.length === 0) || invited?.state !== "Active");
    
  const mergedPlan = {
    ...boughtPlan,
    // if bought after activation then ignore activatedPlan
    ...(planPurchasedBeforeExpiration ? activatedPlan : {}),
    isFree,
    isInactive,
    isInactiveFree: isInactive && isFreeActivatedPlan,
    isInactiveFullPrice: isInactive && !isFreeActivatedPlan,
    invited,
  };

  return mergedPlan;
}

export const selectPlanTokens = (state: RootState) => {
  return state.ecommerce.planTokens
}

const selectPlanStates = (state: RootState) => {

  // const plan = state.ecommerce.plan;
  const userProfile = state.user.userProfile;
  const isSubscriptionListLoading = state.ecommerce.isSubscriptionListLoading;
  const subscription = selectSubscription(state);
  const userPlan = selectUserPlan(state);
  const hasPlanFree = state.ecommerce?.cart?.adjustment?.some((adjustment) => adjustment?.code?.includes('PROMO_SUBSCRIPTION_FREE'));

  const planPurchased = !userPlan?.isFree && !userPlan?.isInactive;
  const showCta = userPlan?.isFree || isInThePast(userPlan?.timeEnded) || userPlan?.isInactive || isPlanExpired(subscription, userPlan);
  const isPlanAutorenewalEnabled = isPlanAutorenewalActive(subscription, userPlan)

  const orderId = userPlan?.orderId || subscription?.parentOrderId;

  const planStates: PlanStatesModel = {
    isPlanAutorenewalActive: isPlanAutorenewalEnabled,
    isPlanExpired: isPlanExpired(subscription, userPlan),
    isPlanFreeExpired: isPlanFreeExpired(subscription, userPlan),
    isPlanFullPriceExpired: isPlanFullPriceExpired(subscription, userPlan),
    isPlanPurchased: planPurchased,
    isPlanActive: isPlanActive(userProfile),
    isSubscriptionFree: hasPlanFree || isSubscriptionFree(subscription, userPlan),
    isPlanFree: hasPlanFree || (!isSubscriptionListLoading && userPlan?.isFree),
    //isPlanCloseToExpiration: isPlanCloseToExpiration(subscription, userPlan, isPlanAutorenewalEnabled),
    isPlanBoughtByUser: isPlanBoughtByUser(userProfile, userPlan),
    showActivatePlan: planPurchased && !isPlanActive(userProfile),
    orderId,
    showCta,

    //mock data:
    // isPlanAutorenewalActive: true,
    // isPlanExpired: false,
    // isPlanFreeExpired: false,
    // isPlanFullPriceExpired: false,
    //isPlanPurchased: true, // user plan is not free and it is active
    // isPlanActive: false,
    //isSubscriptionFree: true, //user is on free trial
    // isPlanFree: true,  //user did not pay: promo code or plan is free (try for free)
    // isPlanCloseToExpiration: false,
    // isPlanBoughtByUser: true,
    //showActivatePlan: true,
    // orderId,
    // showCta: true,
  };

  //console.log('planStates', planStates)
  return planStates;

}

// @todo refactor this, it shouldn't be exported as default 
export default selectPlanStates;


export const setTempCookiePlanPurchased = (hasPlans: boolean) => {
  if (hasPlans) {
    const expirationDate = new Date();
    const fifteenMinutes = 15 * 60 * 1000;
    expirationDate.setTime(expirationDate.getTime() + fifteenMinutes);
    setCookie(COOKIE.PLAN_PURCHASED, true, expirationDate);
  }
}

export const isExcludedFromPlan = (course: Course) => {
  return !!course?.planExcluded ?? false;
}

