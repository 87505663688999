import { SKELETON_COLORS } from "@utility/const";
import React from "react"
import ContentLoader from "react-content-loader"

const width = 295;
const height = 170;

const PaymentInstructionsSkeleton = (props) => {
  return (
    <div className="payment-instructions__skeleton">
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect x="1" y="1" rx="6" ry="6" width="294" height="39" />
        <rect x="1" y="65" rx="6" ry="6" width="294" height="39" />
        <rect x="1" y="129" rx="6" ry="6" width="133" height="39" />
        <rect x="162" y="129" rx="6" ry="6" width="133" height="39" />
      </ContentLoader>
    </div>
  )
}

export default PaymentInstructionsSkeleton