import { handleOnEnterKeyPress, printHTML } from '@utility/Api';
import React, { useEffect, useState } from 'react';

type CheckboxProps = {
  text: string;
  isHtmlText?: boolean;
  className?: string;
  disabled?: boolean;
  checked: boolean;
  onChange?: () => void;
  isTextOnLeft?: boolean;
  name?: string;
  isError?: boolean;
  useInternalState?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLLabelElement>, 'onChange'>;

export const CheckboxExternalChecked = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      text,
      isHtmlText,
      className,
      isTextOnLeft = false,
      checked,
      onChange,
      disabled = false,
      isError,
      useInternalState,
      ...rest
    },
  ): React.ReactElement => {

    const [value, setValue] = useState(checked);

    useEffect(() => {
      if (useInternalState && checked !== value) {
        setValue(checked);
      }
    }, [checked]);

    //CREATO UN NUOVO COMPONENTE DA CHECKBOX PER IL FILTRO LIVESTREAM RECORDING AVAILABLE
    //L'ALTRA CHECKBOX ANDAVA FUORI SYNC RISPETTO AI FILTRI
    const handleClick = () => {
      if (!disabled) {
        onChange();
        setValue(a => !a);
      }
    };

    return (
      <label
        className={`check-box__container ${disabled ? `check-box--disabled` : ``} ${isTextOnLeft ? `check-box--text-left` : ``
          } ${className ? className : ''} ${isError ? 'check-box--error' : ''}`}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={event => {
          handleOnEnterKeyPress(event, handleClick);
        }}
        {...rest}
      >
        <span
          className={`check-box__checkmark ${(useInternalState ? value : checked) ? `check-box__checkmark--active` : ``} ${disabled ? `check-box__checkmark--disabled` : ``
            }`}
        ></span>
        <span
          className="check-box__text"
          dangerouslySetInnerHTML={isHtmlText ? printHTML(text) : null}
        >
          {isHtmlText ? null : text}
        </span>
      </label>
    );
  }
);

export default CheckboxExternalChecked;
