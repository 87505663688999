import React, { useEffect, useState } from "react";
import ButtonV2 from "@components/UI/ButtonV2";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { useHistory } from "react-router-dom";
import { Course, CoursesMap, LangMap } from "@model/CoursesClass";
import { goToCourseCatalog } from "@utility/Api";
import CardController from "@components/CardV3";
import { useSelector } from "@hooks/redux";

type ModalUpNextProps = {
  open: boolean;
  close: () => void;
  courses: Course[];
};
function ModalUpNext({ open, close, courses }: ModalUpNextProps) {
  const lang: LangMap = useSelector((state) => state.utils.lang);
  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);
  const history = useHistory();
  const dispatch = useDispatch();

  const [coursesToShow, setCoursesToShow] = useState<Course[]>([]);


  useEffect(() => {
    let coursesTemp = [];

    //update courses (in particular courseDetails)
    if (courses?.length > 0) {
      courses.forEach(course => {
        if (coursesMap?.[course.courseIdMaster]) {
          coursesTemp.push(coursesMap[course.courseIdMaster]);
        } else {
          coursesTemp.push(course);
        }
      })
    }

    setCoursesToShow(coursesTemp);
  }, [courses, coursesMap])

  return (
    <Modal
      className="modal-up-next"
      contentClassName="modal__content--small"
      // contentClassName="modal__content--centered modal__content--small"
      animateSlide
      blurIntensity="07"
      blur="always"
      close={close}
      show={open}
    >
      <header className="modal-up-next__header">
        <p>{lang.THANKS_FEEDBACK}</p>
      </header>
      <div className="modal-up-next__container">
        <div className="modal-up-next__message">
          <h3>{lang.WHATS_UP_NEXT_LABEL}</h3>
        </div>
        <div className="modal-up-next__course-wrapper">
          <div className="modal-up-next__course">
            {coursesToShow.map(course => (
              <CardController
                course={course}
                variant={"lesson-in-lp"}
                key={course.courseIdMaster}
              />
            ))
            }
          </div>
          <div className="modal-up-next__buttons">
            <ButtonV2 variant="primary" small className="browse-catalog" handleClick={() => {
              goToCourseCatalog(history);
              close();
              dispatch(actions.toggleModal(false, null, true));
            }}>
              {lang.BROWSE_OUR_CATALOGUE}
            </ButtonV2>
          </div>
        </div>


      </div>
    </Modal>
  );
}

export default ModalUpNext;
