import React, { useEffect, useState } from 'react';
import { AccordionListElement } from '@model/AccordionListElement';
import ChevronUp from '@svg-icons/chevron-up.svg';
import Close from '@svg-icons/close.svg';
import CloseChip from '@svg-icons/close3.svg';
import useLangAuth from '@hooks/useLangAuth';
import { LangMapWcag } from '@model/CoursesClass';

interface AccordionListProps {
  elements: Array<AccordionListElement>;
  title: string;
  expand: boolean;
  toggleAccordion?: (title: string, val: boolean) => void;
  name?: string;
  hideCloseIcon?: boolean;
  showSortBy?: boolean;
  currentSortBy?: string;
  titleHover?: string;
  hideArrow?: boolean;
  children?: React.ReactNode;
  showChildren?: boolean;
  isFilterModal?: boolean;
}

export const AccordionList = (props: AccordionListProps): React.ReactElement => {
  const langKeys: Readonly<(keyof LangMapWcag)[]> = [
    'COLLAPSE_ACCORDION',
    'EXPAND_ACCORDION',
    'ACCORDION_COLLAPSED',
    'ACCORDION_EXPANDED',
  ] as const;

  const labels = useLangAuth(langKeys);

  const [numChecked, setNumChecked] = useState(0);
  const [allDisabled, setAllDisabled] = useState(false);

  useEffect(() => {
    let checkedElements = [];
    let enabledElements = [];

    if (props.elements) {
      checkedElements = props.elements.filter(a => a.checked);
      enabledElements = props.elements.filter(a => !a.disabled);
    }
    if (props.showChildren) {
      enabledElements = ['foo'];
    }

    setNumChecked(checkedElements.length);
    setAllDisabled(enabledElements.length === 0);
  }, [props.elements, props.showChildren]);

  return (props.elements && props.elements.length > 0) || props.showChildren ? (
    <div className={'accordion-list'}>
      <div
        className={
          props.isFilterModal
            ? 'accordion-list__title-filter'
            : 'accordion-list__title' +
              (allDisabled ? 'disabled' : '') +
              (!props.hideArrow && props.expand ? ' theme-color-text' : '')
        }
        onClick={() => props.toggleAccordion(props.name, !props.expand)}
      >
        <span>
          {props.title}
          {!props.isFilterModal && !props.showSortBy && numChecked > 0 && ` (${numChecked})`}
          {props.isFilterModal && !props.showSortBy && numChecked > 0 && (
            <span className="accordion-list__num_filter">{numChecked}</span>
          )}
          {props.showSortBy && props.currentSortBy ? ' ' + props.currentSortBy : ''}
        </span>
        {!props.hideArrow && (
          <button
            className={
              'button-no-back accordion-list__chevron ' +
              (props.expand ? '' : 'accordion-list__chevron--expand')
            }
            aria-label={`${!props.expand ? labels?.EXPAND_ACCORDION : labels?.COLLAPSE_ACCORDION} ${
              props.title
            }`}
          >
            <ChevronUp />
          </button>
        )}
      </div>

      <div
        className={'accordion-list__list ' + (props.expand ? 'expand' : '')}
        style={{
          maxHeight: props.expand
            ? props.elements.length * 200 + (props.showChildren ? 600 : 0) + 'px'
            : '0px',
          transitionDuration: props.elements.length > 10 ? '0.8s' : undefined,
        }}
        aria-label={props.expand ? labels?.ACCORDION_EXPANDED : labels?.ACCORDION_COLLAPSED}
      >
        {!props.showChildren &&
          props.elements.map(element => (
            <div
              key={element.id}
              className={
                !props.isFilterModal
                  ? `accordion-list__element ${
                      element.checked && !element.disabled ? 'checked' : ''
                    } ${element.disabled ? 'disabled' : ''}`
                  : `accordion-list__element-filter ${
                      element.checked && !element.disabled ? 'checked' : ''
                    } ${element.disabled ? 'disabled' : ''}`
              }
              onClick={() => !element.disabled && element.toggleCheck()}
              title={props.titleHover}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  !element.disabled && element.toggleCheck();
                }
              }}
              aria-checked={element.checked && !element.disabled ? 'true' : 'false'}
              tabIndex={props.expand ? 0 : null}
            >
              <span>{element.title}</span>
              {!props.hideCloseIcon && element.checked && !props.isFilterModal && (
                <span className="accordion-list__close">
                  <Close fill="currentColor" stroke="currentColor" />
                </span>
              )}
              {!props.hideCloseIcon && element.checked && props.isFilterModal && (
                <span className="accordion-list__close-chip">
                  <CloseChip fill="currentColor" />
                </span>
              )}
            </div>
          ))}
        {props.showChildren && props.children}
      </div>
    </div>
  ) : null;
};

AccordionList.defaultProps = {
  hideCloseIcon: false,
  showSortBy: false,
};
export default AccordionList;
