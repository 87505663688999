const LOCALSTORAGE_CONST = {
    CELEBRATION: "celebration_state",
    UNIVERSITY_STATE: "University_State",
}

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LOCALSTORAGE_CONST.UNIVERSITY_STATE);
        if (serializedState === null) {
            return undefined;
        }

        let parsedState = JSON.parse(serializedState),
            dateString = parsedState.timestamp,
            now = new Date().getTime().toString();

        let isValid = compareTime(dateString, now);
        if (isValid)
            return parsedState.state;
        else
            return undefined;

    } catch (err) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        let now = new Date().getTime();
        let objWithTime = { state: state, timestamp: now }
        const serializedState = JSON.stringify(objWithTime);
        localStorage.setItem(LOCALSTORAGE_CONST.UNIVERSITY_STATE, serializedState);
    } catch (err) {
        console.log(err);
    }
}

export const compareTime = (state, now) => {
    let dateNow = new Date(parseInt(now));
    let dateState = new Date(parseInt(state));
    if ((dateNow - dateState) < (6 * 60 * 60 * 1000)) { //6 ore  
        console.log('valid')
        return true
    } else {
        console.log('not valid')
        return false
    }
}

export const handleCloseCelebrationInLocalStorage = (courseId, username) => {
    let celebrationState = {};
    let numCelebrationShown = 1;
    const lastTimeShow = new Date().getTime();

    const celebrationStateString = localStorage.getItem(LOCALSTORAGE_CONST.CELEBRATION);
    if (celebrationStateString) {
        //use try-catch in case the content of localstorage is present but not a JSON
        try {
            celebrationState = JSON.parse(celebrationStateString);
        } catch (error) {
            console.error('updateCelebrationLocalStorage - localstorage celebration was not a JSON');
        }
    }

    //get number of times the celebration was shown
    if (celebrationState[username]) {
        if (celebrationState[username][courseId]) {
            numCelebrationShown = celebrationState[username][courseId]?.numTimes + 1;
        }
    } else {
        celebrationState[username] = {};
    }

    //update celebration state for courseId
    celebrationState[username][courseId] = {
        numTimes: numCelebrationShown,
        lastTimeShow: lastTimeShow,
    };

    //set local storage
    localStorage.setItem(LOCALSTORAGE_CONST.CELEBRATION, JSON.stringify(celebrationState));
}

export const canShowCelebrationForLocalStorage = (courseId, username) => {
    const celebrationStateString = localStorage.getItem(LOCALSTORAGE_CONST.CELEBRATION);
    if (celebrationStateString) {
        let celebrationState = {};
        //use try-catch in case the content of localstorage is present but not a JSON
        try {
            celebrationState = JSON.parse(celebrationStateString);
        } catch (error) {
            console.error('canShowCelebration - localstorage celebration was not a JSON');
        }

        const numTimes = celebrationState?.[username]?.[courseId]?.numTimes || 0;

        if (numTimes > 0) {
            //celebration was already shown in the past for the specified courseId
            return false;
        }
    }

    return true;
}