import { MODAL_TYPES } from "@utility/const";
import { useSelector } from "./redux";
import { useDispatch } from "react-redux";
import { setShowModalByType } from "@redux-actions/";

type KeysModalType = keyof typeof MODAL_TYPES

type ResultType = { [key in KeysModalType]?: {
  show: boolean,
  toggle: () => void,
  open: (l0Id?) => void,
} }

const useModalTypes = () => {
  const dispatch = useDispatch();

  const typeModal: string = useSelector((state) => state.utils.typeModal);

  const modalTypes = Object.keys(
    MODAL_TYPES
  ) as unknown as (keyof typeof MODAL_TYPES)[];

  const closeModal = () => dispatch(setShowModalByType(null));

  const result: ResultType & {
    close?: () => void;
    isAnyModalOpen?: boolean;
  } =
    modalTypes.reduce((next, key) => {

      const toggleOpenModalType = () => dispatch(setShowModalByType(typeModal === key ? null : key));
      const openModalType = (l0Id?) => dispatch(setShowModalByType(MODAL_TYPES[key], l0Id));

      return {
        ...next, [key]: {
          show: key === typeModal,
          open: openModalType,
          toggle: toggleOpenModalType,
        }
      };
    }, { close: closeModal });

  result.isAnyModalOpen = modalTypes.some(type => typeModal === type);

  return result;
};
export default useModalTypes;
