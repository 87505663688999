import React, { useEffect, useState } from "react";
import { useSelector } from "@hooks/redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useToggle from "@hooks/useToggle";
import { USER_URLS } from "./link-utils";
import Toggle from "./UI/Toggle";
import { formatCurrency, getDaysDifference, getDaysInTheFuture, printDateDDMMYYYY, printHTML } from "@utility/Api";
import { PlanModel } from "@model/PlanModel";
import { UserProfile } from "@model/User";
import selectPlanStates, { selectSubscription, selectUserPlan } from "@utility/ecommercePlanUtility";
import { getSubscriptionList } from "@redux-actions/";
import ButtonV2 from "./UI/ButtonV2";

type RenewalBoxProps = {
  toggleActiveParent?: boolean;
  setToggleActiveParent?: (value: boolean) => void;
  isModal?: boolean;
  desktop?: boolean;
  skipReqSubList?: boolean;
}

const RenewalBox = (props: RenewalBoxProps) => {
  const lang = useSelector(state => state.utils.lang);
  const plan: PlanModel = useSelector((state) => state.ecommerce.plan);
  const isSubscriptionListLoading = useSelector((state) => state.ecommerce.isSubscriptionListLoading);
  const orderSubscription = useSelector(selectSubscription);

  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);

  const [toggleActive, setToggleActive] = useToggle(props.toggleActiveParent || false);
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [titleModal, setTitleModal] = useState<string>('');
  const [nextPayments, setNextPayments] = useState<{ price: string, isPricePerYear: boolean, time: string, timeFor: string }[]>([]);
  const [listLabels, setListLabels] = useState<string[]>([]);
  const renewalPlanActive = useSelector(state => state.ecommerce.renewalPlanActive);
  const planStates = useSelector(selectPlanStates);
  const userPlan = useSelector(selectUserPlan);
  const {bought} = useSelector(state => state.ecommerce.userPlans) ;
  const isLoadingCart = useSelector(state => state.ecommerce.isLoadingCart);


  useEffect(() => {
    if ((!orderSubscription && !isSubscriptionListLoading && !props.skipReqSubList) && !isLoadingCart) {
      dispatch(getSubscriptionList());
    }
  }, [orderSubscription, isLoadingCart])


  useEffect(() => {
    let titleTemp = '';
    let descriptionTemp = '';
    let titleModalTemp = '';
    let nextPaymentsTemp = [];
    let listLabelsTemp = [];

    if (plan && userProfile && lang) {
      // const isFree = props.isModal? planStates.isSubscriptionFree: +plan.price === 0;
      const isFree = planStates.isSubscriptionFree;
      const expirationDate = userPlan?.timeEnded || getDaysInTheFuture(isFree ? 90 : 365);

      //handle title
      const price = formatCurrency(plan.price, userProfile?.ecommerceInfo?.currency, userProfile.preferredLang);
      titleTemp = lang.RENEWAL_BOX_PREMIUM_ACCESS_PLAN;

      if (isFree ) {
        if(props.isModal) {
          descriptionTemp = lang.RENEWAL_BOX_FREE_PLAN_ON_DESCR;
        if (!renewalPlanActive) {
          descriptionTemp = lang?.RENEWAL_BOX_FREE_PLAN_OFF_DESCR;
          descriptionTemp += "<br/><br/>" + lang?.RENEWAL_BOX_FREE_PLAN_OFF_DESCR2;
        }
          descriptionTemp = descriptionTemp?.replace('{DATE}', printDateDDMMYYYY(expirationDate))?.replace('{PRICE}', price + '/' + lang.YEAR?.toLowerCase());
        } else {

        }
        
      }

      //handle modal title
      titleModalTemp = (isFree ? lang.MODAL_START_AUTORENEWAL_FREE_PLAN_TITLE2 : lang.MODAL_START_AUTORENEWAL_FULL_PRICE_TITLE2)
        ?.replace('{DATE}', printDateDDMMYYYY(expirationDate))
        .replace('{PRICE}', price + '/' + lang.YEAR?.toLowerCase());


      //handle next payments labels
      const lastPriceValue = plan.price;
      const lastPrice = formatCurrency(lastPriceValue, userProfile?.ecommerceInfo?.currency, userProfile.preferredLang);
      let firstPrice = '';
      let isFirstPricePerYear = true;

      if (isFree /*&& !props.isModal*/) {
        firstPrice = formatCurrency('0', userProfile?.ecommerceInfo?.currency, userProfile.preferredLang);
        isFirstPricePerYear = false;
      } else {
        firstPrice = lastPrice;
      }

      //const activatedDate = userPlan?.timeStarted;
      const boughtDate =  bought?.timeStarted;

      const secondDate = !props.isModal ? printDateDDMMYYYY(getDaysInTheFuture(isFree ? 90 : 365)) : printDateDDMMYYYY(userPlan?.timeEnded);

      const firstTime = props.isModal
        ? lang.RENEWAL_BOX_FROM_DATE?.replace('{DATE}', printDateDDMMYYYY(boughtDate))
        : lang.RENEWAL_BOX_TODAY

      nextPaymentsTemp = [
        {
          time: firstTime,
          timeFor: isFree ? lang.RENEWAL_BOX_FOR_3_MONTHS : lang.RENEWAL_BOX_FOR_1_YEAR,
          price: firstPrice,
          isPricePerYear: isFirstPricePerYear,
        },
        {
          time: secondDate,
          timeFor: lang.RENEWAL_BOX_FOR_1_YEAR,
          price: lastPrice,
          isPricePerYear: true,
        }
      ];


      const daysPriorReminder = {
        free: "21",
        fullPrice: "40",
      }

      //handle list labels
      listLabelsTemp = props.isModal ?
        isFree ?
        [
          lang.RENEWAL_BOX_FREE_PLAN_LIST1,
          lang.RENEWAL_BOX_FREE_PLAN_LIST2?.replace('{DAYS}', daysPriorReminder.free)?.replace('{DAYS}', daysPriorReminder.free)
        ]
        :
        [lang.RENEWAL_ON_MODAL_TIP_1, lang.RENEWAL_BOX_FULL_PRICE_LIST2?.replace('{DAYS}', daysPriorReminder.fullPrice)]

        : listLabelsTemp = [lang.RENEWAL_BOX_UNSUBSCRIBE_INFO, lang.RENEWAL_BOX_FULL_PRICE_LIST2?.replace('{DAYS}', isFree? daysPriorReminder.free : daysPriorReminder.fullPrice)]

      // reminder?
      //RENEWAL_BOX_FULL_PRICE_LIST2

      // get text between {}
      const listLabelsText = listLabelsTemp?.[0]?.split("{")[1]?.split("}")?.[0];

      listLabelsTemp[0] = listLabelsTemp[0]
        ?.replace('{', `<a class="text-btn-no-arrow small inline" aria-label="${listLabelsText}" href="${USER_URLS.PLAN.URL}" target="_blank" rel="noopener noreferrer">`)
        .replace('}', '</a>')
    }

    const descriptionTempValue = descriptionTemp?.split("{")[1]?.split("}")?.[0];
    descriptionTemp = descriptionTemp
      ?.replace('{', `<a class="text-btn-no-arrow small inline" aria-label="${descriptionTempValue}" href="${USER_URLS.PLAN.URL}" target="_blank" rel="noopener noreferrer">`)
      .replace('}', '</a>');

    setTitle(titleTemp);
    setDescription(descriptionTemp);
    setTitleModal(titleModalTemp);
    setNextPayments(nextPaymentsTemp);
    setListLabels(listLabelsTemp);
  }, [plan, renewalPlanActive, orderSubscription, userProfile, lang]);

  const handleToggle = (newValue: boolean) => {
    setToggleActive(newValue);
    if (props.setToggleActiveParent) {
      props.setToggleActiveParent(newValue);
    }
  }

  useEffect(() => {
    if (props?.toggleActiveParent !== toggleActive) {
      setToggleActive(!!props?.toggleActiveParent);
    }
  }, [props?.toggleActiveParent])


  return (
    <div className="renewal-box">
      {props.isModal
        ?
        <p
          className="renewal-box__title isModal"
          dangerouslySetInnerHTML={printHTML(titleModal)}
        />
        :
        <>
          <p
            className="renewal-box__title"
            dangerouslySetInnerHTML={printHTML(title)}
          />

          <div className="renewal-box__toggle-row">
            <label>{lang?.WANT_PLAN_TO_BE_RENEWED}</label>
            <Toggle
              id={props.desktop ? "toBeRenewed" : "toBeRenewedMobile"}
              defaultChecked={toggleActive}
              onChange={handleToggle}
              canDefaultChange={true}
              aria-label={lang?.WANT_PLAN_TO_BE_RENEWED}
              ecommerce
            />
          </div>

          <p
            className="renewal-box__title"
            dangerouslySetInnerHTML={printHTML(description)}
          />

          <ButtonV2
            variant="text-btn-no-arrow"
            small
            className="renewal-box__renewal-link"
            to={USER_URLS.LEGAL.URL}
            title={lang?.RENEWAL_BOX_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            {lang?.RENEWAL_BOX_POLICY}
          </ButtonV2>
        </>
      }

      <div className={"renewal-box__accordion " + (toggleActive ? 'open' : 'close')}>
        <hr />

        <span className="renewal-box__next-payments-title">{lang?.RENEWAL_BOX_NEXT_PAYMENTS}</span>
        <div className="renewal-box__next-payments">
          {nextPayments?.map((nextPayment, index) =>
            <div className={"renewal-box__next-payments-row"} key={nextPayment.time}>
              <div className="flex">
                <div className={"renewal-box__next-payments-linedot row-" + index}>
                  <div className="renewal-box__next-payments-line line-up"></div>
                  <div className="renewal-box__next-payments-dot"></div>
                  <div className="renewal-box__next-payments-line line-down"></div>
                </div>
                <div className="renewal-box__next-payments-time-container">
                  <span className="renewal-box__next-payments-time">{nextPayment.time + ' '}</span>
                  <span className="renewal-box__next-payments-time-for">{nextPayment.timeFor}</span>
                </div>
              </div>
              <div className="renewal-box__next-payments-price">
                <b>{nextPayment.price}</b>
                {nextPayment.isPricePerYear &&
                  (' / ' + lang?.YEAR?.toLowerCase())
                }
              </div>
            </div>
          )}
        </div>

        <hr className={"" + (!props.isModal? " phone-and-tablet-portrait" : "" )}/>
        {props.isModal &&
          <ul className="renewal-box__list">
          {listLabels?.map(listLabel =>
            <li key={listLabel} dangerouslySetInnerHTML={printHTML(listLabel)} />
          )}
        </ul>
        }

        {!props.isModal && listLabels?.map(listLabel => 
          <p className="renewal-box__unsubscribe-info" key={listLabel} dangerouslySetInnerHTML={printHTML(listLabel)}></p>
        )}
      </div>

    </div>
  );
};

export default RenewalBox;
