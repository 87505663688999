import { getCookie, removeCookie, setCookie } from "@utility/cookie";
import { useCallback, useState } from "react";

const useCookie = (
  cookieName: string
): [string | null, (newValue: string, numberOfDays: number) => void, () => void] => {
  const [value, setValue] = useState<string | null>(() => getCookie(cookieName) || null);

  const updateCookie = useCallback(
    (newValue: string, numberOfDays: number) => {
      setCookie(cookieName, newValue, numberOfDays);
      setValue(newValue);
    },
    [cookieName]
  );

  const deleteCookie = useCallback(() => {
    removeCookie(cookieName);
    setValue(null);
  }, [cookieName]);

  return [value, updateCookie, deleteCookie];
};

export default useCookie;