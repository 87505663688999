import { CMSBrandInfo } from "@model/CMSBrand";
import { GradientClass, GradientRange, GradientVariant } from "../model/GradientClass";

export const GRADIENT_YELLOW_POSITION: GradientRange = { value: 0 };
export const GRADIENT_LIGHT_GREEN_POSITION: GradientRange = { value: 1 };
export const GRADIENT_DARK_GREEN_POSITION: GradientRange = { value: 2 };
export const GRADIENT_LIME_POSITION: GradientRange = { value: 3 };
export const GRADIENT_LIGHT_BLUE_POSITION: GradientRange = { value: 4 };
export const GRADIENT_DARK_BLUE_POSITION: GradientRange = { value: 5 };
export const GRADIENT_PINK_POSITION: GradientRange = { value: 6 };
export const GRADIENT_ORANGE_POSITION: GradientRange = { value: 7 };

//OLD
// export const GRADIENT_YELLOW_POSITION: GradientRange = { from: 0, to: 3 };
// export const GRADIENT_LIGHT_GREEN_POSITION: GradientRange = { from: 4, to: 7 };
// export const GRADIENT_DARK_GREEN_POSITION: GradientRange = { from: 8, to: 11 };
// export const GRADIENT_LIME_POSITION: GradientRange = { from: 12, to: 15 };
// export const GRADIENT_LIGHT_BLUE_POSITION: GradientRange = { from: 16, to: 19 };
// export const GRADIENT_DARK_BLUE_POSITION: GradientRange = { from: 20, to: 23 };
// export const GRADIENT_PINK_POSITION: GradientRange = { from: 24, to: 27 };
// export const GRADIENT_ORANGE_POSITION: GradientRange = { from: 28, to: 31 };

//THEME COLOR
export const PICKER_YELLOW = { ...GRADIENT_YELLOW_POSITION, color: '#ffcc00' };
export const PICKER_LIGHT_GREEN = { ...GRADIENT_LIGHT_GREEN_POSITION, color: '#c7ff00' };
export const PICKER_DARK_GREEN = { ...GRADIENT_DARK_GREEN_POSITION, color: '#24ca00' };
export const PICKER_LIGHT_BLUE = { ...GRADIENT_LIME_POSITION, color: '#00ff9f' };
export const PICKER_DARK_BLUE = { ...GRADIENT_LIGHT_BLUE_POSITION, color: '#009BFF' };
export const PICKER_VIOLET = { ...GRADIENT_DARK_BLUE_POSITION, color: '#009BFF' };
export const PICKER_RED = { ...GRADIENT_PINK_POSITION, color: '#ff00cb' };
export const PICKER_ORANGE = { ...GRADIENT_ORANGE_POSITION, color: '#ff6600' };

export const PICKER_COLORS = [
    PICKER_YELLOW,
    PICKER_LIGHT_GREEN,
    PICKER_DARK_GREEN,
    PICKER_LIGHT_BLUE,
    PICKER_DARK_BLUE,
    PICKER_VIOLET,
    PICKER_RED,
    PICKER_ORANGE,
];

enum GRADIENT_VARIANT_ENUM {
    defaultRadial = 'defaultRadial',
    doubleLayer1 = 'doubleLayer1',
    doubleLayer2 = 'doubleLayer2',
    defaultLinear = 'defaultLinear',
}

//ZEPLIN GRADIENTE SEMPLICE
export const GRADIENT_VARIANT_DEFAULT_RADIAL: GradientVariant = {
    variant: GRADIENT_VARIANT_ENUM.defaultRadial,
    type: 'radial-gradient',
    orientation: 'circle at 94% 0',
    opacity: 'FF',
    colors: null,
    otherStyle: {},
    imageStyle: { mixBlendMode: 'screen' }
};

export const GRADIENTS_DEFAULT_RADIAL: Array<GradientClass> = [
    //yellow
    { range: GRADIENT_YELLOW_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#ffcc26', position: 0 }, { hex: '#3b2c00', position: 64 }] } },
    //light-green
    { range: GRADIENT_LIGHT_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#83a13d', position: 0 }, { hex: '#223600', position: 64 }] } },
    //dark-green
    { range: GRADIENT_DARK_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#58d400', position: 0 }, { hex: '#07853b', position: 64 }] } },
    //lime
    { range: GRADIENT_LIME_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#0bfb9f', position: 0 }, { hex: '#004d18', position: 64 }] } },
    //light-blue
    { range: GRADIENT_LIGHT_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#02d0fe', position: 0 }, { hex: '#072254', position: 64 }] } },
    //dark-blue
    { range: GRADIENT_DARK_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#2000d5', position: 0 }, { hex: '#4e57cf', position: 64 }] } },
    //pink
    { range: GRADIENT_PINK_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: "#ff32cc", position: 0 }, { hex: '#ff32cc', position: 64 }] } },
    //orange
    { range: GRADIENT_ORANGE_POSITION, gradient: { ...GRADIENT_VARIANT_DEFAULT_RADIAL, colors: [{ hex: '#ff6810', position: 0 }, { hex: '#ff6810', position: 64 }] } },
];

//ZEPLIN GRADIENTE COMPOSTO LAYER 1
export const GRADIENT_VARIANT_DOUBLE_LAYER1: GradientVariant = {
    variant: GRADIENT_VARIANT_ENUM.doubleLayer1,
    type: 'radial-gradient',
    orientation: 'circle at 92% 109%',
    opacity: 'FF',
    colors: null,
    otherStyle: { opacity: 0.6 },
    imageStyle: { mixBlendMode: 'soft-light', opacity: 0.7 }
};

export const GRADIENTS_DOUBLE_LAYER1: Array<GradientClass> = [
    //yellow
    { range: GRADIENT_YELLOW_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#ffcc26', position: 0 }, { hex: '#913300', position: 80 }, { hex: '#4f2500', position: 117 }] } },
    //light-green
    { range: GRADIENT_LIGHT_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#e6ff0a', position: 0 }, { hex: '#1d2310', position: 80 }, { hex: '#253e00', position: 117 }] } },
    //dark-green
    { range: GRADIENT_DARK_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#23ca26', position: 0 }, { hex: '#003f91', position: 80 }, { hex: '#001c4f', position: 117 }] } },
    //lime
    { range: GRADIENT_LIME_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#05f08e', position: 0 }, { hex: '#007491', position: 80 }, { hex: '#001e4f', position: 117 }] } },
    //light-blue
    { range: GRADIENT_LIGHT_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#05c9f0', position: 0 }, { hex: '#007491', position: 80 }, { hex: '#001e4f', position: 117 }] } },
    //dark-blue
    { range: GRADIENT_DARK_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#001486', position: 0 }, { hex: '#007afc', position: 80 }, { hex: '#042aff', position: 117 }] } },
    //pink
    { range: GRADIENT_PINK_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: "#cd50ae", position: 0 }, { hex: '#4c4ee6', position: 80 }, { hex: '#910057', position: 117 }] } },
    //orange
    { range: GRADIENT_ORANGE_POSITION, gradient: { ...GRADIENT_VARIANT_DOUBLE_LAYER1, colors: [{ hex: '#ff8605', position: 0 }, { hex: '#910057', position: 80 }, { hex: '#4f0000', position: 117 }] } },
];

//ZEPLIN GRADIENTE COMPOSTO LAYER 2
export const GRADIENT_VARIANT_DOUBLE_LAYER2: GradientVariant = {
    variant: GRADIENT_VARIANT_ENUM.doubleLayer2,
    type: 'linear-gradient',
    orientation: 'to bottom',
    opacity: 'FF',
    colors: null,
    otherStyle: {},
    imageStyle: { mixBlendMode: 'soft-light', opacity: 0.7 }
};

export const GRADIENTS_DOUBLE_LAYER2: Array<GradientClass> = [
    //yellow
    {
        range: GRADIENT_YELLOW_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#ff9900', position: 0 }, { hex: '#fca92b', position: 26 }, { hex: '#fcae00', position: 61 }, { hex: '#faac02', position: 100 }
            ]
        }
    },
    //light-green
    {
        range: GRADIENT_LIGHT_GREEN_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#a9db02', position: 0 }, { hex: '#bcf777', position: 26 }, { hex: '#a8db02', position: 61 }, { hex: '#4c7d02', position: 100 }]
        }
    },
    //dark-green
    {
        range: GRADIENT_DARK_GREEN_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#02b030', position: 0 }, { hex: '#3bad2b', position: 26 }, { hex: '#92bb00', position: 61 }, { hex: '#5fd902', position: 100 }]
        }
    },
    //lime
    {
        range: GRADIENT_LIME_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#0bfb9f', position: 0 }, { hex: '#00d095', position: 26 }, { hex: '#00ffc8', position: 61 }, { hex: '#8cf2ff', position: 100 }]
        }
    },
    //light-blue
    {
        range: GRADIENT_LIGHT_BLUE_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#02afd1', position: 0 }, { hex: '#2ad5f7', position: 26 }, { hex: '#af91ed', position: 61 }, { hex: '#a16aff', position: 100 }]
        }
    },
    //dark-blue
    {
        range: GRADIENT_DARK_BLUE_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#65a5ea', position: 0 }, { hex: '#005bbd', position: 26 }, { hex: '#042aff', position: 61 }, { hex: '#00189c', position: 100 }]
        }
    },
    //pink
    {
        range: GRADIENT_PINK_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: "#b90000", position: 0 }, { hex: '#db3ddb', position: 26 }, { hex: '#d678d6', position: 61 }, { hex: '#ff9e50', position: 100 }]
        }
    },
    //orange
    {
        range: GRADIENT_ORANGE_POSITION, gradient: {
            ...GRADIENT_VARIANT_DOUBLE_LAYER2, colors: [
                { hex: '#956700', position: 0 }, { hex: '#ffa900', position: 26 }, { hex: '#fcae00', position: 61 }, { hex: '#ffbe00', position: 100 }]
        }
    },
];

//ZEPLIN GRADIENTE SINGOLO
export const GRADIENT_VARIANT_LINEAR: GradientVariant = {
    variant: GRADIENT_VARIANT_ENUM.defaultLinear,
    type: 'linear-gradient',
    orientation: '315deg',
    opacity: 'FF',
    colors: null,
    otherStyle: {},
    imageStyle: { mixBlendMode: 'soft-light' }
};

export const GRADIENTS_DEFAULT_LINEAR: Array<GradientClass> = [
    //yellow
    { range: GRADIENT_YELLOW_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#ffd700', position: 0 }, { hex: '#ff6700', position: 100 }] } },
    //light-green
    { range: GRADIENT_LIGHT_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#a9cc3f', position: 0 }, { hex: '#b4ff00', position: 100 }] } },
    //dark-green
    { range: GRADIENT_DARK_GREEN_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#5bad5c', position: 0 }, { hex: '#00b249', position: 100 }] } },
    //lime
    { range: GRADIENT_LIME_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#20d441', position: 0 }, { hex: '#00ccff', position: 100 }] } },
    //light-blue
    { range: GRADIENT_LIGHT_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#0064ff', position: 0 }, { hex: '#00ccff', position: 100 }] } },
    //dark-blue
    { range: GRADIENT_DARK_BLUE_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#4257f5', position: 0 }, { hex: '#4a47a3', position: 100 }] } },
    //pink
    { range: GRADIENT_PINK_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: "#fa7fe5", position: 0 }, { hex: '#b41aa5', position: 100 }] } },
    //orange
    { range: GRADIENT_ORANGE_POSITION, gradient: { ...GRADIENT_VARIANT_LINEAR, colors: [{ hex: '#ff9500', position: 0 }, { hex: '#ff0049', position: 100 }] } },
];


export const getGradientsByVariant = (variant: string): Array<GradientClass> => {
    switch (variant) {
        case GRADIENT_VARIANT_ENUM.defaultRadial:
            return GRADIENTS_DEFAULT_RADIAL;
        case GRADIENT_VARIANT_ENUM.doubleLayer1:
            return GRADIENTS_DOUBLE_LAYER1;
        case GRADIENT_VARIANT_ENUM.doubleLayer2:
            return GRADIENTS_DOUBLE_LAYER2;
        case GRADIENT_VARIANT_ENUM.defaultLinear:
            return GRADIENTS_DEFAULT_LINEAR;
        default:
            return GRADIENTS_DEFAULT_RADIAL;
    }
}

export const getFirstGradient = (variant: GRADIENT_VARIANT_ENUM): GradientClass => {
    const gradientColors: Array<GradientClass> = [...getGradientsByVariant(variant)];
    gradientColors.sort((a, b) => a.range.value - b.range.value);
    return gradientColors[0];
}

export const getLastGradient = (variant: GRADIENT_VARIANT_ENUM): GradientClass => {
    const gradientColors: Array<GradientClass> = [...getGradientsByVariant(variant)];
    gradientColors.sort((a, b) => a.range.value - b.range.value);
    return gradientColors[gradientColors.length - 1];
}

export const getGradientColorByColorPickerValue = (colorPickerValue: number, variant: GRADIENT_VARIANT_ENUM): GradientClass => {
    //if color picker value is null --> return the first gradient
    if (!colorPickerValue) {
        return getFirstGradient(variant);
    }

    //if color picker value is negative --> return the first gradient
    if (colorPickerValue < 0) {
        return getFirstGradient(variant);
    }

    //if color picker value is over the maximum range --> return the last gradient
    if (colorPickerValue > 100) {
        return getLastGradient(variant);
    }

    //find the corresponding gradient
    const gradients = getGradientsByVariant(variant);
    for (let gradientColor of gradients) {
        if (gradientColor.range.value === colorPickerValue) {
            return gradientColor;
        }
    }

    //if color picker value doesn't match to any gradient --> return the first gradient
    return getFirstGradient(variant);
}

export const generateGradient = (colorPickerValue: number, variant: GRADIENT_VARIANT_ENUM, gradientEnable: boolean = true, brandPageInfo?: CMSBrandInfo): string => {
    //find the corrisponding color to color picker value
    let gradientColor = { ...getGradientColorByColorPickerValue(colorPickerValue, variant) };
    if (!gradientColor) {
        return '';
    }

    //get colors for gradient
    let gradientColors = [...gradientColor.gradient.colors];
    if (brandPageInfo?.extendedFields?.StartColor && brandPageInfo?.extendedFields?.EndColor) {
        gradientColors = [
            { hex: brandPageInfo.extendedFields.StartColor, position: gradientColors[0].position },
            { hex: gradientEnable ? brandPageInfo.extendedFields.EndColor : brandPageInfo.extendedFields.StartColor, position: gradientColors[1].position }
        ]
    }

    let gradient: string = `${gradientColor.gradient.type}(${gradientColor.gradient.orientation}`;

    let colors: string = '';
    for (let color of gradientColors) {
        colors += `, ${gradientEnable ? color.hex : gradientColors[0].hex}${gradientColor.gradient.opacity} ${color.position}%`
    }

    gradient += colors + ')';

    return gradient;
}

export const getBackgroundGradientCss = (colorPickerValue: number, variant: GRADIENT_VARIANT_ENUM, gradientEnable: boolean = true, brandPageInfo?: CMSBrandInfo): {} => {
    const style = getGradientColorByColorPickerValue(colorPickerValue, variant).gradient.otherStyle;
    if (brandPageInfo) {
        return { ...style, backgroundImage: generateGradient(colorPickerValue, variant, gradientEnable, brandPageInfo) };
    } else {
        return { ...style, backgroundImage: generateGradient(colorPickerValue, variant, gradientEnable) };
    }
}

export const getImageGradientCss = (colorPickerValue: number, variant: GRADIENT_VARIANT_ENUM): {} => {
    return getGradientColorByColorPickerValue(colorPickerValue, variant).gradient.imageStyle;
}

export const getColorByColorPicker = (colorPickerValue: number, brandPageInfo?: CMSBrandInfo) => {
    if (brandPageInfo?.extendedFields?.MainColor) {
        return { value: -1, color: brandPageInfo.extendedFields.MainColor };
    }

    for (let gradientColor of PICKER_COLORS) {
        if (gradientColor.value === colorPickerValue) {
            return gradientColor;
        }
    }
}

export const fromHexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    }
    return rgb.r + ', ' + rgb.g + ', ' + rgb.b;
}

