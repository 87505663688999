import { TrainingPill, TrainingPillResponse } from "./ProductClass"

export type SearchResult = {
  courseIds: string[];
  channels: string[];
  suggestedCourseIds: string[];
  suggestedChannels: string[];
  suggestedTerms: string[];
  suggestedPhrase: string;
  suggestedFrequency: number;
  channelsInfo: ChannelInfoObject[];
  suggestedChannelsInfo: ChannelInfoObject[]; 
  sections: string[];
}

export type ChannelInfoObject = {
  channelId: string;
  numberOfEvents: number;
  numberOfCourses: number;
  numberOfLessons: number;
}

export class SearchTPResult {
  searchFields: TrainingPill[] = [];
  suggestionFields: TrainingPill[] = [];
  suggestedTerms: string[] = [];
  suggestedPhrase: string = '';
  suggestedFrequency: number = 0;
  responseFilter: TrainingPillResponse = new TrainingPillResponse();
}

export type SearchResultWithTP = {
  searchResponse: SearchResult;
  pillsResponse: SearchTPResult;
}