
import React from "react";
import { History } from 'history';
import { LangMap } from "@model/CoursesClass";
import LazyImage from 'react-lazy-progressive-image';
import ButtonV2 from "@components/UI/ButtonV2";
import { USER_URLS } from "@components/link-utils";
import clsx from "@utility/clsx";

type Props = {
  lang: LangMap;
  history: History;
  calendar?: boolean;
  showCta?: boolean;
  ctaLabel?: string;
  customHandleCtaClick?: () => void;
  isCmsNewPage?: boolean
};

const HotAirBaloon = ({ calendar, lang, history, showCta = true, ctaLabel = '', customHandleCtaClick, isCmsNewPage = false }: Props) => {

  const handleCtaClick = () => {
    if (customHandleCtaClick) {
      customHandleCtaClick();
    } else {
      history?.push(USER_URLS.SUBCATALOGUE.URL)
    }

  }

  const hotAirBaloonClassName = clsx('hotairbaloon','flex column align-center', {
      'cms-new-page': isCmsNewPage
    }
  )

  return (
    <section className={`${hotAirBaloonClassName}`} aria-label={lang.FIND_INSPIRATION}>
      <LazyImage
        src={!calendar ? "/images/empty-hotairbaloon.png" : "/images/empty-calendar.png"}
        placeholder="/images/spinner/img_placeholder.gif"
        visibilitySensorProps={{ partialVisibility: true }}
      >
        {(src, loading) => (
          <img src={src} className={!loading ? "empty-img" : ""} aria-hidden="true" />
        )}
      </LazyImage>
      {isCmsNewPage &&
        <p className="heading-description">{lang.CMS_NEW_PAGE_HOT_AIR_BALOON_DESCRIPTION}</p>
      }
      {showCta && (
        <ButtonV2
          variant="primary"
          handleClick={handleCtaClick}
          className="empty-button"
        >
          {ctaLabel ? ctaLabel : lang.FIND_INSPIRATION}
        </ButtonV2>
      )}
    </section>
  );
};

export default HotAirBaloon;
