import clsx from "@utility/clsx";
import React from "react";

class TagMultipleBadgesProps {
    className?: string = "";
    variant?: "white-border" | "evolution-primary-border" | "filled" | "grey";
    badges?: string[];
};

const TagMultipleBadges = (props: TagMultipleBadgesProps) => {

    return (
        <div
            className={clsx("tag-badge multiple-badge", {
                [props.variant]: props.variant,
                [props.className]: props.className,
                ["long-badge"] : props?.badges[0]?.length > 15,
                ["with-num"] : props?.badges.length > 1,
                ["no-num"] : props?.badges?.length === 1
            })}
        >
            <div className="multiple-badge__value-shown">
                {props?.badges[0]}
            </div>
            {props?.badges.length > 1 &&
                <div className="multiple-badge__num">
                    {"+" + (props?.badges.length - 1)}
                </div>
            }

        </div>
    );
};

export default TagMultipleBadges;

//if desktop -> unset
