import { useInView } from 'react-intersection-observer';

const useInViewCarousel = (carouselId, verticalScroll?, useIdAsSelector = false) => {
  const [ref, isVisible] = useInView({
    //trigger the observer when a specific percentage of the element appears or disappears from the screen
    threshold: 0.95,
    rootMargin: verticalScroll
      ? '0px 1000px 0px 1000px' //consider just vertical scroll
      : '1000px -36px 1000px -36px', //consider just horizontal scroll
    //consider carousel viewport
    root: document.querySelector(
      useIdAsSelector ? carouselId : '#' + carouselId + ' .carousel-scroller'
    ),
  });

  return [ref, isVisible];
};
export default useInViewCarousel;
