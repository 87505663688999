import { cmMetaData } from '@utility/previewUtils';
import React from 'react';
import * as actions from '@redux-actions/';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';

type PageTopTitleProps = {
  className?: string;
  id?: string;
  title: string;
  brandName?: string;
  longName?: string;
  description?: string;
  tooltip?: JSX.Element;
  htmlDescription?: { __html: string };
  children?: React.ReactNode;
  childrenBetweenTitleDescr?: boolean;
  isEditorialPlanPage?: boolean;
  contentId?: number;
};

export const PageTopTitle = ({
  className = '',
  id,
  title,
  brandName,
  longName,
  description,
  tooltip,
  htmlDescription,
  contentId,
  children,
  childrenBetweenTitleDescr = false,
}: PageTopTitleProps) => {
  const dispatch = useDispatch();
  const stateOfLinks = useSelector(state => state.utils.linksHeaderFromCMS)
  type TRowLink  = {
    linkLabel: string   // stringa da visualizzare a schermo
    linkString: string  // link URL relativo alla row referenziata
    }
  
    type TRowLinks = Array<TRowLink>;
    const links: TRowLinks = [{ linkLabel: "Prova", linkString: "#prova" }, { linkLabel: "Prova2", linkString: "#prova2" }, { linkLabel: "Prova3", linkString: "#prova3" }, { linkLabel: "Prova4", linkString: "#prova4" }];
    //dispatch(actions.getHeaderMenuViaCMSRow(links));

  return (
    <section className={'page-top-title ' + className} aria-label={title}>
      <header>

        {brandName && longName ?  
          <>
           <h1 className="typography-primary-h2-left-gradient">{brandName} </h1>
           <h2 className="typography-primary-h2-left-gradient">{longName}</h2>
          </>
          : 
          <h1 className="typography-primary-h2-left-gradient" {...contentId ? {...cmMetaData(contentId)} : null}>{title}</h1>
        }

      </header>

      {childrenBetweenTitleDescr && children}

      {description && <p className="page-top-title__description" {...contentId ? {...cmMetaData(contentId)} : null}>{description}</p>}
      {htmlDescription && (
        <p className="page-top-title__description" dangerouslySetInnerHTML={htmlDescription}/>
      )}

      {!!tooltip && tooltip}

      {!childrenBetweenTitleDescr && children}
    </section>
  );
};

export default PageTopTitle;

