import { printHTML } from "@utility/Api";
import clsx from "@utility/clsx";
import React from "react";

class TagBadgeProps {
  className?: string = "";
  variant?: "white-border" | "evolution-primary-border" | "filled" | "grey";
  label: string;
  isHTML?: boolean;
};

const TagBadge = (props: TagBadgeProps) => {

  return (
    <div
      className={clsx("tag-badge", {
        [props.variant]: props.variant,
        [props.className]: props.className
      })}
      dangerouslySetInnerHTML={props.isHTML ? printHTML(props.label) : null}
    >
      {!props.isHTML ? props.label : null}
    </div>
  );
};

export default TagBadge;