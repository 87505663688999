import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import useModalTypes from "@hooks/useModalByType";
import MenuAreaOfExpertiseMobile from "./MenuAreaOfExpertiseMobile";
import MenuCollectionsMobile from "./MenuCollectionsMobile";
import MenuProfileMobile from "./MenuProfileMobile";
import ModalRight from "@components/UI/ModalRight";
import MenuMobile from "./MenuMobile";
import {HEADER} from "@utility/const.js"
import { UserProfile } from "@model/User";
import MenuAreaOfExpertiseL1Mobile from "./MenuAreaOfExpertiseL1Mobile";
import MenuVisionCareMobile from "./MainMenuVisionCareMobile";

type Props = {
  closeMenu: () => void;
  isAuthenticated: boolean;
  show: boolean;
  logout: () => void;
  isManager: boolean;
  isTeacher: boolean;
  userProfile: UserProfile;
};

const MainMenuMobile = (props: Props) => {
  const modalTypes = useModalTypes();
  const [modalType, setModalType] = React.useState(null)

  const whiteMenu = HEADER.ISWHITE; 

  React.useEffect(() => {
    let modalTypeTemp = null;
    for (const [key, value] of Object.entries(modalTypes)) {
      if (value?.show) {
        modalTypeTemp = key;
      }
    }
    setModalType(modalTypeTemp);
  }, [modalTypes])


  return (
    <ModalRight
      className={(whiteMenu? "light-variant-mobile " : "dark-variant-mobile ") + "overflow-scroll-content"}
      contentClassName={`menu-mobile__modal-content ${modalType || ""}`}
      show={props.show}
      close={modalTypes.close}
      durationAnimation={0.15}
      noOpacityAnimation
      animateSlide
    >
      <AnimatePresence>
      {(modalTypes.visionCareMenu.show) && (
          <MenuVisionCareMobile
            show={modalTypes.visionCareMenu.show}
            fixed={!modalTypes.visionCareMenu.show} isLight={whiteMenu}
          />
        )}
        {(modalTypes.areaOfExpertiseMenu.show || modalTypes.areaOfExpertiseL1Menu.show) && (
          <MenuAreaOfExpertiseMobile
            show={modalTypes.areaOfExpertiseMenu.show || modalTypes.areaOfExpertiseL1Menu.show}
            fixed={!modalTypes.areaOfExpertiseMenu.show} isLight={whiteMenu}
          />
        )}
        {modalTypes.collectionsMenu.show && (
          <MenuCollectionsMobile show={modalTypes.collectionsMenu.show} isLight={whiteMenu}/>
        )}
        {modalTypes.profileMenu.show && (
          <MenuProfileMobile show={modalTypes.profileMenu.show} isLight={whiteMenu}/>
        )}
      </AnimatePresence>
      <MenuMobile {...props} fixed={!modalTypes.menuRightModal.show} isLight={whiteMenu}/>
    </ModalRight>
  );
};

export default MainMenuMobile;
