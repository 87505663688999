import { useSelector } from "@hooks/redux";
import { AccordionListElement } from "@model/AccordionListElement";
import { Brand } from "@model/Brand";
import { Course, CoursesMap, getTrainerFullName, Trainer } from "@model/CoursesClass";
import { DurationFilter } from "@model/DurationFilter";
import { TP_CHARACTERISTICS, TP_COLLECTION } from "@model/ProductClass";
import { UserProfile } from "@model/User";
import { compareDates, comparePrices, getDurationFilterById, isCompletedCourse, isCourseExpiring, isCourseToBeStarted, isL1Course, isLearningPath, isLivestreamCourse, isMandatory, isOverdueCourse, isStartedCourse, isToBeContinued, isUpcomingCourse, isVirtualClassroom } from "./Api";
import { BRAND_TYPE, DURATION, EXPIRATION, SESSION_SCHEDULE, SORTBY } from "./const";
import { isPurchased } from "./ecommerceUtility";
import { isLivestreamNotYetFinished } from "./LivestreamUtility";
import { isStartedLp } from "./LPUtility";
import { isCourseMaster, isCourseOnBoarding } from "./onBoardingCourseUtility";
import { isProgramBlocked } from "./EducationalPathsUtility";

export const multipleSortCourses = (courses: Array<Course>, sortBy: Array<string>, sortByRelevanceCriteria: any = null, userProfile: UserProfile = null) => {
  if (!sortBy) {
    return courses;
  }

  sortBy.forEach((sorting) => {
    courses = sortCourses(courses, sorting, sortByRelevanceCriteria, userProfile);
  });

  return courses;
}

export const sortCourses = (courses: Array<Course>, sortBy: string, sortByRelevanceCriteria: any = null, userProfile: UserProfile = null) => {
  if (!courses || courses?.length < 1) {
    return [];
  }

  /*if (sortBy === SORTBY.RELEVANCE) {
    return sortByRelevance(courses, sortByRelevanceCriteria);
  } else*/
  if (sortBy === SORTBY.UPCOMING) {
    return sortByUpcoming(courses, userProfile);
  } else {
    return courses.sort((a, b) => compareCourses(a, b, sortBy));
  }
}

export const compareCourses = (a: Course, b: Course, sortBy: string, coursesMap?: CoursesMap) => {
  // const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);

  //L1s always have priority
  if (isL1Course(a) !== isL1Course(b)) {
    return isL1Course(a) ? -1 : 1;
  }

  switch (sortBy) {
    case SORTBY.RATING:
      return a.rating !== b.rating ? b.rating - a.rating : compareCourses(a, b, SORTBY.RECOMMANDATION);
    case SORTBY.MANDATORY:
      return isMandatory(a) === isMandatory(b) ? a.weight - b.weight : (isMandatory(a) ? -1 : 1);
    case SORTBY.MANDATORY_DESC:
      return b.mandatory - a.mandatory
    case SORTBY.NEW:
      const orderNew = a?.isNew === b?.isNew ? compareDates(a.startDate, b.startDate) : (!!a?.isNew ? -1 : 1);
      if (orderNew !== 0) {
        return orderNew;
      } else {
        return compareCourses(a, b, SORTBY.TITLE_AZ);
      }
    case SORTBY.SHORTEST:
      return a.duration < b.duration ? -1 : 1;
    case SORTBY.TITLE_AZ:
      return a.courseFullName?.toLowerCase().trim().localeCompare(b.courseFullName?.toLowerCase().trim());
    case SORTBY.TITLE_ZA:
      return b.courseFullName?.toLowerCase().trim().localeCompare(a.courseFullName?.toLowerCase().trim());
    case SORTBY.START_DATE:
      return compareDates(a.startDate, b.startDate);
    case SORTBY.START_DATE_LIVESTREAM:
      const liveInfoA = a.liveInfo?.[0];
      const liveInfoB = b.liveInfo?.[0];
      const differenceDates = compareDates(liveInfoA.timeStart, liveInfoB.timeStart);
      return differenceDates !== 0 ? differenceDates : compareCourses(a, b, SORTBY.RECOMMANDATION);
    case SORTBY.START_DATE_LIVESTREAM_ASC:
      const liveInfoAasc = a.liveInfo?.[0];
      const liveInfoBasc = b.liveInfo?.[0];
      const differenceDatesAsc = compareDates(liveInfoAasc.timeStart, liveInfoBasc.timeStart, 'asc');
      return differenceDatesAsc !== 0 ? differenceDatesAsc : compareCourses(a, b, SORTBY.RECOMMANDATION);
    case SORTBY.RECOMMANDATION:
      const aAff = a.affinity ? a.affinity : 0;
      const bAff = b.affinity ? b.affinity : 0;
      if (aAff === bAff) {
        return compareCourses(a, b, SORTBY.RELEVANCE);
      }
      return aAff > bAff ? -1 : 1;
    case SORTBY.NOT_COMPLETED:
      //put completed courses at the end
      const aIsCompleted = isCompletedCourse(a) ? 1 : 0;
      const bIsCompleted = isCompletedCourse(b) ? 1 : 0;
      return aIsCompleted - bIsCompleted;
    case SORTBY.IN_PROGRESS:
      //put in progress courses at the beginning
      const aIsInProgress = isToBeContinued(a) ? 1 : 0;
      const bIsInProgress = isToBeContinued(b) ? 1 : 0;
      return bIsInProgress - aIsInProgress;
    case SORTBY.OVERDUE:
      //put overdue courses at the beginning
      const aIsOverdue = isOverdueCourse(a) ? 1 : 0;
      const bIsOverdue = isOverdueCourse(b) ? 1 : 0;
      return bIsOverdue - aIsOverdue;
    case SORTBY.EXPIRING:
      //put expiring courses at the beginning, ordered by increasing dayLeft
      const aDayLeft = isCourseExpiring(a) ? a?.dayLeft : 100;
      const bDayLeft = isCourseExpiring(b) ? b?.dayLeft : 100;
      if (aDayLeft === bDayLeft) {
        return 0;
      }
      return aDayLeft > bDayLeft ? 1 : -1;
    case SORTBY.TO_START:
      //put courses to start at the beginning
      const aIsToStart = isCourseToBeStarted(a) ? 1 : 0;
      const bIsToStart = isCourseToBeStarted(b) ? 1 : 0;
      return bIsToStart - aIsToStart;
    case SORTBY.COMPLETION:
      return compareDates(a.courseCompletionDate, b.courseCompletionDate);
    case SORTBY.RELEVANCE:
      if (a.weight === b.weight) {
        return compareCourses(a, b, SORTBY.NEW);
      }
      return a.weight - b.weight;
    case SORTBY.RELEVANCE_FAKE:
      return 0;
    case SORTBY.RELEVANCE_SEARCH:
      const aOrder = getSearchPriority(a);
      const bOrder = getSearchPriority(b);
      return aOrder - bOrder;
    case SORTBY.DONE_FOR_LAST:
      const aCompleted = isCompletedCourse(a) ? 1 : 0;
      const bCompleted = isCompletedCourse(b) ? 1 : 0;
      return aCompleted - bCompleted;
    case SORTBY.PURCHASE_DATE:
      const aBuyedDate = isPurchased(a) ? a.buyedDate : undefined;
      const bBuyedDate = isPurchased(b) ? b.buyedDate : undefined;
      const resultPurchaseDate = compareDates(aBuyedDate, bBuyedDate);
      return resultPurchaseDate !== 0 ? resultPurchaseDate : compareCourses(a, b, SORTBY.RELEVANCE);
    case SORTBY.PRICE_ASC:
      const comparePricesAsc = comparePrices(+a.price, +b.price, "asc");
      return comparePricesAsc !== 0 ? comparePricesAsc : compareCourses(a, b, SORTBY.RECOMMANDATION);
    case SORTBY.PRICE_DES:
      const comparePriceDes = comparePrices(+a.price, +b.price);
      return comparePriceDes !== 0 ? comparePriceDes : compareCourses(a, b, SORTBY.RECOMMANDATION);
    case SORTBY.ONBOARDING:
      const isAOnboarding = isCourseOnBoarding(a);
      const isBOnboarding = isCourseOnBoarding(b);

      if (isAOnboarding !== isBOnboarding) {
        return isAOnboarding ? -1 : 1;
      } else {
        return 0;
      }
    case SORTBY.BLOCKED_PROGRAM:
      const isAProgramBlocked = isProgramBlocked(a, coursesMap);
      const isBProgramBlocked = isProgramBlocked(b, coursesMap);

      if (isAProgramBlocked !== isBProgramBlocked) {
        return isBProgramBlocked ? -1 : 1;
      } else {
        return 0;
      }
    case SORTBY.COMPLEXITY_DESC:
      const firstNum = +a.complexity.complexity?.slice(1);
      const secondNum = +b.complexity.complexity?.slice(1);
      return secondNum - firstNum
    case SORTBY.COURSE_STARTED:
      return +isStartedLp(b, coursesMap) - +isStartedLp(a, coursesMap);
    case SORTBY.MASTER_PRIORITY:
      const isAMaster = isCourseMaster(a);
      const isBMaster = isCourseMaster(b);

      if (isAMaster !== isBMaster) {
        return isAMaster ? -1 : 1;
      } else {
        return 0;
      }
    default:
      return a.weight - b.weight;
  }
}

{/* LEON 4576 -- DELETING OF THE BOOST BY CONTENT TYPE */ }
export const getSearchPriority = (course: Course) => {
return 1;
} 

export const sortByUpcoming = (courses: Array<Course>, userProfile: UserProfile): Array<Course> => {
  if (!courses || courses.length < 1) {
    return [];
  }

  return courses.sort((a, b) => {
    const isUpcomingA = isUpcomingCourse(a) || isLivestreamNotYetFinished(a, userProfile);
    const isUpcomingB = isUpcomingCourse(b) || isLivestreamNotYetFinished(b, userProfile);

    let returnValue = 0;
    if (!isUpcomingA && !isUpcomingB) {
      returnValue = 0;
    } else if (!isUpcomingA) {
      returnValue = 1;
    } else if (!isUpcomingB) {
      returnValue = -1;
    } else {
      let dateA = 0;
      let dateB = 0;

      //if VC
      if (isVirtualClassroom(a) && a.courseSessions[0]) {
        dateA = new Date(a.courseSessions[0].minStartDate).getTime();
      }
      if (isVirtualClassroom(b) && b.courseSessions[0]) {
        dateB = new Date(b.courseSessions[0].minStartDate).getTime();
      }

      //if livestream
      if (isLivestreamCourse(a) && a.liveInfo?.[0]?.timeStart) {
        dateA = new Date(a.liveInfo[0].timeStart).getTime();
      }
      if (isLivestreamCourse(b) && b.liveInfo?.[0]?.timeStart) {
        dateB = new Date(b.liveInfo[0].timeStart).getTime();
      }

      returnValue = dateA - dateB;
    }

    if (returnValue === 0) {
      returnValue = compareCourses(a, b, SORTBY.START_DATE);
    }
    if (returnValue === 0) {
      returnValue = compareCourses(a, b, SORTBY.TITLE_AZ);
    }

    return returnValue;
  });
}

export const sortStatusFilters = (statuses: Array<string>): Array<string> => {
  if (!statuses) {
    return [];
  }

  return statuses.sort((a, b) => getStatusSortWeight(a) - getStatusSortWeight(b));
}

export const getStatusSortWeight = (status: string): number => {
  switch (status) {
    case "new":
      return 0;
    case "toBeStarted":
      return 1;
    case "scheduled":
      return 2;
    case "toBeContinued":
      return 3;
    case "completed":
      return 4;
    case "overdue":
      return 5;
    case "missed":
      return 6;
    case "booked":
      return 7;
    default:
      return 8;
  }
}

export const sortDurationFilters = (durations: Array<string>): Array<string> => {
  if (!durations) {
    return [];
  }

  return durations.sort((a, b) => getDurationSortWeight(a) - getDurationSortWeight(b));
}

export const getDurationSortWeight = (durationId: string): number => {
  const durationObj: DurationFilter = getDurationFilterById(durationId);
  if (!durationObj) {
    return -1;
  }

  return durationObj.index;
}

export const sortScheduleFilters = (schedules: Array<string>): Array<string> => {
  if (!schedules) {
    return [];
  }

  return schedules.sort((a, b) => {
    let indexA: number = SESSION_SCHEDULE[a] ? SESSION_SCHEDULE[a].index : 0;
    let indexB: number = SESSION_SCHEDULE[b] ? SESSION_SCHEDULE[b].index : 0;

    return indexA - indexB;
  });
}

export const sortTrainers = (trainers: Array<Trainer>): Array<Trainer> => {
  if (!trainers) {
    return [];
  }

  return trainers.sort((a, b) => {
    return getTrainerFullName(a).toLowerCase().localeCompare(getTrainerFullName(b).toLowerCase());
  });
}



export const sortByRelevance = (courses: Array<Course>, sortCriteria): Array<Course> => {
  if (!sortCriteria || sortCriteria.length <= 0 || !courses) {
    return courses;
  }

  let sortedCourses = [...courses];

  //sort criteria by index
  sortCriteria = sortCriteria.sort((a, b) => a.index - b.index);

  for (const sortCriterion of sortCriteria) {
    switch (sortCriterion.operator) {
      case 'SORT':
        sortedCourses = sortedCourses.sort((a, b) => sortByTypeAttribute(a, b, sortCriterion));
        break;
      case 'AND':
      case 'OR':
      case 'NOT':
      case '==':
      case '>':
      case '>=':
      case '<':
      case '<=':
      case 'contains':
        sortedCourses = sortedCourses.sort((a, b) => sortByBooleanArithmeticOperator(a, b, sortCriterion));
        // sortedCourses = sortedCourses.filter((course) => checkCondition(course, sortCriterion));
        break;
      default:
    }
  }

  return sortedCourses;
};

const sortByTypeAttribute = (a, b, sortCriterion) => {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  switch (sortCriterion.type) {
    case 'number':
      return compareNumberByAttr(a, b, sortCriterion);
    case 'string':
      return compareStringByAttr(a, b, sortCriterion);
    case 'date':
      return compareDateByAttr(a, b, sortCriterion);
    default:
      return 0;
  }
};

const compareNumberByAttr = (a, b, sortCriterion) => {
  const attr = sortCriterion.attribute;
  if (typeof a[attr] != 'number') {
    console.log('The attr ' + attr + ' is not a number in the course ' + a.courseIdMaster, a);
    return 0;
  }
  if (typeof b[attr] != 'number') {
    console.log('The attr ' + attr + ' is not a number in the course ' + b.courseIdMaster, b);
    return 0;
  }

  switch (sortCriterion.orderBy) {
    case 'asc': return a[attr] - b[attr];
    case 'desc': return b[attr] - a[attr];
    default: return 0;
  }
};

const compareStringByAttr = (a, b, sortCriterion) => {
  const attr = sortCriterion.attribute;
  if (typeof a[attr] != 'string') {
    console.log('The attr ' + attr + ' is not a string in the course ' + a.courseIdMaster, a);
    return 0;
  }
  if (typeof b[attr] != 'string') {
    console.log('The attr ' + attr + ' is not a string in the course ' + b.courseIdMaster, b);
    return 0;
  }

  switch (sortCriterion.orderBy) {
    case 'asc': return a[attr].toLowerCase().localeCompare(b[attr].toLowerCase());
    case 'desc': return b[attr].toLowerCase().localeCompare(a[attr].toLowerCase());
    default: return 0;
  }
};

const compareDateByAttr = (a, b, sortCriterion) => {
  const attr = sortCriterion.attribute;
  const aDate = new Date(a[attr]);
  const bDate = new Date(b[attr]);
  // console.log('typeof a should be date '+attr, typeof aDate);
  // if(typeof aDate != 'date') {
  //   console.log('The attr ' + attr + ' is not a date in the course ' + a.courseIdMaster, a);
  //   return 0;
  // }
  // if(typeof bDate != 'date') {
  //   console.log('The attr ' + attr + ' is not a date in the course ' + b.courseIdMaster, b);
  //   return 0;
  // }

  switch (sortCriterion.orderBy) {
    case 'asc': return aDate.getTime() - bDate.getTime();
    case 'desc': return bDate.getTime() - aDate.getTime();
    default: return 0;
  }
};

const sortByBooleanArithmeticOperator = (a, b, sortCriterion) => {
  const aCond = checkCondition(a, sortCriterion) ? 1 : 0;
  const bCond = checkCondition(b, sortCriterion) ? 1 : 0;

  return bCond - aCond;
}

const checkCondition = (course, sortCriterion) => {
  if (!course) {
    return false;
  }

  switch (sortCriterion.operator) {
    case 'AND':
    case 'OR':
      let left = null;
      let right = null;
      for (let i = 0; i < sortCriterion.elements.length - 1; i++) {
        if (i === 0) {
          left = checkCondition(course, sortCriterion.elements[i]);
        }
        right = checkCondition(course, sortCriterion.elements[i + 1]);
        left = checkBooleanCondition(left, right, sortCriterion);
      }

      return left;

    case 'NOT':
      let leftNot = checkCondition(course, sortCriterion.elements[0]);
      return checkBooleanCondition(leftNot, null, sortCriterion);

    case '==':
    case '>':
    case '>=':
    case '<':
    case '<=':
    case '==':
    case 'contains':
      return checkConditionByTypeAttribute(course, sortCriterion);

    default:
      return false;
  }
}

const checkBooleanCondition = (left, right = null, sortCriterion) => {
  switch (sortCriterion.operator) {
    case 'AND':
      return left && right;
    case 'OR':
      return left || right;
    case 'NOT':
      return !left;
    default:
      return false;
  }
}

const checkConditionByTypeAttribute = (course, sortCriterion) => {
  switch (sortCriterion.type) {
    case 'number':
      return compareNumberToValue(course, sortCriterion);
    case 'string':
      return compareStringToValue(course, sortCriterion);
    // case 'date':
    //   return compareNumberToValue(course, sortCriterion);
    case 'array':
      return compareArrayToValue(course, sortCriterion);
    default:
      return false;
  }
}

const compareNumberToValue = (course, sortCriterion) => {
  const attr = sortCriterion.attribute;
  if (typeof course[attr] != 'number') {
    console.log('The attr ' + attr + ' is not a number in the course ' + course.courseIdMaster, course);
    return false;
  }

  switch (sortCriterion.operator) {
    case '==':
      return course[attr] == sortCriterion.value;
    case '>':
      return course[attr] > sortCriterion.value;
    case '>=':
      return course[attr] >= sortCriterion.value;
    case '<':
      return course[attr] < sortCriterion.value;
    case '<=':
      return course[attr] <= sortCriterion.value;
    default:
      return false;
  }


};

const compareStringToValue = (course, sortCriterion) => {
  const attr = sortCriterion.attribute;
  if (typeof course[attr] != 'string') {
    console.log('The attr ' + attr + ' is not a string in the course ' + course.courseIdMaster, course);
    return false;
  }

  switch (sortCriterion.operator) {
    case '==':
      return course[attr].toLowerCase() == sortCriterion.value.toLowerCase();
    case '>':
      return course[attr].toLowerCase().localeCompare(sortCriterion.value.toLowerCase()) > 0 ? true : false;
    case '>=':
      return course[attr].toLowerCase().localeCompare(sortCriterion.value.toLowerCase()) >= 0 ? true : false;
    case '<':
      return course[attr].toLowerCase().localeCompare(sortCriterion.value.toLowerCase()) < 0 ? true : false;
    case '<=':
      return course[attr].toLowerCase().localeCompare(sortCriterion.value.toLowerCase()) <= 0 ? true : false;
    case 'contains':
      return course[attr].toLowerCase().includes(sortCriterion.value.toLowerCase());
    default:
      return false;
  }


};

const compareArrayToValue = (course, sortCriterion) => {
  const attr = sortCriterion.attribute;

  if (!Array.isArray(course[attr])) {
    console.log('The attr ' + attr + ' is not an array in the course ' + course.courseIdMaster, course);
    return false;
  }

  switch (sortCriterion.operator) {
    case 'contains':
      return course[attr].includes(sortCriterion.value);
    default:
      return false;
  }


};

export const sortBrands = (brands: Array<Brand>, firstHouseBrands = false): Array<Brand> => {
  if (!brands) {
    return [];
  }

  let sortedBrands = brands;
  //now brands are sorted just by name 
  sortedBrands = orderBy(sortedBrands, ["name"]);

  //before brands were sorted by rank and name giving priority to house brands
  // sortedBrands = orderBy(sortedBrands, ["rank", "name"]);

  // if (firstHouseBrands) {
  //   sortedBrands = sortedBrands.sort((a, b) => {
  //     const isAhb = a.type === BRAND_TYPE.HOUSE_BRAND ? 1 : 0;
  //     const isBhb = b.type === BRAND_TYPE.HOUSE_BRAND ? 1 : 0;

  //     return isBhb - isAhb;
  //   })
  // }

  return sortedBrands;
}

const TP_COLLECTION_SORT_WEIGHT = {
  [TP_COLLECTION.NEW]: 0,
  [TP_COLLECTION.CARRYOVER]: 1,
}
export const sortTPCollection = (collections: AccordionListElement[]): AccordionListElement[] => {
  if (!collections || collections.length === 0) {
    return [];
  }

  return collections.sort((a, b) => TP_COLLECTION_SORT_WEIGHT[a.id] - TP_COLLECTION_SORT_WEIGHT[b.id]);
}


const TP_CHARACTERISTICS_SORT_WEIGHT = {
  [TP_CHARACTERISTICS.NEW_PICK_FOR_YOU]: 0,
  [TP_CHARACTERISTICS.ADVERTISING]: 1,
  [TP_CHARACTERISTICS.BESTSELLER]: 2,
  [TP_CHARACTERISTICS.RXABLE]: 3,
}
export const sortTPCharacteristics = (characteristics: AccordionListElement[]): AccordionListElement[] => {
  if (!characteristics || characteristics.length === 0) {
    return [];
  }

  return characteristics.sort((a, b) => TP_CHARACTERISTICS_SORT_WEIGHT[a.id] - TP_CHARACTERISTICS_SORT_WEIGHT[b.id]);
}


export const sortExpirations = (expirationKeys: Array<string>): Array<string> => {
  if (!expirationKeys) {
    return [];
  }

  return expirationKeys?.sort((a, b) => EXPIRATION?.[a]?.index - EXPIRATION?.[b]?.index);

}
