import React, { useEffect, useRef, useState } from "react";
import Modal from "./UI/Modal";
import { Course, LangMap } from "@model/CoursesClass";
import * as actions from "@redux-actions/";
import { connect, ConnectedProps } from "react-redux";
import ButtonV2 from "@components/UI/ButtonV2";
import RadioButtonGroup from "./UI/RadioButtonGroup";
import { UserProfile } from "@model/User";
import {
  SurveyQA,
  SURVEY_QUESTION_TYPE,
  SurveyQuestionResponse,
  SURVEY_TYPE,
} from "@model/Survey";
import CardLoader from "./UI/CardLoader";
import { SurveyResponse } from "@model/SurveyResponse";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import ErrorMessage from "./UI/ErrorMessage";
import { getRatingSurveyType, isLearningPath } from "@utility/Api";
import { RootState } from "src/store/store";

type RatingModalProps = PropsFromRedux & {
  lang: LangMap;
  userProfile: UserProfile;
  goBack: () => void;
  courseName: string;
  course: Course;
  setRating?: (rating: number) => void;
  show: boolean;
  toggleRatingModal: (val: boolean, course: Course | null) => void;
  getSurveyQuestions: (language: string, surveyType: string) => void;
  isLoadingRating: boolean;
  ratingQuestions: SurveyQA;
  sendSurveyAnswers: (answers: SurveyResponse, surveyType: string) => void;
  isSendingRating: boolean;
  sendRatingSuccess: boolean;
  resetRatingModal: () => void;
};

type FormValues = {
  [key: string]: number;
};

export const RatingModal = ({
  userProfile,
  goBack,
  course,
  courseName,
  setRating,
  show,
  toggleRatingModal,
  getSurveyQuestions,
  isLoadingRating,
  ratingQuestions,
  sendSurveyAnswers,
  isSendingRating,
  sendRatingSuccess,
  resetRatingModal,
  lang,
}: RatingModalProps): React.ReactElement => {
  const { control, handleSubmit, errors } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    console.log("onSubmit", data);

    let surveyResponse = new SurveyResponse();
    surveyResponse.compilationDate = new Date().getTime();
    surveyResponse.courseId = course.courseIdMaster;
    surveyResponse.id =
      ratingQuestions.surveyResponses && ratingQuestions.surveyResponses[0]
        ? ratingQuestions.surveyResponses[0].id
        : null;
    surveyResponse.surveyId = ratingQuestions.survey.id;
    surveyResponse.username = userProfile.username;
    surveyResponse.userResponses = Object.keys(data).map((questionId) => {
      return { questionId: parseInt(questionId), responseId: data[questionId] };
    });

    sendSurveyAnswers(surveyResponse, getRatingSurveyType(course));
  };

  useEffect(() => {
    getSurveyQuestions(
      userProfile?.preferredLang ? userProfile?.preferredLang : "en",
      getRatingSurveyType(course)
    );
  }, []);

  useEffect(() => {
    if (sendRatingSuccess) {
      toggleRatingModal(true, { ...course, isStepRatingPassed: true });
    }
  }, [sendRatingSuccess]);

  useEffect(
    () => () => {
      resetRatingModal();
    },
    []
  );

  const textCompletationStatus = (ctype: string): JSX.Element | null => {
    switch (ctype) {
      case "classroom":
      case "interactive":
      case "pdf":
      case "video":
        return (
          <span className="rating-modal__rating__text">
            {lang.RATING_MODAL_COMPLETATION_ALERT}
          </span>
        );
      case "self_learning_lesson":
      case "external_content":
      case "podcast":
        return (
          <span className="rating-modal__rating__text">
            {lang.RATING_MODAL_COMPLETATION_MORE_THAN_FIVE_ALERT}
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div className="rating-modal">
      <Modal
        close={goBack}
        hideX
        blur="always"
        blurIntensity="07"
        contentClassName="modal__content--small"
        show={show}
      >
        <div className="rating-modal__wrapper">
          <header>
            <span className="rating-modal__title mobile">
              {courseName.toUpperCase()}
            </span>
          </header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rating-modal__content">
              <span className="rating-modal__title desktop">
                {courseName.toUpperCase()}
              </span>
              <div className="rating-modal__subtitle">
                {isLearningPath(course)
                  ? lang.RATE_COURSE_TITLE
                  : lang.RATE_LESSON_TITLE.replace(/\s+/g, " ")}
              </div>
              <div className="rating-modal__rating">
                {isLoadingRating ? (
                  <CardLoader />
                ) : (
                  <>
                    {ratingQuestions?.survey?.questions ? (
                      ratingQuestions?.survey?.questions.map(
                        (question, index) => (
                          <React.Fragment key={question.id}>
                            {question.questionType ===
                              SURVEY_QUESTION_TYPE.SINGLE_CHOICE && (
                              <div className="rating-modal__rating__wrapper">
                                <div className="rating-modal__rating__title">
                                  {question.questionInLanguage}
                                </div>
                                <div className="rating-modal__rating__content">
                                  <div className="rating-modal__rating__box">
                                    <Controller
                                      defaultValue={null}
                                      name={question.id.toString()}
                                      control={control}
                                      rules={{
                                        required:
                                          lang.REQUIRED_VALIDATION as string,
                                      }}
                                      render={(props) => (
                                        <RadioButtonGroup
                                          items={question.responses.map((a) => {
                                            return {
                                              value: a.id,
                                              label: a.responseInLanguage,
                                              checked: false,
                                            };
                                          })}
                                          handleOnClick={(val) =>
                                            props.onChange(val)
                                          }
                                          checked={props.value}
                                          isRating
                                          fontSize="14px"
                                          iconBig
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      error={errors[question.id.toString()]}
                                    />
                                    {textCompletationStatus(course.ctype[0])}
                                  </div>

                                  {index ===
                                    ratingQuestions?.survey?.questions.length -
                                      1 && (
                                    <div className="ecommerce-alert-modal__footer">
                                      <ButtonV2
                                        variant="primary"
                                        small
                                        loading={isSendingRating}
                                      >
                                        {lang.SUBMIT_SURVEY}
                                      </ButtonV2>
                                      <ButtonV2
                                        variant="secondary"
                                        small
                                        handleClick={goBack}
                                      >
                                        {lang.CANCEL}
                                      </ButtonV2>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <div className="ecommerce-alert-modal__footer">
                        <ButtonV2
                          variant="secondary"
                          small
                          handleClick={goBack}
                        >
                          {lang.CANCEL}
                        </ButtonV2>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
    userProfile: state.user.userProfile,
    isLoadingRating: state.utils.isLoadingRating,
    ratingQuestions: state.utils.ratingQuestions,
    isSendingRating: state.utils.isSendingRating,
    sendRatingSuccess: state.utils.sendRatingSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRatingModal: (val, course) =>
      dispatch(actions.toggleRatingModal(val, course)),
    getSurveyQuestions: (language, surveyType) =>
      dispatch(actions.getSurveyQuestions(language, surveyType)),
    sendSurveyAnswers: (answers, surveyType) =>
      dispatch(actions.sendSurveyAnswers(answers, surveyType)),
    resetRatingModal: () => dispatch(actions.resetRatingModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RatingModal);
