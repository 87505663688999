// VisionCareMenuModal component displays a menu for selecting vision care courses.
// It fetches and organizes course data based on selected criteria.

import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import useL1List from '@hooks/useL1List';
import ButtonV2 from './UI/ButtonV2';
import LazySVG from './LazySvg';
import { getBrandPageUrl, isCourseVisible, isHighlighted } from '@utility/Api';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import CardController from './CardV3/CardController';
import TextSkeleton from './skeletons/TextSkeleton';
import HeaderMenu from './UI/HeaderMenu';
import { Course, LangMap } from '@model/CoursesClass';
import { purifyItemDataElementId, purifyItemDataElementIdCamelCase } from '@utility/analytics-utils';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { HighlightsThirdColumn } from './collections/CollectionsMenu';
import useModalTypes from '@hooks/useModalByType';
import camelCase from 'lodash/camelCase';
import { buildClickDataTrackingObject } from '@model/TrackingClass';

const VisionCareMenuModal = ({ show }: { show: boolean }) => {
const l1VisionCare = "vision_care";

  // Redux state selectors
  const lang = useSelector(state => state.utils.lang);
  const coursesMap = useSelector(state => state.course.coursesMap);
  const modalTypes = useModalTypes();
  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);

  // Hook to handle breakpoints
  const breakpoint = useBreakpointBoolean();

  // Custom hook to fetch and manage L1 list data
  const [l1List, l0list, selectedL0, setSelectedL0] = useL1List(null, true);

  // State variables
  const [selectedL0Full, setSelectedL0Full] = useState(null);
  const [coursesToShow, setCoursesToShow] = useState([]);
  const l1Map = useSelector(state => state.utils.l1Map);
  const [selectedL1, setSelectedL1] = useState(null);
  const [selectedL1Full, setSelectedL1Full] = useState(null);

  // Effect to update selected L0 details
  useEffect(() => {
    let selectedL0FullTemp = {
      code: '',
    };
    if (selectedL0) {
      selectedL0FullTemp = l0list.find(a => a.code === selectedL0);
    }
    setSelectedL0Full(selectedL0FullTemp);
  }, [selectedL0]);

  // Effect to update selected L1 details
  useEffect(() => {
    if(l1List){
      if (selectedL1) {
        for (let l1 in l1Map){
          if(l1 === selectedL1 ) setSelectedL1Full(l1Map[l1]);
        }
      } else {
        for (let l1 in l1Map){
          if(l1 === l1List[l1VisionCare]?.[0]?.value ){
            setSelectedL1Full(l1Map[l1]);
          } 
          setSelectedL1(l1List[l1VisionCare]?.[0]?.value);
        }
      }
    }
  }, [selectedL1,l1List]);

  // Effect to update courses to show based on selected criteria
  useEffect(() => {
    let coursesToShowTemp = [];

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === l1VisionCare);

      // Sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }

    setCoursesToShow(coursesToShowTemp);
  }, [selectedL0, coursesMap]);

  // Function to close modal
  const closeModal = () => {
    modalTypes?.close();
  };

  return (
    <HeaderMenu
      show={show}
      closeModal={closeModal}
      closeDataId={`mainnav_visionCare${
        selectedL0 ? '_' + purifyItemDataElementId(selectedL0) : ''
      }_close`}
      firstColumn={
        <FirstColumn
          isGetCourseCompleted={isGetCourseCompleted}
          l1List={l1List}
          lang={lang}
          closeModal={closeModal}
          setSelectL1={setSelectedL1}
          selectedL1={selectedL1}
          l1VisionCare={l1VisionCare}
        />
      }
      secondColumn={
        <SecondColumn
          isGetCourseCompleted={isGetCourseCompleted}
          selectedL0Full={selectedL0Full}
          setSelectedL0={setSelectedL0}
          l0list={l0list}
          lang={lang}
          selectedL1Full={selectedL1Full}
          setSelectL1={setSelectedL1}
        />
      }
      thirdColumn={
        <ThirdColumn
          courses={coursesToShow}
          isDesktop={breakpoint.isDesktop}
          closeModal={closeModal}
          l1={selectedL1Full}
        />
      }
      firstColumnProps={{ key: 'l1_list' }}
      secondColumnProps={{ key: 'description_' + selectedL1Full?.key }}
      thirdColumnProps={{ key: 'highlights_' + selectedL1Full?.key }}
    />
  );
};

export default VisionCareMenuModal;

// Types for FirstColumnProps
type FirstColumnProps = {
  isGetCourseCompleted: boolean;
  l1List: any;
  lang: LangMap;
  closeModal: () => void;
  setSelectL1: any;
  selectedL1: any;
  l1VisionCare: any;
};

// FirstColumn component
const FirstColumn = ({
  isGetCourseCompleted,
  l1List,
  lang,
  closeModal,
  setSelectL1,
  selectedL1,
  l1VisionCare  
}: FirstColumnProps) =>
  isGetCourseCompleted ? (
    <div className="area-of-expertise__l0-list">
      <span className="area-of-expertise__l1-title-visionCare">{lang.VISION_CARE_TITLE}</span>
      <div className="area-of-expertise__separator" />
      {l1List[l1VisionCare]?.map(channel => (
        <ButtonV2
          key={channel.value}
          variant="text-btn-dotted"
          className={
            'area-of-expertise__l0-button ' +
            (channel.value === selectedL1 ? 'l0-button--selected' : '')
          }
          handleClick={() =>{ buildClickDataTrackingObject("mainNav_"+camelCase(lang.VISION_CARE_TITLE)+"_"+camelCase(channel.label)); setSelectL1(channel.value)}}
        >
          {channel.label}
        </ButtonV2>
      ))}
    </div>
  ) : (
    <SkeletonL1List />
  );

// Types for SecondColumnProps
type SecondColumnProps = {
  isGetCourseCompleted: boolean;
  selectedL0Full: any;
  lang: LangMap;
  l1List: any;
  closeModal: () => void;
  selectedL1Full: any;
  setSelectL1: any;
};

// SecondColumn component
const SecondColumn = ({
  isGetCourseCompleted,
  selectedL0Full,
  lang,
  selectedL1Full,
  setSelectL1
}: SecondColumnProps) =>
  isGetCourseCompleted ? (
    selectedL1Full &&
    (selectedL0Full.code === '' ? (
      <span className="area-of-expertise__l1-description">{lang?.AREAS_OF_EXPERTISE_DESCR}</span>
    ) : (
      <>
        <span className="area-of-expertise__l1-title">{selectedL1Full.label}</span>
        <div className="area-of-expertise__l1-list">
          <span className="area-of-expertise__l1-description">
            {selectedL1Full.description}
          </span>
          <ButtonV2
            className={'area-of-expertise__l1-button area-of-expertise__l1-channel'}
            to={getBrandPageUrl("channel", selectedL1Full.key)}
            handleClick={() =>{ buildClickDataTrackingObject("mainNav_"+camelCase(lang.VISION_CARE_TITLE)+"_"+ camelCase(selectedL1Full.label)+"_"+camelCase(lang.VIEW_ALL))}}
          >
            {lang.VIEW_ALL}
            <LazySVG
              className="area-of-expertise__l1-channel-arrow"
              src={'/images/svg-icons/arrow-right5.svg'}
              alt={selectedL0Full.label}
            />
          </ButtonV2>
        </div>
      </>
    ))
  ) : (
    <SkeletonL1List />
  );

// ThirdColumn remains unchanged

// Skeleton components remain unchanged

const ThirdColumn = ({
  courses,
  isDesktop,
  closeModal,
  l1
}: {
  courses: Course[];
  isDesktop: boolean;
  closeModal: () => void;
  l1: any;
}) =>
  isDesktop ? (
    <>
      {courses?.map(course => (
        <CardController
          course={course}
          variant={isDesktop ? 'card-areaofexpertise' : 'card-highlights-menu'}
          key={course.courseIdMaster}
          elementPosition={0}
          clickObjectTracking ={("visionCare_"+camelCase(l1?.label))}
        />
      ))}
    </>
  ) : (
    <HighlightsThirdColumn courses={courses} closeModal={closeModal} />
  );

// SkeletonL1 component for rendering a skeleton loading animation
const SkeletonL1 = ({ width = 200 }) => {
  return (
    <TextSkeleton
      className="area-of-expertise__l1-skeleton"
      borderRadius={5}
      width={width}
      height={24}
      lightTheme={true}
    />
  );
};

// SkeletonL1List component for rendering a list of skeleton loading animations
const SkeletonL1List = () => {
  return (
    <>
      <SkeletonL1 width={275} />
      <SkeletonL1 width={323} />
      <SkeletonL1 width={300} />
      <SkeletonL1 width={243} />
    </>
  );
};
