export class AccordionListElement {
    id: string;
    title: string;
    labelMobile?: string;
    checked: boolean = false;
    disabled: boolean = false;
    toggleCheck: (value?) => void;
    order?: any;
    month?: string;
    year?: string;
    complexityId?: string;
}

export class ComplexityAccordionList {
    elements: AccordionListElement[];
    order: number;
    title: string;
    category: string;
}