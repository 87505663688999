import { BrandsList } from "@model/Brand";
import { LangMapTP } from "@model/CoursesClass";
import { ProductCloseUp, ProductCMS, ProductNOADVCampaignImages, ResponseGetProducts, ResponseGetProductsFilter, ResponseGetProductsItems, SingleWeekSummary, SHIPMENT_DATES, SHIPMENT_MODELS, SORTBY_TP_ELEMENTS, TP_CATEGORY, TP_CHARACTERISTICS, TP_COLLECTION, TrainingPill, TrainingPillResponse, FormattedSingleWeekSummary, ResponseGetFilterItems } from "@model/ProductClass";
import { UserProfile } from "@model/User";
import { RootState } from "src/store/store";
import { getFallbackImage } from "./Api";
import { FALLBACK_IMAGE_COVER_URL } from "./const";
import { USER_URLS } from "@components/link-utils";


export const FALLBACK_IMAGE_TP_URL = FALLBACK_IMAGE_COVER_URL + "placeholder-tp-3x.jpg?imwidth=300"
export const FALLBACK_IMAGE_TP_TRANSPARENT_URL = FALLBACK_IMAGE_COVER_URL + "placeholder-tp-3x-transparent.png?imwidth=300"

export const isUserEnabledForTrainingPills = (userProfile: UserProfile): boolean => {
    return !!userProfile?.pillsRegion;
}

export const handleTrainingPillsImages = (pills: TrainingPill[]): TrainingPill[] => {
    if (!pills) {
        return [];
    }

    for (const index in pills) {
        //remove url params
        pills[index].image = pills[index].image?.includes('?') ? pills[index].image?.split('?')[0] : '';

        //assign fallback image
        pills[index].fallbackImage = getFallbackImage((+index % 4) + 1);
    }

    return pills;
}

export const getBagdesFromPill = (pill: TrainingPill, lang: LangMapTP, isModal: boolean = false, location) => {

    let badges: (string[] |string)[] = [];

    if (pill) {
        if(pill?.brandCampaign && pill?.brandCampaign?.length > 0 && location !== USER_URLS.HOMEPAGE.URL) {
            badges.push(pill?.brandCampaign)
        }

        if (isModal && pill.newPicksForYou) {
            badges.push(lang.TP_NEW_PICKS_FOR_YOU);
        }

        if (pill.new) {
            badges.push(lang.TP_NEW);
        }
        if (pill.advertising) {
            badges.push(lang.ADVERTISING);
        }
        if (pill.bestSeller) {
            badges.push(lang.BESTSELLER);
        }
        if (pill.rxAble) {
            badges.push(lang.RXABLE);
        }
    }

    return badges;
}

export const getBagdesFromPillCMS = (pill: TrainingPill, pillCMS: ProductCMS, lang: LangMapTP, isModal: boolean = false) => {
    if (!lang) {
        return [];
    }

    let badges: (string | string[])[] = [];

    if (pill) {
        badges = getBagdesFromPill(pill, lang, isModal, location.pathname);
    }

    if (pillCMS?.polarized) {
        badges.push(lang.POLARIZED);
    }
    if (pillCMS?.adv) {
        badges.push(lang.ADV);
    }

    return badges;
}

export const getADVBrandVideo = (pill: ProductCMS): { landscape: ProductCloseUp, portrait: ProductCloseUp } => {
    if (pill?.pillsFieldsCms?.AdvBrandVideo?.length > 0) {
        //get only videos
        let videos = pill.pillsFieldsCms.AdvBrandVideo.filter((v) => v?.contentType === "CMVideo");

        if (videos?.length > 0) {
            //split landscape videos and portrait videos
            let videosLandscape = videos.filter(a => {
                const dimensions = a.extendedFields?.dimension?.split('x');
                return +dimensions[0] >= +dimensions[1];
            });
            let videosPortrait = videos.filter(a => {
                const dimensions = a.extendedFields?.dimension?.split('x');
                return +dimensions[0] < +dimensions[1];
            });

            let videoLandscape = null;
            if (videosLandscape?.length > 0) {
                //sort videos by greater bitrate
                videosLandscape = videosLandscape.sort(sortByBitRate);

                //get the video with the gratest bitrate
                videoLandscape = videosLandscape[0];
            }

            let videoPortrait = null;
            if (videosPortrait?.length > 0) {
                //sort videos by greater bitrate
                videosPortrait = videosPortrait.sort(sortByBitRate);

                //get the video with the gratest bitrate
                videoPortrait = videosPortrait[0];
            }

            if (videoLandscape || videoPortrait) {
                return {
                    landscape: videoLandscape ? videoLandscape : videoPortrait,
                    portrait: videoPortrait ? videoPortrait : videoLandscape,
                };
            }
        }
    }

    return null;
}

const isImageDesktop = (dimensions: string) => {
    if (dimensions) {
        const coordinates = dimensions?.split("x");
        const x = coordinates[0];
        const y = coordinates[1];
        // console.log({ coordinates, x, y })
        if (!isNaN(+x) && !isNaN(+y)) {
            return +x > +y;
        }
    }

    return null;
}

export const getADVCampaignImages = (pill: ProductCMS): { desktop: ProductNOADVCampaignImages[], mobile: ProductNOADVCampaignImages[] } => {
    if (pill?.pillsFieldsCms?.AdvCampaignImage?.length > 0) {
        //get only images
        let images = pill.pillsFieldsCms.AdvCampaignImage.filter((v) => v?.contentType === "CMPicture");

        if (images?.length > 0) {
            //split desktop and mobile images
            //show max 5 images
            let imagesDesktop = images
                .filter(a => isImageDesktop(a.extendedFields?.dimension) === true || isImageDesktop(a.teaserTitle) === true) //before only 100x50 - changed by https://luxotticaretail.atlassian.net/browse/UU-2136
                .slice(0, 5);
            let imagesMobile = images
                .filter(a => isImageDesktop(a.extendedFields?.dimension) === false || isImageDesktop(a.teaserTitle) === false) //before only 30x30 - changed by https://luxotticaretail.atlassian.net/browse/UU-2136
                .slice(0, 5);

            if (imagesDesktop?.length > 0 || imagesMobile?.length > 0) {
                return { desktop: imagesDesktop, mobile: imagesMobile };
            }
        }
    }

    return { desktop: null, mobile: null };
}

export const sortByBitRate = (a: ProductCloseUp, b: ProductCloseUp): number => {
    const aBitRateArray = a.teaserTitle.split('_');
    const aBitRate: number = +aBitRateArray[aBitRateArray.length - 1].replace('mbps', '');

    const bBitRateArray = b.teaserTitle.split('_');
    const bBitRate: number = +bBitRateArray[bBitRateArray.length - 1].replace('mbps', '');

    return bBitRate - aBitRate;
}

export const fetchTrainingPillsHomepage = async () => {
    const pills = await fetch(`/data/training_pills/TP_HP.json`);
    const pillsJson: TrainingPillResponse = await pills.json();
    return pillsJson;
}

export const fetchTrainingPillsBrandCarousel = async () => {
    const pillBrand = await fetch(`/data/training_pills/TP_BRAND.json`);
    const pillBrandJson: TrainingPillResponse = await pillBrand.json();
    return pillBrandJson;
}

export const fetchTrainingPillsDetail = async () => {
    const pillDetail = await fetch(`/data/training_pills/TP_DETAIL.json`);
    const pillDetailJson: ProductCMS[] = await pillDetail.json();
    return pillDetailJson;
}

export const isFiltersItems = <T,>(filtersItems: T | ResponseGetProductsItems): filtersItems is ResponseGetProductsItems => {
    return Boolean(filtersItems && Object.keys(filtersItems as ResponseGetProductsItems)?.length > 0);
}

export const isFilters = <T,>(filters: T | ResponseGetProducts): filters is ResponseGetProducts => {
    return Boolean(filters && Object.keys(filters as ResponseGetProducts)?.length > 0);
}


export const fetchTrainingPillsFilters = async (filtersModalTemp: ResponseGetProductsItems = null) => {
    const filters = await fetch(`/data/training_pills/TP_FILTERS.json`);
    const filtersJson: ResponseGetProducts = await filters.json();
    if (filtersModalTemp) {
        return { ...filtersJson, items: filtersModalTemp };
    }
    return filtersJson;
}

export const fetchTrainingPillsSearchFilters = async (filtersModalTemp: ResponseGetProductsItems = null) => {
    const filters = await fetch(`/data/training_pills/TP_SEARCH_FILTERS.json`);
    const filtersJson: ResponseGetProducts = await filters.json();
    if (filtersModalTemp) {
        return { ...filtersJson, items: filtersModalTemp };
    }
    return filtersJson;
}

export const mergeFilters = (FEfilters: ResponseGetProductsItems | ResponseGetFilterItems[], BEfilters: ResponseGetProducts, brandsList: BrandsList = null, lastSelectedFilterType: string = ''): ResponseGetProducts => {
    let newFilters: ResponseGetProducts = cloneDeep(BEfilters);

    //BE doesn't return sorting so get them from FE
    newFilters.items.sortings = FEfilters?.sortings ? [...FEfilters.sortings] : [];
    newFilters.items.status = [...(FEfilters?.status ? FEfilters.status : [])];
    newFilters.items.orderDate = [...(FEfilters?.orderDate ? FEfilters.orderDate : [] as [])];
    newFilters.items.orderDateType = FEfilters.orderDateType;

    if (FEfilters) {
        for (let key of Object.keys(FEfilters) as Array<keyof ResponseGetProductsItems>) {
            //for all filters, not sortings
            if (key !== 'sortings' && key !== 'status' && key !== 'orderDate' && key !== "orderDateType" && key !== 'marketingTheme2') {
                if (!Array.isArray(FEfilters[key])) {
                    continue;
                }

                //add FE filters not given by BE
                for (let FEfilter of FEfilters[key as string]) {
                    //check if FE filter is present
                    const index = newFilters.items[key as string]?.findIndex(a => a.id === FEfilter.id);

                    //if not present --> add it
                    if (index < 0) {
                        const newFilter = { ...FEfilter };
                        //if it has the same type of the last selected filter --> not change its enable status
                        //if it is checked --> not disable it
                        //otherwise --> add it disabled
                        if (key !== lastSelectedFilterType && !FEfilter.checked) {
                            newFilter.disabled = true;
                        }
                        newFilters.items[key as string].push(newFilter);
                    } else if (FEfilter.checked && newFilters.items[key]?.[index]) {
                        //if present and checked --> set checked = true
                        newFilters.items[key][index].checked = true;
                    }

                }
            }
        }
    }

    //add parent brand if only the children are present
    if (brandsList?.map && newFilters.items?.brands) {
        newFilters.items.brands.forEach(brand => {
            const brandObj = brandsList.map[brand.id];
            //the brand is a child brand?
            if (brandObj && brandObj.brand !== brand.id) {
                //if the parent brand is not already present --> add it
                const brandParentIndex = newFilters.items.brands.findIndex(a => a.id === brandObj.brand);
                if (brandParentIndex < 0) {
                    newFilters.items.brands.push({
                        ...brand,
                        id: brandObj.brand,
                    });
                }
            }
        })
    }

    console.log('mergeFilters', newFilters);
    return newFilters;
}


export const selectIsUserEnabledForTrainingPills = (state: RootState): boolean => {
    const hasTrainingPills = state.trainingPills.trainingPillsHP.length > 0;
    return hasTrainingPills && isUserEnabledForTrainingPills(state.user.userProfile);
};

export const filtersLabel = {
    [SORTBY_TP_ELEMENTS.RECENT_SHIPMENT]: "SORT_MOST_RECENT_SHIPMENT",
    [SORTBY_TP_ELEMENTS.MODEL_CODE_AZ]: "SORT_AZ_MODEL",
    [SORTBY_TP_ELEMENTS.MODEL_CODE_ZA]: "SORT_ZA_MODEL",
    [SORTBY_TP_ELEMENTS.LATEST_RELEASE]: "SORT_LATEST_RELEASE",
    [SORTBY_TP_ELEMENTS.RELEVANCE]: "RELEVANCE_LABEL",
    [TP_COLLECTION.NEW]: "TP_NEW",
    [TP_COLLECTION.CARRYOVER]: "TP_CARRY_OVER",
    [TP_CHARACTERISTICS.ADVERTISING]: "ADVERTISING",
    [TP_CHARACTERISTICS.BESTSELLER]: "BESTSELLER",
    [TP_CHARACTERISTICS.RXABLE]: "RXABLE",
    [TP_CHARACTERISTICS.NEW_PICK_FOR_YOU]: "TP_NEW_PICKS_FOR_YOU",
    [TP_CATEGORY.SUN]: "TP_SUN",
    [TP_CATEGORY.OPTICAL]: "TP_OPTICAL",
    [TP_CATEGORY.GOGGLES]: "TP_GOGGLES",
    [TP_CATEGORY.CLIPON]: "TP_CLIPON",
    [TP_CATEGORY.HALF_EYES]: "TP_HALF_EYES",
    [TP_CATEGORY.WEARABLE]: "TP_WEARABLE",
    [TP_CATEGORY.SUN_PRESCRIPTION]: "TP_SUN_PRESCRIPTION_LENS",
    [TP_CATEGORY.PRESCRIPTION]: "TP_PRESCRIPTION",
    [TP_CATEGORY.HELMETS]: "TP_HELMETS",
    [TP_CATEGORY.ELECTONICS]: "TP_ELECTRONICS_OPTICAL",
    [TP_CATEGORY.FOOTWEAR]: "TP_AFA_FOOTWEAR",
    [TP_CATEGORY.AFA_ACCESSORIES]: "TP_AFA_ACCESSORIES",
    [TP_CATEGORY.APPAREL]: "TP_AFA_APPAREL",
    [TP_CATEGORY.INSTRUMENT]: "TP_INSTRUMENT",
    [TP_CATEGORY.INSTRUMENT_PARTS]: "TP_INSTRUMENT_SPARE_PARTS",
    [TP_CATEGORY.SNOW]: "TP_SNOW_GOGGLES",
    [TP_CATEGORY.SNOW_ACCESSORIES]: "TP_SNOW_GOGGLES_ACCESSORIES",
    [TP_CATEGORY.ACCESSORIES]: "TP_ACCESSORIES",
    [TP_CATEGORY.MX_GOGGLES]: "TP_MX_GOGGLES",
    [TP_CATEGORY.MX_GOGGLES_ACCESSORIES]: "TP_MX_GOGGLES_ACCESSORIES",
    [TP_CATEGORY.SNOW_ELECTONICS]: "TP_ELECTRONICS_SNOW_GOGGLE",
    [TP_CATEGORY.SPARE]: "TP_SPARE_PARTS",
    [TP_CATEGORY.WHATCHES]: "TP_WHATCHES",
    [TP_CATEGORY.MASK]: "TP_FACE_MASK",
    [TP_CATEGORY.MATERIAL]: "TP_RX_OCP_MATERIAL",
    [TP_CATEGORY.MISCELLANEOUS]: "TP_MISCELLANEOUS",
    [TP_CATEGORY.OTHER]: "TP_OTHER",
    [TP_CATEGORY.NOT_DEFINED]: "TP_NOT_DEFINED",
}

export const shipmentModelsLabel = {
    1: "LAST_WEEK",
    4: "LAST_4_WEEKS",
    [SHIPMENT_DATES.LAST_WEEK]: "LAST_WEEK",
    [SHIPMENT_DATES.LAST_4_WEEKS]: "LAST_4_WEEKS",
    [SHIPMENT_MODELS.NEW_PICKS_FOR_YOU]: "TP_NEW_PICKS_FOR_YOU",
    [SHIPMENT_MODELS.NEW_MODELS]: "TP_NEW_MODELS",
    [SHIPMENT_MODELS.NEW]: "TP_NEW_MODELS"
}

export const shipmentModelsColor = {
    [SHIPMENT_MODELS.NEW_PICKS_FOR_YOU]: "#ffcc00",
    [SHIPMENT_MODELS.NEW_MODELS]: "#00ff9f",
    [SHIPMENT_MODELS.NEW]: "#00ff9f",
}

const shipmentsData: FormattedSingleWeekSummary[] = [
    {
        id: "lastWeek",
        items: {
            newPicksForYou: 1,
            newModels: 2,
        }
    },
    {
        id: "last4Weeks",
        items: {
            newPicksForYou: 3,
            newModels: 5,
        }
    },
];


const weeklySummaryMock: SingleWeekSummary[] = [
    {
        week: 1,
        totalCount: 0,
        facetCount: {
            newModels: 0,
            newPicksForYou: 0,
        }
    },
    {
        week: 521,
        totalCount: 199,
        facetCount: {
            newModels: 199,
            newPicksForYou: 139,
        }
    }
];

const orderFacetsCount = {
    newPicksForYou: 0,
    newModels: 1,
    new: 1,
};

export const selectTPWeeklySummaryData = (state) => {

    const trainingPillsWeeklySummary: Array<SingleWeekSummary> = state.trainingPills.trainingPillsWeeklySummary;
    const isLoadingTrainingPillsWeeklySummary: boolean = state.trainingPills.isLoadingTrainingPillsWeeklySummary;
    if (!isLoadingTrainingPillsWeeklySummary) {

        // console.log(`trainingPillsWeeklySummary`, trainingPillsWeeklySummary)
        const weeklySummary = trainingPillsWeeklySummary.map(s => {
            const total = s.totalCount;
            const items = [];

            const facetCountKeys = Object.keys(s.facetCount)
                .sort((a, b) => orderFacetsCount[a] - orderFacetsCount[b]);

            facetCountKeys.forEach(key => {
                const value = s.facetCount[key] || 0;

                items.push({
                    labelKey: shipmentModelsLabel[key],
                    value: value,
                    color: shipmentModelsColor[key],
                    width: (value / total) * 100
                })
            })
            //         console.log(`{
            //     ...s,
            //     labelKey: shipmentModelsLabel[s.id],
            //     total,
            //     items 
            //   }`, {
            //             ...s,
            //             labelKey: shipmentModelsLabel?.[s.week],
            //             total,
            //             items
            //         })

            return {
                ...s,
                // @todo change based on s.week (week)
                labelKey: shipmentModelsLabel?.[s.week],
                total,
                items
            }
        });
        // console.log(`shipmentsDates`, weeklySummary)
        return weeklySummary.sort((a, b) => a.week - b.week);
    } return [];
}



export const getTrainigPillsWeeklySummaryMock = () => {
    return new Promise((resolve, reject) => setTimeout(() => {
        resolve({ data: shipmentsData })
        // reject({})
    }, 3000))
}

export const setWishListTPMock = () => {
    return new Promise((resolve, reject) => setTimeout(() => {
        resolve({})
        // reject({})
    }, 1500))
}

export const transformProductCMSToTrainingPill = (pillCMS: ProductCMS) => {
    if (!pillCMS) {
        return null;
    }

    const newPill = {
        // image: pillCMS.extendedFields?.ProductImageLinks?.[0], //not the correct image
        // fallbackImage: undefined,
        brandName: pillCMS.pillsFieldsCms?.BrandName,
        brandString: pillCMS.pillsFieldsCms?.Brand?.[0]?.externalReference,
        model: pillCMS?.pillsFieldsCms?.Model?.[0]?.externalReference,
        modelName: pillCMS.pillsFieldsCms,
        bestSeller: pillCMS.bestSeller,
        advertising: pillCMS.advertising,
        rxAble: pillCMS.pillsFieldsCms?.Rxable,
        new: pillCMS.isNew,
        newPicksForYou: pillCMS.newPicksForYou,
        wishlist: pillCMS.wishlist,
        category: pillCMS.Category,
        // orderDate: undefined,
    }

    //remove not found attributes
    for (let key of Object.keys(newPill)) {
        if (newPill[key] === undefined || newPill[key] === null) {
            delete newPill[key];
        }
    }

    return newPill;
}

export const sanitizePillsFiltersItems = (filters) => {
    if (!filters) {
        return filters;
    }

    for (const filterKey of Object.keys(filters)) {
        if (!Array.isArray(filters[filterKey])) {
            delete filters[filterKey];
        }
    }

    return filters;
}

export const sanitizePCHWraps = (text: string): string => {
    if (!text) {
        return text;
    }

    return text.replace(/<CRLF>/g, "</br>");
}