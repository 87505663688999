import { ChannelInfoObject } from "./SearchResult";

export enum SHOW_L1_LABEL {
    HIDE = 'hideLabel',
    SHOW = 'showLabel',
}

export class L1Element {
    type: 'channel' | 'brand';
    value: string;
    label: string;
    image?: string;
    fallbackImage?: string;
    alt?: string;
    labelMedium?: string;
    showL1Label?: boolean;
    order?: number;
    channelInfos?: ChannelInfoObject;
    positionInCarousel?: number;
    contentId?: number;
    imageContentId?: number;
}