import React from 'react';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { UserProfile } from '@model/User';
import Menu from './Menu2';
import { AnimatePresence } from 'framer-motion';
import useModalTypes from '@hooks/useModalByType';
import MainMenuMobile from './MenuMobile/MainMenuMobile';
import ModalSearchMobile from './UI/ModalSearchMobile';

type Props = {
  closeMenu: () => void;
  isAuthenticated: boolean;
  show: boolean;
  logout: () => void;
  isManager: boolean;
  isTeacher: boolean;
  userProfile: UserProfile;
};

const MenuModal = (props: Props) => {
  const breakpoint = useBreakpointBoolean();
  const modalTypes = useModalTypes();

  return (
    <AnimatePresence>
      {breakpoint.isDesktopOrTabletLandscape && modalTypes.menuRightModal.show && (
        <Menu {...props} />
      )}
      {breakpoint.isPhoneOrTabletPortrait &&
        (modalTypes.menuRightModal.show ||
          modalTypes.profileMenu.show ||
          modalTypes.areaOfExpertiseMenu.show ||
          modalTypes.visionCareMenu.show ||
          modalTypes.areaOfExpertiseL1Menu.show ||
          modalTypes.collectionsMenu.show) && (
          <MainMenuMobile
            {...props}
            show={
              modalTypes.menuRightModal.show ||
              modalTypes.profileMenu.show ||
              modalTypes.areaOfExpertiseMenu.show ||
              modalTypes.visionCareMenu.show ||
              modalTypes.areaOfExpertiseL1Menu.show ||
              modalTypes.collectionsMenu.show
            }
          />
        )}
      {breakpoint.isPhoneOrTabletPortrait && modalTypes.searchMenu.show && (
        <ModalSearchMobile show={modalTypes.searchMenu.show} closeModal={modalTypes.close} />
      )}
    </AnimatePresence>
  );
};

export default MenuModal;
