import { useSelector } from "./redux";
import { useDispatch } from "react-redux";
import { getL1Images } from "@redux-actions/";
import { useEffect } from "react";

const useFetchL1 = (l1Ids: string[]) => {
  const L1Images: any[] = useSelector((state) => state.cms.L1Images);
  const isLoadingL1Image = useSelector((state) => state.cms.isLoadingL1Image);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadingL1Image && isAuthenticated) {
      const channels = l1Ids?.filter(
        (a) => !L1Images[a]?.img
      );
      if (channels?.length > 0) {
        dispatch(getL1Images(channels));
      }
    }
  }, [l1Ids, isLoadingL1Image]);
};

export default useFetchL1;
