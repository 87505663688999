import React, { useEffect } from 'react';
import { HEADER } from "@utility/const.js"
import { AnimatePresence } from 'framer-motion';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import AreaOfExpertiseModal from '@components/AreaOfExpertiseModal';
import CartRightModal from '@components/CartRightModal';
import CollectionsMenu, { pickedSelection } from '@components/collections/CollectionsMenu';
import EducationalPathsMenu from '@components/EducationalPathsMenu';
import NotificationsRightModal from '@components/NotificationsRightModal';
import useModalTypes from '@hooks/useModalByType';
import AnimationSlide from '@components/animations/AnimationSlide';
import clsx from '@utility/clsx';
import SearchMenu from '@components/SearchMenu';
import { useSelector } from '@hooks/redux';
import useBlockScroll from '@utility/useBlockScroll';
import VisionCareMenuModal from '@components/VisionCareMenuModal';

const MenuModalCollection = () => {
  const modalTypes = useModalTypes();
  const breakpoint = useBreakpointBoolean();
  const typeModal = useSelector(state => state.utils.typeModal);
  
  const whiteHeader = HEADER.ISWHITE;

  useBlockScroll(modalTypes.isAnyModalOpen);

  const animationSlideToTopDefault = {
    initialPosition: 'top',
    initialX: '0',
  };
  const animationDurationModal = 0.2;

  useEffect(() => {
    if (modalTypes.programsMenu?.show && !breakpoint.isDesktopOrTabletLandscape) {
      modalTypes.close();
    }
    if (modalTypes.profileMenu?.show && breakpoint.isDesktopOrTabletLandscape) {
      modalTypes.close();
    }
  }, [breakpoint.isDesktopOrTabletLandscape]);

  const [selection, setSelection] = React.useState<string>(null);
  const [fullHeightSearch, setFullHeightSearch] = React.useState(false);
  const handleSearch = () => {
    setFullHeightSearch(true);
  };

  useEffect(() => {
    setFullHeightSearch(false);
    () => {
      setFullHeightSearch(false);
    };
  }, [typeModal]);

  return (
    <>
      <AnimatePresence>
        {modalTypes.notificationsRightModal?.show && (
          <NotificationsRightModal show={modalTypes.notificationsRightModal?.show} />
        )}
        {modalTypes.cartRightModal?.show && (
          <CartRightModal show={modalTypes.cartRightModal?.show} />
        )}
      </AnimatePresence>

      {breakpoint.isDesktopOrTabletLandscape && (
        <AnimatePresence>
          {(modalTypes.programsMenu?.show ||
            modalTypes.areaOfExpertiseMenu?.show ||
            modalTypes.visionCareMenu?.show ||
            modalTypes.collectionsMenu?.show ||
            modalTypes.searchMenu?.show) && (
            <div
              className={clsx('header-menu__container', fullHeightSearch && 'header-menu__container--search-open', whiteHeader && "header-v3--white")}
              onClick={modalTypes.close}
            >
              <AnimationSlide
                className={clsx('area-of-expertise', {
                  'educational-paths-menu': modalTypes.programsMenu.show,
                  ['collections-menu ' + selection]: modalTypes.collectionsMenu.show,
                  'search-evolution': modalTypes.searchMenu.show,
                  'search-evolution__height': !!fullHeightSearch,
                })}
                {...animationSlideToTopDefault}
                initialY="10vh"
                exitProps={
                  fullHeightSearch
                    ? {
                        height: '100vh',
                        y: 0,
                        opacity: 1,
                      }
                    : null
                }
                duration={animationDurationModal}
              >
                <EducationalPathsMenu show={modalTypes.programsMenu?.show} />
                <AreaOfExpertiseModal show={modalTypes.areaOfExpertiseMenu?.show} />
                <VisionCareMenuModal show={modalTypes.visionCareMenu?.show} />
                <CollectionsMenu
                  show={modalTypes.collectionsMenu?.show}
                  selection={selection}
                  setSelection={setSelection}
                />
                <SearchMenu
                  show={modalTypes.searchMenu?.show}
                  handleSearch={handleSearch}
                  breakpoint={breakpoint}
                />
              </AnimationSlide>
            </div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default MenuModalCollection;
