import LazySVG from '@components/LazySvg';
import useLangAuth from '@hooks/useLangAuth';
import useBlockScroll from '@utility/useBlockScroll';
import FocusTrap, { Props as FocusTrapProps } from 'focus-trap-react';
import { motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef } from 'react';

type ModalRightProps = {
  children: React.ReactNode;
  close: () => void;
  show: boolean;
  className?: string;
  contentClassName?: string;
  animateSlide?: boolean;
  animateSlideY?: boolean;
  closeAriaLabel?: string;
  hideX?: boolean;
  focusTrapOptions?: Partial<FocusTrapProps['focusTrapOptions']>;
  eventCloseOnClick?: () => void;
  durationAnimation?: number;
  noOpacityAnimation?: boolean;
  ignoreBlock?: boolean;
};

const variants = {
  initial: {
    x: '100%',
    opacity: 0,
  },
  enter: {
    x: '0%',
    opacity: 1,
  },
  exit: {
    x: '100%',
    opacity: 0,
  },
};

const variantsNoOpacity = {
  initial: {
    x: '100%',
  },
  enter: {
    x: '0%',
  },
  exit: {
    x: '100%',
  },
};

const variantsVertical = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
  },
  exit: {
    y: '100%',
    opacity: 0,
  },
};

export const ModalRight = (props: ModalRightProps): React.ReactElement => {
  const closeButtonRef = useRef<HTMLButtonElement>();
  // const modalRef = useRef<HTMLDivElement>();
  useBlockScroll(props.show, props.ignoreBlock);

  const langKeys = ['CLOSE_MODAL'] as const;

  const labels = useLangAuth(langKeys);

  useEffect(() => {
    if (props.show)
      if (!props.hideX) {
        closeButtonRef.current?.focus();
      }
  }, [props.show]);

  const printVariants = () => {
    let variantsRet = {};
    if (props.animateSlide) variantsRet = !props.noOpacityAnimation ? variants : variantsNoOpacity;
    if (props.animateSlideY) variantsRet = variantsVertical;

    return variantsRet;
  };

  return (
    props.show && (
      <FocusTrap
        active={props.show}
        focusTrapOptions={{
          fallbackFocus: 'body',
          allowOutsideClick: true,
          ...props?.focusTrapOptions,
        }}
      >
        <div>
          <motion.div
            role="dialog"
            aria-modal="true"
            className={'modal-right' + (props.className ? ' ' + props.className : '')}
            // ref={modal-rightRef}
            variants={printVariants()}
            initial="initial"
            animate="enter"
            exit="exit"
            transition={{ ease: 'easeInOut', duration: props.durationAnimation }}
            onClick={props.eventCloseOnClick}
          >
            <div
              className={'modal-right__content ' + props.contentClassName}
              onClick={e => e.stopPropagation()}
            >
              <button
                className={
                  'button-no-back modal-right__close' +
                  (props.hideX ? ' modal-right__close--hide' : '')
                }
                aria-label={(!props.hideX && props.closeAriaLabel) || labels?.CLOSE_MODAL}
                onClick={props.close}
                ref={closeButtonRef}
              >
                <LazySVG src="/images/svg-icons/x-icon2.svg" alt="" />
              </button>
              {props.children}
            </div>
          </motion.div>
          <div className="modal-right__background" />
        </div>
      </FocusTrap>
    )
  );
};

ModalRight.defaultProps = {
  contentClassName: '',
  blur: null,
  animateSlideY: false,
  duration: 0.3,
};
export default ModalRight;
