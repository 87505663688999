import { useState, useEffect } from 'react';
import { useSelector } from './redux';
import { Course, CoursesMap } from '@model/CoursesClass';
import { checkisCourseEliminated, isCourseVisible } from '@utility/Api';
import { sortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';

const useGetMostLikedCourses = (maxNum = 30): [Course[]] => {
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);

  const [mostLikedCourses, setMostLikedCourses] = useState<Course[]>([]);

  useEffect(() => {
    let mostLikedCoursesTemp = [];

    if (coursesMap) {
      for (var id in coursesMap) {
        const course = coursesMap[id];
        if (isCourseVisible(course) && !checkisCourseEliminated(course) && course.rating > 0)
          mostLikedCoursesTemp.push(course);
      }
    }

    //sort by rating
    mostLikedCoursesTemp = sortCourses(mostLikedCoursesTemp, SORTBY.RATING).slice(0, maxNum);

    setMostLikedCourses(mostLikedCoursesTemp);
  }, [coursesMap]);

  return [mostLikedCourses];
};
export default useGetMostLikedCourses;
