import { CourseActivity } from "@model/CoursesClass";
import { isActivityCompleted } from "./Api";
import { activityState } from "./const";

export const isActivityStarted = (activity: CourseActivity): boolean =>
    activity.completionState === activityState.STARTED;

export const getActivityCompletionPercentage = (activity: CourseActivity): number => {
    if (isActivityCompleted(activity)) {
        return 100;
    } else if (isActivityStarted(activity)) {
        return 50;
    } else {
        return 0;
    }
}

export const getActivityDuration = (activity) => {
    let val = "";

    if (!activity || !activity.tags) {
        return val;
    }

    activity.tags.forEach((tag) => {
        if (tag.includes("duration") && !tag.includes("section_duration")) {
            val = tag.replace("duration:", "").trim();
        }
    });

    return val;
};