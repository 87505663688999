import { recognizeCardCircuit } from "@utility/ecommerceUtility";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

const useFormContentPayment = () => {
  const [circuit, setCircuit] = useState<string>("generic");
  const [showGenericError, setShowGenericError] = useState<boolean>(false);
  const [errorGetPayments, setErrorGetPayments] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const onSuccessGetPayments = () => {
    setErrorGetPayments(null);
  }
  const onFailureGetPayments = (err: AxiosError) => {
    setErrorGetPayments(err.message);
  }

  const handleOnChange = () => {
    if (showGenericError) {
      setShowGenericError(false);
    }
  };

  const onChangeCNum = (v: string) => {
    //remove "-" for validation
    const value = v.replace(/[- ]/g, "");
    const recognizedCircuit = recognizeCardCircuit(value);
    setCircuit(recognizedCircuit);
    return recognizedCircuit;
  };

  useEffect(() => {
    setErrorGetPayments(null);
    () => setErrorGetPayments(null);
  }, [])
  

  return {
    circuit,
    setCircuit,
    showGenericError,
    setShowGenericError,
    errorGetPayments,
    setErrorGetPayments,
    onSuccessGetPayments,
    onFailureGetPayments,
    isAdding,
    setIsAdding,
    isEditing,
    setIsEditing,
    handleOnChange,
    onChangeCNum,
    loading,
    setLoading,
    selectedAccount,
    setSelectedAccount,
    showForm: isAdding || isEditing,
  };
};

export default useFormContentPayment;
