import { Course } from "./CoursesClass";
import { OrderItem } from "./EcommerceClass";

export class CartModel {
    orderId: string = '';
    orderItem: ProductCart[] = [];
    adjustment: Promo[]; //list of applied promos
    totalProductPrice: string = '0'; //total price of items
    totalProductPriceCurrency: string = 'USD';
    totalAdjustment: string; //total value of promos
    totalAdjustmentCurrency: string = 'USD';
    totalSalesTax: string = '0'; //should be promotion in FE
    totalSalesTaxCurrency: string = 'USD';
    totalShippingTax: string = '0'; //billing taxes?
    totalShippingTaxCurrency: string = 'USD';
    totalShippingCharge: string = '0'; //shipment price --> should be always zero
    totalShippingChargeCurrency: string = 'USD';
    grandTotal: string = '0'; //total considering subtotal promotion promo and taxes
    grandTotalCurrency: string = 'USD';
    paymentInstruction: PaymentInstruction[];
    promotionCode: PromotionCode[];
}

export class ProductCart {
    partNumber: string; //course id or plan id (for now the plan will be unique)
    quantity: string = '1';
    unitQuantity: string; //what is the difference between quantity?
    orderItemId: string; //what is the difference between partNumber?
    freeGift: string; //can be useful?
    adjustment: Promo[] = [];
    totalAdjustment: { currency: string, value: string } = { value: '0', currency: 'USD' };
    salesTax: string = '0'; //should be promotion in FE
    salesTaxCurrency: string = 'USD';
    shippingTax: string = '0'; //billing taxes?
    shippingTaxCurrency: string = 'USD';
    shippingCharge: string = '0'; //shipment price --> should be always zero
    shippingChargeCurrency: string = 'USD';
    unitPrice: string = '0'; //price per one quantity
    orderItemPrice: string = '0'; //total price 
    currency: string = 'USD';
    shipModeId: string;
    addressId?: string;

    constructor(partNumber?: string, quantity?: number | string) {
        if (partNumber) {
            this.partNumber = partNumber;
        }
        if (quantity) {
            this.quantity = quantity.toString();
        }
    }
}

export class Promo {
    code: string;
    amount: string;
    currency: string;
    displayLevel: string;
}

export class PromotionCode {
    code: string;
    associatedPromotion: { description: string, promotionId: string }[]
}

export class PromoWithCode extends Promo {
    promotionCode?: string;
}

export class PaymentInstruction {
    piId: string;
    billing_address_id: string;
    payMethodId: string;
    firstName: string;
    lastName: string;
    addressLine: string[];
    zipCode: string;
    city: string;
    state: string;
    country: string;

    piAmount: string;
    piCurrency: string;
}

export class PaymentInstructionRequest {
    orderid: string;
    billing_address_id: string;
    shippingAddressId?: string;
    piAmount: string;
    payMethodId: string;
    expire_month: string;
    cc_brand: string;
    expire_year: string;
    cc_account: string;
    cc_cvc: string;
    cc_nameoncard: string;
    walletId?: string;
    currency: string;
    autoRenewalActive: boolean;
    createWallet: "true" | "false";
    token?: string;
    forterToken?: string;
    courseAndQuantityMap?: { [courseIdMaster: string]: string };
    isMasterProgram: boolean;
    /* not needed
    startDate?: string;
    fulfillmentInterval?:	string;
    fulfillmentIntervalUOM?: UOM;
    requesttype?:	string;
    */
}

export class PaymentInstructionItem {
    piId: string;
    payMethodId: string;
    expire_month: string;
    cc_brand: string;
    expire_year: string;
    account: string;
    cc_nameoncard: string;
    cc_cvc: string;
    token?: string;
}

export type PaymentInstructionsResponse = Omit<PaymentInstructionItem, "cc_cvc" | "token">[];

export class OrderHistory {
    resourceId: string;
    recordSetComplete: string;
    resourceName: string;
    recordSetStartNumber: string;
    Order: OrderClass[] = [];
    recordSetCount: string;
}

export class OrderClass {
    orderId: string;
    customerOrderNumber: string; //order number
    subscriptionInfo?: OrderSubscriptionsInfo;
    parentOrderId?: string;
}

export class OrderSubscriptionsInfo {
    orderBoughtTimeStart?: string;
    orderTimeEnd: string;
    autoRenewalActive: "Active" | "InActive" | "Cancelled";
}

export type CartItemFormatted = Omit<OrderItem, "id"> & {
    isExpired: boolean;
    fallbackImage: string;
    image: string;
    id: string;
    quantity: string | number;
    currency: string | number;
    productCart?: ProductCart;
    isPlan?: boolean;
} & Course;

export type ResponseSetPaymentAndConfirm = {
    orderId: string,
    resourceName: string,
}