import React from 'react';
import { useSelector } from '@hooks/redux';
import LazySVG from '../LazySvg';
import AnimationSlide from '@components/animations/AnimationSlide';
import CloseIconModalRight from '@components/UI/CloseIconModalRight';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';

const animationSlideToTopDefault = {
  initialPosition: 'top',
  initialX: '0',
};

const animationDurationModal = 0.2;
const animationDurationSection = 0.2;
const animationYSection = '30px';

type HeaderMenuProps = {
  className?: string;
  show: boolean;
  closeModal: () => void;
  closeDataId?: string;
  header?: React.ReactNode;
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
  thirdColumn?: React.ReactNode;
  firstColumnProps?: any;
  secondColumnProps?: any;
  secondColumnClassName?: string;
  thirdColumnProps?: any;
};

const HeaderMenu = (props: HeaderMenuProps) => {
  const lang = useSelector(state => state.utils.lang);

  return (
    <AnimatePresence>
      {props.show && (
        <FocusTrap
          active={props.show}
          focusTrapOptions={{
            fallbackFocus: 'body',
            allowOutsideClick: true,
          }}
        >
          <AnimationSlide
            {...animationSlideToTopDefault}
            initialY={animationYSection}
            duration={animationDurationSection}
            className={'header-menu__content ' + (props.className ? props.className : '')}
            extraProps={{ onClick: e => e.stopPropagation() }}
            exitProps={{ position: 'absolute' }}
          >
            {props.header && (
              <AnimationSlide
                {...animationSlideToTopDefault}
                initialY={animationYSection}
                duration={animationDurationSection}
                delay={animationDurationModal}
                useSectionTagHtml={true}
                {...props.firstColumnProps}
              >
                {props.header}
              </AnimationSlide>
            )}
            <div className="area-of-expertise__container">
              <CloseIconModalRight
                onClick={props.closeModal}
                ariaLabel={lang?.CLOSE_MODAL}
                dataElementId={props?.closeDataId}
              />
              <button
                className={'button-no-back modal-right__close'}
                aria-label={lang?.CLOSE_MODAL}
                onClick={props.closeModal}
                data-element-id={props?.closeDataId}
              >
                <LazySVG src="/images/svg-icons/x-icon2.svg" alt={lang?.CLOSE_MODAL} />
              </button>
              <AnimationSlide
                className="area-of-expertise__section scrollable-section area-of-expertise__first-column"
                {...animationSlideToTopDefault}
                initialY={animationYSection}
                duration={animationDurationSection}
                delay={animationDurationModal}
                useSectionTagHtml={true}
                {...props.firstColumnProps}
              >
                {props.firstColumn}
              </AnimationSlide>
              <AnimationSlide
                className={
                  'area-of-expertise__section area-of-expertise__second-column ' +
                  (!!props.thirdColumn ? '' : 'area-of-expertise__column-larger') +
                  (!props.secondColumnClassName ? '' : ' ' + props.secondColumnClassName)
                }
                {...animationSlideToTopDefault}
                initialY={animationYSection}
                duration={animationDurationSection}
                delay={animationDurationModal + animationDurationSection}
                useSectionTagHtml={true}
                {...props.secondColumnProps}
              >
                {props.secondColumn}
              </AnimationSlide>
              {props.thirdColumn && (
                <AnimationSlide
                  className="area-of-expertise__section  area-of-expertise__section--no-padding area-of-expertise__third-section"
                  {...animationSlideToTopDefault}
                  initialY={animationYSection}
                  duration={animationDurationSection}
                  delay={animationDurationModal + animationDurationSection * 2}
                  useSectionTagHtml={true}
                  {...props.thirdColumnProps}
                >
                  {props.thirdColumn}
                </AnimationSlide>
              )}
            </div>
          </AnimationSlide>
        </FocusTrap>
      )}
    </AnimatePresence>
  );
};

export default HeaderMenu;
