import { ExtendedFieldRowFilter } from "@model/CMSBrand";
import { BADGE, courseStatesConst, ctype, SORTBY } from "./const";

export const mapCMSOrdinamento = (cmsOrdinamento: Array<ExtendedFieldRowFilter>, addNoMatch: boolean = false): Array<string> => {
    let sortings: Array<string> = [];
    if (cmsOrdinamento?.length > 0) {
        cmsOrdinamento.forEach(a => {
            const value = a.value;

            switch (value?.toLowerCase()) {
                case 'reccomandation':
                case 'raccomandation':
                    sortings.push(SORTBY.RECOMMANDATION);
                    break;
                case 'rank':
                    sortings.push(SORTBY.RELEVANCE);
                    break;
                case 'like/rating':
                case 'rating':
                    sortings.push(SORTBY.RATING);
                    break;
                case 'newness':
                    sortings.push(SORTBY.NEW);
                    break;
                case 'not completed':
                    sortings.push(SORTBY.NOT_COMPLETED);
                    break;
                case 'in corso':
                case 'in progress':
                    sortings.push(SORTBY.IN_PROGRESS);
                    break;
                case 'mandatory':
                    sortings.push(SORTBY.MANDATORY);
                    break;
                case 'overdue':
                    sortings.push(SORTBY.OVERDUE);
                    break;
                case 'expiring':
                    sortings.push(SORTBY.EXPIRING);
                    break;
                case 'to start':
                    sortings.push(SORTBY.TO_START);
                    break;
                default:
                    addNoMatch ? sortings.push(value) : null;
                    break;
            }
        });
    }

    return sortings;
}

export const mapCMSBadge = (cmsBadge: Array<ExtendedFieldRowFilter>, addNoMatch: boolean = false): Array<string> => {
    let badges: Array<string> = [];
    if (cmsBadge?.length > 0) {
        cmsBadge.forEach(a => {
            const value = a.value;

            switch (value) {
                case 'Expiring':
                    badges.push(BADGE.expiring);
                    break;
                case 'Overdue':
                    badges.push(courseStatesConst.OVERDUE);
                    break;
                case 'New':
                    badges.push(BADGE.new);
                    break;
                case 'Hot':
                    break;
                default:
                    addNoMatch ? badges.push(value) : null;
                    break;
            }
        });
    }

    return badges;
}

export const mapCMSTipology = (cmsTipology: Array<ExtendedFieldRowFilter>, addNoMatch: boolean = false): Array<string> => {
    let ctypes: Array<string> = [];
    if (cmsTipology?.length > 0) {
        cmsTipology.forEach(a => {
            const value = a.value;

            switch (value) {
                case 'PDF':
                    ctypes.push(ctype.PDF);
                    break;
                case 'Video':
                    ctypes.push(ctype.VIDEO);
                    break;
                case 'Podcast':
                    ctypes.push(ctype.PODCAST);
                    break;
                case 'SCORM':
                    !addNoMatch && ctypes.push(ctype.ONLINE_COURSE);
                    !addNoMatch && ctypes.push(ctype.INTERACTIVE);
                    addNoMatch && ctypes.push(ctype.ONLINE_COURSES);
                    break;
                case 'Course Series':
                    ctypes.push(ctype.MULTIACTIVITY);
                    break;
                case 'Virtual Classroom':
                    ctypes.push(ctype.VIRTUAL_CLASSROOM);
                    break;
                case 'Classroom':
                    ctypes.push(ctype.FACE_TO_FACE);
                    break;
                default:
                    addNoMatch ? ctypes.push(value) : null;
                    break;
            }
        });
    }

    return ctypes;
}

export const mapCMSStatus = (cmsTipology: Array<ExtendedFieldRowFilter>, addNoMatch: boolean = false): Array<string> => {
    let status: Array<string> = [];
    if (cmsTipology?.length > 0) {
        cmsTipology.forEach(a => {
            const value = a.value;

            switch (value?.toLowerCase()) {
                case 'in corso':
                case 'in progress':
                    status.push(courseStatesConst.IN_PROGRESS);
                    break;
                case 'mandatory':
                    status.push(BADGE.mandatory);
                    break;
                case 'overdue':
                    status.push(courseStatesConst.OVERDUE);
                    break;
                case 'expiring':
                    status.push(courseStatesConst.EXPIRING);
                    break;
                case 'to start':
                    !addNoMatch && status.push(courseStatesConst.TO_BEGIN);
                    addNoMatch && status.push("toBeStarted");
                    break;
                default:
                    addNoMatch ? status.push(value) : null;
                    break;
            }
        });
    }

    return status;
}

export const getFiltersValuesParams = (key: string, paramValues: string[]) => {

    const values = paramValues.map(value => decodeURI(value || ''));
    let extendedFieldRowFilter = null;
    if (["sortBy", "status", "ctype"].includes(key)) {
        extendedFieldRowFilter = values.map(value => ({ externalReference: key, value }));
    } else {
        return values;
    }

    switch (key) {
        case "sortBy":
            return mapCMSOrdinamento(extendedFieldRowFilter, true);
        case "ctype":
            return mapCMSTipology(extendedFieldRowFilter, true);
        case "status":
            return mapCMSStatus(extendedFieldRowFilter, true);
        default:
            return values;
    }
}