import React, { ReactElement } from 'react';
import { useSelector } from "@hooks/redux";
import { LangMap } from '@model/CoursesClass';
import { handleOnEnterKeyPress } from '@utility/Api';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';
interface TabHandlerProps {
    tab: any;
    setTab: (tab: any) => void;
    tabsList: Array<{ labelKey: string, id: string, dataElementId?: string, dataDescription?: string }>;
    className?: string;
}

export const TabHandler = (props: TabHandlerProps): ReactElement => {
    const lang: LangMap = useSelector((state) => state.utils.lang);

    return (
        <section
            className={"tab-handler max-width-1440 " + props.className}
            aria-label={lang?.SELECT_OPTION}
        >
            {props.tabsList?.map(singleTab =>

                    <span
                        key={singleTab.id}
                        role="button"
                        tabIndex={0}
                        onClick={() => {buildClickDataTrackingObject("tab_"+camelCase(lang[singleTab.labelKey]));props.setTab(singleTab.id)}}
                        onKeyDown={(event) => {
                            handleOnEnterKeyPress(event, () => props.setTab(singleTab.id))
                        }}
                        className={`focus-outline ${props.tab === singleTab.id ? "tab-active" : ""}`}
                    >
                        {lang[singleTab.labelKey]}
                    </span>
                    
            )}
        </section>
    );
}

export default TabHandler;