import React, { useEffect, useRef, useState } from 'react';
import { USER_URLS } from '@components/link-utils';
import OnboardingIconsList from '@components/onboarding/OnboardingIconsList';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import useGetOnboardingLabels from '@hooks/onboarding/useGetOnboardingLabels';
import CardCover from './CardCover';
import { CardDisplayProps } from './CardDisplayUtils';
import { useHistory } from 'react-router-dom';
import {isCompletedCourse, isCourseCertificateCtype, printHTML} from '@utility/Api';
import EducationalPathsProgramButton from '@components/UI/EducationalPathsProgramButton';
import EducationalPathsComplexity from '@components/EducationalPathsComplexity';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import LazySVG from '@components/LazySvg';
import {Course, CoursesMap} from '@model/CoursesClass';
import { courseStatus } from '@utility/const';
import { isCourseAssessment } from '@utility/CourseUtility';
import { useDispatch } from 'react-redux';
import {toggleModal, updateCourse} from '@redux-actions/';
import { formatProgramForCorrectUrl } from '@utility/buildYourCareerUtility';
import DetailsSingleActivityButton from "@components/DetailsSingleActivityButton";
import {useSelector} from "@hooks/redux";
import clsx from "@utility/clsx";
import { cmMetaData } from '@utility/previewUtils';
import TextSkeleton from '@components/skeletons/TextSkeleton';


const CustomDetailsSingleActivityButton = ({courseWithDetails, lang, isAdvancedCompletedCourse})=>{
	return <DetailsSingleActivityButton
		course={courseWithDetails}
		subtitle={null}
		lang={lang}
		isLoading={false}
		delayGetCourseDetails
		useCustomButton={{
			typeElement: EducationalPathsProgramButton,
			props:{
				course:courseWithDetails,
				isMandatoryCompletedCourse: isAdvancedCompletedCourse
			},
			useActionCustom: !isCourseAssessment(courseWithDetails)
		}
		}
	/>
}

const CardRoleSpecificProgramEducationalPath = (props: CardDisplayProps & {isAdvancedCompletedCourse: boolean, coursesContentId: number}) => {
	const {
		course,
		badgeLabel,
		badgeColor,
		badgePriority,
		image,
		courseFullName,
		fallbackImage,
		ctypeName,
		courseIdMaster,
		priceLabel,
		isComingSoon,
		isCertificate,
		isAssessment,
		isAdvancedCompletedCourse,
		className,
		coursesContentId
	} = props;
	const { courseSummary } = course;
	const lang = useSelector(state=>state.utils.lang)
	const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary)

	const coursesMap = useSelector(state=>state.course.coursesMap)
	const history = useHistory();
	const dispatch = useDispatch();
	const onboardingLabels = useGetOnboardingLabels(course, true, false, true);
	const breakpoint = useBreakpointBoolean();
	const [markerProgress, setMarkerProgress] = useState("");
	const infoContainerRef = useRef(null);
	const badgeContainerRef = useRef(null);
	const markerWrapperRef = useRef(null);
	const card = useRef(null);
	const [courseWithDetails, setCourseWithDetails] = useState<Course>(course)
	const [isCardClickable, setIsCardClickable] = useState<boolean>()


	const goToCourse = () => {
		if(!isCardClickable) return

		if(isAssessment) {
			dispatch(toggleModal(true, course))
			return
		}
		let careerProgram = course?.catalogTypes[0];
		let role = formatProgramForCorrectUrl(careerProgram)
		history.push(USER_URLS.ROLE_SPECIFIC_PROGRAM_PROGRAM.URL
			.replace(':id', courseIdMaster)
			.replace(':roleId',role));
	};

	enum markerProgressTypes {
		INPROGRESS = "in-progress",
		COMPLETED = "completed",
	}

	useEffect(() => {
		setIsCardClickable(!isCourseCertificateCtype(course) || isCompletedCourse(course))

		//logic for vertical progression
		if (course.onboardingChartsInfo?.total) {
			let total = course.onboardingChartsInfo?.total;
			if (total.doneCourses === 0 && total.inProgressCourses === 0) {
				return;
			}
			if (total.totalCourses > 0 && (total.totalCourses === total.doneCourses)) {
				setMarkerProgress(markerProgressTypes.COMPLETED)
				return;
			} else if (total.totalCourses > 0 && (total.inProgressCourses > 0 || total.doneCourses > 0)) {
				setMarkerProgress(markerProgressTypes.INPROGRESS)
				return;
			}
		} else if (course.userCourseStatus && !isCertificate) {
			if (course?.userCourseStatus === courseStatus.AVAILABLE) {
				return;
			} else if (course?.userCourseStatus === courseStatus.COMPLETED) {
				setMarkerProgress(markerProgressTypes.COMPLETED)
				return;
			} else {
				setMarkerProgress(markerProgressTypes.INPROGRESS)
				return;
			}
		} else if (course?.certificateChartInfo) {
			let chart = course?.certificateChartInfo
			if(chart?.doneCourses === chart?.totalCourses) {
				setMarkerProgress(markerProgressTypes.COMPLETED)
			}
		}

	}, [course])

	//get dynamic marker-wrapper height
	useEffect(() => {
		if (markerWrapperRef && infoContainerRef.current) {
			const heightCardParent = card.current.clientHeight
			//<editor-fold desc="CardRoleSpecificProgramEducationalPath.tsx > marker - line 133 at 21/09/2023 16:13:25">
			// console.group('CardRoleSpecificProgramEducationalPath.tsx > marker - line 133 at 21/09/2023 16:13:25');
			// console.log(course?.courseId, course?.courseFullName,heightCardParent+"px");
			// console.groupEnd();
			//</editor-fold>
			markerWrapperRef?.current?.style.setProperty('--height-card-parent', heightCardParent+ "px");
		}

	}, [infoContainerRef, badgeContainerRef, badgeLabel, courseFullName,breakpoint])

	useEffect(() => {
		//<editor-fold desc="CardRoleSpecificProgramEducationalPath.tsx > CourseDetails - line 143 at 21/09/2023 12:07:07">
		// console.group('CardRoleSpecificProgramEducationalPath.tsx > CourseDetails - line 143 at 21/09/2023 12:07:07');
		// console.log(course.courseId, course?.courseDetails);
		// console.log(course.courseId, coursesMap[course.courseIdMaster]?.courseDetails, course);
		// console.groupEnd();
		//</editor-fold>
		setCourseWithDetails(coursesMap[course.courseIdMaster])
	}, [coursesMap]);

	return (
		<section className={
			clsx("card-role-specific-program",{
				 "not-available": isComingSoon && !isCertificate,
				 [className]: isCertificate
			})} ref={card}>
			{!breakpoint.isPhone &&
				<div className='marker-wrapper' ref={markerWrapperRef}>
					<div className={clsx("marker", markerProgress)}></div>
				</div>
			}
			<div className={clsx("content", !isCardClickable && "content--not_clickable") } onClick={goToCourse} {...cmMetaData(coursesContentId)}>
				<div 
					className={clsx("img-badge-container",{
						"with-badge": ((badgeLabel || priceLabel) && !breakpoint.isPhoneOrTabletPortrait && !isCertificate)
					})}>
					<div className='image-container'>
						<div className='image-wrapper'>
							<CardCover image={image} courseFullName={courseFullName} variant='small' className='image' fallbackImage={fallbackImage} hideShadow />
							{breakpoint.isDesktopOrTabletLandscape && (isAssessment || isCertificate) &&
								<div className='icon'>
									<RoleSpecificProgramPathsIcon course={course} ctypeName={ctypeName} />
								</div>
							}
						</div>
						{(badgeLabel || priceLabel) && !breakpoint.isPhoneOrTabletPortrait && !isCertificate && (
							<div className='badge-container' ref={badgeContainerRef}>
								{badgeLabel && (
									<RibbonCourseEvolution
										className='badge'
										course={course}
										useBadgeLabelLive={false}
										ribbonInfo={{
											label: badgeLabel,
											color: badgeColor,
											priority: badgePriority,
										}}
									/>
								)}
								{priceLabel && <RoleSpecificProgramPathsPrice label={priceLabel} className='price' />}
							</div>
						)}
						{breakpoint.isPhoneOrTabletPortrait &&
							<>
								<div className='status'>
									{courseWithDetails &&
										<CustomDetailsSingleActivityButton
											courseWithDetails={courseWithDetails}
											lang={lang}
											isAdvancedCompletedCourse={isAdvancedCompletedCourse}
										/>
									}
								</div>
								{(isAssessment || isCertificate) &&
									<div className='icon'>
										<RoleSpecificProgramPathsIcon course={course} ctypeName={ctypeName} />
									</div>
								}
							</>
						}
					</div>
				</div>
				<div className='content-container'>
					{!isComingSoon && <EducationalPathsComplexity label={ctypeName} />}
					<div className='info-container' ref={infoContainerRef}>
						<div className='information'>
							{isComingSoon && !isCertificate ?
								<>
									<div className="information__badge-and-title__coming-soon">
										<div className='badge-container__coming-soon'>
											{badgeLabel && (
												<RibbonCourseEvolution
													className='badge'
													course={course}
													useBadgeLabelLive={false}
													ribbonInfo={{
														label: badgeLabel,
														color: badgeColor,
														priority: badgePriority,
													}}
												/>
											)}
										</div>
										<div className='title'>
											{courseFullName}
										</div>
									</div>
									{onboardingLabels.icons.length > 0 && <OnboardingIconsList className='badges' onboardingIcons={onboardingLabels.icons} isTagBadge={true} />}
									<div className='description'>
										{courseSummary == undefined ?
											<>	
												<TextSkeleton className="phone-and-tablet-portrait first" width={320} height={15} borderRadius={12}/>
												<TextSkeleton className="phone-and-tablet-portrait" width={320} height={15} borderRadius={12}/>
												<TextSkeleton className="phone-and-tablet-portrait" width={300} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape first" width={400} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape" width={400} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape" width={200} height={15} borderRadius={12}/>
											</> :
											<div dangerouslySetInnerHTML={printHTML(courseSummary)}></div>
										}
										
									</div>
								</>
								:
								<>
									<div className='title'>
										{courseFullName}
									</div>
									{onboardingLabels.icons.length > 0 && <OnboardingIconsList className='badges' onboardingIcons={onboardingLabels.icons} isTagBadge={true} />}
									<div className='description'>
										{courseSummary == undefined ?
											<>
												<TextSkeleton className="phone-and-tablet-portrait first" width={320} height={15} borderRadius={12}/>
												<TextSkeleton className="phone-and-tablet-portrait" width={320} height={15} borderRadius={12}/>
												<TextSkeleton className="phone-and-tablet-portrait" width={300} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape first" width={400} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape" width={400} height={15} borderRadius={12}/>
												<TextSkeleton className="desktop-and-tablet-landscape" width={200} height={15} borderRadius={12}/>
											</> :
											<div dangerouslySetInnerHTML={printHTML(courseSummary)}></div>
										}
									</div>
								</>
							}
						</div>
						{breakpoint.isDesktopOrTabletLandscape &&
							<div className='status'>
								{courseWithDetails &&
									<CustomDetailsSingleActivityButton
										courseWithDetails={courseWithDetails}
										lang={lang}
										isAdvancedCompletedCourse={isAdvancedCompletedCourse}
									/>
								}
							</div>
						}
					</div>
				</div>
				{(badgeLabel || priceLabel) && !breakpoint.isPhone && breakpoint.isPortrait && !isCertificate && (
					<div className='badge-container' ref={badgeContainerRef}>
						{badgeLabel && (
							<RibbonCourseEvolution
								className='badge'
								course={course}
								useBadgeLabelLive={false}
								ribbonInfo={{
									label: badgeLabel,
									color: badgeColor,
									priority: badgePriority,
								}}
							/>
						)}
						{priceLabel && <RoleSpecificProgramPathsPrice label={priceLabel} className='price' />}
					</div>
				)}
			</div>
			{(badgeLabel || priceLabel) && breakpoint.isPhone && !isCertificate && (
				<div className='badge-container'>
					{badgeLabel && (
						<RibbonCourseEvolution
							className='badge'
							course={course}
							useBadgeLabelLive={false}
							ribbonInfo={{
								label: badgeLabel,
								color: badgeColor,
								priority: badgePriority,
							}}
						/>
					)}
					{priceLabel && <RoleSpecificProgramPathsPrice label={priceLabel} className='price' />}
				</div>
			)}

		</section>
	);
};

export default CardRoleSpecificProgramEducationalPath;

type RoleSpecificProgramPathsPriceProps = {
	label: string;
	className?: string;
};
const RoleSpecificProgramPathsPrice = (props: RoleSpecificProgramPathsPriceProps) => {
	const { label, className } = props;

	return <span className={`card-role-specific-program__price ${className ? className : ''}`}>{label}</span>;
};

type RoleSpecificProgramPathsIconProps = {
	course: Course;
	ctypeName: string;
}
const RoleSpecificProgramPathsIcon = ({ course, ctypeName }: RoleSpecificProgramPathsIconProps) => {

	const [iconInfo, setIconInfo] = useState<{ iconType: string; iconDescr: string }>({ iconType: "", iconDescr: "" });

	useEffect(() => {
		if (isCourseAssessment(course)) {
			setIconInfo({ iconType: "/images/ctype-icons/assessment.svg", iconDescr: ctypeName })
			return
		}

		if (isCourseCertificateCtype(course)) {
			setIconInfo({ iconType: "/images/ctype-icons/certificate.svg", iconDescr: ctypeName })
			return
		}
	}, [course])

	return (
		<LazySVG className="card-role-specific-program__icon" src={iconInfo.iconType} alt={iconInfo.iconDescr} />
	)
}
