import { LangMap } from '@model/CoursesClass';
import React, { useEffect, useState } from 'react';
import ButtonV2 from '@components/UI/ButtonV2';
import { useSelector } from '@hooks/redux';
import Plus from '@images/svg-icons/plus.svg';
import Less from '@images/svg-icons/less.svg';

type CounterProps = {
  counter: number;
  setCounter: (counter: number) => void;
  variant?: string;
  min?: number;
  max?: number;
  id?: string | number;
  canSeeTooltip?: boolean;
  tooltipLabel?: string;
};

const Counter = ({
  counter,
  setCounter,
  variant = '',
  min = 1,
  max = 9999,
  id,
  canSeeTooltip = false,
  tooltipLabel,
}: CounterProps) => {
  const lang: LangMap = useSelector(state => state.utils.lang);

  const [maxLength, setMaxLength] = useState<number>(max.toString().length);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleButtonClick = (offset: number) => {
    updateCounter(counter + offset);
  };

  const updateCounter = (newValue: number | '') => {
    if (!newValue && newValue !== 0) {
      //empty field
      setCounter(null);
      return;
    }

    //it's possible that the input doesn't returns a number
    newValue = +newValue;

    if (newValue < min) {
      newValue = min;
    }

    if (newValue > max) {
      newValue = max;

      if (canSeeTooltip) {
        //show tooltip if user tried to select a quantity over the maximum
        setShowTooltip(true);
      }
    } else {
      if (canSeeTooltip) {
        //hide tooltip if user has selected a quantity lower than the maximum
        setShowTooltip(false);
      }
    }
    setCounter(newValue);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueString = e.target.value;
    if (valueString.length > maxLength) {
      valueString = valueString.slice(0, maxLength);
    }
    if (valueString.startsWith('0')) {
      valueString = valueString.slice(1);
    }
    updateCounter(valueString === '' ? '' : +valueString);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    //if user insert a non-number field --> set the minimum value
    if (!counter && counter !== 0) {
      setCounter(min);
    }
    if (counter === 0) setCounter(min);
  };

  return (
    <div className={'counter ' + variant}>
      {showTooltip && (
        <div className="counter__wrapper">
          <div className="counter__tooltip">
            <span>
              {tooltipLabel
                ? tooltipLabel
                : lang?.ECOMMERCE_COUNTER_MAX_SEATS_MASTER?.replace(
                    '{NUM}',
                    max ? max.toString() : '1'
                  )}
            </span>
            <div className="counter__tooltip-arrow" />
          </div>
        </div>
      )}

      <ButtonV2
        variant="icon-btn"
        className={'button-counter' + (variant === "disabled" ? " disabled" : "")}
        disabled={counter <= min}
        handleClick={() => handleButtonClick(-1)}
        ariaLabel={lang.REMOVE_1_LICENSE}
      >
        <Less />
      </ButtonV2>
      <label id={'counter-label_' + id} className="visually-hidden">
        Counter
      </label>
      <input
        className={'input-counter' + (variant === "disabled"? " disabled" : "")}
        type="number"
        max={max}
        maxLength={maxLength}
        min={min}
        value={counter === null ? '' : counter}
        onChange={handleOnChange}
        name="counter-license"
        aria-labelledby={'counter-label_' + id}
        onBlur={handleOnBlur}
      />
      <ButtonV2
        variant="icon-btn"
        className={'button-counter ' + (counter >= max || variant === "disabled" ? ' disabled' : '')}
        handleClick={() => handleButtonClick(1)}
        ariaLabel={lang.ADD_1_LICENSE}
      >
        <Plus />
      </ButtonV2>
    </div>
  );
};

export default Counter;
