import { useState, useEffect } from "react";
import { useSelector } from "./redux";
import { getLivestreamCountdown } from "@utility/firebaseUtility";
import { UserProfile } from "@model/User";
import { Notification } from "@model/Notification";
import { updateType } from "@utility/const";



const useLivestreamCountdown = (notifInfo, notif: Notification, max15Minutes?: boolean): [
  string
] => {
  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);

  const [countdown, setCountdown] = useState(max15Minutes ? '15:00' : null);

  useEffect(() => {
    //reset countdown if notification is different
    setCountdown(max15Minutes ? '15:00' : null);
  }, [notifInfo, notif]);

  useEffect(() => {
    if (notif && notifInfo && userProfile && notif.notificationtype === updateType.LIVESTREAM_STARTING && notifInfo.livestreamStart) {
      let timeout = 1000;
      if (!max15Minutes && countdown === null) {
        timeout = 0;
      } else if (max15Minutes && countdown === '15:00') {
        const newCountdown = getLivestreamCountdown(notifInfo.livestreamStart, userProfile.timezone);
        if (newCountdown) {
          const split = newCountdown.split(':');
          const minutes = parseInt(split[0]);
          const seconds = parseInt(split[1]);
          //if more or equal than 15 missing
          if (minutes >= 15) {
            //timeout until less than 15 minutes missing
            timeout = (((minutes - 15) * 60) + seconds + 1) * 1000;
          }
        }
      }

      let timer = null;
      if (countdown !== '00:00') {
        timer = setTimeout(() => {
          setCountdown(getLivestreamCountdown(notifInfo.livestreamStart, userProfile.timezone));
        }, timeout);
      }

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [countdown, userProfile?.timezone, notifInfo, notif]);

  return [
    countdown
  ];
};
export default useLivestreamCountdown;
