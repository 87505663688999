import React from 'react';
import { Route } from "react-router-dom";

interface PublicRouteProps {
    component: any;
    lang: any;
}


const PublicCmsNewPageRoute = (props: PublicRouteProps) => {

    const { component: ComponentChild, ...rest } = props;

    return (
        <Route
            {...rest}
            render={routeProps => (
                <ComponentChild
                    {...routeProps}
                />
            )}
        />
    )
}

export default PublicCmsNewPageRoute;