import BadgeLabelLive, { BottomLabelLiveProps } from '@components/CardV3/BadgeLabelLive';
import { useSelector } from '@hooks/redux';
import { Course, CourseAssociatedSession, CoursesMap } from '@model/CoursesClass';
import { BadgeCourseClass } from '@utility/CourseUtility';
import { getCourseDateForBadge, getDateForBadge } from '@utility/LivestreamUtility';
import React from 'react';
import BadgeCourse from './BadgeCourse';
import TagBadge from './TagBadge';
import { isAnEvent } from '@utility/Api';

export type RibbonCourseEvolutionProps = {
  course?: Course;
  className?: string;
  useBadgeLabelLive: boolean;
  associatedSession?: CourseAssociatedSession;
  ribbonInfo: BadgeCourseClass;
  variant?: BottomLabelLiveProps['variant'];
  useTagBadge?: boolean;
  showBadgeVC?:boolean;
};

const RibbonCourseEvolution = ({
  course,
  className = '',
  useBadgeLabelLive = false,
  ribbonInfo = new BadgeCourseClass(),
  associatedSession,
  variant,
  useTagBadge = true,
  showBadgeVC = false,
}: RibbonCourseEvolutionProps) => {
  const lang = useSelector(state => state.utils.lang);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  return (
    <>
      {!useBadgeLabelLive &&
        ribbonInfo.label &&
        (useTagBadge ? (
          <TagBadge
            variant="white-border"
            className={`${className} ${ribbonInfo.color}`}
            label={ribbonInfo.label}
          />
        ) : (
          <BadgeCourse className={`${className} ${ribbonInfo.color}`} label={ribbonInfo.label} />
        ))}
      {useBadgeLabelLive && course && (
        <BadgeLabelLive
        {...getDateForBadge(course, true, coursesMap, associatedSession)}
        {...getCourseDateForBadge(course, false)}
        course={course}
        lang={lang}
        defaultLabel={ribbonInfo.label}
        defaultColor={ribbonInfo.color}
        defaultPriority={ribbonInfo.priority}
        showLiveBadge={course.showLiveBadge}
        showLiveCountdown={course.showLiveCountdown}
        useTagBadge={useTagBadge}
        className={className}
        variant={variant}
        showBadgeVC={showBadgeVC}        />
      )}
    </>
  );
};

export default RibbonCourseEvolution;
