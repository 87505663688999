import React, { useEffect, useState } from 'react';
const axios = require('axios');
import * as urlList from '../config';
import { AccordionListElement } from '../model/AccordionListElement';
import AccordionList from './UI/AccordionList';
import Chip from './UI/Chip';
import Modal from './UI/Modal';
import ButtonV2 from '@components/UI/ButtonV2';
import { BrandsList } from '@model/Brand';
import {
  orderDateElementsInitial,
  ResponseGetProducts,
  ResponseGetProductsFilter,
  ResponseGetProductsItems,
  TP_CATEGORY,
} from '@model/ProductClass';
import {
  filtersLabel,
  isFilters,
  isFiltersItems,
  mergeFilters,
  sanitizePillsFiltersItems,
} from '@utility/TrainingPillsUtility';
import {
  getDaysAgo,
  getDaysDifference,
  getExtendedMonthToPrint,
  getFirstDayPastMonths,
  getHeaders,
  printDateLivestream,
  printDateLong,
} from '@utility/Api';
import { getBrandNameById } from '@redux-actions/';
import { LangMap } from '@model/CoursesClass';
import { ApplyFiltersArgs } from './FiltersProduct';
import { sortTPCharacteristics, sortTPCollection } from '@utility/sortUtility';
import { Range } from 'react-date-range';
import DateRange from '@components/UI/DateRange';
import { DATE_CUSTOM_RANGE } from '@utility/filterUtility';
import ChevronUp from '@svg-icons/chevron-up.svg';
import useElementSize from '@hooks/useElementSize';
import useFilterTopicsMaxHeight from '@hooks/useFilterTopicsMaxHeight';

const mockApplyFilters = false;

interface FilterModalProductProps {
  close: () => void;
  show: boolean;
  filters: ResponseGetProductsItems;
  setSmartFilters: (filters: ResponseGetProductsFilter[]) => void;
  lastTypeFilter: string;
  setLastTypeFilter: (lastTypeFilter: string) => void;
  initialFilters: ResponseGetProductsItems;
  elementsNum: number;
  lang: LangMap | any;
  brandsList: BrandsList;
  currentNumProducts: number;
  sortByApplied: string;
  applyFilters: (args: ApplyFiltersArgs) => void;
  category: string;
  isLoading: boolean;
  showBrandsAccordion: boolean;
  searchQuery?: string;
  refetchFilters: ResponseGetProducts | {};
  setRefetchFilters: (filters: ResponseGetProducts) => void;
  isNovelties?: boolean;
  hideCollectionFilter?: boolean;
}

const ACCORDIONS_KEY = {
  SORT_BY: 'Sort By',
  MONTH_SHIPMENT: 'Month Shipment',
  COLLECTION: 'Collection',
  CHARACTERISTICS: 'Characteristics',
  BRANDS: 'Brands',
  CATEGORIES: 'Categories',
  ORDER_DATE: 'Order Date',
  FACE_SHAPE: 'Face shape',
  FRONT_COLOR: 'Front color',
  MATERIAL: 'Material',
  TREATMENT: 'Treatment',
  LAUNCH_DATE_CODE: 'Launch date code',
};

const FilterModalProduct = ({
  close,
  show,
  filters,
  setSmartFilters,
  lastTypeFilter,
  setLastTypeFilter,
  elementsNum,
  lang,
  brandsList,
  currentNumProducts,
  sortByApplied,
  initialFilters,
  applyFilters,
  showBrandsAccordion,
  refetchFilters,
  setRefetchFilters,
  // category,
  isLoading,
  searchQuery,
  isNovelties,
  hideCollectionFilter,
}: FilterModalProductProps) => {
  const [sortBy, setSortBy] = useState(sortByApplied);
  const [filtersModal, setFiltersModal] = useState<typeof filters | {}>({});
  const [isFiltersModalLoading, setIsFiltersModalLoading] = useState(false);
  const [sortingElements, setSortingElements] = useState(new Array<AccordionListElement>());
  const [brandElements, setBrandElements] = useState(new Array<AccordionListElement>());
  // const [shipmentMonthElements, setShipMonthElements] = useState(new Array<AccordionListElement>());
  const [collectionElements, setCollectionElements] = useState(new Array<AccordionListElement>());
  const [characteristicElements, setCharacteristicElements] = useState(
    new Array<AccordionListElement>()
  );
  const [categoryElements, setCategoryElements] = useState(new Array<AccordionListElement>());

  //novelties attributes
  const [faceShapeElements, setFaceShapeElements] = useState(new Array<AccordionListElement>());
  const [frontColorElements, setFrontColorElements] = useState(new Array<AccordionListElement>());
  const [materialElements, setMaterialElements] = useState(new Array<AccordionListElement>());
  const [treatmentElements, setTreatmentElements] = useState(new Array<AccordionListElement>());
  const [launchDateElements, setLaunchDateElements] = useState(new Array<AccordionListElement>());

  const [orderDateType, setOrderDateType] = useState(null);
  const [showReset, setShowReset] = useState(false);
  const [currentOpenTab, setCurrentOpenTab] = useState<string | null>(null); //to open by default collection section, set as default value ACCORDIONS_KEY.COLLECTION
  const [numProducts, setNumProducts] = useState(elementsNum);
  const [showAllCharacteristics, setShowAllCharacteristics] = useState<boolean>(false);

  const [range, setRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [topicsRef, topicsSize] = useElementSize();
  const topicsMaxHeight = useFilterTopicsMaxHeight();

  const titleHover = { titleHover: isFiltersModalLoading ? 'Loading' : '' };

  useEffect(() => {
    setSortBy(sortByApplied);
  }, [sortByApplied]);

  useEffect(() => {
    setNumProducts(currentNumProducts);
  }, [currentNumProducts]);

  const toggleOther = (
    filter: string,
    type: keyof ResponseGetProductsItems,
    arrayFilter: string[] = []
  ): void => {
    if (isFiltersModalLoading) {
      console.log(`isFiltersModalLoading`, isFiltersModalLoading);
      return;
    }

    const filtersModalTemp = cloneDeep(filtersModal as ResponseGetProductsItems);
    setIsFiltersModalLoading(true);

    if (filtersModalTemp?.[type]) {
      if (type !== 'sortings') {
        const indexFilterItem = filtersModalTemp[type]?.findIndex(
          (filterItem: ResponseGetProductsFilter) => filterItem.id === filter
        );
        const filterItemChecked = filtersModalTemp[type]?.[indexFilterItem]?.checked;
        if (typeof filterItemChecked === 'boolean') {
          if (!arrayFilter?.length) {
            filtersModalTemp[type][indexFilterItem].checked = !filterItemChecked;
          } else {
            const indexFilterItems = arrayFilter.map(f =>
              filtersModalTemp[type]?.findIndex(
                (filterItem: ResponseGetProductsFilter) => filterItem.id === f
              )
            );

            indexFilterItems.forEach(indexF => {
              filtersModalTemp[type][indexF].checked = !filterItemChecked;
            });
          }
        }
      } else {
        filtersModalTemp[type] = filtersModalTemp[type]?.map(itemFilter => ({
          ...itemFilter,
          checked: itemFilter.id === filter,
        }));
      }

      setFiltersModal(filtersModalTemp);
      fetchFilters(filtersModalTemp, type);
    }
  };

  useEffect(() => {
    setSmartFilters((hideCollectionFilter ? [] : filtersModal?.collections) || []);
  }, [filtersModal]);

  const fetchFilters = (
    filtersModalT: ResponseGetProductsItems,
    lastSelectedFilterType: keyof ResponseGetProductsItems | '' = ''
  ): Promise<ResponseGetProducts> => {
    // if (mockApplyFilters) {
    //   setTimeout(() => {
    //     fetchTrainingPillsFilters(filtersModalT)
    //       .then(data => {
    //         console.log(`data`, data)
    //         setFiltersModal(mergeFilters(filtersModalT, data).items);
    //         setNumProducts(data.elementsNum)
    //         setIsFiltersModalLoading(false);
    //       });
    //   }, 3000);

    // }
    let url = isNovelties
      ? urlList.GET_FILTERS_NOVELTIES_URL
      : urlList.GET_FILTERS_TRAINING_PILLS_URL;
    if (searchQuery) {
      url += '?text=' + searchQuery;
    }

    return axios.post(url, filtersModalT, { headers: getHeaders() }).then(response => {
      let data: ResponseGetProducts = response.data;
      data.items = sanitizePillsFiltersItems(data.items);

      //if BE doesn't return sortings --> add them by FE
      if (data?.items && !(data.items.sortings?.length > 0) && filtersModalT?.sortings) {
        data.items.sortings = cloneDeep(filtersModalT.sortings);
      }

      setIsFiltersModalLoading(false);
      setFiltersModal(mergeFilters(filtersModalT, data, brandsList, lastSelectedFilterType).items);
      setNumProducts(data.elementsNum);
      return data;
    });
  };

  // console.log('filtersModal', filtersModal);

  useEffect(() => {
    if (isFilters(refetchFilters) && lastTypeFilter) {
      if (refetchFilters) {
        fetchFilters(refetchFilters.items, lastTypeFilter).then(() => {
          setRefetchFilters(null);
          setLastTypeFilter('');
        });
      }
    }
  }, [refetchFilters, lastTypeFilter]);

  useEffect(() => {
    if (isFiltersItems(filters)) {
      console.log(`FilterModalProduct filters`, filters);
      const filtersTemp = cloneDeep(filters);
      setFiltersModal(filtersTemp);
    }
  }, [filters]);

  const applyOrderDate = (filtersT: ResponseGetProductsItems) => {
    const startDate = filtersT?.orderDate?.[0]?.id;
    const endDate = filtersT?.orderDate?.[1]?.id;

    if (startDate && endDate && filtersT?.orderDateType >= 0 && !orderDateType) {
      if ([7, 14, 30].includes(filtersT?.orderDateType)) {
        return setOrderDateType(filtersT?.orderDateType);
      }
      if (filtersT?.orderDateType === 0) {
        orderDateType !== DATE_CUSTOM_RANGE && setOrderDateType(DATE_CUSTOM_RANGE);
        setRange({
          ...range,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }
    }
    if (filtersT?.orderDateType === -1 || typeof filtersT?.orderDateType !== 'number') {
      setOrderDateType(null);
      setRange({
        ...range,
        startDate: new Date(),
        endDate: new Date(),
      });
    }
  };

  useEffect(() => {
    if (isFiltersItems(filtersModal)) {
      applyOrderDate(filtersModal);
    }
  }, [filtersModal?.orderDate && filtersModal?.orderDateType]);

  useEffect(() => {
    let brandElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let monthElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let collectionElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let characteristicElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let categoryElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let faceShapeElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let frontColorElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let materialElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let treatmentElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();
    let launchDateElemsTemp: Array<AccordionListElement> = new Array<AccordionListElement>();

    let showResetTemp: boolean = false;

    if (isFiltersItems(filtersModal)) {
      if (!isNovelties) {
        //training pills filters
        if (filtersModal.brands && showBrandsAccordion) {
          const enahancedFilterBrands: {
            [key: string]: Array<{
              id: string;
              title: string;
              checked?: boolean;
              disabled?: boolean;
              main: boolean;
            }>;
          } = {};
          filtersModal.brands.forEach(brand => {
            if (brandsList && brandsList.map && brandsList.map[brand.id]) {
              const brandName = brandsList.map[brand.id]?.brand;
              if (brandName) {
                if (!enahancedFilterBrands?.[brandName]) {
                  enahancedFilterBrands[brandName] = [];
                }
                enahancedFilterBrands[brandName].push({
                  ...brand,
                  title: getBrandNameById(brandsList, brandName) || brandName,
                  main: brandName === brand.id,
                });
              }
            }
          });

          Object.keys(enahancedFilterBrands).forEach(brandKey => {
            const mainItem = enahancedFilterBrands[brandKey].find(f => f.main);
            const brandKeys = enahancedFilterBrands[brandKey].map(f => f.id);
            let brandElement = new AccordionListElement();
            if (mainItem) {
              brandElement = {
                ...mainItem,
                checked: enahancedFilterBrands[brandKey].some(filterBrand => !!filterBrand.checked),
                disabled: enahancedFilterBrands[brandKey].every(
                  filterBrand => !!filterBrand.disabled
                ),
                toggleCheck: () => toggleOther(brandKey, 'brands', brandKeys),
              };

              if (!!brandElement.checked) {
                showResetTemp = true;
              }

              brandElemsTemp.push(brandElement);
            }
          });
        }

        if (filtersModal.months) {
          filtersModal.months.forEach(item => {
            let monthElement = new AccordionListElement();
            const [year, month] = item.id?.split('-');
            monthElement = {
              ...item,
              title: `${getExtendedMonthToPrint(month, lang)} ${year}`,
              year,
              month,
              toggleCheck: () => toggleOther(item.id, 'months'),
            };
            if (!!item.checked) {
              showResetTemp = true;
            }

            monthElemsTemp.push(monthElement);
          });
        }

        if (filtersModal.characteristics) {
          filtersModal.characteristics.forEach(item => {
            let characteristicElement = new AccordionListElement();
            characteristicElement = {
              ...item,
              title: lang?.[filtersLabel?.[item?.id]] || item.title,
              toggleCheck: () => toggleOther(item.id, 'characteristics'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            characteristicElemsTemp.push(characteristicElement);
          });
        }

        if (filtersModal.categories) {
          filtersModal.categories.forEach(item => {
            let categoryElement = new AccordionListElement();
            categoryElement = {
              ...item,
              title: lang?.[filtersLabel?.[item?.id]] || item.title,
              toggleCheck: () => toggleOther(item.id, 'categories'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            categoryElemsTemp.push(categoryElement);
          });
        }
      } else {
        //novelties filters

        //face shape
        if (filtersModal.faceShape) {
          filtersModal.faceShape.forEach(item => {
            let faceShapeElement = new AccordionListElement();
            faceShapeElement = {
              ...item,
              title: item.id,
              toggleCheck: () => toggleOther(item.id, 'faceShape'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            faceShapeElemsTemp.push(faceShapeElement);
          });
        }

        //front color
        if (filtersModal.frontColor) {
          filtersModal.frontColor.forEach(item => {
            let frontColorElement = new AccordionListElement();
            frontColorElement = {
              ...item,
              title: item.id,
              toggleCheck: () => toggleOther(item.id, 'frontColor'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            frontColorElemsTemp.push(frontColorElement);
          });
        }

        //material
        if (filtersModal.material) {
          filtersModal.material.forEach(item => {
            let materialElement = new AccordionListElement();
            materialElement = {
              ...item,
              title: item.id,
              toggleCheck: () => toggleOther(item.id, 'material'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            materialElemsTemp.push(materialElement);
          });
        }

        //treatment
        if (filtersModal.treatment) {
          filtersModal.treatment.forEach(item => {
            let treatmentElement = new AccordionListElement();
            treatmentElement = {
              ...item,
              title: item.id,
              toggleCheck: () => toggleOther(item.id, 'treatment'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            treatmentElemsTemp.push(treatmentElement);
          });
        }

        //launch date code
        if (filtersModal.launchDate) {
          filtersModal.launchDate.forEach(item => {
            let launchDateElement = new AccordionListElement();
            launchDateElement = {
              ...item,
              title: printDateLong(+item.id, lang, false),
              toggleCheck: () => toggleOther(item.id, 'launchDate'),
            };

            if (!!item.checked) {
              showResetTemp = true;
            }

            launchDateElemsTemp.push(launchDateElement);
          });
        }
      }

      if (filtersModal.collections && !hideCollectionFilter) {
        filtersModal.collections.forEach(item => {
          let collectionElement = new AccordionListElement();
          collectionElement = {
            ...item,
            title: isNovelties ? item.id : lang?.[filtersLabel?.[item.id]] || item.title,
            toggleCheck: () => toggleOther(item.id, 'collections'),
          };

          if (!!item.checked) {
            showResetTemp = true;
          }

          collectionElemsTemp.push(collectionElement);
        });
      }

      //sorting
      if (filtersModal.sortings) {
        const sortingAccordion: Array<AccordionListElement> = new Array<AccordionListElement>();
        filtersModal.sortings.forEach(item => {
          let sortingElement = new AccordionListElement();
          sortingElement = {
            ...item,
            title:
              lang?.[filtersLabel?.[item?.id]] ||
              lang?.[filtersLabel?.[item?.id]] ||
              filtersLabel?.[item?.id] ||
              item.id,
            labelMobile: lang?.SORT_BY_LABEL.replace(
              '{sorting}',
              lang?.[filtersLabel?.[item?.id]] ||
                lang?.[filtersLabel?.[item?.id]] ||
                filtersLabel?.[item?.id] ||
                item.id
            ),
            toggleCheck: () => {
              if (!isFiltersModalLoading) {
                setSortBy(item.id);
              }
              toggleOther(item.id, 'sortings');
            },
          };

          sortingAccordion.push(sortingElement);
        });
        setSortingElements(sortingAccordion);
      }
    }

    brandElemsTemp = orderBy(brandElemsTemp, ['title']);
    setBrandElements(brandElemsTemp);
    // monthElemsTemp = orderBy(monthElemsTemp, ["year", "month"], ['desc', 'desc']);
    // setShipMonthElements(monthElemsTemp);
    if (isNovelties) {
      collectionElemsTemp = collectionElemsTemp?.sort((a, b) =>
        a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
      );
    } else {
      collectionElemsTemp = sortTPCollection(collectionElemsTemp);
    }
    setCollectionElements(collectionElemsTemp);
    characteristicElemsTemp = sortTPCharacteristics(characteristicElemsTemp);
    setCharacteristicElements(characteristicElemsTemp);
    categoryElemsTemp = sortProductCategories(categoryElemsTemp);
    setCategoryElements(categoryElemsTemp);

    //sort face shape alphabetically by title
    faceShapeElemsTemp = faceShapeElemsTemp?.sort((a, b) =>
      a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
    );
    setFaceShapeElements(faceShapeElemsTemp);
    //sort front colors alphabetically by title
    frontColorElemsTemp = frontColorElemsTemp?.sort((a, b) =>
      a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
    );
    setFrontColorElements(frontColorElemsTemp);
    //sort materials alphabetically by title
    materialElemsTemp = materialElemsTemp?.sort((a, b) =>
      a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
    );
    setMaterialElements(materialElemsTemp);
    //sort treaments alphabetically by title
    treatmentElemsTemp = treatmentElemsTemp?.sort((a, b) =>
      a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
    );
    setTreatmentElements(treatmentElemsTemp);
    //sort launch date code by date
    launchDateElemsTemp = launchDateElemsTemp?.sort((a, b) => +b.id - +a.id);
    setLaunchDateElements(launchDateElemsTemp);

    if (orderDateType) {
      showResetTemp = true;
    }

    setShowReset(showResetTemp);
  }, [isFiltersModalLoading, filtersModal, lang, orderDateType]);

  const toggleAccordion = (type, value) =>
    value ? setCurrentOpenTab(type) : setCurrentOpenTab(null);

  const handleApplyFilter = () => {
    console.log(`filtersModal`, filtersModal);
    if (isFiltersItems(filtersModal)) {
      const filtersTemp = { items: filtersModal, elementsNum: 20 };

      applyFilters({ filters: filtersTemp, mock: mockApplyFilters });
    }
    setCurrentOpenTab(null);
    close();
  };

  const handleCloseFilter = () => {
    console.log(`filters`, filters);
    const filtersModalTemp: ResponseGetProductsItems = cloneDeep(filters);
    setFiltersModal(filtersModalTemp);
    fetchFilters(filtersModalTemp);
    const sortByChecked = filtersModalTemp?.['sortings']?.find(filterItem => filterItem.checked);
    console.log(`sortByChecked`, sortByChecked);
    applyOrderDate(filters);
    setSortBy(sortByChecked?.id);
    setCurrentOpenTab(null);
    setShowAllCharacteristics(false);
    close();
  };

  const clearAllFilters = () => {
    const filtersModalTemp: ResponseGetProductsItems = cloneDeep(initialFilters);

    //sorting is not modified, just filters
    const oldSortings = filtersModal['sortings'];
    filtersModalTemp.sortings = oldSortings ? oldSortings : filtersModalTemp.sortings;

    setFiltersModal(filtersModalTemp);
    setIsFiltersModalLoading(true);
    fetchFilters(filtersModalTemp);
  };

  const handleChangeRange = (
    value: ResponseGetProductsItems['orderDate'],
    ordType: typeof orderDateType
  ) => {
    setIsFiltersModalLoading(true);
    const filtersModalTemp: ResponseGetProductsItems = cloneDeep(initialFilters);
    filtersModalTemp.orderDate = value;

    let orderTypeNum = ordType;

    if (ordType === DATE_CUSTOM_RANGE) {
      orderTypeNum = 0;
    }

    if (ordType === null) {
      orderTypeNum = -1;
    }

    filtersModalTemp.orderDateType = orderTypeNum;

    setFiltersModal(filtersModalTemp);
    fetchFilters(filtersModalTemp, 'orderDate');
  };

  const toggleOrderDate = (type: string | number) => {
    // 7 | 14 | 30 | 'CUSTOM'
    if (isFiltersModalLoading) {
      console.log(`isFiltersModalLoading`, isFiltersModalLoading);
      return;
    }

    if (orderDateType !== DATE_CUSTOM_RANGE || type !== DATE_CUSTOM_RANGE) {
      setRange({
        ...range,
        startDate: new Date(),
        endDate: new Date(),
      });
    }
    const shouldBeSet = orderDateType !== type;

    if ('CUSTOM' !== type) {
      const startDate: Date = getDaysAgo(type);
      const endDate = new Date();

      const filterValue: ResponseGetProductsItems['orderDate'] = [
        {
          id: startDate?.toISOString().substring(0, 10) + 'T00:00:00.000Z',
          checked: true,
          disabled: false,
          order: 0,
        },
        {
          id: endDate.toISOString().substring(0, 10) + 'T23:59:59.000Z',
          checked: true,
          disabled: false,
          order: 0,
        },
      ];

      if (shouldBeSet) {
        handleChangeRange(filterValue, type);
      } else {
        handleChangeRange([], null);
      }
    }

    const ordType = shouldBeSet ? type : null;
    if (type === DATE_CUSTOM_RANGE && !ordType) {
      handleChangeRange([], null);
    }

    setOrderDateType(ordType);
  };

  const handleSelect = (value: Range) => {
    // console.log('value', value);
    setRange(value.selection);
    const startDate = value.selection?.startDate;
    const endDate = value.selection?.endDate;
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      const filterValue: ResponseGetProductsItems['orderDate'] = [
        {
          id: startDate?.toISOString(),
          checked: true,
          disabled: false,
          order: 0,
        },
        {
          id: endDate.toISOString(),
          checked: true,
          disabled: false,
          order: 0,
        },
      ];
      handleChangeRange(filterValue, DATE_CUSTOM_RANGE);
    }
  };

  // const isCustomOrderDateChecked = !!orderDateElements?.find(el => el.id === 'CUSTOM')?.checked;
  return (
    <>
      <Modal
        blur="always"
        blurIntensity="07"
        close={handleCloseFilter}
        show={show}
        isCloseIconSquare={true}
        className="fillAvailable"
        contentClassName="modal__content--centered"
        isCloseIcon2={true}
      >
        <div className="filter-modal">
          <div className="filter-modal__second-header">
            <span className="desktop">{lang.FILTERS_LABEL}</span>
            <span className="mobile">{lang.FILTERS_LABEL}</span>
          </div>
          <div className="filter-modal__accordions">
            {characteristicElements && characteristicElements.length > 0 && (
              <>
                <span className="filter-modal__topics--second-title">{lang.CHARACTERISTICS}</span>
                <div
                  className="filter-modal__topics-container"
                  style={{
                    maxHeight: showAllCharacteristics
                      ? topicsSize.height.toString() + 'px'
                      : undefined,
                  }}
                >
                  <div className="filter-modal__topics more-space" ref={topicsRef}>
                    {characteristicElements.map((item, index) => (
                      <Chip
                        className="chip-modal"
                        title={isFiltersModalLoading ? 'Loading' : ''}
                        key={item.id + '_' + index}
                        label={item.title}
                        showClose
                        checked={item.checked}
                        toggle={item.toggleCheck}
                        disabled={item.disabled}
                      />
                    ))}
                  </div>
                </div>
                {topicsSize.height > topicsMaxHeight ? (
                  <div className="filter-modal__view-more-container">
                    <ButtonV2
                      variant="text-btn-no-arrow"
                      className="filter-modal__view-more-btn"
                      small
                      handleClick={() => setShowAllCharacteristics(!showAllCharacteristics)}
                    >
                      {showAllCharacteristics ? lang.VIEW_LESS : lang.VIEW_MORE}
                      <ChevronUp
                        className={
                          showAllCharacteristics
                            ? 'filter-modal__chevron'
                            : 'filter-modal__chevron--expand'
                        }
                      />
                    </ButtonV2>
                  </div>
                ) : (
                  <div className="filter-modal__no-view-more"></div>
                )}
              </>
            )}
            <div className="filter-modal__sort-by-mobile">
              <AccordionList
                {...titleHover}
                elements={sortingElements}
                title={''}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.SORT_BY}
                hideCloseIcon
                showSortBy
                currentSortBy={sortingElements.find(sorting => sorting.id === sortBy)?.labelMobile}
                expand={currentOpenTab === ACCORDIONS_KEY.SORT_BY}
                isFilterModal
              />
            </div>
            {!isNovelties && (
              <AccordionList
                elements={[]}
                title={
                  lang?.SHIPMENT +
                  (orderDateType &&
                  isFiltersItems(filtersModal) &&
                  filtersModal?.orderDate?.length > 0
                    ? ' (1) '
                    : '')
                }
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.ORDER_DATE}
                expand={currentOpenTab === ACCORDIONS_KEY.ORDER_DATE}
                showChildren
                isFilterModal
              >
                <div className="filter-modal__topics">
                  {orderDateElementsInitial.map((item, index) => (
                    <Chip
                      title={isFiltersModalLoading ? 'Loading' : ''}
                      key={'ORDER_DATE_' + item.id + '_' + index}
                      label={lang?.[item.title]?.replace('{NUM}', item.id)}
                      showClose
                      checked={orderDateType === item.id}
                      toggle={() => toggleOrderDate(item.id)}
                      disabled={item.disabled}
                    />
                  ))}
                </div>
                {orderDateType === DATE_CUSTOM_RANGE && (
                  <div className="filter-modal__daterange-container">
                    <div>
                      <DateRange
                        ranges={[range]}
                        onChange={handleSelect}
                        maxDate={new Date()}
                        minDate={getFirstDayPastMonths(2)}
                        months={1}
                      />
                    </div>
                  </div>
                )}
              </AccordionList>
            )}
            {/* <AccordionList
            {...titleHover}
            elements={shipmentMonthElements}
            title={lang.MONTH_SHIPMENT}
            toggleAccordion={toggleAccordion}
            name={ACCORDIONS_KEY.MONTH_SHIPMENT}
            expand={currentOpenTab === ACCORDIONS_KEY.MONTH_SHIPMENT}
          /> */}
            {!isNovelties && (
              <AccordionList
                {...titleHover}
                elements={collectionElements}
                title={lang.COLLECTION}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.COLLECTION}
                expand={currentOpenTab === ACCORDIONS_KEY.COLLECTION}
                isFilterModal
              />
            )}

            {/* {collectionElements && collectionElements.length > 0 &&
            <>
              <span className="filter-modal__topics--title">{lang.COLLECTION}</span>
              <div className="filter-modal__topics">
                {collectionElements.map((item, index) =>
                  <Chip
                    title={isFiltersModalLoading ? "Loading" : ""}
                    key={item.id + '_' + index}
                    label={item.title}
                    showClose
                    checked={item.checked}
                    toggle={item.toggleCheck}
                    disabled={item.disabled}
                  />
                )}
              </div>
            </>
          } */}
            <AccordionList
              elements={brandElements}
              title={lang.FILTER_BRAND}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.BRANDS}
              expand={currentOpenTab === ACCORDIONS_KEY.BRANDS}
              isFilterModal
            />
            <AccordionList
              elements={categoryElements}
              title={lang.TP_PRODUCT_CATEGORY}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.CATEGORIES}
              expand={currentOpenTab === ACCORDIONS_KEY.CATEGORIES}
              isFilterModal
            />
            <AccordionList
              elements={faceShapeElements}
              title={lang.TP_SHAPE}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.FACE_SHAPE}
              expand={currentOpenTab === ACCORDIONS_KEY.FACE_SHAPE}
              isFilterModal
            />
            <AccordionList
              elements={frontColorElements}
              title={lang.TP_FRONT_COLOR}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.FRONT_COLOR}
              expand={currentOpenTab === ACCORDIONS_KEY.FRONT_COLOR}
              isFilterModal
            />
            <AccordionList
              elements={materialElements}
              title={lang.TP_MATERIAL}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.MATERIAL}
              expand={currentOpenTab === ACCORDIONS_KEY.MATERIAL}
              isFilterModal
            />
            <AccordionList
              elements={treatmentElements}
              title={lang.TP_TREATMENT}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.TREATMENT}
              expand={currentOpenTab === ACCORDIONS_KEY.TREATMENT}
              isFilterModal
            />
            <AccordionList
              elements={launchDateElements}
              title={lang.MOCO_LAUNCH_DATE_CODE}
              toggleAccordion={toggleAccordion}
              name={ACCORDIONS_KEY.LAUNCH_DATE_CODE}
              expand={currentOpenTab === ACCORDIONS_KEY.LAUNCH_DATE_CODE}
              isFilterModal
            />
            {isNovelties && (
              <AccordionList
                {...titleHover}
                elements={collectionElements}
                title={lang.COLLECTION}
                toggleAccordion={toggleAccordion}
                name={ACCORDIONS_KEY.COLLECTION}
                expand={currentOpenTab === ACCORDIONS_KEY.COLLECTION}
                isFilterModal
              />
            )}
          </div>
        </div>
        <br />
      </Modal>
      {show && (
        <div className="filter-modal__button box-sticky-bar-width">
          <div className="inner-box-sticky">
            <div>
              {showReset && (
                <ButtonV2
                  variant="tertiary"
                  small
                  className="button-no-back filter-modal__action"
                  handleClick={clearAllFilters}
                >
                  {lang.RESET_FILTERS}
                </ButtonV2>
              )}
            </div>
            <div>
              <ButtonV2
                variant="secondary"
                small
                handleClick={handleApplyFilter}
                loading={isFiltersModalLoading}
              >
                {lang.N_RESULTS_FOR_YOUR_SEARCH.replace('{N}', numProducts?.toString())}
              </ButtonV2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const sortProductCategories = (
  categories: AccordionListElement[] | ResponseGetProductsFilter[] = []
): AccordionListElement[] | ResponseGetProductsFilter[] => {
  const mapCategoryPosition = {
    [TP_CATEGORY.SUN]: 0,
    [TP_CATEGORY.OPTICAL]: 1,
    [TP_CATEGORY.GOGGLES]: 2,
    [TP_CATEGORY.CLIPON]: 3,
    [TP_CATEGORY.HALF_EYES]: 4,
    [TP_CATEGORY.WEARABLE]: 5,
    [TP_CATEGORY.PRESCRIPTION]: 6,
    [TP_CATEGORY.SNOW]: 7,
    [TP_CATEGORY.MX_GOGGLES]: 8,
  };
  // console.log('first category', mapCategoryPosition);
  categories = categories.sort((a, b) => mapCategoryPosition[a.id] - mapCategoryPosition[b.id]);
  // console.log('categories', categories);
  return categories;
};

FilterModalProduct.defaultProps = {
  show: false,
  brandsList: new Array(),
};

export default FilterModalProduct;
