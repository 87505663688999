import { TrainerExtendedCMS } from "./CoursesClass";

export interface CMSOnboardingResponse {
  data: CMSOnboarding[];
}

export type CMSOnboarding = {
  contentId?: number;
  placements: {
    row1: CMSOnboardingRow1[],
    row2: CMSOnboardingRow2[],
  }
}

type CMSOnboardingRow1 = {
  items: CMSOnboardingRow1Item[],
  contentId?: number;
}
type CMSOnboardingRow2 = {
  items: CMSOnboardingRow2Item[],
  contentId?: number;
}

type CMSOnboardingRow1Item = {
  data: string,
  contentId?: number,
}

type CMSOnboardingRow2Item = {
  richtext1: string, //Welcome title
  richtext2: string, //Welcome description
  text1: string, //"What can you expect" title
  richtext3: string, //"What can you expect" description in alto
  richtext4: string, //"What can you expect" description in basso
  text2: string, //"What comes after onboarding" title
  richtext5: string, //"What comes after onboarding" description
  contentId?: number //contentId for CMS preview
}

export interface CMSTeacherResponse {
  [username: string]: CMSTeacher[];
}

export interface CMSTeacher {
  location: string;
  bio: string;
}

export class CMSTeacherRedux {
  teachers: TrainerExtendedCMS[] = [];
  preferredLang: string = "";
  isLoading: boolean = false;
  courseIdMaster: string = "";
}