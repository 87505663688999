import { SKELETON_COLORS } from "@utility/const";
import React from "react"
import ContentLoader from "react-content-loader"
const width = 1440;
const height = 45;

const ChipsListSkeleton = (props) => {
  return (
    <div className="skeleton" style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden', margin: 'auto', marginLeft: '22px' }}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        style={{ margin: 'auto' }}
        {...props}
      >
        <rect x="0" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="120" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="240" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="360" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="480" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="600" y="0" rx="15" ry="15" width="100" height="27" />
        <rect x="720" y="0" rx="15" ry="15" width="100" height="27" />
      </ContentLoader>
    </div>
  )
}

export default ChipsListSkeleton