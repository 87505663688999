import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  PageSections,
  PageTypes,
  TealiumEventsDataLayer,
  ErrorTypes,
  DataLayerTracking,
  EventsTracking,
  TealiumEvents,
  ErrorTracking,
  DataTrackClickObject,
  buildClickDataTrackingObject,
} from '@model/TrackingClass';
import { titles as PageTitles } from '@utility/pageTitles';
import { ParamsPage } from '@model/CMSHeroBanner';
import { handleOnEnterKeyPress } from '@utility/Api';
import ArrowLeftIcon from '@svg-icons/arrow-right6.svg';
import { HEADER_LEVEL, queryParams } from '@utility/const';
import { matchPath } from 'react-router';
import ArrowRight from '@svg-icons/arrow-right-footer-mobile.svg';
import { useHistory } from 'react-router-dom';
import { purifyItemDataElementId, purifyItemDataElementIdCamelCase } from '@utility/analytics-utils';
import CartPage from 'src/pages_v3/CartPage';
import { camelCase } from 'lodash';

export const PUBLIC_URLS = {
  HOMEPAGE: {
    URL: '/landing',
    NAME: 'NAV_HOME',
    DOC_TITLE: 'DOC_TITLE_HOME',
    level: HEADER_LEVEL.ONE,
  },
  CATALOGUE: {
    URL: '/landing/catalogue',
    NAME: 'NAV_CATALOGUE',
    DOC_TITLE: 'DOC_TITLE_CATALOGUE',
    level: HEADER_LEVEL.TWO,
  },
  LOGIN: {
    URL: '/login',
    NAME: 'NAV_LOGINSIGNUP',
    DOC_TITLE: 'DOC_TITLE_HOME',
    level: HEADER_LEVEL.ONE,
  },
  CONTACT_US: {
    URL: '/landing/contact-us',
    NAME: 'CONTACT_SUPPORT_PAGE_NAME_LABEL',
    DOC_TITLE: 'DOC_TITLE_CONTACT_SUPPORT',
    level: HEADER_LEVEL.TWO,
  },
  MANAGE_COOKIES: {
    URL: '/landing/manage-cookies',
    NAME: 'MANAGE_COOKIES',
    level: HEADER_LEVEL.TWO,
  },
  LEGAL: { URL: '/landing/legal', NAME: 'LEGAL_TITLE', level: HEADER_LEVEL.TWO },
  REGISTER: { URL: '/register', NAME: 'SIGNUP_PAGE_NAME_LABEL', level: HEADER_LEVEL.ONE },
  REGISTER_SUCCESS: {
    URL: '/register/success',
    NAME: 'SIGNUP_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.ONE,
  },
  FORGOT_PASSWORD: { URL: '/forgot-password', NAME: 'PASSWORD_FORGOTTEN', level: HEADER_LEVEL.TWO },
  FORGOT_PASSWORD_SUCCESS: {
    URL: '/forgot-password/success',
    NAME: 'PASSWORD_FORGOTTEN',
    level: HEADER_LEVEL.TWO,
  },
  EXPIRED_PASSWORD: { URL: '/expired-password', NAME: 'RESET_PASS', level: HEADER_LEVEL.TWO },
  RESET_PASSWORD: { URL: '/password-reset', NAME: 'RESET_PASS', level: HEADER_LEVEL.ONE },
  RESET_PASSWORD_SUCCESS: {
    URL: '/password-reset/success',
    NAME: 'RESET_PASS',
    level: HEADER_LEVEL.TWO,
  },
  SERVICE_PAGE: { URL: '/landing/service-page', NAME: 'SEE_SOON_APP', level: HEADER_LEVEL.ONE },
  DELETE_USER_SUCCESS: {
    URL: '/delete-user-success',
    NAME: 'DELETE_PROFILE_SUCCESS_TITLE',
    level: HEADER_LEVEL.ONE,
  },
  WELCOME: { URL: '/welcome', NAME: 'WELCOME', level: HEADER_LEVEL.ONE },
  CMS_NEW_PAGE_PUBLIC: {
    URL: '/content/public/*',
    NAME: 'CMS_NEW_PAGE',
    level: HEADER_LEVEL.ONE,
  },
} as const;

export const USER_URLS = {
  CMS_NEW_PAGE_PLAYBOOK: {
    URL: '/cms-new-page-playbook',
    NAME: 'CMS_NEW_PAGE_PLAYBOOK',
    level: HEADER_LEVEL.ONE,
  },
  HOMEPAGE: { URL: '/', NAME: 'HOME_PAGE_NAME_LABEL', level: HEADER_LEVEL.ONE },
  SUBCATALOGUE: {
    URL: '/catalogue/subcatalogue',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  SUBCATALOGUE_TP: {
    URL: '/catalogue/subcatalogue/tp/all',
    NAME: 'TP_CAROUSEL_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  DETAILS_TP: {
    URL: '/tp/detail/:tpId',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.THREE,
  },
  SUBCATALOGUE_SEARCH: {
    URL: '/catalogue/subcatalogue/search',
    NAME: 'SEARCH_RESULTS_FOR',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_PAGE: {
    URL: '/catalogue/:category/:brand',
    URL_ROUTER: '/catalogue/:category(l1|channel|brand)/:brand',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_PAGE_BYC_OFFICE: {
    URL: '/build-your-career/people-development/:brand',
    URL_ROUTER: '/build-your-career/people-development/:brand',
    NAME: 'CATALOGUE_BYC_OFFICE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_TP_SUBCATALOGUE_PAGE: {
    URL: '/catalogue/subcatalogue/tp/:category/:brand',
    URL_ROUTER: '/catalogue/subcatalogue/tp/:category(l1|channel|brand)/:brand',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_SUBCATALOGUE_PAGE: {
    URL: '/catalogue/subcatalogue/:category/:brand',
    URL_ROUTER: '/catalogue/subcatalogue/:category(l1|channel|brand)/:brand',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_CAROUSEL_PAGE: {
    URL: '/catalogue/subcatalogue/:category/:brand/carousel/:carouselKeyTitle',
    URL_ROUTER:
      '/catalogue/subcatalogue/:category(l1|channel|brand)/:brand/carousel/:carouselKeyTitle',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  BRAND_L2_PAGE: {
    URL: '/catalogue/subcatalogue/:category/:brand/l2/:l2Id',
    URL_ROUTER: '/catalogue/subcatalogue/:category(l1|channel|brand)/:brand/l2/:l2Id',
    NAME: 'CATALOGUE_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE: { URL: '/profile', NAME: 'MY_LEONARDO', level: HEADER_LEVEL.TWO },
  PROFILE_TO_DO: { URL: '/profile/todo', NAME: 'PROFILE_TO_DO', level: HEADER_LEVEL.TITLE },
  PROFILE_UPCOMING_EVENTS: {
    URL: '/profile/upcoming',
    NAME: 'UPCOMING_LABEL_HP_RED',
    level: HEADER_LEVEL.ONE,
  },
  PROFILE_MY_PROGRESS: {
    URL: '/profile/mycompletions',
    NAME: 'PROFILE_MY_PROGRESS',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_MY_CERTIFICATES: {
    URL: '/profile/mycertificates',
    NAME: 'MY_CERTIFICATES',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_MY_CHANNELS: {
    URL: '/profile/mychannels',
    NAME: 'MY_CHANNELS',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_MY_TEAM: { URL: '/profile/myteam', NAME: 'PROFILE_MY_TEAM', level: HEADER_LEVEL.TWO },
  PROFILE_MY_TEAM_MEMBER: {
    URL: '/profile/myteam/member/:member',
    NAME: 'MY_TEAM_MEMBER_TITLE',
    NORMAL_URL: '/profile/myteam/member/',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_SETTINGS: { URL: '/profile/settings', NAME: 'PROFILE_SETTINGS', level: HEADER_LEVEL.TWO },
  PROFILE_SETTINGS_PLATFORM: {
    URL: '/profile/settings/platform',
    NAME: 'PLATFORM_SETTINGS',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_SETTINGS_CONTENT: {
    URL: '/profile/settings/content',
    NAME: 'CONTENT_PERSONALIZATION',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_SETTINGS_BILLING: {
    URL: '/profile/settings/billing',
    NAME: 'BILLING_ADDRESS',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_SETTINGS_PAYMENT: {
    URL: '/profile/settings/payment',
    NAME: 'SAVED_PAYMENT_METHODS',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_ORDER_HISTORY: {
    URL: '/profile/order/history',
    NAME: 'ORDER_HISTORY',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_ORDER_HISTORY_DETAILS: {
    URL: '/profile/order/history/:orderId/:courseId',
    NORMAL_URL: '/profile/order/history',
    NAME: 'YOUR_TOKENS',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_ORDER_HISTORY_DETAILS_OLD: {
    URL: '/profile/order/history/:orderId',
    NORMAL_URL: '/profile/order/history',
    NAME: 'YOUR_TOKENS_OLD',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_SEND_TOKEN: {
    URL: '/profile/order/send/:orderId/:token',
    NAME: 'SEND_TOKEN_FRIEND',
    NORMAL_URL: '/profile/order/send/',
    TITLE: 'SEND_TOKEN',
    level: HEADER_LEVEL.TITLE,
  },
  PROFILE_ACTIVATE_LICENSE: {
    URL: '/profile/active-license',
    NAME: 'ACTIVATE_LICENSE',
    level: HEADER_LEVEL.TWO,
  },
  PROFILE_PERSONAL_SETTINGS: {
    URL: '/profile/settings/personal-info',
    NAME: 'PROFILE_SETTINGS_TITLE',
    level: HEADER_LEVEL.THREE,
  },
  PROFILE_PERSONAL_SETTINGS_SUCCESS: {
    URL: '/profile/settings/personal-info/success',
    NAME: 'PROFILE_SETTINGS_TITLE',
    level: HEADER_LEVEL.TITLE,
  },
  WISHLIST: { URL: '/wishlist', NAME: 'WISHLIST_PAGE_NAME_LABEL', level: HEADER_LEVEL.TITLE },
  NOTIFICATIONS: { URL: '/notifications', NAME: 'NOTIFICATIONS_PAGE', level: HEADER_LEVEL.ONE },
  CONTACT_US: {
    URL: '/contact-us/:faqQuestion',
    NORMAL_URL: '/contact-us',
    NAME: 'CONTACT_SUPPORT_PAGE_NAME_LABEL',
    level: HEADER_LEVEL.TWO,
  },
  MANAGE_COOKIES: {
    URL: '/manage-cookies',
    NAME: 'MANAGE_COOKIES',
    level: HEADER_LEVEL.TWO,
  },
  LEGAL: { URL: '/legal', NAME: 'LEGAL_TITLE', level: HEADER_LEVEL.TWO },
  HOWTO: { URL: '/onboarding', NAME: 'HOWTO_SECTION_LABEL', level: HEADER_LEVEL.TWO },
  DELETE_PROFILE: { URL: '/delete-profile', NAME: 'DELETE_PROFILE', level: HEADER_LEVEL.THREE },
  NOT_FOUND: { URL: '/404', NAME: 'PAGE_NOT_FOUND', level: HEADER_LEVEL.ONE },
  CART: { URL: '/cart', NAME: 'CART', level: HEADER_LEVEL.TWO },
  CHECKOUT: { URL: '/checkout', NAME: 'CHECKOUT', level: HEADER_LEVEL.TWO },
  ORDER_CONFIRMATION: {
    URL: '/order/confirmation/:orderId',
    NAME: 'ORDER_CONFIRMATION',
    NORMAL_URL: '/order/confirmation',
    level: HEADER_LEVEL.ONE,
  },
  PLAN: { URL: '/plan', NAME: 'PLAN', level: HEADER_LEVEL.TWO },
  COMMERCIAL_OFFER: {
    URL: '/commercial-offer',
    NAME: 'COMMERCIAL_OFFER_MENU',
    level: HEADER_LEVEL.ONE,
  },
  ONBOARDING_COURSE: {
    URL: '/program',
    NAME: 'ONBOARDINGCOURSE_DOC_TITLE',
    level: HEADER_LEVEL.THREE,
  },
  ONBOARDING_COURSE_ID: {
    URL: '/program/:id',
    NAME: 'ONBOARDINGCOURSE_DOC_TITLE',
    NORMAL_URL: '/program/',
    level: HEADER_LEVEL.THREE,
  },
  EDUCATIONAL_PATHS: {
    URL: '/educational-paths',
    NAME: 'EDUCATIONAL_PATHS_TITLE',
    level: HEADER_LEVEL.ONE,
  },
  BUILD_YOUR_CAREER: {
    URL: '/build-your-career',
    NAME: 'BUILD_CAREER_TITLE',
    level: HEADER_LEVEL.ONE,
  },
  SUBJECT_MATTER_PROGRAM: {
    URL: '/build-your-career/specialty-programs',
    NAME: 'SUBJECT_MATTER_PROGRAM',
    level: HEADER_LEVEL.TWO,
  },
  SUBJECT_MATTER_PROGRAM_PROGRAM: {
    URL: '/build-your-career/specialty-programs/:upskillProgram/:id',
    NAME: 'SUBJECT_MATTER_PROGRAM',
    level: HEADER_LEVEL.THREE,
  },
  MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM: {
    URL: '/build-your-career/management-development-program/:id',
    NAME: 'MANAGEMENT_DEVELOPMENT_PROGRAM',
    level: HEADER_LEVEL.THREE,
  },
  ROLE_SPECIFIC_PROGRAM: {
    URL: '/build-your-career/:roleId',
    NAME: 'ROLE_SPECIFIC_PROGRAM_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  ROLE_SPECIFIC_PROGRAM_PROGRAM: {
    URL: '/build-your-career/:roleId/:id',
    NAME: 'ROLE_SPECIFIC_PROGRAM_TITLE',
    level: HEADER_LEVEL.THREE,
  },
  EDITORIAL_CAROUSEL: {
    URL: '/catalogue/subcatalogue/cms/:carouselName',
    NAME: 'EDITORIAL_CAROUSEL_PAGE',
    level: HEADER_LEVEL.TWO,
  },
  EDITORIAL_PLAN_CAROUSEL: {
    URL: '/catalogue/subcatalogue/plan/cms/:carouselName',
    NAME: 'EDITORIAL_PLAN_CAROUSEL_PAGE',
    level: HEADER_LEVEL.TWO,
  },
  LT_PAGE: { URL: '/tag/:ltName', NAME: 'LT_PAGE', level: HEADER_LEVEL.TWO },
  LATEST_COLLECTIONS: {
    URL: '/latest-collections',
    NAME: 'LATEST_COLLECTIONS_TITLE',
    level: HEADER_LEVEL.ONE,
  },
  HIGHLIGHTS_COLLECTIONS: {
    URL: '/highlights-collections',
    NAME: 'HIGHLIGHTS_COLLECTIONS_MENU_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  TO_BE_CONTINUED_SUBCATALOGUE: {
    URL: '/tobecontinued',
    NAME: 'HIGHLIGHTS_COLLECTIONS_MENU_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  MORE_PREMIUM_CONTENT_PAGE: {
    URL: '/morepremiumcontent',
    NAME: 'MORE_PREMIUM_CONTENT_CAROUSEL_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  COLLECTION: {
    URL: '/collection/:collectionId/:brandId',
    NAME: 'COLLECTION_PAGE_TITLE',
    NORMAL_URL: '/collection/',
    level: HEADER_LEVEL.TWO,
  },
  SUBCATALOGUE_NOVELTIES_STORY: {
    URL: '/catalogue/subcatalogue/novelties/:release/:brandId/:storyId',
    NAME: 'CAROUSEL_BROWSE_BY_STORIES_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  EMPTY_PAGE: { URL: '/emptypage', NAME: 'EMPTY_PAGE_TITLE', level: HEADER_LEVEL.THREE },
  COLLECTIONS_TAB: {
    URL: '/latest-collections',
    NAME: 'COLLECTIONS_LABEL',
    level: HEADER_LEVEL.ONE,
  },
  USER_GROUP_SIMULATION: {
    URL: '/personification',
    NAME: 'PROFILE_PERSONIFICATION_TITLE',
    level: HEADER_LEVEL.TWO,
  },
  CMS_NEW_PAGE: {
    URL: '/content/*',
    NAME: 'CMS_NEW_PAGE',
    level: HEADER_LEVEL.ONE,
  },
  RECOMMENDED_IN_TO_DO: {
    URL: '/profile/recommended',
    NAME: 'RECOMMENDED_IN_TODO_TITLE',
    level: HEADER_LEVEL.TITLE
  },
  CMS_NEW_PAGE_PODCAST: {
    URL: '/content/podcast-vodcast',
    NAME: 'CMS_NEW_PAGE_PODCAST',
    level: HEADER_LEVEL.ONE,
  },
} as const;

export const CANCELLATION_POLICY_URL = USER_URLS.LEGAL.URL;
export const REFUND_POLICY_URL = USER_URLS.LEGAL.URL;

export const PROFILE_ROUTES = [
  USER_URLS.PROFILE.URL,
  USER_URLS.PROFILE_TO_DO.URL,
  //USER_URLS.PROFILE_RECOMMENDED_IN_TO_DO.URL,
  USER_URLS.PROFILE_UPCOMING_EVENTS.URL,
  USER_URLS.PROFILE_MY_PROGRESS.URL,
  USER_URLS.PROFILE_MY_CERTIFICATES.URL,
  USER_URLS.PROFILE_MY_CHANNELS.URL,
  USER_URLS.PROFILE_MY_TEAM.URL,
  USER_URLS.PROFILE_SETTINGS.URL,
  USER_URLS.PROFILE_PERSONAL_SETTINGS.URL,
  USER_URLS.PROFILE_PERSONAL_SETTINGS_SUCCESS.URL,
  USER_URLS.PROFILE_ORDER_HISTORY.URL,
  USER_URLS.PROFILE_ACTIVATE_LICENSE.URL,
  USER_URLS.PROFILE_SETTINGS_PLATFORM.URL,
  USER_URLS.PROFILE_SETTINGS_CONTENT.URL,
  USER_URLS.PROFILE_SETTINGS_BILLING.URL,
] as const;

export const UtagViewPages = {
  LOGIN: {
    Page_Section2: PageTitles.LOGIN_TITLE,
    Page_Section1: PageSections.login,
    Page_Type: PageTypes.static,
    id: TealiumEventsDataLayer.page_view,
  },
  HOMEPAGE: {
    Page_Type: PageTypes.home,
    Page_Section1: PageSections.home,
    id: TealiumEventsDataLayer.page_view,
  },
  LANDING: {
    Page_Section2: PageTitles.LANDING,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.login,
    id: TealiumEventsDataLayer.page_view,
  },
  REGISTER: {
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.login,
    Page_Section2: PageTitles.REGISTER,
    id: TealiumEventsDataLayer.page_view,
  },
  ONBOARDING_HOW_TO: {
    Page_Section2: PageTitles.ONBOARDING_COURSE,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.other,
    id: TealiumEventsDataLayer.page_view,
  },
  CLP: {
    Page_Section2: PageTitles.CATALOGUE_TITLE,
    Page_Type: PageTypes.clp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.category_view,
  },
  HIGHLIGHTS_COLLECTIONS: {
    Page_Section2: PageTitles.HIGHLIGHTS_COLLECTIONS,
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.catalog,
    //page_category: PageSections.highlightscollections,
    id: TealiumEventsDataLayer.page_view,
  },
  CATALOGUE: {
    Page_Section2: PageTitles.FULL_CATALOG,
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.subcatalog,
    id: TealiumEventsDataLayer.page_view,
  },
  CATALOGUE_TP: {
    Page_Section2: PageTitles.NOVELTIES,
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.subcatalog,
    id: TealiumEventsDataLayer.page_view,
  },
  SUBCATALOGUE_TP: {
    Page_Section2: PageTitles.NEW_TO_YOUR_STORE,
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.subcatalog,
    id: TealiumEventsDataLayer.page_view,
  },
  PRODUCT_TP: {
    Page_Section2: PageTitles.PILL,
    Page_Type: PageTypes.pdp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  SEARCH: {
    Page_Section2: PageTitles.SEARCH_TITLE,
    Page_Type: PageTypes.search,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,

  },
  SEARCH_RESULTS_MODAL: {
    Page_Section1: PageSections.subcatalog,
    Page_Section2: PageTitles.RESULTS,
    Page_Type: PageTypes.search,
    id: TealiumEventsDataLayer.page_view,
  },
  SEARCH_RESULTS: {
    Page_Section2: PageTitles.CATALOGUE_TITLE,
    Page_Type: PageTypes.search_results,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.search,
  },
  PRODUCT: {
    Page_Section2: PageTitles.HOMEPAGE_TITLE,
    Page_Type: PageTypes.pdp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  ACCOUNT: {
    Page_Section1: PageSections.account,
    Page_Type: PageTypes.static,
    id: TealiumEventsDataLayer.page_view,
  },
  FAVOURITE: {
    Page_Section2: PageTitles.WISHLIST_TITLE_ANALYTICS,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.account,
    id: TealiumEventsDataLayer.page_view,
  },
  ALERT: {
    Page_Type: PageTypes.notifications,
    Page_Section1: PageSections.account,
    id: TealiumEventsDataLayer.page_view,
  },
  STATIC_CONTENT: {
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.other,
    id: TealiumEventsDataLayer.page_view,
  },
  NOT_FOUND: {
    Page_Section1: PageSections.notfound,
    Page_Type: PageTypes.error,
    id: TealiumEventsDataLayer.page_view,
    //error_type: ErrorTypes.user,
    //error_description: '404 page not found',
  },
  MAINTENANCE: {
    Page_Section2: PageTitles.MAINTENANCE_TITLE,
    Page_Type: PageTypes.maintenance,
    id: TealiumEventsDataLayer.error,
    error_type: ErrorTypes.server,
    error_description: 'website will be back soon',
  },
  COMPABILITY: {
    Page_Section2: PageTitles.COMPATIBILITY_TITLE,
    Page_Type: PageTypes.compatibility,
    id: TealiumEventsDataLayer.error,
    error_type: ErrorTypes.frontend,
  },
  COMMERCIAL_OFFER: {
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.commercialoffer,
    id: TealiumEventsDataLayer.page_view,
  },
  PLAN: {
    Page_Section1: PageSections.plan,
    Page_Type: PageTypes.pdp,
    id: TealiumEventsDataLayer.page_view,
  },
  EMPTY_CART: {
    Page_Section2: PageTitles.CART,
    Page_Type: PageTypes.cart,
    id: TealiumEventsDataLayer.cart_empty,
  },
  CART: {
    Page_Section1: PageSections.cart,
    Page_Type: PageTypes.cart,
    id: TealiumEventsDataLayer.page_view,
  },
  CART_EXPIRED: {
    Page_Section2: PageTitles.CART,
    Page_Type: PageTypes.cart,
    id: TealiumEventsDataLayer.cart_view,
    error_type: ErrorTypes.product,
    error_description: 'product expired',
    error_message: "One or more items in your cart has expired and you can't proceed to checkout",
  },
  CHECKOUT_BILLING: {
    Page_Section2: PageTitles.STANDARD,
    Page_Type: PageTypes.delivery,
    Page_Section1: PageSections.checkout,
    id: TealiumEventsDataLayer.page_view,
  },
  CHECKOUT_PAYMENT: {
    Page_Section2: PageTitles.STANDARD,
    Page_Type: PageTypes.payment,
    Page_Section1: PageSections.checkout,
    id: TealiumEventsDataLayer.page_view,
  },
  ORDER_CONFIRMATION: {
    Page_Section2: PageTitles.STANDARD,
    Page_Type: PageTypes.thankyou,
    Page_Section1: PageSections.checkout,
    id: TealiumEventsDataLayer.page_view,
  },
  ACTIVATE_LICENSE: {
    Page_Section2: PageTitles.PROFILE_ACTIVATE_LICENSE_ANALYTICS,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.account,
    id: TealiumEventsDataLayer.page_view,
  },
  HISTORY: {
    Page_Section2: PageTitles.PROFILE_ORDER_HISTORY_ANALYTICS,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.account,
    id: TealiumEventsDataLayer.page_view,
  },
  TOKEN: {
    Page_Section2: PageTitles.YOUR_LICENSES_ANALYTICS,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.account,
    id: TealiumEventsDataLayer.page_view,
  },
  ONBOARDING_COURSE: {
    Page_Section2: PageTitles.ONBOARDING_COURSE,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.other,
    id: TealiumEventsDataLayer.page_view,
  },
  ONBOARDING_OVERVIEW: {
    Page_Section2: PageTitles.ONBOARDING_COURSE,
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.other,
    id: TealiumEventsDataLayer.page_view,
  },
  ONBOARDING_LESSONS: {
    Page_Section2: PageTitles.ONBOARDING_COURSE + ' - Lessons',
    Page_Type: PageTypes.onboardingLessons,
    Page_Section1: PageSections.onboarding,
    id: TealiumEventsDataLayer.page_view,
  },
  ONBOARDING_TEACHERS: {
    Page_Section2: PageTitles.ONBOARDING_COURSE + ' - Teachers',
    Page_Type: PageTypes.onboardingTeachers,
    Page_Section1: PageSections.onboarding,
    id: TealiumEventsDataLayer.page_view,
  },
  MY_TEAM: {
    Page_Type: PageTypes.managerTeam,
    Page_Section1: PageSections.onboarding,
    id: TealiumEventsDataLayer.page_view,
  },
  MY_TEAM_SINGLE_MEMBER: {
    Page_Type: PageTypes.managerUser,
    Page_Section1: PageSections.onboarding,
    id: TealiumEventsDataLayer.page_view,
  },
  EDUCATIONAL_PATHS: {
    Page_Section2: PageTitles.CERTIFICATE_PROGRAM,
    Page_Type: PageTypes.clp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  BUILD_YOUR_CAREER: {
    Page_Section2: PageTitles.BUILD_YOUR_CAREER,
    Page_Type: PageTypes.clp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  COMPETENCY_HUB_BYC_OFFICE: {
    Page_Section2: PageTitles.COMPETENCY_HUB,
    Page_Type: PageTypes.plp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  SUBJECT_MATTER_PROGRAM: {
    Page_Section2: PageTitles.SUBJECT_MATTER_PROGRAM,
    Page_Type: PageTypes.clp,
    Page_Section1: PageSections.catalog,
    id: TealiumEventsDataLayer.page_view,
  },
  ROLE_SPECIFIC_PROGRAM: {
    Page_Section1: PageSections.catalog,
    Page_Section2: PageTitles.ROLE_SPECIFIC_PROGRAM,
    Page_Type: PageTypes.plp,
    id: TealiumEventsDataLayer.page_view,
  },
  WELCOME: {
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.login,
    Page_Section2: PageTitles.WELCOME,
    id: TealiumEventsDataLayer.page_view,
  },
  WELCOME_AFTER_LOGIN: {
    Page_Type: PageTypes.welcomeHome,
    Page_Section1: PageSections.welcome,
    id: TealiumEventsDataLayer.page_view,
  },
  CMS_NEW_PAGES: {
    Page_Type: PageTypes.static,
    Page_Section1: PageSections.editorial,
    id: TealiumEventsDataLayer.page_view,
  }
}

export type TUtagViewPagesKey = keyof typeof UtagViewPages


// to group them based on the pathname
export const UtagViews: { [key: string]: Partial<DataLayerTracking> } = {
  [PUBLIC_URLS.LOGIN.URL]: UtagViewPages.LOGIN,
  [PUBLIC_URLS.REGISTER.URL]: UtagViewPages.REGISTER,
  [PUBLIC_URLS.HOMEPAGE.URL]: UtagViewPages.LANDING,
  [USER_URLS.CONTACT_US.URL]: {
    Page_Section2: PageTitles.CONTACT,
    ...UtagViewPages.STATIC_CONTENT,
  },
  [USER_URLS.MANAGE_COOKIES.URL]: {
    Page_Section2: PageTitles.COOKIES,
    ...UtagViewPages.STATIC_CONTENT,
  },
  [USER_URLS.LEGAL.URL]: {
    Page_Section2: PageTitles.LEGAL_ANALYTICS,
    ...UtagViewPages.STATIC_CONTENT,
  },
  [USER_URLS.DELETE_PROFILE.URL]: {
    Page_Section2: PageTitles.DELETE_PROFILE_ANALYTICS,
    ...UtagViewPages.ACCOUNT
  },
  [USER_URLS.NOTIFICATIONS.URL]: {
    Page_Section2: PageTitles.NOTIFICATIONS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.USER_GROUP_SIMULATION.URL]: {
    Page_Section2: PageTitles.PERSONIFICATION,
    ...UtagViewPages.ACCOUNT,
  },
  [PUBLIC_URLS.LEGAL.URL]: {
    Page_Section2: PageTitles.LEGAL_TITLE,
    ...UtagViewPages.STATIC_CONTENT,
  },
  [PUBLIC_URLS.FORGOT_PASSWORD.URL]: {
    ...UtagViewPages.LOGIN,
    Page_Section2: PageTitles.FORGOT_PASSWORD_TITLE,
  },
  [PUBLIC_URLS.RESET_PASSWORD.URL]: {
    ...UtagViewPages.LOGIN,
    Page_Section2: PageTitles.PASSWORD_RESET_TITLE_ANALYTICS,
    
  },
  [PUBLIC_URLS.EXPIRED_PASSWORD.URL]: {
    ...UtagViewPages.LOGIN,
    Page_Section2: PageTitles.PASSWORD_EXPIRED_TITLE_ANALYTICS,
  },
  [USER_URLS.SUBCATALOGUE_SEARCH.URL]: UtagViewPages.SEARCH,
  [USER_URLS.WISHLIST.URL]: UtagViewPages.FAVOURITE,
  [USER_URLS.PROFILE.URL]: {
    Page_Section2: PageTitles.PROFILE_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_TO_DO.URL]: {
    Page_Section2: PageTitles.TO_DO_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.RECOMMENDED_IN_TO_DO.URL]: { 
    Page_Section2: PageTitles.RECOMMENDED_IN_TODO,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_UPCOMING_EVENTS.URL]: {
    Page_Section2: PageTitles.UPCOMING_EVENTS_ANALYTICS,
    Page_Type: PageTypes.static,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_MY_PROGRESS.URL]: {
    Page_Section2: PageTitles.MY_PROGRESS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_MY_CERTIFICATES.URL]: {
    Page_Section2: PageTitles.MY_CERTIFICATES_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_MY_CHANNELS.URL]: {
    Page_Section2: PageTitles.MY_CHANNELS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_MY_TEAM.URL]: {
    Page_Section2: PageTitles.MY_TEAM_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_SETTINGS.URL]: {
    Page_Section2: PageTitles.SETTINGS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_SETTINGS_PLATFORM.URL] : {
    Page_Section2: PageTitles.PROFILE_SETTINGS_PLATFORM_ANALYTICS,
    ...UtagViewPages.ACCOUNT
  },
  [USER_URLS.PROFILE_SETTINGS_CONTENT.URL] : {
    Page_Section2: PageTitles.PROFILE_SETTINGS_CONTENT_PERSONALIZATION_ANALYTICS,
    ...UtagViewPages.ACCOUNT
  },
  [USER_URLS.PROFILE_SETTINGS_BILLING.URL] : {
    Page_Section2: PageTitles.PROFILE_SETTINGS_BILLING_ANALYTICS,
    ...UtagViewPages.ACCOUNT
  },
  [USER_URLS.PROFILE_SETTINGS_PAYMENT.URL]: {
    Page_Section2: PageTitles.PROFILE_SETTINGS_PAYMENT_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_PERSONAL_SETTINGS_SUCCESS.URL]: {
    Page_Section2: PageTitles.PERSONAL_SETTINGS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_PERSONAL_SETTINGS.URL]: {
    Page_Section2: PageTitles.PERSONAL_SETTINGS_TITLE_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [USER_URLS.PROFILE_ORDER_HISTORY.URL]: {
    Page_Section2: PageTitles.PROFILE_ORDER_HISTORY_ANALYTICS,
    ...UtagViewPages.ACCOUNT,
  },
  [PUBLIC_URLS.WELCOME.URL]: {
    Page_Section2: PageTitles.WELCOME,
    ...UtagViewPages.WELCOME,
  },
  [USER_URLS.COMMERCIAL_OFFER.URL]: {
    //Page_Section2: PageTitles.COMMERCIAL_OFFER,
    ...UtagViewPages.COMMERCIAL_OFFER,
  },
  [USER_URLS.LATEST_COLLECTIONS.URL]: {
    ...UtagViewPages.CATALOGUE,
    Page_Section1: PageSections.catalog,
    Page_Section2: PageTitles.LATEST_COLLECTIONS_ANALYTICS,
    //Page_Section3: 'all',
  },
  [USER_URLS.COLLECTION.NORMAL_URL]: {
    ...UtagViewPages.CATALOGUE,
    Page_Section2: PageTitles.COLLECTION,
    Page_Type: PageTypes.pdp,
    Page_Section1: PageSections.catalog,
  },
  [USER_URLS.SUBCATALOGUE.URL]: {
    ...UtagViewPages.CATALOGUE,
  },
  [USER_URLS.BRAND_PAGE.URL.replace(':category', 'channel').replace(':brand', '')] : {
    ...UtagViewPages.CATALOGUE,
    Page_Section1: PageSections.catalog,
    Page_Section2: PageTitles.CHANNEL,
  },
  [USER_URLS.BRAND_PAGE.URL.replace(':category', 'brand').replace(':brand', '')] : {
    ...UtagViewPages.CATALOGUE,
    Page_Section1: PageSections.catalog,
    Page_Section2: PageTitles.BRAND
  },
  [USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL.replace(':category', 'channel').replace(':brand', '')] : {
    ...UtagViewPages.CATALOGUE,
    Page_Section1: PageSections.subcatalog,
    Page_Section2: PageTitles.CHANNEL,
  },
  [USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL.replace(':category', 'brand').replace(':brand', '')] : {
    ...UtagViewPages.CATALOGUE,
    Page_Section1: PageSections.subcatalog,
    Page_Section2: PageTitles.BRAND,
  },
  [USER_URLS.EDITORIAL_CAROUSEL.URL] : {
    ...UtagViewPages.CATALOGUE,
    Page_Section2: PageTitles.CMS,

  },
  '/404': UtagViewPages.NOT_FOUND,
};

export const UtagViewsNotifications: { [key: string]: Partial<DataLayerTracking> } = {
  [USER_URLS.HOMEPAGE.URL]: {
    Page_Type: PageTypes.home,
    Page_Section2: PageSections.home
  },
  [USER_URLS.CONTACT_US.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.CONTACT,
  },
  [USER_URLS.MANAGE_COOKIES.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.COOKIES,
  },
  [USER_URLS.LEGAL.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.LEGAL_ANALYTICS,
  },
  [USER_URLS.NOTIFICATIONS.URL]: {
    Page_Type: PageTypes.notifications
  },
  [USER_URLS.DELETE_PROFILE.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.DELETE_PROFILE_ANALYTICS,

  },
  [USER_URLS.CART.URL]: {
    Page_Type: PageTypes.cart,
    Page_Section2: '',
  },
  [USER_URLS.CHECKOUT.URL]: {
    Page_Type: PageTypes.cart,
    Page_Section2: PageTitles.STANDARD,
  },
  [USER_URLS.ORDER_CONFIRMATION.URL]: {
    Page_Type: PageTypes.thankyou,
    Page_Section2: PageTitles.STANDARD
  },
  [USER_URLS.PLAN.URL]: {
    Page_Type: PageTypes.plan,
    Page_Section2: PageSections.plan
  },
  [USER_URLS.HOWTO.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.ONBOARDING_COURSE,
  },
  [USER_URLS.SUBCATALOGUE_SEARCH.URL]: {
    Page_Type: PageTypes.search,
    Page_Section2: PageTitles.RESULTS
  },
  [USER_URLS.SUBCATALOGUE.URL]: {
    Page_Type: PageTypes.plp,
    Page_Section2: PageTitles.FULL_CATALOG
  },
  [USER_URLS.SUBCATALOGUE_TP.URL]: {
    Page_Type: PageTypes.category,
    ...UtagViewPages.SUBCATALOGUE_TP
  },
  [USER_URLS.DETAILS_TP.URL]: {
    Page_Type: PageTypes.pill,
    Page_Section2: PageTitles.PILL
  },
  [USER_URLS.BRAND_PAGE.URL.replace(':category', 'brand').replace(':brand', '')]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.BRAND,
  },
  [USER_URLS.BRAND_PAGE.URL.replace(':category', 'channel').replace(':brand', '')]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.CHANNEL,
  },
  [USER_URLS.BRAND_L2_PAGE.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.BRAND,
  },
  [USER_URLS.BRAND_TP_SUBCATALOGUE_PAGE.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.NEW_TO_YOUR_STORE,
  },
  [USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL]: {
    Page_Type: PageTypes.category,
    ...UtagViewPages.CATALOGUE
  },
  [USER_URLS.EDITORIAL_CAROUSEL.URL]: {
    Page_Type: PageTypes.plp,
    Page_Section2: PageTitles.CMS
  },
  
  [USER_URLS.BRAND_CAROUSEL_PAGE.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.BRAND,
  },
  [USER_URLS.ONBOARDING_COURSE.URL]: {
    Page_Type: PageTypes.onboardingStart,
    Page_Section2: PageTitles.PAGE
  },
  [USER_URLS.ONBOARDING_COURSE_ID.URL]: {
    Page_Type: PageTypes.onboardingStart,
    Page_Section2: PageTitles.PAGE
  },
  [USER_URLS.EDUCATIONAL_PATHS.URL]: {
    Page_Type: PageTypes.educationalPaths,
    Page_Section2: PageTitles.CERTIFICATE_PROGRAM
  },
  [USER_URLS.WISHLIST.URL]: {
    Page_Type: PageTypes.favourite,
    Page_Section2: PageTitles.WISHLIST_TITLE
  },
  [USER_URLS.PROFILE.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.PROFILE_TITLE_ANALYTICS
  },
  [USER_URLS.PROFILE_TO_DO.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.TO_DO_TITLE_ANALYTICS,
  },
  [USER_URLS.RECOMMENDED_IN_TO_DO.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.RECOMMENDED_IN_TODO,
  },
  [USER_URLS.PROFILE_UPCOMING_EVENTS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.UPCOMING_EVENTS_ANALYTICS,
  },
  [USER_URLS.PROFILE_MY_PROGRESS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.MY_PROGRESS_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_MY_CERTIFICATES.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.MY_CERTIFICATES_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_MY_CHANNELS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.MY_CHANNELS_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_MY_TEAM.URL]: {
    Page_Type: PageTypes.managerTeam,
    Page_Section2: PageTitles.MY_TEAM_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_MY_TEAM_MEMBER.URL]: {
    Page_Type: PageTypes.managerTeam,
    Page_Section2: PageTitles.MY_TEAM_MEMBER_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_SETTINGS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.SETTINGS_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_SETTINGS_PLATFORM.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.PROFILE_SETTINGS_PLATFORM_ANALYTICS,
  },
  [USER_URLS.PROFILE_SETTINGS_BILLING.URL]: {
    Page_Type: PageTypes.billing,
    Page_Section2: PageTitles.PROFILE_SETTINGS_BILLING_ANALYTICS,
  },
  [USER_URLS.PROFILE_SETTINGS_PAYMENT.URL]: {
    Page_Type: PageTypes.payment,
    Page_Section2: PageTitles.PROFILE_SETTINGS_PAYMENT_ANALYTICS,
  },
  [USER_URLS.PROFILE_SETTINGS_CONTENT.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.PROFILE_SETTINGS_CONTENT_PERSONALIZATION_ANALYTICS,
  },
  [USER_URLS.PROFILE_PERSONAL_SETTINGS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.PERSONAL_SETTINGS_TITLE_ANALYTICS,
  },
  [USER_URLS.PROFILE_ORDER_HISTORY.URL]: {
    Page_Type: PageTypes.history,
    Page_Section2: PageTitles.PROFILE_ORDER_HISTORY_ANALYTICS,
  },
  [USER_URLS.PROFILE_ORDER_HISTORY_DETAILS.URL]: {
    Page_Type: PageTypes.history,
    Page_Section2: PageTitles.YOUR_LICENSES_ANALYTICS,
  },
  [USER_URLS.PROFILE_ACTIVATE_LICENSE.URL]: {
    Page_Type: PageTypes.history,
    Page_Section2: PageTitles.PROFILE_ACTIVATE_LICENSE_ANALYTICS,
  },
  [USER_URLS.PROFILE_PERSONAL_SETTINGS_SUCCESS.URL]: {
    Page_Type: PageSections.account,
    Page_Section2: PageTitles.PERSONAL_SETTINGS_TITLE_ANALYTICS,
  },
  [USER_URLS.COMMERCIAL_OFFER.URL]: {
    Page_Type: PageTypes.commercial_offer,
    Page_Section2: 'CommercialOffer', //not suggested by analytics team
  },
  [USER_URLS.LATEST_COLLECTIONS.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.LATEST_COLLECTIONS_ANALYTICS,
  },
  [USER_URLS.HIGHLIGHTS_COLLECTIONS.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.HIGHLIGHTS_COLLECTIONS,
  },
  [USER_URLS.TO_BE_CONTINUED_SUBCATALOGUE.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.COLLECTION,
  },
  [USER_URLS.COLLECTION.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.COLLECTION,
  },
  [USER_URLS.BUILD_YOUR_CAREER.URL]: {
    Page_Type: PageTypes.clp,
    Page_Section2: PageTitles.BUILD_YOUR_CAREER,
  },
  [USER_URLS.SUBJECT_MATTER_PROGRAM.URL]: {
    Page_Type: PageTypes.clp,
    Page_Section2: PageTitles.SUBJECT_MATTER_PROGRAM,
  },
  [USER_URLS.ROLE_SPECIFIC_PROGRAM.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.ROLE_SPECIFIC_PROGRAM,
  },
  [USER_URLS.SUBCATALOGUE_NOVELTIES_STORY.URL]: {
    Page_Type: PageTypes.category,
    Page_Section2: PageTitles.NOVELTIES
  },
  [USER_URLS.USER_GROUP_SIMULATION.URL]: {
    Page_Type: PageTypes.static,
    Page_Section2: PageTitles.PERSONIFICATION
  },
  '/404': UtagViewPages.NOT_FOUND,
};

export const styledLogUtagView = (
  title: string,
  data: Partial<DataLayerTracking | EventsTracking | ErrorTracking | DataTrackClickObject>
) => {
  console.log(
    `%c ${title}`,
    'color: #bada55; font-size: 20px',
    new Date().toISOString().slice(11, 23),
    data
  );
};

export const pageIdToUrl = {
  homepage: USER_URLS.HOMEPAGE.URL,
  subcatalogue: USER_URLS.SUBCATALOGUE.URL,
  subcatalogue_tp: USER_URLS.SUBCATALOGUE_TP.URL,
  search: USER_URLS.SUBCATALOGUE_SEARCH.URL,
  brand: USER_URLS.BRAND_PAGE.URL,
  profile_dashboard: USER_URLS.PROFILE.URL,
  profile_settings: USER_URLS.PROFILE_SETTINGS.URL,
  profile_platform: USER_URLS.PROFILE_SETTINGS_PLATFORM.URL,
  profile_content: USER_URLS.PROFILE_SETTINGS_CONTENT.URL,
  profile_billing: USER_URLS.PROFILE_SETTINGS_BILLING.URL,
  profile_personal_info: USER_URLS.PROFILE_PERSONAL_SETTINGS.URL,
  order_history: USER_URLS.PROFILE_ORDER_HISTORY.URL,
  cart: USER_URLS.CART.URL,
  commercial_offer: USER_URLS.COMMERCIAL_OFFER.URL,
  plan: USER_URLS.PLAN.URL,
  activate_license: USER_URLS.PROFILE_ACTIVATE_LICENSE.URL,
  team: USER_URLS.PROFILE_MY_TEAM.URL,
  channels: USER_URLS.PROFILE_MY_CHANNELS.URL,
  todo: USER_URLS.PROFILE_TO_DO.URL,
  upcoming_events: USER_URLS.PROFILE_UPCOMING_EVENTS.URL,
  progress: USER_URLS.PROFILE_MY_PROGRESS.URL,
  certificates: USER_URLS.PROFILE_MY_CERTIFICATES.URL,
  wishlist: USER_URLS.WISHLIST.URL,
  contact: USER_URLS.CONTACT_US.URL,
  manage_cookies: USER_URLS.MANAGE_COOKIES.URL,
  legal: USER_URLS.LEGAL.URL,
  howto: USER_URLS.HOWTO.URL,
} as const;
export const paramIdToParam = {
  [ParamsPage.category]: 'category',
  [ParamsPage.idBrand]: 'brand',
  [ParamsPage.searchQuery]: 'searchQuery',
  [ParamsPage.months]: 'months',
  [ParamsPage.collections]: 'collections',
  [ParamsPage.characterics]: 'characteristics',
  [ParamsPage.characteristics]: 'characteristics',
  [ParamsPage.brands]: 'brand',
  [ParamsPage.categories]: 'categories',
  [ParamsPage.sortings]: 'sortings',
  [ParamsPage.Chip]: 'filterSection',
  [ParamsPage.L1]: 'brand',
  [ParamsPage.Tipology]: 'ctype',
  [ParamsPage.language]: 'language',
  [ParamsPage.Status]: 'status',
  [ParamsPage.Duration]: 'duration',
  [ParamsPage.teacher]: 'filterTeacher',
  [ParamsPage.location]: 'location',
  [ParamsPage.schedule]: 'schedule',
  [ParamsPage.price]: 'price',
  [ParamsPage.offerPlan]: 'offerPlan',
  [ParamsPage.expiration]: 'expiration',
  [ParamsPage.others]: 'others',
  [ParamsPage.Ordinamento]: 'sortBy',
} as const;

export const catalogParamKeysMapping = {
  section: queryParams.FILTER_SECTION,
};

export const catalogParamValuesMapping = {};

export const tpPillsParamKeysMapping = {
  brands: 'brand',
};

export const tpPillsParamValuesMapping = {};

export const isProfileProgressOrCertificates = (pathname: string): boolean => {
  return [USER_URLS.PROFILE_MY_PROGRESS.URL, USER_URLS.PROFILE_MY_CERTIFICATES.URL].includes(
    pathname as any
  );
};

export const isProfileCertificates = (pathname: string): boolean => {
  return [USER_URLS.PROFILE_MY_CERTIFICATES.URL].includes(pathname as any);
};

export const isBrandPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, { path: USER_URLS.BRAND_PAGE.URL_ROUTER, exact: true });
};

export const isBrandPageBycOffice = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, { path: USER_URLS.BRAND_PAGE_BYC_OFFICE.URL_ROUTER, exact: true });
};

export const isBrandSubcataloguePage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, { path: USER_URLS.BRAND_SUBCATALOGUE_PAGE.URL_ROUTER, exact: true });
};
export const isBrandTPSubcataloguePage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, {
    path: USER_URLS.BRAND_TP_SUBCATALOGUE_PAGE.URL_ROUTER,
    exact: true,
  });
};

export const isBrandCarouselPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, { path: USER_URLS.BRAND_CAROUSEL_PAGE.URL_ROUTER, exact: true });
};

export const isBrandL2Page = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }

  return matchPath(pathname, { path: USER_URLS.BRAND_L2_PAGE.URL_ROUTER, exact: true });
};

export const isPlpTpPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }

  return pathname === USER_URLS.SUBCATALOGUE_TP.URL;
};

export const isWishlistPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }

  return pathname === USER_URLS.WISHLIST.URL;
};

export const isCartPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return matchPath(pathname, { path: USER_URLS.CART.URL, exact: true });
};

export const isSearchPage = (pathname: string): boolean => {
  if (!pathname) {
    return false;
  }
  return (
    matchPath(pathname, { path: USER_URLS.SUBCATALOGUE_SEARCH.URL, exact: true }) &&
    !window.location.search.includes(queryParams.COURSE_DETAIL)
  );
};

export const isEditorialPage = (pathname: string): boolean => {
  if(!pathname) {
    return false
  }
  return (matchPath(pathname, {path: USER_URLS.EDITORIAL_CAROUSEL.URL, exact: true}));
}

export const isSubjectMatterProgramPage = (pathname: string): boolean => {
  if(!pathname) {
    return false
  }
  return (matchPath(pathname, {path: USER_URLS.SUBJECT_MATTER_PROGRAM.URL, exact: true}));
}

export const isProgramPage = (pathname: string): boolean => {
  if(!pathname) {
    return false
  }
  return (matchPath(pathname, {path: [
    USER_URLS.SUBJECT_MATTER_PROGRAM_PROGRAM.URL,
    USER_URLS.ROLE_SPECIFIC_PROGRAM_PROGRAM.URL,
    USER_URLS.ONBOARDING_COURSE.URL
  ], exact: true}));
}

export const isActivateLicensePage = (pathname: string): boolean => {
  if(!pathname) {
    return false
  }
  return (matchPath(pathname, {path: USER_URLS.PROFILE_ACTIVATE_LICENSE.URL, exact: true}));
}

export const printPublicLink = type => {
  const { t } = useTranslation();
  return printPublicLinkTranslParam(type, t);
};

export const printPublicLinkTranslParam = (type, t, showArrowRight = false, dataElementId = '') => {
  const history = useHistory();
  if (PUBLIC_URLS[type]) {
    return (
      <>
        <NavLink
          to={PUBLIC_URLS[type].URL}
          activeClassName="active"
          exact
          data-element-id={dataElementId}
        >
          {t(PUBLIC_URLS[type].NAME)}
        </NavLink>
        {showArrowRight && (
          <div className="footer-arrow-right" onClick={() => history.push(USER_URLS[type].URL)}>
            <ArrowRight />
          </div>
        )}
      </>
    );
  } else return '';
};
export const printLink = (
  type: keyof typeof USER_URLS,
  lang,
  forceLabel = '',
  showArrow = false,
  showArrowRight = false,
  header= false,
  track = true
) => {
  const analyticsType = type === 'PROFILE_UPCOMING_EVENTS' ? 'Live Events' : type;
  if (USER_URLS[type]) {
    return (
      <>
        <NavLink to={USER_URLS[type].URL} activeClassName="active" exact onClick ={track? (()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang[USER_URLS[type].NAME] ))}) : null}>
          {showArrow && (
            <span className="arrow-active">
              <ArrowLeftIcon />
            </span>
          )}
          {forceLabel ? forceLabel : lang[USER_URLS[type].NAME] || ''}
        </NavLink>
        {showArrowRight && (
          <a className="footer-arrow-right" href={USER_URLS[type].URL}>
            <ArrowRight />
          </a>
        )}
      </>
    );
  } else return '';
};
// LLEO-925
export const printHeaderLink = (
  type: keyof typeof USER_URLS,
  lang,
  showActiveBorderBottom,
  forceLabel = '',
  showArrow = false,
  showArrowRight = false
) => {
  const analyticsTypeUserUrls = type === 'BUILD_YOUR_CAREER' ? 'Build Your Career' : type;
  if (USER_URLS[type]) {
    return (
      <>
        <NavLink
          to={USER_URLS[type].URL}
          activeClassName={`active${showActiveBorderBottom ? '_headerLinkBorderBottom' : ''}`}
          exact
          data-element-id={`mainNav_${purifyItemDataElementIdCamelCase(analyticsTypeUserUrls?.replace('_', ' ').toLocaleLowerCase())}`}
        >
          {showArrow && (
            <span className="arrow-active">
              <ArrowLeftIcon />
            </span>
          )}
          {forceLabel ? forceLabel : lang[USER_URLS[type].NAME] || ''}
        </NavLink>
        {showArrowRight && (
          <a className="footer-arrow-right" href={USER_URLS[type].URL}>
            <ArrowRight />
          </a>
        )}
      </>
    );
  } else return '';
};

export const BYC_ACTIVE_URLS = {
  BUILD_YOUR_CAREER: "build-your-career",
  UPSKILL_PROGRAMS: "specialty-programs"
}