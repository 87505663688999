import { SKELETON_COLORS } from "@utility/const";
import React from "react"
import ContentLoader from "react-content-loader"

type TextSkeletonProps = {
  className?: string;
  width?: number;
  height?: number;
  borderRadius?: number;
}

const TextSkeleton = ({ className = "", width = 300, height = 25, borderRadius = 0 }: TextSkeletonProps) => {
  return (
    <div className={"skeleton-text " + className} style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
      >
        <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={width + ""} height={height + ""} />
      </ContentLoader>
    </div>
  )
}

export default TextSkeleton