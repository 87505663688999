import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
const axios = require('axios');
import { getHeaders } from '../../utility/Api';
import { showError } from './utilsActions';
import {
  ChannelInfoObject,
  SearchResult,
  SearchResultWithTP,
  SearchTPResult,
} from '@model/SearchResult';
import { getL1Images } from './cmsActions';
import {
  INITIAL_FILTER_NOVELTIES_SEARCH,
  INITIAL_FILTER_TP_SEARCH,
  RequestGetProducts,
  ResponseGetProducts,
  SORTBY_TP_ELEMENTS,
  TrainingPillResponse,
} from '@model/ProductClass';
import { TP_CATALOGUE_PAGINATION } from '@utility/const';
import {
  getNoveltiesCatalogueFailure,
  getNoveltiesCatalogueRequest,
  getNoveltiesCatalogueSuccess,
  getTrainingPillsCatalogueFailure,
  getTrainingPillsCatalogueRequest,
  getTrainingPillsCatalogueSuccess,
} from './trainingPillsActions';
import { transformNoveltiesIntoTrainingPills } from '@utility/CollectionsUtility';
import { promiseMock } from '@utility/ecommerceUtility';
import { BrowseByTopicInfo } from '@model/BrowseByTopicInfo';

export const getSuggestedTerms = () => {
  return dispatch => {
    let url = urlList.GET_SUGGESTED_TERMS_URL;

    dispatch(request());

    axios
      .get(url, { headers: getHeaders() })
      .then(response => {
        let data: SearchResult = response.data;
        console.log('getSuggestedTerms response', data);

        dispatch(success(data.suggestedTerms));
      })
      .catch(err => {
        console.error('getSuggestedTerms error', err);
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_SUGGESTED_TERMS_REQ,
    };
  }

  function success(suggestedTerms) {
    return {
      type: actionTypes.GET_SUGGESTED_TERMS_SUCCESS,
      suggestedTerms,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_SUGGESTED_TERMS_FAILURE,
    };
  }
};

export const getSearchCourses = (
  searchQuery: string,
  isUserEnabledForTrainingPills: boolean = false,
  isUserEnabledForCollections: boolean = false,
  isSearchPLP: boolean = false,
  isSearchSpell: boolean = false,
  useSpellFallback: boolean = true,
  forceNoCourseSuggestion: boolean = false
  // filters: ResponseGetProducts = null, from: number = 0, totalTrainingPills: number = null
) => {
  return (dispatch, getState) => {
    if (searchQuery === '' || searchQuery?.length < 3) {
      //clean search if the user has typed less than 3 characters
      dispatch(resetSearchCourses());
      return;
    } else if (!searchQuery) {
      return;
    }

    dispatch(request(searchQuery, isSearchSpell));

    let url = isSearchSpell ? urlList.GET_SEARCH_COURSES_SPELL_URL : urlList.GET_SEARCH_COURSES_URL;
    //if user is enabled for training pills --> request courses and training pills
    //otherwise request just courses
    const isUserEnabledForTrainingPillsOrCollections =
      isUserEnabledForTrainingPills || isUserEnabledForCollections;
    if (isUserEnabledForTrainingPillsOrCollections) {
      url = isSearchSpell
        ? urlList.GET_SEARCH_COURSES_TP_SPELL_URL
        : urlList.GET_SEARCH_COURSES_TP_URL;
    }
    url += '?text=' + encodeURI(searchQuery);

    const from = 0;
    if (isUserEnabledForTrainingPillsOrCollections) {
      url += '&start=' + from;
      url += '&recordPerPage=' + TP_CATALOGUE_PAGINATION;
    }

    let to = from + TP_CATALOGUE_PAGINATION;
    const totalTrainingPills = null;
    if (totalTrainingPills && to > totalTrainingPills) {
      to = totalTrainingPills;
    }

    let payload: RequestGetProducts = new RequestGetProducts();
    payload = {
      filterPills: cloneDeep(INITIAL_FILTER_TP_SEARCH.items),
      filterNovelties: cloneDeep(INITIAL_FILTER_NOVELTIES_SEARCH.items),
    };

    dispatch(getTrainingPillsCatalogueRequest(from));
    dispatch(getNoveltiesCatalogueRequest(from));

    axios({
      url,
      method: isUserEnabledForTrainingPillsOrCollections ? 'POST' : 'GET',
      data: isUserEnabledForTrainingPillsOrCollections ? payload : null,
      headers: getHeaders(),
    })
      .then(response => {
        console.log('getSearchCourses response', response);

        let data = response.data;

        let dataCourse: SearchResult = isUserEnabledForTrainingPillsOrCollections
          ? data.searchResponse
          : data;
        let dataTrainingPills: SearchTPResult = isUserEnabledForTrainingPills
          ? data.pillsResponse
          : new SearchTPResult();
        let dataNovelties: SearchTPResult =
          isUserEnabledForCollections && data.noveltiesResponse
            ? data.noveltiesResponse
            : new SearchTPResult();

        // JUST FOR TEST - DO NOT COMMIT
        // dataCourse.courseIds = ['co1113', 'lp1'];
        // dataCourse.suggestedCourseIds = ['co1113', 'lp1'];
        // dataCourse.suggestedPhrase = 'oakley corsi';
        // dataTrainingPills.suggestionFields = cloneDeep(dataTrainingPills.searchFields);
        // dataTrainingPills.searchFields = [];
        // dataTrainingPills.suggestedPhrase = 'oakley';

        // dataNovelties = new SearchTPResult();
        // dataNovelties.suggestionFields = dataTrainingPills.suggestionFields;
        // dataNovelties.searchFields = cloneDeep(dataTrainingPills.searchFields);
        // if (dataNovelties.searchFields?.[0]) {
        //     dataNovelties.searchFields[0].modelName = "NOVELTIES"
        // }
        // dataNovelties.suggestedPhrase = 'ciaooooo';
        // dataNovelties.responseFilter = { elementsNum: 59, items: [] };
        // dataTrainingPills = new SearchTPResult();

        // data.channels = ['PR', 'store_operations'];
        // data.suggestedChannels = ['MU', 'smartshopper'];
        // data.trainingPills = cloneDeep(SEARCH_MOCK); //{ elementsNum: 0, items: [] }
        // data.trainingPills.items = data.trainingPills.items.slice(0, to - from);
        // data.suggestedTrainingPills = cloneDeep(SEARCH_SUGGESTED_MOCK);
        // data.suggestedTrainingPills.items = data.suggestedTrainingPills.items.slice(0, to - from);
        /*  dataCourse.suggestedChannelsInfo = [{
                     channelId: "RB",
                     numberOfEvents: 1,
                     numberOfCourses: 12,
                     numberOfLessons: 3
                 }];
                 dataCourse.channelsInfo = [{
                     channelId: "RB",
                     numberOfEvents: 1,
                     numberOfCourses: 12,
                     numberOfLessons: 3
                 }]; */

        let courseIds = dataCourse.courseIds ? dataCourse.courseIds : [];
        let searchL1s = dataCourse.channels ? dataCourse.channels : [];
        let channelsInfo = dataCourse.channels ? dataCourse.channelsInfo : [];
        let sectionL1 = dataCourse.sections ? dataCourse.sections : [];
        let searchTrainingPills: TrainingPillResponse = new TrainingPillResponse();
        let searchNovelties: TrainingPillResponse = new TrainingPillResponse();
        if (dataTrainingPills.searchFields?.length > 0) {
          searchTrainingPills.items = dataTrainingPills.searchFields;
          searchTrainingPills.elementsNum = dataTrainingPills.responseFilter?.elementsNum;
        }
        if (dataNovelties.searchFields?.length > 0) {
          //convert novelties attributes into pills attributes
          //GESTIRE DA QUA IL MOCK
          //MOCK NOVELTIES FOR COLLECTION WAVE 3
          // const mockTP = [
        //   {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/b0337399-b33f-4809-a19a-afe300f94244/9ea925d7-771e-44e6-9e1e-afe401745457/0RX7228__8198__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/b0337399-b33f-4809-a19a-afe300f94244/a1f4f308-0c15-425b-aac4-afe300f942b7/0RX7228__8198.jpg",
        //     code: "0RX72289999",
        //     modelName: "",
        //     advertising: false,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: "",
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8acded24-b1c8-4677-8746-afe300f92a73/95f35505-780c-49a5-a0eb-afe40174d857/0RX7227__8313__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/8acded24-b1c8-4677-8746-afe300f92a73/d14437b8-ff17-419c-9202-afe300f92ae6/0RX7227__8313.jpg",
        //     code: "0RX7227",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: "",
        //     brandCampaign: ["Ray-Ban"]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/c7e76e5e-98d7-422e-b194-afe30120df42/34507b29-def9-48d6-b02d-afe500088427/0RX7229__8210__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/c7e76e5e-98d7-422e-b194-afe30120df42/4534c338-9b41-4dfe-9a89-afe30120dfc5/0RX7229__8210.jpg",
        //     code: "0RX7229",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CLUBMASTER FAMILY",
        //     model: "",
        //     brandCampaign: ["Brand Campaign w/30 characters"]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1cbe1710-6adf-4f65-9c15-afe40174f2bf/c645b4fe-9687-45c3-8385-afe40174f8a6/0RX7230__5204__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1cbe1710-6adf-4f65-9c15-afe40174f2bf/234c26c3-7cbd-4188-90f9-afe40174f325/0RX7230__5204.jpg",
        //     code: "0RX7230",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "LITEFORCE",
        //     model: "",
        //     brandCampaign: ["Ray-Ban", "Brand Campaign w/30 characters"]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a01ca8b5-9b39-4029-8a6f-afe300a4c345/130ab8ef-48cd-4b76-8ffc-afe400b30947/0RB4429__601_31__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/a01ca8b5-9b39-4029-8a6f-afe300a4c345/07e69466-ce35-4224-b1ed-afe300a4c3b2/0RB4429__601_31.jpg",
        //     code: "0RB4429",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CLUBMASTER FAMILY",
        //     model: "",
        //     brandCampaign: ["Brand Campaign w/30 characters", "Ray-Ban"]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/9e4974ce-93c4-4ea3-b993-ae750175ccb3/361b97f5-b9ba-4b2f-80a4-ae7501856683/0RX6493__2944__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/9e4974ce-93c4-4ea3-b993-ae750175ccb3/ad307d45-16df-465c-a68f-ae750175cd69/0RX6493__2944.jpg",
        //     code: "0RX6493",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: "",
        //     brandCampaign: ["Ray-Ban", "Ray-Ban", "Ray-Ban"]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/89c2526a-4c1f-4678-90a8-ae7d00604ebc/8dca85c7-551f-4c71-9dc3-ae7d00b8bcd2/0RX5428__2034__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/89c2526a-4c1f-4678-90a8-ae7d00604ebc/ac621505-fee6-4b31-96b0-ae7d00604f94/0RX5428__2034.jpg",
        //     code: "0RX5428",
        //     modelName: "",
        //     advertising: false,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: "",
        //     brandCampaign: ["Brand Campaign w/30 characters", "Brand Campaign w/30 characters", "Brand Campaign w/30 characters" ]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/efc990b9-8cf4-469e-9a9e-afe300f8ff77/4fddbb99-c900-4c1f-afdc-afe40173b9ba/0RX6513__3135__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/efc990b9-8cf4-469e-9a9e-afe300f8ff77/1aa89f25-78d9-401b-a30a-afe300f8ffc8/0RX6513__3135.jpg",
        //     code: "0RX6513",
        //     modelName: "",
        //     advertising: true,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "LITEFORCE",
        //     model: "",
        //     brandCampaign: ["Brand Campaign w/30 characters", "Brand Campaign w/30 characters", "Brand Campaign w/30 characters" ]
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e43b0c1-29a5-4018-a8f3-afe300a4b9bc/2a42b314-b541-41e4-8284-afe400b48017/0RB4428__894_57__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/1e43b0c1-29a5-4018-a8f3-afe300a4b9bc/a08b049c-eda0-4e75-8bad-afe300a4ba45/0RB4428__894_57.jpg",
        //     code: "0RB4428",
        //     modelName: "",
        //     advertising: false,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: ""
        // },
        // {
        //     urlImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/19c51e94-bcc2-43fa-ac32-afe300a51746/87ab8791-7cf0-4569-bf75-afe400ae1138/0RB2204__901_32__P21__shad__fr.png?imwidth=300",
        //     urlAnnotatedImage: "https://leonardo-im2.luxottica.com/cdn-record-files-pi/19c51e94-bcc2-43fa-ac32-afe300a51746/6c68ae7b-97e8-4198-a6b0-afe300a517d3/0RB2204__901_32.jpg",
        //     code: "0RB2204",
        //     modelName: "",
        //     advertising: false,
        //     brandName: "Ray-Ban",
        //     brand: "RB",
        //     marketingTheme: "CASUAL CLASSIC",
        //     model: ""
        // },
          // ]
          //const mockForBC = [...mockTP, ...dataNovelties.searchFields.slice(0, 10)]
          searchNovelties.items = transformNoveltiesIntoTrainingPills(dataNovelties.searchFields);
          searchNovelties.elementsNum = dataNovelties.responseFilter?.elementsNum;
        }

        const noCoursesToShow =
          courseIds.length === 0 &&
          searchTrainingPills.elementsNum === 0 &&
          searchNovelties.elementsNum === 0 &&
          (!isSearchPLP || searchL1s.length === 0);
        if (noCoursesToShow && !isSearchSpell && useSpellFallback) {
          dispatch(
            getSearchCourses(
              searchQuery,
              isUserEnabledForTrainingPills,
              isUserEnabledForCollections,
              isSearchPLP,
              true
            )
          );
        }

        let suggestedPhrase = '';
        let suggestedPhraseTP = '';
        let suggestedPhraseNovelties = '';
        if (noCoursesToShow) {
          if (!forceNoCourseSuggestion) {
            courseIds = dataCourse.suggestedCourseIds;
            searchL1s = dataCourse.suggestedChannels;
            channelsInfo = dataCourse.suggestedChannelsInfo;
            searchTrainingPills.items = dataTrainingPills.suggestionFields;
            searchTrainingPills.elementsNum = dataTrainingPills.responseFilter?.elementsNum;
            searchNovelties.items = dataNovelties.suggestionFields;
            searchNovelties.elementsNum = dataNovelties.responseFilter?.elementsNum;
          }

          //if suggested phrase is equal to searchQuery --> ignore it
          if (
            ((dataCourse.suggestedPhrase &&
              searchQuery.toLowerCase() !== dataCourse.suggestedPhrase.toLowerCase()) ||
              (dataTrainingPills.suggestedPhrase &&
                searchQuery.toLowerCase() !== dataTrainingPills.suggestedPhrase.toLowerCase()) ||
              (dataNovelties.suggestedPhrase &&
                searchQuery.toLowerCase() !== dataNovelties.suggestedPhrase.toLowerCase())) &&
            (dataCourse.suggestedCourseIds?.length > 0 ||
              dataCourse.suggestedChannels?.length > 0 ||
              dataTrainingPills.suggestionFields?.length > 0 ||
              dataNovelties.suggestionFields?.length > 0)
          ) {
            suggestedPhrase = dataCourse.suggestedPhrase;
            suggestedPhraseTP = dataTrainingPills.suggestedPhrase;
            suggestedPhraseNovelties = dataNovelties.suggestedPhrase;
          }
        }

        //get L1 images if some L1 has to be shown
        if (isSearchPLP && searchL1s?.length > 0) {
          dispatch(getL1Images(searchL1s));
        }

        //if the BE returns more than expected
        let toTrainingPills = to;
        searchTrainingPills.items = searchTrainingPills.items?.slice(0, toTrainingPills - from);
        if (toTrainingPills > searchTrainingPills.elementsNum) {
          toTrainingPills = searchTrainingPills.elementsNum;
        }
        let toNovelties = to;
        searchNovelties.items = searchNovelties.items?.slice(0, toNovelties - from);
        if (toNovelties > searchNovelties.elementsNum) {
          toNovelties = searchNovelties.elementsNum;
        }

        dispatch(
          success(
            courseIds,
            searchL1s,
            channelsInfo,
            sectionL1,
            searchTrainingPills,
            from,
            toTrainingPills,
            suggestedPhrase,
            suggestedPhraseTP,
            { searchNovelties, noveltiesCatalogueFromNumber: from, suggestedPhraseNovelties },
            searchQuery
          )
        );
        dispatch(
          getTrainingPillsCatalogueSuccess(
            searchTrainingPills.items,
            from,
            toTrainingPills,
            searchTrainingPills.elementsNum
          )
        );
        dispatch(
          getNoveltiesCatalogueSuccess(
            searchNovelties.items,
            from,
            toNovelties,
            searchNovelties.elementsNum
          )
        );
      })
      .catch(err => {
        console.error('getSearchCourses error', err);
        dispatch(showError(err));
        dispatch(failure());
        dispatch(getTrainingPillsCatalogueFailure());
        dispatch(getNoveltiesCatalogueFailure());
      });
  };

  function request(searchQuery: string, isSearchSpell: boolean) {
    return {
      type: actionTypes.GET_SEARCH_COURSES_REQ,
      searchQuery,
      isSearchSpell,
    };
  }

  function success(
    searchCourseIds: string[],
    searchL1s: string[],
    channelsInfo: ChannelInfoObject[],
    sectionL1: string[],
    searchTrainingPills: TrainingPillResponse,
    trainingPillsCatalogueFromNumber: number,
    trainingPillsCatalogueToNumber: number,
    suggestedPhrase: string,
    suggestedPhraseTP: string,
    searchNoveltiesInfo: {
      searchNovelties: TrainingPillResponse;
      noveltiesCatalogueFromNumber: number;
      suggestedPhraseNovelties: string;
    },
    searchValRequest: string
  ) {
    console.log('ChannelInfo success', channelsInfo, sectionL1);
    return {
      type: actionTypes.GET_SEARCH_COURSES_SUCCESS,
      searchCourseIds,
      searchL1s,
      channelsInfo,
      sectionL1,
      searchTrainingPills,
      trainingPillsCatalogueFromNumber,
      trainingPillsCatalogueToNumber,
      suggestedPhrase,
      suggestedPhraseTP,
      ...searchNoveltiesInfo,
      searchValRequest,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_SEARCH_COURSES_FAILURE,
    };
  }
};

export const resetSearchCourses = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_SEARCH_COURSES,
    });
  };
};

export const addPreviouslySearched = (searchQuery?: string) => {
  return (dispatch, getState) => {
    if (!searchQuery) {
      searchQuery = getState().search.searchQuery;

      if (!searchQuery) {
        return;
      }
    }

    dispatch({
      type: actionTypes.ADD_PREVIOUSLY_SEARCHED,
      searchQuery,
    });
  };
};

const mockGetBrowseByTopicInfo = async () => {
  const onboardingOverview = await fetch('/data/search/searchChannels.json');
  const onboardingOverviewJson: BrowseByTopicInfo = await onboardingOverview.json();
  return promiseMock({ data: onboardingOverviewJson }, false, 3000);
};

export const getBrowseByTopicInfo = (brands: string[]) => {
  return dispatch => {
    if (!brands || brands?.length === 0) {
      return;
    }
    const mock = false;
    dispatch(request());

    const url = urlList.GET_BROWSE_BY_TOPIC_INFO_URL + '?channelList=' + brands.join(',');

    (mock ? mockGetBrowseByTopicInfo() : axios.get(url, { headers: getHeaders() }))
      .then(response => {
        let data: BrowseByTopicInfo = response.data;
        data = mergeData(data, brands);

        dispatch(success(data));
      })
      .catch(err => {
        console.error('getBrowseByTopicInfo error', err);
        dispatch(showError(err));
        const data = mergeData({}, brands);
        dispatch(failure(data));
      });
  };

  function mergeData(data: BrowseByTopicInfo, brands: string[]): BrowseByTopicInfo {
    //if brand not found --> set 0 courses, events and lessons
    brands.forEach(brand => {
      if (!data[brand]) {
        data[brand] = {
          channelId: brand,
          numberOfCourses: 0,
          numberOfEvents: 0,
          numberOfLessons: 0,
        };
      }
    });

    return data;
  }

  function request() {
    return {
      type: actionTypes.GET_BROWSE_BY_TOPIC_REQ,
    };
  }

  function success(info) {
    return {
      type: actionTypes.GET_BROWSE_BY_TOPIC_SUCCESS,
      info,
    };
  }

  function failure(info) {
    return {
      type: actionTypes.GET_BROWSE_BY_TOPIC_FAILURE,
      info,
    };
  }
};
