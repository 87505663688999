import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonV2 from '@components/UI/ButtonV2';
import { useSelector } from '@hooks/redux';
// import IconCloseBanner from "@svg-icons/close3-white.svg";
import IconInfo from "@svg-icons/info4.svg";
import LazySVG from '../../components_v3/LazySvg';
import {
	setHideBannerWasClicked,
	setExitBannerWasClicked,
	setShowDialogUserGroupSimulationPage,
	setShowDialogUserGroupSimulationHide
} from "@redux-actions/";

type BannerPersonificationProps = {
	stringToWrite: string
}

/**
 * Message that appear for ***5 seconds before closing itself*** or by x action. It opens when:
 * 1) LabelPersonificationBanner is hidden by "Hide" CTA (label = PROFILE_PERSONIFICATION_BANNER_SIMULATION_ON)
 * 2) Personification is finished                        (label = PROFILE_PERSONIFICATION_BANNER_SIMULATION_OFF)
 * 3) Personification is already on another device       (label = PROFILE_PERSONIFICATION_LABEL_WARNING_ON_START_SIMULATION_ALREADY_ON) [to verifying]
 *
 * @see LabelPersonificationBanner
 * */
const UserGroupSimulationDialog = (props: BannerPersonificationProps) => {
	const SECONDS = 5000 // 5 seconds
	const dispatch = useDispatch()

	const bShow = useSelector(state=>state.utils.showDialogUserGroupSimulationPage)
	const lang = useSelector(state => state.utils.lang);
	const showDialogUserGroupSimulationHide = useSelector(state => state.utils.showDialogUserGroupSimulationHide)
	const exitBannerWasClicked = useSelector(state => state.utils.exitBannerWasClicked)
	const exitBannerWasClickedLS = localStorage?.getItem('exitBannerWasClicked')
	// show banner after exit from simulation completed
	if(exitBannerWasClickedLS === "true")
		dispatch(setExitBannerWasClicked(true))
	
	// don't show until selector in redux is set to true
	if (!bShow && !showDialogUserGroupSimulationHide && !exitBannerWasClicked) return null

	// close itself
	const closeHandleClick = _ => {
		if(showDialogUserGroupSimulationHide) {
			dispatch(setShowDialogUserGroupSimulationHide(false))
		}
		if(exitBannerWasClicked) {
			localStorage?.removeItem('exitBannerWasClicked')
			dispatch(setExitBannerWasClicked(false))
		}
		dispatch(setShowDialogUserGroupSimulationPage(false))
	}

	//close itself after 5 seconds
	setTimeout(closeHandleClick, SECONDS)

	return (
		<div className='dialog_usergroupsimulation'>
			<div className='dialog_usergroupsimulation_icon_btn_close_container'>
				<ButtonV2 className='icon-btn' handleClick={closeHandleClick}>
					<LazySVG 
						className='dialog_usergroupsimulation_icon_close' 
						src="images/svg-icons/close3-white.svg" 
						alt='' 
					/>
				</ButtonV2>
			</div>
			<div className='dialog_usergroupsimulation_content'>
				<div className='dialog_usergroupsimulation_icon_info_container'>
					<IconInfo 
						className='dialog_usergroupsimulation_icon_info'
						width={22} 
						height={22} 
						viewBox='0 0 24 24'
					/>
				</div>
				<div className='dialog_usergroupsimulation_text_container'>
					{!showDialogUserGroupSimulationHide && !exitBannerWasClicked &&
						<span className='dialog_usergroupsimulation_text'>{props?.stringToWrite ?? lang?.PROFILE_PERSONIFICATION_BANNER_SIMULATION_ON}</span>
					}
					{showDialogUserGroupSimulationHide &&
						<span className='dialog_usergroupsimulation_text'>{lang?.PROFILE_PERSONIFICATION_BANNER_SIMULATION_ON}</span>
					}
					{exitBannerWasClicked &&
						<span className='dialog_usergroupsimulation_text'>{lang?.PROFILE_PERSONIFICATION_BANNER_SIMULATION_OFF}</span>
					}
				</div>
			</div>
		</div>
	)
}

export default UserGroupSimulationDialog;