import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAddToHomescreenPrompt } from '@hooks/useAddToHomescreenPrompt';
import Button from './UI/Button';
import useLangAuth from '@hooks/useLangAuth';
import {
  handleOnEnterKeyPress,
  isCurrentPublicPage,
  isMobile,
  isUsingDefaultScrollbar,
  printHTML,
  userRoleIsVS,
} from '@utility/Api';
import Modal from './UI/Modal';
import CardCover from './CardV3/CardCover';
import { getCookie, setCookie } from '@utility/cookie';
import { COOKIE } from '@utility/const';
import { isInStandaloneMode } from '@utility/pwaUtility';
import { PUBLIC_URLS, USER_URLS } from './link-utils';
import useGetLang from '@hooks/useGetLang';
import useHandleBodyScrollAfterModalOpen from '@hooks/useHandleBodyScrollAfterModalOpen';
import LazyImage from 'react-lazy-progressive-image';
import LazySVG from './LazySvg';
import ButtonV2 from './UI/ButtonV2';
import { UserProfile } from '@model/User';
import { useSelector } from '@hooks/redux';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';

type Props = {
  showAddToHomescreenPromptLogin: boolean;
  showAddToHomescreenPromptFooter: boolean;
};

export enum CLASS_HOME_SCREEN{
  HOMESCREEN_PROMPT_FOOTER="homescreen-prompt__footer",
  HOMESCREEN_PROMPT_HEADER="homescreen-prompt__header",
}

const AddToHomescreenPrompt = (props: Props) => {
  const labels = useLangAuth([
    'ADD_TO_HOMESCREEN_CTA',
    'ADD_TO_HOMESCREEN_MODAL_TITLE',
    'ADD_TO_HOMESCREEN_CTA_DESCRIPTION',
    'ADD_TO_HOMESCREEN_MODAL_DESCR1',
    'ADD_TO_HOMESCREEN_MODAL_DESCR2',
    'ADD_TO_HOMESCREEN_MODAL_DESCR3',
    'ADD_TO_HOMESCREEN_BUTTON_ADD',
  ]);

  const currentLang = useGetLang();
  const imageUrls = {
    en: [
      '/images/pwa/modal1.png',
      '/images/pwa/modal2.png',
      '/images/pwa/modal3.png',
      '/images/favicons/pwa/leonardo-48x48.png',
    ],
    it: [
      '/images/pwa/modal1ita.png',
      '/images/pwa/modal2ita.png',
      '/images/pwa/modal3ita.png',
      '/images/favicons/pwa/leonardo-48x48.png',
    ],
  };

  const location = useLocation();
  const userProfile = useSelector(state => state.user.userProfile);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);
  const [showIosModal, setShowIosModal] = useState(false);
  const [showBannerAccordingToPage, setShowBannerAccordingToPage] = useState(false);
  const [images, setImages] = useState<string[]>(imageUrls.en);
  const {isDesktop} = useBreakpointBoolean();

  useHandleBodyScrollAfterModalOpen(showIosModal);

  //prompt position for some specific pages
  const [promptPosition, setPromptPosition] = useState<string>('go-fixed');

  const hide = () => {
    //expiration after 1 year
    setCookie(COOKIE.CLOSE_ADD_TO_HOME, 'true', new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
    setVisibleState(false);
  };

  const handleAddToHomescreen = () => {
    if (isMobile.iOS()) {
      setShowIosModal(true);
    } else {
      promptToInstall();
    }
  };

  const isChromeIos = () => navigator.userAgent.match('CriOS');

  useEffect(() => {
    if (props.showAddToHomescreenPromptFooter) {
      setShowBannerAccordingToPage(true);
      return;
    }
    const isPublicPage = isCurrentPublicPage();
    const pathname = location.pathname;
    let showBanner = false;
    if (!isPublicPage || pathname === PUBLIC_URLS.LOGIN.URL || pathname === PUBLIC_URLS.HOMEPAGE.URL) {
      showBanner = true;
    }
    setShowBannerAccordingToPage(showBanner);

    //USIAMO promptPosition="go-fixed" sempre - UU-2624
    //set prompt position - used for some specific pages
    // let promptPositionTemp = '';
    // if (props.showAddToHomescreenPromptLogin) {
    //   if (isUsingDefaultScrollbar()) {
    //     promptPositionTemp = 'go-fixed';
    //   }
    // }
    // setPromptPosition(promptPositionTemp);
  }, [location]);

  useEffect(() => {
    if (currentLang === 'IT') {
      setImages(imageUrls.it);
    } else {
      setImages(imageUrls.en);
    }
  }, [currentLang]);

  useEffect(() => {
    //IOS check
    // console.log(navigator.userAgent);
    // console.log("isSafari: " + isSafari());
    // console.log("isMobile.iOS: " + isMobile.iOS());
    // console.log("isInStandaloneMode: " + isInStandaloneMode());
    // console.log("isChromeIos: " + isChromeIos());
    // console.log("isMobile", isMobile.any());

    const closeAddToHomescreen = getCookie(COOKIE.CLOSE_ADD_TO_HOME);
    let isVisibleTemp = false;

    //if user has closed the prompt --> do not show it
    //if the user role is VS --> do not show it
    if (
      !userRoleIsVS(userProfile?.extRole) &&
      (!closeAddToHomescreen || props.showAddToHomescreenPromptFooter) &&
      !isInStandaloneMode()
    ) {
      if (isMobile.iOS() && !isChromeIos()) {
        isVisibleTemp = true;
      } else if (prompt) {
        isVisibleTemp = true;
      }
    }
    if(isDesktop){
      isVisibleTemp = false;
    }

    setVisibleState(isVisibleTemp);
  }, [prompt, isInStandaloneMode(), userProfile]);

  return (
    <>
      {isVisible && showBannerAccordingToPage && (
        <div
          className={
            'homescreen-prompt ' +
            promptPosition +
            ' ' +
            (props.showAddToHomescreenPromptFooter ? CLASS_HOME_SCREEN.HOMESCREEN_PROMPT_FOOTER : CLASS_HOME_SCREEN.HOMESCREEN_PROMPT_HEADER )
          }
        >
          <div
            className={
              'homescreen-prompt__content' +
              (props.showAddToHomescreenPromptFooter ? '__footer' : '')
            }
          >
            <div className="flex align-center">
              {props.showAddToHomescreenPromptLogin && isMobile.any() && (
                <div
                  className="button-close"
                  onClick={hide}
                  onKeyDown={event => {
                    handleOnEnterKeyPress(event, hide, true);
                  }}
                  tabIndex={0}
                >
                  <LazySVG src="images/svg-icons/x-icon2.svg" alt={labels?.CLOSE_POPUP} />
                </div>
              )}

              <div className="leonardo-icon">
                <LazyImage
                  src={images[3]}
                  placeholder=""
                  visibilitySensorProps={{
                    partialVisibility: true,
                    offset: { bottom: -100, right: -100 },
                  }}
                >
                  {(src, loading) => (
                    <img
                      className="icon"
                      src={src}
                      alt={labels?.ADD_TO_HOMESCREEN_CTA}
                      draggable={false}
                    />
                  )}
                </LazyImage>
              </div>

              <section className={'description-section' + (!isMobile.any() ? '__desk' : '')}>
                {props.showAddToHomescreenPromptFooter && (
                  <ButtonV2
                    // variant="text-btn-no-arrow"
                    variant="text-btn"
                    small
                    className="description-section-title"
                    handleClick={handleAddToHomescreen}
                  >
                    {labels?.ADD_TO_HOMESCREEN_MODAL_TITLE}
                  </ButtonV2>
                )}
                {!props.showAddToHomescreenPromptFooter && (
                  <p
                    className={'description-section-title' + (!isMobile.any() ? '__desk' : '')}
                    onClick={handleAddToHomescreen}
                    onKeyDown={event => {
                      handleOnEnterKeyPress(event, handleAddToHomescreen, true);
                    }}
                  >
                    {labels?.ADD_TO_HOMESCREEN_CTA}
                  </p>
                )}
                {!props.showAddToHomescreenPromptFooter && (
                  <p
                    className={'description-section-subtitle' + (!isMobile.any() ? '__desk' : '')}
                    onClick={handleAddToHomescreen}
                    onKeyDown={event => {
                      handleOnEnterKeyPress(event, handleAddToHomescreen, true);
                    }}
                  >
                    {' '}
                    {labels?.ADD_TO_HOMESCREEN_CTA_DESCRIPTION}
                  </p>
                )}
              </section>
            </div>
            {!props.showAddToHomescreenPromptFooter && (
              <div className="button-add">
                <Button
                  className="button-download"
                  handleClick={handleAddToHomescreen}
                  ariaLabel={labels?.ADD_TO_HOMESCREEN_CTA}
                >
                  <LazySVG src="images/pwa/addButton.svg" alt={labels?.ADD_TO_HOMESCREEN_CTA} />
                </Button>
                <section className="add-label">{labels?.ADD_TO_HOMESCREEN_BUTTON_ADD}</section>
              </div>
            )}
            {props.showAddToHomescreenPromptLogin && !isMobile.any() && (
              <div
                className="button-close-desk"
                onClick={hide}
                onKeyDown={event => {
                  handleOnEnterKeyPress(event, hide, true);
                }}
                tabIndex={0}
              >
                <LazySVG src="images/svg-icons/x-icon2.svg" alt={labels?.CLOSE_POPUP} />
              </div>
            )}
          </div>
        </div>
      )}

      {showIosModal && (
        <Modal
          blur="always"
          blurIntensity="07"
          close={() => setShowIosModal(false)}
          show={showIosModal}
          contentClassName="modal__content--centered flex column justify-center max-width-620 text-center"
        >
          <div className="add-to-homepage-modal">
            <p className="title">{labels?.ADD_TO_HOMESCREEN_MODAL_TITLE}</p>

            <p
              className="description-text"
              dangerouslySetInnerHTML={printHTML(
                '<span>1. </span>' + labels?.ADD_TO_HOMESCREEN_MODAL_DESCR1
              )}
            />
            <div className="description-image">
              <CardCover
                image={images[0]}
                variant="none"
                courseFullName={labels?.ADD_TO_HOMESCREEN_MODAL_DESCR1}
                hideShadow
              />
            </div>

            <p
              className="description-text"
              dangerouslySetInnerHTML={printHTML(
                '<span>2. </span>' + labels?.ADD_TO_HOMESCREEN_MODAL_DESCR2
              )}
            />
            <div className="description-image">
              <CardCover
                image={images[1]}
                variant="none"
                courseFullName={labels?.ADD_TO_HOMESCREEN_MODAL_DESCR2}
                hideShadow
              />
            </div>

            <p
              className="description-text"
              dangerouslySetInnerHTML={printHTML(
                '<span>3. </span>' + labels?.ADD_TO_HOMESCREEN_MODAL_DESCR3
              )}
            />
            <div className="description-image">
              <CardCover
                image={images[2]}
                variant="none"
                courseFullName={labels?.ADD_TO_HOMESCREEN_MODAL_DESCR3}
                hideShadow
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddToHomescreenPrompt;
