import React from "react";
import { useSelector } from "@hooks/redux";
import ModalEvolution from "./ModalEvolution";

type ModalMessageProps = {
  show: boolean;
  title: string;
  description: string;
  close: () => void;
  alert?: boolean;
};

const ModalMessage = ({
  show,
  close,
  title,
  description,
  alert,
}: ModalMessageProps) => {
  const lang = useSelector((state) => state.utils.lang);

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      icon={alert ? "/images/svg-icons/warning.svg" : ""}
      title={title}
      description={description}
      mainButtonLabel={lang?.GOT_IT2}
      mainButtonAction={close}
    />
  );
};

export default ModalMessage;
