import React from 'react';

type Props = {
	label: string;
	className?: string;
};

const EducationalPathsPrice = (props: Props) => {
	const { label, className } = props;

	return <span className={`educational-paths__price ${className ? className : ''}`}>{label}</span>;
};

export default EducationalPathsPrice;
