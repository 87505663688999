import React from 'react'
import ContentLoader from 'react-content-loader'

const CardClassroom = ({ isWhite = false }) => (
  <ContentLoader
    height={640}
    width={300}
    speed={5}
    primaryColor={isWhite ? "#fff" : "#f3f3f3"}
    secondaryColor={isWhite ? "#f3f3f3" : "#ecebeb"}
  >
    <rect x="0" y="0" rx="5" ry="5" width="300" height="200" />
    <rect x="20" y="220" rx="2" ry="2" width="230" height="16" />
    <rect x="20" y="251" rx="2" ry="2" width="231" height="50" />
    <rect x="20" y="317" rx="2" ry="2" width="80" height="12" />
    <rect x="270" y="251" rx="2" ry="2" width="20" height="50" />
    <rect x="20" y="350" rx="0" ry="0" width="260" height="1" />
    <rect x="20" y="370" rx="2" ry="2" width="120" height="15" />
    <rect x="50" y="410" rx="2" ry="2" width="90" height="85" />
    <rect x="160" y="410" rx="2" ry="2" width="90" height="85" />
    <rect x="20" y="475" rx="15" ry="15" width="15" height="15" />
    <rect x="265" y="475" rx="15" ry="15" width="15" height="15" />
    <rect x="50" y="520" rx="2" ry="2" width="100" height="20" />
    <rect x="50" y="560" rx="5" ry="5" width="200" height="38" />
  </ContentLoader>
)

CardClassroom.metadata = {
  name: 'Ariadne',
  github: 'ariadne-github',
  description: 'Card Classroom',
  filename: 'CardClassroom',
}

export default CardClassroom