import { useSelector } from '@hooks/redux';
import { getNotReadNumber } from '@utility/firebaseUtility';
import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";

type HelmetMetaTagsProps = {
  title?: string;
  description?: string;
  useTitleFormatLeonardo?: boolean;
}

const HelmetMetaTags = ({ title, description, useTitleFormatLeonardo = false }: HelmetMetaTagsProps) => {
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const userProfile = useSelector((state) => state.user.userProfile);
  const notifications = useSelector((state) => state.firebase.notifications);

  const [numNotif, setNumNotif] = useState("");


  useEffect(() => {
    if (useTitleFormatLeonardo) {
      const numNotifTemp = getNotReadNumber(notifications, coursesMap, userProfile) || 0;

      if (numNotifTemp > 0) {
        setNumNotif("(" + numNotifTemp.toString() + ") ");
      } else {
        setNumNotif("");
      }
    }
  }, [notifications, coursesMap, userProfile])

  return (
    <Helmet>
      {title && <title>{useTitleFormatLeonardo ? (numNotif + title + ' - Leonardo') : title}</title>}
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default HelmetMetaTags
