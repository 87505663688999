import { Course } from '@model/CoursesClass';
import { DurationFilter } from '@model/DurationFilter';
import { UserProfile } from '@model/User';
import {
  getDurationFilterById,
  isVirtualClassroom,
  isOnlineCourse,
  isOnlyMultiActivity,
  getRangeScheduleByDateString,
  isMandatory,
  isOverdueCourse,
  getSubtitlesCodes,
  isSubtitledVideoCourse,
  isLivestreamCourse,
  getExpirationDateFilterKey,
  getDaysInTheFuture,
  numberTwoDigits,
  getTodayMidnight,
  getDaysAgo,
} from '@utility/Api';
import {
  BADGE,
  courseStatesConst,
  FILTER_COURSE,
  FILTER_CTYPE,
  FILTER_OTHERS,
  FILTER_PRICE,
  sessionStatus,
} from './const';
import { getBadgesCourse, isCourseEl360 } from './CourseUtility';
import { getOfferPlanFilter, isPurchasable } from './ecommerceUtility';
import { getL1IdFromBrandId } from './levelUtility';
import { getLivestreamDate, getLivestreamRecording } from './LivestreamUtility';
import { getVCRecording, getVCRecordingLinks } from './VCUtility';

const checkFiltersInput = (courses: Array<Course>, filters: Array<any>) => {
  if (!courses || courses.length === 0) {
    return [];
  }

  if (!filters || filters.length === 0) {
    return courses;
  }

  return null;
};
const checkFilterInput = (courses: Array<Course>, filter: any) => {
  return checkFiltersInput(courses, [filter]);
};

export const filterByBrands = (courses: Array<Course>, brands: Array<string>): Array<Course> => {
  return filterByChannels(
    courses,
    brands?.map(a => getL1IdFromBrandId(a))
  );

  // const inputNotValid = checkFiltersInput(courses, brands);
  // if (inputNotValid) {
  //     return inputNotValid;
  // }

  // return courses.filter(course => {
  //     let courseBrands: string[] = course.b2bbrand;

  //     if (!courseBrands) {
  //         return false;
  //     }

  //     for (const brand of courseBrands) {
  //         if (brands.includes(brand)) {
  //             return true;
  //         }

  //         if (considerParent && brandsList?.map && brand !== 'ALL') {
  //             // check if the parent is present
  //             const parentBrand = brandsList.map[brand]?.brand;
  //             if (parentBrand && brands.includes(parentBrand)) {
  //                 return true;
  //             }
  //         }
  //     }
  //     return false;
  // });
};

export const filterByBrand = (courses: Array<Course>, brand: string): Array<Course> => {
  return filterByBrands(courses, [brand]);
};

export const filterByChannels = (
  courses: Array<Course>,
  channels: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, channels);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    let courseCatalogTypes: string[] = course.catalogTypes;

    if (!courseCatalogTypes) {
      return false;
    }

    for (const channel of courseCatalogTypes) {
      if (channels.includes(channel)) {
        return true;
      }
    }
    return false;
  });
};

export const filterByChannel = (courses: Array<Course>, channel: string): Array<Course> => {
  return filterByChannels(courses, [channel]);
};

export const filterByCtypes = (courses: Array<Course>, ctypes: Array<string>): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, ctypes);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (!course.ctype) {
      return false;
    }

    for (const ctype of course.ctype) {
      if (ctypes.includes(ctype)) {
        return true;
      }
    }

    if (ctypes.includes(FILTER_CTYPE.CLASSES) && isVirtualClassroom(course)) {
      return true;
    } else if (ctypes.includes(FILTER_CTYPE.ONLINE_COURSES) && isOnlineCourse(course)) {
      return true;
    } else if (ctypes.includes(FILTER_CTYPE.MULTIACTIVITY) && isOnlyMultiActivity(course)) {
      return true;
    } else if (ctypes.includes(FILTER_CTYPE.SUBTITLED_VIDEO) && isSubtitledVideoCourse(course)) {
      return true;
    }

    return false;
  });
};

export const filterBySections = (courses: Array<Course>, level2: Array<string>): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, level2);
  if (inputNotValid) {
    return inputNotValid;
  }

  level2 = level2.map(section => section.toLowerCase());

  return courses.filter(course => {
    let courseSections: Array<string> = course.level2 ;

    if (courseSections) {
      for (const section of courseSections) {
        if (level2.includes(section)) {
          return true;
        }
      }
    }
    return false;
  });
};

export const respectDuration = (course: Course, durationId: string): boolean => {
  const filterResult = filterByDuration([course], durationId);
  return filterResult?.length > 0;
};
export const filterByDuration = (courses: Array<Course>, durationId: string): Array<Course> => {
  const inputNotValid = checkFilterInput(courses, durationId);
  if (inputNotValid) {
    return inputNotValid;
  }

  return filterByDurations(courses, [durationId]);
};

export const filterByDurations = (
  courses: Array<Course>,
  durationIds: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, durationIds);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    for (const durationId of durationIds) {
      const durationObj: DurationFilter = getDurationFilterById(durationId);
      if (!durationObj) {
        break;
      }

      if (
        (!durationObj.lower || course.duration <= durationObj.lower) &&
        (!durationObj.greater || course.duration >= durationObj.greater)
      ) {
        return true;
      }
    }

    return false;
  });
};

export const filterByBadges = (courses: Array<Course>, badges: Array<string>): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, badges);
  if (inputNotValid) {
    return inputNotValid;
  }

  badges = badges.map(badge => badge.toLowerCase());

  return courses.filter(course => {
    const badgesCourse: Array<string> = getBadgesCourse(course);

    for (const badge of badgesCourse) {
      if (badges.includes(badge)) {
        return true;
      }
    }

    // overdue is not a badge but cms brand page request returns it as a badge
    if (badges.includes(courseStatesConst.OVERDUE) && isOverdueCourse(course)) {
      return true;
    }

    return false;
  });
};

export const filterByTeacher = (courses: Array<Course>, teacher: string): Array<Course> => {
  return filterByTeachers(courses, [teacher]);
};

export const filterByTeachers = (
  courses: Array<Course>,
  teachers: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, teachers);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (course.courseSessions?.length > 0) {
      for (const session of course.courseSessions) {
        if (teachers.includes(session.trainer)) {
          return true;
        }

        if (session.trainers?.length > 0) {
          for (let trainer of session.trainers) {
            if (teachers.includes(trainer.username)) {
              return true;
            }
          }
        }
      }
    }

    // livestream trainers
    const trainersLiveStream = course?.liveInfo?.[0]?.trainers;
    if (trainersLiveStream?.length > 0) {
      for (const trainer of trainersLiveStream) {
        if (teachers.includes(trainer.username)) {
          return true;
        }
      }
    }

    return false;
  });
};

export const filterByLocations = (
  courses: Array<Course>,
  locations: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, locations);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (course.courseSessions?.length > 0) {
      for (const session of course.courseSessions) {
        if (locations.includes(session.city) || locations.includes(session.location)) {
          return true;
        }
      }
    }
    return false;
  });
};

export const filterBySchedules = (
  courses: Array<Course>,
  schedules: Array<string>,
  timezone: string
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, schedules);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (isLivestreamCourse(course)) {
      const { timeStart } = getLivestreamDate(course);
      if (timeStart) {
        const schedulesCourse = getRangeScheduleByDateString(timeStart, timezone);

        for (const scheduleCourse of schedulesCourse) {
          if (schedules.includes(scheduleCourse)) {
            return true;
          }
        }
      }
    }

    if (course.courseSessions) {
      for (const courseSession of course.courseSessions) {
        //not consider past sessions
        if (courseSession.minStartDate && courseSession.status !== sessionStatus.SESSION_OVER) {
          const schedulesCourse = getRangeScheduleByDateString(
            courseSession.minStartDate,
            timezone
          );

          for (const scheduleCourse of schedulesCourse) {
            if (schedules.includes(scheduleCourse)) {
              return true;
            }
          }
        }
      }
    }

    return false;
  });
};

export const filterByCourseStates = (
  courses: Array<Course>,
  courseStates: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, courseStates);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (course.courseStates?.length > 0) {
      for (const courseState of course.courseStates) {
        if (courseStates.includes(courseState)) {
          return true;
        }
      }

      // mandatory is not a course state but cms brand page request returns it as a course state
      if (courseStates.includes(BADGE.mandatory) && isMandatory(course)) {
        return true;
      }
    }

    return false;
  });
};

export const filterByComplexity = (
  courses: Array<Course>,
  complexity: string,
  withMacroCategory: boolean = false
): Array<Course> => {
  return filterByComplexities(courses, [complexity], withMacroCategory);
};

export const filterByComplexities = (
  courses: Array<Course>,
  complexities: Array<string>,
  withMacroCategory: boolean = false
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, complexities);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (!course.complexityId) {
      return false;
    }

    const courseComplexityId = course.complexityId;

    if (withMacroCategory) {
      const courseMacroCategory = course.macroCategory;
      let result = false;

      complexities.forEach(complexity => {
        const complexityId = getComplexityFromComplexityFilter(complexity);
        const macroCategory = getMacroCategoryFromComplexityFilter(complexity);

        if (courseComplexityId === complexityId && courseMacroCategory === macroCategory) {
          result = true;
        }
      });

      return result;
    } else {
      return complexities.includes(courseComplexityId);
    }
  });
};

export const filterByLanguage = (
  courses: Array<Course>,
  languages: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, languages);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (course.totalLanguages?.length > 0) {
      for (const language of course.totalLanguages) {
        if (languages.includes(language.value)) {
          return true;
        }
      }
    }

    const subtitles = getSubtitlesCodes(course);
    for (const subtitle of subtitles) {
      if (languages.includes(subtitle)) {
        return true;
      }
    }

    return false;
  });
};

export const filterByRecordingAvailable = (
  courses: Array<Course>,
  others,
  userProfile: UserProfile
): Array<Course> => {
  const inputNotValid =
    checkFiltersInput(courses, []) && others?.[FILTER_OTHERS.RECORDING_AVAILABLE];
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (getLivestreamRecording(course, userProfile)?.length > 0) {
      return true;
    }
    const {directurl} = getVCRecordingLinks(course)
    if (!!directurl) {
      return true;
    }
    return false;
  });
};

export const filterByEl360Courses = (
  courses: Array<Course>,
  el360: Array<string>
): Array<Course> => {
  const inputNotValid =
    checkFiltersInput(courses,[]) && el360?.[FILTER_COURSE.EL_360];
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (isCourseEl360(course)) {
      return true;
    }
    return false;
  });
};

export const filterByPriceCondition = (course: Course, price: string): boolean => {
  if (!isPurchasable(course) || !price) {
    return false;
  }

  const filterPrice = FILTER_PRICE[price];
  if (
    filterPrice &&
    (filterPrice.from === null || +course.price > filterPrice.from) &&
    (filterPrice.to === null || +course.price <= filterPrice.to)
  ) {
    return true;
  }

  return false;
};

export const filterByPrice = (courses: Array<Course>, prices: Array<string>): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, prices);

  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    for (const price of prices) {
      if (filterByPriceCondition(course, price)) {
        return true;
      }
    }

    return false;
  });
};

export const filterByOfferPlan = (
  courses: Array<Course>,
  offerPlans: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, offerPlans);

  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    const planFilter = getOfferPlanFilter(course);
    return offerPlans.includes(planFilter);
  });
};

export const filterByExpiration = (
  courses: Array<Course>,
  expirationKeys: Array<string>
): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, expirationKeys);

  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    const filterKey = getExpirationDateFilterKey(course);
    if (filterKey) {
      if (expirationKeys.includes(filterKey)) {
        return true;
      }
    }

    return false;
  });
};

export const filterByDateSessionRange = (
  courses: Array<Course>,
  dateSessionRange: { min: Date; max: Date }
): Array<Course> => {
  const inputNotValid = checkFilterInput(courses, dateSessionRange);
  if (inputNotValid) {
    return inputNotValid;
  }
  if (!dateSessionRange.min || !dateSessionRange.max) {
    return courses;
  }

  console.log('filterByDateSessionRange', dateSessionRange);
  return courses.filter(course => {
    const minTimestamp = new Date(dateSessionRange.min).getTime();
    const maxTimestamp = new Date(dateSessionRange.max).getTime();

    if (course.associatedSession) {
      return (
        minTimestamp < new Date(course.associatedSession.timeStart).getTime() &&
        maxTimestamp > new Date(course.associatedSession.timeFinish).getTime()
      );
    } else {
      if (isVirtualClassroom(course) && course.courseSessions?.length > 0) {
        //check if at least a session is in range
        for (const session of course.courseSessions) {
          if (
            minTimestamp < new Date(session.sessionDates?.[0]?.timeStart).getTime() &&
            maxTimestamp > new Date(session.sessionDates?.[0]?.timeFinish).getTime()
          ) {
            return true;
          }
        }
        return false;
      }

      if (isLivestreamCourse(course)) {
        const liveInfo = course?.liveInfo?.[0];
        if (liveInfo) {
          return (
            minTimestamp < new Date(liveInfo.timeStart).getTime() &&
            maxTimestamp > new Date(liveInfo.timeEnd).getTime()
          );
        }
      }
    }

    return false;
  });
};

export const filterByDateSessionArray = (
  courses: Array<Course>,
  dates: string[]
): Array<Course> => {
  const inputNotValid = checkFilterInput(courses, dates);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    if (course.associatedSession) {
      return (
        dates.includes(course.associatedSession?.timeStart?.slice(0, 10)) ||
        dates.includes(course.associatedSession?.timeFinish?.slice(0, 10))
      );
    } else {
      if (isVirtualClassroom(course) && course.courseSessions?.length > 0) {
        //check if at least a session is in range
        for (const session of course.courseSessions) {
          if (
            dates.includes(session.sessionDates?.[0]?.timeStart?.slice(0, 10)) ||
            dates.includes(session.sessionDates?.[0]?.timeFinish?.slice(0, 10))
          ) {
            return true;
          }
        }
        return false;
      }

      if (isLivestreamCourse(course)) {
        const liveInfo = course?.liveInfo?.[0];
        if (liveInfo) {
          return (
            dates.includes(liveInfo.timeStart?.slice(0, 10)) ||
            dates.includes(liveInfo.timeEnd?.slice(0, 10))
          );
        }
      }
    }

    return false;
  });
};

export const getSessionRange = (course: Course): string[] => {
  if (!course) {
    return [];
  }

  if (course.associatedSession) {
    const timeStart = new Date(course.associatedSession.timeStart);
    const timeFinish = new Date(course.associatedSession.timeFinish);
    timeFinish.setHours(23, 59, 59, 0);

    return getDates(timeStart, timeFinish).map(a => fromDateToString(a));
  } else {
    if (isVirtualClassroom(course)) {
      let minMaxSessionDateRange = { min: null, max: null };
      for (const session of course.courseSessions) {
        if (
          !minMaxSessionDateRange.min ||
          minMaxSessionDateRange.min.localeCompare(session.sessionDates?.[0]?.timeStart) > 0
        ) {
          minMaxSessionDateRange.min = session.sessionDates[0].timeStart;
        }
        if (
          !minMaxSessionDateRange.max ||
          minMaxSessionDateRange.max.localeCompare(session.sessionDates?.[0]?.timeFinish) < 0
        ) {
          minMaxSessionDateRange.max = session.sessionDates[0].timeFinish;
        }
      }

      const timeStart = new Date(minMaxSessionDateRange.min);
      const timeFinish = new Date(minMaxSessionDateRange.max);
      timeFinish.setHours(23, 59, 59, 0);

      return getDates(timeStart, timeFinish).map(a => fromDateToString(a));
    }

    if (isLivestreamCourse(course)) {
      const timeStart = new Date(course.liveInfo?.[0]?.timeStart);
      const timeFinish = new Date(course.liveInfo?.[0]?.timeEnd);
      timeFinish.setHours(23, 59, 59, 0);

      return getDates(timeStart, timeFinish).map(a => fromDateToString(a));
    }
  }

  return [];
};

export const getFixedRange = (
  course: Course,
  numDayList: number[],
  inFuture: boolean = false
): number[] => {
  if (!course) {
    return [];
  }

  return numDayList.filter(numDay => {
    const range = inFuture
      ? getDates(getTodayMidnight(), getDaysInTheFuture(numDay - 1, getTodayMidnight(true)))
      : getDates(getDaysAgo(numDay - 1, getTodayMidnight()), getTodayMidnight(true));

    return (
      filterByDateSessionArray(
        [course],
        range.map(a => fromDateToString(a))
      )?.length > 0
    );
  });
};

export const filterByDateFixedRange = (courses: Array<Course>, ranges: string[]): Array<Course> => {
  const inputNotValid = checkFiltersInput(courses, ranges);
  if (inputNotValid) {
    return inputNotValid;
  }

  return courses.filter(course => {
    let isFuture = false;
    if (ranges[0].includes('_future')) {
      isFuture = true;
    }

    return (
      getFixedRange(
        course,
        ranges.map(a => +a.replace(/_future|_past/, '')),
        isFuture
      )?.length > 0
    );
  });
};

export const fromDateToString = (date: Date) => {
  return (
    date.getFullYear() +
    '/' +
    numberTwoDigits(date.getMonth() + 1) +
    '/' +
    numberTwoDigits(date.getDate())
  );
};

export const getDates = (startDate: Date, stopDate: Date): Date[] => {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = getDaysInTheFuture(1, currentDate);
  }
  return dateArray;
};

export const areMinMaxValuedInDateSessionRange = (min, max) => {
  const isMinValued = min && min.getTime() !== 0 && max.getTime() < 32505368793000;
  const isMaxValued = max && max.getTime() !== 0 && max.getTime() < 32505368793000;
  return isMinValued && isMaxValued;
};

export const isDateSessionRangeFilterApplied = dateSessionRange => {
  return (
    dateSessionRange?.type &&
    areMinMaxValuedInDateSessionRange(dateSessionRange.min, dateSessionRange.max)
  );
};

export const DATE_CUSTOM_RANGE = 'CUSTOM';
export const DATES_FIXED_RANGE = [7, 14, 30];

const FILTER_COMPLEXITY_SEPARATOR = '_COMPLEXITYSEPARATOR_';
export const combinateComplexityCategory = (
  complexityCategory: string,
  complexity: string
): string => {
  return complexityCategory + FILTER_COMPLEXITY_SEPARATOR + complexity;
};
export const getMacroCategoryFromComplexityFilter = (complexityFilter: string): string => {
  const regex = new RegExp(FILTER_COMPLEXITY_SEPARATOR + '.*', 'g');
  return complexityFilter?.replace(regex, '');
};
export const getComplexityFromComplexityFilter = (complexityFilter: string): string => {
  const regexComplexityId = new RegExp('.*' + FILTER_COMPLEXITY_SEPARATOR, 'g');
  return complexityFilter?.replace(regexComplexityId, '');
};
