
import LazySVG from "@components/LazySvg";
import { useSelector } from "@hooks/redux";
import clsx from "@utility/clsx";
import React from "react";
import { useTranslation } from "react-i18next";


type CardShowMoreProps = {
  variant: string;
  onClick: () => void;
  className?: string;
  isProductCarousel?: boolean;
  linkLabel?: string;
  linkString?: string
}


const CardShowMore = ({ variant = "", onClick, className = "" , isProductCarousel = false , linkLabel = '' , linkString = ''}: CardShowMoreProps) => {
  const lang = useSelector(state => state.utils.lang)

  return (
    <section
      className={clsx("card-show-more", {
        [variant]: variant,
        [className]: className
      })}
      onClick={onClick}
      tabIndex={0}
      aria-label={lang?.SEE_ALL_LABEL}
    >
      <span className="view-all">
         {isProductCarousel ? linkLabel : lang?.SEE_ALL_LABEL}
        <LazySVG
          src="/images/svg-icons/arrow-right6.svg"
          alt={lang?.SEE_ALL_LABEL}
        />
      </span>
    </section>
  )

}

export default CardShowMore;