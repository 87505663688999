import React, { ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CardDisplayProps, defaultCardDisplayProps } from "./CardDisplayUtils";
import { isCourseVisible, truncateLabel } from "../../utility/Api";
import DetailsSingleActivityButton from "@components/DetailsSingleActivityButton";
import { Course, CourseLanguage, LangMap } from "@model/CoursesClass";
import AnimationExpand from "@components/animations/AnimationExpand";
import NotYetAvailableIcon from "@images/svg-icons/not-yet-available.svg";
import { canCourseBeAccessed, isPurchasable } from "@utility/ecommerceUtility";
import CartIcon from "@components/CartIcon";
import { ADD_TO_CART_STEPS } from "@utility/const";
import { ProductCart } from "@model/CartModel";
import { useSelector } from "@hooks/redux";
import { getCourseDetails, insertProductInCart } from "@redux-actions/";
import WishlistIcon from "@components/WishlistIcon";
import ButtonV2 from "@components/UI/ButtonV2";

interface CardLessonInLPProps extends CardDisplayProps {
  compatibility: number,
  course: Course,
  showLaunchButton: boolean,
  toggleIframePopup: (value: boolean, course: Course, url: string, urlToCheck: string, isVideo: boolean) => void,
  isMandatory: boolean,
  catalogTypes: Array<string>,
  previewVideoUrl: string,
  subtitle: CourseLanguage,
  delayGetCourseDetails: boolean;
}

const CardLessonInLP = (props: CardLessonInLPProps): ReactElement => {
  const { course, courseIdMaster, courseFullName, credits, ctypeName, duration,
    showBar, summary, badgeLabel, courseStatus, valueBar, delayGetCourseDetails = false } = props;

  const dispatch = useDispatch();

  const lang: LangMap = useSelector((state) => state.utils.lang);
  const isLoadingDetails = useSelector((state) => state.course.isLoadingDetails);
  const isLoadingCart = useSelector((state) => state.ecommerce.isLoadingCart);

  useEffect(() => {
    //only if we don't have to delay the course detail
    if (!delayGetCourseDetails && course && (!course.courseDetails || course.courseDetails.length === 0) && !isLoadingDetails[courseIdMaster]) {
      dispatch(getCourseDetails(course));
    }
  }, [course?.courseDetails]);

  const handleClickCard = () => {
    props.openCourseModal();
  }

  const handleBuyCourse = (lessonCourseId) => {
    dispatch(insertProductInCart(new ProductCart(lessonCourseId, 1), ADD_TO_CART_STEPS.NO_MODAL));
  };

  return (
    <div className={"card-v3 card-v3-lesson-in-lp"}
      data-id={courseIdMaster}
    >
      <div className="card-v3-lesson-in-lp__firstrow">
        <span className={"card-v3-lesson-in-lp__badge " + props.badgeColor}>{badgeLabel}</span>

        <div className="card-v3-lesson-in-lp__icons">
          <WishlistIcon
            className="card-v3-lesson-in-lp__icon"
            isWishlist={props.isWishlist}
            isLoadingWishlistLocal={props.isLoadingWishlistLocal}
            isNotYetAvailable={props.isNotYetAvailable}
            enableWishlist={props.enableWishlist}
            toggleWishlist={props.toggleWishlist}
          />

          <CartIcon variant="course" course={props.course} className="card-v3-lesson-in-lp__icon" />
        </div>
      </div>

      <p className="card-v3-lesson-in-lp__name">
        {truncateLabel(courseFullName?.toUpperCase(), 50)}
      </p>
      <div className="card-v3-lesson-in-lp__info">
        <span className="ctype">
          {ctypeName}
        </span>
        <span className="duration">
          {duration}
        </span>
        <span className="credits">
          {credits + ' ' + (credits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL)}
        </span>
      </div>

      <p className={"card-v3-lesson-in-lp__summary"} dangerouslySetInnerHTML={summary}></p>
      <div className="card-v3-lesson-in-lp__cta-row">
        <div>
          {(course.isNotYetAvailable || props.isParentLpBlocked)
            ?
            <NotYetAvailableIcon className="card-v3-lesson-in-lp__lock" />
            :
            <>
              {canCourseBeAccessed(course) &&
                <DetailsSingleActivityButton
                  lang={lang}
                  course={course}
                  subtitle={props.subtitle}
                  isLessonWithinLp
                  delayGetCourseDetails={delayGetCourseDetails}
                  isLoading={isLoadingDetails[courseIdMaster]}
                />
              }
              {!canCourseBeAccessed(course) && isPurchasable(course, true) &&
                <div className="launch-container">
                  <ButtonV2
                    variant="primary"
                    className="button-buy-now"
                    handleClick={() => handleBuyCourse(course.courseIdMaster)}
                    loading={isLoadingCart}
                  >
                    {lang?.BUY_NOW}
                  </ButtonV2>
                </div>
              }
            </>
          }
        </div>

        {/* do not show "details +" button if the course is locked and no_standalone */}
        {((!course.isNotYetAvailable && !props.isParentLpBlocked) || isCourseVisible(course)) &&
          <ButtonV2 variant="text-btn-no-arrow" small className={'card-v3-lesson-in-lp__details-button with-icon'} handleClick={handleClickCard} >
            <span className="text">
              {lang.DETAILS_LABEL}
            </span>
            {/* <div className="plus-icon">+</div> */}
          </ButtonV2>
        }
      </div>

      {(!!courseStatus || (!!showBar && !!valueBar)) &&
        <div className="card-v3__status">
          {showBar &&
            <div className="card-v3__bar" >
              <AnimationExpand key={valueBar} initialWidth="0" finalWidth={valueBar + '%'} animationEase="easeOut"></AnimationExpand>
            </div>
          }
        </div>
      }


    </div>
  );
};

CardLessonInLP.defaultProps = {
  ...defaultCardDisplayProps
};

export default CardLessonInLP;
