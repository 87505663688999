import useCombinedRefs from "@hooks/useCombinedRefs";
import useToggle from "@hooks/useToggle";
import { useSelector } from "@hooks/redux";

import React from "react";
import { handleOnEnterKeyPress } from "@utility/Api";

type ToggleProps = {
  text?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  isTextOnLeft?: boolean;
  name?: string;
  canDefaultChange?: boolean;
  ecommerce?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      name,
      text,
      isTextOnLeft = false,
      defaultChecked = false,
      onChange,
      disabled = false,
      canDefaultChange = false,
      ecommerce,
      ...rest
    },
    forwardedRef
  ): React.ReactElement => {
    // @todo https://www.alltimepower.com/blog/react-hook-forms-custom-Toggle/

    const [checked, setChecked] = useToggle(defaultChecked || false);

    const innerRef = React.useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    const setCheckedInput = (checked) => {
      if (innerRef.current.checked !== checked && !disabled) {
        // just emulate an actual click on the input element
        innerRef.current.click();
      }
    };

    const handleClick = () => {
      if (!disabled) {
        setChecked();
      }
    };

    React.useEffect(() => {
      setCheckedInput(checked);
      if (onChange && (!canDefaultChange || checked !== defaultChecked)) {
        onChange(checked);
      }
    }, [checked]);

    React.useEffect(() => {
      if (canDefaultChange && checked !== defaultChecked) {
        setCheckedInput(defaultChecked);
      }
    }, [defaultChecked]);

    return (
      <div
        className={`toggle-wrapper ${disabled ? `check-box--disabled` : ``} ${isTextOnLeft ? `check-box--text-left` : ``
          } ${ecommerce? `ecommerce`: ``}`}
      >
        <input
          type="checkbox"
          name={name}
          className="toggle"
          id="toggle"
          defaultChecked={defaultChecked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          onKeyDown={event => {
            handleOnEnterKeyPress(event, () => setChecked(prev => !prev), true)
          }}
          disabled={disabled}
          ref={combinedRef}
          aria-checked={checked ? "true" : "false"}
          {...rest}
        />
        <label
          onClick={handleClick}
        ></label>
      </div>
    );
  }
);

export default Toggle;
