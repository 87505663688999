import React, { useEffect, useState } from 'react';
import Popup from '@components/UI/Popup';
import { UtagViewPages } from '@components/link-utils';
import { isPersonificationOn } from '../../../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { UserTypes } from '@model/TrackingClass';

const withErrorHandling =
  WrappedComponent =>
  ({
    showError,
    closeAlert,
    children,
    lang,
    userProfile,
    timeOnTraining,
    isTimeOnTrainingLoaded,
    error,
  }) => {
    const [timeoutClose, setTimeoutClose] = useState(null);
    const [showErrorInternal, setShowErrorInternal] = useState(null);
    const [errorInternal, setErrorInternal] = useState('');

    useEffect(() => {
      if (showError) {
        setShowErrorInternal(true);
        closeAlert();
        if (typeof error === 'string') {
          setErrorInternal(error);
        }

        if (timeoutClose) {
          clearTimeout(timeoutClose);
        }

        const newTimeoutClose = setTimeout(() => {
          setShowErrorInternal(false);
        }, 2000);

        setTimeoutClose(newTimeoutClose);
      }
    }, [showError]);

    // useEffect(() => {
    //   if (showError && userProfile && isTimeOnTrainingLoaded) {
    //     console.log(
    //       `%c Error notification`,
    //       'color: #bada55; font-size: 20px',
    //       new Date().toISOString().slice(11, 23),
    //       {
    //         ...UtagViewPages.ALERT,
    //         Page_Language: userProfile?.preferredLang,
    //         Page_Country: userProfile?.country,
    //         User_LoginStatus: userProfile?.senderSystem,
    //         User_Id: userProfile?.username,
    //         user_timeontraining: timeOnTraining,
    //         Page_DeviceType: 'web',
    //         User_MoodleID: userProfile?.moodleId,
    //       }
    //     );
    //     window?.utag?.view({
    //       ...UtagViewPages.ALERT,
    //       Page_Language: userProfile?.preferredLang,
    //       Page_Country: userProfile?.country,
    //       User_LoginStatus: userProfile?.senderSystem,
    //       User_Id: userProfile?.username,
    //       user_timeontraining: timeOnTraining,
    //       Page_DeviceType: 'web',
    //       User_MoodleID: userProfile?.moodleId,
    //     });
    //   }
    // }, [showError, userProfile, isTimeOnTrainingLoaded]);

    return (
      <WrappedComponent>
        {showErrorInternal && (
          <Popup
            popupClass="popup-small"
            variant="error"
            close={closeAlert}
            btnName="Close"
            handleClick={closeAlert}
          >
            <p className="error-message">{errorInternal || lang.ERROR_MESSAGE}</p>
          </Popup>
        )}
        {children}
      </WrappedComponent>
    );
  };

const DivWithErrorHandling = withErrorHandling(({ children }) => <div>{children}</div>);

export default DivWithErrorHandling;
