export const rotateScaleVariants = {
  hidden: {
    opacity: 0,
    rotate: 0,
  },
  visible: {
    opacity: 1,
    rotate: -360,
    transition: {
      duration: 2,
      stiffness: 50,
      type: "spring",
    },
  },
};
export const scaleFadeInVariants = {
  visible: { scale: 1, opacity: 1, transition: { duration: 1 } },
  hidden: { scale: 1.5, opacity: 0, transition: { duration: 1 } },
};
