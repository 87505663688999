import { useState, useEffect } from "react";

const useTime = (intervalTime: number) => {
  const [time, setTime] = useState(() => new Date().valueOf());

  useEffect(() => {
    if (intervalTime === null) {
      return;
    }

    const intervalId = setInterval(() => {
      setTime(new Date().valueOf());
    }, intervalTime);

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalTime]);

  return time;
};

export default useTime;