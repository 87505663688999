import React from "react";
import { printLink } from "../link-utils";
import { useLocation } from "react-router-dom";
import { selectIsUserEnabledForTrainingPills } from "@utility/TrainingPillsUtility";
import { useSelector } from "@hooks/redux";
import { USER_URLS } from "../link-utils";
import UserIcon from "@svg-icons/user2.svg";
import ChevronLeft from "@images/svg-icons/chevron-left-header.svg";
import useModalTypes from "@hooks/useModalByType";
import { motion } from "framer-motion";
import { hasUserActivePlan, isUserEl360 } from "@utility/userUtility";
import TagBadge from "@components/UI/TagBadge";
import { isExternalUser, isMYLUser } from "@utility/Api";

const variants = {
  initial: {
    x: "90vw",
  },
  enter: {
    x: "0",
  },
  exit: {
    x: "100vw",
  },
};

const MenuProfileMobile = ({ show, isLight }: { show: boolean, isLight?:boolean }) => {
  const lang = useSelector((state) => state.utils.lang);
  const isManager = useSelector((state) =>
    state.user.userProfile?.role?.includes?.("manager")
  );

  const location = useLocation();
  const isUserEnabledForTrainingPills = useSelector(
    selectIsUserEnabledForTrainingPills
  );
  const ecommerceEnable = useSelector((state) => state.user.ecommerceEnable);
  const modalTypes = useModalTypes();
  const userProfile = useSelector(state => state.user.userProfile);
  const userEl360 = isUserEl360(userProfile);
  const isUserPlanActive = hasUserActivePlan(userProfile);

  const closeMenu = () => {
    modalTypes.close();
  };

  const handleShowCurrentPage = (event, page: keyof typeof USER_URLS) => {
    if (location.pathname === USER_URLS[page].URL) {
      closeMenu();
    }
  };

  const handleClickBackButton = () => {
    modalTypes.menuRightModal.open();
  };

  return (
    show && (
      <motion.nav
        initial="initial"
        animate="enter"
        exit="exit"
        transition={{ ease: "easeInOut", duration: 0.3 }}
        variants={variants}
        className={"menu-profile-mobile menu-mobile" + (isLight ? "" : " dark-variant-mobile")}
        aria-label={lang?.MENU}
      >
        <header id="mainnav" onClick={handleClickBackButton}>
          <button
            className="icon-button back-button header-v3__back-button focus-outline mobile"
            title={lang?.GO_BACK}
            aria-label={lang?.GO_BACK}
            onClick={handleClickBackButton}
           // autoFocus
          >
            <ChevronLeft />
          </button>
          {(!userEl360 || !isUserPlanActive) ?  <span className="circle">
            <UserIcon />
          </span> : null }
          {lang.PROFILE_PAGE}
        </header>
        {(userEl360 || isUserPlanActive) && 
        <div className={(userEl360 ? "el-360": "active-plan") + "-section"}>
            <span>{userEl360 ? lang.EL360_LABEL : lang.ECOMM16_LABEL}</span>
            <TagBadge variant='white-border' label={userEl360 ? lang.EL360_ACTIVE : lang.ECOMM16_ACTIVE} />
        </div>
        }

        <ul className="menu-mobile__navigation">
          <>
            <li
              className="menu-mobile__item-head"
              onClick={(event) => handleShowCurrentPage(event, "PROFILE")}
            >
              {printLink("PROFILE", lang)}
            </li>

          {userProfile && !isExternalUser(userProfile) && !isMYLUser(userProfile) && (
            <li
              className="menu-mobile__item-head"
              onClick={(event) => handleShowCurrentPage(event, "PROFILE_TO_DO")}
            >
              {printLink("PROFILE_TO_DO", lang)}
            </li>
          )}
            
            <li
              className="menu-mobile__item-head"
              onClick={(event) =>
                handleShowCurrentPage(event, "PROFILE_MY_PROGRESS")
              }
            >
              {printLink("PROFILE_MY_PROGRESS", lang)}
            </li>
            <li
              className="menu-mobile__item-head"
              onClick={(event) =>
                handleShowCurrentPage(event, "PROFILE_MY_CERTIFICATES")
              }
            >
              {printLink("PROFILE_MY_CERTIFICATES", lang)}
            </li>

            {isUserEnabledForTrainingPills && (
              <li
                className="menu-mobile__item-head"
                onClick={(event) =>
                  handleShowCurrentPage(event, "SUBCATALOGUE_TP")
                }
              >
                {printLink("SUBCATALOGUE_TP", lang)}
              </li>
            )}
            {isManager && (
              <li
                className="menu-mobile__item"
                onClick={(event) =>
                  handleShowCurrentPage(event, "PROFILE_MY_TEAM")
                }
              >
                {printLink("PROFILE_MY_TEAM", lang)}
              </li>
            )}

            {ecommerceEnable && (
              <li
                className="menu-mobile__item"
                onClick={(event) =>
                  handleShowCurrentPage(event, "PROFILE_ORDER_HISTORY")
                }
              >
                {printLink("PROFILE_ORDER_HISTORY", lang)}
              </li>
            )}

            {ecommerceEnable && (
              <li
                className="menu-mobile__item"
                onClick={(event) =>
                  handleShowCurrentPage(event, "PROFILE_ACTIVATE_LICENSE")
                }
              >
                {printLink("PROFILE_ACTIVATE_LICENSE", lang)}
              </li>
            )}
          </>
        </ul>
      </motion.nav>
    )
  );
};

export default MenuProfileMobile;
