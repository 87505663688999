import { CMSCarouselSubscription } from "@model/CMSBrand";
import { useEffect, useState } from "react";
import { useSelector } from "./redux";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { Course, CoursesMap } from "@model/CoursesClass";
import { isInPlan } from "@utility/ecommerceUtility";
import { UserProfile } from "@model/User";
import { mapCMSOrdinamento } from "@utility/CMSBrandUtility";
import { multipleSortCourses } from "@utility/sortUtility";
import { isCourseVisible, removeHTMLTags } from "@utility/Api";
import { CMSPlanPage } from "@model/CMSPlanPage";
import { CMSCarouselLayout, HPCarouselCourseIdL1 } from "./useHomepageCarousels";
import { getIdsFromTeasersHeroBanner } from "../store/selectors/coursesSelectors";

type CardsInfos = {
  label: string,
  image: string
  cardContentId: number
  labelContentId: number
}

class WhoIsThisPlanForSection {
  sectionTitle: string;
  sectionDescr: string;
  planInfoContentId: number;
  cardsSection: CardsInfos[] = [];
}

export class PlanPageCarousel {
  layout: CMSCarouselLayout = CMSCarouselLayout.EDITORIAL;
  elementNumber: number;
  title: string;
  description: string;
  link: string;
  linkLabel: string;
  id: string;
  variant: string = 'card-evolution';
  position: number;
  courses: Course[];
  contentId: number;
}

const usePlanCarousel = (): [
  number,
  number,
  WhoIsThisPlanForSection,
  PlanPageCarousel[],
  boolean
] => {
  const dispatch = useDispatch();
  //const planCarouselInfo: CMSCarouselSubscription = useSelector((state) => state.cms.planCarouselInfo);
  const isLoadingPlanCarousel: boolean = useSelector((state) => state.user.ecommerceEnable && (state.cms.isLoadingPlanCarouselInfo || !state.course.isGetCourseCompleted));
  const planPageInfos: CMSPlanPage = useSelector((state) => state.cms.planPageInfos);
  const isLoadingPlanPageInfos: boolean = useSelector((state) => state.user.ecommerceEnable && (state.cms.isLoadingPlanPageInfos || !state.course.isGetCourseCompleted))
  const planPageLanguage = useSelector((state) => state.cms.planPageInfosLanguage);

  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);
  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);
  const ecommerceEnable: boolean = useSelector((state) => state.user.ecommerceEnable);

  const [numCoursesToShow, setNumCoursesToShow] = useState<number>(12);
  const [CMSPlanPageContentId, setCMSPlanPageContentId] = useState<number>();
  const [whoIsThisPlanForSection, setWhoIsThisPlanForSection] = useState<WhoIsThisPlanForSection>();
  const [planPageCarousels, setPlanPageCarousels] = useState([]);

  useEffect(() => {
    if (ecommerceEnable && !planPageInfos) {
      dispatch(actions.getCMSPlanPageInfos());
    }
  }, [ecommerceEnable, planPageInfos]);

  useEffect(() => {
    //download planpage according to chosen lang
    if(planPageLanguage) {
      if (planPageLanguage !== userProfile?.preferredLang && !isLoadingPlanPageInfos) {
        dispatch(actions.getCMSPlanPageInfos());
      }
    }
  }, [userProfile?.preferredLang]);

  useEffect(() => {
    if (!ecommerceEnable || isLoadingPlanPageInfos || !planPageInfos) {
      setWhoIsThisPlanForSection(null);
      setPlanPageCarousels([]);
      return;
    }

    //get CMS PlanPage contentId
    const CMSPlanPageContentIdTemp = planPageInfos[0]?.contentId;

    //get who is this plan for Section
    const whoIsThisPlanForSectionTemp = planPageInfos[0]?.placements?.row1.length > 0 ? 
    getWhoIsThisPlanForSection(planPageInfos[0]?.placements?.row1) : null

    //get carousels
    const carouselsToDisplayTemp = getPlanPageCarousels(planPageInfos[0]?.placements, coursesMap, userProfile, setNumCoursesToShow);
    setPlanPageCarousels(carouselsToDisplayTemp);
    setWhoIsThisPlanForSection(whoIsThisPlanForSectionTemp);
    setCMSPlanPageContentId(CMSPlanPageContentIdTemp);

    const purchasableCourses = filterPlanCarouselCourses(coursesMap)

  }, [coursesMap, userProfile, isLoadingPlanCarousel, ecommerceEnable, planPageInfos]);

  return [
    CMSPlanPageContentId,
    numCoursesToShow,
    whoIsThisPlanForSection,
    planPageCarousels,
    isLoadingPlanPageInfos
  ];
};

const getWhoIsThisPlanForSection = (row: CMSPlanPage["placements"]["row1"]): WhoIsThisPlanForSection => {
  let whoIsThisPlanForSection = new WhoIsThisPlanForSection();

  for (let key in row?.[0]) {
    if (row[0][key] !== "") {
      if (key.match(/^(text)[0-9]{1,}$/gi)) {
        let keyId = +key.replace("text", "");
        for (let el in row?.[0]?.extendedFields) {
          if (el.match(new RegExp(`^(content)[${keyId}]$`, "gi"))) {
            whoIsThisPlanForSection?.["cardsSection"].push({ label: row?.[0]?.[key], image: row[0]?.extendedFields?.[el]?.[0]?.["data"] , cardContentId: row[0]?.extendedFields?.[el]?.[0]?.["contentId"], labelContentId: row?.[0]?.extendedFields?.[el]?.[0]?.["contentId"]})
          }
        }
      }
    }

  }
  const titleDescContentId =  row?.[0]?.contentId;
  const sectionTitleAndDescr = removeHTMLTags(row?.[0]?.richtext1);
  const limitPosition = sectionTitleAndDescr.indexOf('?') + 1;
  whoIsThisPlanForSection["sectionTitle"] = sectionTitleAndDescr.substring(0, limitPosition);
  whoIsThisPlanForSection["sectionDescr"] = sectionTitleAndDescr.substring(limitPosition);
  whoIsThisPlanForSection["planInfoContentId"] = titleDescContentId;

  return whoIsThisPlanForSection;
}

const getPlanPageCarousels = (
  placements: CMSPlanPage["placements"],
  coursesMap: CoursesMap,
  userProfile: UserProfile,
  setNumCoursesToShow: (numCoursesToShow: number) => void
): PlanPageCarousel[] => {

  let planPageCarousels: PlanPageCarousel[] = [];

  const rowsKey = Object.keys(placements).sort((a, b) => {
    const aIndex = +a.replace('row', '');
    const bIndex = +b.replace('row', '');
    return aIndex - bIndex;
  });

  let i = 1;
  for (let rowKey of rowsKey) {
    if (rowKey === "row1") { //dedicated to whoIsThisPlanForSection
      continue;
    } else {

      const row = placements[rowKey];
      if (row?.length > 0) {

        let carousel = new PlanPageCarousel();
        carousel.title = row[0]?.title;
        carousel.linkLabel = row[0]?.linkLabel;
        carousel.link = row[0]?.link || row[0]?.segment.replace("-", "_");
        carousel.description = row[0]?.description;
        carousel.id = row[0]?.segment;
        carousel.contentId = row[0]?.contentId;
        carousel.courses = getCarouselCoursesToDisplay(getIdsFromTeasersHeroBanner(row[0]?.extendedFields?.CourseIdL1), coursesMap)

        // sort courses in the carousel
        const ordinamento = row?.extendedFields?.Ordinamento?.length > 0
          ? row?.extendedFields?.Ordinamento
          : [{ value: 'Newness', externalReference: '' }];
        const sortings = mapCMSOrdinamento(ordinamento);
        carousel.courses = multipleSortCourses(carousel.courses, sortings, null, userProfile);

        // show max N elements in the carousel
        if (row?.extendedFields?.ElementNumber?.length > 0) {
          setNumCoursesToShow(+(row.extendedFields.ElementNumber[0].value));
        }

        if (carousel.courses.length > 0) {
          carousel.position = i;
          planPageCarousels.push(carousel);
          i++;
        }

      }

    }

  }

  if(planPageCarousels.length > 4) {
    planPageCarousels.slice(0,4); //max 4 carousels in PLP
  }
  
  return planPageCarousels;

}

export const filterPlanCarouselCourses = (coursesMap) => {
  let purchasableCourses: Array<Course> = [];

  if (coursesMap) {
    for (let id in coursesMap) {
      const course = coursesMap[id];
      //get just priceable lessons
      if (isCourseVisible(course) && isInPlan(course)) {
        purchasableCourses.push(course);
      }
    }
  }
  return purchasableCourses;
}

const getCarouselCoursesToDisplay = (carouselIdsAndL1Ids: HPCarouselCourseIdL1, coursesMap: CoursesMap) => {
  const ids = carouselIdsAndL1Ids["ids"];
  let coursesToDisplay: Course[] = [];

  if (coursesMap && carouselIdsAndL1Ids) {
    for (let carouselId of ids) {
      const course = coursesMap[carouselId.id];
      //get just priceable lessons
      if (isCourseVisible(course) && isInPlan(course)) {
        coursesToDisplay.push({...course, contentId: carouselId?.contentId});
      }
    }
  }
 
  return coursesToDisplay;

}

export default usePlanCarousel;
