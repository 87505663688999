import { useState, useEffect } from 'react';
import { Course, CoursesMap } from '@model/CoursesClass';
import { useSelector } from '@hooks/redux';
import { isEducationalPath } from '@utility/EducationalPathsUtility';
import { getHeaders, isCompletedCourse, isCourseVisible, isMandatory, isStartedCourse, sanitizeCourseIdV2 } from '@utility/Api';
import { compareCourses, sortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
const axios = require('axios');
import * as urlList from '../../config';
import { updateCoursesInCatalog } from '../../store/actions/coursesActions';
import { TeacherEducationalPaths } from '@model/Teacher';
import { useDispatch } from 'react-redux';
import { isStartedLp } from '@utility/LPUtility';

class EducationalPathMapClass {
	available: Course[] = [];
	completed: Course[] = [];
}

class useShowEducationalPathsMenuProps {}

const useGetEducationalPaths = (downloadTeachers = false): [EducationalPathMapClass, TeacherEducationalPaths[]] => {
	const dispatch = useDispatch();
	const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
	const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

	const [educationalPathsMap, setEducationalPathsMap] = useState<EducationalPathMapClass>(new EducationalPathMapClass());
	const [teachers, setTeachers] = useState([]);

	useEffect(() => {
		let educationalPathsMapTemp = new EducationalPathMapClass();
		if (coursesMap) {
			Object.values(coursesMap).forEach(course => {
				//user must have at least one educational path excluding the onboarding retail
				if (isCourseVisible(course) && isEducationalPath(course)) {
					if (isCompletedCourse(course)) {
						educationalPathsMapTemp.completed.push(course);
					} else {
						educationalPathsMapTemp.available.push(course);
					}
				}
			});
		}

		educationalPathsMapTemp.available = sortCourses(educationalPathsMapTemp.available, SORTBY.START_DATE);
		educationalPathsMapTemp.available = sortCourses(educationalPathsMapTemp.available, SORTBY.COMPLEXITY_DESC);
		educationalPathsMapTemp.available = educationalPathsMapTemp.available.sort((a, b) => compareCourses(a, b, SORTBY.COURSE_STARTED, coursesMap));
		educationalPathsMapTemp.available = sortCourses(educationalPathsMapTemp.available, SORTBY.MANDATORY_DESC);
		educationalPathsMapTemp.completed = sortCourses(educationalPathsMapTemp.completed, SORTBY.COMPLETION);
		educationalPathsMapTemp.available = sortCourses(educationalPathsMapTemp.available, SORTBY.MASTER_PRIORITY);
		educationalPathsMapTemp.available = educationalPathsMapTemp.available.sort((a, b) => compareCourses(a, b, SORTBY.BLOCKED_PROGRAM, coursesMap));

		if (downloadTeachers) {
			//update teachers if some educational paths enter or leave the available list
			let shouldUpdateTeachers = false;
			//get old available educational paths list
			const oldAvailableCourseIdMasters = educationalPathsMap.available.map(a => a.courseIdMaster).sort((a, b) => a.localeCompare(b));
			//get new available educational paths list
			const newAvailableCourseIdMasters = educationalPathsMapTemp.available.map(a => a.courseIdMaster).sort((a, b) => a.localeCompare(b));

			if (oldAvailableCourseIdMasters.length !== newAvailableCourseIdMasters.length) {
				//old and new lists have a different number of elements
				shouldUpdateTeachers = true;
			} else {
				oldAvailableCourseIdMasters.forEach((a, index) => {
					if (a !== newAvailableCourseIdMasters[index]) {
						//old and new lists have a different element
						shouldUpdateTeachers = true;
					}
				});
			}

			if (shouldUpdateTeachers) {
				const completedIds = educationalPathsMapTemp.completed.map(p => p.courseIdMaster);

				const queryParams = `?courseId=${[...newAvailableCourseIdMasters, ...completedIds].join(',')}`;
				const url = urlList.GET_COURSES_EDUCATIONAL_PATHS.replace('{courseIds}', queryParams);
				if(isAuthenticated) {
					axios({
						url,
						method: 'GET',
						headers: getHeaders(),
					})
						.then(response => {
							const teachers = response.data.teachers;
							teachers.sort((a, b) => a.firstName?.toLowerCase().localeCompare(b.firstName?.toLowerCase()));
							setTeachers(teachers);
	
							const courses = response.data.learningPathCourses;
							dispatch(
								updateCoursesInCatalog(
									courses,
									courses.map(course => course.courseIdMaster),
									'',
									true,
								),
							);
						})
						.catch(error => {
							console.error("Error during EducationalPaths's Teachers download", error);
						});
				}
			}
		}
		
		setEducationalPathsMap(educationalPathsMapTemp);
	}, [coursesMap]);

	return [educationalPathsMap, teachers];
};

export default useGetEducationalPaths;
