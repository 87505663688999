import React, { useEffect, useState } from 'react';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import SkeletonPulse from './SkeletonPulse';


const EditorialCarouselSkeletonPulse = props => {
  const dimensions = useEditorialCarouselSkeletonPulseDimensions(props?.variant);

  const yCard = dimensions.title.height + dimensions.title.marginBottom
    + dimensions.description.height + dimensions.description.marginBottom
    + dimensions.description2.height + dimensions.description2.marginBottom;
  const fullHeight = yCard
    + dimensions.card.height + dimensions.card.marginBottom
    + dimensions.cardTitle1.height + dimensions.cardTitle1.marginBottom
    + dimensions.cardTitle2.height + dimensions.cardTitle2.marginBottom
    + dimensions.cardTitle3.height + dimensions.cardTitle3.marginBottom
    + dimensions.cardTitle4.height + dimensions.cardTitle4.marginBottom
    + dimensions.cardTitle5.height + dimensions.cardTitle5.marginBottom;

  return (
    <div className={'skeleton__editorial-carousel ' + props.className}>
      <SkeletonPulse
        index={props.index}
        width={(dimensions.card.width * numElements) + (dimensions.spaceBetweenCards * (numElements - 1))}
        height={fullHeight}
        widthRect="100vw"
      >
        <CarouselTitleSkeletonPulse dimensions={dimensions} />

        {!props?.variant &&
          [...Array(numElements)].map((e, i) => (
            <CardBigEvolutionSkeletonPulse
              key={i}
              x={(dimensions.card.width * i) + (dimensions.spaceBetweenCards * i)}
              y={yCard} dimensions={dimensions}
            />
          ))
        }

        {props?.variant == "trainingPills" &&
          [...Array(numElements)].map((e, i) => (
            <CardMediumPillSkeletonPulse
              key={i}
              x={(dimensions.card.width * i) + (dimensions.spaceBetweenCards * i)}
              y={yCard} dimensions={dimensions}
            />
          ))
        }

        {props?.variant == "latestCollections" &&
          [...Array(numElements)].map((e, i) => (
            <CardLatestCollectionsSkeletonPulse
              key={i}
              x={(dimensions.card.width * i) + (dimensions.spaceBetweenCards * i)}
              y={yCard} dimensions={dimensions}
            />
          ))
        }

      </SkeletonPulse>
    </div>
  );
};


export const CarouselTitleSkeletonPulse = ({ dimensions }) => {
  return (
    <>
      <rect x="0" y="0" rx="6" ry="6" width={dimensions.title.width} height={dimensions.title.height} />
      <rect x="0" y={dimensions.title.height + dimensions.title.marginBottom} rx="6" ry="6" width={dimensions.description.width} height={dimensions.description.height} />
      {dimensions.description2.width > 0 &&
        <rect x="0" y={dimensions.title.height + dimensions.title.marginBottom + dimensions.description.height + dimensions.description.marginBottom}
          rx="6" ry="6" width={dimensions.description2.width} height={dimensions.description2.height}
        />
      }
    </>
  )
}

export const CardBigEvolutionSkeletonPulse = ({ x, y, dimensions }) => {
  const yTitle1 = y + dimensions.card.height + dimensions.card.marginBottom;
  const yTitle2 = yTitle1 + dimensions.cardTitle1.height + dimensions.cardTitle1.marginBottom;
  const yTitle3 = yTitle2 + dimensions.cardTitle2.height + dimensions.cardTitle2.marginBottom;
  const yTitle4 = yTitle3 + dimensions.cardTitle3.height + dimensions.cardTitle3.marginBottom;
  const yTitle5 = yTitle4 + dimensions.cardTitle4.height + dimensions.cardTitle4.marginBottom;

  return (
    <>
      <rect x={x} y={y} rx="6" ry="6" width={dimensions.card.width} height={dimensions.card.height} />
      <rect x={x} y={yTitle1} rx="6" ry="6" width={dimensions.cardTitle1.width} height={dimensions.cardTitle1.height} />
      <rect x={x} y={yTitle2} rx="6" ry="6" width={dimensions.cardTitle2.width} height={dimensions.cardTitle2.height} />
      <rect x={x} y={yTitle3} rx="6" ry="6" width={dimensions.cardTitle3.width} height={dimensions.cardTitle3.height} />
      <rect x={x} y={yTitle4} rx="6" ry="6" width={dimensions.cardTitle4.width} height={dimensions.cardTitle4.height} />
      {dimensions.cardTitle5.width > 0 &&
        <rect x={x} y={yTitle5} rx="6" ry="6" width={dimensions.cardTitle5.width} height={dimensions.cardTitle5.height} />
      }
    </>
  )
}

export const CardMediumPillSkeletonPulse = ({x, y, dimensions}) => {
  const yTitle1 = y + dimensions.card.height + dimensions.card.marginBottom;
  const yTitle2 = yTitle1 + dimensions.cardTitle1.height + dimensions.cardTitle1.marginBottom;
  const yTitle3 = yTitle2 + dimensions.cardTitle2.height + dimensions.cardTitle2.marginBottom;
  const yTitle4 = yTitle3 + dimensions.cardTitle3.height + dimensions.cardTitle3.marginBottom;

  return (
    <>
      <rect x={x} y={y} rx="12" ry="12" width={dimensions.card.width} height={dimensions.card.height}/>
      <rect x={x} y={yTitle1} rx="10" ry="10" width={dimensions.cardTitle1.width} height={dimensions.cardTitle1.height}/>
      <rect x={x} y={yTitle2} rx="7" ry="7" width={dimensions.cardTitle2.width} height={dimensions.cardTitle2.height}/>
      <rect x={x} y={yTitle3} rx="7" ry="7" width={dimensions.cardTitle3.width} height={dimensions.cardTitle3.height}/>
      <rect x={x} y={yTitle4} rx="5.25" ry="5.25" width={dimensions.cardTitle4.width} height={dimensions.cardTitle4.height}/>
      <rect x={x + dimensions.cardTitle4.width + 6} y={yTitle4} rx="5.25" ry="5.25" width={dimensions.cardTitle5.width} height={dimensions.cardTitle5.height}/>
    </>
  )
}

export const CardLatestCollectionsSkeletonPulse = ({x, y, dimensions}) => {

  return (
    <rect x={x} y={y} rx="12" ry="12" width={dimensions.card.width} height={dimensions.card.height}/>
  )

}

export const useEditorialCarouselSkeletonPulseDimensions = (variant = "default") => {

  const breakpoint = useBreakpointBoolean();
  const [dimensions, setDimensions] = useState(editorialCarouselSkeletonDimensions[variant].desktopOrTabletLandscape);

  useEffect(() => {
    if (breakpoint.isDesktopOrTabletLandscape) {
      setDimensions(editorialCarouselSkeletonDimensions[variant].desktopOrTabletLandscape);
    } else if (breakpoint.isTablet && breakpoint.isPortrait) {
      setDimensions(editorialCarouselSkeletonDimensions[variant].tabletPortrait);
    } else {
      setDimensions(editorialCarouselSkeletonDimensions[variant].phone);
    }
  }, [breakpoint]);

  return dimensions;
}

export const editorialCarouselSkeletonDimensions = {
  default: {
    desktopOrTabletLandscape: {
      spaceBetweenCards: 16,
      title: {
        width: 786,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 786,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 255,
        height: 143,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 132,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 254,
        height: 24,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 254,
        height: 24,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 132,
        height: 24,
        marginBottom: 0,
      },
      cardTitle5: {
        width: 0,
        height: 0,
        marginBottom: 0,
      }
    },
    tabletPortrait: {
      spaceBetweenCards: 12,
      title: {
        width: 480,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 480,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 176,
        height: 227,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 79,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 152,
        height: 24,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 152,
        height: 24,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 79,
        height: 24,
        marginBottom: 8,
      },
      cardTitle5: {
        width: 79,
        height: 24,
        marginBottom: 0,
      }
    },
    phone: {
      spaceBetweenCards: 12,
      title: {
        width: 319,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 319,
        height: 14,
        marginBottom: 11,
      },
      description2: {
        width: 319,
        height: 14,
        marginBottom: 20,
      },
      card: {
        width: 176,
        height: 227,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 79,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 152,
        height: 24,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 152,
        height: 24,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 79,
        height: 24,
        marginBottom: 8,
      },
      cardTitle5: {
        width: 79,
        height: 24,
        marginBottom: 0,
      }
    }
  },
  trainingPills: {
    desktopOrTabletLandscape: {
      spaceBetweenCards: 16,
      title: {
        width: 786,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 786,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 255,
        height: 230,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 132,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 30,
        height: 10.5,
        marginBottom: 0,
      },
      cardTitle5: {
        width: 30,
        height: 10.5,
        marginBottom: 0,
      }
    },
    tabletPortrait: {
      spaceBetweenCards: 16,
      title: {
        width: 480,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 480,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 220,
        height: 198,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 132,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 30,
        height: 10.5,
        marginBottom: 8,
      },
      cardTitle5: {
        width: 30,
        height: 10.5,
        marginBottom: 0,
      }
    },
    phone: {
      spaceBetweenCards: 16,
      title: {
        width: 319,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 319,
        height: 14,
        marginBottom: 11,
      },
      description2: {
        width: 319,
        height: 14,
        marginBottom: 20,
      },
      card: {
        width: 220,
        height: 198,
        marginBottom: 12,
      },
      cardTitle1: {
        width: 132,
        height: 20,
        marginBottom: 8,
      },
      cardTitle2: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle3: {
        width: 80,
        height: 14,
        marginBottom: 8,
      },
      cardTitle4: {
        width: 30,
        height: 10.5,
        marginBottom: 8,
      },
      cardTitle5: {
        width: 30,
        height: 10.5,
        marginBottom: 0,
      }
    }
  },
  latestCollections: {
    desktopOrTabletLandscape: {
      spaceBetweenCards: 2,
      title: {
        width: 786,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 786,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 260,
        height: 550,
        marginBottom: 0,
      },
      cardTitle1: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle3: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle4: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle5: {
        width: 0,
        height: 0,
        marginBottom: 0,
      }
    },
    tabletPortrait: {
      spaceBetweenCards: 2,
      title: {
        width: 480,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 480,
        height: 14,
        marginBottom: 20,
      },
      description2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      card: {
        width: 260,
        height: 550,
        marginBottom: 0,
      },
      cardTitle1: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle3: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle4: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle5: {
        width: 0,
        height: 0,
        marginBottom: 0,
      }
    },
    phone: {
      spaceBetweenCards: 2,
      title: {
        width: 319,
        height: 30,
        marginBottom: 11,
      },
      description: {
        width: 319,
        height: 14,
        marginBottom: 11,
      },
      description2: {
        width: 319,
        height: 14,
        marginBottom: 20,
      },
      card: {
        width: 260,
        height: 550,
        marginBottom: 0,
      },
      cardTitle1: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle2: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle3: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle4: {
        width: 0,
        height: 0,
        marginBottom: 0,
      },
      cardTitle5: {
        width: 0,
        height: 0,
        marginBottom: 0,
      }
    }
  }
}

const numElements = 8;

export default EditorialCarouselSkeletonPulse;
