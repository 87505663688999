import React from "react";
import { OnboardingLabelsIconClass } from "@hooks/onboarding/useGetOnboardingLabels";
import { printHTML } from "@utility/Api";
import TagBadge from "@components/UI/TagBadge";

type OnboardingIconsListProps = {
  onboardingIcons: OnboardingLabelsIconClass[];
  isTagBadge?: boolean;
  className?: string;
};

const OnboardingIconsList = ({ onboardingIcons, isTagBadge = false, className }: OnboardingIconsListProps) => {

  return (
    <div className={"onboarding-icons-list__icons " + (isTagBadge ? "onboarding-icons-list__icons-tagbadge " : " ") + (className ? className : "")}>
      {onboardingIcons?.map(icon =>
        <div key={icon.icon} className="onboarding-icons-list__icon-row">
          {isTagBadge
            ?
            <TagBadge
              variant="filled"
              label={icon.label}
              isHTML={icon.isHTML}
            />
            :
            <>
              {/* <LazySVG
                className="onboarding-icons-list__icon"
                src={icon.icon}
                alt={icon.label}
              /> */}
              {/* LEON-3452 -- HIDE BULLET POINT */}
              {/* <span
                className="onboarding-icons-list__icon-label"
                dangerouslySetInnerHTML={icon.isHTML ? printHTML("• " + icon.label) : null}
              >
                {!icon.isHTML ? "• " + icon.label : null}
              </span> */}
            </>
          }
        </div>
      )}
    </div>
  );
};

export default OnboardingIconsList;