import React, { ReactElement, useEffect } from 'react';
import Modal from './UI/Modal';
import { Course, LangMap } from '@model/CoursesClass';
import * as actions from '@redux-actions/';
import { connect, ConnectedProps } from 'react-redux';
import Slider from './UI/Slider';
import TextField from './UI/TextField';
import ButtonV2 from '@components/UI/ButtonV2';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { UserProfile } from '@model/User';
import { SurveyQA, SURVEY_QUESTION_TYPE, SURVEY_TYPE } from '@model/Survey';
import CardLoader from './UI/CardLoader';
import { SurveyResponse } from '@model/SurveyResponse';
import { getReviewSurveyType, isLearningPath } from '@utility/Api';
import { RootState } from 'src/store/store';

type CourseReviewModalProps = PropsFromRedux & {
  lang: LangMap;
  userProfile: UserProfile;
  goBack: () => void;
  courseName: string;
  show: boolean;
  course: Course;
  getSurveyQuestions: (language: string, surveyType: string) => void;
  isLoadingReview: boolean;
  reviewQuestions: SurveyQA;
  sendSurveyAnswers: (answers: SurveyResponse, surveyResponse: string) => void;
  isSendingReview: boolean;
  sendReviewSuccess: boolean;
  resetReviewModal: () => void;
};

type FormValues = {
  [key: string]: string;
};

export const CourseReviewModal = ({
  lang,
  userProfile,
  goBack,
  courseName,
  course,
  show,
  getSurveyQuestions,
  isLoadingReview,
  reviewQuestions,
  sendSurveyAnswers,
  isSendingReview,
  sendReviewSuccess,
  resetReviewModal,
}: CourseReviewModalProps): ReactElement => {
  const [counterDigit, setCounterDigit] = React.useState(255);
  const { register, handleSubmit } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    console.log('submit', data);

    let userResponses = [];
    for (let question of reviewQuestions.survey.questions) {
      const userResponse = data[question.id];
      if (userResponse) {
        if (question.questionType === SURVEY_QUESTION_TYPE.SINGLE_CHOICE) {
          const questionResponse = question.responses[userResponse];
          userResponses.push({
            questionId: question.id,
            responseId: questionResponse.id,
          });
        } else if (question.questionType === SURVEY_QUESTION_TYPE.FREE_TEXT) {
          userResponses.push({
            questionId: question.id,
            responseId: null,
            freeText: userResponse,
          });
        }
      }
    }

    let surveyResponse = new SurveyResponse();
    surveyResponse.compilationDate = new Date().getTime();
    surveyResponse.courseId = course.courseIdMaster;
    surveyResponse.id =
      reviewQuestions.surveyResponses && reviewQuestions.surveyResponses[0]
        ? reviewQuestions.surveyResponses[0].id
        : null;
    surveyResponse.surveyId = reviewQuestions.survey.id;
    surveyResponse.username = userProfile.username;
    surveyResponse.userResponses = userResponses;
    console.log('surveyResponse', surveyResponse);

    sendSurveyAnswers(surveyResponse, getReviewSurveyType(course));
  };

  useEffect(() => {
    getSurveyQuestions(
      userProfile?.preferredLang ? userProfile?.preferredLang : 'en',
      getReviewSurveyType(course)
    );
  }, []);

  useEffect(() => {
    if (sendReviewSuccess) {
      goBack();
    }
  }, [sendReviewSuccess]);

  useEffect(
    () => () => {
      resetReviewModal();
    },
    []
  );

  const handleChange = val => {
    setCounterDigit(255 - val.length);
  };

  return (
    <div className="course-review-modal">
      <Modal
        close={goBack}
        hideX
        blur="always"
        blurIntensity="07"
        contentClassName="modal__content--small"
        show={show}
      >
        <div className="course-review-modal__wrapper">
          <header>
            <span className="course-review-modal__title">{courseName.toUpperCase()}</span>
          </header>
          <div className="course-review-modal__subtitle">
            {isLearningPath(course) ? lang.RATE_COURSE_TITLE : lang.RATE_LESSON_TITLE}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoadingReview ? (
              <CardLoader className="card-loader__center" />
            ) : (
              <>
                <div className="course-review-modal__questions">
                  {reviewQuestions?.survey?.questions &&
                    reviewQuestions?.survey?.questions.map((question, index) => (
                      <React.Fragment key={question.id}>
                        {question.questionType === SURVEY_QUESTION_TYPE.SINGLE_CHOICE && (
                          <div className="question__box">
                            <div className="question__title">{question.questionInLanguage}</div>
                            <div className="question__slider">
                              <Slider
                                max={question.responses.length - 1}
                                name={question.id.toString()}
                                leftLabel={question.responses[0].responseInLanguage}
                                rightLabel={
                                  question.responses[question.responses.length - 1]
                                    .responseInLanguage
                                }
                                ref={register}
                              />
                            </div>
                          </div>
                        )}
                        {question.questionType === SURVEY_QUESTION_TYPE.FREE_TEXT && (
                          <div className="question__box">
                            <div className="question__title question__title-textarea">
                              {question.questionInLanguage}
                            </div>
                            <div className="question__textfield">
                              <div className="char-counter">{counterDigit}/255</div>
                              <TextField
                                textarea
                                variant="border"
                                placeholder={lang.COMMENTS_SUGGESTIONS}
                                name={question.id.toString()}
                                ref={register}
                                maxLength={255}
                                onChange={handleChange}
                                className="text-area"
                              />
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
                <div className="ecommerce-alert-modal__footer">
                  <ButtonV2 variant="primary" small loading={isSendingReview}>
                    {lang.SUBMIT_SURVEY}
                  </ButtonV2>
                  <ButtonV2 variant="secondary" small handleClick={goBack}>
                    {lang.CANCEL}
                  </ButtonV2>
                </div>
              </>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
    userProfile: state.user.userProfile,
    isLoadingReview: state.utils.isLoadingReview,
    reviewQuestions: state.utils.reviewQuestions,
    isSendingReview: state.utils.isSendingReview,
    sendReviewSuccess: state.utils.sendReviewSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSurveyQuestions: (language, surveyType) =>
      dispatch(actions.getSurveyQuestions(language, surveyType)),
    sendSurveyAnswers: (answers, surveyType) =>
      dispatch(actions.sendSurveyAnswers(answers, surveyType)),
    resetReviewModal: () => dispatch(actions.resetReviewModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CourseReviewModal);
