import { TrainingPill } from '@model/ProductClass';

export class CollectionInfoResponse {
  brandName: string;
  brandDescription: string;
  urlImage: string;
  urlVideo: string;
  urlVtt: string;
  release: string;
  brand: string;
  brandCode?: string;
}

export class CollectionStoriesResponse {
  shortName: string;
  longName: string;
  release: string;
  releaseDate: Date;
  isOlderThan2Months: boolean;
  stories: CollectionStoryResponse[];
}

export class CollectionStoryResponse {
  name: string;
  description: string;
  noveltiesByBrands: NoveltyByBrand[];
  order: number;
  urlImage: string;
}

export class CollectionClass {
  longName: string;
  brandDescription: string;
  urlVideo: string;
  urlVtt: string;
  urlImage: string;
  release: string;
  releaseDate: Date;
  isOlderThan2Months: boolean;
  brand: string;
  brandName: string;
  stories: CollectionStory[];
  brandCollectionLongName?: string;
}

export class CollectionStories {
  longName: string;
  release: string;
  releaseDate: Date;
  isOlderThan2Months: boolean;
  stories: CollectionStoryResponse[];
}

export class CollectionStory {
  name: string;
  description: string;
  urlImage: string;
  order: number;
  novelties: CollectionNovelty[];
  isOpen?: boolean; //per gestire accordion
}

export class CollectionNovelty {
  code: string; //id modello della novelty
  urlImage: string; //immagine della novelty
  urlAnnotatedImage?: string; //immagine della novelty 3/4
  modelName: string; //nome del modello
  order: number; //non usato, l'ordinamento avviene lato bff
  advertising: boolean; //true se il modello è taggato come advertising (usato poi per ordinamento),
  brandCampaign?: string[] //COLLECTION WAVE 3
}

export class CollectionInfoMapModel {
  [key: string]: CollectionInfoMapElementModel;
}

export class CollectionInfoMapElementModel {
  collection: CollectionInfoResponse;
  isLoading: boolean = false;
  isCompleted: boolean = false;
  language: string = undefined;
}

export class CollectionStoriesMapModel {
  [key: string]: CollectionStoriesMapElementModel;
}

export class CollectionStoriesMapElementModel {
  collection: CollectionStories;
  isLoading: boolean = false;
  isCompleted: boolean = false;
  language: string = undefined;
}

export class LatestCollectionCarouselModel {
  shortName: string; //nome "corto della collezione", es. SS 2022
  longName: string; //nome della collezione, es. Spring/Summer 2022
  description: string; //descrizione collezione
  release: string; //id della collezione
  releaseDate: Date; //release date della collezione (non usata a FE)
  isOlderThan2Months: boolean; //true se la release date è più vecchia di 2 mesi
  collectionNumber: number; //anche se viene restituito l'ordine della collezione, l'ordinamento avviene lato bff
  noveltiesByBrand: NoveltyByBrand[]; //informazioni legate ai brand
  addition: boolean; //true if collection is addition
}

export class LatestCollectionCarouselsModel extends LatestCollectionCarouselModel {
  isNew?: boolean;
}

export class NoveltyByBrand {
  brand: string; //id brand
  brandName: string; //nome del brand (i brand non si traducono)
  urlImage: string; //image used in latest collections page
  urlMenu: string; //image used inside collections menu and brand page
  urlVideo: string; //video usato nella pagina singola collezione
  order: number; //non usato, l'ordinamento avviene lato bff
  novelties: CollectionNovelty[]; //novelties
  houseBrand?: boolean; //true se il brand è house brand
  //info get by LatestCollectionCarouselModel
  release?: string;
  releaseDate?: Date;
  isOlderThan2Months?: boolean;
  shortName?: string;
  longName?: string;
  description?: string;
  //attributes set by FE
  brandCollectionLongName?: string;
  collectionNumber?: number;
  //CMS contentId for CMS Preview (LatestCollectionsCarousel.tsx 82)
  urlImageContentId?: number;
  urlMenuContentId?: number;
  urlVideoContentId?: number;
}

export class PillsFromSameStoryMapModel {
  [key: string]: PillsFromSameStoryModel;
}

export class PillsFromSameStoryModel {
  pills: TrainingPill[];
  isLoading: boolean = false;
  isCompleted: boolean = false;
  language: string = undefined;
  brandCollectionLongName: string = '';
}

/* COLLECTION WAVE 3 */
export class BrandCampaignsByCollection {
  tags: string[];
  title: string;
  description: string;
  image: string;
  order:number;
}
