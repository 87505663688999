import React from "react";
import { useSelector } from "@hooks/redux";
import { handleOnEnterKeyPress } from "@utility/Api";
import CardCover from "@components/CardV3/CardCover";
import { CardDisplayProps } from "@components/CardV3/CardDisplayUtils";
import { cmMetaData } from "@utility/previewUtils";
import { purifyItemDataElementIdandApplyCamelCasing } from '@utility/analytics-utils';
import { DataLayerTracking, selectTrackingDataLayerBase } from '@model/TrackingClass';
import { styledLogUtagView } from "@components/link-utils";

const CardHighlightsMenu = (props: CardDisplayProps) => {
  const { course, ctypeName } = props;

  const lang = useSelector((state) => state.utils.lang);

  const handleClickCard = () => {
    props.openCourseModal();
  };

  const handleKeyDown = (event) => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };
  return (
    <a
      data-id={props.courseIdMaster}
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace(
        "{name}",
        props.courseFullName
      )}
      className={"card-highlights-menu focus-outline "}
      onClick={handleClickCard}
      tabIndex={props.tabIndex}
      onKeyDown={handleKeyDown}
      data-element-id={
        course?.level0
          ? `MainNav_Brands_${purifyItemDataElementIdandApplyCamelCasing(course.level0)}_${(course.courseId)}`
          : `mainnav_Brands_tile`
      }
      data-description='Tile'
    >
      <div className="card-highlights-menu__content">
        <div className="card-highlights-menu__image">
          <CardCover
            image={props.image}
            courseFullName={props.courseFullName}
            fallbackImage={props.fallbackImage}
            hideShadow
            hideSpinner
            typeHarvard={props?.typeHarvard}
          />
        </div>

        <div className="card-highlights-menu__info">
          <div className="card-highlights-menu__ctype-row">
            <span className="card-highlights-menu__ctype">
              {ctypeName}
            </span>
            {props.duration &&
              <>
                <div className="card-highlights-menu__divider" />
                <span className="card-highlights-menu__duration">
                  {props.duration}
                </span>
              </>
            }
          </div>
          <span className="card-highlights-menu__name">
            {course.courseFullName?.toUpperCase()}
          </span>
        </div>
      </div>
    </a>
  );
};

export default CardHighlightsMenu;
