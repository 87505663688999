import clsx from '@utility/clsx';
import React from 'react';
import ContentLoader from 'react-content-loader';

type SkeletonPulse = {
  className?: string
  index?: number | string
  width?: number
  height?: number
  widthRect?: string
  children?: any
  primaryColor?: string
  secondaryColor?: string
  sizeAccordingDiv?: boolean
}

export const SkeletonPulse = ({
  className = "",
  index = 1, // index == 0 → error, starts from 1
  width = 100,
  height = 100,
  widthRect = "100px",
  children,
  primaryColor = "#ffffff26",
  secondaryColor = "#ffffff1a",
  sizeAccordingDiv = false,
}:SkeletonPulse) => {

  return (
    <div
      className={clsx("skeleton-pulse", {
        [className]: className,
      })}
      style={sizeAccordingDiv ? {} : { width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}
    >
      <ContentLoader
        width={widthRect || width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={sizeAccordingDiv ? { width: '100%', height: '100%' } : {}}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        animate={false}
      >
        {children && children}
        <linearGradient id={'lineargradientId_' + index} >
          <stop offset="0%" stopColor={secondaryColor}>
          </stop>
          <stop offset="30%" stopColor={primaryColor}>
          </stop>
          <stop offset="30%" stopColor={secondaryColor}>
          </stop>
          {/* x1 is the coordinate when linear gradient starts */}
          <animate attributeName="x1" dur="2.25s" from="-100%" to="200%" repeatCount="indefinite" />
          {/* x2 is the coordinate when linear gradient ends */}
          <animate attributeName="x2" dur="2.25s" from="0%" to="300%" repeatCount="indefinite" />
        </linearGradient>
        <rect fill={`url(#${"lineargradientId_" + index})`}></rect>
      </ContentLoader>
    </div>
  )
}

export default SkeletonPulse;