import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { ADD_TO_CART_STEPS } from "@utility/const";
import { ProductCart } from "@model/CartModel";
import { useSelector } from "@hooks/redux";
import ModalEvolution from "@components/UI/ModalEvolution";

type ModalCourseOnly1MonthProps = {
  show: boolean;
  close: () => void;
};

const ModalCourseOnly1Month = ({
  show,
  close,
}: ModalCourseOnly1MonthProps) => {
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
  const product: ProductCart = useSelector((state) => state.ecommerce.productModalCourseOnly1Month);
  const variantModalCourseOnly1Month: string = useSelector((state) => state.ecommerce.variantModalCourseOnly1Month);

  const dispatch = useDispatch();

  const handleBuyCourse = () => {
    close();

    //proceed to add to cart
    dispatch(actions.insertProductInCart(new ProductCart(product.partNumber, product.quantity), ADD_TO_CART_STEPS.EXPIRATION_LESS_1_MONTH));
  };

  const getLabels = () => {
    const labels = {
      title: '',
      description: '',
    };

    if (lang) {
      switch (variantModalCourseOnly1Month) {
        case '24h':
          labels.title = lang.HAVE_ONLY_1_DAY;
          labels.description = lang.HAVE_ONLY_1_DAY_DESCR;
          break;
        case '7d':
          labels.title = lang.HAVE_ONLY_7_DAYS;
          labels.description = lang.HAVE_ONLY_7_DAYS_DESCR;
          break;
        default:
          labels.title = lang.HAVE_ONLY_1_MONTH;
          labels.description = lang.HAVE_ONLY_1_MONTH_DESCR;
          break;
      }
    }
    // console.log('labels', labels)
    return labels;
  }

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="only-1-month"
      icon={'/images/svg-icons/calendar-2.svg'}
      title={getLabels().title}
      description={getLabels().description}
      mainButtonLabel={lang?.CONTINUE_LABEL}
      mainButtonAction={handleBuyCourse}
      secondaryButtonLabel={lang?.CANCEL}
      secondaryButtonAction={close}
    />
  );
};

export default ModalCourseOnly1Month;
