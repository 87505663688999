import React, { ReactElement } from "react";

import { handleOnEnterKeyPress, isCompletedCourse } from "@utility/Api";
import CircleProgress from "@components/UI/CircleProgress";
import PlayNoCircleIcon from "@images/svg-icons/play-no-circle.svg";
import CardLoader from "./CardLoader";
import { Course, CoursesMap } from "@model/CoursesClass";
import { isLpBlocked } from "@utility/LPUtility";


type PlayIconProps = {
  className?: string;
  ariaLabel: string;
  valueBar: number;
  onClick: () => void;
  tabIndex?: number;
  showProgressAsBorder?: boolean;
  course?: Course;
  coursesMap?: CoursesMap;
}

const PlayIcon = ({ className = '', ariaLabel, valueBar = 0, onClick, tabIndex = 0, course, coursesMap, showProgressAsBorder = false }: PlayIconProps): ReactElement => {

  const handleKeyDown = (event) => {
    handleOnEnterKeyPress(event, onClick, true);
  }

  return (
    <div
      className={"play-icon " + className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      key={`progress_${valueBar}`}
      >
      {!isCompletedCourse(course) && !isLpBlocked(course, coursesMap) && showProgressAsBorder && 
        <CardLoader className="card-loader--with-play"/>
      }
      <CircleProgress
        classNameFirstRing="variant-card-activity"
        firstPercentageValue={valueBar}
      />
      <PlayNoCircleIcon className="play-icon__play-svg" viewBox="0 0 13 16" />
    </div>
  );
};

export default PlayIcon;