import React, { ReactElement, useState } from "react";
import { Teacher } from "../../model/Teacher";
import Avatar from "@components/UI/Avatar";
import CardLoader from "@components/UI/CardLoader";
import ButtonV2 from "@components/UI/ButtonV2";
import PlayIcon from "@images/svg-icons/play.svg";
import PauseIcon from "@images/svg-icons/pause.svg";
import { useTranslation } from "react-i18next";
import LazySVG from "@components/LazySvg";
import { useSelector } from "@hooks/redux";
import BackgroundEvolution from "@components/UI/BackgroundEvolution";

type Props = {
  teacher: Teacher;
  link?: { label: string, to: string };
  hideTeacherDetails?: boolean;
  hidePlayButton?: boolean;
  hideRole?: boolean;
};

const CardTeacherOverview = (props: Props): ReactElement => {
  const lang = useSelector((state) => state.utils.lang);

  let audioElement = React.useRef<HTMLAudioElement>();
  const [audioPaused, setAudioPaused] = useState(true);


  const { t } = useTranslation();

  const toggleAudio = () => {
    if (audioElement?.current) {
      if (audioElement.current.paused) {
        const playPromise = audioElement.current.play();
        if (playPromise) {
          playPromise.then(function () {
            console.log('play')
          }).catch(function (error) {
            console.log('error play', error)
          });
        }
      } else {
        audioElement.current.pause();
      }

    }
  }

  return (
    <div className="card-v3 card-v3-teacher-overview">
      <BackgroundEvolution className='card-v3-teacher-overview__background' />
      {props.teacher ?
        <div className={'card-v3-teacher-overview__wrapper'} >
          <Avatar
            image={props.teacher.image} user={props.teacher}
            fullName={props.teacher.fullName} variant="community"
          />
          <div className="card-v3-teacher-overview__info" role="region" aria-labelledby="teacher-name" id="main">
            <span id="teacher-name" className="text-24 card-v3-teacher-overview__fullName">{props.teacher.fullName}</span>
            {!props.hideTeacherDetails &&
              <>
                {!props.hideRole &&
                  <span className="text-16">{props.teacher.role ? props.teacher.role.join(', ') : ''}</span>
                }
                <div className="card-v3-teacher-overview__location">
                  {props.teacher.location &&
                    <>
                      <LazySVG src="/images/svg-icons/map-marker.svg" alt={lang.LOCATION} />
                      <span className="text-16">{props.teacher.location}</span>
                    </>
                  }
                </div>
              </>
            }
            {props.link &&
              <ButtonV2 variant="text-btn" small to={props.link.to} className="card-v3-teacher-overview__link">
                {props.link.label}
              </ButtonV2>
            }
          </div>
          {(!props.hideTeacherDetails && !props.hidePlayButton) &&
            <>
              <audio id="teacher-audio" ref={audioElement} style={{ display: 'none' }} onPlay={() => setAudioPaused(false)} onPause={() => setAudioPaused(true)}>
                <source src={props.teacher.audio} />
              </audio>
              <ButtonV2
                variant="tertiary"
                small
                className="teacher-audio-button"
                title={audioPaused ? t("PLAY_AUDIO") : t("PAUSE_AUDIO")}
                handleClick={toggleAudio}
              >
                {audioPaused
                  ? <PlayIcon />
                  : <PauseIcon />
                }
              </ButtonV2>
            </>
          }
        </div>
        : <CardLoader />
      }
    </div>
  );
};

export default CardTeacherOverview;
