import { Course, CoursesMap } from '@model/CoursesClass';
import { isCourseMaster, isCourseOnBoarding } from './onBoardingCourseUtility';
import { isCompletedCourse } from './Api';

export const isEducationalPath = (course: Course) => {
  return isCourseOnBoarding(course);
};

export const isProgramSoldOut = (course: Course) => {
  return isCourseMaster(course) && course?.masterAttributes?.availableSubscription <= 0;
};

export const getBlockingPrograms = (program: Course, coursesMap: CoursesMap) => {
  if (!program || !isEducationalPath(program) || !program.blockedby || program.blockedby.length < 1 || !coursesMap) {
      return [];
  }

  let blockingPrograms: Course[] = [];
  for (const blockingProgramId of program.blockedby) {
      const blockingProgram = coursesMap[blockingProgramId];
      if (blockingProgram && !isCompletedCourse(blockingProgram)) {
          blockingPrograms.push(blockingProgram);
      }
  }

  return blockingPrograms;
}

export const isProgramBlocked = (program: Course, coursesMap: CoursesMap) => {
  return getBlockingPrograms(program, coursesMap).length > 0;
}