import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { PICKER_COLORS } from '@utility/gradient-utils';
import clsx from '@utility/clsx';
import { EVOLUTION_PRIMARY_COLOR } from '@utility/const';

type Props = {
    className?: string;
    classNameFirstRing?: string;
    classNameSecondRing?: string;
    firstPercentageValue: number;
    secondPercentageValue?: number;
    firstColor?: string;
    secondColor?: string;
    children?: JSX.Element;
}

const isNumberValued = (num: number): boolean => {
    return !!num || num === 0;
}

const CircleProgress = (props: Props) => {
    let [firstPercentage, setFirstPercentage] = useState(0);
    let [secondPercentage, setSecondPercentage] = useState(isNumberValued(props.secondPercentageValue) ? 0 : null);

    const [firstPathColor, setFirstPathColor] = useState(PICKER_COLORS[6].color);

    useEffect(() => {
        setTimeout(() => {
            //delay value assignment to show the animation from 0 to value
            setFirstPercentage(props.firstPercentageValue || 0);
            setSecondPercentage(isNumberValued(props.secondPercentageValue) ? props.secondPercentageValue : null);
        }, 1000);
    }, [props.firstPercentageValue, props.secondPercentageValue]);

    useEffect(() => {
        setFirstPathColor(props.firstColor ? props.firstColor : EVOLUTION_PRIMARY_COLOR);
    }, [props.firstColor]);

    return (
        <div
            className={clsx("circle-progress", {
                [props.className]: props.className,
                'double-circle-progress': isNumberValued(secondPercentage),
            })}
            style={{ color: firstPathColor }}
        >
            <CircularProgressbarWithChildren
                value={firstPercentage}
                minValue={0}
                maxValue={100}
                strokeWidth={2}
                className={'ring first-ring ' + (props.classNameFirstRing ? props.classNameFirstRing : '')}
                styles={buildStyles({
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 1,
                    pathColor: firstPathColor,
                    rotation: (secondPercentage || 0) / 100,
                })}
            >
                {isNumberValued(secondPercentage) &&
                    <div style={{ color: props.secondColor }}>
                        <CircularProgressbarWithChildren
                            value={secondPercentage}
                            minValue={0}
                            maxValue={100}
                            className={'ring second-ring ' + (props.classNameSecondRing ? props.classNameSecondRing : '')}
                            strokeWidth={2}
                            styles={buildStyles({
                                trailColor: "transparent",
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 1,
                                pathColor: props.secondColor,

                            })}
                        >
                            {!!props.children && props.children}
                        </CircularProgressbarWithChildren>
                    </div>
                }
            </CircularProgressbarWithChildren >
        </div >
    )
}

CircleProgress.defaultProps = {
    percentage: 0
}

export default CircleProgress;
