import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { getHeaders, isProdEnv } from '../../utility/Api';
import { getLabels, getLabelsNotif, setColorPicker, setLeaderboardPresence, setNotificationChannels, showError, getLabelsLevel } from './utilsActions';
const axios = require('axios');
import { cleanCompletedCourses, getCourses } from "./coursesActions";
import { LEADERBOARD_PRESENCE, NOTIFICATION_CHANNELS, UserProfile } from '@model/User';
import { logout } from './authenticationActions';
import { API_ADMIN_STATUS_CODE, COOKIE } from '@utility/const';
import { PUBLIC_URLS } from '@components/link-utils';
import { removeCookie, setCookie } from '@utility/cookie';
import { getCart, getPlan } from '.';
import { FORTER_SN_PR, FORTER_SN_QA } from '../../config_local';
import { promiseMock } from '@utility/ecommerceUtility';
import { LeaderboardModel } from '@model/LeaderboardModel';
//import { mockGetLeaderboardCy } from './utilsActions';

// import profileMock from '../../data/user_profile_moodle.json' //MOCK

export const getUserProfile = () => {
    return (dispatch, getState) => {
        let url = urlList.GET_USER_PROFILE_URL;
        dispatch(request());

        axios.get(url, {
            headers: getHeaders()
        })
            .then((profile: { data: UserProfile }) => {
                // console.log('getUserProfile', profile.data)

                if (profile.status === 401) {
                    dispatch(failure());
                    dispatch(showError(err));
                }

                const userProfile = profile.data;

                let preferredLang = userProfile.preferredLang;
                if (!preferredLang || preferredLang == "") {
                    preferredLang = "en";
                    userProfile.preferredLang = "en";
                }
                let differentLanguageLabel = isPreferredLangChanged(preferredLang, getState);

                handleUserProfileArrival(userProfile, dispatch, getState);
                dispatch(success(userProfile));

                //get labels if language is changed
                if (differentLanguageLabel) {
                    dispatch(getLabels(preferredLang))
                    dispatch(getLabels(preferredLang, 'ecommerce'))
                    dispatch(getLabelsNotif(preferredLang))
                    dispatch(getLabels(preferredLang, 'trainingpills'))
                    dispatch(getLabelsLevel(preferredLang))
                }


            }).catch(err => {
                console.error('getUserProfile', err);
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_USER_PROFILE_REQUEST
        };
    }

    function success(userProfile) {
        //console.log("🚀 ~ success ~ userProfile:", userProfile)
        return {
            type: actionTypes.GET_USER_PROFILE_SUCCESS,
            userProfile,
            isFullUserCompleted: true,
            isBycUser: userProfile?.bycStatus === 'standard',
            isBycOffice: userProfile?.bycStatus === 'office',
            isUserCompletedAnalytics: true
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_USER_PROFILE_FAILURE
        };
    }
};

// type: timezone | language | multiple
export const updateUser = (value, type) => {
    return (dispatch, getState) => {
        let url = null;
        let userProfile = { ...getState().user.userProfile };

        if ((!value || value == "") && type !== 'preferredNotificationChannels') {
            return;
        }

        switch (type) {
            case 'timezone':
                url = urlList.UPDATE_USER_TIMEZONE_URL;
                userProfile.timezone = value;
                break;
            case 'language':
                url = urlList.UPDATE_USER_LANGUAGE_URL;
                userProfile.preferredLang = value;
                //clear session filters
                sessionStorage.clear();
                break;
            case 'multiple':
                if (typeof value === "object") {
                    url = urlList.UPDATE_USER_URL;
                    userProfile = { ...userProfile, ...value };
                }
                break;
            case 'shipping':
                if (typeof value === "object") {
                    url = urlList.UPDATE_USER_URL;
                    userProfile = { ...value };
                }
                break;
            default:
                url = urlList.UPDATE_USER_URL;
                console.log('userProfile[type]', userProfile[type])
                userProfile[type] = value;
                break;
        }

        dispatch(requestUpdateUser());

        axios({
            url,
            method: "POST",
            headers: getHeaders(),
            data: userProfile
        })
            .then(response => {
                dispatch(updateUserSuccessHandler(response, type));

            }).catch(err => {
                dispatch(updateUserFailureHandler(err));
            });
    }

};
export const requestUpdateUser = () => {
    return {
        type: actionTypes.GET_USER_PROFILE_UPDATE_REQUEST
    };
}

export const successUpdateUser = (userProfile) => {
    return {
        type: actionTypes.GET_USER_PROFILE_UPDATE_SUCCESS,
        userProfile,
        isOnlyUser: false
    };
}

export const failureUpdateUser = () => {
    return {
        type: actionTypes.GET_USER_PROFILE_UPDATE_FAILURE
    };
}

export const updateUserSuccessHandler = (response, type) => {
    return (dispatch) => {
        //1) save new userProfile
        dispatch(successUpdateUser(response.data));

        if (type === 'timezone' || type === 'language') {
            //2) clean completed courses (also courses0)
            //2) call catalog for courses in the new timezone and update course in modal if opened
            dispatch(cleanCompletedCourses());
            dispatch(getCourses());
        }


        switch (type) {
            case 'language':
                //3) call labels
                dispatch(getLabels(response.data.preferredLang))
                dispatch(getLabels(response.data.preferredLang, 'ecommerce'))
                dispatch(getLabelsNotif(response.data.preferredLang))
                dispatch(getLabels(response.data.preferredLang, 'trainingpills'))
                dispatch(getLabelsLevel(response.data.preferredLang))
                break;
        }
    }
}

export const updateUserFailureHandler = (err) => {
    return (dispatch) => {
        console.error('updateUser', err);
        dispatch(failureUpdateUser());
        dispatch(showError(err));
    }
}

export const mockGetUserProfile = async () => {
    const data = await fetch('/data/user_profile_moodle.json');
    const dataJson = await data.json();
    return promiseMock({ data: dataJson }, false, 1000);
};

export const updateLearnerNameAndDownloadCertificate = (learnerName, certificateUrl) => {

    const mock = false;
    const url = urlList.UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT;

    return (
        mock ? 
        mockGetUserProfile()
        : axios({
            url,
            method: 'POST',
            headers: getHeaders(),
            params: { learnerName: learnerName, certificateUrl: certificateUrl },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err, dispatch) => {
            dispatch(showError(err));
            return Promise.reject(err);
        })
    )
}

export const getUserProfileFast = () => {

    return (dispatch, getState) => {
        let url = urlList.GET_USER_PROFILE_FAST_URL;

        axios.get(url, {
            headers: getHeaders()
        })
            .then(result => {
                // console.log('getUserProfileFast')

                if (result.status === 401) {
                    return;
                }

                const userProfile = result.data;

                //only if the real getUserProfile has not returned yet
                if (!getState().user.isUserCompleted) {
                    let preferredLang = userProfile.preferredLang;
                    if (!preferredLang || preferredLang == "") {
                        preferredLang = "en";
                    }
                    let differentLanguageLabel = isPreferredLangChanged(preferredLang, getState);

                    handleUserProfileArrival(userProfile, dispatch, getState);
                    dispatch({
                        type: actionTypes.GET_USER_PROFILE_SUCCESS,
                        userProfile: userProfile
                    });

                    //get labels if language is changed
                    if (differentLanguageLabel) {
                        dispatch(getLabels(preferredLang))
                        dispatch(getLabels(preferredLang, 'ecommerce'))
                        dispatch(getLabelsNotif(preferredLang))
                        dispatch(getLabels(preferredLang, 'trainingpills'))
                        dispatch(getLabelsLevel(preferredLang))
                    }

                }

            }).catch(err => {
                console.error('getUserProfileFast', err);
                //dispatch(failure());
                dispatch(showError(err));
            });

    }
};

const isPreferredLangChanged = (preferredLang, getState) => {
    let differentLanguageLabel = true;
    if (getState && getState().user && getState().user.userProfile && getState().user.userProfile.preferredLang) {
        if (preferredLang === getState().user.userProfile.preferredLang) {
            differentLanguageLabel = false;
        }
    }

    return differentLanguageLabel;
}

const handleUserProfileArrival = (userProfile, dispatch, getState) => {
    //update theme color if different
    const colorPickerValue = getState().utils.colorPickerValue;
    if (userProfile.preferredColorTheme && colorPickerValue.toString() !== userProfile.preferredColorTheme) {
        dispatch(setColorPicker(parseInt(userProfile.preferredColorTheme)));
    }
    if (userProfile.preferredColorTheme === '') {
        dispatch(setColorPicker(5));
    }

    //update user leaderboard presence if different
    const leaderboardPresence = getState().utils.leaderboardPresence;
    let userLeaderboardPresence = null;
    if (userProfile.preferredLeaderBoardPresence === LEADERBOARD_PRESENCE.ENABLED) {
        userLeaderboardPresence = true;
    } else if (userProfile.preferredLeaderBoardPresence === LEADERBOARD_PRESENCE.DISABLED) {
        userLeaderboardPresence = false;
    }
    if (userLeaderboardPresence !== null && leaderboardPresence !== userLeaderboardPresence) {
        dispatch(setLeaderboardPresence(userLeaderboardPresence));
    }

    //update user notification channels if different
    const notificationChannels = getState().utils.notificationChannels;
    let userNotificationChannels = null;
    if (userProfile.preferredNotificationChannels === NOTIFICATION_CHANNELS.ENABLED) {
        userNotificationChannels = true;
    } else if (userProfile.preferredNotificationChannels === NOTIFICATION_CHANNELS.DISABLED) {
        userNotificationChannels = false;
    }
    if (userNotificationChannels !== null && notificationChannels !== userNotificationChannels) {
        dispatch(setNotificationChannels(userNotificationChannels));
    }

    //no more useful, L1 carousel is no more displayed
    // dispatch(getL1Images(userProfile.followedChannels, userProfile.followedBrands));

}

export const followL1 = (l1, isFollow = true) => {
    return (dispatch, getState) => {
        if (!l1) {
            console.error('followL1: parameters not valid', { l1 });
            return;
        }

        let url = urlList.FOLLOW_CHANNEL_URL;

        //add parameters
        url += '?channel=' + l1;

        if (isFollow) {
            url += '&follow=true';
        } else {
            url += '&follow=false';
        }

        dispatch(request());

        axios({
            url,
            method: "GET",
            headers: getHeaders()
        })
            .then(response => {
                if (!response?.data) {
                    dispatch(failure());
                    dispatch(showError(''));
                    return;
                }

                const userProfile = response.data;
                //no more useful, L1 carousel is no more displayed
                // dispatch(getL1Images(userProfile.followedChannels, userProfile.followedBrands));

                dispatch(success(userProfile));
            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_REQUEST
        };
    }

    function success(userProfile) {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_SUCCESS,
            userProfile
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_FAILURE
        };
    }
};

export const followL1List = (followsList) => {
    return (dispatch, getState) => {
        if (!followsList) {
            console.error('followMultipleL1: parameters not valid', { followsList });
            return;
        }

        let url = urlList.FOLLOW_L1_LIST_URL;

        dispatch(request());

        const mock = false;

        const mockPromise = promiseMock({ data: getState().user.userProfile });

        return (
            mock ? mockPromise :
                axios({
                    url,
                    method: "POST",
                    headers: getHeaders(),
                    data: followsList,
                }))
            .then(response => {
                if (!response?.data) {
                    dispatch(failure());
                    dispatch(showError(''));
                    return Promise.reject();
                }

                const userProfile = response.data;
                const oldUserProfile = getState().user.userProfile;
                //no more useful, L1 carousel is no more displayed
                // dispatch(getL1Images(userProfile.followedChannels, userProfile.followedBrands));

                if (userProfile?.followedChannels && userProfile?.followedBrands) {
                    dispatch(success({ ...oldUserProfile, ...userProfile }));
                }
                return Promise.resolve(userProfile);
            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
                return Promise.reject(err);
            });
    }

    function request() {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_REQUEST
        };
    }

    function success(userProfile) {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_SUCCESS,
            userProfile
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_USER_PROFILE_UPDATE_FAILURE
        };
    }
};

export function getLeaderboard() {
    let url = urlList.GET_LEADERBOARD_URL;

    return (dispatch) => {

        dispatch(request());

        axios({
            url,
            method: "GET",
            headers: getHeaders()
        })
            .then((response: { data: LeaderboardModel }) => {
                const leaderboard = response?.data?.leaderboardInfo;
                const userInfo = response?.data?.userInfo;
                if (!leaderboard) {
                    console.error('getLeaderboard returned a non correct format');
                    dispatch(failure());
                    dispatch(showError(''));
                    return;
                }

                if (userInfo) {
                    const isUserPresent = leaderboard.filter(a => a.username === userInfo.username);
                    if (!isUserPresent || isUserPresent.length < 1) {
                        leaderboard.push(userInfo);
                        leaderboard.sort((a, b) => a.currentPosition - b.currentPosition);
                    }
                }

                dispatch(success(leaderboard));
            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_LEADERBOARD_REQUEST
        };
    }

    function success(leaderboard) {
        return {
            type: actionTypes.GET_LEADERBOARD_SUCCESS,
            leaderboard
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_LEADERBOARD_FAILURE
        };
    }
}

export function getLeaderboardCy() {
    let url = urlList.GET_LEADERBOARD_CY_URL;

    return (dispatch) => {

        dispatch(request());

        /*const mock = true;
        (mock
            ? mockGetLeaderboardCy()
            : axios.get(url, { headers: getHeaders() })
        )*/

        axios({
            url,
            method: "GET",
            headers: getHeaders()
        })
            .then((response: { data: LeaderboardModel }) => {
                const leaderboardCy = response?.data?.leaderboardInfo;
                const userInfo = response?.data?.userInfo;
                if (!leaderboardCy) {
                    console.error('getLeaderboard returned a non correct format');
                    dispatch(failure());
                    dispatch(showError(''));
                    return;
                }

                if (userInfo) {
                    const isUserPresent = leaderboardCy.filter(a => a.username === userInfo.username);
                    if (!isUserPresent || isUserPresent.length < 1) {
                        leaderboardCy.push(userInfo);
                        leaderboardCy.sort((a, b) => a.currentPosition - b.currentPosition);
                    }
                }

                dispatch(success(leaderboardCy));
            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.GET_LEADERBOARDCY_REQUEST
        };
    }

    function success(leaderboardCy) {
        return {
            type: actionTypes.GET_LEADERBOARDCY_SUCCESS,
            leaderboardCy
        };
    }

    function failure() {
        return {
            type: actionTypes.GET_LEADERBOARDCY_FAILURE
        };
    }
}


export function resetProfileUpdate() {
    return {
        type: actionTypes.RESET_USER_PROFILE_UPDATE
    }
}

export const deleteUser = (history) => {
    let url = urlList.DELETE_USER_URL;

    return (dispatch) => {
        dispatch(request());

        axios({
            url,
            method: "GET",
            headers: getHeaders()
        })
            .then(response => {
                if (response.data?.statusCode === API_ADMIN_STATUS_CODE.OK) {
                    dispatch(success());
                    //go to delete user success
                    if (history) {
                        history.push(PUBLIC_URLS.DELETE_USER_SUCCESS.URL);
                    }
                    //logout the user
                    dispatch(logout());
                } else {
                    dispatch(failure());
                    dispatch(showError('error deleting user'));
                }

            }).catch(err => {
                dispatch(failure());
                dispatch(showError(err));
            });
    }

    function request() {
        return {
            type: actionTypes.DELETE_USER_REQUEST
        };
    }

    function success() {
        return {
            type: actionTypes.DELETE_USER_SUCCESS,
        };
    }

    function failure() {
        return {
            type: actionTypes.DELETE_USER_FAILURE
        };
    }
}

export const setNoShowModalCart = (value, updateCookie = false) => {
    return (dispatch, getState) => {
        const noShowModalCart = getState().user.noShowModalCart;

        //if the value is changed
        if ((value && !noShowModalCart) || (!value && noShowModalCart)) {
            if (updateCookie) {
                if (value) {
                    setCookie(COOKIE.NO_SHOW_MODAL_CART, 'true');
                } else {
                    removeCookie(COOKIE.NO_SHOW_MODAL_CART);
                }
            }

            dispatch({
                type: actionTypes.SET_NO_SHOW_MODAL_CART,
                value,
            });
        }
    }
}

//NO MORE USED
export const setNoShowModalExpiration = (value, updateCookie = false) => {
    return (dispatch, getState) => {
        const noShowModalExpiration = getState().user.noShowModalExpiration;

        //if the value is changed
        if ((value && !noShowModalExpiration) || (!value && noShowModalExpiration)) {
            if (updateCookie) {
                if (value) {
                    setCookie(COOKIE.NO_SHOW_MODAL_EXPIRATION, 'true');
                } else {
                    removeCookie(COOKIE.NO_SHOW_MODAL_EXPIRATION);
                }
            }

            dispatch({
                type: actionTypes.SET_NO_SHOW_MODAL_EXPIRATION,
                value,
            });
        }
    }
}

export const setEcommerceEnable = (value) => {
    return (dispatch, getState) => {
        const ecommerceEnable = getState().user.ecommerceEnable;

        //if the user is enabled to ecommerce --> make ecommerce requests
        const isAuthenticated = getState().authentication.isAuthenticated;
        if (value && isAuthenticated) {
            const cart = getState().ecommerce.cart;
            const isPlanCompleted = getState().ecommerce.isPlanCompleted;
            const isLoadingPlan = getState().ecommerce.isLoadingPlan;

            //get user cart
            if (!cart) {
                dispatch(getCart(true));
            }

            //get plan info
            if (!isPlanCompleted && !isLoadingPlan) {
                dispatch(getPlan(true));
            }

            //add forter import if not already done
            const forterImport = document.getElementById('forter_import');
            if (!forterImport) {
                var script = document.createElement("script");  // create a script DOM node
                //https://6db77fc22b76.cdn4.forter.com/sn/6db77fc22b76/script.js
                script.src = "https://cdn4.forter.com/script.js?sn=" + (isProdEnv() ? FORTER_SN_PR : FORTER_SN_QA);  // set its src to the provided URL
                script.defer = true;
                script.id = 'forter_import';

                document.head.appendChild(script);
            }
        }

        //if the value is changed
        if ((value && !ecommerceEnable) || (!value && ecommerceEnable)) {
            dispatch({
                type: actionTypes.SET_ECOMMERCE_ENABLE,
                value,
            });
        }
    }
}

export const setLearnerName = (learnerName: string) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_LEARNER_NAME,
            learnerName
          })
    }
}