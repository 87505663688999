import { pageIdToUrl } from '@components/link-utils';
import { CMSCarouselLayout } from '@hooks/useHomepageCarousels';

export type HeroBannerInfoItems = Item[];
// export type PageType = {
//   externalReference: keyof typeof pageIdToUrl;
// } & {
//   [K in ParamsPage]?: Array<{
//     externalReference: string;
//   }>;
// };
export interface HeroBannerInfoResponse {
  data: HeroBannerInfo[];
}

export type Teasers = {
  [key: `teaser${number}`]: {
    titolo?: string;
    tag?: string;
  };
};

export interface CourseIdL1 {
  targetUrl: any[];
  callToActionEnabled: boolean;
  teaserTitle: string;
  teaserText: string;
  detailText: string;
  pictures: any[];
  related: any[];
  contentType: string;
  locale: string;
  creationDate: Date;
  language: string;
  title: string;
  segment: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: RowTeasers[];
  locationTaxonomy: any[];
  lastModified: Date;
  url: string;
  path: string;
  folderId: number;
  extendedFields: {};
  contentId: number;
  restType: string;
}

export interface RowTeasersExtendedFields {
  ElementNumber: RowTeasers[];
  image?: CMPictureImage[];
  layout?: {
    layout: string;
    restType: string;
  }[];
  CourseIdL1: CourseIdL1[];
  Tipology: string;
  icon1: [];
  content1: Content1;
  content2: Content1;
}

export interface RowTeasers {
  link?: string;
  linkLabel?: string;
  targetUrl?: any[];
  callToActionEnabled: boolean;
  teaserTitle: string;
  teaserText: string;
  detailText: string;
  pictures: any[];
  related: any[];
  contentType: string;
  locale: string;
  creationDate: Date;
  language: string;
  title: string;
  segment: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: any[];
  locationTaxonomy: any[];
  lastModified: Date;
  url?: string;
  path: string;
  folderId: number;
  extendedFields?: RowTeasersExtendedFields;
  contentId: number;
  restType: string;
  value?: string;
  'lookup-value'?: string;
  'lookup-externalReference'?: string;
  taxonomyVector?: string;
  externalReference?: string;
  callToActionCustomText?: string;
  richtext1?: string,
  richtext2?: string,
  richtext3?: string,
}

export enum LayoutAlignment {
  'center' = 'Centered alignment',
  'left' = 'Left alignment',
  'right' = 'Right alignment',
}
export interface HeroBannerInfo<T = string> {
  header?: any[];
  footer?: any[];
  favicon?: string;
  placements?: {
    row1: HeroBannerInfo[];
    row2: RowTeasers[];
    row3: RowTeasers[];
    row4: RowTeasers[];
    row5: RowTeasers[];
    row6: RowTeasers[];
    row7: RowTeasers[];
    row8: RowTeasers[];
    row9: RowTeasers[];
    row10: RowTeasers[];
    row12: RowTeasers[];
    row13: RowTeasers[]; // master
    row22: RowTeasers[]; // Harvard
    row23: RowTeasers[]; // Harvard
  };
  javaScript?: any[];
  css?: any[];
  children?: any[];
  teaserTitle: string;
  teaserText?: string;
  detailText?: string;
  pictures?: any[];
  related: any[];
  callToActionCustomText?: string;
  callToActionEnabled: boolean;
  contentType: ContentType;
  locale: string;
  creationDate: Date;
  contentId: number;
  title: string;
  segment: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  subjectTaxonomy: HeroBannerInfo[];
  locationTaxonomy: any[];
  lastModified: Date;
  url?: string;
  path: string;
  folderId: number;
  extendedFields?: any;
  language: string;
  restType: RestType;
  layoutVariant?: any[];
  items?: Item[];
  extDisplayedDate?: Date;
  externalReference?: string;
  'lookup-externalReference'?: string;
  taxonomyVector?: string;
  'lookup-value'?: string;
  value?: T;
  mimeType?: string;
  copyright?: string;
  caption?: string;
  alt?: string;
  disableCropping?: boolean;
  data?: string;
}

export enum ParamsPage {
  category = 'Category',
  idBrand = 'IdBrand',
  searchQuery = 'SearchQuery',
  months = 'Months',
  collections = 'Collections',
  characterics = 'Characterics',
  characteristics = 'Characteristics',
  brands = 'Brand',
  categories = 'Categories',
  sortings = 'Sortings',
  Chip = 'Chip',
  L1 = 'L1',
  Tipology = 'Tipology',
  language = 'Language',
  Status = 'Status',
  Duration = 'Duration',
  teacher = 'Teacher',
  location = 'Location',
  schedule = 'Schedule',
  price = 'Price',
  offerPlan = 'OfferPlan',
  expiration = 'Expiration',
  others = 'Others',
  Ordinamento = 'Ordinamento',
}

export type Content1ExtendedFields = HeroBannerInfoParams & {
  Teacher: string;
  CourseID: string;
  LinkURL?: string;
  Brand?: RowTeasers[];
  PageType?: HeroBannerInfo<keyof typeof pageIdToUrl>[];
  TargetWindow?: string;
};

type HeroBannerInfoParams = Record<ParamsPage, HeroBannerInfo[]>;

export interface Content1 {
  targetUrl: any[];
  teaserTitle: string;
  teaserText: string;
  detailText: string;
  pictures: any[];
  related: any[];
  callToActionEnabled: boolean;
  contentType: string;
  locale: string;
  creationDate: Date;
  contentId: number;
  title: string;
  segment: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: any[];
  locationTaxonomy: any[];
  lastModified: Date;
  url: string;
  path: string;
  folderId: number;
  extendedFields: Content1ExtendedFields;
  language: string;
  restType: string;
  LinkUrl?: string;
  data?: string;
}

export interface ItemExtendedFields {
  content1: Content1[];
  content2?: Content1[];
  boolean2: boolean;
  boolean3: boolean;
  boolean1: boolean;
  icon1: HeroBannerInfo[];
  boolean4: boolean;
  icon2: HeroBannerInfo[];
  boolean5: boolean;
  layoutVariant?: {
    layout: LayoutAlignment | CMSCarouselLayout;
    restType: string;
  }[];
  content3: HeroBannerInfo[];
}

export interface Item {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  textarea1: string;
  textarea2: string;
  textarea3: string;
  textarea4: string;
  textarea5: string;
  richtext1: string;
  richtext2: string;
  richtext3: string;
  richtext4: string;
  richtext5: string;
  targetUrl: any[];
  teaserTitle: string;
  teaserText: string;
  detailText: string;
  pictures: any[];
  related: any[];
  callToActionEnabled: boolean;
  contentType: string;
  locale: string;
  creationDate: Date;
  contentId: number;
  title: string;
  segment: string;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  extDisplayedDate: Date;
  subjectTaxonomy: any[];
  locationTaxonomy: any[];
  lastModified: Date;
  url: string;
  path: string;
  folderId: number;
  extendedFields: ItemExtendedFields;
  language: string;
  restType: string;
}

interface CMPictureImage {
  mimeType: string;
  width: number;
  height: number;
  copyright: string;
  caption: string;
  alt: string;
  disableCropping: boolean;
  data: string;
  cropImages: any;
  teaserTitle: string;
  related: any[];
  callToActionEnabled: boolean;
  contentType: string;
  creationDate: string;
  locale: string;
  title: string;
  segment: string;
  contentId: number;
  keywords: string;
  htmlDescription: string;
  htmlTitle: string;
  extDisplayedDate: string;
  subjectTaxonomy: [];
  locationTaxonomy: any[];
  lastModified: string;
  url: string;
  path: string;
  folderId: number;
  extendedFields: any;
  language: string;
  restType: string;
}

export enum ContentType {
  CMChannel = 'CMChannel',
  CMCollection = 'CMCollection',
  CMPicture = 'CMPicture',
  CMTaxonomy = 'CMTaxonomy',
}

export enum RestType {
  RestCMChannel = 'RestCMChannel',
  RestCMCollection = 'RestCMCollection',
  RestCMPicture = 'RestCMPicture',
  RestCMTaxonomy = 'RestCMTaxonomy',
}
