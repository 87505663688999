import { useEffect, useState } from "react";
import { useSelector } from "./redux";
import { isCartItemExpired, selectOrderItemsCourses } from "@utility/ecommerceUtility";
import { getMasterAvailableSeats } from "@utility/onBoardingCourseUtility";
import { ProductCart } from "@model/CartModel";
import { Course } from "@model/CoursesClass";
import { getPriceWithAdjustement } from "@utility/Api";
import { createSelector } from "@reduxjs/toolkit";

class useCartItemsClass extends Course {
  id: string = "";
  name: string = "";
  oldPrice: string = "";
  finalPrice: string = "";
  orderItemPrice: string = "";
  expirationDate: string = "";
  fallbackImage: string = "";
  image: string = "";
  isExpired: boolean = false;
  quantity: string = "";
  currency: string = "USD";
  productCart: ProductCart = new ProductCart();
  endDate: string = "";
  maxCounterPurchase: number = undefined;
  isPlan?: boolean;
}

const useCartItems = (orderItems): useCartItemsClass[] => {
  const selector = createSelector(
    state => state,
    (state, orderItems) => orderItems,
    (state, orderItems) => selectOrderItemsCourses(state, orderItems)
  );
  const cartItemsCourses = useSelector(state => selector(state, orderItems));
  const orderedItems = useSelector(state => state.ecommerce.cart?.orderItem || []);
  const isGetCourseAndPlanCompleted = useSelector(state => state.course.isGetCourseCompleted && (!state.ecommerce.isLoadingPlan));

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    let cartItemsTemp = [];

    if (isGetCourseAndPlanCompleted) {

      cartItemsTemp = orderedItems.map((order) => {
        const course = cartItemsCourses[order.partNumber];

        const oldPrice = order.adjustment?.length > 0 ? order.orderItemPrice : '';
        const finalPrice = getPriceWithAdjustement(order);

        if (course) {
          return {
            id: order.partNumber,
            name: course.courseFullName,
            oldPrice,
            finalPrice,
            orderItemPrice: order.orderItemPrice,
            expirationDate: course.endDate,
            fallbackImage: course.fallbackImage,
            image: course.courseOverviewFile,
            isExpired: !course?.isPlan ? isCartItemExpired(course) : false,
            quantity: order.quantity,
            currency: order.currency,
            productCart: order,
            endDate: !course?.isPlan ? course.endDate : '',
            maxCounterPurchase: getMasterAvailableSeats(course),
            ...course,
          }
        } else {
          return {
            id: order.partNumber,
            name: order.partNumber,
            oldPrice,
            finalPrice,
            orderItemPrice: order.orderItemPrice,
            fallbackImage: "",
            image: "",
            expirationDate: "",
            isExpired: true,
            quantity: order.quantity,
            currency: order.currency,
            productCart: order,
            isPlan: false,
            ...course,
          }
        }
      }).filter(item => !!item);
    }

    setCartItems(cartItemsTemp);
  }, [JSON.stringify(cartItemsCourses), JSON.stringify(orderedItems), isGetCourseAndPlanCompleted])

  return cartItems;
};
export default useCartItems;
