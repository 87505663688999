import { CartItemFormatted, OrderSubscriptionsInfo } from "./CartModel";
import { Course } from "./CoursesClass";
import { SubscriptionInfo } from "./PlanModel";
import { WalletItem } from "./WalletModel";

export type OrderItemsHistory = {
  number: string;
  token: string;
  date: Date;
  subtotal: number;
  taxes: number;
  total: number;
  promo: number;
  items: CartItemFormatted[];
};

export type OrderItem = {
  id: number;
  name: string;
  expirationDate: Date | string;
  quantity: number | string;
  oldPrice: number | string;
  price: number | string;
  image: string;
};

export type OrderHistoryDetailsReducer = {
  [key: string]: OrderDetails;
};

export type OrderHistoryDetails = {
  id: number;
  name: string;
  expirationDate: Date;
  licenseQuantity: number;
  tokens: {
    id: number;
    imageUrl: string;
    fallBackImageUrl: string;
    name: string;
    activated: boolean;
  }[];
};

export interface UsableShippingChargePolicy {
  name: string;
  type: string;
  uniqueID: string;
}

export interface Adjustment {
  amount: string;
  code: string;
  displayLevel: string;
  usage: string;
  currency: string;
  xadju_calUsageId: string;
}

export interface TotalAdjustment {
  currency: string;
  value: string;
}

export interface OrderItemT {
  personTitle: string;
  country: string;
  zipCode: string;
  unitUom: string;
  shippingChargeCurrency: string;
  lastUpdateDate: Date;
  postalCode: string;
  phone2: string;
  language: string;
  phone2Publish: string;
  phone1Publish: string;
  salesTax: string;
  addressId: string;
  phone1: string;
  correlationGroup: string;
  email2: string;
  usableShippingChargePolicy: UsableShippingChargePolicy[];
  email1: string;
  fax2: string;
  state: string;
  fax1: string;
  shippingCharge: string;
  orderItemPrice: string;
  shipModeLanguage: string;
  unitPrice: string;
  shipModeCode: string;
  productId: string;
  shipModeDescription: string;
  nickName: string;
  fulfillmentCenterName: string;
  firstName: string;
  adjustment: Adjustment[];
  totalAdjustment: TotalAdjustment;
  orderItemInventoryStatus: string;
  lastName: string;
  city: string;
  expectedShipDate: Date;
  description: string;
  xitem_isPersonalAddressesAllowedForShipping: string;
  shippingTax: string;
  orderItemStatus: string;
  offerID: string;
  currency: string;
  createDate: Date;
  salesTaxCurrency: string;
  quantity: string;
  orderItemId: string;
  fulfillmentCenterId: string;
  shipModeId: string;
  isExpedited: string;
  addressLine: string[];
  orderItemFulfillmentStatus: string;
  shippingTaxCurrency: string;
  stateOrProvinceName: string;
  carrier: string;
  freeGift: string;
  unitQuantity: string;
  contractId: string;
  middleName: string;
  partNumber: string;
  productUrl: string;
  UOM: string;
}

export interface Adjustment2 {
  amount: string;
  code: string;
  displayLevel: string;
  usage: string;
  currency: string;
  xadju_calUsageId: string;
}

export interface ProtocolData {
  name: string;
  value: string;
}

export interface PaymentInstruction {
  piId: string;
  personTitle: string;
  lastName: string;
  country: string;
  zipCode: string;
  city: string;
  postalCode: string;
  phone2: string;
  phone2Publish: string;
  phone1Publish: string;
  piDescription: string;
  payMethodId: string;
  phone1: string;
  xpaym_tokenization: string;
  email2: string;
  email1: string;
  fax2: string;
  xpaym_policyId: string;
  state: string;
  fax1: string;
  piAmount: string;
  billing_address_id: string;
  nickName: string;
  addressLine: string[];
  piLanguage: string;
  stateOrProvinceName: string;
  piStatus: string;
  firstName: string;
  protocolData: ProtocolData[];
  piCurrency: string;
  middleName: string;
  xpaym_punchoutPayment: string;
}

export interface OrderDetailsWithCreditCard extends OrderDetails {
  walletDto: WalletItem;
  identifier: number;
  paymentToken: string;
  creditCardBin: string;
  creditCardPan: string;
  issuer: string;
  owner: string;
  payMethodId: string;
  expireDate: string;
  creation: string;
  isDefault: boolean;
}

export interface OrderDetails {
  orderId: string;
  grandTotal: string;
  orderItem: OrderItemT[];
  resourceId: string;
  totalProductPriceCurrency: string;
  totalAdjustment: string;
  orderStatus: string;
  totalShippingChargeCurrency: string;
  resourceName: string;
  adjustment: Adjustment2[];
  recordSetComplete: string;
  paymentInstruction: PaymentInstruction[];
  products: [{
    id: string;
    token: string;
    activated: boolean;
  }];
  totalShippingTax: string;
  prepareIndicator: string;
  x_isPurchaseOrderNumberRequired: string;
  totalShippingTaxCurrency: string;
  grandTotalCurrency: string;
  totalSalesTaxCurrency: string;
  storeUniqueID: string;
  placedDate: Date;
  x_trackingIds: string;
  recordSetStartNumber: string;
  shipAsComplete: string;
  recordSetCount: string;
  orderTypeCode: string;
  totalProductPrice: string;
  buyerId: string;
  totalAdjustmentCurrency: string;
  totalShippingCharge: string;
  locked: string;
  storeNameIdentifier: string;
  lastUpdateDate: Date;
  totalSalesTax: string;
  x_isPersonalAddressesAllowedForShipping: string;
  recordSetTotal: string;
  tokenList?: OrderToken[];
  subscriptionInfo?: OrderSubscriptionsInfo;
}

export type OrderToken = {
  token: string,
  license: string,
  duration: number,
  object: string,
  userbuyer: string,
  userconsumer: string,
  statustoken: "idle" | "active" | "disable" | "pending",
  orderid: string,
  timecreated: string,
  timestarted: string,
  timeended: string,
  timeinvalidated: string,
  sendedTime: number,
  sendedTo: string,
  status: string,
  sendFailure?:boolean,
}

export type OrderTokenResponse = {
  sendedTo: string,
  success: boolean
}

export type OrdersTokens = {
  tokenList: OrderToken[];
  availableTokens: number,
  activatedTokens: number,
  expiredTokens: number,
  pendingTokens: number
}

export class BillingAddress {
  name: string = "";
  surname: string = "";
  address: string = "";
  zipCode: string = "";
  city: string = "";
  state: string = "";
}

export class PromoList {
  orderId: string;
  promotionCode: PromotionCode[];
}

export class PromotionCode {
  code: string;
  associatedPromotion: { promotionId: string }[];
}

export class ActivateTokenResponse {
  active: boolean; //esito attivazione token
  type: 'course' | 'lesson' | 'plan';
  object: string;  //courseId or id plan
  orderId: string;
  errorCode?: ACTIVATE_TOKEN_ERROR_CODE;
  errorMessage?: string;
}

export enum ACTIVATE_TOKEN_ERROR_CODE {
  ERR1 = "01",
  ERR2 = "02",
  ERR3 = "03",
  ERR4 = "04",
  ERR500 = "500",
}
// errorcode=01 : Token non trovato
// errorcode=02 : Token non attivabile (già attivo o cancellato)
// errorcode=03 : User non trovato
// errorcode=04 : L'object (course/lesson/plan) risulta già attivo per l'utente
// errorcode=500: Errore generico in caso di eccezione (ex: scrittura a db fallita)

export class License {
  email: string;
  token: string;
  id: number;
}