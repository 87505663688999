import React from 'react';
import FocusTrap from 'focus-trap-react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import useBlockScroll from '@utility/useBlockScroll';
import Search from '@components/Search/Search';
import { isSearchPage, USER_URLS } from '@components/link-utils';
import { useSelector } from '@hooks/redux';
import LazySVG from '@components/LazySvg';

const variants = {
  initial: {
    x: '100%',
    opacity: 0,
  },
  enter: {
    x: '0%',
    opacity: 1,
  },
  exit: {
    x: '0%',
    opacity: 0,
  },
};

type ModalSearchMobileProps = {
  show: boolean;
  closeModal?: () => void;
};

export const ModalSearchMobile = (props: ModalSearchMobileProps): React.ReactElement => {
  useBlockScroll(props.show);
  const history = useHistory();
  const location = useLocation();
  const lang = useSelector(state => state.utils.lang);

  const onAnimationComplete = () => {
    props.closeModal();
    history[isSearchPage(location.pathname) ? 'replace' : 'push']({
      pathname: USER_URLS.SUBCATALOGUE_SEARCH.URL,
      state: { searchQuery: '' },
    });
  };

  return (
    props.show && (
      <FocusTrap
        active={props.show}
        focusTrapOptions={{
          fallbackFocus: 'body',
          allowOutsideClick: true,
        }}
      >
        <div>
          <motion.div
            role="dialog"
            aria-modal="true"
            className={'modal-right modal-search-mobile'}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            onAnimationComplete={onAnimationComplete}
          >
            <button
              className={'button-no-back modal-right__close'}
              aria-label={lang?.CLOSE_MODAL}
              onClick={props.closeModal}
            >
              <LazySVG src="/images/svg-icons/x-icon2.svg" alt="" />
            </button>
            <Search />
          </motion.div>
        </div>
      </FocusTrap>
    )
  );
};

export default ModalSearchMobile;
