import useCombinedRefs from '@hooks/useCombinedRefs';
import useToggle from '@hooks/useToggle';
import { handleOnEnterKeyPress, printHTML } from '@utility/Api';
import React, {useEffect, useState} from 'react';
import Check from '@images/svg-icons/checkmark-icon.svg';

type CheckboxProps = {
  text: string;
  isHtmlText?: boolean;
  className?: string;
  disabled?: boolean;
  defaultChecked?: boolean
  onChange?: (checked: boolean) => void;
  isTextOnLeft?: boolean;
  checked?: boolean
  name?: string;
  isError?: boolean;
  useSvg?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLLabelElement>, 'onChange'>;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      text,
      isHtmlText,
      className,
      isTextOnLeft = false,
      defaultChecked,
      onChange,
      disabled = false,
      //checked = undefined,
      isError,
      useSvg,
      ...rest
    },
    forwardedRef
  ): React.ReactElement => {
    // @todo https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/

    const [checked, setChecked] = useToggle(defaultChecked || false);
    //const [bFirstRender, setFirstRender] = useState<boolean>(true)

    const innerRef = React.useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    const setCheckedInput = checked => {
      // debugger
      //if(bFirstRender) return
      if (innerRef.current.checked !== checked && !disabled) {
        // just emulate an actual click on the input element
        innerRef.current.click();
      }
    };

    const handleClick = () => {
      // debugger
      //setFirstRender(false)
      if (!disabled) {
        setChecked();
      }
    };

    React.useEffect(() => {
      //debugger
      //if(bFirstRender) return
      //setFirstRender(false)
      setCheckedInput(checked);
      if (onChange) {
        onChange(checked);
      }
    }, [checked]);

    // useEffect(function setCheckExternally() {
    // _setChecked(checked)
    // }, [checked]);

 /*   useEffect(function () {
      if(checked == undefined) return
      _setChecked(checked)
    }, [checked]);
*/
    return (
      <label
        className={`check-box__container ${disabled ? `check-box--disabled` : ``} ${
          isTextOnLeft ? `check-box--text-left` : ``
        } ${className ? className : ''} ${isError ? 'check-box--error' : ''}`}
        onClick={handleClick}
        tabIndex={0}
        onKeyDown={event => {
          handleOnEnterKeyPress(event, handleClick);
        }}
        {...rest}
      >
        <input
          style={{ display: 'none' }}
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={e => {
            setChecked(e.target.checked);
          }}
          disabled={disabled}
          ref={combinedRef}
          name={name}
        />
        <span
          className={`check-box__checkmark ${checked ? `check-box__checkmark--active` : ``} ${
            disabled ? `check-box__checkmark--disabled` : ``
          }`}
        >
          {useSvg && <Check/>}
        </span>
        <span
          className="check-box__text"
          dangerouslySetInnerHTML={isHtmlText ? printHTML(text, { ADD_ATTR: ['target'] }) : null}
        >
          {isHtmlText ? null : text}
        </span>
      </label>
    );
  }
);

export default Checkbox;
