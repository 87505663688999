import React, { ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FavEmptyIcon from '@images/svg-icons/fav-empty.svg';
import SwipeIcon from '@images/svg-icons/swipe-icon.svg';
import NotYetAvailableIcon from '@images/svg-icons/not-yet-available.svg';
import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import AnimationExpand from '../animations/AnimationExpand';
import { CardDisplayProps, defaultCardDisplayProps, getCtypeIcon } from './CardDisplayUtils';
import { GRADIENT_VARIANT_ENUM } from '../../model/GradientClass';
import { handleOnEnterKeyPress, isAnEvent, truncateLabel } from '../../utility/Api';
import Avatar from '../UI/Avatar';
import useLangAuth from '@hooks/useLangAuth';
import { RootState } from 'src/store/store';
import WishlistIcon from '@components/WishlistIcon';

const CardSmall = (props: CardDisplayProps & PropsFromRedux): ReactElement => {
  const {
    gradientStyle,
    courseIdMaster,
    courseFullName,
    ctypeName,
    duration,
    showBar,
    iconName,
    valueBar,
    teacherImage,
    teacherName,
    isLoadingWishlistLocal,
  } = props;

  const [icon, setIcon] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const langKeys = ['SHOW_OVERVIEW_ITEM'] as const;

  const labels = useLangAuth(langKeys);

  useEffect(() => {
    const iconTemp = getCtypeIcon(iconName);
    setIcon(iconTemp);
  }, [iconName]);

  const defaultDragElastic = 0.3;
  const [dragElastic, setDragElastic] = useState(defaultDragElastic);
  const [dragConstraintLeft, setDragConstraintLeft] = useState(0);
  const [dragConstraintRight, setDragConstraintRight] = useState(0);

  const xCard = useMotionValue(0);
  const xCardRange = [-5, 0, 5];
  const opacityOverlay = useTransform(xCard, xCardRange, [1, 0, 1]);
  const zIndexOverlay = useTransform(xCard, xCardRange, [40, 0, 40]);

  const slideUpControls = useAnimation();

  const ribbonFavouritesX = 38;

  const checkPosition = info => {
    //if the card is dragged more than the (favourites ribbon height / 2)
    if (info.offset.x >= ribbonFavouritesX * (0.5 / defaultDragElastic)) {
      //if the animation of wishlist is already started --> stop
      //this avoid multiple wishlist request
      if (dragElastic === 0) {
        return;
      }

      //disable drag
      setDragElastic(0);

      //move card on top of favourites ribbon
      setDragConstraintLeft(ribbonFavouritesX);
      setDragConstraintRight(ribbonFavouritesX);
      slideUpControls.start({
        x: ribbonFavouritesX,
        transition: { ease: 'easeInOut', duration: 0.5 },
      });

      //toggle wishlist
      if (!props.isLoadingWishlistLocal && !props.isNotYetAvailable) {
        props.toggleWishlist();
      }

      //wait some seconds showing the favourites ribbon
      setTimeout(() => {
        //move card on its initial position
        setDragConstraintLeft(0);
        setDragConstraintRight(0);
        slideUpControls.start({
          x: 0,
          transition: { type: 'spring', stiffness: 500, damping: 60 },
        });

        //when the card returns on its initial position
        setTimeout(() => {
          //enable drag
          setDragElastic(defaultDragElastic);
        }, 600);
      }, 1000);
    }
  };

  const handleClickCard = () => {
    if (props.isNotYetAvailable) {
      setShowOverlay(true);

      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    } else {
      props.openCourseModal();
    }
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const enableWishlist = () => {
    return !props.isStatic && !props.isNotYetAvailable && !props.isActivity;
  };

  const cardContent = (
    <>
      <motion.div
        className="card-v3__overlay mobile"
        style={{ opacity: opacityOverlay, zIndex: zIndexOverlay }}
      >
        <div className="card-v3__swipe card-v3-small__swipe">
          <SwipeIcon />
        </div>
      </motion.div>

      {props.isNotYetAvailable && (
        <div
          className={`card-v3__overlay overlay-not-available darker overlay-not-yet-available ${
            showOverlay ? 'show' : ''
          } `}
        >
          <div className="not-available">
            <NotYetAvailableIcon />
            <span className="title">{props.lang.NOT_YET_AVAILABLE}</span>
          </div>
        </div>
      )}

      <div className="card-v3__cover">
        <div className="card-v3__cover--row">
          {teacherImage && (
            <Avatar
              image={teacherImage}
              fullName={teacherName}
              variant="small"
              className="card-v3-small__avatar"
            />
          )}
          <div className="card-v3__content">
            <div
              className={
                'card-v3__icon ' + (props.isActivity && !props.isNotYetAvailable ? '' : 'mobile')
              }
            >
              {icon}
            </div>

            <WishlistIcon
              className="desktop"
              isWishlist={props.isWishlist}
              isLoadingWishlistLocal={props.isLoadingWishlistLocal}
              isNotYetAvailable={props.isNotYetAvailable}
              enableWishlist={enableWishlist()}
              toggleWishlist={props.toggleWishlist}
              isStatic={props.isStatic}
            />

            {teacherName && (
              <div className={'card-v3__teacher-name'}>
                <span>{truncateLabel(teacherName, 23)}</span>
              </div>
            )}

            <div className={'card-v3__title' + (teacherName ? '' : ' padding-right')}>
              <span>{truncateLabel(courseFullName, 56)}</span>
            </div>

            <div className="width-100">
              <div className="card-v3__bottom-info-text">
                {!isAnEvent(props?.course) && <span>{truncateLabel(ctypeName, 23)}</span>}
                <span>{duration}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card-v3__status">
          {showBar && (
            <div className="card-v3__bar">
              <AnimationExpand
                key={valueBar}
                initialWidth="0"
                finalWidth={valueBar + '%'}
                animationEase="easeOut"
              ></AnimationExpand>
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={'card-v3 card-v3-small ' + (props.className ? props.className : '')}
      data-id={courseIdMaster}
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      aria-label={labels?.SHOW_OVERVIEW_ITEM?.replace('{name}', courseFullName)}
      tabIndex={props.tabIndex}
    >
      {/* wrapper with drag for mobile */}
      {enableWishlist() && (
        <motion.div
          drag="x"
          className="card-v3__wrapper mobile"
          style={{
            x: xCard,
            zIndex: zIndexOverlay,
            backgroundImage: gradientStyle[props.gradients[0]].backgroundImage,
          }}
          dragConstraints={{ left: dragConstraintLeft, right: dragConstraintRight }}
          dragElastic={dragElastic}
          onDrag={(_, info) => {
            checkPosition(info);
          }}
          animate={slideUpControls}
          dragMomentum={false}
        >
          {cardContent}
        </motion.div>
      )}

      {/* wrapper without drag for desktop */}
      <div
        className={'card-v3__wrapper ' + (enableWishlist() ? 'desktop' : '')}
        style={{ backgroundImage: gradientStyle[props.gradients[0]].backgroundImage }}
      >
        {cardContent}
      </div>

      {/* Add to favourites ribbon, just for mobile */}
      {enableWishlist() && (
        <div className={''}>
          <FavEmptyIcon />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gradientStyle: state.utils.gradientStyle,
    lang: state.utils.lang,
    isLoadingWishlist: state.course.isLoadingWishlist,
  };
};

CardSmall.defaultProps = {
  ...defaultCardDisplayProps,
  gradients: [GRADIENT_VARIANT_ENUM.defaultLinear],
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardSmall);
