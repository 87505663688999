import { USER_URLS } from '@components/link-utils';
import { useSelector } from '@hooks/redux';
import { UserProfile } from '@model/User';
import { isUserEnabledForCollections } from '@utility/CollectionsUtility';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useIsEnabledForCollections = (redirectIfNotEnabled = false): boolean => {
  const history = useHistory();

  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const isUserEnabled = useSelector(state => isUserEnabledForCollections(state.user.userProfile));

  useEffect(() => {
    if (redirectIfNotEnabled && userProfile && !isUserEnabled) {
      history.replace(USER_URLS.HOMEPAGE.URL);
    }
  }, [userProfile]);

  return isUserEnabled;
};
