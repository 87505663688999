import {TUseBreakpointBooleanReturn} from "@hooks/createBreakpoint";
import {CLASS_HOME_SCREEN} from "@components/AddToHomescreenPrompt";
import {TouchEvent, TouchEventHandler, useEffect, useRef, useState} from "react";
import useBlockScroll from "@utility/useBlockScroll";

export const scrollToElement = (
  query: string,
  queryScroller: string,
  behavior: ScrollBehavior,
  block: ScrollLogicalPosition,
  inline: ScrollLogicalPosition,
  center?: boolean
) => {
  let element = document.querySelector(query) as HTMLElement;
  if (element) {
    if (center) {
      let scrollElement = document.querySelector(queryScroller) as HTMLElement;

      if (scrollElement) {
        let clientWidth = scrollElement.clientWidth;

        scrollElement.scrollTo({
          left: element?.offsetLeft + (element?.offsetWidth - clientWidth) / 2,
          behavior: 'smooth',
        });
      }
    } else {
      element.scrollIntoView({ behavior: behavior, block: block, inline: inline });
    }
  }
};



/**
 * Helper function to check if banner AddHomeToScreen is shown or not.
 * If it does, return its height, if not returns 0
 *
 * @return {{state: boolean, height: number}} State of shown or not, and its height
 * */
export const hasBannerAddToHomeScreen  = ()=>{
  const has = document.querySelector(`.${CLASS_HOME_SCREEN.HOMESCREEN_PROMPT_HEADER}`) != null
  const heightHomescreenPromptBanner= parseInt(getComputedStyle(document.documentElement).getPropertyValue('--homescreen-prompt-banner-height'))
  const height = has? heightHomescreenPromptBanner: 0
  return {"state": has, "height": height};
}


/**
* Helper to scroll page to the target element is into view, considering the
* header (desktop or mobile/tablet version) and banner of AddHomeToScreenn
* */
export const scrollToElementAwareOfHeaderAndAddToHomescreen = (
    element: string | HTMLElement,
    breakpoint: TUseBreakpointBooleanReturn,
    scrollOpt?: object,
    onFinish?: ()=>void )=>{



  let el : HTMLElement

  if(typeof element == "string") el = document.querySelector(element) as HTMLElement
  else{ el = element}
  if(el == null) return


  // if BannerAddToHomeScreen is opened
  const bannerAddToHomeScreen = hasBannerAddToHomeScreen()

  let y = el.getBoundingClientRect().y
  const finalPosition = breakpoint.heightHeader + bannerAddToHomeScreen.height
  y -= finalPosition


  if(onFinish){
    const callback = ()=>{
      // if reach final position run callback
      if(Math.abs(el.getBoundingClientRect().y -  y) == finalPosition ){
        observer.unobserve(el)
        onFinish()

      }
    }
    const observer = new IntersectionObserver(callback, {root: el, rootMargin: `0px`, threshold: 1.0})
    observer.observe(el)
  }

  window.scrollBy({top: y, ...scrollOpt})


}

/**
 * Get max pixel of scroll are possible inside parent of given element
 *
 * @params {HTMLElement} el
 * @return {number | undefined}
 * */
export function getMaxScrollOfElement(el: HTMLElement): number | undefined{
  if(el){
    el.scrollTo(0, 10000000);
    const max = el.scrollTop;
    el.scrollTo(0,0);
    return max
  }
}


export type TUseCaptureGestureSwipeLeftOrRight =  {
  handlePrev:()=>void,
  handleNext:()=>void,
  tollerance?: number,
  durationTransitioningInMs?: number
}

export type TUseCaptureGestureSwipeLeftOrRightReturn = {
  onTouchStart:(e:TouchEvent)=>void,
  onTouchMove:(e:TouchEvent)=>void,
  transitioning?: boolean
}
/**
 * Capture a swipe left or right to an element and trigger a next or prev given function when finger satisfies a certain
 * tollerance
 * */
export const useCaptureGestureSwipeLeftOrRight = (
  {handlePrev, handleNext, tollerance = 100, durationTransitioningInMs = 500}: TUseCaptureGestureSwipeLeftOrRight
   ):TUseCaptureGestureSwipeLeftOrRightReturn=>{
  const [transitioning, _setTransitioning] = useState<boolean>(false);
  const [touchesStart, setTouchesStart] = useState<{x: number, y: number}>({x:0,y:0});
  const refTransitioning = useRef<boolean>(false)
  // useBlockScroll(transitioning)

  const setTransitioning = (newValue : boolean)=>{
    refTransitioning.current = newValue
    _setTransitioning(newValue)
  }


  const handleTouchStart = (e:TouchEvent) => {
    setTouchesStart({x:e.touches[0].clientX, y: e.touches[0].clientY});
  };

  const handleTouchMove = (e:TouchEvent) => {
    if(touchesStart.x == null) return e.preventDefault()

    const touchMoveX = e.touches[0].clientX;
    const touchDeltaX = touchMoveX - touchesStart.x;

    if (touchDeltaX > tollerance) {
      goPrev();
    } else if (touchDeltaX < - tollerance) {
      goNext();
    }
  };

  const goPrev = () => {
    if (!refTransitioning.current) {
      setTransitioning(true);
      handlePrev()
      setTimeout(reset, durationTransitioningInMs);
    }
  }

  const goNext = () => {
    if (!refTransitioning.current) {
      setTransitioning(true);
      handleNext()
      setTimeout(reset, durationTransitioningInMs);
    }
  }

  const reset = ()=>{
    setTransitioning(false);
    setTouchesStart({x: null, y:null})
  }

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    transitioning: transitioning

  }

}