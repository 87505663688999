import { LangMap } from "@model/CoursesClass";
import { UserCompletionCourse } from "@model/MyTeamModel";
import { printCtype, printDate, printDuration } from "./Api";

export const singleMemberFieldsTable = [
    { key: 'courseFullName', label: 'MYTEAM_MEMBER_OVERDUE_LESSON_NAME', preventSorting: true },
    { key: 'ctype', label: 'MYTEAM_MEMBER_OVERDUE_LESSON_TYPE', preventSorting: true },
    { key: 'expectedCompletion', label: 'MYTEAM_MEMBER_OVERDUE_DUE_DATE', preventSorting: true },
    { key: 'duration', label: 'MYTEAM_MEMBER_OVERDUE_DURATION', preventSorting: true },
];

export const getSingleMemberCourseFieldValue = (course: UserCompletionCourse, field: string, lang: LangMap): string => {
    if (!course) {
        return '';
    }

    switch (field) {
        case 'ctype':
            return printCtype(course.ctype, lang);
        case 'expectedCompletion':
            return printDate(course.expectedCompletion, lang, true)
        case 'duration':
            return printDuration(course.duration, lang, false, true)
        default:
            return course[field] || '';
    }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const downloadBase64 = (dataBase64: any, filename: string, contentType: string = '') => {
    if (!dataBase64 || !filename) {
        return;
    }

    const blob = b64toBlob(dataBase64, contentType);
    let a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    a.click();
}