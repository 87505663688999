import { useEffect, useRef, useState } from 'react';
type RefStateValue<T> = T | ((prevValue: T) => T);
type RefStateFunction<T> = (prevValue: T) => T;

function useRefState<T>(initialValue: T) {
  const [state, setState] = useState<T>(initialValue);
  const stateRef = useRef<T>(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const updater = (value: RefStateValue<T>) => {
    if (typeof value === 'function') {
      setState(prevState => {
        const newValue = (value as RefStateFunction<T>)(prevState);
        stateRef.current = newValue;
        return newValue;
      });
    } else {
      setState(value);
      stateRef.current = value;
    }
  };

  return [stateRef, updater] as const;
}

export default useRefState;
