import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TrainingPill } from '@model/ProductClass';
import { TealiumEvents, EventsTracking } from '@model/TrackingClass';
import { styledLogUtagView, USER_URLS } from '@components/link-utils';
import * as actions from '@redux-actions/';

const useWishlistTP = (
  product: TrainingPill,
  isTPDetail: boolean = false
): [boolean, boolean, (e) => void] => {
  const dispatch = useDispatch();

  const [isWishlist, setIsWishlist] = useState(false);
  const [isLoadingWishlistLocal, setIsLoadingWishlistLocal] = useState(false);
  const [timeoutWishlist, setTimeoutWishlist] = useState(null);

  useEffect(() => {
    if (!product) {
      return;
    }

    //set wishlist
    setIsWishlist(product?.wishlist);
  }, [product]);

  useEffect(() => {
    return () => {
      //cancel timeout before component unmounting
      if (timeoutWishlist) {
        clearTimeout(timeoutWishlist);
      }
    };
  }, []);

  const handleToggleWish = (e?) => {
    if (e) {
      e.stopPropagation();
    }

    if (!isLoadingWishlistLocal) {
      setIsLoadingWishlistLocal(true);

      //execute wishlist change after 700ms, if the user reset the wishlist as before --> cancel timeout
      const timerWishlist = setTimeout(() => {
        //save old wishlist in case of error
        const prevIsWishlist = isWishlist;

        const responsePromise = dispatch(actions.toggleWishTP(product, !isWishlist, isTPDetail));

        responsePromise
          .then(res => {
            // res could be used to verify state wishlist..

            // if (!prevIsWishlist) {
            //   const utagLinkData: EventsTracking = {
            //     id: TealiumEvents.fav_add,
            //     Products_Id_Array: [product.model],
            //     Products_ModelName_Array: [product.modelName],
            //     Page_DeviceType: 'web',
            //   };

            //   styledLogUtagView('Add Product to Wishlist', utagLinkData);
            // }

            //do not change state just if the user removes a TP from wishlist in wishlist page
            //(because the component is unmounted before the state change and React returns an error)
            if (window.location.pathname !== USER_URLS.WISHLIST.URL || !isWishlist || isTPDetail) {
              setIsWishlist(!isWishlist);
              setIsLoadingWishlistLocal(false);
            }
          })
          .catch(err => {
            console.log(`err`, err);

            //revert wishlist
            setIsWishlist(prevIsWishlist);
            setIsLoadingWishlistLocal(false);
          });
      }, 700);

      setTimeoutWishlist(timerWishlist);
    } else {
      //cancel timeout if the user restored the old wishlist state
      if (timeoutWishlist) {
        clearTimeout(timeoutWishlist);
        setIsLoadingWishlistLocal(false);
      }
    }
  };

  return [isWishlist, isLoadingWishlistLocal, handleToggleWish];
};
export default useWishlistTP;
