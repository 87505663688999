import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '@utility/Api';
import { UserCompletion, UserCompletionDetail } from '@model/MyTeamModel';

class InitialStateProps {
    usersCompletions: UserCompletion[] = [];
    peopleInTeam: number = 0;
    onlineCompleted: number = 0;
    classroomsCompleted: number = 0;
    timeSpent: number = 0;
    percentageCompletion: number = 0;
    isLoadingMyTeamList: boolean = false;
    isLoadedMyTeamList: boolean = false;
    lastUpdateReport: string = null;
    isGetUserCourseEnded: boolean = false;
    isDownloadingExcel: boolean = false;
    showRefresh: boolean = false;
    refreshRequested: boolean = false;
    myCredits: number = 0;
    myCompletedOnline: number = 0;
    myCompletedClassrooms: number = 0;
    myTimeTraining: number = 0;
    isMyStatsCompleted: boolean = false;
    isLoadingMyTeamDetail: boolean = false;
}

const initialState: InitialStateProps = new InitialStateProps();

const getMyTeamRequest = state => {
    return updateObject(state, {
        isLoadingMyTeamList: true,
        isGetUserCourseEnded: false
    })
}

const getMyTeamSuccess = (state, action) => {
    let usersCompletions = orderBy(action.myTeam.usersCompletions, ["lastName"], "asc");

    return updateObject(state, {
        usersCompletions,
        peopleInTeam: action.myTeam.peopleInTeam,
        onlineCompleted: action.myTeam.onlineCompleted,
        classroomsCompleted: action.myTeam.classroomsCompleted,
        timeSpent: action.myTeam.timeSpent,
        percentageCompletion: action.myTeam.percentageCompletion,
        isLoadingMyTeamList: false,
        isLoadedMyTeamList: true,
        showRefresh: action.myTeam.showRefresh == 1 ? true : false,
        refreshRequested: action.myTeam.refreshRequested == 1 ? true : false,
        lastUpdateReport: action.myTeam.reportInstant
    })
}

const getMyTeamFailure = state => {
    return updateObject(state, {
        isLoadingMyTeamList: false,
        isLoadedMyTeamList: true,
    })
}

const getRefreshRequest = state => {
    return updateObject(state, {
        refreshRequested: true
    })
}

const getRefreshSuccess = state => {
    return updateObject(state, {
        refreshRequested: true
    })
}

const getRefreshFailure = state => {
    return updateObject(state, {
        refreshRequested: false
    })
}

const setRefreshRequested = (state, action) => {
    return updateObject(state, {
        refreshRequested: action.refreshRequested
    })
}

const endedCall = state => {
    return updateObject(state, {
        isGetUserCourseEnded: true
    })
}

const logout = () => {
    return initialState;
}


const getReportExcelRequest = state => {
    return updateObject(state, {
        isDownloadingExcel: true
    })
}

const getReportExcelSuccess = (state, action) => {
    return updateObject(state, {
        isDownloadingExcel: false
    })
}

const getReportExcelFailure = state => {
    return updateObject(state, {
        isDownloadingExcel: false
    })
}

const getMyStats = (state, action) => {
    return updateObject(state, {
        myCredits: action.myStats.myCredits,
        myCompletedOnline: action.myStats.myCompletedOnline,
        myCompletedClassrooms: action.myStats.myCompletedClassrooms,
        myTimeTraining: action.myStats.myTimeTraining,
        isMyStatsCompleted: true
    })
}

const getMyTeamDetailRequest = (state) => {
    return updateObject(state, {
        isLoadingMyTeamDetail: true
    })
}

const getMyTeamDetailEnd = (state) => {
    return updateObject(state, {
        isLoadingMyTeamDetail: false,
    })
}

const reducer = (state = initialState, action): InitialStateProps => {
    switch (action.type) {
        case actionTypes.GET_MY_TEAM_REQUEST: return getMyTeamRequest(state);
        case actionTypes.GET_MY_TEAM_SUCCESS: return getMyTeamSuccess(state, action);
        case actionTypes.GET_MY_TEAM_FAILURE: return getMyTeamFailure(state);
        case actionTypes.GET_MY_TEAM_WITH_COURSE_SUCCESS: return endedCall(state);
        case actionTypes.GET_REFRESH_REQUEST: return getRefreshRequest(state);
        case actionTypes.GET_REFRESH_SUCCESS: return getRefreshSuccess(state);
        case actionTypes.GET_REFRESH_FAILURE: return getRefreshFailure(state);
        case actionTypes.SET_REFRESH_REQUESTED: return setRefreshRequested(state, action);
        case actionTypes.GET_REPORT_EXCEL_REQUEST: return getReportExcelRequest(state);
        case actionTypes.GET_REPORT_EXCEL_SUCCESS: return getReportExcelSuccess(state, action);
        case actionTypes.GET_REPORT_EXCEL_FAILURE: return getReportExcelFailure(state);
        case actionTypes.GET_MY_STATS: return getMyStats(state, action);
        case actionTypes.GET_MY_TEAM_DETAIL_REQUEST: return getMyTeamDetailRequest(state);
        case actionTypes.GET_MY_TEAM_DETAIL_END: return getMyTeamDetailEnd(state);
        case actionTypes.LOGOUT: return logout();
        default:
            return state;
    }
};
export default reducer;