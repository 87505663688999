import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Layout from "@components/Layout";
import TextField from "@components/UI/TextField";
import ErrorMessage from "@components/UI/ErrorMessage";
import { useForm } from "react-hook-form";
import ButtonV2 from "@components/UI/ButtonV2";
import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import { styledLogUtagView, USER_URLS, UtagViewPages } from "@components/link-utils";
import { History } from "history";
import * as actions from "@redux-actions/";

import RefundAndCancellationPolicy from "@components/UI/RefundAndCancellationPolicy";
import { printHTML, removeHTMLTags } from "@utility/Api";
import { useSelector } from "@hooks/redux";
import { useQuery } from "@hooks/useQuery";
import { queryParams } from "@utility/const";
import { DataLayerTracking, ErrorTracking, ErrorTypes, selectTrackingDataLayerBase, TealiumEvents, TealiumEventsDataLayer } from "@model/TrackingClass";
import CardLoader from "@components/UI/CardLoader";
import { ACTIVATE_TOKEN_ERROR_CODE } from "@model/EcommerceClass";
import LazySVG from "@components/LazySvg";
import ModalEvolution from "@components/UI/ModalEvolution";
import PageTopTitle from "@components/UI/PageTopTitle";
import { triggerErrorEvent, triggerPageView } from "@utility/analytics-utils";

type FormValues = {
  token: string;
};

type ProfileActivateLicenseProps = {
  lang: LangMapEcomm & LangMap;
  history: History;
};

const printHTMLWithStrongTag = (string) => {
  return printHTML(
    string
      ?.replace("{", "<strong>")
      ?.replace("{", "<strong>")
      .replace("}", "</strong>")
      .replace("}", "</strong>")
  );
};

const ProfileActivateLicense = ({
  lang,
  history,
}: ProfileActivateLicenseProps) => {
  const { register, errors, handleSubmit, reset } = useForm<FormValues>(
    {
      mode: "onSubmit",
    }
  );
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get(queryParams.TOKEN);
  const loadingActivation: boolean = useSelector(
    (state) =>
      state.ecommerce?.showModalLicenseActivate?.loading
  );
  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted || !state.ecommerce.isLoadingCart);

  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      if (isGetCourseCompleted) {
        //send the activation request
        dispatch(actions.activateLicense(token, true , true));
        //remove token param from URL
        history.replace(window.location.pathname);
      } else {
        //show just the loader if the catalog is not downloaded
        dispatch(actions.setActivateLicenseModal({
          token,
          open: true,
          loading: true,
          success: false,
        }));
      }

      //wait the loader modal opening
      setTimeout(() => {
        setShowContent(true);
      }, 1000);
    } else {
      if (!showContent) {
        setShowContent(true);
      }
    }
  }, [token, isGetCourseCompleted]);

  const onSubmit = (data: FormValues) => {
    console.log(`data`, data);
    if (!loadingActivation) {
      dispatch(actions.activateLicense(data.token, false, true))
    }
  };

  const baseTealiumTracking = useSelector(selectTrackingDataLayerBase);
  const userProfile = useSelector(state => state.user.userProfile);

  //ANALYTICS
  useEffect(() => {
    if (baseTealiumTracking && !window.location.search.includes(queryParams.COURSE_DETAIL)) {
      const utagViewData: DataLayerTracking = {
        ...baseTealiumTracking,
        ...UtagViewPages.ACTIVATE_LICENSE,
      }
      styledLogUtagView(UtagViewPages.ACTIVATE_LICENSE?.Page_Section2, utagViewData);
      triggerPageView(utagViewData);
    }
  }, [!!baseTealiumTracking, window.location.search]);

  return (
    <>
      <Layout>
        {showContent &&
          <div className="activate-license">
            <PageTopTitle
              title={lang?.ACTIVATE_LICENSE}
              htmlDescription={printHTMLWithStrongTag(lang?.ACTIVATE_LICENSE_DESCR)}
            />
            <form className="max-width-620" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                type="text"
                name="token"
                mandatory
                placeholder={lang?.INSERT_TOKEN}
                className="token"
                ref={register({
                  required: lang.ERROR_REQUIRED as string,
                })}
                aria-invalid={errors.token ? "true" : "false"}
                isError={!!errors.token ? true : false}
                readOnly={loadingActivation}
              />
              <ErrorMessage error={errors.token} />
              <div className="activate-license__cta-wrapper flex">
                <ButtonV2
                  variant="primary"
                  small
                  className="activate-license__cta"
                  loading={loadingActivation}
                >
                  {lang?.ACTIVATE}
                </ButtonV2>
              </div>
              <RefundAndCancellationPolicy firstUrlTitleKey="REFUND_CANCELLATION_FILE_TITLE" />
            </form>
          </div>
        }
      </Layout>
    </>
  );
};

export default ProfileActivateLicense;


type ActivateLicenseModalProps = {
  show: boolean;
  success: boolean;
  loading: boolean;
  errorCode: string;
};
export const ActivateLicenseModal = ({
  show,
  success,
  loading,
  errorCode,
}: ActivateLicenseModalProps) => {
  const lang: LangMap = useSelector((state) => state.utils.lang);

  const dispatch = useDispatch();
  const history = useHistory();

  const close = () => {
    dispatch(
      actions.setActivateLicenseModal({
        token: "",
        loading: false,
        open: false,
      })
    );
  };

  const handleClickSuccess = () => {
    close();
    history.push(USER_URLS.HOMEPAGE.URL);
  };

  const handleClickError = () => {
    close();
    history.push(USER_URLS.CONTACT_US.URL);
  };

  const getErrorMessageLabel = () => {
    let errorLabel = '';

    if (errorCode && lang) {
      switch (errorCode) {
        case ACTIVATE_TOKEN_ERROR_CODE.ERR1:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_01;
          break;
        case ACTIVATE_TOKEN_ERROR_CODE.ERR2:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_02;
          break;
        case ACTIVATE_TOKEN_ERROR_CODE.ERR3:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_03;
          break;
        case ACTIVATE_TOKEN_ERROR_CODE.ERR4:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_04;
          break;
        case ACTIVATE_TOKEN_ERROR_CODE.ERR500:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_05;
          break;
        default:
          errorLabel = lang.TOKEN_PROCESSING_ERROR_MESSAGE_05;
          break;
      }
    }
    let utagErrorData: ErrorTracking = {
      Error_Source: ErrorTypes.user,
      Error_Code: 'Invalid licence key',
      Error_Message: removeHTMLTags(errorLabel)
    }
    styledLogUtagView(utagErrorData?.Error_Code, utagErrorData)
    triggerErrorEvent(utagErrorData)
    return errorLabel;
  }

  return (
    loading
      ?
      <ModalEvolution
        close={close}
        show={show}
        hideX={true}
        modalClassName="only-1-month"
        title={lang?.ACTIVATE_TOKEN_LOADING_TEXT}
        footerElement={
          <div className="activate-license__loading-section">
            <CardLoader />
            <LazySVG src="/images/svg-icons/lp-icon.svg" className="svg-icon" />
          </div>
        }
      />
      :
      <ModalEvolution
        close={close}
        show={show}
        hideX={!success}
        modalClassName="only-1-month"
        icon={success
          ? "/images/svg-icons/circle-tick.svg"
          : "/images/svg-icons/circle-close.svg"
        }
        title={success
          ? lang?.TOKEN_PROCESSING_SUCCESS_TITLE
          : lang?.TOKEN_PROCESSING_ERROR_TITLE
        }
        descriptionElement={
          <>
            <p
              className="message"
              dangerouslySetInnerHTML={printHTMLWithStrongTag(
                success
                  ? lang?.TOKEN_PROCESSING_SUCCESS_MESSAGE
                  : getErrorMessageLabel()
              )}
            />
            <p className="what-happens-next">{lang?.WHAT_HAPPENS_NEXT}</p>
            <p
              className="message-2"
              dangerouslySetInnerHTML={printHTMLWithStrongTag(
                success
                  ? lang?.TOKEN_PROCESSING_SUCCESS_MESSAGE2
                  : lang?.TOKEN_PROCESSING_ERROR_MESSAGE2
              )}
            />
          </>
        }
        mainButtonLabel={success ? lang?.BACK_TO_HP : lang?.CONTACT_CUSTOMER_CARE}
        mainButtonAction={success ? handleClickSuccess : handleClickError}
        secondaryButtonLabel={success ? '' : lang?.CANCEL}
        secondaryButtonAction={success ? undefined : close}
      />
  );
};
