import React from 'react';
import { DateRangePicker, Range } from 'react-date-range';

type Props = {
  className?: string;
  classNameContainer?: string;
  ranges: Range[];
  onChange: (value) => void;
  minDate?: Date;
  maxDate?: Date;
  months?: number;
};
const DateRange = ({ ranges, className = '', classNameContainer = '', onChange, minDate, maxDate, months }: Props) => {
  return (
    <div className={'custom-date-range-container ' + classNameContainer}>
      <DateRangePicker
        className={'custom-date-range ' + className}
        ranges={ranges}
        date={new Date()}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        showMonthAndYearPickers={false}
        showMonthArrow={false}
        scroll={{ enabled: true, monthWidth: 278 }}
        weekStartsOn={1}
        monthDisplayFormat="MMMM yyyy"
        months={months}
      />
    </div>
  );
};

export default DateRange;
