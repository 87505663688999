import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { loadState } from "@utility/localStorage";
import { isProdEnv as isProd } from '@utility/Api';

const persistedState = loadState();
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  devTools: !isProd(),
  middleware: [thunk],
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
