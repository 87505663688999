const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

//FOR TRAINING PILLS
export const carouselSettingsCardMediumPillDefault = {
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: !isTouchDevice(),
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: !isTouchDevice(),
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: !isTouchDevice(),
      },
    },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: !isTouchDevice(),
      },
    },
    {
      breakpoint: 348,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !isTouchDevice(),
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};

//FOR TRAINING PILLS
export const carouselSettingsCardMediumPillVariableWidthDefault = {
  slidesToShow: 13,
  slidesToScroll: 13,
  responsive: [
    {
      breakpoint: 3338,
      settings: {
        slidesToShow: 13,
        slidesToScroll: 13,
      },
    },
    {
      breakpoint: 3138,
      settings: {
        slidesToShow: 12,
        slidesToScroll: 12,
      },
    },
    {
      breakpoint: 2938,
      settings: {
        slidesToShow: 11,
        slidesToScroll: 11,
      },
    },
    {
      breakpoint: 2738,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 10,
      },
    },
    {
      breakpoint: 2538,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9,
      },
    },
    {
      breakpoint: 2338,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 2138,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 1938,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1738,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1361,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

//FOR PILLS
export const carouselSettingsPill = {
  slidesToShow: 1,
  slidesToScroll: 1,
}

//FOR VC SESSIONS
export const carouselSettingsVcSessionsTablet = {
  slidesToShow: 4,
  slidesToScroll: 2,
  dots:true,
}

export const carouselSettingsVcSessionsDesktop = {
  slidesToShow: 3,
  slidesToScroll: 1,
  centermode: true,
  centerpaddng: "60px",
  variableWidth: true,
dots:true,
responsive: [
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 4,
      centermode: false,
      slidesToScroll: 1,
      arrows: !isTouchDevice(),
      centerMode: true,
      variableWidth: true,
    },
  },
]
}

export const carouselSettingsCardProgressDefault = {
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        centermode: true,
        slidesToScroll: 1,
        arrows: !isTouchDevice(),
        centerMode: true,
        variableWidth: true,
      },
    },
  ]
}

export const carouselSettingsCardVcSessionDefault = {
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !isTouchDevice(),
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};


export const carouselSettingsImageDefault = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
};
