import React, { ReactElement } from 'react';
import LazyImage from 'react-lazy-progressive-image';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import { LangMap } from '@model/CoursesClass';
import { L1Element } from '@model/L1Element';
import { handleOnEnterKeyPress, toSurnameCase } from '@utility/Api';
import { handleClickL1Card } from '@redux-actions/';
import CardCover from './CardCover';
import { USER_URLS } from '@components/link-utils';
import history from '../../history';
import { cmMetaData } from '@utility/previewUtils';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import camelCase from 'lodash/camelCase';

type CardL1Props = {
  l1: L1Element;
  tabIndex?: number;
  dataElementId?: string;
  onClick?: () => void;
  l1ContentId?: number;
};

const CardL1Search = (props: CardL1Props): ReactElement => {
  const { l1 } = props;
  const browseByTopicInfo = useSelector(state => state.search.browseByTopicInfo?.info?.[l1?.value]);
  const dispatch = useDispatch();

  const channelInfos = l1.channelInfos ? l1.channelInfos : browseByTopicInfo;

  const lang: LangMap = useSelector(state => state.utils.lang);
  const l1Map = useSelector(state => state.utils.l1Map);

  const handleClickCard = () => {
    buildClickDataTrackingObject("browseByTopic_"+camelCase(l1.value))
    props?.onClick?.();
    if (!props?.onClick) {
      if (window.location.pathname !== USER_URLS.SUBCATALOGUE_SEARCH.URL) {
        history.push(USER_URLS.SUBCATALOGUE_SEARCH.URL);
      }
      dispatch(handleClickL1Card(l1.type, l1.value));
    }
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  const printMoreInfo = channelInfos => {
    if (
      channelInfos.numberOfEvents > 0 &&
      channelInfos.numberOfCourses > 0 &&
      channelInfos.numberOfLessons > 0
    ) {
      return lang?.ADDITIONAL_CHANNEL_INFOS?.replace(
        '{NumEvents}',
        channelInfos?.numberOfEvents.toString()
      )
        .replace('{NumCourses}', channelInfos?.numberOfCourses.toString())
        .replace('{NumLessons}', channelInfos?.numberOfLessons.toString());
    } else {
      let moreInfoString = lang?.ADDITIONAL_CHANNEL_INFOS?.replace(
        '{NumEvents}',
        channelInfos?.numberOfEvents.toString()
      )
        .replace('{NumCourses}', channelInfos?.numberOfCourses.toString())
        .replace('{NumLessons}', channelInfos?.numberOfLessons.toString());
      if (channelInfos.numberOfEvents === 0) {
        moreInfoString = moreInfoString.replace('0 live sessions / ', '');
      }
      if (channelInfos.numberOfCourses == 0) {
        moreInfoString = moreInfoString.replace('0 courses / ', '');
      }
      if (channelInfos.numberOfLessons == 0) {
        moreInfoString = moreInfoString.replace(' / 0 lessons', '');
      }
      return moreInfoString;
    }
  };

  l1.labelMedium = l1.labelMedium.replace('channel', '');
  const labelL0 = l1Map[l1.value]?.l0?.label;

  return (
    <a
      className={'card-search card-search__l1'}
      data-id={l1.type + '_' + l1.value}
      data-element-id={props.dataElementId}
      {...props?.l1ContentId && cmMetaData(props?.l1ContentId)}
    >
      <div className={'card-search__content'}>
        <div
          className="card-search__cover"
          onClick={handleClickCard}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', l1.label)}
        >
          <CardCover
            image={l1.image}
            courseFullName={l1.alt || l1.label || l1.type + '_' + l1.value}
            fallbackImage={l1.fallbackImage}
            fallbackText={l1.label}
            variant="card-medium"
            hideShadow
            forceFallback={!l1.image ? true : false}
            contentId={l1?.imageContentId}
          />
          <div className="card-search__iride">
            <LazyImage src={'/images/iride_x3.png'} placeholder="">
              {(src, loading) => <img className="icon" alt="iride" src={src} draggable={false} />}
            </LazyImage>
          </div>
        </div>

        <section className={`card-search__info `} aria-label={l1.labelMedium}>
          <div>
            {labelL0 && (
              <div className="card-search__ctype">
                <span>{labelL0}</span>
              </div>
            )}
            <div className="card-search__title" onClick={handleClickCard}>
              {l1.label}
            </div>
            <div className="card-search__title-descr">
              {channelInfos?.numberOfCourses > 0 && channelInfos?.numberOfCourses < 10 && (
                <div
                  aria-label={lang?.EXPLORE_ALL_CHANNEL_INFO_COURSES?.replace(
                    '{NumCourses}',
                    channelInfos?.numberOfCourses.toString()
                  )}
                >
                  <span className="elementEvent">
                    {toSurnameCase(
                      lang?.EXPLORE_ALL_CHANNEL_INFO_COURSES?.replace(
                        '{NumCourses}',
                        channelInfos?.numberOfCourses.toString()
                      )
                    )}
                  </span>
                </div>
              )}
              {channelInfos?.numberOfCourses > 0 && channelInfos?.numberOfCourses > 10 && (
                <div className="elementEventLong">
                  {toSurnameCase(
                    lang?.EXPLORE_ALL_CHANNEL_INFO_COURSES?.replace(
                      '{NumCourses}',
                      channelInfos?.numberOfCourses.toString()
                    )
                  )}
                </div>
              )}
              {channelInfos?.numberOfLessons > 0 && channelInfos?.numberOfLessons < 10 && (
                <div className="elementLesson">
                  {toSurnameCase(
                    lang?.EXPLORE_ALL_CHANNEL_INFO_LESSONS?.replace(
                      '{NumLessons}',
                      channelInfos?.numberOfLessons.toString()
                    )
                  )}
                </div>
              )}
              {channelInfos?.numberOfLessons > 0 && channelInfos?.numberOfLessons > 10 && (
                <div className="elementLessonLong">
                  {toSurnameCase(
                    lang?.EXPLORE_ALL_CHANNEL_INFO_LESSONS?.replace(
                      '{NumLessons}',
                      channelInfos?.numberOfLessons.toString()
                    )
                  )}
                </div>
              )}
              {channelInfos?.numberOfEvents > 0 && (
                <div className="elementLiveEvent">
                  {toSurnameCase(
                    lang?.EXPLORE_ALL_CHANNEL_INFO_EVENTS?.replace(
                      '{NumEvents}',
                      channelInfos?.numberOfEvents.toString()
                    )
                  )}
                </div>
              )}
            </div>
            {/*             <div className="card-search__title-descr">{lang?.ADDITIONAL_CHANNEL_INFOS?.replace("{NumEvents}",channelInfos?.numberOfEvents.toString()).replace("{NumCourses}",channelInfos?.numberOfCourses.toString()).replace("{NumLessons}",channelInfos?.numberOfLessons.toString())}</div> */}
          </div>
        </section>
      </div>
    </a>
  );
};

export default CardL1Search;
