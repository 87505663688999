export class ShippingInfo {
    resourceId: string;
    resourceName: string;
    userId: string;
    addressId: string;
    contact: ShippingAddress[] = [];
    // orderId: string;
    // orderItem: ShippingAddress[];
}

export class ShippingAddress {
    addressId: string;
    firstName: string;
    lastName: string;
    addressLine: string[] | string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    nickName: string;
    isTemporary?: boolean;
    isNew?: boolean;
}