import { authFirebase } from "./firebase";

export const signup = (email, password) => {
  return authFirebase().createUserWithEmailAndPassword(email, password);
}

export const signin = (email, password) => {
  return authFirebase().signInWithEmailAndPassword(email, password);
}

// export function signInWithGoogle() {
//   const provider = new authFirebase.GoogleAuthProvider();
//   return authFirebase().signInWithPopup(provider);
// }

// export function signInWithGitHub() {
//   const provider = new authFirebase.GithubAuthProvider();
//   return authFirebase().signInWithPopup(provider);
// }

export const signInWithCustomToken = (token) => {
  return authFirebase().signInWithCustomToken(token);
}

export const logout = () => {
  return authFirebase().signOut();
}