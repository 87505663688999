import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/Api';
import { API_ADMIN_STATUS_CODE } from '@utility/const';

export const initialState = {
  loading: false,
  error: null,
  isAuthenticated: false,
  showPassExpiredPopup: false,
  showMYLKOPopup: false,
  cookies: null,
  isGAJustInizialized: false,
  loadingRegister: false,
  responseRegister: null,
  registerUserType: '',
  isActivatingUser: false,
  responseActivateUser: null,
  isSendingPswEmail: false,
  isResettingPublicPsw: false,
  responseResetPublicPsw: null,
  userTypeResetPublicPsw: null,
  isResettingLoggedPsw: false,
  isResetLoggedPswSuccess: false,
  countries: [],
  statesOfResidence: [],
  loadingStatesOfResidence: false,
  loadingCountries: false,
  isLoggingMylToken: false,
  showLoginModalLoader: false,
};

const getAuthenticationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    isAuthenticated: false,
    error: null,
    responseActivateUser: null,
    isLoggingMylToken: action.isLoggingMylToken,
    showLoginModalLoader: action.showLoginModalLoader,
  });
};

const getAuthenticationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    isAuthenticated: true,
    cookies: action.cookies,
    showPassExpiredPopup: false,
    isLoggingMylToken: false,
    showLoginModalLoader: false,
  });
};

const getAuthenticationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    isAuthenticated: false,
    isLoggingMylToken: false,
    showLoginModalLoader: false,
  });
};

const logout = state => {
  return {
    ...initialState,
    showLoginModalLoader: state.showLoginModalLoader,
  };
};

const togglePassExpiredPopup = (state, action) => {
  return updateObject(state, {
    showPassExpiredPopup: action.show,
  });
};

const toggleShowMYLKOPopup = (state, action) => {
  return updateObject(state, {
    showMYLKOPopup: action.show,
  });
};

const setGACheck = (state, action) => {
  return updateObject(state, {
    isGAJustInizialized: action.value,
  });
};

const cleanError = state => {
  return updateObject(state, {
    error: null,
  });
};

const getFirebaseTokenSuccess = (state, action) => {
  return updateObject(state, {
    cookies: action.cookies,
  });
};

const getFirebaseTokenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getRegisterStart = state => {
  return updateObject(state, {
    loadingRegister: true,
    responseRegister: null,
    registerUserType: '',
  });
};

const getRegisterSuccess = (state, action) => {
  return updateObject(state, {
    loadingRegister: false,
    responseRegister: API_ADMIN_STATUS_CODE.OK,
    registerUserType: action.userType,
  });
};

const getRegisterFail = (state, action) => {
  return updateObject(state, {
    loadingRegister: false,
    responseRegister: action.error,
  });
};

const resetRegisterResponse = state => {
  return updateObject(state, {
    responseRegister: null,
  });
};

const activateUserStart = state => {
  return updateObject(state, {
    isActivatingUser: true,
    responseActivateUser: null,
  });
};

const activateUserSuccess = state => {
  return updateObject(state, {
    isActivatingUser: false,
    responseActivateUser: API_ADMIN_STATUS_CODE.OK,
  });
};

const activateUserFail = (state, action) => {
  return updateObject(state, {
    isActivatingUser: false,
    responseActivateUser: action.error,
  });
};

const sendPswEmailStart = state => {
  return updateObject(state, {
    isSendingPswEmail: true,
  });
};

const sendPswEmailSuccess = state => {
  return updateObject(state, {
    isSendingPswEmail: false,
  });
};

const sendPswEmailFail = state => {
  return updateObject(state, {
    isSendingPswEmail: false,
  });
};

const resetPswPublicStart = state => {
  return updateObject(state, {
    isResettingPublicPsw: true,
    responseResetPublicPsw: null,
    userTypeResetPublicPsw: null,
  });
};

const resetPswPublicSuccess = (state, action) => {
  return updateObject(state, {
    isResettingPublicPsw: false,
    responseResetPublicPsw: API_ADMIN_STATUS_CODE.OK,
    userTypeResetPublicPsw: action.userType,
  });
};

const resetPswPublicFail = (state, action) => {
  return updateObject(state, {
    isResettingPublicPsw: false,
    responseResetPublicPsw: action.error,
  });
};

const resetPswLoggedStart = state => {
  return updateObject(state, {
    isResettingLoggedPsw: true,
    isResetLoggedPswSuccess: false,
  });
};

const resetPswLoggedSuccess = state => {
  return updateObject(state, {
    isResettingLoggedPsw: false,
    isResetLoggedPswSuccess: true,
  });
};

const resetPswLoggedFail = state => {
  return updateObject(state, {
    isResettingLoggedPsw: false,
    isResetLoggedPswSuccess: false,
  });
};

const resetPswLoggedReset = state => {
  return updateObject(state, {
    isResettingLoggedPsw: false,
    isResetLoggedPswSuccess: false,
  });
};

const getCountriesStart = state => {
  return updateObject(state, {
    loadingCountries: true,
  });
};

const getCountriesSuccess = (state, action) => {
  return updateObject(state, {
    loadingCountries: false,
    countries: action.countries,
  });
};

const getCountriesFail = (state, action) => {
  return updateObject(state, {
    loadingCountries: false,
  });
};

const getStatesOfResidenceStart = state => {
  return updateObject(state, {
    loadingStatesOfResidence: true,
  });
};

const getStatesOfResidenceSuccess = (state, action) => {
  return updateObject(state, {
    loadingStatesOfResidence: false,
    statesOfResidence: action.statesOfResidence,
  });
};

const getStatesOfResidenceFail = (state, action) => {
  return updateObject(state, {
    loadingStatesOfResidence: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return getAuthenticationStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return getAuthenticationSuccess(state, action);
    case actionTypes.LOGIN_FAILURE:
      return getAuthenticationFail(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.TOGGLE_PASS_EXPIRED_POPUP:
      return togglePassExpiredPopup(state, action);
    case actionTypes.TOGGLE_MYL_KO_POPUP:
      return toggleShowMYLKOPopup(state, action);
    case actionTypes.SET_GA_CHECK:
      return setGACheck(state, action);
    case actionTypes.CLEAN_ERROR_LOGIN:
      return cleanError(state);
    case actionTypes.FIREBASE_TOKEN_SUCCESS:
      return getFirebaseTokenSuccess(state, action);
    case actionTypes.FIREBASE_TOKEN_FAILURE:
      return getFirebaseTokenFail(state, action);
    case actionTypes.REGISTER_START:
      return getRegisterStart(state);
    case actionTypes.REGISTER_SUCCESS:
      return getRegisterSuccess(state, action);
    case actionTypes.REGISTER_FAILURE:
      return getRegisterFail(state, action);
    case actionTypes.REGISTER_RESET:
      return resetRegisterResponse(state);
    case actionTypes.ACTIVATE_USER_REQUEST:
      return activateUserStart(state);
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return activateUserSuccess(state);
    case actionTypes.ACTIVATE_USER_FAILURE:
      return activateUserFail(state, action);
    case actionTypes.CHANGE_PSW_EMAIL_REQUEST:
      return sendPswEmailStart(state);
    case actionTypes.CHANGE_PSW_EMAIL_SUCCESS:
      return sendPswEmailSuccess(state);
    case actionTypes.CHANGE_PSW_EMAIL_FAILURE:
      return sendPswEmailFail(state);
    case actionTypes.RESET_PSW_PUBLIC_REQUEST:
      return resetPswPublicStart(state);
    case actionTypes.RESET_PSW_PUBLIC_SUCCESS:
      return resetPswPublicSuccess(state, action);
    case actionTypes.RESET_PSW_PUBLIC_FAILURE:
      return resetPswPublicFail(state, action);
    case actionTypes.RESET_PSW_LOGGED_REQUEST:
      return resetPswLoggedStart(state);
    case actionTypes.RESET_PSW_LOGGED_SUCCESS:
      return resetPswLoggedSuccess(state);
    case actionTypes.RESET_PSW_LOGGED_FAILURE:
      return resetPswLoggedFail(state);
    case actionTypes.RESET_PSW_LOGGED_RESET:
      return resetPswLoggedReset(state);
    case actionTypes.GET_COUNTRIES_START:
      return getCountriesStart(state);
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return getCountriesSuccess(state, action);
    case actionTypes.GET_COUNTRIES_FAILURE:
      return getCountriesFail(state, action);
    case actionTypes.GET_STATE_OF_RESIDENCE_FAILURE:
      return getStatesOfResidenceFail(state, action);
    case actionTypes.GET_STATE_OF_RESIDENCE_SUCCESS:
      return getStatesOfResidenceSuccess(state, action);
    case actionTypes.GET_STATE_OF_RESIDENCE_START:
      return getStatesOfResidenceStart(state);
    default:
      return state;
  }
};
export default reducer;
