import React, { Component, useEffect } from 'react';
import { Route } from "react-router-dom";
import { USER_URLS } from '@components/link-utils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useHistory } from "react-router-dom";

interface PublicRouteProps {
    component: any;
    lang: any;
}


const PublicRoute = (props: PublicRouteProps) => {

    // let ComponentChild = props.component;
    const { component: ComponentChild, ...rest } = props;
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isAuthenticated);
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            history.push(USER_URLS.HOMEPAGE.URL);
        }
    }, []);

    return (
        !isAuthenticated ?
            <Route
                {...rest}
                render={routeProps => (
                    <ComponentChild
                        {...routeProps}
                    />
                )}
            />
            : null
    )
}

export default PublicRoute;