import React, { ReactElement } from 'react';
import LazyImage from 'react-lazy-progressive-image';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import { handleClickL1Card } from '@redux-actions/';
import { L1Element } from '@model/L1Element';
import { handleOnEnterKeyPress, toSurnameCase } from '@utility/Api';
import CardCover from './CardCover';
import BrandPageTop from '../../pages_v3/BrandPageTop';
import ArrowRight from '@svg-icons/arrow-right.svg';
import { LangMap } from '@model/CoursesClass';
import { cmMetaData } from '@utility/previewUtils';

type CardL1Props = {
  l1: L1Element;
  tabIndex?: number;
  tabletVariant?: boolean;
  onClick?: () => void;
  beforeOnClickCard?: (l1, elementPosition) => void;
  typeHarvard?: string;
  elementPosition?: number
  dataElementId?: string;
  l1ContentId?: number;
};

const showBrandLogo = false;

const CardL1Big = (props: CardL1Props): ReactElement => {
  const { l1, elementPosition } = props;
  const dispatch = useDispatch();

  const lang = useSelector(state => state.utils.lang);
  const l1Map = useSelector(state => state.utils.l1Map);

  const handleClickCard = () => {
    if (props.beforeOnClickCard) {
      props.beforeOnClickCard(l1, elementPosition);
    }

    if (!props?.onClick) {
      dispatch(handleClickL1Card(l1.type, l1.value));
    }
    else{
      props?.onClick?.();
      dispatch(handleClickL1Card(l1.type, l1.value));
    }
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };
  // console.log('l1.value', l1.value)
  // console.log('l1Map[l1.value]', l1Map[l1.value]);
  const labelL0 = l1Map[l1.value]?.l0?.label;

  const browseByTopicInfo = useSelector(state => state.search.browseByTopicInfo?.info?.[l1?.value]);
  const channelInfos = l1.channelInfos ? l1.channelInfos : browseByTopicInfo;

  const hasMoreElementsSearch =
    !!channelInfos?.numberOfEvents ||
    channelInfos?.numberOfCourses ||
    !!channelInfos?.numberOfLessons;

  const hasMoreElements =
    !!l1.channelInfos?.numberOfEvents ||
    l1.channelInfos?.numberOfCourses ||
    !!l1.channelInfos?.numberOfLessons;

  if (props.tabletVariant) {
    return (
      <a
        className={
          'card-l1-evolution tablet-style' + (hasMoreElementsSearch ? ' has-more-elements' : '')
        }
        data-id={l1.type + '_' + l1.value}
        tabIndex={props.tabIndex}
        onClick={handleClickCard}
        onKeyDown={handleKeyDown}
        aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', l1.label)}
        {...props?.l1ContentId && cmMetaData(props?.l1ContentId)}
      >
        <div className="card-v3__wrapper" role="region" aria-label={l1.label}>
          <Iride />
          <CardL1Cover l1={l1} showBrandLogo={showBrandLogo} typeHarvard={props?.typeHarvard} />
        </div>
        <div className="card-l1-evolution__text-wrapper" aria-label={l1.label?.toUpperCase()}>
          {labelL0 && (
            <div className="card-l1-evolution__l0-label" aria-label={labelL0}>
              <span>{labelL0}</span>
            </div>
          )}
          {((l1.image && l1.showL1Label) || !showBrandLogo) && (
            <span className={'card-v3__fallback-text card-v3__l1-mychannel-text ' + l1.value}>
              {l1.label?.toUpperCase()}
            </span>
          )}
          {hasMoreElementsSearch && <CardL1MoreElements lang={lang} l1={{ ...l1, channelInfos }} />}
        </div>
      </a>
    );
  }

  return (
    <a
      className={'card-l1-evolution' + (hasMoreElements ? ' has-more-elements' : '')}
      data-element-id={props.dataElementId}
      tabIndex={props.tabIndex}
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      role='region'
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', l1.label)}
      {...props?.l1ContentId && cmMetaData(props?.l1ContentId)}
    >
      <div className={'card-v3__wrapper'} role="region" aria-label={l1.label}>
        {hasMoreElements && <CardL1MoreElements lang={lang} l1={l1} />}
        <Iride />
        {labelL0 && (
          <div className="card-l1-evolution__l0-label" role="region" aria-label={labelL0}>
            <span>{labelL0}</span>
          </div>
        )}
        <div className="card-l1-evolution__arrow">
          <ArrowRight fill="white" />
        </div>

        <div
          className={`topic-card__end-image ${l1.type === 'brand' && l1.value ? '' : 'channel'} ${l1.value
            }`}
        >
          {l1.type === 'brand' && showBrandLogo && l1.value && (
            <BrandPageTop
              brand={l1.value}
              category="brand"
              location={location}
              setSubCategoryTitle={() => { }}
              showTitle
              isNotBrandPage
            />
          )}
        </div>
        <CardL1Cover l1={l1} showBrandLogo={showBrandLogo} typeHarvard={props?.typeHarvard} />
        {((l1.image && l1.showL1Label) || !showBrandLogo) && (
          <span className={'card-v3__fallback-text card-v3__l1-mychannel-text ' + l1.value}>
            {l1.label?.toUpperCase()}
          </span>
        )}
      </div>
      {/* <section className="card-evolution__content mobile">
        {labelL0 && <p className="card-evolution__type">{labelL0}</p>}
        {l1.label && <p className="card-evolution__name">{l1.label}</p>}
      </section> */}
    </a>
  );
};

export default CardL1Big;

const Iride = () => (
  <div className="card-l1-evolution__iride">
    <LazyImage src={'/images/iride_x3.png'} placeholder="">
      {(src, loading) => <img className="icon" alt="iride" src={src} draggable={false} />}
    </LazyImage>
  </div>
);

const CardL1MoreElements = ({ lang, l1 }: { lang: LangMap; l1: L1Element }) => {
  return (
    <div className="moreElements">
      {l1.channelInfos?.numberOfCourses > 0 && l1.channelInfos?.numberOfCourses < 10 && (
        <div className="elementEvent">
          {toSurnameCase(
            lang?.EXPLORE_ALL_CHANNEL_INFO_COURSES?.replace(
              '{NumCourses}',
              l1.channelInfos?.numberOfCourses.toString()
            )
          )}
        </div>
      )}
      {l1.channelInfos?.numberOfCourses > 0 && l1.channelInfos?.numberOfCourses > 10 && (
        <div className="elementEventLong">
          {toSurnameCase(
            lang?.EXPLORE_ALL_CHANNEL_INFO_COURSES?.replace(
              '{NumCourses}',
              l1.channelInfos?.numberOfCourses.toString()
            )
          )}
        </div>
      )}
      {l1.channelInfos?.numberOfLessons > 0 && l1.channelInfos?.numberOfLessons < 10 && (
        <div className="elementLesson">
          {toSurnameCase(
            lang?.EXPLORE_ALL_CHANNEL_INFO_LESSONS?.replace(
              '{NumLessons}',
              l1.channelInfos?.numberOfLessons.toString()
            )
          )}
        </div>
      )}
      {l1.channelInfos?.numberOfLessons > 0 && l1.channelInfos?.numberOfLessons > 10 && (
        <div className="elementLessonLong">
          {toSurnameCase(
            lang?.EXPLORE_ALL_CHANNEL_INFO_LESSONS?.replace(
              '{NumLessons}',
              l1.channelInfos?.numberOfLessons.toString()
            )
          )}
        </div>
      )}
      {l1.channelInfos?.numberOfEvents > 0 && (
        <div className="elementLiveEvent">
          {toSurnameCase(
            lang?.EXPLORE_ALL_CHANNEL_INFO_EVENTS?.replace(
              '{NumEvents}',
              l1.channelInfos?.numberOfEvents.toString()
            )
          )}
        </div>
      )}
    </div>
  );
};

const CardL1Cover = ({ l1, showBrandLogo, typeHarvard = null }: { l1: L1Element; showBrandLogo: boolean, typeHarvard: string }) => {
  return (
    <CardCover
      image={l1.image}
      courseFullName={l1.alt || l1.label || l1.type + '_' + l1.value}
      fallbackImage={l1.fallbackImage}
      fallbackText={l1.type === 'brand' && showBrandLogo ? '' : l1.label?.toUpperCase()}
      hideShadow={true}
      variant="card-big"
      forceFallback={!l1.image ? true : false}
      typeHarvard={typeHarvard}
      contentId={l1?.imageContentId}
    />
  );
};
