import React from "react";
import { useSelector } from "@hooks/redux";
import { GetSubscriptionResponse, PlanModel, SubscriptionInfo, WCSDetail } from "@model/PlanModel";
import { formatCurrency, printDateDDMMYYYY } from "@utility/Api";
import { WalletItem } from "@model/WalletModel";
import { CARD_VALIDATION } from "@utility/const";
import ModalEvolution from "./ModalEvolution";

type ModalRenewalActivatedProps = {
  show: boolean;
  close: () => void;
  plan: PlanModel;
  subscription: WCSDetail;
  paymentInfo: WalletItem;
  boughtTimeEnded: string;
};

const ModalRenewalActivated = ({
  show,
  close,
  plan,
  subscription,
  paymentInfo,
  boughtTimeEnded,
}: ModalRenewalActivatedProps) => {
  const lang = useSelector((state) => state.utils.lang);



  const toReplace = useSelector(state => {
    const userProfile = state.user.userProfile;

    //const price = subscription?.totalCost?.value;
    const price = plan.price;

    return {
      DATE: printDateDDMMYYYY(
        boughtTimeEnded
      ),
      METHOD: Object.values(CARD_VALIDATION).find(p => p.payMethodId === paymentInfo?.payMethodId)?.type,
      LAST_4_DIGITS: paymentInfo?.creditCardPan,
      PRICE: formatCurrency(price, userProfile?.ecommerceInfo?.currency, userProfile?.preferredLang, false),
    }
  });

  const description = lang?.MODAL_AUTORENEWAL_ACTIVATED_DESCR?.replace(
    "{DATE}",
    toReplace.DATE.toString()
  )
    ?.replace("{DATE}", toReplace.DATE.toString())
    ?.replace("{METHOD}", toReplace.METHOD)
    ?.replace("{LAST_4_DIGITS}", toReplace.LAST_4_DIGITS)
    ?.replace("{PRICE}", toReplace.PRICE)
    ?.replace("{PRICE}", toReplace.PRICE);

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      modalClassName="modal-renewal-activated stop-renewal-confirmation"
      title={lang?.MODAL_AUTORENEWAL_ACTIVATED}
      description={description}
      mainButtonLabel={lang?.GOT_IT2}
      mainButtonAction={close}
    />
  );
};

export default ModalRenewalActivated;
