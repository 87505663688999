import { CMSBannerLink, InfoTortonaResponse } from '@model/CMSTortona';
import { useSelector } from '@hooks/redux';
import { useEffect, useState } from 'react';
import { Item } from '@model/CMSHeroBanner';
import { InfoTortona } from '@model/CMSTortona';
import { useIsEnabledForCollections } from './collections/useIsEnabledForCollections';

const TORTONA_CMS_TAG = 'tortona-sso:';

const useTortonaInfo = (
  useTortonaInfoParam?: boolean,
  tortonaInfoParam?: InfoTortonaResponse['data']
) => {
  //INFO FOR TORTONA
  //get tortonaInfoParam is present --> use it
  //if tortonaInfoParam is not present --> get info from redux state.cms.infoTortona
  const isTortonaError = useSelector(state =>
    useTortonaInfoParam ? false : state.cms.isInfoTortonaError
  );
  const isLoadingTortona = useSelector(state =>
    useTortonaInfoParam ? false : state.cms.isLoadingInfoTortona
  );
  const tortonaInfo: InfoTortonaResponse['data'] = useSelector(state =>
    useTortonaInfoParam ? tortonaInfoParam : state.cms.infoTortona
  );
  const isUserEnabledForCollections: boolean = useIsEnabledForCollections();

  const [resultTortona, setResultTortona] = useState(null);

  //USE EFFECT FOR TORTONA
  useEffect(() => {
    // fallback

    let items = tortonaInfo?.[0];
    let resultTortonaInfo = null;
    let action = undefined;

    if (items && (isUserEnabledForCollections || !hasTortonaCmsTag(items))) {
      const targetWindow = items?.extendedFields?.content1?.[0]?.extendedFields?.TargetWindow;
      const {linkUrl, linkContentId} = getCMSBannerLink(items);
      if (linkUrl) {
        action = () => window.open(linkUrl, targetWindow);
      }
      let finalAction = undefined;
      if (action) {
        finalAction = () => {
          //
          action();
        };
      }

      const imageDesktop = items?.extendedFields?.icon1?.[0]?.data;
      const imageMobile = items?.extendedFields?.icon2?.[0]?.data;
      const imageDesktopContentId = items?.extendedFields?.icon1?.[0]?.contentId;
      const imageMobileContentId = items?.extendedFields?.icon2?.[0]?.contentId;

      resultTortonaInfo = {
        title: items?.richtext1,
        description: items?.richtext2,
        titleAndDescriptionContentId: items?.contentId,
        video: items?.extendedFields?.content2?.[0]?.data,
        videoContentId: items?.extendedFields?.content2?.[0]?.contentId,
        image: imageDesktop,
        imageDesktopContentId: imageDesktopContentId,
        imageMobile: imageMobile || imageDesktop,
        imageMobileContentId: imageMobile ?  imageMobileContentId : imageDesktopContentId,
        labelAction: items?.extendedFields?.content1?.[0]?.title,
        labelActionContentId: items?.extendedFields?.content1?.[0]?.contentId,
        linkAction: finalAction,
        link: linkUrl,
        linkContentId: linkContentId,
      };
    }

    setResultTortona(resultTortonaInfo);
  }, [isLoadingTortona, isTortonaError, tortonaInfo]);

  return { resultTortona };
};

export const getCMSBannerLink = (item: Item | InfoTortona): CMSBannerLink => {
  let linkUrl = item?.extendedFields?.content1?.[0]?.extendedFields?.LinkURL;
  let linkContentId;
  if (hasTortonaCmsTag(item)) {
    linkUrl = item.extendedFields.content1[0].extendedFields.PageType[0].externalReference.replace(
      TORTONA_CMS_TAG,
      ''
    );
    linkContentId = item.extendedFields.content1[0].extendedFields.PageType[0]?.contentId
  }

  return {linkUrl, linkContentId};
};

export const hasTortonaCmsTag = (item: Item | InfoTortona): boolean => {
  return item?.extendedFields?.content1?.[0]?.extendedFields?.PageType?.[0]?.externalReference?.startsWith(
    TORTONA_CMS_TAG
  );
};

export default useTortonaInfo;
