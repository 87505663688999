import React, { CSSProperties } from "react";
import RatingIcon from "@svg-icons/stellina-rating.svg";
import { handleOnEnterKeyPress } from "@utility/Api";
import clsx from "@utility/clsx";

export type ItemRadioButton = {
  label: string;
  value: string | number;
  checked: boolean;
};

export type Props = {
  handleOnClick: (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
  value: string | number;
  checked: boolean;
  labelText?: string;
  styleLabel?: CSSProperties;
  positionLabel?: "right" | "bottom";
  fontSize?: string;
  hideLabel?: boolean,
  readOnly?: boolean,
  iconBig?: boolean,
  htmlName?: string,
  showCircle?: boolean;
  disabled?: boolean;
};



const RadioButton = ({
  handleOnClick,
  checked,
  fontSize = "1em",
  labelText,
  styleLabel,
  positionLabel = "bottom",
  hideLabel = false,
  readOnly = false,
  iconBig = false,
  htmlName = "radio",
  showCircle = false,
  disabled = false,
}: Props) => {

  return (
    <span className={clsx("radio__input",
      {
        "radio__input--read-only": readOnly,
        "checked": checked
      }
    )}>
      <input
        type="radio"
        name={htmlName}
        aria-label={htmlName}
        tabIndex={-1}
        // id="radio-button" id not unique
        value={labelText}
        onClick={handleOnClick}
      />
      {showCircle
        ?
        <span
          className={clsx("radio__control focus-outline",
            {
              "checked": checked,
              "unchecked": !checked,
              "disabled" : disabled,
            }
          )}
          tabIndex={!readOnly ? 0 : -1}
          aria-label={labelText}
          onKeyDown={(event) => handleOnEnterKeyPress(event, handleOnClick)}
        ></span>
        :
        <span className={clsx("star-icon focus-outline",
          {
            "star-icon--big": iconBig,
            "checked": checked,
            "unchecked": !checked,
          }
        )}
          tabIndex={!readOnly ? 0 : -1}
          onKeyDown={(event) => handleOnEnterKeyPress(event, handleOnClick)}
        >
          <RatingIcon />
        </span>
      }

    </span>
  );
};

export default RadioButton;
