import { Course, CoursesMap, LangMap, LangMapEcomm, LangMapNotif } from "@model/CoursesClass";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { ProductCart } from "@model/CartModel";
import { ADD_TO_CART_STEPS } from "@utility/const";
import { formatCurrency, getParentLPPurchasable } from "@utility/Api";
import { useSelector } from "@hooks/redux";
import { UserProfile } from "@model/User";
import ModalEvolution from "./ModalEvolution";
import ButtonV2 from "@components/UI/ButtonV2";

type ModalLessonPartCourseProps = {
  show: boolean;
  close: () => void;
};

const ModalLessonPartCourse = ({
  show,
  close,
}: ModalLessonPartCourseProps) => {
  const dispatch = useDispatch();

  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
  const langNotif: LangMapNotif = useSelector((state) => state.utils.langNotif);
  const userProfile: UserProfile = useSelector((state) => state.user.userProfile);
  const product: ProductCart = useSelector((state) => state.ecommerce.productModalLessonPartCourse);
  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);

  const [parentLPs, setParentLPs] = useState<Course[]>([]);

  useEffect(() => {
    setParentLPs(getParentLPPurchasable(product?.partNumber, coursesMap));
  }, [product, coursesMap]);

  const handleBuyCourse = (courseId) => {
    close();

    //open course detail
    dispatch(actions.toggleModalCourseById(courseId));
  };

  const handleBuyLesson = () => {
    close();

    //proceed to add to cart
    dispatch(actions.insertProductInCart(new ProductCart(product.partNumber, product.quantity), ADD_TO_CART_STEPS.LESSON_PART_COURSE));
  };

  return (
    <ModalEvolution
      close={close}
      show={show}
      modalClassName="lesson-part"
      icon={'/images/svg-icons/info.svg'}
      title={lang?.LESS_PART_COURSE}
      description={lang?.LESS_PART_COURSE_DESCR}
      footerElement={
        <>
          <div className="courses-list">
            {parentLPs.map((course) => (
              <div className="course-item" key={course.courseIdMaster}>
                <p className="course-title">{course.courseFullName}</p>
                <p className="course-price">
                  {formatCurrency(
                    course.price,
                    userProfile?.ecommerceInfo?.currency,
                    userProfile?.preferredLang
                  )}
                </p>
                <ButtonV2
                  variant="primary"
                  small
                  className="with-100 cta"
                  handleClick={() => handleBuyCourse(course.courseIdMaster)}
                >
                  {langNotif?.GO_TO_LP}
                </ButtonV2>
              </div>
            ))}
          </div>
          <span className="or-label">{lang?.OR}</span>
          <ButtonV2 variant="secondary" small handleClick={() => handleBuyLesson()}>
            {lang?.BUY_SINGLE_LESSON_LABEL}
          </ButtonV2>
        </>
      }
    />
  );
};

export default ModalLessonPartCourse;
