import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React from "react";
import { useSelector } from "@hooks/redux";
import ModalEvolution from "@components/UI/ModalEvolution";

type ModalContentExpirationProps = {
  show: boolean;
  close: () => void;
};

const ModalContentExpiration = ({
  show,
  close,
}: ModalContentExpirationProps) => {
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);

  return (
    <ModalEvolution
      close={close}
      show={show}
      hideX={true}
      icon={'/images/svg-icons/calendar-2.svg'}
      title={lang?.CONTENT_EXPIRATION_MODAL_TITLE}
      description={lang?.CONTENT_EXPIRATION_MODAL_MESSAGE}
      mainButtonLabel={lang?.GOT_IT2}
      mainButtonAction={close}
    />
  );
};

export default ModalContentExpiration;
