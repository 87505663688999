export default {
    NOTIF_NEW_COURSE_TITLE: "",
    NOTIF_NEW_COURSE_DESCR: "",
    NOTIF_NEW_COURSE_ASSIGNED_TITLE: "",
    NOTIF_NEW_COURSE_ASSIGNED_DESCR: "",
    NOTIF_NEW_COURSE_ASSIGNED_DESCR2: "",
    NOTIF_COURSE_OVERDUE_TITLE: "",
    NOTIF_COURSE_OVERDUE_DESCR: "",
    NOTIF_RECORDING_AVAILABLE_TITLE: "",
    NOTIF_RECORDING_AVAILABLE_DESCR: "",
    NOTIF_BOOK_COMPLETE_TITLE: "",
    NOTIF_BOOK_COMPLETE_DESCR: "",
    NOTIF_SESSION_DATE_MODIFIED_TITLE: "",
    NOTIF_SESSION_DATE_MODIFIED_DESCR: "",
    NOTIF_NEW_SESSION_TITLE: "",
    NOTIF_NEW_SESSION_DESCR: "",
    NOTIF_SESSION_CANCEL_TITLE: "",
    NOTIF_SESSION_CANCEL_DESCR: "",
    NOTIF_BOOK_CANCEL_TITLE: "",
    NOTIF_BOOK_CANCEL_DESCR: "",
    NOTIF_WAIT_LIST_OPEN_TITLE: "",
    NOTIF_WAIT_LIST_OPEN_DESCR: "",
    NOTIF_WAIT_LIST_CLOSE_TITLE: "",
    NOTIF_WAIT_LIST_CLOSE_DESCR: "",
    NOTIF_BOOK_CONFIRM_TITLE: "",
    NOTIF_BOOK_CONFIRM_DESCR: "",
    NOTIF_COURSE_COMPLETED_TITLE: "",
    NOTIF_COURSE_COMPLETED_DESCR: "",
    NOTIF_PROFILE_UPDATED_TITLE: "",
    NOTIF_PROFILE_UPDATED_DESCR: "",
    NOTIF_NEW_COURSE_MATERIAL_TITLE: "",
    NOTIF_NEW_COURSE_MATERIAL_DESCR: "",
    NOTIF_COURSE_EXPIRING_TITLE: "",
    NOTIF_COURSE_EXPIRING_DESCR: "",
    NOTIF_TEAM_EXPIRING_ASSIGNMENTS_TITLE: "",
    NOTIF_TEAM_EXPIRING_ASSIGNMENTS_DESCR: "",
    NOTIF_COURSE_COMPLETE_TITLE: "",
    NOTIF_COURSE_COMPLETE_DESCR: "",
    NOTIF_SESSION_START_TITLE: "",
    NOTIF_SESSION_START_DESCR: "",
    NOTIF_SESSION_MISSED_TITLE: "",
    NOTIF_SESSION_MISSED_DESCR: "",
    NOTIF_CERTIFICATE_AVAILABLE_TITLE: "",
    NOTIF_CERTIFICATE_AVAILABLE_DESCR: "",
    NOTIF_COURSE_COMPLIANCE_EXPIRING_TITLE: "",
    NOTIF_COURSE_COMPLIANCE_EXPIRING_DESCR: "",
    NOTIF_LP_COMPLETED_TITLE: "",
    NOTIF_LP_COMPLETED_DESCR: "",
    NOTIF_REPORT_MANAGER_UPDATED_TITLE: "",
    NOTIF_REPORT_MANAGER_UPDATED_DESCR: "",
    NOTIF_COURSE_PENDING_TITLE: "",
    NOTIF_LESSON_PENDING_TITLE: "",
    NOTIF_COURSE_PENDING_DESCR: "",
    NOTIF_MONTHLY_ACHIEVEMENT_TITLE: "",
    NOTIF_MONTHLY_ACHIEVEMENT_DESCR: "",
    NOTIF_ACTION_GO_COURSE: "",
    NOTIF_ACTION_GO_VC: "",
    NOTIF_ACTION_DOWNLOAD_CALENDAR: "",
    NOTIF_ACTION_GO_ASSIGNMENT: "",
    NOTIF_ACTION_GO_MY_TEAM: "",
    NOTIF_ACTION_GO_CATALOG: "",
    NOTIF_ACTION_GO_REPORT: "",
    NOTIF_RATING_TITLE: "",
    NOTIF_RATING_DESCR: "​",
    NOTIF_ACTION_RATING: "",
    NOTIF_WISH_EXPIRING_TITLE: "",
    NOTIF_WISH_EXPIRING_DESCR: "",
    NOTIF_WISH_AVAILABILITY_TITLE: "",
    NOTIF_WISH_AVAILABILITY_DESCR: "",
    NOTIF_ACTION_BOOKING: "",
    NOTIF_LEADERBOARD_IN_TITLE: "",
    NOTIF_LEADERBOARD_IN_DESC: "",
    NOTIF_ACTION_GO_LEADERBOARD: "",
    NOTIF_LEADERBOARD_OUT_TITLE: "",
    NOTIF_LEADERBOARD_OUT_DESC: "",
    NOTIF_NEW_LP_ASSIGNED_TITLE: "",
    NOTIF_NEW_LP_ASSIGNED_DESCR: "",
    NOTIF_LP_OVERDUE_TITLE: "",
    NOTIF_LP_OVERDUE_DESCR: "",
    NOTIF_LP_EXPIRING_TITLE: "",
    NOTIF_LP_EXPIRING_DESCR: "",
    NOTIF_TEAM_EXPIRING_ASSIGNMENTS_LP_TITLE: "",
    NOTIF_TEAM_EXPIRING_ASSIGNMENTS_LP_DESCR: "",
    NOTIF_RATING_LP_TITLE: "",
    NOTIF_RATING_LP_DESCR: "",
    RATE_LP: "",
    GO_TO_LP: "",
    NOTIF_LIVESTREAM_STARTING_TITLE: "",
    NOTIF_LIVESTREAM_STARTING_DESC: "",
    NOTIF_LIVESTREAM_JOIN_TITLE: "",
    NOTIF_LIVESTREAM_JOIN_DESC: "",
    NOTIF_LIVESTREAM_RECORDING_TITLE: "",
    NOTIF_LIVESTREAM_RECORDING_DESC: "",
    NOTIF_ACTION_LIVESTREAM_JOIN: "",
    NOTIF_ACTION_RECORDING_LAUNCH: "",
    NOTIF_NEW_CONTENT_TP_TITLE: "",
    NOTIF_NEW_CONTENT_TP_DESC: "",
    NOTIF_ACTION_NEW_CONTENT_TP: "",
    NOTIF_NEW_VC_ASSIGNED_TITLE: "",
    NOTIF_NEW_VC_ASSIGNED_DESCR: "",
    NOTIF_TOKEN_ACTIVATED_TITLE: "",
    NOTIF_TOKEN_ACTIVATED_DESCR: "",
    NOTIF_TOKEN_ACTIVATED_ACTION: "",
    ONBOARDING_OVERDUE_MANAGER_TITLE: "",
    ONBOARDING_OVERDUE_MANAGER_DESC: "",
    ONBOARDING_ACTION_OVERDUE_MANAGER: "",
    COURSE_OVERDUE_MANAGER_TITLE: "Course Overdue",
    COURSE_OVERDUE_MANAGER_DESC: "The course {coursetitle} of your team member is now past its deadline",
    NOTIF_COURSE_COMPLETE_CERT_DESCR: "",
    NOTIF_LP_COMPLETED_CERT_DESCR: "",
}
