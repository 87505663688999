import React from 'react';
import { HTMLMotionProps, motion, Variants } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export type Props = {
  children: React.ReactNode;
  className?: string;
  staggerChildren?: number;
  inViewCallback?: () => void;
  props: HTMLMotionProps<'div'>;
};

const StaggerChildren = ({
  children,
  className = '',
  staggerChildren = 0.2,
  inViewCallback,
  ...props
}) => {
  const staggeredRef = React.useRef(false);
  const parentVariants: Variants = {
    center: {
      transition: {
        staggerChildren: staggerChildren,
      },
    },
  };

  const { ref, inView } = useInView({});

  React.useEffect(() => {
    if (inView) {
      staggeredRef.current = true;

      if (inViewCallback) {
        inViewCallback();
      }
    }
  }, [inView]);

  return (
    <motion.div
      ref={ref}
      variants={parentVariants}
      animate={inView || staggeredRef.current ? 'center' : 'enter'}
      className={className}
      {...props}
    >
      {(inView || staggeredRef.current) && children}
    </motion.div>
  );
};

StaggerChildren.displayName = 'StaggerChildren';

export default StaggerChildren;
