import React from "react";

export enum EAlign {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}


export type TAlign = `${EAlign}`; // 'RED' | 'GREEN' | 'BLUE'

interface AlignmentProps {
  alignment?: TAlign;
  children: React.ReactNode;
}


const Align = (props : AlignmentProps) => {
  const alignmentClass = `alignment-${props.alignment || EAlign.LEFT}`;

  return (
    <div className={`flex ${alignmentClass}`}>
      {props.children}
    </div>
  );
}

export default Align;

