import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    // resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: ["en", "it", "es", "pt_br", "zh_cn", "fr"],
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
