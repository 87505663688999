import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import useL1List from '@hooks/useL1List';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import ChevronLeft from '@images/svg-icons/chevron-left-header.svg';
import useModalTypes from '@hooks/useModalByType';
import { AnimatePresence, motion } from 'framer-motion';
import MenuAreaOfExpertiseL1Mobile from './MenuAreaOfExpertiseL1Mobile';
import { getBrandPageUrl, isCourseVisible, isHighlighted } from '@utility/Api';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { Course } from '@model/CoursesClass';
import CardController from '@components/CardV3';
import { HighlightsThirdColumn } from '@components/collections/CollectionsMenu';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';

const l1VisionCare = "vision_care";
const variants = {
  initial: {
    x: '90vw',
  },
  enter: {
    x: '0',
  },
  exit: {
    x: '100vw',
  },
};

const MenuVisionCareMobile = ({ show, fixed = false, isLight }) => {
  const lang = useSelector(state => state.utils.lang);
  const modalTypes = useModalTypes();
  const coursesMap = useSelector(state => state.course.coursesMap);
  const [coursesToShow, setCoursesToShow] = useState([]);
  const [l1List, l0list, _, __, loadingL1List] = useL1List('', true);

  const closeModal = () => {
    modalTypes.close();
  };


  // Effect to update courses to show based on selected criteria
  useEffect(() => {
    let coursesToShowTemp = [];

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === l1VisionCare);

      // Sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }

    setCoursesToShow(coursesToShowTemp);
  }, [ coursesMap]);

  const handleClickBackButton = () => {
    modalTypes.menuRightModal.open();
  };

  return (
    show && (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        transition={{ ease: 'easeInOut', duration: 0.4 }}
        variants={variants}
        className={'menu-aoe-mobile' + (isLight ? "" : " dark-variant-mobile")}
      >
        {!modalTypes.areaOfExpertiseL1Menu.show &&
          <div className={'menu-aoe-mobile__content absolute ' + (fixed ? 'fixed' : '')}>
            <header onClick={handleClickBackButton}>
              <button
                className="icon-button back-button header-v3__back-button focus-outline mobile"
                title={lang?.GO_BACK}
                aria-label={lang?.GO_BACK}
                onClick={handleClickBackButton}
                //autoFocus
              >
                <ChevronLeft />
              </button>
              {lang.VISION_CARE_TITLE}
            </header>
            <section className="menu-aoe-mobile__container" aria-label={lang.VISION_CARE_TITLE}>
              <div className="menu-aoe-mobile__l0-list">
                {l1List[l1VisionCare]?.map((l1,index) => (
                  <React.Fragment key={`${index}_${l1.value}`}>
                    <ButtonV2
                      className={'menu-aoe-mobile__l1-list'}
                      to={getBrandPageUrl("channel", l1.value)}
                      key={`${l1.value}`}
                      handleClick={()=>{buildClickDataTrackingObject("mainNav_"+"visionCare_"+camelCase(l1.label))}}
                    >
                      {l1.label}
                    </ButtonV2>
                  </ React.Fragment>
                ))}
              </div>
            </section>
            {coursesToShow?.length === 3 && (
            <div className="menu-aoe-mobile__bottom-section__threeCards">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
          {coursesToShow?.length === 2 && (
            <div className="menu-aoe-mobile__bottom-section__twoCards">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
          {coursesToShow.length !== 3 && coursesToShow.length !== 2 && (
            <div className="menu-aoe-mobile__bottom-section">
              <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
            </div>
          )}
          </div>
        }
      </motion.div>
    )
  );
};
const ThirdColumn = ({
  courses,
  isDesktop,
  closeModal,
}: {
  courses: Course[];
  isDesktop: boolean;
  closeModal: () => void;
}) => {

  return(
    <>
      {courses?.map((course: Course) => (
        <CardController
          course={course}
          variant={'card-areaofexpertise'}
          key={course.courseId}
          elementPosition={0}
          clickObjectTracking={'visionCare'}
        />
      ))}
    </>
  );
}
      

export default MenuVisionCareMobile;
