import { LearningPath } from "@components/LearningPath/LPCardInterface";
import { Course, CoursesMap, LPCourseSort } from "@model/CoursesClass";
import { enrichChildInfo, getSortDateTodo, isCompletedCourse, isCourseInLPDisabled, isCourseToBeStarted, isLearningPath, isMissedCourse, isVCInTheFuture, isVirtualClassroom } from "./Api";


/* with type guard */
export function isLearningPathType<T>(course: T | LearningPath): course is LearningPath {
    return isLearningPath(course);
};

export const getLpCourses = (lp: Course, coursesMap: CoursesMap): Array<Course> => {
    if (!lp || !coursesMap) {
        return [];
    }

    let courses = [];
    lp.learningPath.forEach(courseLpSort => {
        const courseId = courseLpSort.parentId ? courseLpSort.parentId : courseLpSort.courseId;
        if (coursesMap[courseId]) {
            const parent = coursesMap[courseId];
            let child = parent;

            //get child
            if (courseLpSort.parentId) {
                const children = parent.childCourses?.filter(a => a.childId === courseLpSort.courseId)
                if (children?.length > 0) {
                    child = enrichChildInfo(parent, child);
                }
            }

            courses.push(child);
        }
    });
    // console.log('LP courses', courses)

    return courses;
}

export const getLpCoursesId = (lp: Course | LearningPath): string[] => {
    if (!lp) {
        return [];
    }

    const courseIds = [];
    lp.learningPath.forEach(courseLp => {
        if (courseLp.courseId) {
            courseIds.push(courseLp.courseId);
        }
        if (courseLp.parentId) {
            courseIds.push(courseLp.parentId);
        }
    });

    return courseIds;
}

export const getAllLpCoursesId = (courses: Array<LearningPath | Course>): string[] => {
    if (!courses) {
        return [];
    }
    let coursesIdFromLPs: string[] = [];
    courses.forEach(course => {
        if (isLearningPathType(course)) {
            const ids = getLpCoursesId(course);
            coursesIdFromLPs = [...coursesIdFromLPs, ...ids];
        }
    });

    return coursesIdFromLPs;
}

export const getLpChannels = (lp: Course, coursesMap: CoursesMap): Array<string> => {
    let lpCourses = getLpCourses(lp, coursesMap);

    let channels = [];
    lpCourses.forEach(course => {
        if (course.catalogTypes) {
            course.catalogTypes
                .forEach(channel => {
                    if (!channels.includes(channel)) {
                        channels.push(channel);
                    }
                });
        }
    });
    return channels;
}

export const lpsContainsCourses = (courses: Array<Course>, lps: Array<Course>) => {
    let lpsFiltered = [];
    if (lps.length > 0) {
        const courseIds = courses.map(a => a.courseIdMaster);
        lpsFiltered = lps.filter(lp => {
            let isLPBrand = false;
            lp.learningPath.forEach((courseInLP) => {
                if (courseIds.includes(courseInLP.courseId)) {
                    isLPBrand = true;
                }
            });

            return isLPBrand;
        });
    }

    return lpsFiltered;
}

export const getBlockingLps = (lp: Course, coursesMap: CoursesMap) => {
    if (!lp || !isLearningPath(lp) || !lp.blockedby || lp.blockedby.length < 1 || !coursesMap) {
        return [];
    }

    let blockingLps: Course[] = [];
    for (const blockingLpId of lp.blockedby) {
        const blockingLp = coursesMap[blockingLpId];
        if (blockingLp && !isCompletedCourse(blockingLp)) {
            blockingLps.push(blockingLp);
        }
    }

    return blockingLps;
}

export const isLpBlocked = (lp: Course, coursesMap: CoursesMap) => {
    return getBlockingLps(lp, coursesMap).length > 0;
}

export const isLearningPathContainingVCInFuture = (lp: Course, coursesMap: CoursesMap) => {
    if (!lp || !isLearningPath(lp) || !coursesMap) {
        return false;
    }

    const lessonsWithinLp = getLpCourses(lp, coursesMap);
    for (const lesson of lessonsWithinLp) {
        if (isVCInTheFuture(lesson)) {
            return true;
        }
    }

    return false;
}

export const getLearningPathMinStartDateVC = (lp: Course, coursesMap: CoursesMap) => {
    if (!lp || !isLearningPath(lp) || !coursesMap) {
        return '';
    }

    const lessonsWithinLp = getLpCourses(lp, coursesMap);
    let minStartDate: string = '';
    for (const lesson of lessonsWithinLp) {
        if (isVirtualClassroom(lesson)) {
            const minStartDateVC = getSortDateTodo(lesson);
            if (!minStartDate || minStartDateVC < minStartDate) {
                minStartDate = minStartDateVC;
            }
        }
    }

    return minStartDate;
}


export const isLessonNotOptionalForLP = (lessonInLp: LPCourseSort) => {
    return !!lessonInLp.mandatory || lessonInLp.mandatory === undefined;
}

export const isStartedLp = (lp, coursesMap) => {
    const lpCpurses = getLpCourses(lp, coursesMap);
    let arrayStart = [];
    if (lpCpurses) {
        lpCpurses.forEach(course => {
            arrayStart.push(isCourseToBeStarted(course))
        });
    }
    if (arrayStart.includes(false)) {
        return true;
    } else return false;
}

export const addIsNotYetAvailableAttribute = (course: Course, lp: Course, coursesMap: CoursesMap) => {
    if (!course || !lp || !coursesMap) {
        return course;
    }

    const learningPathInfo = lp.learningPath.find(a => a.parentId === course.courseIdMaster || a.courseId === course.courseId);
    course.isNotYetAvailable = isCourseInLPDisabled(
        learningPathInfo,
        coursesMap
    );

    return cloneDeep(course);
}