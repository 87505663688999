import { SKELETON_COLORS } from "@utility/const";
import React from "react"
import ContentLoader from "react-content-loader";

const width = 322;
const height = 106;

const CardSmallSkeleton = (props) => {
  return (
    <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }} >
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
        {...props}
      >
        <rect x="0" y="0" rx="12" ry="12" width={width} height={height} />
      </ContentLoader>
    </div >
  )
}

export default CardSmallSkeleton