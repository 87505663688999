import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import useL1List from '@hooks/useL1List';
import ButtonV2 from '@components/UI/ButtonV2';
import LazySVG from '@components/LazySvg';
import ChevronLeft from '@images/svg-icons/chevron-left-header.svg';
import useModalTypes from '@hooks/useModalByType';
import { AnimatePresence, motion } from 'framer-motion';
import MenuAreaOfExpertiseL1Mobile from './MenuAreaOfExpertiseL1Mobile';
import { isCourseVisible, isHighlighted } from '@utility/Api';
import { multipleSortCourses } from '@utility/sortUtility';
import { SORTBY } from '@utility/const';
import { Course } from '@model/CoursesClass';
import CardController from '@components/CardV3';
import { HighlightsThirdColumn } from '@components/collections/CollectionsMenu';
import { URL_CTA_PODCAST_SECOND_COLUMN } from '@components/AreaOfExpertiseModal';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';
import lang from '@utility/lang';

const variants = {
  initial: {
    x: '90vw',
  },
  enter: {
    x: '0',
  },
  exit: {
    x: '100vw',
  },
};

const MenuAreaOfExpertiseMobile = ({ show, fixed = false, isLight }) => {
  const lang = useSelector(state => state.utils.lang);
  const modalTypes = useModalTypes();
  const coursesMap = useSelector(state => state.course.coursesMap);
  const [coursesToShow, setCoursesToShow] = useState([]);
  const [l0, setl0] = useState(null);
  const [l1List, l0list, _, __, loadingL1List] = useL1List('', true);
  const userProfile = useSelector(state => state.user.userProfile);

  let coursesToShowTemp = [];

  const closeModal = () => {
    modalTypes.close();
  };


  useEffect(() => {
    let coursesToShowTemp = [];

    if (coursesMap) {
      coursesToShowTemp = Object.values(coursesMap).filter(
        a => isCourseVisible(a) && isHighlighted(a)
      );

      /*if (selectedL0) {
        //filter by L0 if selected
        coursesToShowTemp = coursesToShowTemp.filter(a => a.level0 === selectedL0);
      } else {
        //exclude l0 with order > 100
        let listOfCode = l0list?.map(a => a?.code);
        coursesToShowTemp = coursesToShowTemp.filter(a => listOfCode?.includes(a?.level0));
      }*/

      //sort by start date, if same start date --> sort by title
      coursesToShowTemp = multipleSortCourses(coursesToShowTemp, [
        SORTBY.TITLE_AZ,
        SORTBY.START_DATE,
      ]).slice(0, 3);
    }

    //console.log(coursesToShowTemp);

    setCoursesToShow(coursesToShowTemp);
  }, [ coursesMap]);

  const handleClickBackButton = () => {
    modalTypes.menuRightModal.open();
  };

  const handleClickL0 = (l0Id: string) => {
    modalTypes.areaOfExpertiseL1Menu.open(l0Id);
    setl0(l0Id);
  };

  return (
    show && (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        transition={{ ease: 'easeInOut', duration: 0.3 }}
        variants={variants}
        className={'menu-aoe-mobile' + (isLight ? '' : ' dark-variant-mobile')}
      >
        {!modalTypes.areaOfExpertiseL1Menu.show && (
          <div className={'menu-aoe-mobile__content absolute ' + (fixed ? 'fixed' : '')}>
            <header onClick={handleClickBackButton}>
              <button
                className="icon-button back-button header-v3__back-button focus-outline mobile"
                title={lang?.GO_BACK}
                aria-label={lang?.GO_BACK}
                onClick={handleClickBackButton}
                //autoFocus
              >
                <ChevronLeft />
              </button>
              {lang.AREAS_OF_EXPERTISE}
            </header>
            <section className="menu-aoe-mobile__container" aria-label={lang.AREAS_OF_EXPERTISE}>
              <div className="menu-aoe-mobile__l0-list">
                {l0list
                  ?.filter(item => item?.code !== 'vision_care')
                  .map(l0 => (
                    <>
                      <ButtonV2
                        className={'menu-aoe-mobile__l1-list'}
                        handleClick={() =>{buildClickDataTrackingObject("mainNav_"+camelCase(lang.AREAS_OF_EXPERTISE)+"_"+camelCase(l0.label));handleClickL0(l0.code)}}
                        key={l0.code}
                      >
                        {l0.label}
                        <LazySVG
                          className="area-of-expertise__l1-channel-arrow"
                          src={'/images/svg-icons/arrow-right5.svg'}
                          alt={l0.label}
                        />
                      </ButtonV2>
                    </>
                  ))}
                {userProfile.menuEntryPoint?.podcast &&(
                  <ButtonV2
                    className={'menu-aoe-mobile__l1-list'}
                    to={URL_CTA_PODCAST_SECOND_COLUMN}
                    key={lang.PODCAST_HEADER}
                    handleClick={() =>{buildClickDataTrackingObject("mainNav_"+camelCase(lang.AREAS_OF_EXPERTISE)+"_"+camelCase(lang.PODCAST_HEADER))}}
                  >
                    {lang.PODCAST_HEADER}
                    <LazySVG
                      className="area-of-expertise__l1-channel-arrow"
                      src={'/images/svg-icons/arrow-right5.svg'}
                      alt={lang.PODCAST_HEADER}
                    />
                  </ButtonV2>
                )}
              </div>
            </section>
            {coursesToShow?.length === 3 && (
              <div className="menu-aoe-mobile__bottom-section__threeCards">
                <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal}/>
              </div>
            )}
            {coursesToShow?.length === 2 && (
              <div className="menu-aoe-mobile__bottom-section__twoCards">
                <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
              </div>
            )}
            {coursesToShow.length !== 3 && coursesToShow.length !== 2 && (
              <div className="menu-aoe-mobile__bottom-section">
                <ThirdColumn courses={coursesToShow} isDesktop={true} closeModal={closeModal} />
              </div>
            )}
          </div>
        )}
        <AnimatePresence>
          {modalTypes.areaOfExpertiseL1Menu.show && (
            <MenuAreaOfExpertiseL1Mobile show={modalTypes.areaOfExpertiseL1Menu.show} />
          )}
        </AnimatePresence>
      </motion.div>
    )
  );
};
const ThirdColumn = ({
  courses,
  isDesktop,
  closeModal,
}: {
  courses: Course[];
  isDesktop: boolean;
  closeModal: () => void;
}) =>
  isDesktop ? (
    <>
      {courses?.map(course => (
        <CardController
          course={course}
          variant={'card-areaofexpertise'}
          key={course.courseIdMaster} elementPosition={0}
          clickObjectTracking={camelCase("allContent")} />
      ))}
    </>
  ) : (
    <HighlightsThirdColumn courses={courses} closeModal={closeModal} />
  );
export default MenuAreaOfExpertiseMobile;
