import { useState, useEffect, Dispatch } from 'react';
import { useSelector } from './redux';
import { Course, CoursesMap, LevelEnrichedFE, LevelEnrichedMap } from '@model/CoursesClass';
import useL0 from './useL0';
import { getL0Type, getL1ImageChipLarge, getL1Type } from '@utility/levelUtility';
import { getFallbackImage } from '@utility/Api';
import { EBuildYourCareerLevelsL1 } from '@utility/buildYourCareerUtility';
import {LEVELS_AREA, ctype} from "@utility/const";
import { useL0BycOffice } from './buildYourCareer/useL0BycOffice';

export type ExtendedL1Element = LevelEnrichedFE & {
  image2?: string;
  l0: string;
  isFollowed?: boolean;
};

function sortByLabel(a, b) {
  // Use toUpperCase() to ignore character casing
  const labelA = a?.label?.toUpperCase();
  const labelB = b?.label?.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}

const useL1List = (
  initialL0 = null,
  isAreaOfExpertise = undefined
): [
  l1list: Record<string, ExtendedL1Element[]>,
  l0ToPrint: LevelEnrichedFE & { code: string; type: string; image?: string }[],
  selectedL0: string,
  setSelectedL0: Dispatch<string>,
  loading: boolean
] => {
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const isGetCourseCompleted = useSelector(state => state.course.isGetCourseCompleted);
  const isGetBrandsCompleted = useSelector(state => state.utils.isGetBrandsCompleted);

  const l1Map: LevelEnrichedMap = useSelector(state => state.utils.labelL1Map);

  const L1Images: any[] = useSelector(state => state.cms.L1Images);
  const [channelsByL0] = useL0();

  // console.log('channelsByL0', channelsByL0);

  const [selectedL0, setSelectedL0] = useState<string>(initialL0);

  const [l1ToPrint, setL1ToPrint] = useState<ExtendedL1Element[]>([]);
  const [l0ToPrint, setL0ToPrint] = useState<LevelEnrichedFE[]>([]);

  useEffect(() => {
    let newMyL1List = new Array<ExtendedL1Element>();
    let newMyL0List = new Array<LevelEnrichedFE & { code: string; type: string; image?: string }>();
    let numFallbackImage: number = 4;

    //get followed channels with at least one course associated to that channel
    let channels = channelsByL0;

    if (channels?.length > 0) {
      if (channels?.length > 0) {
        // console.log(`channels`, channels)
        channels.forEach(a => {
          newMyL0List.push({ code: a.code, type: getL0Type(a.code), ...a });
          a.l1?.forEach(b => {
            newMyL1List.push({
              type: getL1Type(b.key),
              value: b.key,
              label: b.label,
              fallbackImage: getFallbackImage((numFallbackImage % 4) + 1),
              l0: a.code,
              imageChipLarge: getL1ImageChipLarge(b.key),
            });
            numFallbackImage++;
          });
        });
      }
    }

    // console.log(`L1Images`, L1Images);

    const l0Images: Record<string, string> = {};

    if (L1Images && newMyL1List.length > 0) {
      for (const newMyL1 of newMyL1List) {
        if (L1Images[newMyL1.value]) {
          newMyL1.image = L1Images[newMyL1.value].img;
          newMyL1.image2 = L1Images[newMyL1.value].img;
          newMyL1.alt = L1Images[newMyL1.value].alt;
          newMyL1.showL1Label = L1Images[newMyL1.value].showL1Label;
          newMyL1.order = L1Images[newMyL1.value].order;
          if (L1Images[newMyL1.value].img && !l0Images[newMyL1.l0] && newMyL1.l0 !== 'brands') {
            l0Images[newMyL1.l0] = L1Images[newMyL1.value]?.img;
          }
        }
      }
    }

    // console.log('l0Images', l0Images);
    //sort L1 and L0
    newMyL1List.sort((a, b) => a.order - b.order);
    newMyL0List = newMyL0List
      .filter(a => {
        if (isAreaOfExpertise === undefined) {
          return a;
        }
        if (isAreaOfExpertise) {
          return a.area === LEVELS_AREA.EDUCATIONAL_CATALOG || a.area === LEVELS_AREA.EDUCATIONAL_CATALOG_BYC;
        } else {
          return a.area == LEVELS_AREA.BRANDS;
        }
      })
      .map(a => ({ ...a, image: l0Images?.[a.code] || '' }));

    // console.log('newMyL0List', newMyL0List);
    newMyL0List.sort((a, b) => a.order - b.order);

    // console.log('l1Map', l1Map);

    // console.log('MyL1List', newMyL1List)
    setL1ToPrint(newMyL1List);
    setL0ToPrint(newMyL0List);
  }, [L1Images, l1Map, channelsByL0, coursesMap]);

  useEffect(() => {
    if (initialL0 === null) {
      setSelectedL0(l0ToPrint?.[0]?.code);
    }
  }, [l0ToPrint]);

  const loading = !isGetBrandsCompleted || !isGetCourseCompleted;

  const groupedL1 = groupBy(l1ToPrint, a => a.l0);
  // console.log(`groupedL1`, groupedL1);
  if (loading) {
    return [{}, [], null, setSelectedL0, loading];
  }
  return [groupedL1, l0ToPrint, selectedL0, setSelectedL0, loading];
};

/**
 * @type TL1Key parameter for useGetCourseByL1 and useGetCourseByL1s
 * */
type TL1Key = EBuildYourCareerLevelsL1 | string

/**
 * @type TUseGetcourseByL1 Return type for useGetCourseByL1
 */
type TUseGetcourseByL1 = [Array<Course>, boolean]

/**
 * Get courses under a given L1
 * @param {TL1Key} L1 Key L1 under L0 defined
 * @return {TUseGetcourseByL1} Empty array or courses array, with condition of loading
 * */
export const useGetCourseByL1 = (L1: TL1Key): TUseGetcourseByL1=>{
  const [bIsLoading, setBIsLoading] = useState(true)
  const [coursesRes, setCoursesRes] = useState<Array<Course>>([])
  const courses: CoursesMap = useSelector(state => state.course.coursesMap);

  useEffect(() => {
    if(courses && Object.keys(courses).length > 0){
      const res = Object.values(courses).filter(course=>course?.catalogTypes.includes(L1))

      //<editor-fold desc="useL1List.ts > useGetCourseByL1 - line 174 at 05/09/2023 10:24:31">
      /*console.group('useL1List.ts > useGetCourseByL1 - line 174 at 05/09/2023 10:24:31');
      console.log(courses, res, L1);
      console.groupEnd();*/
      //</editor-fold>

      setCoursesRes(res)
      setBIsLoading(false)
    }
  }, [courses]);

  return [coursesRes, bIsLoading]
}

/**
 * @type L1WithL2List
 * @example
 * {
 *   "career_master":{
 *       key: "",
 *       label: "",
 *       ...
 *       l2:[
 *           ...,
 *           ...
 *       ]
 *   }
 * }
*/
export type L1WithL2List = {
  [key: TL1Key]:{
    l2: Array<Course>
  } & LevelEnrichedFE
}

/**
 * @type TUseGetcourseByL1s Return type for useGetCourseByL1s
 */
type TUseGetcourseByL1s = [
  L1WithL2List,
  boolean
]

/**
 * Get courses under a given L1
 * @param  {Array<TL1Key>} L1s Key L1 under L0 defined
 * @return {TUseGetcourseByL1s} Empty array or courses array, with condition of loading
 * */
export const useGetCourseByL1s = (L1s: Array<TL1Key>):TUseGetcourseByL1s=>{
  const [bIsLoading, setBIsLoading] = useState(true)
  const [coursesRes, setCoursesRes] = useState<L1WithL2List>({})
  const courses: CoursesMap = useSelector(state => state.course.coursesMap);
  const l1s = useSelector(state=>state.utils.labelL1Map)

  useEffect(() => {
    if(Object.keys(l1s).length == 0) return; // if
    if(courses && Object.keys(courses).length > 0){
      const temp = {}
      L1s.forEach(l1=>{
        temp[l1] = l1s[l1]
        temp[l1]["l2"] = Object.values(courses)
                .filter(course => course?.catalogTypes.includes(l1))
            || []

        //<editor-fold desc="useL1List.ts > useGetCourseByL1 - line 174 at 05/09/2023 10:24:31">
        /*console.group('useL1List.ts > useGetCourseByL1 - line 174 at 05/09/2023 10:24:31');
        console.log(courses, res, l1);
        console.groupEnd();*/
        //</editor-fold>

      })

      setCoursesRes(temp)
      setBIsLoading(false)
    }
  }, [courses, l1s]);

  return [coursesRes, bIsLoading]
}


type TUseGetcourseByL0L1s = [
  L1WithL2List,
  boolean
]

// export const useGetCourseByL0L1sOffice = (l0: LevelEnrichedFE):TUseGetcourseByL0L1s => {
export const useGetCourseByL0L1sOffice = ():TUseGetcourseByL0L1s => {

  const [listL0, isLoading] = useL0BycOffice()
  const [bIsLoading, setBIsLoading] = useState(true)
  const [coursesRes, setCoursesRes] = useState<L1WithL2List>({})
  const courses: CoursesMap = useSelector(state => state.course.coursesMap);

  // if(!l0) return [coursesRes, false]

  useEffect(() => {

    if(!isLoading && listL0) {

      const specialtyProgramsOfficeL0 = listL0.find(l0 => l0.area === LEVELS_AREA.BUILD_YOUR_CAREER_UP_OFFICE)
      
      if(!specialtyProgramsOfficeL0) return 

      if(specialtyProgramsOfficeL0 && specialtyProgramsOfficeL0.l1 && Array.isArray(specialtyProgramsOfficeL0.l1)) {

        const l1s = specialtyProgramsOfficeL0.l1
  
        if(Object.values(l1s)?.length == 0) return;
  
        if(courses && Object.values(courses).length > 0) {
          let temp = {}
          Object.values(l1s).forEach(l1 => {
            temp[l1.key] = l1
            temp[l1.key]["l2"] = Object.values(courses).filter(course => 
              // GET only course with that L1 key and with CTYPE learning_path
              course?.catalogTypes?.includes(l1.key) && course?.ctype?.includes(ctype.LEARNING_PATH)
            ) || []
          })
  
          setCoursesRes(temp)
          setBIsLoading(false)
        }
      }
    }
    

  }, [courses,listL0,isLoading]);

  return [coursesRes, bIsLoading]
}

export default useL1List;
