import { CONFIG_FIREBASE } from '../config_local';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

console.log('Configuration Firebase project ' + CONFIG_FIREBASE.projectId);
firebase.initializeApp(CONFIG_FIREBASE);

export const authFirebase = firebase.auth;
// export const db = firebase.database();
export const myFirestore = firebase.firestore();
