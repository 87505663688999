import { useState, useEffect } from "react";
import { useSelector } from "./redux";
import { getNotReadNumber } from "@utility/firebaseUtility";

const useUnreadNotifications = (): [number, string | number] => {
  const [numNotifs, setNumNotifs] = useState(0);

  const notifications = useSelector((state) => state.firebase.notifications);
  const coursesMap = useSelector((state) => state.course.coursesMap);
  const userProfile = useSelector((state) => state.user.userProfile);

  useEffect(() => {
    setNumNotifs(getNotReadNumber(notifications, coursesMap, userProfile));
  }, [notifications, coursesMap, userProfile]);

  return [numNotifs, numNotifs < 100 ? numNotifs : "99+"];
};
export default useUnreadNotifications;