import * as actionTypes from './actionTypes';
import { logout, signInWithCustomToken } from '../../firebase/auth';
import { authFirebase, myFirestore } from '../../firebase/firebase';
import { getCookie } from '../../utility/cookie';
import * as urlList from '../../config';
const axios = require('axios');
import {
  COOKIE,
  NOTIFICATION_ACTION,
  NOTIFICATION_ATTRIBUTE,
  queryParams,
  updateType,
} from '../../utility/const';
import {
  getDataFromSnapshot,
  sortNotifications,
  getQueryFirebase,
  getNotifRetention,
  getParentIdByNotif,
  isNotificationToShow,
  isPreviewToShow,
} from '../../utility/firebaseUtility';
import {
  toggleModal,
  deleteCourse,
  updateCourse,
  setChildCourse,
  getCourses,
  getCourseCompletion,
  toggleRatingModal,
  openConfirmBookingModal,
  updateCourseInCatalog,
  handleCloseIframe,
  completeCertificateActivity,
  getCourseCompletionById,
} from './coursesActions';
import { getLeaderboard, getUserProfile } from './userActions';
import { refreshReportMyTeam } from './reportManagerActions';
import {
  getHeaders,
  isCourseCertificateCtype,
  isLivestreamCourse,
  scrollToLeaderboard,
} from '../../utility/Api';
import { getUserRelevantSessionsById, setShowModalByType, showError, toggleIframePopup } from './utilsActions';
import { getFirebaseToken } from './authenticationActions';
import { USER_URLS } from '@components/link-utils';
import { createIcs } from '@utility/CalendarUtility';
import { getLivestreamRecording, getLivestreamUrls } from '@utility/LivestreamUtility';
import { TP_CHARACTERISTICS } from '@model/ProductClass';
import { setCatalogueCourses, toggleModalCourseById } from '.';
import { filterPlanCarouselCourses } from '@hooks/usePlanCarousel';
import { isIdPlan } from '@utility/ecommercePlanUtility';
import { isSupportLessonInOnboarding } from '@utility/onBoardingCourseUtility';

const timestampNow = new Date().getTime();

export const signInFirebase = (forceRefreshToken = false) => {
  return (dispatch, getState) => {
    const token = getCookie(COOKIE.FIREBASE_TOKEN);

    const isAuthenticating = getState().firebase.isAuthenticating;
    if (isAuthenticating) {
      console.log('Login - Firebase is already authenticating');
      return;
    }

    if (!token || forceRefreshToken) {
      console.error('Firebase token not found for authentication');

      //refresh firebase token
      dispatch(getFirebaseToken());
      return;
    }

    dispatch(request());

    signInWithCustomToken(token)
      .then(result => {
        // console.log('CURRENT USER FIREBASE', authFirebase().currentUser);

        dispatch(success());

        //examples to create notifications - don't commit

        // setTimeout(() => {
        //   console.log('GENERATE NOTIF');
        //   dispatch(generateNotif('co29500', updateType.LIVESTREAM_RECORDING, false));
        // }, 3000);

        // setTimeout(() => {
        //     console.log('GENERATE NOTIF GENERIC');
        //     dispatch(generateNotifFirebase('lp221', updateType.COURSE_DELETED, NOTIFICATION_ATTRIBUTE.GENERIC));
        // }, 3000);

        //get only user notifications not expired after login or page refresh
        dispatch(
          onSnapshotNotifications(NOTIFICATION_ATTRIBUTE.USER, {
            fieldPath: 'timeend',
            opStr: '>=',
            value: timestampNow,
          })
        );
        //get only generic notification generated after login or page refresh
        dispatch(
          onSnapshotNotifications(NOTIFICATION_ATTRIBUTE.GENERIC, {
            fieldPath: 'notificationDto.timestart',
            opStr: '>=',
            value: timestampNow,
          })
        );
      })
      .catch(error => {
        console.error('Firebase authentication failed', error);
        dispatch(failure(error));
        dispatch(showError(error));
      });
  };

  function request() {
    return {
      type: actionTypes.FIREBASE_LOGIN_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.FIREBASE_LOGIN_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.FIREBASE_LOGIN_FAILURE,
      error: error.message,
    };
  }
};

export const logoutFirebase = (loginAfterLogout = false) => {
  return (dispatch, getState) => {
    const isAuthenticating = getState().firebase.isAuthenticating;
    if (isAuthenticating) {
      console.log('Logout - Firebase is already authenticating');
      return;
    }

    dispatch(request());

    // unsubscribe listeners
    const snapshotSubscribers = getState().firebase.snapshotSubscribers;
    snapshotSubscribers.map(subscriber => {
      if (subscriber) {
        subscriber();
      }
    });
    dispatch(setSnapshotSubscribers([]));

    logout()
      .then(() => {
        console.log('Firebase logout success');
        dispatch(success());
      })
      .catch(error => {
        console.error('Firebase logout failed', error);
        dispatch(failure(error));
      })
      .finally(() => {
        if (loginAfterLogout) {
          dispatch(signInFirebase(true));
        }
      });
  };

  function request() {
    return {
      type: actionTypes.FIREBASE_LOGOUT_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.FIREBASE_LOGOUT_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.FIREBASE_LOGOUT_FAILURE,
      error: error.message,
    };
  }
};

export const refreshLoginFirebase = () => {
  return (dispatch, getState) => {
    //if two last login are closer than 1 minute --> not refresh login
    const lastLogins = getState().firebase.lastLogins;
    if (lastLogins && lastLogins.length >= 2) {
      const length = lastLogins.length;
      if (lastLogins[length - 1] - lastLogins[length - 2] < 1000 * 60) {
        console.log(
          'Firebase - last 2 login are closer than 1 minute',
          lastLogins[length - 1],
          lastLogins[length - 2]
        );
        return;
      }
    }

    dispatch(logoutFirebase(true));
  };
};

export const onSnapshotNotifications = (attribute, whereCondition = null) => {
  return (dispatch, getState) => {
    try {
      const query = getQueryFirebase(attribute, whereCondition);
      if (!query) {
        return;
      }

      dispatch(request());
      console.log('ON SNAPSHOT NOTIFICATIONS ' + attribute, query);

      let snapshotSubscribers = getState().firebase.snapshotSubscribers;

      const snapshotSubscriber = query.onSnapshot(
        snapshot => {
          dispatch(handleNotifications(attribute, getDataFromSnapshot(snapshot)));

          //add subscriber to list
          snapshotSubscribers.push(snapshotSubscriber);
          dispatch(setSnapshotSubscribers(snapshotSubscribers));
        },
        error => {
          console.error('Firebase onShapshot failed', error);
          dispatch(failure(error));
          dispatch(refreshLoginFirebase());
          //dispatch(showError(error));
        }
      );
    } catch (error) {
      console.error('Firebase onShapshot failed', error);
      dispatch(failure(error));
      dispatch(refreshLoginFirebase());
      // dispatch(showError(error));
    }
  };

  function request() {
    return {
      type: actionTypes.FIREBASE_SNAPSHOT_REQUEST,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.FIREBASE_SNAPSHOT_FAILURE,
      error: error.message,
    };
  }
};

export const getNotifications = (attribute, whereCondition = null) => {
  return dispatch => {
    try {
      const query = getQueryFirebase(attribute, whereCondition);
      if (!query) {
        return;
      }

      dispatch(request());
      console.log('GET NOTIFICATIONS ' + attribute, query);

      query
        .get()
        .then(notifications => {
          dispatch(success());
          dispatch(handleNotifications(attribute, getDataFromSnapshot(notifications)));
        })
        .catch(error => {
          console.error('Firebase get notifications failed', error);
          dispatch(failure(error));
          dispatch(showError(error));
        });
    } catch (error) {
      console.error('Firebase onShapshot failed', error);
      dispatch(failure(error));
      dispatch(showError(error));
    }
  };

  function request() {
    return {
      type: actionTypes.FIREBASE_GET_NOTIF_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.FIREBASE_GET_NOTIF_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.FIREBASE_GET_NOTIF_FAILURE,
      error: error.message,
    };
  }
};

export const handleNotifications = (
  attribute,
  notifications,
  isSnapshot = true,
  localNotifications = null
) => {
  return (dispatch, getState) => {
    let notificationsList = [];
    const showNotifAnimation = getState().firebase.showNotifAnimation;
    let oldNotifications = getState().firebase[attribute];

    // console.log('Handle notifications', notifications);

    //get notifications content
    if (isSnapshot) {
      notifications = notifications.map(snap =>
        snap.data().notificationDto
          ? { ...snap.data().notificationDto, id: snap.id }
          : { ...snap.data(), id: snap.id }
      );
    }
    console.log('notifications', notifications);
    if (!Array.isArray(notifications)) {
      notifications = [];
    }

    //OUT OF SCOPE local notifications
    // if (!localNotifications) {
    //     localNotifications = getState().firebase.localNotifications;
    // }
    // if (localNotifications?.length > 0 && attribute === NOTIFICATION_ATTRIBUTE.USER) {
    //     notifications = [...notifications, ...localNotifications];
    //     console.log('notifications + local', notifications)
    // }

    notifications.forEach(notification => {
      // notification.id = snap.id;
      // ref is useless if notification writings pass through notification center
      // notification.ref = snap.ref;
      if (!notification.timestart) {
        notification.timestart = 0;
      }

      //check if notification is new
      if (oldNotifications) {
        //notification is new if there are not other notifications with same id and timestart
        const oldNotificationsFilter = oldNotifications.filter(
          old => old.id === notification.id && old.timestart === notification.timestart
        );
        const isNewNotif = !oldNotificationsFilter || oldNotificationsFilter.length === 0;

        //if notification is new --> handle it
        if (isNewNotif) {
          dispatch(handleNotifArrival(notification));
          showNotifAnimation[notification.id] = true;
        }
      }

      //add notif to notifications list
      notificationsList.push(notification);
    });

    console.log('Handle notifications modified', notificationsList);

    notificationsList = sortNotifications(notificationsList);
    dispatch(saveNotif(attribute, notificationsList, showNotifAnimation));
  };

  function saveNotif(attributeNotif, notificationsList, showNotifAnimation) {
    return {
      type: actionTypes.FIREBASE_SAVE_NOTIF,
      attribute: attributeNotif,
      notifications: notificationsList,
      showNotifAnimation: showNotifAnimation,
    };
  }
};

export const setNotif = (ref, doc) => {
  return dispatch => {
    dispatch(writeDbRequest());

    const batch = myFirestore.batch();
    batch.set(ref, doc);
    batch
      .commit()
      .then(response => {
        console.log('set notif ok');
        dispatch(writeDbSuccess());
      })
      .catch(error => {
        console.error('Firebase set notif failed', error);
        dispatch(writeDbFailure(error));
        dispatch(showError(error));
      });
  };
};

export const readNotifs = notifications => {
  if (!notifications || notifications.length < 1) {
    return null;
  }

  return dispatch => {
    const localNotifications = notifications.filter(notif => notif.isLocalNotif);
    notifications = notifications.filter(notif => !notif.isLocalNotif);

    //update local notifications
    if (localNotifications?.length > 0) {
      dispatch(toggleLocalNotifsAsRead(localNotifications));
    }

    //update firebase notifications
    if (notifications?.length > 0) {
      dispatch(writeDbRequest());

      let url = urlList.READ_NOTIFICATIONS;

      axios({
        url,
        method: 'POST',
        headers: getHeaders(),
        data: notifications,
      })
        .then(response => {
          console.log('notif is updated', response.data);
          dispatch(writeDbSuccess());
        })
        .catch(error => {
          console.error('Firebase update notif failed', error);
          dispatch(writeDbFailure(error));
          dispatch(showError(error));
        });
    }
  };
};

export const updateNotifsFirebase = (refs, valuesToUpdate) => {
  if (!refs || refs.length < 1) {
    return null;
  }

  return dispatch => {
    dispatch(writeDbRequest());

    const batch = myFirestore.batch();

    //updates every ref
    for (let i = 0; i < refs.length; i++) {
      batch.update(refs[i], valuesToUpdate[i]);
    }

    batch
      .commit()
      .then(response => {
        console.log('notif is updated');
        dispatch(writeDbSuccess());
      })
      .catch(error => {
        console.error('Firebase update notif failed', error);
        dispatch(writeDbFailure(error));
        dispatch(showError(error));
      });
  };
};

export const deleteNotif = ref => {
  return dispatch => {
    dispatch(writeDbRequest());

    const batch = myFirestore.batch();
    batch.delete(ref);
    batch
      .commit()
      .then(response => {
        console.log('delete notif response', response);
        dispatch(writeDbSuccess());
      })
      .catch(error => {
        console.error('Firebase delete notif failed', error);
        dispatch(writeDbFailure(error));
        dispatch(showError(error));
      });
  };
};

function writeDbRequest() {
  return {
    type: actionTypes.FIREBASE_WRITE_DB_REQUEST,
  };
}

function writeDbSuccess() {
  return {
    type: actionTypes.FIREBASE_WRITE_DB_SUCCESS,
  };
}

function writeDbFailure(error) {
  return {
    type: actionTypes.FIREBASE_WRITE_DB_FAILURE,
    error: error.message,
  };
}

export const toggleNotifAsRead = notification => {
  if (!notification) {
    return;
  }

  //if notif is local --> update it locally
  // if (notification.isLocalNotif) {
  //     return toggleLocalNotifAsRead(notification);
  // }

  let notifTemp = { ...notification };
  notifTemp.read = !notifTemp.read;

  return readNotifs([notifTemp]);
};

export const toggleNotifAsReadFirebase = notification => {
  if (!notification) {
    return;
  }

  return updateNotifsFirebase([notification.ref], [{ read: !notification.read }]);
};

export const markAllNotifsAsRead = notifications => {
  if (!notifications || notifications.length < 1) {
    return;
  }

  //get notifications not read
  notifications = notifications.filter(notif => !notif.read);

  //if at least one notification is not read
  if (notifications && notifications.length > 0) {
    for (let notif of notifications) {
      notif.read = true;
    }

    return readNotifs(notifications);
  }

  return;
};

export const markAllNotifsAsReadFirebase = notifications => {
  if (!notifications || notifications.length < 1) {
    return;
  }

  let refs = [];
  let valuesToUpdate = [];

  //get notifications not read
  notifications = notifications.filter(notif => !notif.read);

  //if at least one notification is not read
  if (notifications && notifications.length > 0) {
    for (let notification of notifications) {
      refs.push(notification.ref);
      valuesToUpdate.push({ read: true });
    }

    return updateNotifFirebase(refs, valuesToUpdate);
  }

  return null;
};

export const handleNotifAction = (notif, action, history) => {
  return (dispatch, getState) => {
    if (!notif || !action) {
      return;
    }

    const coursesMap = getState().course.coursesMap;
    const userProfile = getState().user.userProfile;
    const lang = getState().utils.lang;

    //if the notification is not read --> mark as read
    if (!notif.read) {
      dispatch(toggleNotifAsRead(notif));
    }

    switch (action) {
      case NOTIFICATION_ACTION.DOWNLOAD_CALENDAR:
        createIcs(notif.courseid, notif.sessionid, coursesMap, userProfile.timezone, lang);
        break;
      case NOTIFICATION_ACTION.GO_TO_COURSE:
        if (coursesMap && notif.courseid) {
          let courseId = null;
          let language = null;
          if (coursesMap[notif.courseid]) {
            //if the course is a parent course
            courseId = notif.courseid;
          } else {
            //get parent and language if the notif has courseid referred to a child course
            const parent = coursesMap[getParentIdByNotif(notif, coursesMap)];
            if (parent) {
              courseId = notif.parentCourseId;
              language = {
                courseId: notif.courseid,
                parentId: courseId,
                isParent: false,
              };
            }
          }

          if (courseId) {
            //open course detail modal
            if (language) {
              dispatch(setChildCourse(language));
            }
            const course = coursesMap[courseId];
            dispatch(toggleModal(true, course));

            dispatch(setShowModalByType(null));

            if (isSupportLessonInOnboarding(course)) {
              //go to onboarding scrolling to the relative lesson
              const parentOnboardingId = course.parentLP?.[0]?.courseId;
              if (parentOnboardingId) {
                const path = USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', parentOnboardingId);
                history.push(
                  path + '?' + queryParams.ONBOARDING_SCROLL_TO_LESSON + '=' + course.courseIdMaster
                );

                if (window.location.pathname === path) {
                  //refresh page to trigger scroll
                  history.go();
                }
              }
            }
          }
        }
        break;
      case NOTIFICATION_ACTION.GO_TO_CATALOGUE:
        history.push(USER_URLS.SUBCATALOGUE.URL);
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.GO_TO_COMPLETIONS:
        history.push(USER_URLS.PROFILE_MY_PROGRESS.URL);
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.GO_TO_CERTIFICATES:
        history.push(USER_URLS.PROFILE_MY_CERTIFICATES.URL);
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.GO_TO_MY_TEAM:
        if (window.location.pathname !== USER_URLS.PROFILE_MY_TEAM.URL) {
          history.push(USER_URLS.PROFILE_MY_TEAM.URL);
        }
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.GO_TO_LEADERBOARD:
        if (window.location.pathname === USER_URLS.PROFILE.URL) {
          scrollToLeaderboard();
        } else {
          history.push(USER_URLS.PROFILE.URL + '?goTo=leaderboard');
        }
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.RATE_COURSE:
        if (coursesMap && coursesMap[notif.courseid]) {
          dispatch(toggleRatingModal(true, coursesMap[notif.courseid]));
          dispatch(setShowModalByType(null));
        }
        break;
      case NOTIFICATION_ACTION.BOOKING:
        if (notif.sessionid && coursesMap && coursesMap[notif.courseid]) {
          dispatch(openConfirmBookingModal(notif.courseid, notif.sessionid, true));
          dispatch(setShowModalByType(null));
        }
        break;
      case NOTIFICATION_ACTION.LIVESTREAM_JOIN:
        if (coursesMap && coursesMap[notif.courseid]) {
          const course = coursesMap[notif.courseid];
          const urlsLivestream = getLivestreamUrls(course);

          if (urlsLivestream.liveUrl && course.showJoin) {
            dispatch(toggleIframePopup(true, course, null, null, false, urlsLivestream));
            dispatch(setShowModalByType(null));
          }
        }
        break;
      case NOTIFICATION_ACTION.LIVESTREAM_RECORDING_LAUNCH:
        if (coursesMap && coursesMap[notif.courseid]) {
          const course = coursesMap[notif.courseid];
          let recording = getLivestreamRecording(course, userProfile, notif.moduleId);
          if (recording.length === 1) {
            let completationUrl = '';
            completationUrl = getLivestreamCompletation(course, userProfile, null, recording[0]);
            let completeRecording = recording[0].includes('?') ? recording[0] + '&api=1' : recording[0] + '?api=1';
            toggleIframePopup(true, course, completeRecording, completationUrl, false);
          }

          if (isLivestreamCourse(course)) {
            //handle rating
            dispatch(handleCloseIframe(course, true));
          }
        }
        break;
      case NOTIFICATION_ACTION.GO_TO_TP_PLP:
        //go to PLP filtered by new picks for you
        const isSubcatalogueTP = history.location.pathname === USER_URLS.SUBCATALOGUE_TP.URL;
        const params = new URLSearchParams({
          characteristics: TP_CHARACTERISTICS.NEW_PICK_FOR_YOU,
        })?.toString();
        history.push(USER_URLS.SUBCATALOGUE_TP.URL + '?' + params);
        if (isSubcatalogueTP) {
          history.go();
        }
        dispatch(setShowModalByType(null));
        break;
      case NOTIFICATION_ACTION.ACTIVATE_TOKEN_GO_CONTENT:
        //for courses and lessons --> show course detail
        //for plan --> show Plan PDP
        const isPlan = isIdPlan(notif.object);
        if (isPlan) {
          dispatch(
            setCatalogueCourses(filterPlanCarouselCourses(coursesMap), 'PREMIUM_ACCESS_PLAN')
          );
          if (document.location.pathname !== USER_URLS.PLAN.URL) {
            history.push(USER_URLS.PLAN.URL);
          }
        } else {
          dispatch(toggleModalCourseById(notif.object));
        }
        dispatch(setShowModalByType(null));
        break;
      default:
        break;
    }
  };
};

export const handleNotifArrival = notif => {
  return (dispatch, getState) => {
    if (!notif) {
      return;
    }

    console.log('Handling arrival of notification', notif);

    switch (notif.notificationtype) {
      case updateType.REPORT_MANAGER_UPDATED_OLD:
      case updateType.REPORT_MANAGER_UPDATED:
      case updateType.LP_OVERDUE_MANAGER:
        dispatch(refreshReportMyTeam());
        break;
      case updateType.COURSE_DELETED:
      case updateType.LP_DELETED:
        dispatch(deleteCourse(notif.courseid));
        break;
      case updateType.PROFILE_UPDATED:
        dispatch(getUserProfile());
        dispatch(getCourses());
        break;
      case updateType.COURSE_IS_EXPIRING_OLD:
      case updateType.COURSE_IS_EXPIRING:
      case updateType.LP_IS_EXPIRING:
      case updateType.TEAM_EXPIRING_ASSIGNMENTS:
      case updateType.TEAM_EXPIRING_ASSIGNMENTS_LP:
      case updateType.COURSE_COMPLIANCE_EXPIRED:
      case updateType.COURSE_PENDING:
      case updateType.MONTHLY_ACHIEVEMENT:
      case updateType.RATING:
      case updateType.RATING_LP:
      case updateType.WISH_EXPIRING:
      case updateType.LIVESTREAM_STARTING:
      case updateType.BOOK_COMPLETE:
      case updateType.WAIT_LIST_OPEN:
        dispatch(getUserRelevantSessionsById(notif.courseId));
        break;
      case updateType.SESSION_DATE_MODIFIED:
        dispatch(getUserRelevantSessionsById(notif.courseId));
        break;
      case updateType.FROM_WAIT_TO_BOOK:
        dispatch(getUserRelevantSessionsById(notif.courseId));
        break;
      case updateType.BOOK_CANCEL:
        dispatch(getUserRelevantSessionsById(notif.courseId));
        break;
      case updateType.LIVESTREAM_JOIN:
      case updateType.TOKEN_ACTIVATED:
      case updateType.LP_OVERDUE_MANAGER:
        //do nothing, just show the notification
        break;
      case updateType.LEADERBOARD_IN:
      case updateType.LEADERBOARD_IN_MANAGER:
      case updateType.LEADERBOARD_OUT:
      case updateType.LEADERBOARD_OUT_MANAGER:
        //update leaderboard just if we are in Profile Homepage because the request is executed at Profile HP landing
        if (window.location.pathname === USER_URLS.PROFILE.URL) {
          dispatch(getLeaderboard());
        }
        break;
      case updateType.ACTIVITY_COMPLETED:
      case updateType.COURSE_COMPLETED_OLD:
      case updateType.COURSE_COMPLETED:
      case updateType.LP_COMPLETED:
      case updateType.CERTIFICATE_AVAILABLE:
        if (getState().course.isGetCourseCompletionCompleted) {
          dispatch(getCourseCompletion(true));
        }
        if (notif.courseid) {
          dispatch(updateCourse(notif.courseid, notif, true));

          if (notif.certificate) {
            //if a course with a certificate is completed --> complete its certificate lesson
            completeCertificateActivity(notif.certificate);
          }

          if (
            notif.notificationtype === updateType.COURSE_COMPLETED_OLD ||
            notif.notificationtype === updateType.COURSE_COMPLETED
          ) {
            const coursesMap = getState().course.coursesMap;
            //get parentCourseId
            getParentIdByNotif(notif, coursesMap);
            const course = coursesMap?.[notif.parentCourseId];

            //if lesson certificate is completed
            if (isCourseCertificateCtype(course)) {
              course.parentLP?.forEach(parentLp => {
                //get LP certificates
                dispatch(getCourseCompletionById(parentLp.courseId));
              });
            }
          }
        }
        break;
      default:
        if (notif.courseid) {
          dispatch(updateCourse(notif.courseid, notif, true));
        }
        break;
    }

    if (
      isPreviewToShow(notif, false) &&
      isNotificationToShow(notif, getState().course.coursesMap, getState().user.userProfile)
    ) {
      dispatch(pushNotificationPreview(notif));
    }
  };
};

export const generateNotif = (
  courseId,
  type,
  avoidDuplication = true,
  sessionid = '',
  sessiondate = 0,
  completiondate = 0,
  rest = {}
) => {
  return (dispatch, getState) => {
    if (!type) {
      return;
    }

    if (avoidDuplication) {
      const actualNotifs = getState().firebase.notifications;
      let newIdNotif = courseId + ':' + type;
      if (sessionid) {
        newIdNotif += ':' + sessionid;
      }
      for (const actualNotif of actualNotifs) {
        if (actualNotif.id === newIdNotif) {
          console.log('Notif ' + newIdNotif + ' was already created');
          return;
        }
      }
    }

    const timestart = new Date().getTime();
    const retention = getNotifRetention(type);

    let username = authFirebase().currentUser ? authFirebase().currentUser.uid : null;
    const userProfile = getState().user.userProfile;
    if (!username && userProfile && userProfile.username) {
      username = userProfile.username;
    }

    let notification = {
      username: username,
      notificationtype: type,
      retention: retention,
      timestart: timestart,
      timeend: timestart + retention,
      courseid: courseId ? courseId : '',
      sessionid: sessionid,
      sessiondate: sessiondate,
      inserttime: timestart,
      ...rest,
    };

    const notifications = [notification];

    let url = urlList.CREATE_NOTIFICATION;

    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
      data: notifications,
    })
      .then(response => {
        console.log('notif is created', response.data);
        dispatch(writeDbSuccess());
      })
      .catch(error => {
        console.error('Firebase create notif failed', error);
        dispatch(writeDbFailure(error));
        dispatch(showError(error));
      });
  };
};

export const generateNotifFirebase = (courseId, type, attribute) => {
  return dispatch => {
    if (!type) {
      return;
    }

    const query = getQueryFirebase(attribute);
    if (!query) {
      return;
    }

    //create new document
    const ref = myFirestore.collection(query.path).doc();

    const timestart = new Date().getTime();
    const retention = getNotifRetention(type);

    let notif = {
      notificationtype: type,
      retention: retention,
      timestart: timestart,
      timeend: timestart + retention,
      courseid: courseId ? courseId : '',
      sessionid: '',
      sessiondate: null,
      inserttime: timestart,
    };

    dispatch(setNotif(ref, notif));
  };
};

export const setShowNotifAnimation = showNotifAnimation => {
  return {
    type: actionTypes.FIREBASE_SET_SHOW_NOTIF_ANIMATION,
    showNotifAnimation: showNotifAnimation,
  };
};

export const setSnapshotSubscribers = snapshotSubscribers => {
  return {
    type: actionTypes.FIREBASE_SET_SNAPSHOT_SUBSCRIBERS,
    snapshotSubscribers: snapshotSubscribers,
  };
};

export const pushNotificationPreview = notif => {
  return dispatch => {
    if (!notif) {
      return;
    }

    dispatch({
      type: actionTypes.FIREBASE_PUSH_NOTIFICATION_PREVIEW,
      notif,
    });
  };
};

export const popNotificationPreview = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FIREBASE_POP_NOTIFICATION_PREVIEW,
    });
  };
};

export const toggleNotifPanel = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIREBASE_SET_SHOW_NOTIF_PANEL,
      showNotifPanel: !getState().firebase.showNotifPanel,
    });
  };
};

export const setShowNotifPanel = showNotifPanel => {
  return dispatch => {
    dispatch({
      type: actionTypes.FIREBASE_SET_SHOW_NOTIF_PANEL,
      showNotifPanel,
    });
  };
};

export const notifyRecordingLivestreamUpdate = livestream => {
  return dispatch => {
    if (!livestream || !isLivestreamCourse(livestream)) {
      console.error('notifyRecordingLivestreamUpdate wrong params', livestream);
      return;
    }

    let moduleId = livestream.liveInfo?.[0]?.moduleId;

    let url = urlList.LIVESTREAM_NOTIFY_ME_RECORDING;
    url += '?courseId=' + livestream.courseIdMaster;
    url += '&moduleId=' + moduleId;
    url += '&subscribe=true';

    dispatch(request(livestream.courseIdMaster));

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then(response => {
        let course = response.data;

        dispatch(updateCourseInCatalog(course, course.courseIdMaster, '', true));

        dispatch(success(course.courseIdMaster));
      })
      .catch(err => {
        dispatch(failure(livestream.courseIdMaster));
        dispatch(showError(err));
      });
  };

  function request(courseId) {
    return {
      type: actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_REQUEST,
      courseId,
    };
  }

  function success(courseId) {
    return {
      type: actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_SUCCESS,
      courseId,
    };
  }

  function failure(courseId) {
    return {
      type: actionTypes.GET_NOTIFY_RECORDING_LIVESTREAM_FAILURE,
      courseId,
    };
  }
};

//OUT OF SCOPE FOR NOW
// export const notifyLivestreamStarting = (livestream) => {
//     return (dispatch) => {
//         if (!livestream) {
//             console.error('notifyLivestreamStarting wrong params', livestream);
//             return;
//         }

//         dispatch(request(livestream.courseId));

//         // let url = urlList.GET_USER_PROFILE_URL;
//         // dispatch(request());

//         // axios.get(url, {
//         //     headers: getHeaders()
//         // })
//         //     .then(profile => {
//         //         console.log('data', profile.data)
//         setTimeout(() => {
//             const response = { data: { ...livestream } };
//             response.data.liveInfo[0].willBeNotifiedLivestreamStart = true;
//             let course = response.data;

//             dispatch(updateCourseInCatalog(course, course.courseId, '', true));

//             dispatch(success(livestream.courseId))
//         }, 2000);

//         //     }).catch(err => {
//         //         dispatch(failure(livestream.courseId));
//         //         dispatch(showError(err));
//         //     });
//     }

//     function request(courseId) {
//         return {
//             type: actionTypes.GET_NOTIFY_LIVESTREAM_START_REQUEST,
//             courseId
//         };
//     }

//     function success(courseId) {
//         return {
//             type: actionTypes.GET_NOTIFY_LIVESTREAM_START_SUCCESS,
//             courseId
//         };
//     }

//     function failure(courseId) {
//         return {
//             type: actionTypes.GET_NOTIFY_LIVESTREAM_START_FAILURE,
//             courseId
//         };
//     }
// };

export const generateLocalNotif = (
  courseId,
  type,
  retention,
  avoidDuplication = true,
  sessionid = '',
  sessiondate = 0
) => {
  return (dispatch, getState) => {
    //OUT OF SCOPE
    return;
    if (!type) {
      return;
    }

    const actualLocalNotifs = getState().firebase.localNotifications;
    if (avoidDuplication) {
      let newIdNotif = courseId + ':' + type;
      if (sessionid) {
        newIdNotif += ':' + sessionid;
      }
      for (const actualNotif of actualLocalNotifs) {
        if (actualNotif.id === newIdNotif) {
          console.log('Local notif ' + newIdNotif + ' was already created');
          return;
        }
      }
    }

    const timestart = new Date().getTime();

    let username = authFirebase().currentUser ? authFirebase().currentUser.uid : null;
    const userProfile = getState().user.userProfile;
    if (!username && userProfile && userProfile.username) {
      username = userProfile.username;
    }

    let notification = {
      username: username,
      notificationtype: type,
      retention: retention,
      timestart: timestart,
      timeend: timestart + retention,
      courseid: courseId ? courseId : '',
      sessionid: sessionid,
      sessiondate: sessiondate,
      inserttime: timestart,
      id: courseId + ':' + type,
      isLocalNotif: true,
      read: false,
    };

    actualLocalNotifs.push(notification);
    console.log('actualLocalNotifs', actualLocalNotifs);

    const notifications = getState().firebase.notifications;
    dispatch(
      handleNotifications(NOTIFICATION_ATTRIBUTE.USER, notifications, false, [notification])
    );

    dispatch({
      type: actionTypes.GET_LOCAL_NOTIFICATIONS,
      localNotifications: actualLocalNotifs,
    });
  };
};

export const toggleLocalNotifAsRead = notification => {
  return toggleLocalNotifAsRead([notification]);
};

export const toggleLocalNotifsAsRead = localNotificationsParam => {
  return (dispatch, getState) => {
    if (!localNotificationsParam || localNotificationsParam.length < 1) {
      return;
    }

    const notifications = getState().firebase.notifications;
    const localNotifications = getState().firebase.localNotifications;

    for (const notification of localNotificationsParam) {
      let read = null;
      for (const notif of notifications) {
        if (notif.id === notification.id) {
          notif.read = notification.read;
          read = notif.read;
          break;
        }
      }

      for (const notif of localNotifications) {
        if (notif.id === notification.id) {
          notif.read = read === null ? notification.read : read;
          break;
        }
      }
    }

    dispatch(handleNotifications(NOTIFICATION_ATTRIBUTE.USER, notifications, false, []));

    dispatch({
      type: actionTypes.GET_LOCAL_NOTIFICATIONS,
      localNotifications: localNotifications,
    });
  };
};
