import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import {
  formatCurrency,
  handleOnEnterKeyPress,
  printDate,
  printDateDDMMYYYY,
  printProductQuantity,
} from "@utility/Api";
import CloseIcon from "@svg-icons/close.svg";
import CardCover from "@components/CardV3/CardCover";
import Counter from "@components/UI/Counter";
import { UserProfile } from "@model/User";
import { useSelector } from "@hooks/redux";
import { CartItemFormatted, ProductCart } from "@model/CartModel";
import { useHistory } from "react-router-dom";
import { isIdPlan } from "@utility/ecommercePlanUtility";
import ButtonV2 from "@components/UI/ButtonV2";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";

type CartCardModalProps = {
  cartItem: CartItemFormatted & { [key: string]: any };
  className?: string;
  removing?: boolean;
  childrenBottom?: React.ReactNode;
  isRenewal?: boolean;
};

const CartCardModal = ({
  cartItem,
  className,
  removing,
  childrenBottom,
  isRenewal,
}: CartCardModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [timeoutQuantity, setTimeoutQuantity] = useState<NodeJS.Timeout>(null);
  const [counter, setCounter] = useState<number>(+cartItem?.quantity || 1);
  const [hideQuantity, setHideQuantity] = useState<boolean>(true);
  const breakpoint = useBreakpointBoolean();

  const handleChangeQuantity = (value: number) => {
    let product = new ProductCart();
    product = { ...cartItem.productCart, quantity: (+value).toString() };

    if (timeoutQuantity) {
      clearTimeout(timeoutQuantity);
    }
    const newTimeoutSearch = setTimeout(() => {
      dispatch(actions.updateProductInCart(product));
    }, 500);

    setTimeoutQuantity(newTimeoutSearch);
    setCounter(value);
  };

  const lang = useSelector(state => state.utils.lang);

  const userProfile: UserProfile = useSelector(state => state.user.userProfile);

  const handleRemoveItem = () => {
    const id = cartItem.id;
    dispatch(actions.removeProductInCart(id.toString()));
  };

  useEffect(() => {
    setCounter(+cartItem?.quantity || 1);
  }, [cartItem?.quantity]);

  useEffect(() => {
    setHideQuantity(isIdPlan(cartItem.id));
  }, [cartItem?.id]);

  return (
    <div
      className="cart-card-modal"
      key={cartItem.id}
      role="region"
      aria-label={cartItem.name}
    >
      <div className="cart-card-modal__header">
        <div
          className="cart-card-modal__image"
          tabIndex={0}
          onClick={() => dispatch(actions.openCartCourseDetail(cartItem))}
          onKeyDown={(e) =>
            handleOnEnterKeyPress(
              e,
              () => dispatch(actions.openCartCourseDetail(cartItem)),
              true
            )
          }
          aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace(
            "{name}",
            cartItem.name
          )}
        >
          <CardCover
            variant="card-medium"
            dontCrop
            hideShadow
            image={cartItem.image}
            courseFullName={cartItem.name}
          />
        </div>
        <div className="cart-card-modal__descr">
          <div className="cart-card-modal__title-row">
            <p
              onClick={() => dispatch(actions.openCartCourseDetail(cartItem))}
              className="title"
            >
              {cartItem.name}
            </p>
            <div className="remove-btn-wrapper flex justify-end">
              <div className="remove-btn">
                <ButtonV2
                  variant="text-btn-no-arrow"
                  small
                  handleClick={handleRemoveItem}
                  disabled={removing}
                >
                  {lang?.REMOVE}
                </ButtonV2>
              </div>
            </div>
          </div>

          {!cartItem.isExpired ? (
            cartItem?.expirationDate && !isRenewal ? (
              <p
                className={
                  "descr-item expiration-date" +
                  (isRenewal ? " is-renewal" : "")
                }
              >
                {lang?.VALID_UNTIL?.replace(
                  "{DATE}",
                  printDateDDMMYYYY(cartItem?.expirationDate, lang)
                )}
              </p>
            ) : (
              isRenewal && (
                <p
                  className={
                    "descr-item expiration-date is-renewal" +
                    (+cartItem.price === 0 ? " is-renewal-paid" : "")
                  }
                >
                  {+cartItem.price === 0
                    ? lang?.SINCE_AUTORENEWAL_ACTIVE_FREE
                    : lang.AUTORENEWAL_ON}
                </p>
              )
            )
          ) : (
            <p className="expired">{lang?.EXPIRED}</p>
          )}

          <div className={"descr-item flex " + (hideQuantity ? "justify-end" : "justify-between")}>
            {!hideQuantity &&
              (!cartItem.isExpired ? (
                <div className="flex">
                  <Counter
                    counter={counter}
                    setCounter={handleChangeQuantity}
                    id={cartItem?.id}
                    max={cartItem?.maxCounterPurchase}
                    canSeeTooltip={!isNaN(cartItem?.maxCounterPurchase)}
                  />
                </div>
              ) : (
                <span></span>
              ))}
            <div className="price">
              {!!cartItem.oldPrice ? (
                <p className="crossed">
                  {formatCurrency(
                    cartItem.oldPrice,
                    cartItem.currency.toString(),
                    userProfile?.preferredLang
                  )}
                </p>
              ) : (
                !breakpoint.isPhone && <p className="empty">&nbsp;</p>
              )}
              <p>
                {formatCurrency(
                  cartItem.finalPrice,
                  cartItem.currency.toString(),
                  userProfile?.preferredLang
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {childrenBottom}
    </div>
  );
};

export default CartCardModal;
