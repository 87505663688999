import { UserProfile } from "@model/User"

export const isUserEl360 = (userProfile: UserProfile): boolean => {
    return userProfile?.el360Ecomm;
}

export const hasUserActivePlan = (userProfile: UserProfile): boolean => {
    return !!userProfile?.subscription;
}

/**
 * @param userProfile 
 * @returns TRUE if user is enabled to see BuildYourCareer page, FALSE otherwise
 */
export const isBycEnabled = (userProfile: UserProfile): boolean => {
    return userProfile?.bycStatus === 'standard' || userProfile?.bycStatus === 'office';
}