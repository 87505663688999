
export default {
  DELETE: "",
  REMOVE: "",
  PLATFORM_SETTINGS: "",
  BILLING_ADDRESS: "",
  BILLING_ADDRESS_DESCR: "",
  BILLING_ADDRESS_DESCR_DONE: "",
  ADDRESS_PLACEHOLDER: "",
  ZIP_CODE_PLACEHOLDER: "",
  CITY_PLACEHOLDER: "",
  STATE_PLACEHOLDER: "",
  SAVE_ADDRESS: "",
  EDIT_ADDRESS: "",
  ERROR_ZIP_CODE_PATTERN: "",
  ORDER_HISTORY: "",
  ACTIVATE_LICENSE: "",
  ACTIVATE_LICENSE_DESCR: "",
  USED: "",
  INACTIVE: "",
  YOUR_ORDER_HISTORY: "",
  YOUR_ORDER_HISTORY_DESCR: "",
  YOUR_ORDERS: "",
  ORDER_NUMBER: "",
  PURCHASE_DATE: "",
  PURCHASED_DATE: "",
  EXPIRATION_DATE: "",
  EXPIRATION_DATE_CARD_CART: "",
  SEE_TOKEN: "",
  VALID_UNTIL: "",
  LICENSE_QUANTITY: "",
  SUBTOTAL: "",
  SUBTOTAL_CART: "",
  TAXES: "",
  PROMO_CODE: "",
  TOTAL: "",
  TOTAL_CART: "",
  TOKEN: "",
  TOKENS: "",
  YOUR_TOKENS: "",
  YOUR_TOKENS_DESCR: "",
  NAME_PLAN: "",
  ACTIVATE: "",
  SEND_TOKEN: "",
  INSERT_TOKEN: "",
  QUANTITY: "",
  DISCOVER_LICENSE_POLICY: "",
  LICENSE_POLICY: "",
  UNIT_PRICE: "",
  PRICE: "",
  PRICES: "",
  BUY: "",
  BUY_NOW: "",
  COURSE_IS_PART_PLAN: "",
  DISCOVER_MORE: "",
  BUY_MORE_LICENSES: "",
  LICENSES_NUM: "",
  BUY_FOR_OTHERS: "",
  SEE_CART: "",
  EXPIRING_DATE: "",
  EXPIRING: "",
  LESSON_WILL_EXPIRE: "",
  INCLUDED_IN_YOUR_PLAN: "",
  PURCHASED: "",
  LICENSES_AVAILABLE: "",
  TOKEN_PROCESSING_SUCCESS_TITLE: "",
  TOKEN_PROCESSING_ERROR_TITLE: "",
  TOKEN_PROCESSING_SUCCESS_MESSAGE: "",
  TOKEN_PROCESSING_ERROR_MESSAGE_05: "",
  WHAT_HAPPENS_NEXT: "",
  TOKEN_PROCESSING_SUCCESS_MESSAGE2: "",
  TOKEN_PROCESSING_ERROR_MESSAGE2: "",
  CONTACT_CUSTOMER_CARE: "",
  SEND_TOKEN_FRIEND: "",
  SEND_TOKEN_FRIEND_DESCR: "",
  ENTER_EMAIL_FRIEND_PLACEHOLDER: "",
  STEP_1_FRIEND_ACTIVATION_TOKEN: "",
  STEP_2_FRIEND_ACTIVATION_TOKEN: "",
  STEP_3_FRIEND_ACTIVATION_TOKEN: "",
  TOKEN_SENT_CORRECTLY: "",
  NEXT_STEPS: "",
  SEND_ANOTHER_TOKEN: "",
  FREE: "",
  UNDER_SUBSCRIPTION_PLAN: "",
  IN_DAYS: "",
  IN_1_MONTH: "",
  IN_MONTHS: "",
  IN_1_YEAR: "",
  IN_YEARS: "",
  BACK: "",
  NAME_LABEL: "",
  SURNAME_LABEL: "",
  ADDRESS_LABEL: "",
  ZIP_CODE_LABEL: "",
  CITY_LABEL: "",
  STATE_LABEL: "",
  COUNTRY_LABEL: "",
  BUY_COURSE_LABEL: "",
  BUY_LESSON_LABEL: "",
  HAVE_ADD_COURSE_ALREADY_IN_SUBSCRIPTION: "",
  HAVE_ADD_LESSON_ALREADY_IN_SUBSCRIPTION: "",
  HAVE_ADD_COURSE_ALREADY_IN_SUBSCRIPTION_DESCR: "",
  HAVE_ADD_LESSON_ALREADY_IN_SUBSCRIPTION_DESCR: "",
  LESS_PART_COURSE: "",
  LESS_PART_COURSE_DESCR: "",
  HAVE_ONLY_1_MONTH: "",
  HAVE_ONLY_1_MONTH_DESCR: "",
  HAVE_ONLY_7_DAYS: "",
  HAVE_ONLY_7_DAYS_DESCR: "",
  HAVE_ONLY_1_DAY: "",
  HAVE_ONLY_1_DAY_DESCR: "",
  BUY_FULL_COURSE_LABEL: "",
  BUY_SINGLE_LESSON_LABEL: "",
  UNLIMITED_ACCESS_PLAN: "",
  UNLIMITED_ACCESS_PLAN_DESCR_1: "",
  UNLIMITED_ACCESS_PLAN_DESCR_2: "",
  REFUND_CANCELLATION_POLICY: "",
  LICENSE_REFUND_POLICY: "",
  CART_MODAL_MESSAGE: "",
  CONTENT_EXPIRATION_MODAL_MESSAGE: "",
  GOT_IT: "",
  DONT_SHOW_MESSAGE_AGAIN: "",
  DISCOVER_MORE_PLAN: "",
  INCLUDED_PLAN: "",
  VIEW_RECORDING: "",
  CART: "",
  ITEM: "",
  ACTIVATE_IT_NOW: "",
  APPLY_PROMO_CODE: "",
  INSERT_PROMO_CODE: "",
  APPLY_CODE: "",
  PROCEED_TO_CHECKOUT: "",
  PROCEED_TO_PAYMENT: "",
  ESTIMATED_TAXES: "",
  ACCEPT_TERMS_CONDITIONS_BUTTONS: "",
  CONTACT_US: "",
  PROMO_CODE_SUCCESS: "",
  PROMO_CODE_ERROR: "",
  REMOVE_PROMO_CODE: "",
  ERROR_CART_ITEM_EXPIRED: "",
  ERROR_CART_ITEMS_WITH_PLAN: "You can purchase only one plan at a time. To proceed, please remove all other plans or items from your cart. ",
  EXPIRED: "",
  BACK_TO_HP: "",
  CHECKOUT: "",
  FOR_ASSISTANCE_CALL: "",
  SAVE_ADDRESS_ACCOUNT: "",
  VIEW_ORDER_SUMMARY: "",
  TAX: "",
  PROMOTION: "",
  PAYMENT_DETAILS: "",
  ORDER_CONFIRMATION: "",
  EDIT_BILLING_ADDRESS: "",
  SAVE_CARD_ACCOUNT: "",
  CARD_NUMBER_PLACEHOLDER: "",
  CCNAME_PLACEHOLDER: "",
  CVC_PLACEHOLDER: "",
  CC_EXPIRY_PLACEHOLDER: "",
  PAY_WITH_CREDIT_CARD: "",
  PAY_WITH_PAYPAL: "",
  PAY_CONFIRM_ORDER: "",
  EDIT_CART: "",
  ORDER_SUCCESSFULLY_CONFIRMED_DESCR: "",
  PAYMENT_METHOD: "",
  CREDIT_CARD: "",
  ADD_NEW_CARD: "",
  USA_LABEL: "",
  PLAN: "",
  WITH_PLAN_CAN: "",
  SUBSCRIPTION_TRIAL_3_MONTHS: "",
  WHO_PLAN_FOR: "",
  WHO_PLAN_FOR_DESCR: "",
  PLAN_INCLUDES: "",
  TRY_FOR_FREE: "",
  FREE_TRIAL_END_3_MONTHS: "",
  YOU_SAVED_IN_TOTAL: "",
  EMPTY_CART_TITLE: "",
  EMPTY_CART_DESCR: "",
  SEE_OUR_OFFER_PLANS: "",
  EVERY_TRANSITION_SAFE: "",
  PAYMENT_METHODS: "",
  CALCULATE: "",
  COURSES_SELECTION_DESCR: "",
  OFFER_PLAN: "",
  FILTER_PLAN_PROMO: "",
  FILTER_PLAN_MASTER_COURSES: "",
  FILTER_PLAN_PROFESSIONAL: "",
  FILTER_PLAN_CERTIFICATES: "",
  FILTER_PRICE_FROM_TO: "",
  FILTER_PRICE_OVER_FROM: "",
  PLAN_WHAT_CAN_DO_1: "",
  PLAN_WHAT_CAN_DO_2: "",
  PLAN_WHAT_CAN_DO_3: "",
  EMAIL_THANKS_YOUR_ORDER: "",
  EMAIL_THANKS_YOUR_ORDER_DESCR: "",
  EMAIL_PAYED_WITH: "",
  EMAIL_REFUND: "",
  EMAIL_WHATS_NEXT: "",
  EMAIL_WHATS_NEXT_DESCR: "",
  EMAIL_GO_TO_PAGE: "",
  EMAIL_PERSONAL_PROFILE: "",
  EMAIL_ACTIVATE_PURCHASE: "",
  EMAIL_ACTIVATE_PURCHASE_DESCR: "",
  EMAIL_ACTIVATE_PURCHASE_SUBDESCR1: "",
  EMAIL_ACTIVATE_PURCHASE_SUBDESCR2: "",
  EMAIL_ORDER_HISTORY_DESCR: "",
  EMAIL_ORDER_HISTORY_LINK: "",
  EMAIL_EMAIL_US: "",
  EMAIL_EMAIL_US_DESCR: "",
  EMAIL_RECEIVED_GIFT: "",
  EMAIL_RECEIVED_GIFT_DESCR1: "",
  EMAIL_RECEIVED_GIFT_DESCR2: "",
  EMAIL_RECEIVED_GIFT_REGISTRATION: "",
  EMAIL_RECEIVED_GIFT_REGISTRATION_DESCR: "",
  EMAIL_RECEIVED_GIFT_REGISTRATION_CTA: "",
  EMAIL_RECEIVED_GIFT_ACTIVATE: "",
  EMAIL_RECEIVED_GIFT_ACTIVATE_DESCR1: "",
  EMAIL_RECEIVED_GIFT_ACTIVATE_DESCR2: "",
  EMAIL_RECEIVED_GIFT_ACTIVATE_CTA: "",
  PREMIUM_ACCESS_PLAN: "",
  STEP_1_CHECKOUT_TITLE: "",
  STEP_1_CHECKOUT_DESCR: "Shortly, you will receive a confirmation email.",
  STEP_2_CHECKOUT_TITLE: "Token",
  STEP_2_CHECKOUT_DESCR: "Next, you will receive an email containing your tokens.",
  STEP_3_CHECKOUT_TITLE: "",
  STEP_3_CHECKOUT_DESCR: "",
  STEP_3_CHECKOUT_MASTER_DESCR: "You will be able to activate the token for personal use or send it to a fellow learner. Remember to activate it in order to partecipate in the master.",
  STEP_4_CHECKOUT_TITLE: "Tutor",
  STEP_4_CHECKOUT_DESCR: "A tutor will be in contact shortly to give you all necessary details and confirm the start date/time of the Master. You will be notified if the Master is canceled.",
  COMMERCIAL_OFFER_MENU: "",
  COMMERCIAL_OFFER_TITLE: "",
  COMMERCIAL_OFFER_DESCR: "",
  PREMIUM_ACCESS_PLAN_DESCR: "",
  VIEW_DETAILS: "",
  PICK_A_COURSE_CAROUSEL: "",
  PICK_A_COURSE_CAROUSEL_DESCR: "",
  SINGLE_COURSE_OFFER_CAROUSEL: "",
  SINGLE_COURSE_OFFER_CAROUSEL_DESCR: "",
  PURCHASED_CONTENT_CAROUSEL_TITLE: "",
  PURCHASED_CONTENT_CAROUSEL_TITLE_DESCR: "",
  COMMERCIAL_OFFER_CAROUSEL_TITLE: "",
  COMMERCIAL_OFFER_CAROUSEL_DESCR: "",
  CREDIT_CARD_NUMBER_INVALID: "",
  CREDIT_CARD_NAME_REQUIRED: "",
  CREDIT_CARD_EXPIRE_DATE_REQUIRED: "",
  CREDIT_CARD_CVV_REQUIRED: "",
  CREDIT_CARD_GENERIC_ERROR: "",
  WCAG_PUCHASABLE: "",
  WCAG_PURCHASED: "",
  REFUND_POLICY: "",
  CANCELLATION_POLICY: "",
  REFUND_CANCELLATION_FILE_TITLE: "",
  PRIVACY_POLICY_ECOMMERCE_TITLE: "",
  EXPLORE_MORE_CONTENT: "",
  EXPLORE_MORE_CONTENT_DESCR: "",
  EXPLORE_MORE_CONTENT_CTA: "",
  DISCOVER_SUBSCRIPTION_PLAN: "",
  DISCOVER_SUBSCRIPTION_PLAN_DESCR: "",
  FAQ_ECOMMERCE_EXTENDED: "",
  TERMS_OF_USE_TITLE: "",
  BUY_AGAIN: "",
  OR: "",
  YEARLY: "",
  COMMERCIAL_OFFER_SUBDESCR: "",
  CONFIRM_ORDER: "",
  ACTIVATE_TOKEN_LOADING_TEXT: "",
  TOKEN_PROCESSING_ERROR_MESSAGE_01: "",
  TOKEN_PROCESSING_ERROR_MESSAGE_02: "",
  TOKEN_PROCESSING_ERROR_MESSAGE_03: "",
  TOKEN_PROCESSING_ERROR_MESSAGE_04: "",
  PREMIUM_ACCESS_PLAN_PLP_DESCR: "",
  ACTIVATED_ON: "",
  ACTIVATED_USER: "",
  PLAN_CONTINUE_EXPERIENCE: "",
  PLAN_BADGE_END_FREE_TRIAL: "End of free trial:{DATE}",
  PLAN_BADGE_VALID_UNTIL: "Valid until:{DATE}",
  PLAN_BADGE_EXPIRED_ON: "Expired on:{DATE}",
  PLAN_BADGE_AUTOMATIC_RENEWAL_ON: "Valid until: automatical renewal ON",
  MONTHS_3_FREE_TRIAL: "3-months free trial",
  FREE_TRIAL_WILL_END_ON: "The free trial will end on {DATE}",
  PLAN_WILL_EXPIRE_AFTER_1_YEAR: "This plan will expire after 1 year from its purchase date ({DATE}).",
  FREE_TRIAL_WILL_END_ON_AND_WILL_BE_RENEWED: "The free trial will end on {DATE} and will be renewed at {PRICE} until canceled.",
  PLAN_WILL_END_ON_AND_WILL_BE_RENEWED: "This plan will expire on {DATE} and will be renewed at {PRICE} until canceled.",
  STOP_AUTOMATIC_RENEWAL_CTA: "Stop automatic renewal",
  FREE_TRIAL_WILL_END_ON_ACTIVATE_RENEWAL: "The free trial will end on {DATE}. Activate the auto-renewal to keep access to the premium content",
  PLAN_WILL_END_ON_ACTIVATE_RENEWAL: "This plan will expire on {DATE}. Activate the automatic renewal to keep access to the premium content",
  ACTIVATE_PLAN: "Activate plan",
  PLAN_EXPIRED_ON: "This yearly plan has expired on {DATE}. You have until {DATE2} to activate the automatic renewal. After this day you will lose access to Premium Content.",
  // PLAN_EXPIRED_ON_ADD_PAYMENT: "This yearly plan has expired on {DATE}. You have until {DATE2} to add a valid payment method. After this day you will lose access to Premium Content.", // NOT IN PHASE 1
  ADD_PAYMENT_METHOD: "Add payment method",
  WANT_PLAN_TO_BE_RENEWED: "Do you want your plan to be renewed automatically?",
  FREE_TRIAL_WILL_END_SOON: "Your free trial will end soon. Complete all the Premium Content and finish the courses you have started.",
  // FREE_TRIAL_ENDED: "Your free trial ended on {DATE}. You have {DAYS} days to activate the automatic renewal which will renew the plan at {PRICE}/year until canceled. After this day, you will lose access to premium content.", // NOT IN PHASE 1
  PLAN_WILL_END_SOON: "Your plan will end soon. Complete all the Premium Content and finish the courses you have started.",
  // PLAN_EXPIRED_DAYS_LEFT: "Your plan has expired. You have {DAYS} days to complete your unfinished courses as to not lose your progress.", // NOT IN PHASE 1
  LICENSE_REFUND_POLICY_NOT_SURE: "<b>Not sure?</b> See our {refund policy} or {license policy}",
  MODAL_RENEWAL_CONFIRMATION_TITLE: "Are you sure you want to stop your automatic renewal?",
  MODAL_RENEWAL_CONFIRMATION_DESCR: "You will be able to view your purchased content until {DATE}. After this day, you will have to purchase a new to plan to access premium content.",
  AUTORENEWAL_ON: "Auto renewal ON: Premium Content is accessible until deactivated",
  ADD_NEW_CREDIT_CARD: "Add new Credit Card",
  SAVED_PAYMENT_METHODS: "Saved payments methods",
  PAYMENT_METHOD_DETAIL: "Payment method detail",
  ADD_NEW_PAYMENT_METHOD: "Add new payment method",
  MAKE_DEFAULT_METHOD: "Make default method",
  RENEWAL_BOX_FULL_PRICE_TITLE: "This plan will expire 1 year from its purchase date. After that, you will be able to renew your subscrition at the cost of {PRICE}.",
  RENEWAL_BOX_FREE_PLAN_OFF_DESCR: "By clicking on the button above, your plan will renew automatically at {PRICE} per year until canceled.",
  RENEWAL_BOX_FREE_PLAN_OFF_DESCR2: "You can unsubscribe at any time during the 3-month trial by visiting the {Subscription Plan page} and selecting <b>stop automatic renewal</b>",
  RENEWAL_BOX_FREE_PLAN_ON_DESCR: "After the three month free trial, the Leonardo subscription will renew automatically at {PRICE} per year until canceled.",
  RENEWAL_BOX_POLICY: "Automatic renewal policy",
  RENEWAL_BOX_NEXT_PAYMENTS: "Next payments",
  RENEWAL_BOX_FREE_PLAN_TIME_START: "<b>Today</b> for 1 year",
  RENEWAL_BOX_FREE_PLAN_TIME_END: "{DATE} for 1 year",
  RENEWAL_BOX_TODAY: "Today",
  RENEWAL_BOX_FROM_DATE: "From {DATE}",
  RENEWAL_BOX_FOR_3_MONTHS: "for 3 months",
  RENEWAL_BOX_FOR_1_YEAR: "for 1 year",
  RENEWAL_BOX_FREE_PLAN_LIST1: "You can unsubscribe at any time during the 3-month trial by visiting the {Subscription Plan page} and selecting <b>stop automatic renewal</b>",
  RENEWAL_BOX_FREE_PLAN_LIST2: "You will receive an email reminder {DAYS} days prior to the first charge after the end of the free trial and {DAYS} days prior to the auto-renewal of your annual subscription.",
  RENEWAL_BOX_FULL_PRICE_LIST1: "You can unsubscribe at any given time from the {Plan page} by selecting <b>stop automatic renewal</b>",
  RENEWAL_BOX_FULL_PRICE_LIST2: "You will receive an email reminder {DAYS} days before the next payment",
  RENEWAL_BOX_PLAN_PAGE: "Plan page",
  TOTAL_FOR_TODAY_CART: "Total for today",
  MODAL_START_AUTORENEWAL_TITLE: "Are you sure you want to activate automatic renewal?",
  MODAL_START_AUTORENEWAL_FREE_PLAN_TITLE2: "At the end of the 3-month trial ({DATE}), your plan subscription will be automatically renewed at the cost of {PRICE}.",
  MODAL_START_AUTORENEWAL_FULL_PRICE_TITLE2: "On {DATE} your yearly plan subscription will be automatically renewed at the cost of {PRICE}.",
  CARD_DETAILS_WILL_BE_SAVED: "Your card details will be saved",
  CHOOSE_PAYMENT_METHOD: "Choose your payment method",
  MODAL_AUTORENEWAL_ACTIVATED: "Automatic renewal activated",
  AUTORENEWAL_ACTIVATED: "Automatic renewal activated",
  MODAL_AUTORENEWAL_ACTIVATED_DESCR: "On {DATE}, your payment method {METHOD} ***{LAST_4_DIGITS} will be charged {PRICE} for the renewal of your yearly subscription. Then, the subscription will renew annually at {PRICE}/year until canceled.",
  GOT_IT2: "Got it",
  CONFIRM_DELETE_PAYMENT_TITLE: "Are you sure you want to delete this payment method?",
  CONFIRM_DELETE_PAYMENT_DESCR: "This payment method will be removed from your saved payment methods.",
  CONFIRM_SET_DEFAULT_PAYMENT_TITLE: "Are you sure you want this to be your default payment method?",
  CONFIRM_SET_DEFAULT_PAYMENT_DESCR: "You can only have one default payment method at a time. Setting this payment method as default will void the previous one.",
  DEFAULT: "Default",
  SUCCESS_DELETE_PAYMENT_TITLE: "Payment method deleted",
  SUCCESS_DELETE_PAYMENT_DESCR: "Your payment method has been deleted.",
  FAILURE_DELETE_PAYMENT_TITLE: "Payment method not deleted",
  FAILURE_DELETE_PAYMENT_DESCR: "An error has occured.",
  ADD_ITEMS_PLAN_ERROR_TITLE: "Could not add to cart",
  ADD_ITEMS_PLAN_ERROR_DESCR: "It is not permitted to purchase any item together with a plan.",
  SINCE_AUTORENEWAL_ACTIVE_FREE: "Valid until: Since you have activated automatic renewal, you will have access to Premium Content until you decide to de-activate it.",
  ERROR_CANT_CONTINUE_LESSON_NO_PLAN_TITLE: "Plan has expired",
  ERROR_CANT_CONTINUE_LESSON_NO_PLAN_DESCR: "To continue this lesson you need to renew the plan.",
  AN_ERROR_OCCURRED_WHILE_RETRIEVING_CARD_INFO: "An error has occured while retrieving your card information.",
  RENEWS_AT_UNTIL_CANCELLED: "Renews at {PRICE}/year until canceled.",
  YOU_MAY_CANCEL_AUTO_RENEWAL: "You may cancel your auto-renewal at any time by clicking here: {Stop automatic renewal}",
  YOUR_PLAN_RENEWED_AT: "Your plan will renew at {PRICE} until canceled.",
  RENEWAL_ON_TIP_1: "You may cancel your free trial at any time during the 3 months: just visit the Plan page and select Stop automatic renewal.",
  // RENEWAL_ON_TIP_2: "You will receive an email reminder {DAYS} days prior to the first charge after the end of the free trial and {DAYS} days prior to the auto-renewal of your annual subscription.", NOT USED
  RENEWAL_ON_MODAL_TIP_1: "You may cancel your auto-renewal at any time: just visit the Plan page and select stop automatic renewal", // USED BUT MIGHT BE REPLACED?
  // RENEWAL_ON_MODAL_TIP_2: "You will receive an email reminder {DAYS} days before the next charge", // NOT USED
  // PURCHASING_PLAN_AFTER_RENEWAL_YOU_CAN: "You are purchasing the plan for {DATE}. After that, you will be able to renew your subscription at the cost of {PRICE}, or choose for it to be renewed automatically.", // not necessary, added new key for this (TITLE)
  ACCEPT_TERMS_CONDITIONS_BUTTONS_ABOVE: "By clicking on the button above you agree to our {Terms of Use} and {Privacy Policy}",
  CLICKING_PAY_ACCEPT_RENEWAL: "By clicking on the button above you will submit the Payment Method and accept auto-renewal",
  PLAN_EXPIRED_COURSE_OVERVIEW_MESSAGE: "Your Plan has expired. To continue this content you’ll need to buy it or the {Premium Access Plan} again.",
  ORDER_NAME_PLAN_AUTORENEWAL_SUFFIX: " - Plan Renewal",
  AUTO_RENEWAL_POLICY_FILE_TITLE: "Auto-renewal policy",
  CONTENT_EXPIRATION_MODAL_TITLE: "Take this course before it expires",
  CART_MODAL_TITLE: "What is included in this plan?",
  VIEW_CART: "View cart",
  //labels for master
  NOT_PURCHASED_MASTER_TITLE: "Purchase the {COMPLEXITY} in order to access the following content.",
  COMMERCIAL_OFFER_DESCR2: "Explore our exclusive content and pick the best solution for you and your team",
  ECOMMERCE_COUNTER_MAX_SEATS_MASTER: "For this master only a few spots left. You can't select more than {NUM}.",
  REQUEST_SENT_LABEL: "Request sent",
  SOLD_OUT: "Sold out",
  FREE_TRIAL: "Free trial",
  REQUEST_TO_JOIN: "Request to join",
  CONTACT_CUSTOMER_SERVICE: "Contact Customer Service",
  MORE_PREMIUM_CONTENT_CAROUSEL_TITLE: "More premium content",
  MORE_PREMIUM_CONTENT_CAROUSEL_DESCR: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
  MASTER_SOLDOUT_FIRST_LABEL: "This master is sold out",
  MASTER_SOLDOUT_SECOND_LABEL: "Send your request to have the chance to participate.",
  WAITING_LIST_REQUEST_SENT_TITLE: "Request sent",
  WAITING_LIST_REQUEST_SENT_DESCR: "You have requested to join. In case of availability, someone from customer service will contact you.",
  PURCHASABLE_OUTSIDE_PLAN_LABEL: "Purchasable outside of plan",
  CART_ERROR_MASTER_OUT_SELLABILITY: "The purchase period of {COURSENAME} is over",
  CART_ERROR_MASTER_NOT_ENOUGH_SEATS: "There are only a few spots left for the master {COURSENAME}. You can't select more than {NUM}",
  //ECOMM 1.6
  RENEWAL_BOX_PREMIUM_ACCESS_PLAN: "The Premium Access Plan comes with 25 licenses, which means 25 people will be able to access our full range of premium content! Activate the license for yourself, or gift them to others and start learning together.",
  RENEWAL_BOX_UNSUBSCRIBE_INFO: "You can unsubscribe at any given time from the Plan page by selecting stop automatic renewal.",
  QUANTITY_LONG: "Quantity",
  THANK_YOU: "Thank you",
  ORDER_SUCCESSFULLY_CONFIRMED: "Your order n°ORDER has been succesfully placed.",
  DISCOVER_LEONARDO_PREMIUM_OFFER: "Discover Leonardo’s Premium Offer",
  DISCOVER_SUBSCRIPTION_PLAN_DESCR2: "Luxury Ambassador will teach you how to go above and beyond your customer's expectations. By the end of this course, you'll be able to elevate your approach in store and add value to the brands and styles you represent.",
  PLAN_PAGE_1_DESC_TITLE: "What is the plan?",
  PLAN_PAGE_1_DESC_CONTENT: "The Premium Access Plan gives you unlimited access to our full catalog of courses, lessons and certifications created by industry professionals. Courses cover Vision Care, People and Practice Management, and much more.",
  PLAN_PAGE_2_DESC_TITLE: "What are the benefits?",
  PLAN_PAGE_2_DESC_CONTENT: "Enhance your knowledge on a range of topics from myopia, presbyopia, and light management and refraction to the customer journey, e-commerce planning, and social media management.",
  PLAN_PAGE_3_DESC_TITLE: "How do you use the plan?",
  PLAN_PAGE_3_DESC_CONTENT: "Purchasing the Premium Plan gets you 25 licenses, which means you and 24 others can access our range of premium content and get started on your Leonardo learning journey together! Contribute to the education of your employees, or even direct your new hires to their tailor-made onboarding paths. If you wish to purchase individual lessons or courses, you will receive just 1 license, which you can also activate for yourself or send to somebody else. Below, you’ll find an overview of some of the content included in the plan.",
  //NEXT STEPS TO UPDATE
  // STEP_1_CHECKOUT_TITLE: "Activate or send license",
  // STEP_1_CHECKOUT_DESCR: "Shortly, you will receive a confirmation email. You will then be able to activate each license for personal use or send them to up to 25 friends.",
  // STEP_2_CHECKOUT_TITLE: "Discover Leonardo",
  // STEP_2_CHECKOUT_DESCR: "After activating your license, explore our catalog created by industry experts and start learning with Leonardo!",
  YOU_HAVE_ACTIVATED_PREMIUM_ACCESS_PLAN: "You have activated the Premium Access Plan.",
  YOU_HAVE_PURCHASED_PREMIUM_ACCESS_PLAN: "You have purchased the Premium Access Plan.",
  ACTIVATE_PLAN_FOR_YOURSELF_OR_SEND_LICENSE: "Activate it for yourself or send a license to a fellow learner.",
  START_LEARNING_OR_SEND_LICENSE: "Start learning now or send a license to a fellow learner.",
  SEND_LICENSE_TO_A_FELLOW_LEARNER: "Send a license to a fellow learner and help them get started on their learning journey.",
  ACTIVATE_PLAN_FOR_ME: "Activate plan for me",
  MANAGE_LICENSES: "Manage licenses",
  ACTIVATE_AUTOMATIC_RENEWAL: "Activate automatic renewal",
  KEEP_ACCESS_TO_PREMIUM_CONTENT: "Keep access to the premium content by clicking here",
  //NEXT STEPS THANK YOU PAGE
  STEP_1_CHECKOUT_TITLE_ECOMM16: "Activate or send license",
  STEP_2_CHECKOUT_TITLE_ECOMM16: "Discover Leonardo",
  STEP_2_CHECKOUT_TITLE_MASTER: "Master details",
  STEP_1_CHECKOUT_DESCR_ECOMM16: "Shortly, you will receive an email with instructions on how to activate your license. You can use it personally or send it to a friend.",
  STEP_2_CHECKOUT_DESCR_ECOMM16: "After activating your license, start learning with Leonardo!",
  STEP_1_CHECKOUT_DESCR_PLAN: "Shortly, you will receive a confirmation email. You will then be able to activate each license for personal use or send them to up to {QUANTITY} friends.",
  STEP_2_CHECKOUT_DESCR_PLAN: "After activating your license, explore our catalog created by industry experts and start learning with Leonardo!",
  STEP_1_CHECKOUT_DESCR_MASTER:"Shortly, you will receive an email with instructions on how to activate your license. You can use it personally or send it to a friend. Remember to activate it in order to partecipate in the Master.",
  STEP_2_CHECKOUT_DESCR_MASTER: "A tutor will be in contact shortly to give you all necessary details and confirm the start date/time of the Master. You will be notified if the Master is canceled.",
  AVAILABLE_LICENSES : 'Available licenses',
  AVAILABLE_LICENSES_DESCR : 'Here is how you can use the available licenses',
  SEND_LICENSES_TO_LEARNER: 'Send the license to a fellow learner',
  SEND_LICENSES_DESCR: "Enter the email of the person you wish to give access to your {COURSE}. They will receive the license via email with the steps to follow to activate it, which they will need to do through the 'Activate my license' page.",
  USE_LICENSE: 'Use the license for yourself',
  USE_LICENSE_DESCR: "You can use one of the {NUMBER} licenses for yourself by clicking on the 'Activate license for me' button.",
  ACTIVATE_LICENSE_FOR_ME: 'Activate license for me',
  ADD_LEARNER: 'Add learner',
  SEND_LICENSE: 'Send license',
  SHARE_A_GIFT: 'Share a license:</br>Give the gift of learning',
  SENT_LICENSES: 'Sent licenses',
  SENT_LICENSES_DESCR: 'Here is a list of your shared licenses and their recipients.',
  NO_LICENSES_SENT: 'No licenses sent.',
  LICENSE_NUMBER_TITLE: 'License number',
  LEARNER: 'Learner',
  SENT_DATE_LICENSE: 'Sent date',
  ACTIVATION_DATE_LICENSE: 'Activation date',
  STATUS_LICENSE: 'Status',
  RECOVER_LICENSE_CTA: 'Recover',
  ACTIVATED_LICENSE: 'Activated',
  PENDING_LICENSE: 'Pending activation',
  PENDING_BADGE: 'Pending',
  AUTORENEWAL_ACTIVED: 'Automatic renewal activated',
  LICENSES_ACTIVATED: 'Licenses activated',
  ACTIVATED_FOR_ME: 'Activated for me',
  ENTER_LEARNER_EMAIL: 'Enter your fellow learner’s email address',
  MAX_NUMBER_LICENSES: 'You have reached the maximum number of licenses.',
  NO_LICENSES_REMAINING: 'You do not have any licenses remaining. Repurchase the {COURSE} to continue learning.',
  NO_LICENSES_REMAINING_PLAN: 'You do not have any licenses remaining in this Plan. If your plan has expired, make sure to purchase a new one to continue learning.',
  POP_UP_RECOVER_LICENSE: 'Confirm license recovery',
  POP_UP_RECOVER_DESCR: 'Are you sure you wish to recover this license? If you confirm, {email} will not be able to use this license anymore. You will be available to share it with another learner.',
  POP_UP_RECOVER_CANCEL: 'Cancel',
  POP_UP_RECOVER_CONFIRM: 'Yes, I confirm',
  CONNECTION_ERROR_ECOMM: 'Connection error. Please try again.',
  EMAIL_ERROR_ECOMM: 'Please enter a correct email address, e.g. name.surname@example.com',
  //CANADA
  MODAL_ACTIVATE_PLAN_CONFIRMATION_TITLE: "Confirm activation",
  MODAL_ACTIVATE_PLAN_CONFIRMATION_DESCR: "Are you sure you wish to activate this plan? By confirming, you'll use one of your remaining {QUANTITY} licenses.",
  MODAL_ACTIVATE_PLAN_CONFIRM: "yes, I confirm",
  MODAL_ACTIVATE_PLAN_CANCEL: "Cancel",
  COUNTRY_US: 'United States',
  COUNTRY_CA: 'Canada',
} as const
