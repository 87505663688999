import React from "react";

const ErrorMessage = ({ error, className = "" }) => {
  return (
    <>
      {/* use role="alert" to announce the error message */}
      {error?.type && <span role="alert" className={className}>{error.message}</span>}
    </>
  );
};

export default ErrorMessage;
