import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React from "react";
import { useDispatch } from "react-redux";
import BuyLicenseSection from "@components/CourseOverview/BuyLicenseSection";
import RefundAndCancellationPolicy from "./RefundAndCancellationPolicy";
import { USER_URLS } from "@components/link-utils";
import * as actions from "@redux-actions/";
import { useSelector } from "@hooks/redux";
import { printHTML } from "@utility/Api";
import selectPlanStates, { isPlanFree } from "@utility/ecommercePlanUtility";
import { PlanModel } from "@model/PlanModel";
import ModalEvolution from "@components/UI/ModalEvolution";
import ButtonV2 from "./ButtonV2";

type ModalPlanProps = {
  show: boolean;
  title?: string;
  description?: string;
  // planId: string; //for now just one plan
  close: () => void;
};

const ModalPlan = ({
  show,
  close,
  title,
  description,
}: ModalPlanProps) => {
  const dispatch = useDispatch();
  const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
  const quantityModalPlan: number = useSelector((state) => state.ecommerce.quantityModalPlan);

  const handleDiscoverMorePlan = () => {
    //close modals
    dispatch(actions.toggleModal(false, null, true));
    close();
  };

  const planStates = useSelector(selectPlanStates);

  return (
    <ModalEvolution
      close={close}
      show={show}
      modalClassName="plan"
      title={title || lang?.UNLIMITED_ACCESS_PLAN}
      descriptionElement={
        <>
          <p dangerouslySetInnerHTML={printHTML(description || lang?.UNLIMITED_ACCESS_PLAN_DESCR_1)} />
          <RefundAndCancellationPolicy firstUrlTitleKey="REFUND_CANCELLATION_FILE_TITLE" />
        </>
      }
      footerElement={
        <>
          <BuyLicenseSection
            hideLicensePolicy
            variant="free-plan"
            initialValue={quantityModalPlan}
            hideQuantity
            priceLeft
            lineThroughPrice={planStates.isPlanFree}
          />
          <ButtonV2
            variant="text-btn-no-arrow"
            small
            className="discover-plan"
            handleClick={() => handleDiscoverMorePlan()}
            to={USER_URLS.PLAN.URL}
          >
            {lang?.DISCOVER_MORE_PLAN}
          </ButtonV2>
        </>
      }
    />
  );
};

export default ModalPlan;
