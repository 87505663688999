import { DataLayerTracking, PageSections, PageTypes, TealiumEvents, TealiumEventsDataLayer, selectTrackingDataLayerBase } from '@model/TrackingClass';
import { isActivateLicensePage, isBrandL2Page, isBrandPage, isBrandSubcataloguePage, isBrandTPSubcataloguePage, isEditorialPage, isPlpTpPage, isProgramPage, isSubjectMatterProgramPage, styledLogUtagView, USER_URLS, UtagViewsNotifications } from '@components/link-utils';
import { matchPath } from 'react-router';
import { UserProfile } from '@model/User';
import { isMobile } from '@utility/Api';
import { isPersonificationOn } from '../../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { UserTypes } from '@model/TrackingClass';
import { useSelector } from 'react-redux';
import { queryParams } from '@utility/const';
import { triggerPageView } from '@utility/analytics-utils';

// export const analyticsViewHeroBanner = bannerId => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     const userProfile = getState().user.userProfile;

//     const utagData = {
//       id: TealiumEvents.hero_banner_view,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       banner_id: bannerId, //CMS hero banner id
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Hero banner view', utagData);
//     window?.utag?.view(utagData);
//   };
// };

// export const analyticsClickCtaHeroBanner = bannerId => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     const userProfile = getState().user.userProfile;

//     const utagData = {
//       id: TealiumEvents.hero_banner_click,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       banner_id: bannerId, //CMS hero banner id
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Hero banner click', utagData);
//     window?.utag?.link(utagData);
//   };
// };

// export const analyticsClickContentCarousel = (
//   content: Course | L1Element | TrainingPill,
//   positionInCarousel,
//   carouselId = -1,
//   carouselPosition = 0
// ) => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     //for now handle just HP
//     if (
//       !content ||
//       !matchPath(window?.location?.pathname, { path: USER_URLS.HOMEPAGE.URL, exact: true })
//     ) {
//       return;
//     }

//     const userProfile = getState().user.userProfile;

//     let courseIdMaster = '';
//     let courseId = '';
//     let complexity = '';
//     if (content instanceof L1Element) {
//       courseIdMaster = content.value;
//       courseId = content.value;
//     } else if (content instanceof TrainingPill || content.model) {
//       courseIdMaster = content.model;
//       courseId = content.model;
//     } else {
//       courseIdMaster = content.courseIdMaster;
//       courseId = content.courseId;
//       complexity = content.complexity?.complexity;
//     }

//     const utagData = {
//       id: TealiumEvents.carousel_click_content,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       complexity: [complexity],
//       Products_Id_Array: [courseIdMaster],
//       product_id_child: [courseId],
//       carousel_product_position: positionInCarousel, //course position inside carousel
//       carousel_id: carouselId, //CMS carousel id
//       carousel_position: carouselPosition, //carousel position respect other carousels in HP
//       Page_Section2: UtagViews[window.location.pathname]?.Page_Section2, //non copre tutte le pagine ma per ora
//       Page_Type: UtagViews[window.location.pathname]?.Page_Type,
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Click card inside carousel', utagData);
//     window?.utag?.link(utagData);
//   };
// };

// export enum AnalyticsCarouselButtonType {
//   slider_dx = 'slider_dx',
//   slider_sx = 'slider_sx',
//   see_all = 'see_all',
// }
// export const analyticsClickArrowCarousel = (
//   buttonType: AnalyticsCarouselButtonType,
//   carouselId,
//   carouselPosition,
//   carouselSkin: CMSCarouselLayout
// ) => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     //for now handle just HP
//     if (!matchPath(window?.location?.pathname, { path: USER_URLS.HOMEPAGE.URL, exact: true })) {
//       return;
//     }

//     const userProfile = getState().user.userProfile;

//     const utagData = {
//       id: TealiumEvents.carousel_click_arrow,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       button_type: buttonType,
//       carousel_id: carouselId, //CMS carousel id
//       carousel_position: carouselPosition, //carousel position respect other carousels in HP
//       carousel_skin: carouselSkin, //CMS carousel skin
//       Page_Section2: UtagViews[window.location.pathname]?.Page_Section2, //non copre tutte le pagine ma per ora
//       Page_Type: UtagViews[window.location.pathname]?.Page_Type,
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Click arrow or see all inside carousel', utagData);
//     window?.utag?.link(utagData);
//   };
// };

// export const analyticsCarouselCollectionClickCard = (
//   brandId: string,
//   collectionId: string,
//   positionInCarousel: number,
//   carouselId: number,
//   carouselPosition
// ) => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     //for now handle just HP
//     if (!matchPath(window?.location?.pathname, { path: USER_URLS.HOMEPAGE.URL, exact: true })) {
//       return;
//     }

//     const userProfile = getState().user.userProfile;

//     const utagData = {
//       id: TealiumEvents.carousel_collections_click_card,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       collection_id: brandId + '/' + collectionId, //es. RB/2023SS
//       carousel_product_position: positionInCarousel, //collection position inside carousel
//       carousel_id: carouselId, //CMS carousel id
//       carousel_position: carouselPosition, //carousel position respect other carousels in HP
//       carousel_skin: CMSCarouselLayout.COLLECTION, //CMS carousel skin
//       Page_Section2: UtagViews[window.location.pathname]?.Page_Section2, //non copre tutte le pagine ma per ora
//       Page_Type: UtagViews[window.location.pathname]?.Page_Type,
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Click collection card inside carousel', utagData);
//     window?.utag?.link(utagData);
//   };
// };

// export enum AnalyticsCarouselSubelementType {
//   subelement = 'subelement',
//   see_all = 'see_all',
//   close = 'close',
// }
// export const analyticsCarouselCollectionClickSubelement = (
//   subelementType: AnalyticsCarouselSubelementType,
//   brandId: string,
//   collectionId: string,
//   positionInCarousel: number,
//   carouselId: number,
//   carouselPosition: number,
//   productId: string
// ) => {
//   //removed by https://luxotticaretail.atlassian.net/browse/LEON-3373
//   return (dispatch, getState) => {
//     //for now handle just HP
//     if (!matchPath(window?.location?.pathname, { path: USER_URLS.HOMEPAGE.URL, exact: true })) {
//       return;
//     }

//     const userProfile = getState().user.userProfile;

//     const utagData = {
//       id: TealiumEvents.carousel_collections_click_subelement,
//       User_Id: userProfile?.username,
//       User_LoginStatus: userProfile?.senderSystem,
//       carousel_subelement_type: subelementType,
//       content_type:
//         subelementType === AnalyticsCarouselSubelementType.subelement ? 'novelties' : '',
//       collection_id: brandId + '/' + collectionId, //es. RB/2023SS
//       collection_product_id:
//         subelementType === AnalyticsCarouselSubelementType.subelement ? productId : '',
//       carousel_product_position: positionInCarousel, //novelty position inside carousel
//       carousel_id: carouselId, //CMS carousel id
//       carousel_position: carouselPosition, //carousel position respect other carousels in HP
//       Page_Section2: UtagViews[window.location.pathname]?.Page_Section2, //non copre tutte le pagine ma per ora
//       Page_Type: UtagViews[window.location.pathname]?.Page_Type,
//       User_MoodleID: userProfile?.moodleId,
//     };

//     styledLogUtagView('Click sub-element inside a collection card inside carousel', utagData);
//     window?.utag?.link(utagData);
//   };
// };

export const analyticsNotification = (sectionName: string, elementName: string) => {
  return (dispatch, getState) => {
    //$PageType$Notifications$NotifcationName$
    let pageType;
    Object.keys(UtagViewsNotifications).forEach(urlObj => {
      if (matchPath(window.location.pathname, { path: urlObj })) {
        pageType = UtagViewsNotifications[urlObj].Page_Type;
      }
    });
    const id = `${pageType}_${sectionName}_${elementName}`;

    const utagData = {
      id: TealiumEvents.notification_click_card,
      //id: id,
    };

    styledLogUtagView('Click Notification element', utagData);
    window?.utag?.link(utagData);
  };
};

// Analytics LEON_2602
export const analyticsOpenModal = (pageCategory:string) => {
  return (dispatch, getState) => {

    let pageName;

    Object.keys(UtagViewsNotifications).forEach(urlObj => {
      if (matchPath(window.location.pathname, { path: urlObj })) {
          let urlForUtagViews = urlObj;
          if(isSubjectMatterProgramPage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.SUBJECT_MATTER_PROGRAM.URL
          } else 
          if(isProgramPage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.ONBOARDING_COURSE.URL;
          } else if(isBrandPage(window.location.pathname) || isBrandSubcataloguePage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.BRAND_PAGE.URL.replace(':brand', '');
            if(window.location.pathname.includes("/brand/")) {
              urlForUtagViews = urlForUtagViews.replace(':category', 'brand');
            } else {
              urlForUtagViews = urlForUtagViews.replace(':category', 'channel');
            }
          } else if(isBrandTPSubcataloguePage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.BRAND_TP_SUBCATALOGUE_PAGE.URL
          } else if (isBrandL2Page(window.location.pathname)) {
            urlForUtagViews = USER_URLS.BRAND_L2_PAGE.URL
          } else if (isEditorialPage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.EDITORIAL_CAROUSEL.URL;
          } else if (isPlpTpPage(window.location.pathname)) {
            urlForUtagViews = USER_URLS.SUBCATALOGUE_TP.URL
          } else if (window.location.search.includes(queryParams.SEARCH_QUERY) && !window.location.search.includes(queryParams.COURSE_DETAIL)) {
            urlForUtagViews = USER_URLS.SUBCATALOGUE_SEARCH.URL;
          }
          
          pageName = UtagViewsNotifications[urlForUtagViews]?.Page_Section2;
          if(window.location?.search.includes(queryParams.COURSE_DETAIL)) {
            pageName = 'overlay';
          }
        }
    });
    const utagData : DataLayerTracking = {
      id: TealiumEvents.impression,
      Page_Section2: pageName,
    };

    // styledLogUtagView(`${pageCategory} Overlay`, utagData);
    // triggerPageView(utagData);
  };
};