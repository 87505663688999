import { useSelector } from './redux';
import { useDispatch } from 'react-redux';
import { getBrowseByTopicInfo } from '@redux-actions/';
import { useEffect } from 'react';

const useGetBrowseByTopicInfo = (l1Ids: string[]) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const browseByTopicInfo = useSelector(state => state.search.browseByTopicInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !browseByTopicInfo?.isLoading) {
      const channels = l1Ids?.filter(a => !browseByTopicInfo?.info?.[a]);

      //if some channel info is missing --> request info
      if (channels?.length > 0) {
        dispatch(getBrowseByTopicInfo(channels));
      }
    }
  }, [l1Ids?.toString(), browseByTopicInfo?.isLoading]);
};

export default useGetBrowseByTopicInfo;
