import { COOKIE, PREVIEW_LABEL_COOKIE_KEYS } from '@utility/const';
import { removeCookie } from '@utility/cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks/redux';

type CollectionPreviewBannerProps = {
};

const BACKOFFICE_COLLECTION_PREVIEW_URL = '/backoffice/collection-preview';

const CollectionPreviewBanner = (props: CollectionPreviewBannerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

 const showCollectionPreview = useSelector(state => state.utils.showCollectionPreviewBanner);
  const lang = useSelector(state => state.utils.lang);

  useEffect(() => {
    if (showCollectionPreview && lang) {
        updateCollectionPreviewBannerHeight();
    }
  }, [showCollectionPreview, lang]);

  useEffect(() => {
    if (showCollectionPreview) {
      window.addEventListener("resize", updateCollectionPreviewBannerHeight)
    }
  }, [showCollectionPreview]);

  const updateCollectionPreviewBannerHeight = () => {
    let elementHeight = document.getElementById('label-preview-banner').offsetHeight;
    //console.log('elementHeight', elementHeight)
    if (!elementHeight) {
      elementHeight = 38;
    }
    document.documentElement.style.setProperty('--label-preview-banner-height', elementHeight + 'px');
  }


  const backToBackoffice = () => {
    removeCookie(COOKIE.COLLECTION_PREVIEW);
    window.location = BACKOFFICE_COLLECTION_PREVIEW_URL as unknown as Location;
  }

  if (showCollectionPreview) {
    return (
      <div className="label-preview-banner" id="label-preview-banner">
        <div className="label-preview-banner__container">
          <span>
            {lang.COLLECTION_PREVIEW_TEXT}
          </span>

          <div className="label-preview-banner__ctas">
            <Button handleClick={backToBackoffice}>{lang.COLLECTION_PREVIEW_BACK_TO_BACKOFFICE}</Button>
          </div>
        </div>
      </div>
    )
  }
  return null;
}

export default CollectionPreviewBanner;
