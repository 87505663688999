import React from 'react';
import { useSelector } from '@hooks/redux';
import { handleOnEnterKeyPress } from '@utility/Api';
import CardCover from '@components/CardV3/CardCover';
import { CardDisplayProps } from '@components/CardV3/CardDisplayUtils';
import ButtonV2 from '@components/UI/ButtonV2';
import { useDispatch } from 'react-redux';
import { setShowModalByType } from '@redux-actions/';
import { purifyItemDataElementIdandApplyCamelCasing } from '@utility/analytics-utils';
import { CoverHarvard } from '../Harvard/Harvard';
import clsx from '@utility/clsx';
import { DataLayerTracking } from '@model/TrackingClass';
import { styledLogUtagView } from "@components/link-utils";
import { buildClickDataTrackingObject, UserTypes } from '@model/TrackingClass';
import { camelCase } from 'lodash';

const CardAreaOfExpertise = (props: CardDisplayProps) => {
  const { course, ctypeName } = props;
  const dispatch = useDispatch();

  const lang = useSelector(state => state.utils.lang);

  const handleClickCard = () => {
    buildClickDataTrackingObject("mainNav_"+props.clickObjectTracking+"_image",course.courseId);
    dispatch(setShowModalByType(null));
    props.openCourseModal();
  };

  const handleKeyDown = event => {
    handleOnEnterKeyPress(event, handleClickCard, true);
  };

  return (
    <a
      data-id={props.courseIdMaster}
      aria-label={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', props.courseFullName)}
      className={'card-areaofexpertise focus-outline '}
      onClick={handleClickCard}
      tabIndex={props.tabIndex}
      onKeyDown={handleKeyDown}
    >
      <CardCover
        className="card-areaofexpertise__image"
        variant="areaofexpertise"
        image={props.image}
        courseFullName={props.courseFullName}
        fallbackImage={props.fallbackImage}
        hideSpinner
      />

      <div className="card-areaofexpertise__info">
        {ctypeName && <span className="card-areaofexpertise__ctype">{ctypeName}</span>}
        <span className={clsx("card-areaofexpertise__name", props.typeHarvard && "card-areaofexpertise__name--oneline")}>
          {course.courseFullName?.toUpperCase()}
        </span>
        {props.typeHarvard && <CoverHarvard type={props.course.typeHarvard} />}
        {(!!props.typeHarvard == false) && <span className="card-areaofexpertise__duration">{props.duration}</span>}

        <ButtonV2
          className="card-areaofexpertise__cta"
          variant="text-btn"
          small
          handleClick={(e)=>{e.stopPropagation(); buildClickDataTrackingObject("mainNav_"+props.clickObjectTracking+"_tile",course.courseId);    dispatch(setShowModalByType(null));
            props.openCourseModal();}}
          variantLongArrow
        >
          {lang.AREAS_OF_EXPERTISE_CARD_CTA}
        </ButtonV2>
      </div>
    </a>
  );
};

export default CardAreaOfExpertise;
